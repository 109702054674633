import React, { Component, FormEvent } from 'react';
import { Alert, Modal, ModalHeader, ModalBody, ModalFooter, Container, Form, FormGroup, Button, Label, Input, ButtonGroup, Dropdown, DropdownMenu, DropdownItem, ListGroup, ListGroupItem, InputGroup, InputGroupAddon, DropdownToggle, ButtonDropdown } from 'reactstrap';
import { LocalStorageWorker } from '../StorageHelper';
import { CryptoHelper } from '../CryptoHelper.js';
import { AppCtx, AppContextInterface, ApiHelper } from '../AppContextInterface';
import { Redirect } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DateTime } from 'luxon';
import { TimelinePostQuizAnswer, TimelinePostQuizData } from './Timeline';

interface QuizModalProps {
    open:boolean;
    setParamsCallback(myParams:TimelinePostQuizData):void;
    closeCallback():void;
}

interface QuizModalState {
    language:string;
    isOpen:boolean;
    infoMessage:string;
    errorMessage:string;
    quizQuestion:string;
    quizDays:number;
    quizAnswers:TimelinePostQuizAnswer[];
    curAnswer:string;
}

export interface QuizModal extends React.Component {
    props:QuizModalProps;
    state:QuizModalState;
    contextType: AppContextInterface;
    updateTimer:NodeJS.Timer;
}

export class QuizModal extends React.Component {
    static contextType = AppCtx;
    context!: React.ContextType<typeof AppCtx>;

    constructor(props: QuizModalProps) {
        super(props);

        let contextLanguage = "de";

        if (this.context) {
            if (this.context.language !== null && this.context.language !== "") {
                contextLanguage = this.context.language;
            }
        }

        this.state = {
            language: contextLanguage,
            isOpen:props.open,
            infoMessage:"",
            errorMessage:"",
            quizQuestion:"",
            quizDays:30,
            quizAnswers:[],
            curAnswer:""
        }
    }

    componentDidMount() {
        this.updateTimer = setInterval(this.watchPropsUpdate.bind(this),250);
    }

    watchPropsUpdate() {
        if(this.props.open != this.state.isOpen) {
            this.setState({isOpen: this.props.open});
        }
    }

    componentWillUnmount() {
        if(this.updateTimer) {
            clearInterval(this.updateTimer);
        }
    }

    getText(exp: string) {
        if (this.state.language === "de") {
            if (exp === "Quiz") { 
                return "Quiz";
            }
        } else {
            
        }

        return exp;
    }

    modalClosed() {
        this.setState({isOpen: false});
        this.props.closeCallback();
    }

    handleSubmit(e:FormEvent) {
        e.preventDefault();
        this.props.setParamsCallback({
            question:this.state.quizQuestion,
            days:this.state.quizDays,
            answers:this.state.quizAnswers
        });
    }

    addRightAnswer() {
        let newAnswer:TimelinePostQuizAnswer = {
            answer: this.state.curAnswer,
            right: true
        }
        this.setState({quizAnswers: [...this.state.quizAnswers,newAnswer]});
        this.setState({curAnswer: ""});
    }

    addWrongAnswer() {
        let newAnswer:TimelinePostQuizAnswer = {
            answer: this.state.curAnswer,
            right: false
        }
        this.setState({quizAnswers: [...this.state.quizAnswers,newAnswer]});
        this.setState({curAnswer: ""});
    }

    removeAnswer(clickedItem:TimelinePostQuizAnswer) {
        let myAnswers:TimelinePostQuizAnswer[];
        myAnswers = this.state.quizAnswers.filter((item:TimelinePostQuizAnswer) => { return item.answer !== clickedItem.answer });
        this.setState({quizAnswers: myAnswers});
    }

    handleOptionKeyPress(e:React.KeyboardEvent) {
        if(e.key === "Enter") {
            let hasRightAnswer:boolean = false;

            for(let i = 0; i < this.state.quizAnswers.length; i++) {
                if(this.state.quizAnswers[i].right) {
                    hasRightAnswer = true;
                }
            }

            if(hasRightAnswer) {
                this.addWrongAnswer();
            } else {
                this.addRightAnswer();
            }
        }
    }

    render() {
        if (this.context) {
            if (this.context.producerID === "") {
                return <Redirect to="/" push={true} />
            }
        } else {
            return <Redirect to="/" push={true} />
        }

        return (
            <Modal isOpen={this.state.isOpen} size="lg" onClosed={this.modalClosed.bind(this)}>
                <ModalHeader>
                    { this.getText("Quiz") }
                </ModalHeader>
                <ModalBody>
                    <Alert isOpen={this.state.infoMessage !== ""} color="info">{this.state.infoMessage}</Alert>
                    <Alert isOpen={this.state.errorMessage !== ""} color="danger">{this.state.errorMessage}</Alert>
                    <Form onSubmit={this.handleSubmit.bind(this)}>
                        <FormGroup className="mb-2">
                            <Label>{this.getText("Question")}</Label>
                            <Input type="text" placeholder={this.getText("Question")} value={this.state.quizQuestion} onChange={(e:React.ChangeEvent<HTMLInputElement>) => this.setState({quizQuestion: e.target.value})} />
                        </FormGroup>
                        <Button variant="primary" type="submit">{this.getText("Submit")}</Button>
                    </Form>
                    <div>
                        <h6>{this.getText("Mögliche Antworten")}</h6>
                        <ListGroup>
                            {this.state.quizAnswers.map(item =>
                                <ListGroupItem>
                                    <FontAwesomeIcon icon={solid('question')} size="sm" /> {item.answer} { item.right ? <FontAwesomeIcon icon={solid('circle-question')} size="sm" /> : <FontAwesomeIcon icon={solid('circle-xmark')} size="sm" /> } <FontAwesomeIcon icon={solid('remove')} style={{cursor: "pointer"}} onClick={this.removeAnswer.bind(this,item)} size="sm" /> 
                                </ListGroupItem>
                            )}
                        </ListGroup>
                        <InputGroup>
                            <Input type="text" placeholder={this.getText("Add answer")} onKeyDown={this.handleOptionKeyPress.bind(this)} value={this.state.curAnswer} onChange={(e:React.ChangeEvent<HTMLInputElement>) => this.setState({curAnswer: e.target.value})} />
                            <ButtonDropdown toggle={function noRefCheck(){}}>
                                <DropdownToggle caret>
                                    
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={this.addRightAnswer.bind(this)}>
                                        <FontAwesomeIcon icon={solid('plus')} size="sm" /> {this.getText("Richtige Antwort")}
                                    </DropdownItem>
                                    <DropdownItem onClick={this.addWrongAnswer.bind(this)}>
                                        <FontAwesomeIcon icon={solid('plus')} size="sm" /> {this.getText("Falsche Antwort")}
                                    </DropdownItem>
                                </DropdownMenu>
                            </ButtonDropdown>
                        </InputGroup>
                    </div>
                </ModalBody>
            </Modal>
        )
    }
}

export default QuizModal;