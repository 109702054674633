import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import { AppCtx, AppContextInterface, ApiHelper } from '../AppContextInterface';
import { Card, Alert, Form, FormGroup, Button, Label, Input, Row, Col, CardHeader, CardBody, CardFooter, InputGroup, InputGroupAddon } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ImageList,ImageData } from './ImageList';
import ImageModal from './ImageModal';
import { MessagingPostMediaData } from './Timeline';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-US';
import { DateTime } from 'luxon';

interface ProfileEditState {
    errorMessage: string,
    infoMessage: string,
    language: string,
    userName: string,
    descriptionEN: string,
    descriptionDE: string,
    softDescriptionDE: string,
    softDescriptionEN: string,
    birthday: Date|null,
    hairColor: string,
    eyeColor: string,
    height: number,
    breastSize: number,
    cupSize: string,
    smallImageURL: string,
    bigImageURL: string,
    softSmallImageURL: string,
    softBigImageURL: string,
    smallImageMediaID: string,
    bigImageMediaID: string,
    softSmallImageMediaID: string,
    softBigImageMediaID: string,
    loading: boolean,
    imageModalOpen: boolean,
    imageModalMode: string,
    tags: string[],
    lastUpdateStatus: number,
    lastUpdateMessage: string,
    websiteTitle: string,
    website: string,
    additionalLinkTitle: string,
    additionalLink: string,
    additionalLinkTitle2: string,
    additionalLink2: string,
    location: string,
    newTagString: string,
    tagAddMode: boolean,
    isWindows: boolean,
    profileMode: string
}

interface ProfileEditProps {

}

export interface ProfileEdit extends React.Component {
    state: ProfileEditState;
    props: ProfileEditProps;
    contextType: AppContextInterface;
    tagInputRef: React.RefObject<HTMLInputElement>;
}

export class ProfileEdit extends Component {
    static contextType = AppCtx;
    context!: React.ContextType<typeof AppCtx>;

    constructor(props: ProfileEditProps) { 
        super(props);
        let contextLanguage = "de";
        if (this.context) {
            if (this.context.language !== null && this.context.language !== "") {
                contextLanguage = this.context.language;
            }
        }

        this.state = {
            errorMessage: "",
            infoMessage: "",
            language: contextLanguage,
            userName: "",
            birthday: null,
            hairColor: "",
            eyeColor: "",
            height: 0,
            breastSize: 0,
            cupSize: "",
            descriptionDE: "",
            descriptionEN: "",
            softDescriptionDE: "",
            softDescriptionEN: "",
            smallImageURL: "",
            softSmallImageURL: "",
            bigImageURL: "",
            softBigImageURL: "",
            smallImageMediaID: "",
            softSmallImageMediaID: "",
            bigImageMediaID: "",
            softBigImageMediaID: "",
            loading: false,
            imageModalOpen: false,
            imageModalMode: "",
            tags: [],
            lastUpdateStatus: 0,
            lastUpdateMessage: "",
            website: "",
            additionalLink: "",
            additionalLink2: "",
            websiteTitle: "",
            additionalLinkTitle: "",
            additionalLinkTitle2: "",
            location: "",
            newTagString: "",
            tagAddMode: false,
            isWindows: navigator.platform.indexOf("Win") > -1,
            profileMode: "soft"
        }

        registerLocale('de', de);
        registerLocale('en', en);

        if(contextLanguage === "de") {
            setDefaultLocale('de');
        } else {
            setDefaultLocale('en');
        }

        this.tagInputRef = React.createRef();
    }

    componentDidMount() {
        this.fetchProfileInfo();
    }

    fetchProfileInfo = async() => {
        this.setState({loading: true});

        let myImageURL:string = "";
        let jsonurl:string = ApiHelper.getAPIUrl(this.context) + "/profile";

        if(this.context) {
            if(!this.context.loggedin) {
                await ApiHelper.checkLoginStatus(this.context);
            }
            jsonurl += "/" + encodeURIComponent(this.context.producerID);
            jsonurl += "?authToken=" + encodeURIComponent(this.context.authToken);
            jsonurl += "&role=producer";
            jsonurl += "&viewerID=" + encodeURIComponent(this.context.producerID);
        } else {
            this.setState({errorMessage: "No context"});
            return;
        }

        this.setState({loading: true});

        try {
            let response = await fetch(jsonurl);
            let data = await response.json();
            this.setState({loading: false});

            if(data.lastUpdateStatus != null) {
                this.setState({lastUpdateStatus: data.lastUpdateStatus});
            }

            if(data.lastUpdateMessage != null) {
                this.setState({lastUpdateMessage: data.lastUpdateMessage});
            }
    
            if(data.lastUpdate != null) {
                if(data.lastUpdate.descriptionDE != null) {
                    this.setState({descriptionDE: data.lastUpdate.descriptionDE ? data.lastUpdate.descriptionDE : ""});
                } else {
                    this.setState({descriptionDE: data.girl.descriptionDE ? data.girl.descriptionDE : ""});
                }
                if(data.lastUpdate.descriptionEN != null) {
                    this.setState({descriptionEN: data.lastUpdate.descriptionEN ? data.lastUpdate.descriptionEN : ""});
                } else {
                    this.setState({descriptionEN: data.girl.descriptionEN ? data.girl.descriptionEN : ""});
                }
                if(data.lastUpdate.softDescriptionDE != null) {
                    this.setState({softDescriptionDE: data.lastUpdate.softDescriptionDE ? data.lastUpdate.softDescriptionDE : ""});
                } else {
                    this.setState({softDescriptionDE: data.girl.softDescriptionDE ? data.girl.softDescriptionDE : ""});
                }
                if(data.lastUpdate.softDescriptionEN != null) {
                    this.setState({softDescriptionEN: data.lastUpdate.softDescriptionEN ? data.lastUpdate.softDescriptionEN : ""});
                } else {
                    this.setState({softDescriptionEN: data.girl.softDescriptionEN ? data.girl.softDescriptionEN : ""});
                }
                if(data.lastUpdate.smallImageMediaID != null) {
                    this.setState({smallImageMediaID: data.lastUpdate.smallImageMediaID});
                } else {
                    this.setState({smallImageMediaID: data.girl.smallImageMediaID});
                }
                if(data.lastUpdate.bigImageMediaID != null) {
                    this.setState({bigImageMediaID: data.lastUpdate.bigImageMediaID});
                } else {
                    this.setState({bigImageMediaID: data.girl.bigImageMediaID});
                }
                if(data.lastUpdate.softSmallImageMediaID != null) {
                    this.setState({softSmallImageMediaID: data.lastUpdate.softSmallImageMediaID});
                } else {
                    this.setState({softSmallImageMediaID: data.girl.softSmallImageMediaID});
                }
                if(data.lastUpdate.softBigImageMediaID != null) {
                    this.setState({softBigImageMediaID: data.lastUpdate.softBigImageMediaID});
                } else {
                    this.setState({softBigImageMediaID: data.girl.softBigImageMediaID});
                }
    
                if(data.lastUpdate.height != null) {
                    this.setState({height: data.lastUpdate.height});
                } else {
                    this.setState({height: data.girl.height});
                }
                if(data.lastUpdate.breastSize != null) {
                    this.setState({breastSize: data.lastUpdate.breastSize});
                } else {
                    this.setState({breastSize: data.girl.breastSize});
                }
                if(data.lastUpdate.cupSize != null) {
                    this.setState({cupSize: data.lastUpdate.cupSize});
                } else {
                    this.setState({cupSize: data.girl.cupSize});
                }
    
                if(data.lastUpdate.hairColor != null) {
                    this.setState({hairColor: data.lastUpdate.hairColor});
                } else {
                    this.setState({hairColor: data.girl.hairColor});
                }
                if(data.lastUpdate.eyeColor != null) {
                    this.setState({eyeColor: data.lastUpdate.eyeColor});
                } else {
                    this.setState({eyeColor: data.girl.eyeColor});
                }
                if(data.lastUpdate.websiteTitle != null) {
                    this.setState({websiteTitle: data.lastUpdate.websiteTitle});
                } else {
                    this.setState({websiteTitle: data.girl.websiteTitle});
                }
                if(data.lastUpdate.website != null) {
                    this.setState({website: data.lastUpdate.website});
                } else {
                    this.setState({website: data.girl.website});
                }
                if(data.lastUpdate.additionalLinkTitle != null) {
                    this.setState({additionalLinkTitle: data.lastUpdate.additionalLinkTitle});
                } else {
                    this.setState({additionalLinkTitle: data.girl.additionalLinkTitle});
                }
                if(data.lastUpdate.additionalLink != null) {
                    this.setState({additionalLink: data.lastUpdate.additionalLink});
                } else {
                    this.setState({additionalLink: data.girl.additionalLink});
                }
                if(data.lastUpdate.additionalLinkTitle2 != null) {
                    this.setState({additionalLinkTitle2: data.lastUpdate.additionalLinkTitle2});
                } else {
                    this.setState({additionalLinkTitle2: data.girl.additionalLinkTitle2});
                }
                if(data.lastUpdate.additionalLink2 != null) {
                    this.setState({additionalLink2: data.lastUpdate.additionalLink2});
                } else {
                    this.setState({additionalLink2: data.girl.additionalLink2});
                }
                if(data.lastUpdate.location != null) {
                    this.setState({location: data.lastUpdate.location});
                } else {
                    this.setState({location: data.girl.location});
                }
                try {
                    if(data.lastUpdate.birthday !== null) {
                        this.setState({birthday: new Date(data.lastUpdate.birthday)});
                    } else {
                        this.setState({birthday: null});
                    }
                } catch(e:any) {
                    this.setState({birthday: null});
                }
                if(data.lastUpdate.smallImageURL != null) {
                    this.setState({smallImageURL: data.lastUpdate.smallImageURL});
                } else {
                    if(data.girl.smallImageURL != null) {
                        this.setState({smallImageURL: data.girl.smallImageURL});
                    } else {
                        this.setState({smallImageURL: "https://placehold.co/160"});
                    }
                }
                if(data.lastUpdate.bigImageURL != null) {
                    this.setState({bigImageURL: data.lastUpdate.bigImageURL});
                } else {
                    if(data.girl.bigImageURL != null) {
                        this.setState({bigImageURL: data.girl.bigImageURL});
                    } else {
                        this.setState({bigImageURL: "https://placehold.co/789x394"});
                    }
                }
                if(data.lastUpdate.softSmallImageURL != null) {
                    this.setState({softSmallImageURL: data.lastUpdate.softSmallImageURL});
                } else {
                    this.setState({softSmallImageURL: "https://placehold.co/160"});
                }
                if(data.lastUpdate.softBigImageURL != null) {
                    this.setState({softBigImageURL: data.lastUpdate.softBigImageURL});
                } else {
                    this.setState({softBigImageURL: "https://placehold.co/789x394"});
                }
                if(data.lastUpdate.tags != null) {
                    this.setState({tags: data.lastUpdate.tags});
                } else {
                    this.setState({tags: data.girl.tags});
                }
            } else {
                if(data.girl) {
                    this.setState({descriptionDE: data.girl.descriptionDE ? data.girl.descriptionDE : ""});
                    this.setState({descriptionEN: data.girl.descriptionEN ? data.girl.descriptionEN : ""});
                    this.setState({softDescriptionDE: data.girl.softDescriptionDE ? data.girl.softDescriptionDE : ""});
                    this.setState({softDescriptionEN: data.girl.softDescriptionEN ? data.girl.softDescriptionEN : ""});
                    this.setState({smallImageMediaID: data.girl.smallImageMediaID});
                    this.setState({bigImageMediaID: data.girl.bigImageMediaID});
                    this.setState({softSmallImageMediaID: data.girl.softSmallImageMediaID});
                    this.setState({softBigImageMediaID: data.girl.softBigImageMediaID});
                    this.setState({height: data.girl.height});
                    this.setState({breastSize: data.girl.breastSize});
                    this.setState({cupSize: data.girl.cupSize});
                    this.setState({hairColor: data.girl.hairColor});
                    this.setState({eyeColor: data.girl.eyeColor});
                    this.setState({websiteTitle: data.girl.websiteTitle});
                    this.setState({website: data.girl.website});
                    this.setState({additionalLinkTitle: data.girl.additionalLinkTitle});
                    this.setState({additionalLink: data.girl.additionalLink});
                    this.setState({additionalLinkTitle2: data.girl.additionalLinkTitle2});
                    this.setState({additionalLink2: data.girl.additionalLink2});
                    this.setState({location: data.girl.location});
                    try {
                        if(data.girl.birthday !== null) {
                            this.setState({birthday: new Date(data.girl.birthday)});
                        } else {
                            this.setState({birthday: null});
                        }
                    } catch(e:any) {
                        this.setState({birthday: null});
                    }
                    if(data.girl.smallImageURL != null) {
                        this.setState({smallImageURL: data.girl.smallImageURL});
                    } else {
                        this.setState({smallImageURL: "https://placehold.co/160"});
                    }
                    if(data.girl.bigImageURL != null) {
                        this.setState({bigImageURL: data.girl.bigImageURL});
                    } else {
                        this.setState({bigImageURL: "https://placehold.co/789x394"});
                    }
                    if(data.girl.softSmallImageURL != null) {
                        this.setState({softSmallImageURL: data.girl.softSmallImageURL});
                    } else {
                        this.setState({softSmallImageURL: "https://placehold.co/160"});
                    }
                    if(data.girl.softBigImageURL != null) {
                        this.setState({softBigImageURL: data.girl.softBigImageURL});
                    } else {
                        this.setState({softBigImageURL: "https://placehold.co/789x394"});
                    }
                    this.setState({tags: data.girl.tags});
                }
            }
        } catch(e:any) {
            this.setState({loading: false});
        }
    }

    imageModalChangeMediaAddMode(mode:string) {
        // do nothing
    }

    imageModalSelectMulti(images: MessagingPostMediaData[]) {
        // do nothing
    }

    imageClicked = async(img: MessagingPostMediaData) => {
        console.log("ProfileEdit imageClicked "+ this.state.imageModalMode + " " + img.mediaID);
        this.setState({imageModalOpen: false});

        if (this.state.imageModalMode === "big") {
            this.setState({bigImageMediaID: img.mediaID});
        } else if (this.state.imageModalMode === "bigsoft") {
            this.setState({softBigImageMediaID: img.mediaID});
        } else if (this.state.imageModalMode === "smallsoft") {
            this.setState({softSmallImageMediaID: img.mediaID});
        } else {
            this.setState({smallImageMediaID: img.mediaID});
        }

        let myImageURL:string = "";
        let jsonurl:string = ApiHelper.getAPIUrl(this.context) + "/image/get/" + img.mediaID + "?role=producer";

        if(this.context) {
            jsonurl += "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
            jsonurl += "&producerOrUserID=" + encodeURIComponent(this.context.producerID);

            if (this.state.imageModalMode === "big") {
                jsonurl += "&dimension=940x500";
            } else if (this.state.imageModalMode === "bigsoft") {
                jsonurl += "&dimension=940x500";
            } else if (this.state.imageModalMode === "smallsoft") {
                jsonurl += "&dimension=80x80";
            } else {
                jsonurl += "&dimension=80x80";
            }
        }

        this.setState({loading: true});
        let response = await fetch(jsonurl);
        let data = await response.json();
        this.setState({loading: false});

        if(data.image) {
            myImageURL = data.image.imageURL;
        } else {
            if(typeof(data.error) !== "undefined") {
                if(typeof(data.title) !== "undefined") {
                    this.setState({errorMessage: "No image URL returned " + data.title});
                } else {
                    this.setState({errorMessage: "No image URL returned"});
                }
            } else {
                this.setState({errorMessage: "No image URL returned " + data.error});
            }
        }

        if(myImageURL != "") {
            if (this.state.imageModalMode === "big") {
                this.setState({bigImageURL: myImageURL});
            } else if (this.state.imageModalMode === "bigsoft") {
                this.setState({softBigImageURL: myImageURL});
            } else if (this.state.imageModalMode === "smallsoft") {
                this.setState({softSmallImageURL: myImageURL});
            } else {
                this.setState({smallImageURL: myImageURL});
            }
        }
    }

    handleSubmit(e:React.FormEvent) {
        e.preventDefault();

        this.setState({loading: true});
        this.setState({errorMessage: ""});
        this.setState({infoMessage: ""});
        let jsonurl:string = ApiHelper.getAPIUrl(this.context) + "/profile";

        if(this.context) {
            jsonurl += "/" + encodeURIComponent(this.context.producerID);
            jsonurl += "?authToken=" + encodeURIComponent(this.context.authToken);
        }

        let myBody = {
            descriptionDE: this.state.descriptionDE,
            descriptionEN: this.state.descriptionEN,
            softDescriptionDE: this.state.softDescriptionDE,
            softDescriptionEN: this.state.softDescriptionEN,
            smallImageMediaID: this.state.smallImageMediaID,
            bigImageMediaID: this.state.bigImageMediaID,
            softSmallImageMediaID: this.state.softSmallImageMediaID,
            softBigImageMediaID: this.state.softBigImageMediaID,
            birthday: this.state.birthday !== null ? DateTime.fromISO(this.state.birthday.toISOString()).toFormat("MM-dd-yyyy") : null,
            hairColor: this.state.hairColor,
            eyeColor: this.state.eyeColor,
            height: isNaN(this.state.height) ? null : this.state.height,
            breastSize: isNaN(this.state.breastSize) ? null : this.state.breastSize,
            cupSize: this.state.cupSize,
            tags: this.state.tags,
            websiteTitle: this.state.websiteTitle,
            website: this.state.website,
            additionalLinkTitle: this.state.additionalLinkTitle,
            additionalLink: this.state.additionalLink,
            additionalLinkTitle2: this.state.additionalLinkTitle2,
            additionalLink2: this.state.additionalLink2,
            location: this.state.location
        }

        fetch(jsonurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(myBody)
        })
        .then(response => response.json())
        .then(data => {
            this.setState({loading: false});
            if(data.error) {
                this.setState({errorMessage: data.error});
            } else {
                this.setState({errorMessage: ""});
                if(data.result == "ok") {
                    this.setState({infoMessage: this.getText("ProfileSaved")});
                } else {
                    this.setState({infoMessage: data.result});
                }
            }
        })
        .catch((error) => {
            this.setState({loading: false});
            this.setState({errorMessage: error});
        });
    }

    handlePlusTag() {
        this.setState({tagAddMode: true});
        this.setState({newTagString: ""});
        setTimeout(this.focusTagInput.bind(this),300);
    }

    tagInputKeyDown(e:React.KeyboardEvent) {
        if(e.key === "Enter") {
            if(this.state.newTagString !== "") {
                this.setState({tags: [...this.state.tags, this.state.newTagString]});
                this.setState({tagAddMode: false});
            }
        }
        if(e.key === "Escape") {
            this.setState({tagAddMode: false});
        }
    }

    handleAddTag() {
        if(this.state.newTagString !== "") {
            this.setState({tags: [...this.state.tags, this.state.newTagString]});
            this.setState({tagAddMode: false});
        }
    }

    handleAddTagCancel() {
        this.setState({tagAddMode: false});
    }

    focusTagInput() {
        if(this.tagInputRef.current) {
            this.tagInputRef.current.focus();
        }
    }

    imageModalClosed() {
        this.setState({imageModalOpen: false});
    }

    bigImageClicked() {
        if(this.state.profileMode === "soft") {
            this.setState({imageModalOpen: true, imageModalMode: "bigsoft"});
        } else {
            this.setState({imageModalOpen: true, imageModalMode: "big"});
        }
    }

    smallImageClicked() {
        if(this.state.profileMode === "soft") {
            this.setState({imageModalOpen: true, imageModalMode: "smallsoft"});
        } else {
            this.setState({imageModalOpen: true, imageModalMode: "small"});
        }
    }

    tagClicked(tag:string) {
        console.log("tagClicked");
        let newArray:string[] = this.state.tags.filter((item) => item !== tag);
        this.setState({tags: newArray});
    }

    getText(exp: string) {
        if (this.state.language === "de") {
            if (exp === "CopyFromHard") {return "Aus dem FSK18-Profil kopieren";}
            if (exp === "CopyFromSoft") {return "Aus dem Softprofil kopieren";}
            if (exp === "EditSoftProfile") {return "Softprofil bearbeiten";}
            if (exp === "EditHardProfile") {return "FSK 18 Profil bearbeiten";}
            if (exp === "SwitchToSoftProfile") {return "Zum Softprofil wechseln";}
            if (exp === "SwitchToHardProfile") {return "Zum FSK 18 Profil wechseln";}
            if (exp === "ProfileSaved") {return "Die Änderungen wurden gespeichert, wir prüfen diese schnellstmöglich und übernehmen sie in Dein Profil";}
            if (exp === "Submit") {return "Speichern";}
            if (exp === "Name") {return "Name";}
            if (exp === "Name") {return "Vorname";}
            if (exp === "Strasse") {return "Strasse";}
            if (exp === "descriptionDE") {return "Dein Profiltext (deutsch)";}
            if (exp === "descriptionEN") {return "Dein Profiltext (englisch)";}
            if (exp === "softDescriptionDE") {return "Profiltext soft (deutsch)";}
            if (exp === "softDescriptionEN") {return "Profiltext soft (englisch)";}
            if (exp === "publicBirthday") {return "Dein Geburtstag (öffentlich)";}
            if (exp === "hairColor") {return "Haarfarbe";}
            if (exp === "eyeColor") {return "Augenfarbe";}
            if (exp === "height") {return "Grösse (in cm)";}
            if (exp === "breastSize") {return "Brustumfang";}
            if (exp === "cupSize") {return "Körbchengröße";}
            if (exp === "Change Background Image") {return "Bild im Hintergrund ändern";}
            if (exp === "AddTag") {return "Tag hinzufügen";}
            if( exp === "ProfileDescription") {return "Lade ein kleines Bild als Vorschau von Dir und ein Hintergrundbild hoch, dazu kannst Du noch ein wenig über Dich schreiben und ein paar Eckdaten eingeben.";}
            if( exp === "LastUpdateInReview") {return "Du hast ein Profil-Update eingereicht, das noch überprüft werden muss, aber Du kannst, wenn Du möchtest, zusätzliche Änderungen vornehmen ";}
            if( exp === "LastUpdateRejected") {return "Dein letztes Profi-Update wurde abgelehnt ";}
            if (exp === "WebsiteTitle") {return "Name Deiner Website";}
            if (exp === "Website") {return "URL Deiner Website";}
            if (exp === "AdditionalLinkTitle") {return "Name zusätzlicher Link";}
            if (exp === "AdditionalLink") {return "URL zusätzlicher Link";}
            if (exp === "AdditionalLinkTitle2") {return "Name zusätzlicher Link 2";}
            if (exp === "AdditionalLink2") {return "URL zusätzlicher Link 2";}
            if (exp === "Location") {return "Deine Stadt/Region";}
            if (exp === "OptionalInputs") {return "Optionale Profilangaben";}
            if (exp === "hidden") {return "versteckt";}
            if (exp === "ShowBirthday") {return "Geburtstag anzeigen";}
            if (exp === "HideBirthday") {return "Geburtstag verstecken";}
            if (exp === "EditProfile") {return "Profil editieren";}
        } else {
            if (exp === "CopyFromHard") {return "Copy from hard profile";}
            if (exp === "CopyFromSoft") {return "Copy from soft profile";}
            if (exp === "EditSoftProfile") {return "Edit your soft profile";}
            if (exp === "EditHardProfile") {return "Edit your hardcore profile";}
            if (exp === "SwitchToSoftProfile") {return "Switch to soft profile";}
            if (exp === "SwitchToHardProfile") {return "Switch to hardcore profile";}
            if (exp === "ProfileSaved") {return "Your changes have been saved, we will check them as soon as possible and apply them to your profile";}
            if (exp === "Submit") {return "Save";}
            if (exp === "Name") {return "Name";}
            if (exp === "Name") {return "Last name";}
            if (exp === "Strasse") {return "Street";}
            if (exp === "EditProfile") {return "Edit your profile";}
            if (exp === "descriptionDE") {return "Short text about you (german)";}
            if (exp === "descriptionEN") {return "Short text about you (english)";}
            if (exp === "softDescriptionDE") {return "Soft text about you (german)";}
            if (exp === "softDescriptionEN") {return "Soft text about  (englisch)";}
            if (exp === "publicBirthday") {return "Your birthday (public)";}
            if (exp === "hairColor") {return "Haircolor";}
            if (exp === "eyeColor") {return "Eyecolor";}
            if (exp === "height") {return "Height (in cm)";}
            if (exp === "breastSize") {return "Breastsize";}
            if (exp === "cupSize") {return "Cupsize";}
            if (exp === "AddTag") {return "Add Tag";}
            if( exp === "ProfileDescription") {return "Upload a small image as a preview of you and a background image, you can also write a little about yourself and enter a few details.";}
            if( exp === "LastUpdateInReview") {return "You have submitted a profile update that still needs to be reviewed, but you can, if you want, make additional changes ";}
            if( exp === "LastUpdateRejected") {return "Your last profile update was rejected ";}
            if (exp === "WebsiteTitle") {return "Name of your website";}
            if (exp === "Website") {return "URL of your website";}
            if (exp === "AdditionalLinkTitle") {return "Name of additional link";}
            if (exp === "AdditionalLink") {return "URL of additional link";}
            if (exp === "AdditionalLinkTitle2") {return "Name of additional link 2";}
            if (exp === "AdditionalLink2") {return "URL of additional link 2";}
            if (exp === "Location") {return "Your city/region";}
            if (exp === "OptionalInputs") {return "Optional profile inputs";}
            if (exp === "hidden") {return "hidden";}
            if (exp === "ShowBirthday") {return "Show Birthday";}
            if (exp === "HideBirthday") {return "Hide Birthday";}
        }

        return exp;
    }

    switchToHardProfile() {
        this.setState({profileMode: "hard"});
    }

    switchToSoftProfile() {
        this.setState({profileMode: "soft"});
    }

    copyProfileTextDEFromHard() {
        this.setState({softDescriptionDE: this.state.descriptionDE});
    }

    copyProfileTextENFromHard() {
        this.setState({softDescriptionEN: this.state.descriptionEN});
    }

    copyProfileTextDEFromSoft() {
        this.setState({descriptionDE: this.state.softDescriptionDE});
    }

    copyProfileTextENFromSoft() {
        this.setState({descriptionEN: this.state.softDescriptionEN});
    }

    dateDiffInDays(a:Date, b:Date) {
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
        // Discard the time and time-zone information.
        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
      
        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    }

    render () {
        return (
            <div className='mt-4'>
                <ImageModal singleSelect={true} open={this.state.imageModalOpen} mediaAddMode={this.state.imageModalMode} changeMediaAddMode={this.imageModalChangeMediaAddMode.bind(this)} itemClickCallback={this.imageClicked.bind(this)} closeCallback={this.imageModalClosed.bind(this)} itemSelectMulti={this.imageModalSelectMulti.bind(this)} />
                <Card>
                    <CardHeader>
                        {this.getText("EditProfile")}
                    </CardHeader>
                    <CardBody>
                        <p><small>{this.getText("ProfileDescription")}</small></p>
                        <div className="modeladvertorial_pad">
                            { this.state.loading ? <p className="pt-4"><FontAwesomeIcon icon={solid('cog')} spin size="4x" /></p> : null }
                            <div className="modeladvertorial_userinfo">
                                { this.state.profileMode === "soft" ?
                                <div className="modeladvertorial_user">
                                    <img style={{cursor: "pointer"}} onClick={this.smallImageClicked.bind(this)} src={this.state.softSmallImageURL} alt="SmallImageSoft" />
                                </div>
                                :
                                <div className="modeladvertorial_user">
                                    <img style={{cursor: "pointer"}} onClick={this.smallImageClicked.bind(this)} src={this.state.smallImageURL} alt="SmallImage" />
                                </div> }
                                { this.state.profileMode === "soft" ?
                                <div className="modeladvertorial_userinfoheader">
                                    <img style={{cursor: "pointer"}} onClick={this.bigImageClicked.bind(this)} src={this.state.softBigImageURL} alt="BigImageSoft" />
                                </div>
                                :
                                <div className="modeladvertorial_userinfoheader">
                                    <img style={{cursor: "pointer"}} onClick={this.bigImageClicked.bind(this)} src={this.state.bigImageURL} alt="BigImage" />
                                </div> }
                                { this.state.lastUpdateStatus != null ? this.state.lastUpdateStatus === 0 ? <Alert color="info">{this.getText("LastUpdateInReview")}</Alert> : this.state.lastUpdateStatus === -1 ? <Alert color="warning">{this.getText("LastUpdateRejected")}.{this.state.lastUpdateMessage}</Alert> : null : null }
                                <div className="modeladvertorial_userinfobody">
                                    <Row>
                                        <Col sm="12" md="6">
                                            <h4 className="modeladvertorial_hideiftoobig">
                                                <a href="#">
                                                    <span className='modealadvertorial_firstnamebig'>{this.context?.pseudo}</span>
                                                </a>
                                                { this.state.birthday != null ?
                                                <small className='modeladvertorial_jahre'> / {Math.floor(this.dateDiffInDays(this.state.birthday,new Date()) / 365)} Jahre</small>
                                                : null }
                                            </h4>
                                        </Col>
                                    </Row>
                                    <div className='modeladvertorial_makehigher'>
                                        <p>{this.state.profileMode === "soft" ? this.state.softDescriptionDE : this.state.descriptionDE }</p>
                                    </div>
                                    <div className='mb-2'>
                                        <Button onClick={this.handleSubmit.bind(this)} type="submit" color="primary">{this.getText("Submit")}</Button>&nbsp;
                                        { this.state.profileMode === "soft" ?
                                        <Button onClick={this.switchToHardProfile.bind(this)} color='secondary' type='button'>{this.getText("SwitchToHardProfile")}</Button>
                                        :
                                        <Button onClick={this.switchToSoftProfile.bind(this)} color='secondary' type='button'>{this.getText("SwitchToSoftProfile")}</Button>
                                        }
                                        &nbsp;<Button onClick={this.bigImageClicked.bind(this)} color='secondary' type='button'>{this.getText("Change Background Image")}</Button>
                                    </div>
                                    { this.state.errorMessage !== "" ? <Alert color="danger">{this.state.errorMessage}</Alert> : null }
                                    { this.state.infoMessage !== "" ? <Alert color="info">{this.state.infoMessage}</Alert> : null }
                                    <Row>
                                        <Col sm="12" md="6">
                                            { this.state.profileMode === "soft" ?
                                            <h4>{this.getText("EditSoftProfile")}</h4>
                                            :
                                            <h4>{this.getText("EditHardProfile")}</h4> 
                                            }
                                            { this.state.profileMode === "soft" ?
                                            <FormGroup>
                                                <Label for="softDescriptionDE">{this.getText("softDescriptionDE")} <Button size='sm' color='secondary' className='d-inline' onClick={this.copyProfileTextDEFromHard.bind(this)}>{this.getText("CopyFromHard")}</Button></Label>
                                                <Input type="textarea" name="softDescriptionDE" id="softDescriptionDE" value={this.state.softDescriptionDE} onChange={(e:React.ChangeEvent<HTMLInputElement>) => {this.setState({softDescriptionDE: e.target.value})}} />
                                            </FormGroup>
                                            :
                                            <FormGroup>
                                                <Label for="descriptionDE">{this.getText("descriptionDE")} <Button size='sm' color='secondary' className='d-inline' onClick={this.copyProfileTextDEFromSoft.bind(this)}>{this.getText("CopyFromSoft")}</Button></Label>
                                                <Input type="textarea" name="descriptionDE" id="descriptionDE" value={this.state.descriptionDE} onChange={(e:React.ChangeEvent<HTMLInputElement>) => {this.setState({descriptionDE: e.target.value})}} />
                                            </FormGroup> }
                                            { this.state.profileMode === "soft" ?
                                            <FormGroup>
                                                <Label for="softDescriptionEN">{this.getText("softDescriptionEN")} <Button size='sm' color='secondary' className='d-inline' onClick={this.copyProfileTextENFromHard.bind(this)}>{this.getText("CopyFromHard")}</Button></Label>
                                                <Input type="textarea" name="softDescriptionEN" id="softDescriptionEN" value={this.state.softDescriptionEN} onChange={(e:React.ChangeEvent<HTMLInputElement>) => {this.setState({softDescriptionEN: e.target.value})}} />
                                            </FormGroup> :
                                            <FormGroup>
                                                <Label for="descriptionEN">{this.getText("descriptionEN")} <Button size='sm' color='secondary' className='d-inline' onClick={this.copyProfileTextDEFromSoft.bind(this)}>{this.getText("CopyFromSoft")}</Button></Label>
                                                <Input type="textarea" name="descriptionEN" id="descriptionEN" value={this.state.descriptionEN} onChange={(e:React.ChangeEvent<HTMLInputElement>) => {this.setState({descriptionEN: e.target.value})}} />
                                            </FormGroup> }
                                            <p className='mt-1'>
                                            { this.state.isWindows && this.state.language === "de" ? <span className="helptextTimeline">Zeilenumbrüche kannst Du mit <kbd>Shift+Return</kbd> einfügen, für Smileys drücke <kbd>gleichzeitig Windows- und Punkt-Taste</kbd></span> : null }
                                            { this.state.isWindows && this.state.language === "en" ? <span className="helptextTimeline">Line-Breaks with <kbd>Shift+Return</kbd>, press <kbd>Windows+.</kbd> for Smileys</span> : null }
                                            </p>
                                            <hr />
                                            <Row>
                                                <Col sm="12">
                                                    <FormGroup>
                                                        <Label for="birthday">{this.getText("publicBirthday")} { this.state.birthday !== null ? <small>{DateTime.fromISO(this.state.birthday.toISOString()).toLocaleString(DateTime.DATE_SHORT)}</small> : <small>{this.getText("hidden")}</small>}</Label>
                                                        { this.state.birthday === null ? 
                                                            <div><Button size="sm" onClick={() => {this.setState({birthday: new Date(1990,1,1)})}}>{this.getText("ShowBirthday")}</Button></div> : 
                                                            <div>
                                                                <DatePicker selected={ this.state.birthday ? this.state.birthday : null } showMonthDropdown showYearDropdown dropdownMode="select" locale={this.state.language === 'de' ? de : en} onChange={(newDate:Date) => { this.setState({birthday: newDate})}} />
                                                                <Button size="sm" className='d-inline' onClick={() => {this.setState({birthday: null})}}>{this.getText("HideBirthday")}</Button>
                                                            </div>
                                                        }
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <hr />
                                            <h4>{this.getText("OptionalInputs")}</h4>
                                            <Row>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <Label for="hairColor">{this.getText("hairColor")}</Label>
                                                        <Input type="text" name="hairColor" id="hairColor" value={this.state.hairColor} onChange={(e:React.ChangeEvent<HTMLInputElement>) => {this.setState({hairColor: e.target.value})}} />
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <Label for="eyeColor">{this.getText("eyeColor")}</Label>
                                                        <Input type="text" name="eyeColor" id="eyeColor" value={this.state.eyeColor} onChange={(e:React.ChangeEvent<HTMLInputElement>) => {this.setState({eyeColor: e.target.value})}} />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <Label for="height">{this.getText("height")}</Label>
                                                        <Input type="text" name="height" id="height" value={this.state.height} onChange={(e:React.ChangeEvent<HTMLInputElement>) => {this.setState({height: e.target.value})}} />
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="3">
                                                    <FormGroup>
                                                        <Label for="breastSize">{this.getText("breastSize")}</Label>
                                                        <Input type="text" name="breastSize" id="breastSize" value={this.state.breastSize} onChange={(e:React.ChangeEvent<HTMLInputElement>) => {this.setState({breastSize: e.target.value})}} />
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="3">
                                                    <FormGroup>
                                                        <Label for="eyeColor">{this.getText("cupSize")}</Label>
                                                        <Input type="text" name="cupSize" id="cupSize" value={this.state.cupSize} onChange={(e:React.ChangeEvent<HTMLInputElement>) => {this.setState({cupSize: e.target.value})}} />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col sm="12" md="6">
                                            <h4>{this.getText("Tags")}</h4>
                                            { this.state.tagAddMode ? 
                                            <FormGroup>
                                                <Label for="newTag">{this.getText("NewTag")}</Label>
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">#</InputGroupAddon>
                                                    <input className='form-control' type="text" ref={this.tagInputRef} name="newTag" id="newTag" value={this.state.newTagString} onChange={(e:React.ChangeEvent<HTMLInputElement>) => {this.setState({newTagString: e.target.value})}} onKeyDown={this.tagInputKeyDown.bind(this)} />
                                                    <InputGroupAddon addonType="append">
                                                        <Button onClick={this.handleAddTag.bind(this)} type="button" variant="primary"><FontAwesomeIcon icon={solid("plus")}></FontAwesomeIcon></Button>
                                                    </InputGroupAddon>
                                                    <InputGroupAddon addonType="append">
                                                        <Button onClick={this.handleAddTagCancel.bind(this)} type="button" variant="primary"><FontAwesomeIcon icon={solid("xmark")}></FontAwesomeIcon></Button>
                                                    </InputGroupAddon>
                                                </InputGroup>
                                            </FormGroup> : null}
                                            <div className="modeladvertorial_tag">
                                                { this.state.tags.map((tag, index) => {
                                                    return <span onClick={this.tagClicked.bind(this,tag)} className="modeladvertorial_tagcolor1">#{tag}</span>
                                                })}
                                                <h5 onClick={this.handlePlusTag.bind(this)} className="modeladvertorial_plus">+</h5>
                                            </div>
                                            <hr />
                                            <FormGroup>
                                                <Label for="websiteTitle">{this.getText("WebsiteTitle")}</Label>
                                                <Input type="text" name="websiteTitle" id="websiteTitle" value={this.state.websiteTitle} onChange={(e:React.ChangeEvent<HTMLInputElement>) => {this.setState({websiteTitle: e.target.value})}} />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="website">{this.getText("Website")}</Label>
                                                <Input type="text" name="website" id="website" value={this.state.website} onChange={(e:React.ChangeEvent<HTMLInputElement>) => {this.setState({website: e.target.value})}} />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="additionalLinkTitle">{this.getText("AdditionalLinkTitle")}</Label>
                                                <Input type="text" name="AdditionalLinkTitle" id="AdditionalLinkTitle" value={this.state.additionalLinkTitle} onChange={(e:React.ChangeEvent<HTMLInputElement>) => {this.setState({additionalLinkTitle: e.target.value})}} />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="additionalLink">{this.getText("AdditionalLink")}</Label>
                                                <Input type="text" name="AdditionalLink" id="AdditionalLink" value={this.state.additionalLink} onChange={(e:React.ChangeEvent<HTMLInputElement>) => {this.setState({additionalLink: e.target.value})}} />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="additionalLinkTitle2">{this.getText("AdditionalLinkTitle2")}</Label>
                                                <Input type="text" name="AdditionalLinkTitle2" id="AdditionalLinkTitle2" value={this.state.additionalLinkTitle2} onChange={(e:React.ChangeEvent<HTMLInputElement>) => {this.setState({additionalLinkTitle2: e.target.value})}} />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="additionalLink2">{this.getText("AdditionalLink2")}</Label>
                                                <Input type="text" name="AdditionalLink2" id="AdditionalLink2" value={this.state.additionalLink2} onChange={(e:React.ChangeEvent<HTMLInputElement>) => {this.setState({additionalLink2: e.target.value})}} />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="location">{this.getText("Location")}</Label>
                                                <Input type="text" name="location" id="location" value={this.state.location} onChange={(e:React.ChangeEvent<HTMLInputElement>) => {this.setState({location: e.target.value})}} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </div>
        );
    }
}
