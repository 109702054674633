import React, { Component, FormEvent } from 'react';
import { AxiosResponse } from 'axios';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheckCircle, faCheck, faPencil, faArrowLeftLong } from '@fortawesome/pro-solid-svg-icons';
import { Card, CardBody, Container, CardHeader, FormGroup, Button, Label, Input, CardTitle, Col, CardFooter, Alert } from 'reactstrap';
import Moment from 'moment';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { AppCtx, AppContextInterface, ApiHelper } from '../AppContextInterface';

export interface ShopItem {

    amountAvailable?: number;
    amountSold?: number;
    articleName?: string;
    articleNameEN?: string;
    artikelnummer?: string;
    category?: number;
    centsAmount: number;
    descriptionLong?: string;
    descriptionShort?: string;
    digitalGood?: boolean;
    found: boolean;
    id: number;
    orderNum?: number;
    isActive?: boolean;
    previewImage?: string;
    producerID?: string;
    publishDate?: Date;
    userCanBuyThisArticle?: boolean;
    sortIndex?: number;
    centsAmountDouble?: string;
    videoHashtag?: string;
    descriptionShortEN?: string;
    descriptionLongEN?: string;
}

export interface ShopItemEditProps {
}

export interface ShopItemEdit {
    id: number;
    state: ShopItemEditState;
    props: ShopItemEditProps;
}

interface ShopItemEditState {
    id: number;
    entry: ShopItem;
    loading: boolean;

}

export class ShopItemEdit extends React.Component<ShopItemEditProps> {
    static contextType = AppCtx;
    context!: React.ContextType<typeof AppCtx>;

    constructor(props: ShopItemEditProps) {
        super(props);

        this.state = {
            id: 0,
            loading: true,
            entry: {
               found: false,
                id: 0,
                centsAmount: 0,
            }

        }

        library.add(faTimes);
        library.add(faCheck);
        library.add(faArrowLeftLong);
    }
    componentDidMount() {
        let myurl = window.location.pathname.split("/");

        if (myurl.length === 3) {
            this.setState({ id: Number(myurl[2]) });
            
            this.doFetchItem(myurl[2]);
        }
    }
    handleSubmit(event: FormEvent) {
        event.preventDefault();
        const formData = new FormData(event.currentTarget as HTMLFormElement)
        this.formSubmitted(formData);
    }

    formSubmitted = async (reqdata: FormData) => {

        this.setState({ loading: true });

        let jsonurl = ApiHelper.getAPIUrl(this.context) + "/shopitem?id=" + this.state.id;

        const response = await fetch(jsonurl, {
            method: 'post',
            body: reqdata,
        });
        const data = await response.json();
        this.setState({ entry: data, loading: false });

    }

    async doFetchItem(id:string) {
        this.setState({ loading: true });
        let jsonurl = ApiHelper.getAPIUrl(this.context) + "/shopitem?id=" + id;
        const response = await fetch(jsonurl);
        const data = await response.json();
        this.setState({ entry: data, loading: false });
        console.log(data);
    }


    render() {
        return (
            <Container fluid={false}>
            { !this.state.loading ?  
            <form className="form form-horizontal" onSubmit={this.handleSubmit.bind(this)}>
            <Card className="mt-3 mb-3">
                    <CardHeader>
                                <code>#{this.state.entry.id}</code>  {this.state.entry.articleName} bearbeiten <Link to={"/shop"} className="float-end btn btn-secondary btn-sm"><FontAwesomeIcon icon={["fas", "arrow-left-long"]}  />&nbsp;&nbsp;Zur Liste</Link>
                    </CardHeader>
                    <CardBody>

                        <div className="row">
                        <FormGroup>
                                <input type="checkbox" className="form-check-input"  id="active" name="active" defaultChecked={this.state.entry.isActive} />
                                <label className="form-check-label" htmlFor="active">
                                Dieser Artikel wird zum Verkauf angeboten
                            </label>
                        </FormGroup>
                         </div>
                        <div className="row">

                        <FormGroup>
                        <label htmlFor="Artikelname" className="col-3 col-form-label">Artikelname</label>
                        <div className="col-12">
                                <input type="text" className="form-control" id="Artikelname" name="Artikelname" placeholder="Artikelname" defaultValue={this.state.entry.articleName} />
                         </div>

                        </FormGroup>
                        </div>
                        <div className="row">
                            <FormGroup className="col-6">
                                <label htmlFor="Artikelnummer" className="col-3 col-form-label">Artikelnummer</label>
                                    <input type="text" className="form-control" id="Artikelnummer" name="Artikelnummer" placeholder="Artikelnummer" defaultValue={this.state.entry.artikelnummer} />

                            </FormGroup>
                            <FormGroup className="col-6">
                                    <label htmlFor="OrderNum" className="col-3 col-form-label">SortIndex</label>
                                    <input type="text" className="form-control" id="OrderNum" name="OrderNum" placeholder="OrderNum" defaultValue={this.state.entry.orderNum} />

                            </FormGroup>
                        </div>
                        <div className="row">

                            <FormGroup className="col-6">
                                <label htmlFor="AmountAvailable" className="col-3 col-form-label">Bestand</label>
                                <div className="col-12">
                                    <input type="text" className="form-control" id="AmountAvailable" name="AmountAvailable" placeholder="AmountAvailable" defaultValue={this.state.entry.amountAvailable} />
                                </div>
                            </FormGroup>
                            <FormGroup className="col-6">
                                <label htmlFor="CentsAmount" className="col-3 col-form-label">Preis</label>
                                <div className="col-12">
                                    <input type="text" className="form-control" id="CentsAmount" name="CentsAmount" placeholder="CentsAmount" defaultValue={this.state.entry.centsAmountDouble} />
                                </div>
                            </FormGroup>
                        </div>
                        <hr />
                        <div className="row">
                            <FormGroup className="col-6">
                                <label htmlFor="Artikelnummer" className="col-3 col-form-label">VideoHashtag</label>
                                <input type="text" className="form-control" id="VideoHashtag" name="VideoHashtag" placeholder="VideoHashtag" defaultValue={this.state.entry.videoHashtag} />

                            </FormGroup>
                            <FormGroup className="col-6">
                                        <label htmlFor="publishDate" className="col-3 col-form-label">Veröffentlichung</label>
                                        <input type="date" className="form-control" id="publishDate" name="publishDate" placeholder="publishDate" defaultValue={Moment(this.state.entry.publishDate).format('YYYY-MM-DD')} />

                            </FormGroup>

                        </div>
                        <hr />

                        <div className="row">
                            <FormGroup className="col-6">
                                <input type="checkbox" className="form-check-input" id="digitalGood" name="digitalGood" defaultChecked={this.state.entry.digitalGood } />
                                <label className="form-check-label"  htmlFor="digitalGood">
                                    Digitaler Artikel
                                </label>
                            </FormGroup>
                        </div>
                        <div className="row">
                            <FormGroup className="col-12">
                                <label htmlFor="DescriptionShort" className="col-3 col-form-label">Beschreibung kurz</label>
                                <textarea id="DescriptionShort" name="DescriptionShort" cols={20} rows={2} className="form-control" defaultValue={this.state.entry.descriptionShort}></textarea>
                            </FormGroup>
                        </div>
                        <div className="row">
                            <FormGroup className="col-12">
                                <label htmlFor="DescriptionLong" className="col-3 col-form-label">Beschreibung lang</label>
                                <textarea id="DescriptionLong" name="DescriptionLong" cols={20} rows={2} className="form-control" defaultValue={this.state.entry.descriptionLong}></textarea>
                            </FormGroup>
                        </div>
                        <div className="row">
                            <FormGroup>
                                        <label htmlFor="ArticleNameEN" className="col-3 col-form-label">Artikelname (EN)</label>
                                <div className="col-12">
                                            <input type="text" className="form-control" id="ArticleNameEN" name="ArticleNameEN" placeholder="ArticleNameEN" defaultValue={this.state.entry.articleNameEN} />
                                </div>

                            </FormGroup>
                        </div>

                        <div className="row">
                            <FormGroup className="col-12">
                                        <label htmlFor="descriptionShortEN" className="col-3 col-form-label">Beschreibung kurz (EN)</label>
                                        <textarea id="descriptionShortEN" name="descriptionShortEN" cols={20} rows={2} className="form-control" defaultValue={this.state.entry.descriptionShortEN}></textarea>
                            </FormGroup>
                        </div>
                        <div className="row">
                            <FormGroup className="col-12">
                                        <label htmlFor="descriptionLongEN" className="col-3 col-form-label">Beschreibung lang (EN)</label>
                                        <textarea id="descriptionLongEN" name="descriptionLongEN" cols={20} rows={2} className="form-control" defaultValue={this.state.entry.descriptionLongEN}></textarea>
                            </FormGroup>
                        </div>

                                <hr />
                        <FormGroup className="col-12">
                            <label htmlFor="DescriptionLong" className="col-3 col-form-label">Neues Vorschaubild hochladen</label>
                            <div className="col-12">
                                <input type="file" className="form-control" id="PreviewImage" name="PreviewImage" placeholder="PreviewImage"  />
                            </div>
                        </FormGroup>
                        <hr />

                        {this.state.entry.previewImage == "" ? <p>Kein Bild vorhanden</p> : <img src={'https://s3-eu-west-1.amazonaws.com/guppyshop/' + this.state.entry.previewImage} />}

                    </CardBody>
                    <CardFooter>
                                <Button type="submit" color="success"><FontAwesomeIcon icon={["fas", "check"]} />&nbsp;&nbsp;Speichern</Button> &nbsp;
                                <Link to={"/shop"} className="btn btn-secondary btn-md"><FontAwesomeIcon icon={["fas", "arrow-left-long"]} />&nbsp;&nbsp;Zur Liste</Link>
                    </CardFooter>
            </Card>
                </form>
                :
                <Alert>Daten werden geladen</Alert>
                }
                </Container>
        )
    }
}
