import React, { Component } from 'react';

import { AppCtx, AppContextInterface } from '../AppContextInterface';
import { Alert, Container, Form, FormGroup, Button, Label, Input, ButtonGroup, Dropdown, DropdownMenu, DropdownItem,NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

export class PrivacyPolicy extends Component {
    static contextType = AppCtx;
    context!: React.ContextType<typeof AppCtx>;

    static displayName = PrivacyPolicy.name;

    render () {
        return (
            <div>
                <h4>Datenschutzerklärung/-vereinbarung</h4>

                <p>&nbsp;</p>

                <h4>Datenerhebung</h4>

                <p>
                    Die ARTAGOS Medien- Design- Produktions GmbH erhebt und verwaltet  systembedingt Informationen über Art und Zeit des elektronischen Zugriffes auf unsere Webangebote, wie IP-Adresse, Betriebssystem, Datum und Uhrzeit des Zugriffes, Internet-Service-Provider um einen optimalen Betrieb unserer Angebote gewährleisten zu können.
                    Darüber hinaus erhebt und verwaltet die ARTAGOS Medien- Design- Produktions personenbezogene Daten wie Name, Adresse, Bankverbindung, Altersnachweis, Fotos und Steuerdaten, um Anbietern von Erotik-Chat-Cams die Möglichkeit zu geben, sich der Öffentlichkeit zu präsentieren und sich diese Leistungen vergüten zu lassen.
                    Zu diesem Zweck stellt die ARTAGOS Medien- Design- Produktions GmbH diese Daten der CAM-CONTENT S.L. zur Verfügung.
                    Wir weisen darauf hin, dass die Chatsysteme von der CAM-CONTENT S.L. betrieben werden. Bitte konsultieren Sie die <a href="https://signup.deine.fans/PrivacyPolicy.aspx" target="_blank">Datenschutzerklärung der CAM-CONTENT S.L.</a> um sich über den Umgang mit den Chatdaten zu informieren.
                    Mit dem Einverständnis zu dieser Datenschutzerklärung stimmen Sie ausdrücklich zu, dass die ARTAGOS Medien- Design- Produktions GmbH die Daten in der beschriebenen Weise weitergeben darf.
                </p>

                <h4>Umgang mit Foto und Videodaten</h4>
                <p>
                    Bei Fotos und Videos handelt es sich um personenbezogene Daten. Zu Werbezwecken gibt die ARTAGOS Medien- Design- Produktions GmbH von Ihnen dafür bereitgestellte Fotos und Videos (bereitgestellt durch Upload in Ihrem Senderportal) ausdrücklich an die CAM-CONTENT S.L., Partner der CAM-CONTENT S.L. und weitere Werbetreibende weiter. Dieser Weitergabe stimmen Sie durch Ihr Einverständnis zu dieser Erklärung ausdrücklich zu. Diese Einwilligung gilt ebenfalls als Zustimmung gemäß Ar9 DSGVO Absatz 2.a sollten diese Bilder oder Video-Daten Rückschlüsse auf ihre sexuelle Orientierung erlauben.
                </p>

                <h4>Datensicherung</h4>
                <p>
                    Alle erhobenen personenbezogenen Daten werden von der ARTAGOS Medien- Design- Produktions GmbH unter Berücksichtigung aktueller sicherheitstechnischer Standards verschlüsselt und geschützt.
                </p>

                <h4>Offenlegung gegenüber Dritten</h4>
                <p>
                    ARTAGOS Medien- Design- Produktions GmbH verkauft oder vermietet keine persönlichen Daten an Dritte. Eine Offenlegung erfolgt nur im Rahmen von gesetzlichen Vorgaben zu den nachfolgend aufgeführten Zwecken:
                    <ul>
                        <li>
                            Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a DSGVO ausdrückliche Einwilligung dazu erteilt haben,
                        </li>
                        <li>
                            die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben,

                        </li>
                        <li>
                            für den Fall, dass für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c DSGVO eine gesetzliche Verpflichtung besteht, sowie
                        </li>
                        <li>
                            dies gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1 lit. b DSGVO für die Abwicklung von Vertragsverhältnissen mit Ihnen erforderlich ist.
                        </li>
                    </ul>
                    Für die Speicherung, Sicherung und Vorhaltung Ihrer und unserer Daten sowie allen dazugehörigen Dienstleistungen sowie die Auszahlung ihrer Vergütung haben wir externe Dienstleister beauftragt und mit ihnen einen Vertrag zur Auftragsverarbeitung abgeschlossen und alle weiteren gesetzlichen Vorgaben zur Auftragsverarbeitung eingehalten.
                </p>
                <h4>Rankings</h4>
                <p>
                    Für die Positionierung der Livecam auf unseren Cam-Portalen werden Algorithmen eingesetzt, die unter anderen auch Performance-Daten berücksichtigen und so einen indirekten Rückschluss auf den Erfolg eines Producers zulassen.<br />
                    Im Rahmen von Wettbewerben sowie zur Qualitätssteigerung werden Rankings erstellt, die den eigenen Erfolg im Bezug auf ein definiertes Qualitätskriterium im direkten Vergleich mit anderen Producern anzeigen. Beispielhaft erzielte Coins in einem bestimmten Zeitraum. Hierbei wird nur das Pseudonym des Producers in Relation zu anderen Producern angezeigt. Sie können sich jederzeit durch einen einfachen Klick oder eine Meldung an den Support von diesen Ranking-Listen entfernen lassen.
                </p>

                <h4>Löschung</h4>
                <p>
                    Bei Inaktivität des Senderaccounts (in der Regel nach einem Jahr ohne Login) löscht die ARTAGOS Medien- Design- Produktions GmbH automatisch alle Daten des Accounts. Ausgenommen davon sind Daten, die gesetzlichen Sperrfristen unterliegen, sowie Video- und Fotodaten die bereits zu Werbezwecken weitergegeben wurden. Es werden dann allerdings keine weiteren Foto- oder Videodaten zu Werbezwecken weitergegeben.
                </p>

                <h4>Recht auf Löschung</h4>
                <p>
                    Sie können dieser Einverständniserklärung jederzeit widersprechen und die von ihnen gespeicherten Daten löschen lassen. Die ARTAGOS Medien- Design- Produktions GmbH wird alle Daten löschen, die nicht auf Grundlage von juristischen Zwängen (etwa gesetzliche Aufbewahrungspflichten) einer Löschung entgegenstehen.
                </p>
                <h4>Recht auf Widerspruch</h4>
                <p>
                    Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung sie betreffender personenbezogener Daten, die aufgrund von Artikel 6 Absatz 1 Buchstaben e oder f erfolgt, Widerspruch einzulegen. Hierzu richten Sie ihren Widerspruch per Mail  an datenschutz@artagos.de.
                </p>
                <h4>Weitere Rechte</h4>
                <p>
                    Darüber hinaus können Sie jederzeit von ihren gesetzmäßigen rechten, insbesondere dem Recht auf Auskunft (Art 15 DSGVO), Berichtigung (Art. 16 DSGVO), Widerruf (Art 7 Abs. 3 DSGVO) wahrnehmen. Kontaktieren Sie hierzu datenschutz@artagos.de.
                </p>
                <h4>Kommunikation</h4>
                <p>
                    Mit der Anerkennung dieser Datenschutzvereinbarung willige ich ein, dass mich die ARTAGOS Medien- Design- Produktions GmbH mittels Telefon, E-Mail oder Post kontaktieren darf. Mir ist bewusst, dass ich diese Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen kann. Wir setzen Sie davon in Kenntnis, dass durch den Widerruf der Einwilligung die Rechtmäßigkeit, der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung, nicht berührt wird.
                </p>
                <h4>Google Analytics</h4>
                <p>
                    Die Tracking-Maßnahmen mit Google Analytics werden auf Grundlage des Art. 6 Abs. 1 S. 1 lit. f DSGVO durchgeführt. Mit den zum Einsatz kommenden Tracking-Maßnahmen wollen wir eine bedarfsgerechte Gestaltung und die fortlaufende Optimierung unserer Webseite sicherstellen. Zum anderen setzen wir die Tracking-Maßnahmen ein, um die Nutzung unserer Webseite statistisch zu erfassen und zum Zwecke der Optimierung unseres Angebotes für Sie auszuwerten. Diese Interessen sind als berechtigt im Sinne der vorgenannten Vorschrift anzusehen.<br />
                    Zum Zwecke der bedarfsgerechten Gestaltung und fortlaufenden Optimierung unserer Seiten nutzen wir Google Analytics, ein Webanalysedienst der Google Ireland Limited https://www.google.de/intl/de/about/ (Gordon House, Barrow Street, Dublin 4, Irland; im Folgenden „Google“) sowie die Erweiterung Google Optimize. In diesem Zusammenhang werden pseudonymisierte Nutzungsprofile erstellt und Cookies (siehe unter Ziff. IV) verwendet. Die durch das Cookie erzeugten Informationen über Ihre Benutzung dieser Website wie
                    <ul>
                        <li>Browser-Typ/-Version,</li>
                        <li>verwendetes Betriebssystem,</li>
                        <li>Referrer-URL (die zuvor besuchte Seite),</li>
                        <li>Hostname des zugreifenden Rechners (IP-Adresse),</li>
                        <li>Uhrzeit der Serveranfrage,</li>
                    </ul>
                    werden an einen Server von Google in den USA übertragen und dort gespeichert. Die Informationen werden verwendet, um die Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu Zwecken der Marktforschung und bedarfsgerechten Gestaltung dieser Internetseiten zu erbringen. Auch werden diese Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben ist oder soweit Dritte diese Daten im Auftrag verarbeiten. Es wird in keinem Fall Ihre IP-Adresse mit anderen Daten von Google zusammengeführt. Die IP-Adressen werden anonymisiert, so dass eine Zuordnung nicht möglich ist (IP-Masking).
                    <br />
                    Sie können die Installation der Cookies durch eine entsprechende Einstellung der Browser-Software verhindern; wir weisen jedoch darauf hin, dass in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich genutzt werden können. Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie ein Browser-Add-on herunterladen und installieren https://tools.google.com/dlpage/gaoptout?hl=de.
                    <br />
                    Alternativ zum Browser-Add-on, insbesondere bei Browsern auf mobilen Endgeräten, können Sie die Erfassung durch Google Analytics zudem verhindern, indem Sie auf diesen Link klicken: Google Analytics deaktivieren. Es wird ein Opt-out-Cookie gesetzt, das die zukünftige Erfassung Ihrer Daten beim Besuch dieser Website verhindert. Der Opt-out-Cookie gilt nur in diesem Browser und nur für unsere Website und wird auf Ihrem Gerät abgelegt. Löschen Sie die Cookies in diesem Browser, müssen Sie das Opt-out-Cookie erneut setzen.
                    <br />
                    Weitere Informationen zum Datenschutz im Zusammenhang mit Google Analytics finden Sie etwa in der Google Analytics-Hilfe https://support.google.com/analytics/answer/6004245?hl=de.
                </p>
                <h4>Verantwortliche Stelle</h4>
                <p>
                    ARTAGOS Medien-Design-Produktions GmbH<br />
                    datenschutz@artagos.de
                </p>
            </div>
        );
    }
}
