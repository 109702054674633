import React, { Component, FormEvent } from 'react';
import { Alert, Container, Form, FormGroup, Button, Label, Input, InputGroup, InputGroupText, Badge, ButtonToolbar, ButtonGroup, Modal, ModalHeader, ModalBody, ModalFooter, Dropdown, DropdownMenu, DropdownItem } from 'reactstrap';
import { LocalStorageWorker } from '../StorageHelper';
import { CryptoHelper } from '../CryptoHelper.js';
import { AppCtx, AppContextInterface, ApiHelper } from '../AppContextInterface';
import { Redirect } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DateTime } from 'luxon';
import { VideoModal } from './VideoModal';
import { ImageModal } from './ImageModal';
import { MessagingPost,MessagingPostMediaData } from './Timeline';
import { faL } from '@fortawesome/pro-solid-svg-icons';
import { MessengerWrite } from './MessengerWrite';
import SmallMediaPreview from './SmallMediaPreview';
import { v4 as uuidv4 } from 'uuid';

interface InboxProps {

}

export interface Inbox extends React.Component {
    state: InboxState;
    props: InboxProps;
    contextType: AppContextInterface;
}

interface InboxState {
    errorMessage: string;
    language: string;
    messages: InboxMail[];
    fetchingMessages: boolean;
    currentFolder: string;
    replyingToMsg: InboxMail|null;
    msgActionInProgress: boolean;
    msgText:string;
    freeMedia: MessagingPostMediaData[];
    payMedia: MessagingPostMediaData[];
    msgToDelete:string;
    mediaAddMode: string;
    imageModal: boolean;
    videoModal: boolean;
    audioModal: boolean;
    priceModal: boolean;
    price: number;
    clickedMediaData: MessagingPostMediaData|null;
    priceInputEuro: number;
    priceInputCents: number;
    smallPreviewDimension: string;
    mediumPreviewDimension: string;
    singleImagePreviewDimension: string;
    clickedMediaDeletable: boolean;
    pageCounter: number;
    videoPlayURL: string;
    videoPlayURLError: string;
    singleVideoAPIAction: boolean;
}

interface InboxMail {
    msgID: string;
    sendTime: Date;
    clickTime: Date;
    content: string;
    sender: string;
    receiver: string;
    msgtext: string;
    freeMedia: MediaData[];
    payMedia: BuyMediaData[];
    price: number;
    replyToMsgID: string;
    responses: InboxMail[];
    paidSendPrice: number|null;
}

export interface MediaData {
    type:string;
    mediaID:string;
    reviewStatus:number;
}
export interface BuyMediaData {
    type:string;
    mediaID:string;
    reviewStatus:number;
}

export class Inbox extends React.Component {
    static contextType = AppCtx;
    context!: React.ContextType<typeof AppCtx>;

    constructor(props: InboxProps) {
        super(props);

        let contextLanguage = "de";

        if (this.context) {
            if (this.context.language !== null && this.context.language !== "") {
                contextLanguage = this.context.language;
            }
        }

        this.state = {
            errorMessage: "",
            fetchingMessages: false,
            language: contextLanguage,
            messages: [],
            currentFolder: "inbox",
            replyingToMsg: null,
            msgActionInProgress: false,
            msgText: "",
            freeMedia: [],
            payMedia: [],
            msgToDelete: "",
            mediaAddMode: "",
            imageModal: false,
            videoModal: false,
            audioModal: false,
            priceModal: false,
            price: 0,
            clickedMediaData: null,
            priceInputEuro: 0,
            priceInputCents: 0,
            smallPreviewDimension: "80x80xinside",
            mediumPreviewDimension: "300x300xinside",
            singleImagePreviewDimension: "480x480xinside",
            clickedMediaDeletable: false,
            pageCounter: 0,
            videoPlayURL: "",
            videoPlayURLError: "",
            singleVideoAPIAction: false
        }
    }

    componentDidMount() {
        this.fetchMessages(this.state.currentFolder,0);
    }

    getText(exp: string) {
        if (this.state.language === "de") {
            if(exp === "MarkRead") {
                return "Gelesen";
            }
            if(exp === "Reply") {
                return "Antworten";
            }
            if(exp === "Delete") {
                return "Löschen";
            }
            if(exp === "image") {
                return "Bild";
            }
            if(exp === "video") {
                return "Video";
            }
            if(exp === "audio") {
                return "Audio";
            }
            if (exp === "Username") {
                return "Pseudo";
            }
            if (exp === "inbox") {
                return "Posteingang";
            }
            if (exp === "sentmessages") {
                return "Gesendete Nachrichten";
            }
            if(exp === "ConfirmDelete") {
                return "Ja, Nachricht löschen";
            }
            if(exp === "Cancel") {
                return "Abbrechen";
            }
            if(exp === "Price") {
                return "Preis";
            }
            if(exp === "RedBorderedImages") {
                return "Rot umrandete Bilder/Videos müssen noch freigeschaltet werden, bevor sie den Usern angezeigt werden.";
            }
            if(exp === "Wirklich Nachricht löschen?") {
                return "Wirklich Nachricht löschen?";
            }
            if(exp === "Preis festlegen") {
                return "Preis festlegen";
            }
            if(exp === "Anhänge") {
                return "Anhänge";
            }
            if(exp === "OrangeOutlinedItemsHaveToBeReviewed") {
                return "(orange umrandete Inhalte müssen noch freigeschaltet werden, bevor sie angezeigt werden)";
            }
            if(exp === "Kostenlose Inhalte") {
                return "Kostenlose Inhalte";
            }
            if(exp === "Kostenpflichtige Inhalte") {
                return "Kostenpflichtige Inhalte";
            }
            if(exp === "InboxDescription") {
                return "Hier kannst du Nachrichten von Deinen Usern lesen und beantworten, Du kannst auch kostenpflichtige Inhalte in Nachrichten versenden.";
            }
            if(exp === "SentMessagesDescription") {
                return "Hier kannst du Nachrichten lesen, die Du an Deine User geschickt hast.";
            }
        } else {
            if(exp === "image") {
                return "Bild";
            }
            if(exp === "video") {
                return "video";
            }
            if(exp === "audio") {
                return "audio";
            }
            if (exp === "Username") {
                return "Livecam Profile";
            }
            if (exp === "inbox") {
                return "Inbox";
            }
            if (exp === "sentmessages") {
                return "Sent Messages";
            }
            if(exp === "ConfirmDelete") {
                return "Yes, delete message";
            }
            if(exp === "Wirklich Nachricht löschen?") {
                return "Really delete message?";
            }
            if(exp === "Preis festlegen") {
                return "Set price";
            }
            if(exp === "Preis") {
                return "Price";
            }
            if(exp === "Anhänge") {
                return "Attachments";
            }
            if(exp === "OrangeOutlinedItemsHaveToBeReviewed") {
                return "(orange outlined items have to be reviewed, before they will be shown to users)";
            }
            if(exp === "Kostenlose Inhalte") {
                return "Free content";
            }
            if(exp === "Kostenpflichtige Inhalte") {
                return "Pay content";
            }
            if(exp === "RedBorderedImages") {
                return "Red bordered images/videos have to be reviewed, before users will be able to see them.";
            }
            if(exp === "Bild aus der Nachricht löschen") {
                return "Delete image from message";
            }
            if(exp === "InboxDescription") {
                return "Here you can read messages from your users and reply to them. You can also send pay content in messages.";
            }
            if(exp === "SentMessagesDescription") {
                return "Here you can read messages that you sent to your users.";
            }
        }

        return exp;
    }

    changeFolder(newfolder:string) {
        this.setState({msgToDelete: ""});
        this.setState({replyingToMsg: null});
        this.setState({currentFolder: newfolder});
        this.fetchMessages(newfolder,this.state.pageCounter);
    }

    fetchMessages = async (folder:string,pageCounter:number = 0) => {
        this.setState({ fetchingMessages: true });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/inboxgirl";

        if (this.context) {
            if(!this.context.loggedin) {
                await ApiHelper.checkLoginStatus(this.context);
            }
            jsonurl += "?producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        } else {
            jsonurl += "?producerID=missingcontext";
        }
        jsonurl += "&folder=" + encodeURIComponent(folder);

        let response: any = null;

        try {
            response = await fetch(jsonurl);
            let data = await response.json();
            this.setState({ fetchingMessages: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                    if(!ApiHelper.checkResponse(data,this.context) && this.context !== null) {
                        this.context.producerID = "";
                        alert("Please relogin");
                    }
                }
            }

            if (data.messages) {
                this.setState({ messages: data.messages },this.fetchRealImageURLs.bind(this));
            }
        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching data: " + error.toString() });
            this.setState({ fetchingMessages: false });
        }
    }

    markMessageRead(msg:InboxMail) {
        if(msg.clickTime === null) {
            msg.clickTime = new Date();
            this.setState({messages: this.state.messages});
            this.markMessageReadOnServer(msg.msgID);
        }
    }

    markMessageReadOnServer = async(msgid:string) => {
        this.setState({ msgActionInProgress: true });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/inboxgirl";

        if (this.context) {
            jsonurl += "?producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        } else {
            jsonurl += "?producerID=missingcontext";
        }
        jsonurl += "&msgID=" + encodeURIComponent(msgid);

        let response: any = null;

        try {
            response = await fetch(jsonurl,{method: 'PUT'});
            let data = await response.json();
            this.setState({ msgActionInProgress: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                    if(!ApiHelper.checkResponse(data,this.context) && this.context !== null) {
                        this.context.producerID = "";
                        alert("Please relogin");
                    }
                }
            }

            if(this.context) {
                this.context.doUpdateUnread = true;
            }
        } catch (error: any) {
            this.setState({ errorMessage: "Exception marking message as read: " + error.toString() });
            this.setState({ msgActionInProgress: false });
        }
    }

    replyMessage(msg:InboxMail) {
        this.setState({ errorMessage: "" });
        this.setState({replyingToMsg: msg});
        setTimeout(this.setFocusInReply.bind(this),1000);
    }

    setFocusInReply() {
        var replyInput = document.getElementById("txtMessage");
        if(replyInput) {
            replyInput.focus();
        }
    }

    cancelReply() {
        this.setState({ errorMessage: "" });
        this.setState({replyingToMsg: null});
        this.setState({freeMedia: []});
        this.setState({payMedia: []});
    }

    handleSubmit(event: FormEvent) {
        event.preventDefault();
        this.formSubmitted();
    }

    formSubmitted() {
        let myLocalStorage = new LocalStorageWorker();

        if (this.context === null) {
            return;
        }

        if(this.state.replyingToMsg === null) {
            return;
        }

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/messengergirl?producerid=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);

        jsonurl += "&receiver=" + encodeURIComponent(this.state.replyingToMsg.sender);
        jsonurl += "&replyToMsgID=" + encodeURIComponent(this.state.replyingToMsg.msgID);

        this.setState({ msgActionInProgress: true });

        let postObject:MessagingPost = {
            msgText: this.state.msgText,
            msgTitle: "",
            payText: "",
            price: this.state.price,
            tags: "",
            pinned: false,
            nonsub: false,
            freeMedia: this.state.freeMedia,
            payMedia: this.state.payMedia,
            quiz: null,
            poll: null,
            fundraise: null,
            publishTime: null,
            priceOverrides: null
        }

        fetch(jsonurl, {method: "POST",body: JSON.stringify(postObject), headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}}).then(async(response: any) => {
            let data = await response.json();

            if(typeof(data.title) !== "undefined") {
                this.setState({ errorMessage: "Problem sending message: " + data.title });
                this.setState({ msgActionInProgress: false });
                return;
            }

            if (data.result === "ok") {
                this.setState({msgActionInProgress: false });
                this.setState({replyingToMsg: null});
                this.fetchMessages(this.state.currentFolder,0);
                this.setState({ errorMessage: "" });
                this.setState({ msgText: "" });
                this.setState({ price: 0 });
                this.setState({ freeMedia: [] });
                this.setState({ payMedia: [] });
            } else {
                this.setState({ errorMessage: "Problem sending message: " + data.error });
                this.setState({ msgActionInProgress: false });
            }
        }).catch((error: any) => {
            this.setState({ errorMessage: "Error sending message: " + error.toString() });
            this.setState({ msgActionInProgress: false });
        });
    }

    deleteMessage = (msgid:string) => {
        this.setState({ msgToDelete: msgid });
    }

    cancelDelete = () => {
        this.setState({ msgToDelete: "" });
    }

    confirmDelete = async(msgid:string) => {
        this.setState({ msgActionInProgress: true });
        this.setState({ msgToDelete: "" });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/inboxgirl";

        if (this.context) {
            jsonurl += "?producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        } else {
            jsonurl += "?producerID=missingcontext";
        }
        jsonurl += "&msgID=" + encodeURIComponent(msgid);

        let response: any = null;

        try {
            response = await fetch(jsonurl,{method: 'DELETE'});
            let data = await response.json();
            this.setState({ msgActionInProgress: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                    if(!ApiHelper.checkResponse(data,this.context) && this.context !== null) {
                        this.context.producerID = "";
                        alert("Please relogin");
                    }
                }
            }

            if(this.context) {
                this.context.doUpdateUnread = true;
            }
            this.fetchMessages(this.state.currentFolder,0);
        } catch (error: any) {
            this.setState({ errorMessage: "Exception deleting message: " + error.toString() });
            this.setState({ msgActionInProgress: false });
        }
    }

    countFreeMediaImages() {
        return this.state.freeMedia.filter((item:MessagingPostMediaData) => { return item.type === "image" }).length;
    }

    countFreeMediaVideos() {
        return this.state.freeMedia.filter((item:MessagingPostMediaData) => { return item.type === "video" }).length;
    }

    countFreeMediaAudios() {
        return this.state.freeMedia.filter((item:MessagingPostMediaData) => { return item.type === "audio" }).length;
    }

    countPayMediaImages() {
        return this.state.payMedia.filter((item:MessagingPostMediaData) => { return item.type === "audio" }).length;
    }

    countPayMediaVideos() {
        return this.state.payMedia.filter((item:MessagingPostMediaData) => { return item.type === "audio" }).length;
    }

    countPayMediaAudios() {
        return this.state.payMedia.filter((item:MessagingPostMediaData) => { return item.type === "audio" }).length;
    }

    getSmallMediaPreviewUrl(item:MessagingPostMediaData) {
        return "https://placehold.co/80";
    }

    getMediumMediaPreviewUrl(item:MessagingPostMediaData) {
        return "https://placehold.co/200";
    }

    getSingleImagePreviewUrl(item:MessagingPostMediaData) {
        return "https://placehold.co/300";
    }

    getGuid() {
        let myguid:string = uuidv4();

        return myguid;
    }

    fetchRealImageURLs() {
        let producerID:string = "";
        let authToken:string = "";

        if(this.context) {
            producerID = this.context.producerID;
            authToken = this.context.authToken;
        }

        document.querySelectorAll('img[src="https://placehold.co/200"]').forEach((item: any) => {
            let myURL:string = ApiHelper.getAPIUrl(this.context) + "/" + item.getAttribute("x-mediatype") + "/get/" + item.getAttribute("x-mediaid") + "?role=producer&producerOrUserID=" + encodeURIComponent(producerID) + "&authToken=" + encodeURIComponent(authToken) + "&dimension=" + this.state.mediumPreviewDimension;

            fetch(myURL).then(async(response: any) => {
                let data = await response.json();

                if(data.image) {
                    item.src = data.image.imageURL;
                    if(data.image.reviewStatus === 1) {
                        item.classList.add("reviewed");
                    } else if(data.image.reviewStatus === -1) {
                        item.classList.add("rejected");
                    } else {
                        item.classList.add("inreview");
                    }
                }
            }).catch(function() {
                item.src = "https://placehold.co/200?Text=Error";
            });
        });

        document.querySelectorAll('img[src="https://placehold.co/300"]').forEach((item: any) => {
            let myURL:string = ApiHelper.getAPIUrl(this.context) + "/" + item.getAttribute("x-mediatype") + "/get/" + item.getAttribute("x-mediaid") + "?role=producer&producerOrUserID=" + encodeURIComponent(producerID) + "&authToken=" + encodeURIComponent(authToken) + "&dimension=" + this.state.singleImagePreviewDimension;

            fetch(myURL).then(async(response: any) => {
                let data = await response.json();

                if(data.image) {
                    item.src = data.image.imageURL;
                    if(data.image.reviewStatus === 1) {
                        item.classList.add("reviewed");
                    } else if(data.image.reviewStatus === -1) {
                        item.classList.add("rejected");
                    } else {
                        item.classList.add("inreview");
                    }
                }
            }).catch(function() {
                item.src = "https://placehold.co/300?Text=Error";
            });
        });
    }

    detectAndroid():boolean {
        var ua = navigator.userAgent.toLowerCase();
        var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
        return isAndroid;
    }

    detectiOS():boolean {
        return [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }

    fetchVideoPlayURL = async(clickedMediaData:MessagingPostMediaData|null) => {
        if(clickedMediaData === null) {
            return;
        }

        let mediaID:string = clickedMediaData.mediaID;

        this.setState({ singleVideoAPIAction: true });
        this.setState({ videoPlayURLError: "" });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/videoplay/" + encodeURIComponent(mediaID);

        if (this.context) {
            jsonurl += "?userOrProducerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken) + "&role=producer";
            if(this.detectiOS()) {
                jsonurl += "&playerType=hls";
            } else {
                jsonurl += "&playerType=mp4";
            }
            jsonurl += "&viewContextType=control";
            jsonurl += "&viewContextID=control";
        } else {
            alert("Cannot get video play url because of missing context");
        }

        let response: any = null;

        try {
            response = await fetch(jsonurl);
            let data = await response.json();
            this.setState({ singleVideoAPIAction: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ videoPlayURLError: "Error: " + data.error });
                    if(!ApiHelper.checkResponse(data,this.context) && this.context !== null) {
                        this.context.producerID = "";
                        alert("Please relogin");
                    }
                }
            }

            this.setState({ videoPlayURL: data.url });

        } catch (error: any) {
            this.setState({ videoPlayURLError: "Error fetching data: " + error.toString() });
            this.setState({ singleVideoAPIAction: false });
        }
    }

    freeMediaClicked(clickedMediaData:MessagingPostMediaData,deletable:boolean) {
        if(clickedMediaData) {
            this.setState({mediaAddMode: "free"});
            this.setState({clickedMediaData: clickedMediaData},this.fetchRealImageURLs.bind(this));
            this.setState({clickedMediaDeletable: deletable});
            setTimeout(this.fetchRealImageURLs.bind(this),1000);
            if(clickedMediaData.type === "video") {
                this.fetchVideoPlayURL(clickedMediaData);
            }
        } else {
            this.setState({errorMessage: "Invalid image clicked"});
        }
    }

    payMediaClicked(clickedMediaData:MessagingPostMediaData,deletable:boolean) {
        if(clickedMediaData) {
            this.setState({mediaAddMode: "pay"});
            this.setState({clickedMediaData: clickedMediaData},this.fetchRealImageURLs.bind(this));
            this.setState({clickedMediaDeletable: deletable});
            setTimeout(this.fetchRealImageURLs.bind(this),1000);
            if(clickedMediaData.type === "video") {
                this.fetchVideoPlayURL(clickedMediaData);
            }
        } else {
            this.setState({errorMessage: "Invalid image clicked"});
        }
    }

    closeClickedMediaData() {
        this.setState({clickedMediaData: null});
    }

    deleteClickedMediaData() {
        if(this.state.clickedMediaData !== null) {
            let myImages:MessagingPostMediaData[];
            if(this.state.mediaAddMode == "free") {
                myImages = this.state.freeMedia.filter((img:MessagingPostMediaData) => { return img.mediaID !== this.state.clickedMediaData?.mediaID });
                this.setState({freeMedia: myImages});
            } else {
                myImages = this.state.payMedia.filter((img:MessagingPostMediaData) => { return img.mediaID !== this.state.clickedMediaData?.mediaID });
                this.setState({payMedia: myImages});
            }
            this.setState({clickedMediaData: null});
        }
    }

    clickedMediaDataModalClose() {
        this.setState({clickedMediaData: null});
    }

    formatPrice(price:number) {
        return Math.floor(price / 100) + "," + (price % 100).toString().padStart(2, "0");
    }

    onMessageWriteCancel() {
        this.setState({replyingToMsg: null});
    }

    onMessageSent() {
        if(this.state.replyingToMsg !== null) {
            this.setState({replyingToMsg: null});
        }
        this.fetchMessages(this.state.currentFolder,0);
    }

    render() {
        if (this.context) {
            if (this.context.producerID === "") {
                return <Redirect to="/" push={true} />
            }
        } else {
            return <Redirect to="/" push={true} />
        }

        return (
            <div id='InboxDIV' className='d-flex flex-nowrap'>
                <Modal isOpen={this.state.clickedMediaData !== null} size="md" onClosed={this.clickedMediaDataModalClose.bind(this)}>
                    <ModalHeader close={<button className="close" onClick={this.clickedMediaDataModalClose.bind(this)}>×</button>}>
                        { this.state.clickedMediaData ? this.state.clickedMediaData.type : null }
                    </ModalHeader>
                    <ModalBody>
                    { this.state.singleVideoAPIAction ?
                    <div className="d-flex flex-column align-items-center justify-content-center w-100 h-100">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">{this.getText("Loading")}...</span>
                        </div>
                    </div>
                    :
                    this.state.videoPlayURL !== "" ? <video style={{width: "100%"}} controls autoPlay src={this.state.videoPlayURL} playsInline muted /> : 
                    this.state.clickedMediaData ? <img x-mediatype={this.state.clickedMediaData.type} x-mediaid={this.state.clickedMediaData.mediaID} src={this.getSingleImagePreviewUrl(this.state.clickedMediaData)} className="w-100" /> 
                    : null 
                    }
                    { this.state.videoPlayURLError !== "" ? <div className="alert alert-danger">{this.state.videoPlayURLError}</div> : null }
                    </ModalBody>
                    <ModalFooter>
                        { this.state.clickedMediaDeletable ?
                        <Button onClick={this.deleteClickedMediaData.bind(this)}>{this.getText("Bild aus der Nachricht löschen")}</Button> : null }
                        <Button onClick={this.closeClickedMediaData.bind(this)}>{this.getText("Close")}</Button>
                    </ModalFooter>
                </Modal>

                <div id="InboxMessagesPane" className="d-flex flex-column align-items-stretch flex-shrink-0 bg-white w-100">
                    <h4 className="pageHeadline">
                        {this.getText(this.state.currentFolder)}&nbsp;
                        <Button size="sm" className='ml-2' onClick={this.fetchMessages.bind(this, this.state.currentFolder, 0)}><FontAwesomeIcon icon={solid('sync')} fixedWidth /> {this.getText("Refresh")}</Button>
                    </h4>
                    { this.state.currentFolder === "inbox" ? <p className='border-bottom pb-2'><small>{this.getText("InboxDescription")}</small></p> : <p className='border-bottom pb-2'><small>{this.getText("SentMessagesDescription")}</small></p> }
                    <div className="mb-0 d-flex" style={{gap: "0.5rem"}}>
                        <Button disabled={this.state.fetchingMessages} size="sm" onClick={this.changeFolder.bind(this, "inbox")}><FontAwesomeIcon icon={solid('envelope')} fixedWidth /> {this.getText("inbox")}</Button>
                        <Button disabled={this.state.fetchingMessages} size="sm" onClick={this.changeFolder.bind(this, "sentmessages")}><FontAwesomeIcon icon={solid('message-dots')} fixedWidth /> {this.getText("sentmessages")}</Button>
                    </div>
                    {this.state.errorMessage !== "" ? <Alert color="danger">{this.state.errorMessage}</Alert> : null}
                    {this.state.replyingToMsg !== null ?
                        <MessengerWrite onCancel={this.onMessageWriteCancel.bind(this)} onMessageSent={this.onMessageSent.bind(this)} userPseudo={this.state.replyingToMsg.sender} conversationID="" replyToMsgID={this.state.replyingToMsg.msgID} targetListID="" />
                    : null }

                    { this.state.msgToDelete !== "" ?
                        <div id='MessengerConfirmDeleteDIV' className='container pt-4'>
                            <Alert color="warning">{this.getText("Wirklich Nachricht löschen?")}</Alert>
                            <div className="d-flex flex-row-reverse">
                                <Button variant="primary" disabled={this.state.msgActionInProgress} type="submit" onClick={this.confirmDelete.bind(this,this.state.msgToDelete)} size="sm">{this.getText("ConfirmDelete")}</Button>&nbsp;
                                <Button variant="secondary" disabled={this.state.msgActionInProgress} type="button" onClick={this.cancelDelete.bind(this)} size="sm">{this.getText("Cancel")}</Button>
                            </div>
                        </div>
                    : null }
                    <hr></hr>

                    {this.state.fetchingMessages ? <p className="pt-4"><FontAwesomeIcon icon={solid('cog')} spin size="4x" /></p> :
                        this.state.messages !== null ? <ul className="list-group list-group-flush border-bottom scrollarea">
                            {this.state.messages.map(msg =>
                                msg !== null ?
                                <div className="list-group-item list-group-item-action py-3 lh-sm">
                                    <div className="d-flex w-100 align-items-center justify-content-between flex-wrap">
                                        <strong className="mb-1">{msg.clickTime ? <FontAwesomeIcon icon={solid('envelope-open')} fixedWidth /> : <FontAwesomeIcon icon={solid('envelope')} fixedWidth />} {msg.sender}</strong>
                                        { msg.paidSendPrice !== null ? msg.paidSendPrice > 0 ? <small className='badge bg-secondary'>{new Number(msg.paidSendPrice / 100).toFixed(2)} <FontAwesomeIcon icon={solid('coins')} fixedWidth /></small> : null : null }
                                        <small>{DateTime.fromISO(msg.sendTime.toString()).toLocaleString(DateTime.DATETIME_MED)}</small>
                                    </div>
                                    <div className="col-10 mb-1 small">{msg.msgtext}</div>
                                    <div className="col-10 mb-1 small">
                                        {msg.clickTime === null ? <span><Button size="sm" onClick={this.markMessageRead.bind(this, msg)}><FontAwesomeIcon icon={solid('glasses')} fixedWidth /> {this.getText("MarkRead")}</Button>&nbsp;</span> : null }
                                        <Button size="sm" onClick={this.replyMessage.bind(this, msg)}><FontAwesomeIcon icon={solid('reply')} fixedWidth /> {this.getText("Reply")}</Button>&nbsp;
                                        <Button size="sm" onClick={this.deleteMessage.bind(this, msg.msgID)}><FontAwesomeIcon icon={solid('remove')} fixedWidth /> {this.getText("Delete")}</Button>
                                    </div>
                                    { msg.freeMedia.length > 0 ? 
                                    <div>
                                        <small><b>{this.state.currentFolder == "sentmessages" ? this.getText("Kostenlose Inhalte") : this.getText("Anhänge")}:</b></small><br />
                                        <div className="d-flex flex-wrap" style={{gap: "4px"}}>
                                            {msg.freeMedia.map((item,index) =>
                                                <SmallMediaPreview key={"SmallMediaPreviewFreeMessage-" + msg.msgID + "-" + index} context="message" contextID={msg.msgID} guid={this.getGuid()} type={item.type} mediaID={item.mediaID} reviewStatus={item.reviewStatus} clicked={this.freeMediaClicked.bind(this,item,false)} />
                                            )}
                                        </div>
                                    </div>
                                    : null }
                                    { msg.payMedia.length > 0 ? 
                                    <div>
                                        <h6>{this.getText("Kostenpflichtige Inhalte")} <Badge color="secondary">{this.getText("Price")}: {msg.price}</Badge>:</h6>
                                        <div className="d-flex flex-wrap" style={{gap: "4px"}}>
                                            {msg.payMedia.map((item,index) =>
                                                <SmallMediaPreview key={"SmallMediaPreviewPayMessage-" + msg.msgID + "-" + index} context="message" contextID={msg.msgID} guid={this.getGuid()} type={item.type} mediaID={item.mediaID} reviewStatus={item.reviewStatus} clicked={this.payMediaClicked.bind(this,item,false)} />
                                            )}
                                        </div>
                                    </div>
                                    : null }
                                    {msg.responses !== null ? msg.responses.map(response =>
                                        <div className="offset-1 col-11 mt-1 mb-1 small">
                                            <div className="d-flex w-100 align-items-center justify-content-between flex-wrap">
                                                <strong><FontAwesomeIcon icon={solid('reply')} fixedWidth /> <FontAwesomeIcon icon={solid('envelopes')} fixedWidth /> {this.getText("Response to")} {response.receiver}</strong>
                                                <small>{DateTime.fromISO(response.sendTime.toString()).toLocaleString(DateTime.DATETIME_MED)}</small>
                                            </div>
                                            <div className="w-100 small">{response.msgtext}</div>
                                            { response.freeMedia.length > 0 ? 
                                            <div>
                                                <h6>{this.state.currentFolder == "inbox" ? this.getText("Kostenlose Inhalte") : this.getText("Anhänge")}:</h6>
                                                <div className="d-flex flex-wrap" style={{gap: "4px"}}>
                                                    {response.freeMedia.map((item,index) =>
                                                        <SmallMediaPreview key={"SmallMediaPreviewFreeResponse-" + msg.msgID + "-" + response.msgID + "-" + index} context="message" contextID={response.msgID} guid={this.getGuid()} type={item.type} mediaID={item.mediaID} reviewStatus={item.reviewStatus} clicked={this.freeMediaClicked.bind(this,item,false)} />
                                                    )}
                                                </div>
                                            </div>
                                            : null }
                                            { response.payMedia.length > 0 ? 
                                            <div>
                                                <h6>{this.getText("Kostenpflichtige Inhalte")} <Badge color="secondary">{this.getText("Price")}: {msg.price}</Badge>:</h6>
                                                <div className="d-flex flex-wrap" style={{gap: "4px"}}>
                                                    {response.payMedia.map((item,index) =>
                                                        <SmallMediaPreview key={"SmallMediaPreviewPayResponse-" + msg.msgID + "-" + response.msgID + "-" + index} context="message" contextID={response.msgID} guid={this.getGuid()} type={item.type} mediaID={item.mediaID} reviewStatus={item.reviewStatus} clicked={this.payMediaClicked.bind(this,item,false)} />
                                                    )}
                                                </div>
                                            </div>
                                            : null }
                                        </div>
                                    ) : null }
                                </div> : null
                            )}
                        </ul>
                    : null }
                </div>
            </div>
        )
    }
}