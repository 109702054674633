import React, { Component } from 'react';
import { AppCtx, AppContextInterface, ApiHelper } from '../AppContextInterface';
import { Redirect } from 'react-router';
import { Alert, Container, Form, FormGroup, Button, Label, Input, ButtonGroup, Dropdown, DropdownMenu, DropdownItem, ListGroup, ListGroupItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import { LocalStorageWorker } from '../StorageHelper';

export interface Home extends React.Component {
    state: HomeState;
    contextType: AppContextInterface;
}

interface HomeProps {

}

interface SalesInfo {
    sumAmount: number;
    sumOriginalAmount: number;
    creditType: string|null;
    records: SalesInfoRecord[];
}

interface SalesInfoRecord {
    id: number,
    amount: number,
    originalAmount: number,
    userID: string,
    pseudo: string,
    producerID: string,
    creditType: string,
    ipaddress: string,
    domain: string,
    clientType: string,
    clientTypeDetail: string,
    salesTimestamp: Date
}

interface HomeState {
    errorMessage: string;
    fetching: boolean;
    language: string;
    doneProfileEdit: boolean;
    doneTimelinePost: boolean;
    doneMasterDataEdit: boolean;
    doneSubscription: boolean;
    donePayoutModel: boolean;
    smallImageStatus: string;
    bigImageStatus: string;
    totalLikes: number;
    validSubscribers: number;
    validSubscribersToday: number;
    validFavs: number;
    validFavsToday: number;
    salesToday: SalesInfo|null;
    salesYesterday: SalesInfo|null;
    salesThisMonth: SalesInfo|null;
    salesLastMonth: SalesInfo|null;
    platform: string;
    redirectPage: string;
}

export class Home extends Component {
    static contextType = AppCtx;
    context!: React.ContextType<typeof AppCtx>;

    static displayName = Home.name;

    constructor(props: HomeProps) {
        super(props);

        let myLocalStorage:LocalStorageWorker = new LocalStorageWorker();

        let defaultLanguage = "auto";
        let saveData = "1";

        if (myLocalStorage.get("language") != null) {
            defaultLanguage = myLocalStorage.get("language");
        }
        if (myLocalStorage.get("saveData") != null) {
            saveData = myLocalStorage.get("saveData");
        }

        console.log("defaultLanguage1: " + defaultLanguage + " (" + typeof(defaultLanguage) + ")");

        if(defaultLanguage === "auto") {
            if(typeof(navigator.language) !== undefined) {
                if(navigator.language.indexOf("de") === 0) {
                    defaultLanguage = "de";
                }
                if(navigator.language.indexOf("en") === 0) {
                    defaultLanguage = "en";
                }
            }
        }

        console.log("defaultLanguage2: " + defaultLanguage + " (" + typeof(defaultLanguage) + ")");

        let myplatform:string = ApiHelper.getPlatform();

        if(this.context) {
            myplatform = this.context.platform;
        }

        this.state = {
            errorMessage: "",
            fetching: false,
            language: defaultLanguage,
            doneProfileEdit: false,
            doneTimelinePost: false,
            doneMasterDataEdit: false,
            doneSubscription: false,
            donePayoutModel: false,
            smallImageStatus: "",
            bigImageStatus: "",
            totalLikes: 0,
            validSubscribers: 0,
            validSubscribersToday: 0,
            validFavs: 0,
            validFavsToday: 0,
            salesToday: null,
            salesYesterday: null,
            salesThisMonth: null,
            salesLastMonth: null,
            platform: myplatform,
            redirectPage: ""
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = async () => {
        this.setState({ fetching: true });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/girlhomestatus";

        if (this.context) {
            if(!this.context.loggedin) {
                await ApiHelper.checkLoginStatus(this.context);
                if(this.context.producerID !== "") {
                    let myLocalStorage:LocalStorageWorker = new LocalStorageWorker();
                    let redirectPage:string = "";
            
                    if(myLocalStorage) {
                        if(myLocalStorage.get("lastpage") !== "" && myLocalStorage.get("lastpage") !== null) {
                            redirectPage = "/" + myLocalStorage.get("lastpage");
                            myLocalStorage.remove("lastpage");
                            this.setState({ redirectPage: redirectPage });
                            return;
                        }
                    }
                }
            }
            if(this.context.authToken === "") {
                this.setState({ errorMessage: "Du bist nicht eingeloggt / You are not logged in" });
                return;
            }
            jsonurl += "?producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
            jsonurl += "&platform=" + encodeURIComponent(this.context.platform);
        } else {
            jsonurl += "?producerID=missingcontext";
        }

        let response: any = null;

        try {
            response = await fetch(jsonurl);
            let data = await response.json();
            this.setState({ fetching: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                }
            }

            this.setState({ doneProfileEdit: data.doneProfileEdit });
            this.setState({ doneTimelinePost: data.doneTimelinePost });
            this.setState({ doneMasterDataEdit: data.doneMasterDataEdit });
            this.setState({ doneSubscription: data.doneSubscription });
            this.setState({ donePayoutModel: data.donePayoutModel });
            this.setState({ smallImageStatus: data.smallImageStatus });
            this.setState({ bigImageStatus: data.bigImageStatus });
            this.setState({ likes: data.likes });
            this.setState({ validSubscribers: data.validSubscribers });
            this.setState({ validSubscribersToday: data.validSubscribersToday });
            this.setState({ validFavs: data.validFavs });
            this.setState({ validFavsToday: data.validFavsToday });
            this.setState({ validFavs: data.validFavs });
            this.setState({ validFavsToday: data.validFavsToday });
            this.setState({ salesToday: data.salesToday });
            this.setState({ salesYesterday: data.salesYesterday });
            this.setState({ salesThisMonth: data.salesThisMonth });
            this.setState({ salesLastMonth: data.salesLastMonth });
        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching data: " + error.toString() });
            this.setState({ fetching: false });
        }
    }

    getText(exp: string) {
        if (this.state.language === "de") {
            if(exp === "AdLink") {
                return "Bewerbe Dein Profil mit folgendem Link:";
            }
            if(exp === "AdLinkEx") {
                return "Wenn du weißt, dass deine Kundschaft international ist, dann verwende:";
            }
            if (exp === "Willkommen beim Live-Strip Fans Admin:") {
                return "Willkommen beim Live-Strip Fans Admin:";
            }
            if(exp === "Hier kannst Du wichtige Funktionen für Deinen Account vornehmen:") {
                return "Hier kannst Du wichtige Funktionen für Deinen Account vornehmen:";
            }
            if(exp === "Content wie Bilder, neue Videos, Ankündigungen usw. in Deiner Timeline posten") {
                return "Content wie Bilder, neue Videos, Ankündigungen usw. in Deiner Timeline posten";
            }
            if(exp === "Über den Posteingang(Inbox) oder den Messenger Nachrichten lesen und schreiben (inkl. kostenpflichtiger Inhalte)") {
                return "Über den Posteingang(Inbox) oder den Messenger Nachrichten lesen und schreiben (inkl. kostenpflichtiger Inhalte)";
            }
            if(exp === "Deine Fans kontaktieren und mit ihnen chatten") {             
                return "Deine Fans kontaktieren und mit ihnen chatten";
            }
            if(exp === "Lade Vorschaubilder hoch und richte Dein Profil ein") {          
                return "Lade Vorschaubilder hoch und richte Dein Profil ein";
            }
            if(exp === "Wähle Dein Auszahlungsmodell") {
                return "Wähle Dein Auszahlungsmodell";
            }
            if(exp === "Trage Deine Abrechnungsdaten ein") {          
                return "Trage Deine Abrechnungsdaten ein";
            }
            if(exp === "Erstelle einen Eintrag für Deine Timeline") {   
                return "Erstelle einen Eintrag für Deine Timeline";
            }
            if(exp === "Richte ein Abo-Modell für Deine kostenpflichtigen Inhalte ein") {   
                return "Richte ein Abo-Modell für Deine kostenpflichtigen Inhalte ein";
            }
            if(exp === "Du hast noch kein Vorschaubild hochgeladen") {   
                return "Du hast noch kein Vorschaubild hochgeladen";
            }
            if(exp === "Dein Vorschaubild wurde abgelehnt") {   
                return "Dein Vorschaubild wurde abgelehnt";
            }
            if(exp === "Dein Vorschaubild ist noch in der Prüfung und noch nicht freigeschaltet") {   
                return "Dein Vorschaubild ist noch in der Prüfung und noch nicht freigeschaltet";
            }
            if(exp === "Leider hast Du ein ungültiges Vorschaubild eingestellt") {   
                return "Leider hast Du ein ungültiges Vorschaubild eingestellt";
            }
            if(exp === "Du hast noch kein Hintergrundbild hochgeladen") {   
                return "Du hast noch kein Hintergrundbild hochgeladen";
            }
            if(exp === "Dein Hintergrundbild wurde abgelehnt") {   
                return "Dein Hintergrundbild wurde abgelehnt";
            }
            if(exp === "Dein Hintergrundbild ist noch in der Prüfung und noch nicht freigeschaltet") {   
                return "Dein Hintergrundbild ist noch in der Prüfung und noch nicht freigeschaltet";
            }
            if(exp === "Leider hast Du ein ungültiges Hintergrundbild eingestellt") {   
                return "Leider hast Du ein ungültiges Hintergrundbild eingestellt";
            }
            if(exp === "Login") {
                return "Einloggen";
            }
            if(exp === "Signup") {
                return "Registrieren";
            }
            if(exp === "EditYourTimeline") {
                return "Bearbeite hier Deine Timeline";
            }
        } else {
            if(exp === "EditYourTimeline") {
                return "Edit your timeline";
            }
            if(exp === "AdLink") {
                return "Promote your profile with the following link";
            }
            if(exp === "AdLinkEx") {
                return "If you want to reach customers outside Germany, Austria and Switzerland, use";
            }
            if (exp === "Willkommen beim Live-Strip Fans Admin:") {
                return "Welcome to the Live-Strip Fans Admin:";
            }
            if(exp === "Hier kannst Du wichtige Funktionen für Deinen Account vornehmen:") {
                return "Here you can perform important functions for your account:";
            }
            if(exp === "Content wie Bilder, neue Videos, Ankündigungen usw. in Deiner Timeline posten") {
                return "Post content such as pictures, new videos, announcements, etc. in your timeline";
            }
            if(exp === "Über den Posteingang(Inbox) oder den Messenger Nachrichten lesen und schreiben (inkl. kostenpflichtiger Inhalte)") {
                return "Read and write messages via the inbox or messenger (including paid content)";
            }
            if(exp === "Deine Fans kontaktieren und mit ihnen chatten") {
                return "Contact your fans and chat with them";
            }
            if(exp === "Lade Vorschaubilder hoch und richte Dein Profil ein") {
                return "Upload preview images and set up your profile";
            }
            if(exp === "Wähle Dein Auszahlungsmodell") {
                return "Choose your payout model";
            }
            if(exp === "Trage Deine Abrechnungsdaten ein") {
                return "Enter your billing information";
            }
            if(exp === "Erstelle einen Eintrag für Deine Timeline") {
                return "Create an entry for your timeline";
            }
            if(exp === "Richte ein Abo-Modell für Deine kostenpflichtigen Inhalte ein") {
                return "Set up a subscription model for your paid content";
            }
            if(exp === "Du hast noch kein Vorschaubild hochgeladen") {
                return "You have not yet uploaded a preview image";
            }
            if(exp === "Dein Vorschaubild wurde abgelehnt") {
                return "Your preview image was rejected";
            }
            if(exp === "Dein Vorschaubild ist noch in der Prüfung und noch nicht freigeschaltet") {
                return "Your preview image is still being reviewed and not yet released";
            }
            if(exp === "Leider hast Du ein ungültiges Vorschaubild eingestellt") {
                return "Unfortunately you have posted an invalid preview image";
            }
            if(exp === "Du hast noch kein Hintergrundbild hochgeladen") {
                return "You have not yet uploaded a background image";
            }
            if(exp === "Dein Hintergrundbild wurde abgelehnt") {
                return "Your background image was rejected";
            }
            if(exp === "Dein Hintergrundbild ist noch in der Prüfung und noch nicht freigeschaltet") {
                return "Your background image is still being reviewed and not yet released";
            }
            if(exp === "Leider hast Du ein ungültiges Hintergrundbild eingestellt") {
                return "Unfortunately you have posted an invalid background image";
            }
            if(exp === "Login") {
                return "Login";
            }
            if(exp === "Signup") {
                return "Signup";
            }
        }

        return exp;
    }

    advertisingLinkClick() {
        if(this.context) {
            navigator.clipboard.writeText("https://livestrip.fans/" + encodeURIComponent(this.context.pseudo));
        }
    }

    advertisingLinkClickOpen() {
        if(this.context) {
            window.open("https://livestrip.fans/" + encodeURIComponent(this.context.pseudo), "_blank");
        }
    }

    advertisingLinkClickEx() {
        if(this.context) {
            navigator.clipboard.writeText("https://livestrip.fans/" + encodeURIComponent(this.context.pseudo));
        }
    }

    advertisingLinkClickOpenEx() {
        if(this.context) {
            window.open("https://livestrip.fans/" + encodeURIComponent(this.context.pseudo), "_blank");
        }
    }

    addSpacesToNumber(mynum:number) {
        if(mynum < 1000) {
            return "    ";
        }
        if(mynum < 10000) {
            return "   ";
        }
        if(mynum < 100000) {
            return "  ";
        }
        if(mynum < 1000000) {
            return " ";
        }
        return "";
    }
     
    writeNumber(mynum:number) {
        return this.addSpacesToNumber(mynum) + (mynum / 100).toFixed(2);
    }

    render () {
        if(this.state.redirectPage !== "") {
            return <Redirect to={this.state.redirectPage} push={true} />
        }

        return (
            <div>
            { this.state.platform === "fans" ?
            <section>
                <div className='pageHeadline'>Live-Strip Fans ControlCenter</div>
                <Alert isOpen={this.context?.infomsg !== ""} color="info">{this.context?.infomsg}</Alert>
                <p className='mt-2'>{this.getText("Willkommen beim Live-Strip Fans Admin:")}</p>
                <ButtonGroup className='d-flex w-100 flex-nowrap'>
                    { this.context?.producerID === "" ? <Link to="/login" className='w-50' style={{marginRight: "4px"}}><Button className='w-100' color="primary"><FontAwesomeIcon icon={solid("right-to-bracket")}></FontAwesomeIcon> {this.getText("Login")}</Button></Link> : null }
                    { this.context?.producerID === "" ? <a href="https://signup.livestrip.fans" target="_blank" className='w-50' style={{marginLeft: "4px"}}><Button className='w-100' color="primary"><FontAwesomeIcon icon={solid("user-plus")}></FontAwesomeIcon> {this.getText("Signup")}</Button></a> : null }
                </ButtonGroup>
                { this.context !== null ? this.context.producerID !== "" ? this.state.fetching ? <p className="pt-4"><FontAwesomeIcon icon={solid('cog')} spin size="4x" /></p> : 
                    !this.state.doneProfileEdit || !this.state.doneMasterDataEdit || !this.state.doneTimelinePost || !this.state.doneSubscription ? 
                    <Alert color="info">
                        <h6>TODO:</h6>
                        <ListGroup>
                            {!this.state.donePayoutModel && false ? <ListGroupItem className='importantTODOItem'><Link to={"/choosemyconditions"}><FontAwesomeIcon icon={solid('coins')}></FontAwesomeIcon> {this.getText("Wähle Dein Auszahlungsmodell")}</Link></ListGroupItem> : null}
                            {!this.state.doneMasterDataEdit ? <ListGroupItem className='importantTODOItem'><Link to={"/my-data"}><FontAwesomeIcon icon={solid('address-card')}></FontAwesomeIcon> {this.getText("Trage Deine Abrechnungsdaten ein")}</Link></ListGroupItem> : null}
                            {!this.state.doneProfileEdit ? <ListGroupItem className='importantTODOItem'><Link to={"/profile"}><FontAwesomeIcon icon={solid('image')}></FontAwesomeIcon> {this.getText("Lade Vorschaubilder hoch und richte Dein Profil ein")}</Link></ListGroupItem> : null}
                            {!this.state.doneTimelinePost ? <ListGroupItem className='importantTODOItem'><Link to={"/timeline"}><FontAwesomeIcon icon={solid('list-timeline')}></FontAwesomeIcon> {this.getText("Erstelle einen Eintrag für Deine Timeline")}</Link></ListGroupItem> : null}
                            {!this.state.doneSubscription ? <ListGroupItem className='importantTODOItem'><Link to={"/subscriptions"}><FontAwesomeIcon icon={solid('money-check-dollar-pen')}></FontAwesomeIcon> {this.getText("Richte ein Abo-Modell für Deine kostenpflichtigen Inhalte ein")}</Link></ListGroupItem> : null}
                        </ListGroup>
                    </Alert> : null : null : null 
                }
                <p>{this.getText("Hier kannst Du wichtige Funktionen für Deinen Account vornehmen:")}</p>
                <ul>
                    <li>{this.getText("Content wie Bilder, neue Videos, Ankündigungen usw. in Deiner Timeline posten")}</li>
                    <li>{this.getText("Über den Posteingang(Inbox) oder den Messenger Nachrichten lesen und schreiben (inkl. kostenpflichtiger Inhalte)")}</li>
                    <li>{this.getText("Dein Profil editieren, Bilder und Videos hochladen, sowie Deine Abrechnungsdaten eingeben")}</li>
                    <li>{this.getText("Verschiedene Preismodelle für Deinen Content festlegen und Deine Abonnenten verwalten")}</li>
                </ul>
                { this.context !== null ? this.context.pseudo !== "" ? 
                <Alert color='secondary'>
                    <h6>{this.getText("AdLink")}</h6>
                    <kbd>https://livestrip.fans/{this.context.pseudo}</kbd>
                    <Button size="sm" style={{display: "inline",marginLeft: "4px",padding: "1px 4px",backgroundColor: "#000",borderColor: "#000"}} onClick={this.advertisingLinkClick.bind(this)} className="btn btn-sm btn-secondary"><FontAwesomeIcon icon={solid("paste")}></FontAwesomeIcon></Button>
                    <Button size="sm" style={{display: "inline",marginLeft: "4px",padding: "1px 4px",backgroundColor: "#000",borderColor: "#000"}} onClick={this.advertisingLinkClickOpen.bind(this)} className="btn btn-sm btn-secondary"><FontAwesomeIcon icon={solid("globe")}></FontAwesomeIcon></Button>
                </Alert> : null : null }
                { this.context !== null ? this.context.producerID !== "" ? this.state.fetching ? <p className="pt-4"><FontAwesomeIcon icon={solid('cog')} spin size="4x" /></p> : 
                    this.state.smallImageStatus !== "OK" || this.state.bigImageStatus !== "OK" ? 
                    <Alert color="info">
                        <h6>TODO:</h6>
                        <ListGroup>
                            {this.state.smallImageStatus === "NoImage" ? <ListGroupItem className='importantTODOItem'><Link to={"/profile"}>{this.getText("Du hast noch kein Vorschaubild hochgeladen")}</Link></ListGroupItem> : null}
                            {this.state.smallImageStatus === "Rejected" ? <ListGroupItem className='importantTODOItem'><Link to={"/profile"}>{this.getText("Dein Vorschaubild wurde abgelehnt")}</Link></ListGroupItem> : null}
                            {this.state.smallImageStatus === "NotReviewed" ? <ListGroupItem className='importantTODOItem'><Link to={"/profile"}>{this.getText("Dein Vorschaubild ist noch in der Prüfung und noch nicht freigeschaltet")}</Link></ListGroupItem> : null}
                            {this.state.smallImageStatus === "InvalidImage" ? <ListGroupItem className='importantTODOItem'><Link to={"/profile"}>{this.getText("Leider hast Du ein ungültiges Vorschaubild eingestellt")}</Link></ListGroupItem> : null}
                            {this.state.bigImageStatus === "NoImage" ? <ListGroupItem className='importantTODOItem'><Link to={"/profile"}>{this.getText("Du hast noch kein Hintergrundbild hochgeladen")}</Link></ListGroupItem> : null}
                            {this.state.bigImageStatus === "Rejected" ? <ListGroupItem className='importantTODOItem'><Link to={"/profile"}>{this.getText("Dein Hintergrundbild wurde abgelehnt")}</Link></ListGroupItem> : null}
                            {this.state.bigImageStatus === "NotReviewed" ? <ListGroupItem className='importantTODOItem'><Link to={"/profile"}>{this.getText("Dein Hintergrundbild ist noch in der Prüfung und noch nicht freigeschaltet")}</Link></ListGroupItem> : null}
                            {this.state.bigImageStatus === "InvalidImage" ? <ListGroupItem className='importantTODOItem'><Link to={"/profile"}>{this.getText("Leider hast Du ein ungültiges Hintergrundbild eingestellt")}</Link></ListGroupItem> : null}
                        </ListGroup>
                    </Alert> : null : null : null 
                }
                { this.context !== null ? this.context.producerID !== "" ? this.state.fetching ? <p className="pt-4"><FontAwesomeIcon icon={solid('cog')} spin size="4x" /></p> : 
                <Alert color='secondary'>
                    <h6>{this.getText("Deine Statistiken")}</h6>
                    <p className='mt-2' style={{fontSize: "12px",fontFamily: "monospace"}}>
                        <pre>
                            {this.getText("Likes insgesamt:")} {this.state.totalLikes}<br />
                            <br />
                            {this.getText("Deine neuen Abos heute:")} {this.state.validSubscribersToday}<br />
                            {this.getText("Abos insgesamt:")}         {this.state.validSubscribers}<br />
                            <Link to="/userlists">{this.getText("Alle Abonnenten anzeigen")}</Link><br />
                            <br />
                            {this.getText("User, die Dich als Favoriten gespeichert haben:")}       {this.state.validFavs}<br />
                            {this.getText("User, die Dich heute als Favoriten gespeichert haben:")} {this.state.validFavsToday}<br />
                            <Link to="/messenger">{this.getText("Zum Messenger")}</Link><br />
                            <br />
                            { this.state.salesToday ? 
                            <span>{this.getText("Dein Umsatz heute:")}          {this.writeNumber(this.state.salesToday.sumAmount)} &euro;<br /></span> : null }
                            { this.state.salesYesterday ? 
                            <span>{this.getText("Dein Umsatz gestern:")}        {this.writeNumber(this.state.salesYesterday.sumAmount)} &euro;<br /></span> : null }
                            { this.state.salesThisMonth ?
                            <span>{this.getText("Dein Umsatz diesen Monat:")}   {this.writeNumber(this.state.salesThisMonth.sumAmount)} &euro;<br /></span> : null }
                            { this.state.salesLastMonth ?
                            <span>{this.getText("Dein Umsatz letzten Monat:")}  {this.writeNumber(this.state.salesLastMonth.sumAmount)} &euro;<br /></span> : null }
                            { this.state.salesToday ? <Link to="/sales">{this.getText("Detaillierte Sales-Statistik")}</Link> : null }
                        </pre>
                    </p>
                </Alert> : null : null }
            </section> : 
            <section>
                <div className='pageHeadline'>Livestrip Content Control</div>
                <Alert isOpen={this.context?.infomsg !== ""} color="info">{this.context?.infomsg}</Alert>
                <hr />
                { this.context?.producerID === "" ? 
                <ButtonGroup className='d-flex w-100 flex-nowrap'>
                    <Link to="/login" className='w-50' style={{marginRight: "4px"}}><Button className='w-100' color="primary"><FontAwesomeIcon icon={solid("right-to-bracket")}></FontAwesomeIcon> {this.getText("Login")}</Button></Link>
                    <a href="https://sender.livestrip.com/Signup.aspx" target="_blank" className='w-50' style={{marginLeft: "4px"}}><Button className='w-100' color="primary"><FontAwesomeIcon icon={solid("user-plus")}></FontAwesomeIcon> {this.getText("Signup")}</Button></a>
                </ButtonGroup> : null }
                { this.context?.producerID !== "" ? 
                <p>
                    <Link to="/timeline" className='w-100'><Button className='w-100' color="primary"><FontAwesomeIcon icon={solid("list-timeline")}></FontAwesomeIcon> {this.getText("EditYourTimeline")}</Button></Link>
                </p>
                : null }
            </section> }
            </div>
        );
    }
}
