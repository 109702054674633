import React, { Component, FormEvent } from 'react';
import { Alert, Container, Form, FormGroup, Button, Label, Input } from 'reactstrap';
import { LocalStorageWorker } from '../StorageHelper';
import { CryptoHelper } from '../CryptoHelper.js';
import { AppCtx, AppContextInterface, ApiHelper } from '../AppContextInterface';
import { Redirect } from 'react-router'

interface SupportProps {

}

export interface Support {
    state: SupportState;
    props: SupportProps;
    contextType: AppContextInterface;
}

interface SupportState {
    text: string;
    submitting: false;
    errorMessage: string;
    language: string;
    sendSuccess: boolean;
}

export class Support extends React.Component {
    static contextType = AppCtx;
    context!: React.ContextType<typeof AppCtx>;

    constructor(props: SupportProps) {
        super(props);

        let myLocalStorage = new LocalStorageWorker();

        let contextLanguage = "de";

        if (this.context) {
            if (this.context.language !== null && this.context.language !== "") {
                contextLanguage = this.context.language;
            }
        }

        this.state = { 
            text: '',
            submitting: false, 
            errorMessage: '', 
            language: contextLanguage,
            sendSuccess: false
        };
    }

    componentDidMount() {
        let myLocalStorage:LocalStorageWorker = new LocalStorageWorker();

        if(myLocalStorage.get("lastpage") !== "support") {
            myLocalStorage.add("lastpage","support");
        }
    }

    handleTextChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState({ text: event.target.value });
    }

    handleSubmit(event: FormEvent) {
        event.preventDefault();
        this.formSubmitted();
    }

    formSubmitted = async () => {
        this.setState({ errorMessage: "" });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/girlsupport?text=" + encodeURIComponent(this.state.text) + "&postURL=" + encodeURIComponent(window.location.hostname);

        if (this.context) {
            jsonurl += "&producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        }

        this.setState({ submitting: true });

        let response:any = null;

        try {
            const settings = {
                method: 'POST',
                headers: {
                    Accept: 'application/json'
                }
            };
            response = await fetch(jsonurl, settings);
            let data = await response.json();
            this.setState({ submitting: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                    if(!ApiHelper.checkResponse(data,this.context) && this.context !== null) {
                        this.context.producerID = "";
                        alert("Please relogin");
                    }
                } else {
                    this.setState({ sendSuccess: data.success });
                }
            }
        } catch (error: any) {
            this.setState({ errorMessage: "Error making changes: " + error.toString() });
            this.setState({ submitting: false });
        }
    }

    getText(exp: string) {
        if (this.state.language === "de") {
            if (exp === "SendSuccess") {
                return "Vielen Dank! Wir haben Deine Nachricht empfangen und werden uns schnellstmöglich darum kümmern.";
            }
            if (exp === "SupportSubtitle") {
                return "Bitte schreibe uns Deine Nachricht und wir versuchen schnellstmöglich Dir zu helfen.";
            }
            if (exp === "Submit") {
                return "Abschicken";
            }
            if (exp === "ChangesSuccess") {
                return "Die Änderungen wurden übernommen";
            }
            if (exp === "Error") {
                return "Fehler";
            }
            if (exp === "YourMessage") {
                return "Deine Nachricht";
            }
        } else {
            if (exp === "SendSuccess") {
                return "Thank you! We have received your message and will take care of it as soon as possible.";
            }
            if (exp === "SupportSubtitle") {
                return "Please write us your message and we will try to help you as soon as possible.";
            }
            if (exp === "ChangesSuccess") {
                return "Changes successfully applied";
            }
            if (exp === "YourMessage") {
                return "Your message";
            }
        }

        return exp;
    }

    render() {
        if (this.context) {
            if (this.context.producerID === "") {
                return <Redirect to="/" push={true} />
            }
        } else {
            return <Redirect to="/" push={true} />
        }

        return (
            <div id='SupportDIV' className='container pt-4'>
                <h4 className="pageHeadline">{this.getText("Support")}</h4>
                <div className="mb-4"><small>{this.getText("SupportSubtitle")}</small></div>

                {this.state.sendSuccess ? <Alert color="success">{this.getText("SendSuccess")}</Alert> : null}
                {this.state.errorMessage !== "" ? <Alert color="danger">{this.state.errorMessage}</Alert> : null}

                <Form onSubmit={this.handleSubmit.bind(this)}>
                    <FormGroup className="mb-2" controlId="formBasicEMail">
                        <Label>{this.getText("YourMessage")}</Label>
                        <textarea className='form-control' rows={4} value={this.state.text} onChange={this.handleTextChange.bind(this)}></textarea>
                    </FormGroup>
                    <Button variant="primary" disabled={this.state.submitting} type="submit">{this.getText("Submit")}</Button>
                </Form>
            </div>
        );
    }
}

export default Support;