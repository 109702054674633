import React, { Component,FormEvent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppCtx, AppContextInterface, ApiHelper } from '../AppContextInterface';
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import { LocalStorageWorker } from '../StorageHelper';
import { v4 as uuidv4 } from 'uuid';

export interface MessagingPostMediaData {
    type: string;
    mediaID: string;
    reviewStatus: number;
}

interface SmallMediaPreviewProps {
    type: string;
    mediaID: string;
    guid: string;
    reviewStatus: number;
    clicked: (item:MessagingPostMediaData) => void;
    context: string;
    contextID: string;
}

interface SmallMediaPreviewState {
    type: string;
    mediaID: string;
    reviewStatus: number;
    language: string;
    itemExtraClass: string;
    itemPreviewUrl: string;
    smallPreviewDimension: string;
    guid: string;
    context: string;
    contextID: string;
}

export interface SmallMediaPreview {
    state: SmallMediaPreviewState;
    props: SmallMediaPreviewProps;
    contextType: AppContextInterface;
    mediaID: string;
}

export class SmallMediaPreview extends React.Component {
    static contextType = AppCtx;
    context!: React.ContextType<typeof AppCtx>;

    constructor(props: SmallMediaPreviewProps) {
        super(props);

        let myLocalStorage = new LocalStorageWorker();
        let mylang: string = "de";

        if (this.context) {
            mylang = this.context.language;
        }

        this.state = {
            type: props.type,
            mediaID: props.mediaID,
            reviewStatus: props.reviewStatus,
            language: mylang,
            itemPreviewUrl: "https://placehold.co/80",
            itemExtraClass: "",
            smallPreviewDimension: "80x80xinside",
            guid: props.guid,
            context: props.context,
            contextID: props.contextID
        };
    }

    componentDidMount() {
        this.fetchRealImageURLs();
    }

    fetchRealImageURLs() {
        let producerID:string = "";
        let authToken:string = "";

        if(this.context) {
            producerID = this.context.producerID;
            authToken = this.context.authToken;
        }

        document.querySelectorAll('img[x-smallmediapreviewcontrol="' + this.state.guid + '"]').forEach((item: any) => {
            let myURL:string = ApiHelper.getAPIUrl(this.context) + "/" + item.getAttribute("x-mediatype") + "/get/" + item.getAttribute("x-mediaid") + "?role=producer&producerOrUserID=" + encodeURIComponent(producerID) + "&authToken=" + encodeURIComponent(authToken) + "&dimension=" + encodeURIComponent(this.state.smallPreviewDimension) + "&context=" + encodeURIComponent(this.state.context) + "&contextID=" + encodeURIComponent(this.state.contextID);

            console.log("Fetching image URL for mediaType " + item.getAttribute("x-mediatype") + " and mediaid " + item.getAttribute("x-mediaid"));

            fetch(myURL).then(async(response: any) => {
                let data = await response.json();
                
                if(item.getAttribute("x-mediatype") === "video") {
                    console.log("Got video data " + JSON.stringify(data));
                }

                if(typeof(data.image) !== "undefined") {
                    if(data.image) {
                        item.src = data.image.imageURL;
                        console.log("Got image URL for mediaType " + item.getAttribute("x-mediatype") + " and mediaid " + item.getAttribute("x-mediaid"));
                        let divItem:HTMLElement|null = document.getElementById("smallMediaPreviewParent" + this.state.mediaID);
                        if(divItem) {
                            if(data.image.reviewStatus === 1) {
                                divItem.classList.add("reviewed");
                            } else if(data.image.reviewStatus === -1) {
                                divItem.classList.add("rejected");
                            } else {
                                divItem.classList.add("inreview");
                            }
                        }
                    }
                }

                if(typeof(data.video) !== "undefined") {
                    if(data.video) {
                        console.log("Setting video preview URL for mediaType " + item.getAttribute("x-mediatype") + " and mediaid " + item.getAttribute("x-mediaid"));
                        if(item.getAttribute("x-mediapreview") === "hard") {
                            item.src = data.video.previewImageURLHard;
                        } else {
                            item.src = data.video.previewImageURLSoft;
                        }
                        console.log("Got video preview URL " + item.src + " for mediaType " + item.getAttribute("x-mediatype") + " and mediaid " + item.getAttribute("x-mediaid"));
                        if(data.video.reviewStatus === 1) {
                            item.classList.add("reviewed");
                        } else if(data.video.reviewStatus === -1) {
                            item.classList.add("rejected");
                        } else {
                            item.classList.add("inreview");
                        }
                    }
                }
            }).catch(function() {
                item.src = "https://placehold.co/80?text=Error";
            });
        });
    }

    getSmallMediaPreviewUrl() {
        return "https://placehold.co/80";
    }

    getMediumMediaPreviewUrl() {
        return "https://placehold.co/200";
    }

    getSingleImagePreviewUrl() {
        return "https://placehold.co/300";
    }

    getText(exp:string):string {
        if (this.state.language === "de") {
            if(exp === "image") {
                return "Bild";
            }
            if(exp === "video") {
                return "Video";
            }
            if(exp === "audio") {
                return "Audio";
            }
        } else {
            if(exp === "image") {
                return "Image";
            }
            if(exp === "video") {
                return "Video";
            }
            if(exp === "audio") {
                return "Audio";
            }
        }

        return exp;
    }

    getItemClass(type:string) {
        if (type === "image") {
            return "smallMediaPreview";
        } else if (type === "video") {
            return "smallMediaPreviewVideo";
        } else if (type === "audio") {
            return "smallMediaPreview";
        }
    }

    getItem():MessagingPostMediaData {
        return {
            type: this.state.type,
            mediaID: this.state.mediaID,
            reviewStatus: this.state.reviewStatus
        }
    }

    render() {
        if (this.context) {
            if (this.context.producerID === "") {
                return <p>Du bist nicht eingeloggt</p>;
            }
        } else {
            return <p>Du bist nicht eingeloggt</p>;
        }

        return (
            <div id={"smallMediaPreviewParent" + this.state.mediaID}  className={ this.getItemClass(this.state.type) }>
                <div className="smallMediaPreviewIcon">
                    { this.state.type === "image" ? <FontAwesomeIcon icon={solid('image')} size="sm" /> : this.state.type === "video" ? <FontAwesomeIcon icon={solid('video')} size="sm" /> : this.state.type === "audio" ? <FontAwesomeIcon icon={solid('microphone')} size="sm" /> : null }
                    { this.getText(this.state.type) }
                </div>
                <div className="w-100">
                    { this.state.type === "image" ? 
                        <img x-mediatype={this.state.type} x-mediaid={this.state.mediaID} x-smallmediapreviewcontrol={this.state.guid} src={this.getSmallMediaPreviewUrl()} className="w-100" style={{cursor: "pointer"}} onClick={this.props.clicked.bind(this,this.getItem())} />
                    : this.state.type === "video" ?
                        <div className='d-flex'>
                            <img x-mediatype={this.state.type} x-mediaid={this.state.mediaID} x-smallmediapreviewcontrol={this.state.guid} x-mediapreview="hard" src={this.getSmallMediaPreviewUrl()} className="w-100" style={{cursor: "pointer"}} onClick={this.props.clicked.bind(this,this.getItem())} />
                            <img x-mediatype={this.state.type} x-mediaid={this.state.mediaID} x-smallmediapreviewcontrol={this.state.guid} x-mediapreview="soft" src={this.getSmallMediaPreviewUrl()} className="w-100" style={{cursor: "pointer"}} onClick={this.props.clicked.bind(this,this.getItem())} />
                        </div>
                    : null }
                </div>
            </div>
        );
    }
}

export default SmallMediaPreview;