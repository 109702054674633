import React, { Component, FormEvent } from 'react';
import { ListGroup,ListGroupItem,InputGroup,Alert, Modal, ModalHeader, ModalBody, ModalFooter, Container, Form, FormGroup, Button, Label, Input, ButtonGroup, Dropdown, DropdownMenu, DropdownItem } from 'reactstrap';
import { LocalStorageWorker } from '../StorageHelper';
import { CryptoHelper } from '../CryptoHelper.js';
import { AppCtx, AppContextInterface, ApiHelper } from '../AppContextInterface';
import { Redirect } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DateTime } from 'luxon';
import { TimelinePostPollData,TimelinePostPollAnswer } from './Timeline';

interface PollModalProps {
    open:boolean;
    setParamsCallback(myParams:TimelinePostPollData):void;
    closeCallback():void;
}

interface PollModalState {
    language:string;
    isOpen:boolean;
    infoMessage:string;
    errorMessage:string;
    pollQuestion:string;
    pollDays:number;
    pollAnswers:TimelinePostPollAnswer[];
    curAnswer:string;
}

export interface PollModal extends React.Component {
    props:PollModalProps;
    state:PollModalState;
    contextType: AppContextInterface;
    updateTimer:NodeJS.Timer;
}

export class PollModal extends React.Component {
    static contextType = AppCtx;
    context!: React.ContextType<typeof AppCtx>;

    constructor(props: PollModalProps) {
        super(props);

        let contextLanguage = "de";

        if (this.context) {
            if (this.context.language !== null && this.context.language !== "") {
                contextLanguage = this.context.language;
            }
        }

        this.state = {
            language: contextLanguage,
            isOpen:props.open,
            infoMessage:"",
            errorMessage:"",
            pollQuestion:"",
            pollDays:30,
            pollAnswers:[],
            curAnswer:""
        }
    }

    componentDidMount() {
        this.updateTimer = setInterval(this.watchPropsUpdate.bind(this),250);
    }

    watchPropsUpdate() {
        if(this.props.open != this.state.isOpen) {
            this.setState({isOpen: this.props.open});
        }
    }

    componentWillUnmount() {
        if(this.updateTimer) {
            clearInterval(this.updateTimer);
        }
    }

    getText(exp: string) {
        if (this.state.language === "de") {
            if (exp === "Poll") {
                return "Umfrage";
            }
        } else {
            
        }

        return exp;
    }

    modalClosed() {
        this.setState({isOpen: false});
        this.props.closeCallback();
    }

    handleSubmit(e:FormEvent) {
        e.preventDefault();
        this.props.setParamsCallback({
            question:this.state.pollQuestion,
            days:this.state.pollDays,
            answers:this.state.pollAnswers
        });
    }

    addAnswer() {
        let newAnswer:TimelinePostPollAnswer = {
            answer: this.state.curAnswer
        }
        this.setState({pollAnswers: [...this.state.pollAnswers,newAnswer]});
        this.setState({curAnswer: ""});
    }

    removeAnswer(clickedItem:TimelinePostPollAnswer) {
        let myAnswers:TimelinePostPollAnswer[];
        myAnswers = this.state.pollAnswers.filter((item:TimelinePostPollAnswer) => { return item.answer !== clickedItem.answer });
        this.setState({pollAnswers: myAnswers});
    }

    handleOptionKeyPress(e:React.KeyboardEvent) {
        if(e.key === "Enter") {
            this.addAnswer();
        }
    }

    render() {
        if (this.context) {
            if (this.context.producerID === "") {
                return <Redirect to="/" push={true} />
            }
        } else {
            return <Redirect to="/" push={true} />
        }

        return (
            <Modal isOpen={this.state.isOpen} size="lg" onClosed={this.modalClosed.bind(this)}>
                <ModalHeader>
                    { this.getText("Poll") }
                </ModalHeader>
                <ModalBody>
                    <Alert isOpen={this.state.infoMessage !== ""} color="info">{this.state.infoMessage}</Alert>
                    <Alert isOpen={this.state.errorMessage !== ""} color="danger">{this.state.errorMessage}</Alert>
                    <Form onSubmit={this.handleSubmit.bind(this)}>
                        <FormGroup className="mb-2">
                            <Label>{this.getText("Question")}</Label>
                            <Input type="text" placeholder={this.getText("Question")} value={this.state.pollQuestion} onChange={(e:React.ChangeEvent<HTMLInputElement>) => this.setState({pollQuestion: e.target.value})} />
                        </FormGroup>
                        <FormGroup className="mb-2">
                            <Label>{this.getText("Days")}</Label>
                            <Input type="tel" placeholder={this.getText("Days")} value={this.state.pollDays} onChange={(e:React.ChangeEvent<HTMLInputElement>) => this.setState({pollDays: e.target.value})} />
                        </FormGroup>
                        <Button variant="primary" type="submit">{this.getText("Submit")}</Button>
                    </Form>
                    <div>
                        <h6>{this.getText("Mögliche Antworten")}</h6>
                        <ListGroup>
                            {this.state.pollAnswers.map(item =>
                                <ListGroupItem>
                                    <FontAwesomeIcon icon={solid('question')} size="sm" /> {item.answer} <FontAwesomeIcon icon={solid('remove')} style={{cursor: "pointer"}} onClick={this.removeAnswer.bind(this,item)} size="sm" /> 
                                </ListGroupItem>
                            )}
                        </ListGroup>
                        <InputGroup>
                            <Input type="text" placeholder={this.getText("Add answer")} onKeyDown={this.handleOptionKeyPress.bind(this)} value={this.state.curAnswer} onChange={(e:React.ChangeEvent<HTMLInputElement>) => this.setState({curAnswer: e.target.value})} />
                            <Button>
                                <FontAwesomeIcon icon={solid('plus')} size="sm" onClick={this.addAnswer.bind(this)} />
                            </Button>
                        </InputGroup>
                    </div>
                </ModalBody>
            </Modal>
        )
    }
}

export default PollModal;