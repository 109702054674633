import React, { Component } from 'react';

import { AppCtx, AppContextInterface } from '../AppContextInterface';
import { Alert, Container, Form, FormGroup, Button, Label, Input, ButtonGroup, Dropdown, DropdownMenu, DropdownItem,NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

export class Impressum extends Component {
    static contextType = AppCtx;
    context!: React.ContextType<typeof AppCtx>;

    static displayName = Impressum.name;

    render () {
        return (
            <div>
                
            </div>
        );
    }
}
