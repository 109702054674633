import React, { Component, FormEvent } from 'react';
import { Alert, Col, Row, Container, Form, FormGroup, Button, Label, Input, ButtonGroup, Dropdown, DropdownMenu, DropdownItem } from 'reactstrap';
import { LocalStorageWorker } from '../StorageHelper';
import { CryptoHelper } from '../CryptoHelper.js';
import { AppCtx, AppContextInterface, ApiHelper } from '../AppContextInterface';
import { Redirect } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DateTime } from 'luxon';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-US';

interface SalesListProps {

}

export interface SalesList extends React.Component {
    state: SalesListState;
    props: SalesListProps;
    contextType: AppContextInterface;
}

interface SalesListState {
    successMessage: string;
    errorMessage: string;
    language: string;
    sales: ProducerFanCreditJSON[];
    groupedSales: ProducerFanGroupedSalesRecord[];
    filterCreditType: string;
    fetchingSales: boolean;
    selectedRecord: ProducerFanCreditJSON|null;
    fetchingRecord: boolean;
    startDate: Date;
}

interface ProducerFanCreditJSON {
    id: number;
    amount: number;
    originalAmount: number;
    userID: string;
    pseudo: string;
    producerID: string;
    creditType: string;
    salesTimestamp: Date;
    ipaddress: string;
    domain: string;
    clientType: string;
    clientTypeDetail: string;
}

interface ProducerFanGroupedSalesRecord {
    creditType: string;
    amount: number;
    records: ProducerFanCreditJSON[];
    originalAmount: number;
}

export class SalesList extends React.Component {
    static contextType = AppCtx;
    context!: React.ContextType<typeof AppCtx>;

    constructor(props: SalesListProps) {
        super(props);

        let contextLanguage = "de";

        if (this.context) {
            if (this.context.language !== null && this.context.language !== "") {
                contextLanguage = this.context.language;
            }
        }

        let nowDate:Date = new Date();
        let startDate:Date = new Date(nowDate.getFullYear(), nowDate.getMonth(), 1, 0, 0, 0, 0);

        this.state = {
            successMessage: "",
            errorMessage: "",
            language: contextLanguage,
            sales: [],
            groupedSales: [],
            filterCreditType: "",
            fetchingSales: false,
            selectedRecord: null,
            fetchingRecord: false,
            startDate: startDate
        };
    }

    componentDidMount() {
        
    }

    getText(exp: string) {
        if (this.state.language === "de") {
            if(exp === "Month") {
                return "Monat";
            }
            if(exp === "Saleslisten") {
                return "Verkäufe";
            }
            if(exp === "time") {
                return "Zeitpunkt";
            }
            if(exp === "amount") {
                return "Betrag";
            }
            if(exp === "ipaddress") {
                return "IP-Addresse";
            }
            if(exp === "pseudo") {
                return "Nickname";
            }
            if(exp === "creditType") {
                return "Typ";
            }
            if(exp === "amount") {
                return "Betrag";
            }
            if(exp === "domain") {
                return "Domain";
            }
            if(exp === "originalAmount") {
                return "Originalbetrag";
            }
            if(exp === "records") {
                return "Anzahl";
            }
            if (exp === "Username") {
                return "Pseudo";
            }
            if(exp === "ALL") {
                return "Alle";
            }
            if(exp === "BUY_TIMELINE_POST") {
                return "Verkaufte Timeline-Posts";
            }
            if(exp === "BUY_MESSAGE") {
                return "Verkaufte Nachrichten";
            }
            if(exp === "BUY_MESSENGER_MESSAGE") {
                return "Verkaufte Nachrichten";
            }
            if(exp === "CHARGE_TIP") {
                return "Trinkgeld";
            }
            if(exp === "FanBalance") {
                return "FanBalance-Käufe";
            }
            if(exp === "SEND_MESSAGE") {
                return "Nachrichtenversand";
            }
            if(exp === "CHARGE_SUBSCRIPTION") {
                return "Abo-Gebühren";
            }
        } else {
            if(exp === "Saleslisten") {
                return "Sales";
            }
            if(exp === "time") {
                return "Time";
            }
            if(exp === "amount") {
                return "Amount";
            }
            if(exp === "ipaddress") {
                return "IP-Address";
            }
            if(exp === "domain") {
                return "Domain";
            }
            if(exp === "pseudo") {
                return "Nickname";
            }
            if(exp === "creditType") {
                return "Type";
            }
            if(exp === "amount") {
                return "Amount";
            }
            if(exp === "originalAmount") {
                return "Original Amount";
            }
            if(exp === "records") {
                return "Records";
            }
            if (exp === "Username") {
                return "Livecam Profile";
            }
            if(exp === "ALL") {
                return "All";
            }
            if(exp === "BUY_TIMELINE_POST") {
                return "Sold Timeline-Posts";
            }
            if(exp === "BUY_MESSAGE") {
                return "Sold Messages";
            }
            if(exp === "BUY_MESSENGER_MESSAGE") {
                return "Sold Messenger-Messages";
            }
            if(exp === "CHARGE_TIP") {
                return "Tips";
            }
            if(exp === "FanBalance") {
                return "FanBalance-Sales";
            }
            if(exp === "SEND_MESSAGE") {
                return "Message Send Fees";
            }
            if(exp === "CHARGE_SUBSCRIPTION") {
                return "Subscription Fees";
            }
        }

        return exp;
    }

    clickRecord(id: number) {
        this.fetchSingleRecord(id);
    }

    fetchSingleRecord = async (id: number) => {
        this.setState({ fetchingRecord: true });
        this.setState({ selectedRecord: null });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/account/earnings/" + id;

        if (this.context) {
            jsonurl += "?producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        } else {
            jsonurl += "?producerID=nocontext";
        }

        let response: any = null;

        try {
            response = await fetch(jsonurl);
            let data = await response.json();
            this.setState({ fetchingRecord: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                    if(!ApiHelper.checkResponse(data,this.context) && this.context !== null) {
                        this.context.producerID = "";
                        alert("Please relogin");
                    }
                }
            }

            if (data.record) {
                this.setState({ selectedRecord: data.record });
            }
        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching data: " + error.toString() });
            this.setState({ fetchingRecord: false });
        }
    }

    clickList(filterCreditType: string) {
        this.setState({ selectedRecord: null });

        if (filterCreditType !== "" && filterCreditType !== null) {
            this.setState({ filterCreditType: filterCreditType });
            this.fetchSales(filterCreditType);
        } else {
            this.setState({ errorMessage: "Invalid filterCreditType clicked: " + filterCreditType });
        }
    }

    fetchSales = async (filterCreditType: string) => {
        this.setState({ fetchingSales: true });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/account/earnings";

        if (this.context) {
            if(!this.context.loggedin) {
                await ApiHelper.checkLoginStatus(this.context);
            }
            jsonurl += "?producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        } else {
            jsonurl += "?producerID=nocontext";
        }

        if(filterCreditType !== "ALL") {
            jsonurl += "&filterCreditType=" + encodeURIComponent(filterCreditType);   
        }

        jsonurl += "&startTime=" + encodeURIComponent(DateTime.fromISO(this.state.startDate.toISOString()).toFormat("MM-dd-yyyy"));
        let newDate:Date;
        if(this.state.startDate.getMonth() == 11) {
            newDate = new Date(this.state.startDate.getFullYear() + 1, 0, 1, 0, 0, 0, 0);
        } else {
            newDate = new Date(this.state.startDate.getFullYear(), this.state.startDate.getMonth() + 1, 1, 0, 0, 0, 0);
        }
        jsonurl += "&endTime=" + encodeURIComponent(DateTime.fromISO(newDate.toISOString()).toFormat("MM-dd-yyyy"));

        let response: any = null;

        try {
            response = await fetch(jsonurl);
            let data = await response.json();
            this.setState({ fetchingSales: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                }
            }

            if (data.all) {
                this.setState({ sales: data.all.records });
            }

            if (data.grouped) {
                this.setState({ groupedSales: data.grouped });
            } else {
                this.setState({ groupedSales: [] });
            }
        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching data: " + error.toString() });
            this.setState({ fetchingSales: false });
        }
    }

    unselectList() {
        this.setState({filterCreditType: ""});
    }

    unselectRecord() {
        this.setState({selectedRecord: null});
    }

    changeDate(newDate:Date) {
        this.setState({startDate: newDate},this.fetchSales.bind(this,this.state.filterCreditType));
    }

    render() {
        if (this.context) {
            if (this.context.producerID === "") {
                return <Redirect to="/" push={true} />
            }
        } else {
            return <Redirect to="/" push={true} />
        }
    
        return (
            <div>
                <div id='SalesListDIV' className='row'>
                    <div id="SalesListsPane" className={this.state.filterCreditType !== "" ? "col-12 col-lg-3 SalesListselected" : "col-12 col-lg-3 noSalesListselected"}>
                        <h4 className='pageHeadline'>
                            <span className="fs-5 fw-semibold">{this.getText("Saleslisten")}</span>
                        </h4>
                        <p>
                            { this.state.startDate !== null ? this.getText("Month") + " : " + DateTime.fromISO(this.state.startDate.toISOString())?.toLocaleString(DateTime.DATE_MED)?.replace("1. ","") : null }
                            <DatePicker selected={ this.state.startDate } dateFormat="MM/yyyy" showMonthDropdown showYearDropdown showMonthYearPicker dropdownMode="select" locale={this.state.language === 'de' ? de : en} onChange={this.changeDate.bind(this)} />
                        </p>
                        <ul className="list-group">
                            <li className={"ALL" == this.state.filterCreditType ? "list-group-item salesList active" : "list-group-item salesList"} onClick={this.clickList.bind(this, "ALL")}>{this.getText("ALL")}</li>
                            <li className={"BUY_TIMELINE_POST" == this.state.filterCreditType ? "list-group-item salesList active" : "list-group-item salesList"} onClick={this.clickList.bind(this, "BUY_TIMELINE_POST")}>{this.getText("BUY_TIMELINE_POST")}</li>
                            <li className={"BUY_MESSAGE" == this.state.filterCreditType ? "list-group-item salesList active" : "list-group-item salesList"} onClick={this.clickList.bind(this, "BUY_MESSAGE")}>{this.getText("BUY_MESSAGE")}</li>
                            <li className={"BUY_MESSENGER_MESSAGE" == this.state.filterCreditType ? "list-group-item salesList active" : "list-group-item salesList"} onClick={this.clickList.bind(this, "BUY_MESSENGER_MESSAGE")}>{this.getText("BUY_MESSENGER_MESSAGE")}</li>
                            <li className={"CHARGE_TIP" == this.state.filterCreditType ? "list-group-item salesList active" : "list-group-item salesList"} onClick={this.clickList.bind(this, "CHARGE_TIP")}>{this.getText("CHARGE_TIP")}</li>
                            <li className={"SEND_MESSAGE" == this.state.filterCreditType ? "list-group-item salesList active" : "list-group-item salesList"} onClick={this.clickList.bind(this, "SEND_MESSAGE")}>{this.getText("SEND_MESSAGE")}</li>
                            <li className={"CHARGE_SUBSCRIPTION" == this.state.filterCreditType ? "list-group-item salesList active" : "list-group-item salesList"} onClick={this.clickList.bind(this, "CHARGE_SUBSCRIPTION")}>{this.getText("CHARGE_SUBSCRIPTION")}</li>
                        </ul>
                    </div>
                    { this.state.filterCreditType !== "" && this.state.selectedRecord === null ? 
                    <div id="SalesRecordsPane" className="col-12 col-lg-9">
                        <p className="d-flex align-items-center flex-shrink-0 p-3 link-dark text-decoration-none border-bottom">
                            <Button variant="secondary" size="sm" className='d-inline-block' onClick={this.unselectList.bind(this)}><FontAwesomeIcon icon={solid('arrow-left')} fixedWidth /></Button>&nbsp;
                            <span className="fs-5 fw-semibold">{this.getText("Liste")} {this.getText(this.state.filterCreditType)}</span>
                        </p>
                        {this.state.successMessage !== "" ? <Alert color="success">{this.state.successMessage}</Alert> : null}
                        {this.state.errorMessage !== "" ? <Alert color="danger">{this.state.errorMessage}</Alert> : null}
                        {this.state.fetchingSales ? <p className="pt-4"><FontAwesomeIcon icon={solid('cog')} spin size="4x" /></p> :
                            this.state.sales.length === 0 ? <p className="pt-4">{this.getText("Keine Einträge gefunden")}</p> :
                            <table className="table table-striped table-sm w-100">
                                <tr>
                                    <th>{this.getText("time")}</th>
                                    <th>{this.getText("pseudo")}</th>
                                    <th style={{textAlign: "right"}}>{this.getText("amount")}</th>
                                    <th style={{textAlign: "right"}}>{this.getText("creditType")}</th>
                                </tr>
                                {this.state.sales.map(record =>
                                    <tr onClick={this.clickRecord.bind(this,record.id)} className={record.id === this.state.selectedRecord?.id ? "salesRecordItem active" : "salesRecordItem"}>
                                        <td><small>{DateTime.fromISO(record.salesTimestamp.toString()).toLocaleString(DateTime.DATETIME_MED)}</small></td>
                                        <td><strong className="mb-1"><FontAwesomeIcon icon={solid('user')} fixedWidth /> {record.pseudo}</strong></td>
                                        <td style={{textAlign: "right"}}>{(record.amount / 100).toFixed(2)} EUR</td>
                                        <td style={{textAlign: "right"}}>{this.getText(record.creditType)}</td>
                                    </tr>
                                )}
                            </table>
                        }
                        {typeof(this.state.groupedSales.map) !== "function" ? null :
                            this.state.groupedSales.length === 0 ? null :
                            <table className="table table-striped table-sm w-100">
                                <tr>
                                    <th>{this.getText("creditType")}</th>
                                    <th style={{textAlign: "right"}}>{this.getText("amount")}</th>
                                    <th style={{textAlign: "right"}}>{this.getText("records")}</th>
                                </tr>
                                {this.state.groupedSales.map(record =>
                                    <tr>
                                        <td>{record.creditType}</td>
                                        <td style={{textAlign: "right"}}>{(record.amount / 100).toFixed(2)} EUR</td>
                                        <td style={{textAlign: "right"}}>{record.records.length}</td>
                                    </tr>
                                )}
                            </table>
                        }
                    </div>
                    : null }
                    <div id="SalesRecordsPane" className="d-flex flex-column align-items-stretch col-12 col-lg-9">
                        {this.state.successMessage !== "" ? <Alert color="success">{this.state.successMessage}</Alert> : null}
                        {this.state.errorMessage !== "" ? <Alert color="danger">{this.state.errorMessage}</Alert> : null}
                        {this.state.fetchingRecord ? <p className="pt-4"><FontAwesomeIcon icon={solid('cog')} spin size="4x" /></p> : null }
                        { this.state.selectedRecord !== null ? 
                            <div className='singleRecordPane w-100'>
                                <p className="d-flex align-items-center flex-shrink-0 p-3 link-dark text-decoration-none border-bottom">
                                    <Button variant="secondary" size="sm" className='d-inline-block' onClick={this.unselectRecord.bind(this)}><FontAwesomeIcon icon={solid('arrow-left')} fixedWidth /></Button>&nbsp;
                                    <span className="fs-5 fw-semibold">User {this.state.selectedRecord.pseudo}</span>
                                </p>
                                <table className='table table-condensed w-100'>
                                    <tr>
                                        <td>UserID</td>
                                        <td>{this.state.selectedRecord.userID}</td>
                                    </tr>
                                    <tr>
                                        <td>{this.getText("time")}</td>
                                        <td>{DateTime.fromISO(this.state.selectedRecord.salesTimestamp.toString()).toLocaleString(DateTime.DATETIME_MED)}</td>
                                    </tr>
                                    <tr>
                                        <td>amount</td>
                                        <td>{(this.state.selectedRecord.amount / 100).toFixed(2)} EUR</td>
                                    </tr>
                                    <tr>
                                        <td>ipaddress</td>
                                        <td>{this.state.selectedRecord.ipaddress}</td>
                                    </tr>
                                    <tr>
                                        <td>domain</td>
                                        <td>{this.state.selectedRecord.domain}</td>
                                    </tr>
                                    <tr>
                                        <td>clientType</td>
                                        <td>{this.state.selectedRecord.clientType}</td>
                                    </tr>
                                    <tr>
                                        <td>clientTypeDetail</td>
                                        <td>{this.state.selectedRecord.clientTypeDetail}</td>
                                    </tr>
                                </table>
                            </div>
                        : null }
                    </div>
                </div>
            </div>
        )
    }
}

export default SalesList;