import React, { Component, FormEvent } from 'react';
import { Alert, Container, Form, FormGroup, Button, Label, Input, Card, CardHeader, CardBody, CardFooter } from 'reactstrap';
import { LocalStorageWorker } from '../StorageHelper';
import { CryptoHelper } from '../CryptoHelper.js';
import { AppCtx, AppContextInterface, ApiHelper } from '../AppContextInterface';
import { Redirect } from 'react-router'
//import { Amplify, Auth, Hub } from 'aws-amplify';

interface LoginFormProps {
    
}

export interface LoginForm {
    state: LoginFormState;
    props: LoginFormProps;
    contextType: AppContextInterface;
    pLang: string;
}

interface LoginFormState {
    userName: string;
    password: string;
    submitting: boolean;
    saveData: boolean;
    errorMessage: string;
    language: string;
    loggedin: boolean;
    signup: boolean;
    signuplink: string;
    api: string;
}

export class LoginForm extends React.Component {
    static contextType = AppCtx;
    context!: React.ContextType<typeof AppCtx>;

    constructor(props: LoginFormProps) {
        super(props);

        let myLocalStorage:LocalStorageWorker = new LocalStorageWorker();

        let defaultLanguage = "auto";
        let saveData = "1";

        if (myLocalStorage.get("language") != null) {
            defaultLanguage = myLocalStorage.get("language");
        }
        if (myLocalStorage.get("saveData") != null) {
            saveData = myLocalStorage.get("saveData");
        }

        console.log("defaultLanguage1: " + defaultLanguage + " (" + typeof(defaultLanguage) + ")");

        if(defaultLanguage === "auto") {
            if(typeof(navigator.language) !== undefined) {
                if(navigator.language.indexOf("de") === 0) {
                    defaultLanguage = "de";
                }
                if(navigator.language.indexOf("en") === 0) {
                    defaultLanguage = "en";
                }
            }
        }

        console.log("defaultLanguage2: " + defaultLanguage + " (" + typeof(defaultLanguage) + ")");

        this.state = { 
            userName: '', 
            password: '', 
            submitting: false, 
            saveData: parseInt(saveData) === 0 ? false : true, 
            errorMessage: '', 
            language: defaultLanguage,
            loggedin: false,
            signup: false,
            signuplink: "",
            api: "main"
        };
    }

    componentDidMount() {
        let myLocalStorage: LocalStorageWorker = new LocalStorageWorker();

        if (myLocalStorage.get("pseudo") != null) {
            this.setState({ userName: myLocalStorage.get("pseudo") });
        }

        if (myLocalStorage.get("passwordtransit") !== null) {
            var transitmessage = myLocalStorage.get("passwordtransit").trim();
            let myCryptoHelper: CryptoHelper = new CryptoHelper();
            this.setState({ password: myCryptoHelper.getDecryptedString(transitmessage) });
        }

        myLocalStorage.remove("producerid");

        let urlParams = new URLSearchParams(window.location.search);

        if(urlParams.get("token") != null) {
            let pToken:string = urlParams.get("token")!;
            console.log("received pToken: " + pToken);
            this.tokenLogin(pToken);
        }

        if(urlParams.get("language") != null) {
            let pLanguage:string = urlParams.get("language")!;
            console.log("received language: " + pLanguage);
            setTimeout(this.updateLanguageFromURLParams.bind(this), 500);
        } else {
            if (myLocalStorage.get("language") != null) {
                setTimeout(this.updateLanguageFromLocalStorage.bind(this), 500);
            }
        }
    }

    updateLanguageFromURLParams() {
        let urlParams = new URLSearchParams(window.location.search);
        if(urlParams.get("language") != null) {
            let pLanguage:string = urlParams.get("language")!;
            this.changeLanguage(pLanguage);
        }
    }

    updateLanguageFromLocalStorage() {
        let myLocalStorage = new LocalStorageWorker();
        if (myLocalStorage.get("language") != null) {
            this.changeLanguage(myLocalStorage.get("language"));
        }
    }

    handleUserNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ userName: event.target.value });
    }

    handlePasswordChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ password: event.target.value });
    }

    handleSaveDataChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ saveData: event.target.checked });
        let myLocalStorage = new LocalStorageWorker();
        if (event.target.checked) {
            myLocalStorage.add("saveData", "1");
        } else {
            myLocalStorage.add("saveData", "0");
        }
    }

    handleAPIChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({api: event.target.value});
        let myLocalStorage = new LocalStorageWorker();
        myLocalStorage.add("api", event.target.value);
    }

    handleLanguageChange(event: React.ChangeEvent<HTMLSelectElement>) {
        let newLang = event.target.value;
        this.changeLanguage(newLang);
    }

    changeLanguage(newLang: string) {
        this.setState({ language: newLang });
        let myLocalStorage = new LocalStorageWorker();
        myLocalStorage.add("language", newLang);
        this.context!.language = newLang;
    }

    handleSubmit(event: FormEvent) {
        event.preventDefault();
        this.formSubmitted();
    }

    formSubmitted = async() => {
        let myLocalStorage = new LocalStorageWorker();

        var pseudo = this.state.userName;
        var mypassword = this.state.password;

        if (this.context) {
            this.context.api = this.state.api;
        }

        if(this.context) {
            this.context.infomsg = "";
        }

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/girllogin";

        jsonurl += "?hostname=" + encodeURIComponent(window.location.hostname);

        this.setState({ errorMessage: "" });
        this.setState({ submitting: true });
        this.setState({ signuplink: "" });

        let data = null;

        try {
            let postObject = {
                pseudo: pseudo,
                password: mypassword
            }

            const response = await fetch(jsonurl, {method: "POST",body: JSON.stringify(postObject), headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}});
            data = await response.json();
            this.setState({ submitting: false });

            const cryptoRandomString = require('crypto-random-string');

            if (data.login === "OK") {
                try {
                    if (this.state.saveData) {
                        myLocalStorage.add("pseudo", pseudo);
                        myLocalStorage.add("producerid", data.producerID);
                        let myCryptoHelper: CryptoHelper = new CryptoHelper();
                        myLocalStorage.add("passwordtransit", myCryptoHelper.getEncryptedString(mypassword));
                    } else {
                        myLocalStorage.remove("pseudo");
                        myLocalStorage.remove("producerid");
                        myLocalStorage.remove("password");
                    }
                } catch (e: any) {
                    this.setState({ errorMessage: "error saving encrypted pwd: " + e.toString() });
                }

                let myemail:string = "";
                let myphonenumber:string = "";

                if(data.email != null) {
                    myemail = data.email;
                }
                if(myemail == "" || myemail == null) {
                    myemail = "info@artagos.de";
                }
                if(data.phonenumber != null) {
                    myphonenumber = data.phonenumber;
                }

                if (this.context) {
                    this.context.producerID = data.producerID;
                    this.context.pseudo = data.pseudo;
                    this.context.platform = data.platform;
                    this.context.email = myemail;
                    this.context.phonenumber = myphonenumber;
                    this.context.loggedin = true;
                    this.context.language = this.state.language;
                    this.context.authToken = data.authToken;
                    this.context.jwtAuthToken = data.jwtAuthToken;
                    this.context.refreshToken = data.refreshToken;

                    if(this.context.language === "de") {
                        this.context.infomsg = "Du bist eingeloggt als " + data.pseudo;
                    } else {
                        this.context.infomsg = "You are logged in as " + data.pseudo;
                    }

                    myLocalStorage.add("lastToken",this.context.authToken);
                    myLocalStorage.add("lastPseudo",this.context.pseudo);
                    myLocalStorage.add("lastProducerID",this.context.producerID);
                    myLocalStorage.add("lastLanguage",this.context.language);

                    /*try {
                        Hub.listen('auth', this.authEventListener.bind(this));

                        Amplify.configure({
                            Auth: {
                                identityPoolId: "eu-west-1:facaef99-eb33-48af-b5ae-739edb22fb8a",
                                region: "eu-west-1",
                                userPoolId: "eu-west-1_U6LI5uyvd",
                                userPoolWebClientId: "15e8m1kg2g0mm59g0s4n94q2um",
                                mandatorySignIn: false,
                                signUpVerificationMethod: "code",
                                authenticationFlowType: "USER_PASSWORD_AUTH"
                            }
                        });
                        
                        const user = await Auth.signIn(pseudo, mypassword);

                        if(user) {
                            this.context.cognitoUser = user;
                        }
                    } catch (error:any) {
                        console.log('error signing into AWS Cognito', error);

                        if(error) {
                            let errorMessage:string = error.toString();
                            if(errorMessage) {
                                if(errorMessage.indexOf("Incorrect username or password") > -1) {
                                    console.log('trying to sign up to AWS Cognito');
                                    this.awsSignUp(pseudo, mypassword,myemail,myphonenumber);
                                }
                            }
                        }
                    }*/
                }
                this.setState({signup: data.isSignup});
                this.setState({signuplink: data.signupLink});
                this.setState({ loggedin: true });
            } else {
                if(typeof(data.error) !== "undefined") {
                    if(data.error !== null) {
                        this.setState({ errorMessage: this.getText("LoginFailed") + " : " + data.error });
                    } else {
                        this.setState({ errorMessage: this.getText("LoginFailed") + " : " + data.login });
                    }
                } else {
                    this.setState({ errorMessage: this.getText("LoginFailed") + " : " + data.login });
                }
            }
        } catch (error: any) {
            this.setState({ errorMessage: "Error on login request: " + error.toString() });
            this.setState({ submitting: false });
            return;
        }
    }

    authEventListener = (data:any) => {
        const { payload } = data;
        const { event } = payload;
        console.log("AWS cognito hub event: " + event + "");
        if (event === 'autoSignIn') {
            const user = payload.data;
            if(user) {
                if(this.context) {
                    this.context.cognitoUser = user;
                }
            }
        } else if (event === 'autoSignIn_failure') {
            // redirect to sign in page
        }
    }

    awsSignUp = async(username:string,password:string,email:string,phone_number:string) => {
        /*let myproducerID:string = "";

        if(this.context) {
            myproducerID = this.context.producerID;
        }

        try {
            const { user } = await Auth.signUp({
                username,
                password,
                attributes: {
                    email,          // optional,
                    "custom:producerid": myproducerID,
                },
                autoSignIn: { // optional - enables auto sign in after user is confirmed
                    enabled: true,
                }
            });
            console.log(user);
        } catch (error) {
            console.log('error signing up with aws Cogito:', error);
        }*/
    }

    tokenLogin = async(pToken:string) => {
        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/girllogin?token=" + encodeURIComponent(pToken);

        this.setState({ errorMessage: "" });
        this.setState({ submitting: true });
        this.setState({ signuplink: "" });

        let data = null;

        try {
            const response = await fetch(jsonurl);
            data = await response.json();
            this.setState({ submitting: false });

            const cryptoRandomString = require('crypto-random-string');

            if (data.login === "OK") {
                if (this.context) {
                    this.context.producerID = data.producerID;
                    this.context.pseudo = data.pseudo;
                    this.context.loggedin = true;
                    this.context.language = this.state.language;
                    this.context.authToken = data.authToken;
                    this.context.infomsg = "Du bist eingeloggt als " + data.pseudo;
                }
                this.setState({signup: data.isSignup});
                this.setState({signuplink: data.signupLink});
                this.setState({ loggedin: true });
            } else {
                this.setState({ errorMessage: this.getText("LoginFailed") + " : " + data.error });
            }
        } catch (error: any) {
            this.setState({ errorMessage: "Error on login request: " + error.toString() });
            this.setState({ submitting: false });
            return;
        }
    }

    getText(exp: string) {
        if (this.state.language === "de") {
            if (exp === "Username") {
                return "Benutzername";
            }
            if (exp === "Password") {
                return "Passwort";
            }
            if (exp === "UsernameHelp") {
                return "Dein Benutzername mit dem Dein Profil angezeigt wird";
            }
            if (exp === "LoginFormSubtitle") {
                return "Logge Dich hier mit Deinen Zugangsdaten ein";
            }
            if (exp === "Save Data") {
                return "Logindaten merken";
            }
            if (exp === "Submit") {
                return "Abschicken";
            }
            if (exp === "LoggedIn") {
                return "Du bist eingeloggt";
            }
            if (exp === "LoginFailed") {
                return "Login fehlgeschlagen";
            }
        } else {
            if (exp === "Username") {
                return "Username";
            }
            if (exp === "UsernameHelp") {
                return "This is the name that has been assigned to your creator profile";
            }
            if (exp === "LoginFormSubtitle") {
                return "Please login with your account login information";
            }
            if (exp === "LoggedIn") {
                return "You are logged in";
            }
            if (exp === "LoginFailed") {
                return "Login failed";
            }
        }

        return exp;
    }

    render() {
        if (this.state.loggedin) {
            if (this.state.signup && this.state.signuplink != "") {
                window.location.replace(this.state.signuplink);
                //return <Redirect to={this.state.signuplink} />
            }
            return <Redirect to="/" push={true} />
        }

        return (
            <div id='loginFormDIV' className='pt-4'>
                <Form onSubmit={this.handleSubmit.bind(this)}>
                <Card>
                    <CardHeader>
                        <b>Fans Controlcenter Login</b> <br />
                        <small>{this.getText("LoginFormSubtitle")}</small>
                    </CardHeader>
                    <CardBody>
                            <FormGroup controlId="formBasicUsername">
                                <Label>{this.getText("Username")}</Label>
                                <Input type="text" placeholder={this.getText("Username")} autoFocus={this.state.userName === "" ? true : false} value={this.state.userName} onChange={this.handleUserNameChange.bind(this)} />
                                <p className="text-muted">
                                    {this.getText("UsernameHelp")}
                                </p>
                            </FormGroup>

                            <FormGroup controlId="formBasicPassword" className="mb-2">
                                <Label>{this.getText("Password")}</Label>
                                <Input type="password" placeholder={this.getText("Password")} autoFocus={this.state.userName !== "" && this.state.password === "" ? true : false} value={this.state.password} onChange={this.handlePasswordChange.bind(this)} />
                            </FormGroup>
                            <FormGroup className="mb-2 d-none">
                                <Label>API</Label>
                                <Input id="apiSelect" name="api" type="select" onChange={this.handleAPIChange.bind(this)}>
                                    <option value="main">Main</option>
                                    <option value="local">Local Dev</option>
                                </Input>
                            </FormGroup>
                            <FormGroup controlId="formBasicCheckbox" className="mb-2">
                                <Input type="checkbox" checked={this.state.saveData} value="1" onChange={this.handleSaveDataChange.bind(this)} />
                                <Label check>{this.getText("Save Data")}</Label>
                            </FormGroup>
                            <hr />
                            <FormGroup>
                                <Label>{this.getText("Language")}</Label>
                                <select className='form-control' defaultValue={this.state.language} id="drpLanguage" size={1} onChange={this.handleLanguageChange.bind(this)}>
                                    <option value="auto">Auto</option>
                                    <option value="en">English</option>
                                    <option value="de">Deutsch/German</option>
                                </select>
                            </FormGroup>
                    </CardBody>
                    <CardFooter>
                        <Button variant="primary" disabled={this.state.submitting} type="submit">{this.getText("Submit")}</Button>
                    </CardFooter>
                </Card>
                </Form>


                {this.state.loggedin ? <Alert color="success" className='mt-2'>{this.getText("LoggedIn")}</Alert> : null}
                {this.state.errorMessage !== "" ? <Alert color="danger" className='mt-2'>{this.state.errorMessage}</Alert> : null}


            </div>
        );
    }
}

export default LoginForm;