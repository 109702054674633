import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { AppCtx, AppContextInterface } from '../AppContextInterface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro';


interface ImpressumFooterState {
    loggedin: boolean;
}

export interface ImpressumFooter {
    state: ImpressumFooterState;
    toggleNavbar(): void;
    checkStatusTimer: NodeJS.Timer;
}

export class ImpressumFooter extends Component {
    static displayName = ImpressumFooter.name;
    static contextType = AppCtx;
    context!: React.ContextType<typeof AppCtx>;

    constructor (props:any) {
        super(props);

        this.state = {
            loggedin: false
        };
    }

    componentDidMount() {
        this.checkStatusTimer = setInterval(this.checkStatus.bind(this), 250);
    }

    componentWillUnmount() {
        clearInterval(this.checkStatusTimer);
    }

    checkStatus() {
        if (this.context) {
            this.setState({ loggedin: this.context.loggedin });
        }
    }


  render () {
    return (
      <Row>
           {!this.state.loggedin || window.location.pathname == "/Impressum" ?
          <Col xs="6">
              <p><b>Impressum</b></p>
              <hr/>
              <p><b>ARTAGOS Medien-Design-Produktions GmbH</b></p>
              <p>Wannenäckerstr. 25</p>
              <p>74078 Heilbronn</p>
              <p>Deutschland</p>
              <p>Geschäftsführung: Daniel Bender</p>
            </Col> : null} 
            {!this.state.loggedin || window.location.pathname == "/Impressum" ?
            <Col xs="6">
            <p>&nbsp;</p>
              <hr/>
              <p> info@artagos.de</p>
              <p>(+49)7131/20374-10</p>
              <p>Amtsgericht Stuttgart HRB 107401</p>
              <p>USt-IdNr. DE212181355</p>
            </Col>     : null}   
                  </Row>
    );
  }
}
