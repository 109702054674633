import React, { Component } from 'react';
import { Alert, Container, Form, FormGroup, Button, Label, Input } from 'reactstrap';
import { LocalStorageWorker } from '../StorageHelper';
import { CryptoHelper } from '../CryptoHelper.js';
import { AppCtx, AppContextInterface, ApiHelper } from '../AppContextInterface';
import { Redirect } from 'react-router'
import { FormEvent } from 'react';

interface ChooseMyConditionsProps {

}

export interface ChooseMyConditions {
    state: ChooseMyConditionsState;
    props: ChooseMyConditionsProps;
    contextType: AppContextInterface;
}

interface ChooseMyConditionsState {
    payoutType: string;
    submitting: false;
    infoMessage: string;
    errorMessage: string;
    language: string;
    stornoInfoChecked: boolean;
    changeConfirmChecked: boolean;
}

export class ChooseMyConditions extends React.Component {
    static contextType = AppCtx;
    context!: React.ContextType<typeof AppCtx>;

    constructor(props: ChooseMyConditionsProps) {
        super(props);

        let myLocalStorage = new LocalStorageWorker();

        let contextLanguage = "de";

        if (this.context) {
            if (this.context.language !== null && this.context.language !== "") {
                contextLanguage = this.context.language;
            }
        }

        this.state = { 
            payoutType: '',
            submitting: false, 
            infoMessage: '',
            errorMessage: '', 
            language: contextLanguage,
            stornoInfoChecked: false,
            changeConfirmChecked: false
        };
    }

    componentDidMount() {
        this.fetchData();
    }
    handlepayoutTypeChange(event: React.ChangeEvent<HTMLSelectElement>) {
        this.setState({ payoutType: event.target.value });
    }

    handleSubmit(event: FormEvent) {
        event.preventDefault();
        this.formSubmitted();
    }

    fetchData = async () => {
        this.setState({ errorMessage: "" });
        this.setState({ infoMessage: "" });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/payoutconditions";

        if (this.context) {
            if(!this.context.loggedin) {
                await ApiHelper.checkLoginStatus(this.context);
            }
            jsonurl += "?producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        }

        this.setState({ submitting: true });

        let response: any = null;

        try {
            response = await fetch(jsonurl);
            let data = await response.json();
            this.setState({ submitting: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                } else {
                    this.setState({ payoutType: data.conditions });
                }
            }
        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching data: " + error.toString() });
            this.setState({ submitting: false });
        }
    }

    formSubmitted = async () => {
        this.setState({ emailChanged: false });
        this.setState({ emailMessage: "" });
        this.setState({ passwordChanged: false });
        this.setState({ passwordMessage: "" });
        this.setState({ errorMessage: "" });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/payoutconditions";

        jsonurl += "?conditions=" + encodeURIComponent(this.state.payoutType);

        if (this.context) {
            jsonurl += "&producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        }

        this.setState({ submitting: true });

        let response:any = null;

        try {
            const settings = {
                method: 'POST',
                headers: {
                    Accept: 'application/json'
                }
            };
            response = await fetch(jsonurl, settings);
            let data = await response.json();
            this.setState({ submitting: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                    if(!ApiHelper.checkResponse(data,this.context) && this.context !== null) {
                        this.context.producerID = "";
                        alert("Please relogin");
                    }
                }

                if(data.success) {
                    this.setState({ infoMessage: "Changes saved" });
                } else {
                    this.setState({ errorMessage: "Error: " + data.error });
                }
            }
        } catch (error: any) {
            this.setState({ errorMessage: "Error making changes: " + error.toString() });
            this.setState({ submitting: false });
        }
    }

    getText(exp: string) {
        if (this.state.language === "de") {
            if(exp === "80percent") {
                return "Auszahlung jeden Montag. 80% ohne Abzüge.";
            }
            if(exp === "95percent") {
                return "Auszahlung bis zum 10. des Folgemonats: 95% minus Paymentkosten";
            }
            if(exp === "Paymentkosten") {
                return "Paymentkosten";
            }
            if(exp === "Lastschrift") {
                return "Lastschrift";
            }
            if(exp === "Sofortüberweisung") {
                return "Sofortüberweisung/Online-Überweisen";
            }
            if(exp === "Kreditkarte Europa") {
                return "Kreditkarte EU-Länder";
            }
            if(exp === "Kreditkarte weltweit") {
                return "Kreditkarte nicht-EU Länder";
            }
            if(exp === "ChooseMyConditionsTitle") {
                return "Vertragskonditionen";
            }
            if(exp === "ChooseMyConditionsSubtitle") {
                return "Wähle Dein Auszahlungsmodell";
            }
            if (exp === "PayoutType") {
                return "Auszahlungsmodell";
            }
            if (exp === "Save Data") {
                return "Logindaten merken";
            }
            if (exp === "Submit") {
                return "Abschicken";
            }
            if (exp === "ChangesSuccess") {
                return "Die Änderungen wurden übernommen";
            }
            if (exp === "Error") {
                return "Fehler";
            }
            if(exp === "Save") {
                return "Speichern";
            }
            if(exp === "Current") {
                return "Aktuell";
            }
            if(exp === "StornoInfo") {
                return "Was passiert bei Stornos?";
            }
            if(exp === "StornoInfoDesc") {
                return "Im Falle von Stornos werden diese in Höhe des Erlösanteils rückbelastet und im Falle einer späteren Bezahlung im Rahmen der Beitreibung wieder in Höhe des ausgewählten Erlösanteils gut geschrieben.";
            }
            if(exp === "ChangeConfirm") {
                return "Der Wechsel des Auszahlungsmodelles ist jederzeit mit Wirksamkeit zum nächsten Monat möglich.";
            }
        } else {
            if(exp === "80percent") {
                return "Payout every Monday. 80% without deductions.";
            }
            if(exp === "95percent") {
                return "Payout until the 10th of the following month: 95% minus payment costs";
            }
            if(exp === "Paymentkosten") {
                return "Payment costs";
            }
            if(exp === "Lastschrift") {
                return "Direct debit";
            }
            if(exp === "Sofortüberweisung") {
                return "Sofort/OnlineUeberweisen";
            }
            if(exp === "Kreditkarte Europa") {
                return "Credit card EU countries"
            }
            if(exp === "Kreditkarte weltweit") {
                return "Credit card non-EU countries";
            }
            if(exp === "ChooseMyConditionsTitle") {
                return "Payout Terms";
            }
            if(exp === "ChooseMyConditionsSubtitle") {
                return "Select a model for your payouts";
            }
            if (exp === "PayoutType") {
                return "Payout Model";
            }
            if (exp === "ChangesSuccess") {
                return "Changes successfully applied";
            }
            if(exp === "Save") {
                return "Save";
            }
            if(exp === "Current") {
                return "Current";
            }
            if(exp === "ChangeConfirm") {
                return "The change of the payout model is possible at any time effective next month.";
            }
        }

        return exp;
    }

    stornoInfoCheckedChange(e:React.ChangeEvent<HTMLInputElement>) {
        this.setState({ stornoInfoChecked: e.target.checked });
    }

    changeConfirmCheckedChange(e:React.ChangeEvent<HTMLInputElement>) {
        this.setState({ changeConfirmChecked: e.target.checked });
    }

    payoutTypeChanged80(e:React.ChangeEvent<HTMLInputElement>) {
        if(e.target.checked) {
            this.setState({ payoutType: "80percent"});
        } else {
            this.setState({ payoutType: "95percent"});
        }
    }

    payoutTypeChanged95(e:React.ChangeEvent<HTMLInputElement>) {
        if(e.target.checked) {
            this.setState({ payoutType: "95percent"});
        } else {
            this.setState({ payoutType: "80percent"});
        }
    }

    render() {
        if (this.context) {
            if (this.context.producerID === "") {
                return <Redirect to="/" push={true} />
            }
        } else {
            return <Redirect to="/" push={true} />
        }

        return (
            <div id='ChooseMyConditionsDIV' className='container pt-4'>
                <h4 className="pageHeadline">{this.getText("ChooseMyConditionsTitle")}</h4>
                <div className="mb-4"><small>{this.getText("ChooseMyConditionsSubtitle")}</small></div>

                {this.state.infoMessage !== "" ? <Alert color="info">{this.state.infoMessage}</Alert> : null}
                {this.state.errorMessage !== "" ? <Alert color="danger">{this.state.errorMessage}</Alert> : null}

                <Form onSubmit={this.handleSubmit.bind(this)}>
                    <p>
                        <input id="cb80" type="checkbox" checked={this.state.payoutType === "80percent"} onChange={this.payoutTypeChanged80.bind(this)} /> <label htmlFor="cb80">{this.getText("80percent")}</label>
                    </p>
                    <p>
                        <input id="cb95" type="checkbox" checked={this.state.payoutType === "95percent"} onChange={this.payoutTypeChanged95.bind(this)} /> <label htmlFor="cb95" style={{display: "inline"}}>{this.getText("95percent")}</label><br />
                        <small style={{lineHeight: "10px"}}>
                            {this.getText("Paymentkosten")}:<br />
                            {this.getText("Lastschrift")}: 4%<br />
                            {this.getText("SofortÜberweisung")}: 4%<br />
                            {this.getText("Kreditkarte Europa")}: 5%<br />
                            {this.getText("Kreditkarte weltweit")}: 10%<br />
                        </small>
                    </p>
                    <p>
                        <input type="checkbox" id="cbStornoInfo" checked={this.state.stornoInfoChecked} onChange={this.stornoInfoCheckedChange.bind(this)} /> <label htmlFor="cbStornoInfo">{this.getText("StornoInfo")}</label><br />
                        <small>{this.getText("StornoInfoDesc")}</small>
                    </p>
                    <p>
                        <input type="checkbox" id="cbChangeConfirm" checked={this.state.changeConfirmChecked} onChange={this.changeConfirmCheckedChange.bind(this)} /> <label htmlFor="cbChangeConfirm">{this.getText("ChangeConfirm")}</label>
                    </p>
                    <Button variant="primary" disabled={this.state.submitting} type="submit">{this.getText("Save")}</Button>
                </Form>
            </div>
        );
    }
}

export default ChooseMyConditions;