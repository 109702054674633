import React, { Component, FormEvent } from 'react';
import { Col, Alert, Container, Form, FormGroup, Button, Label, Input, Row, Table } from 'reactstrap';
import ShopItemEntry from './ShopItemEntry';
import { AppCtx, AppContextInterface, ApiHelper } from '../AppContextInterface';

interface ShopItemsListProps {
    selectCallback: (itemID: number) => void;
}

interface ShopItemsListState {
    loading: boolean;
    shopitems: Array<any>;
    LoggedIn: boolean;
}
export interface ShopItemsList {
    state: ShopItemsListState;
    props: ShopItemsListProps;
    contextType: AppContextInterface;
    selectCallback: (itemID: number) => void;

}

export class ShopItemsList extends React.Component {
    //static displayName = ShopItemsList.name;
    static contextType = AppCtx;
    context!: React.ContextType<typeof AppCtx>;

    constructor(props: ShopItemsListProps) {
        super(props);
        this.selectCallback = props.selectCallback;
        this.state = { LoggedIn: false, shopitems: [], loading: false };
    }

    componentDidMount() {
        this.doFetchItems();
    }

    getText(exp:string):string {
        return exp;
    }

    render() {
        // let { loggedin } = this.context!;
        return (
            <Container className='mt-2' fluid={false}>
                <h4 className='pageHeadline'>{this.getText("Shop")}</h4>
                {!this.state.loading ?
                    <Table striped={true}>
                        <thead>
                            <tr>
                                <th>Aktiviert</th>
                                <th>Kaufbar</th>
                                <th>Artikelnummer</th>
                                <th>Beschreibung</th>
                                <th>Bestand</th>
                                <th>Verkauft</th>
                                <th>Verfügbar ab</th>
                                <th>Digital</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.shopitems.length === 0 ? <tr><td>Du hast noch keine Artikel im Shop</td></tr> : this.state.shopitems.map((entry: any, index: number) => <ShopItemEntry selectCallback={this.selectCallback} index={index} id={index} entry={entry} />)}
                        </tbody>
                        <tfoot>
                            <tr><td colSpan={9}><Alert>{this.state.shopitems.length} Aktikel im Shop</Alert></td></tr>
                        </tfoot>
                    </Table>
                    :
                    <Alert>Daten werden geladen</Alert>
                }
            </Container>
        );
    }

    async doFetchItems() {
        this.setState({  loading: true});
        let jsonurl = ApiHelper.getAPIUrl(this.context) + "/shopitems?ProducerID=" + encodeURIComponent(this.context!.producerID.toString());
        const response = await fetch(jsonurl);
        const data = await response.json();
        this.setState({ shopitems: data, loading: false });
    }
}