import React, { Component, FormEvent } from 'react';
import { Alert, Button, Pagination, PaginationItem, PaginationLink, Badge, Modal, ModalHeader, ModalBody, ModalFooter, Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import { LocalStorageWorker } from '../StorageHelper';
import { CryptoHelper } from '../CryptoHelper.js';
import { AppCtx, AppContextInterface, ApiHelper } from '../AppContextInterface';
import { Redirect } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DateTime } from 'luxon';
import { VideoModal } from './VideoModal';
import { ImageModal } from './ImageModal';
import { MessagingPost,MessagingPostMediaData } from './Timeline';
import { MediaData,BuyMediaData } from './Inbox';
import SmallMediaPreview from './SmallMediaPreview';
import { v4 as uuidv4 } from 'uuid';
import { PageData } from './Timeline';

interface MessengerConversationControlProps {
    conversationID: string;
    userPseudo: string;
    doReload: boolean;
    onMessagesReloaded: () => void;
}

export interface MessengerConversationControl extends React.Component {
    state: MessengerConversationControlState;
    props: MessengerConversationControlProps;
    contextType: AppContextInterface;
    propsChangeTimer: NodeJS.Timer;
    fetchImagesTimeout: NodeJS.Timeout|null;
    markReadTimeout: NodeJS.Timeout|null;
}

interface MsgPost {
    msg: string,
    receiverPseudo: string,
    conversationID: string,
    freeMedia: MessagingPostMediaData[],
    payMedia: MessagingPostMediaData[],
    price: number
}

interface MessengerConversationControlState {
    errorMessage: string;
    fetching: boolean;
    language: string;
    messages: MessengerMail[];
    selectedUserPseudo: string;
    conversationID: string;
    fetchingMessages: boolean;
    clickedMediaData: MessagingPostMediaData|null;
    smallPreviewDimension: string;
    mediumPreviewDimension: string;
    singleImagePreviewDimension: string;
    clickedMediaDeletable: boolean;
    videoPlayURL: string;
    videoPlayURLError: string;
    singleVideoAPIAction: boolean;
    platform: string;
    pagesArray: PageData[];
    curPage: number;
    pageSize: number;
    totalItemsCount: number;
    extraPagesDropdownOpen: boolean;
    maxPages: number;
}

interface MessengerConversation {
    conversationID: string;
    userPseudo: string;
    unreadFromUser: number;
    lastSendFromUser: Date;
    unreadFromGirl: number;
}

interface MessengerMail {
    msgID: string;
    sendTime: Date;
    clickTime: Date;
    content: string;
    sender: string;
    receiver: string;
    msgtext: string;
    freeMedia: MediaData[];
    payMedia: BuyMediaData[];
    folder: string;
    price: number;
    bought: boolean;
    schedule: boolean;
    scheduleExpired: boolean;
    paidSendPrice: number|null;
}

interface MessageAttachment {
    attachmentID: string;
    attachmentType: string;
    attachmentFileName: string;
    s3Key: string;
    s3Bucket: string;
    isBought: boolean;
    price: number;
}

export class MessengerConversationControl extends React.Component {
    static contextType = AppCtx;
    context!: React.ContextType<typeof AppCtx>;

    constructor(props: MessengerConversationControlProps) {
        super(props);

        let contextLanguage = "de";

        if (this.context) {
            if (this.context.language !== null && this.context.language !== "") {
                contextLanguage = this.context.language;
            }
        }

        let myplatform:string = ApiHelper.getPlatform();

        if(this.context) {
            myplatform = this.context.platform;
        }

        this.state = {
            errorMessage: "",
            fetching: false,
            fetchingMessages: false,
            language: contextLanguage,
            selectedUserPseudo: props.userPseudo,
            conversationID: props.conversationID,
            messages: [],
            clickedMediaData: null,
            smallPreviewDimension: "80x80xinside",
            mediumPreviewDimension: "300x300xinside",
            singleImagePreviewDimension: "480x480xinside",
            clickedMediaDeletable: false,
            videoPlayURL: "",
            videoPlayURLError: "",
            singleVideoAPIAction: false,
            platform: myplatform,
            pagesArray: [],
            curPage: 0,
            pageSize: 20,
            totalItemsCount: 0,
            extraPagesDropdownOpen: false,
            maxPages: 20
        }

        this.fetchImagesTimeout = null;
        this.markReadTimeout = null;
    }

    componentDidMount() {
        this.fetchUserMessages(this.state.conversationID);
        this.markReadTimeout = setTimeout(this.markMessagesRead.bind(this),8000);
        this.propsChangeTimer = setInterval(this.checkPropsChange.bind(this),1000);
    }

    componentWillUnmount(): void {
        if(this.fetchImagesTimeout) {
            clearTimeout(this.fetchImagesTimeout);
        }
        if(this.markReadTimeout) {
            clearTimeout(this.markReadTimeout);
        }
        clearTimeout(this.propsChangeTimer);
    }

    checkPropsChange() {
        if(this.props.conversationID !== this.state.conversationID) {
            if(this.fetchImagesTimeout) {
                clearTimeout(this.fetchImagesTimeout);
            }
            if(this.markReadTimeout) {
                clearTimeout(this.markReadTimeout);
            }
            this.setState({
                conversationID: this.props.conversationID,
                selectedUserPseudo: this.props.userPseudo,
                messages: []
            });
            this.fetchUserMessages(this.props.conversationID);
            this.props.onMessagesReloaded();
            return;
        }
        
        if(this.props.doReload) {
            this.fetchUserMessages(this.state.conversationID);
            this.props.onMessagesReloaded();
        }
    }

    getText(exp: string) {
        if (this.state.language === "de") {
            if(exp === "Scheduled") {
                return "Wird an neue User in dieser Gruppe gesendet";
            }
            if(exp === "ScheduleExpired") {
                return "Auto-Versand Abgelaufen";
            }
            if(exp === "ConversationWith") {
                return "Unterhaltung mit";
            }
            if(exp === "Messages") {
                return "Nachrichten";
            }
            if(exp === "NoMessages") {
                return "Keine Nachrichten vorhanden";
            }
            if(exp === "Eigene Gruppe") {
                return "Eigene Gruppe";
            }
            if(exp === "Username") {
                return "Pseudo";
            }
            if(exp === "ConfirmDelete") {
                return "Ja, Nachricht löschen";
            }
            if(exp === "Cancel") {
                return "Abbrechen";
            }
            if(exp === "Price") {
                return "Preis";
            }
            if(exp === "RedBorderedImages") {
                return "Rot umrandete Bilder/Videos müssen noch freigeschaltet werden, bevor sie den Usern angezeigt werden.";
            }
            if(exp === "MessengerDescription") {
                return "Der Messenger bietet Dir eine Übersicht über Deine Unterhaltungen mit einzelnen Usern, Du kannst auch kostenpflichtige Inhalte in Nachrichten versenden. Wenn Du lieber eine Übersicht über alle Nachrichten haben möchtest, dann nutze bitte die Funktion 'Posteingang' im Menü.";
            }
            if(exp === "OrangeOutlinedItemsHaveToBeReviewed") {
                return "(orange umrandete Inhalte müssen noch freigeschaltet werden, bevor sie angezeigt werden)";
            }
        } else {
            if(exp === "Scheduled") {
                return "will be sent to new users in this group";
            }
            if(exp === "ScheduleExpired") {
                return "autosend expired";
            }
            if(exp === "ConversationWith") {
                return "Conversation with";
            }
            if(exp === "NoMessages") {
                return "No messages found";
            }
            if(exp === "Eigene Gruppe") {
                return "Own group";
            }
            if (exp === "Username") {
                return "Livecam Profile";
            }
            if(exp === "ConfirmDelete") {
                return "Yes, delete message";
            }
            if(exp === "Wirklich Nachricht löschen?") {
                return "Really delete message?";
            }
            if(exp === "Preis festlegen") {
                return "Set price";
            }
            if(exp === "Preis") {
                return "Price";
            }
            if(exp === "Anhänge") {
                return "Attachments";
            }
            if(exp === "OrangeOutlinedItemsHaveToBeReviewed") {
                return "(orange outlined items have to be reviewed, before they will be shown)";
            }
            if(exp === "Kostenlose Inhalte") {
                return "Free content";
            }
            if(exp === "Kostenpflichtige Inhalte") {
                return "Pay content";
            }
            if(exp === "RedBorderedImages") {
                return "Red bordered images/videos have to be reviewed, before users will be able to see them.";
            }
            if(exp === "MessengerDescription") {
                return "The messenger offers you an overview of your conversations with individual users, you can also send paid content in messages. If you would rather have an overview of all messages, please use the function 'Inbox' in the menu."
            }
        }

        return exp;
    }

    handlePagination(numMessages:number) {
        if(numMessages > 0) {
            let newArray:PageData[] = [];
            for(let si = 0;si < numMessages;si += this.state.pageSize) {
                let newPage:PageData = {
                    page: Math.floor(si / this.state.pageSize),
                    active: Math.floor(si / this.state.pageSize) == this.state.curPage ? true : false
                };
                newArray.push(newPage);
            }
            this.setState({pagesArray: newArray});
        } else {
            this.setState({pagesArray: []});
        }
    }

    fetchUserMessages = async (conversationID:string) => {
        this.setState({ fetchingMessages: true });
        this.setState({ errorMessage: "" });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/messages";

        if (this.context) {
            jsonurl += "?conversationID=" + encodeURIComponent(conversationID) + "&producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
            jsonurl += "&platform=" + encodeURIComponent(this.context.platform);
            jsonurl += "&startIndex=" + encodeURIComponent(this.state.curPage * this.state.pageSize);
            jsonurl += "&numRecords=" + encodeURIComponent(this.state.pageSize);
        } else {
            jsonurl += "?conversationID=" + encodeURIComponent(conversationID) + "&authToken=missing";
        }

        jsonurl += "&role=producer";

        let response: any = null;

        try {
            response = await fetch(jsonurl);
            let data = await response.json();
            this.setState({ fetchingMessages: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                    if(!ApiHelper.checkResponse(data,this.context) && this.context !== null) {
                        this.context.producerID = "";
                        alert("Please relogin");
                    }
                }
            }

            if (data.messages) {
                this.setState({ messages: data.messages, pagesArray: [], totalItemsCount: data.numMessages },this.handleAfterDataFetched.bind(this));
                this.fetchImagesTimeout = setTimeout(this.fetchRealImageURLs.bind(this), 1000);
                this.markReadTimeout = setTimeout(this.markMessagesRead.bind(this), 12000);
            }
        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching data: " + error.toString() });
            this.setState({ fetching: false });
        }
    }

    handleAfterDataFetched() {
        this.handlePagination(this.state.totalItemsCount);
    }

    markMessagesRead = async () => {
        this.markReadTimeout = null;

        if(this.state.conversationID === "") {
            return;
        }

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/messages";

        if (this.context) {
            jsonurl += "?conversationID=" + encodeURIComponent(this.state.conversationID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
            jsonurl += "&platform=" + encodeURIComponent(this.context.platform);
        } else {
            jsonurl += "?conversationID=" + encodeURIComponent(this.state.conversationID) + "&authToken=missing";
        }

        jsonurl += "&role=producer";

        let response: any = null;

        try {
            response = await fetch(jsonurl,{method: "PUT"});
            let data = await response.json();
        } catch (error: any) {
            console.error("Error marking messages read: " + error.toString());
        }
    }

    formatPrice(price:number) {
        return Math.floor(price / 100) + "," + (price % 100).toString().padStart(2, "0");
    }

    getSmallMediaPreviewUrl(item:MessagingPostMediaData) {
        return "https://placehold.co/80";
    }

    getMediumMediaPreviewUrl(item:MessagingPostMediaData) {
        return "https://placehold.co/200";
    }

    getSingleImagePreviewUrl(item:MessagingPostMediaData) {
        return "https://placehold.co/300";
    }

    fetchRealImageURLs() {
        this.fetchImagesTimeout = null;

        let producerID:string = "";
        let authToken:string = "";

        if(this.context) {
            producerID = this.context.producerID;
            authToken = this.context.authToken;
        }

        document.querySelectorAll('img[src="https://placehold.co/200"]').forEach((item: any) => {
            let myURL:string = ApiHelper.getAPIUrl(this.context) + "/" + item.getAttribute("x-mediatype") + "/get/" + item.getAttribute("x-mediaid") + "?role=producer&producerOrUserID=" + encodeURIComponent(producerID) + "&authToken=" + encodeURIComponent(authToken) + "&dimension=" + this.state.mediumPreviewDimension;

            fetch(myURL).then(async(response: any) => {
                let data = await response.json();

                if(data.image) {
                    item.src = data.image.imageURL;
                    if(data.image.reviewStatus === 1) {
                        item.classList.add("reviewed");
                    } else if(data.image.reviewStatus === -1) {
                        item.classList.add("rejected");
                    } else {
                        item.classList.add("inreview");
                    }
                }
            }).catch(function() {
                item.src = "https://placehold.co/200?Text=Error";
            });
        });

        document.querySelectorAll('img[src="https://placehold.co/300"]').forEach((item: any) => {
            let myURL:string = ApiHelper.getAPIUrl(this.context) + "/" + item.getAttribute("x-mediatype") + "/get/" + item.getAttribute("x-mediaid") + "?role=producer&producerOrUserID=" + encodeURIComponent(producerID) + "&authToken=" + encodeURIComponent(authToken) + "&dimension=" + this.state.singleImagePreviewDimension;

            fetch(myURL).then(async(response: any) => {
                let data = await response.json();

                if(data.image) {
                    item.src = data.image.imageURL;
                    if(data.image.reviewStatus === 1) {
                        item.classList.add("reviewed");
                    } else if(data.image.reviewStatus === -1) {
                        item.classList.add("rejected");
                    } else {
                        item.classList.add("inreview");
                    }
                }
            }).catch(function() {
                item.src = "https://placehold.co/300?Text=Error";
            });
        });
    }

    getPayMediaDescriptionText() {
        if(this.context?.language == "de") {
            return "Zu diesem Preis können User "
        } else {

        }
    }

    freeMediaClicked(clickedMediaData:MessagingPostMediaData,deletable:boolean) {
        if(clickedMediaData) {
            this.setState({mediaAddMode: "free"});
            this.setState({clickedMediaData: clickedMediaData},this.fetchRealImageURLs.bind(this));
            this.setState({clickedMediaDeletable: deletable});
            setTimeout(this.fetchRealImageURLs.bind(this),1000);
            if(clickedMediaData.type === "video") {
                this.fetchVideoPlayURL(clickedMediaData);
            }
        } else {
            this.setState({errorMessage: "Invalid image clicked"});
        }
    }

    payMediaClicked(clickedMediaData:MessagingPostMediaData,deletable:boolean) {
        if(clickedMediaData) {
            this.setState({mediaAddMode: "pay"});
            this.setState({clickedMediaData: clickedMediaData},this.fetchRealImageURLs.bind(this));
            this.setState({clickedMediaDeletable: deletable});
            setTimeout(this.fetchRealImageURLs.bind(this),1000);
            if(clickedMediaData.type === "video") {
                this.fetchVideoPlayURL(clickedMediaData);
            }
        } else {
            this.setState({errorMessage: "Invalid image clicked"});
        }
    }

    detectAndroid():boolean {
        var ua = navigator.userAgent.toLowerCase();
        var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
        return isAndroid;
    }

    detectiOS():boolean {
        return [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }

    fetchVideoPlayURL = async(clickedMediaData:MessagingPostMediaData|null) => {
        if(clickedMediaData === null) {
            return;
        }

        let mediaID:string = clickedMediaData.mediaID;

        this.setState({ singleVideoAPIAction: true });
        this.setState({ videoPlayURLError: "" });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/videoplay/" + encodeURIComponent(mediaID);

        if (this.context) {
            jsonurl += "?userOrProducerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken) + "&role=producer";
            if(this.detectiOS()) {
                jsonurl += "&playerType=hls";
            } else {
                jsonurl += "&playerType=mp4";
            }
            jsonurl += "&viewContextType=control";
            jsonurl += "&viewContextID=control";
        } else {
            alert("Cannot get video play url because of missing context");
        }

        let response: any = null;

        try {
            response = await fetch(jsonurl);
            let data = await response.json();
            this.setState({ singleVideoAPIAction: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ videoPlayURLError: "Error: " + data.error });
                    if(!ApiHelper.checkResponse(data,this.context) && this.context !== null) {
                        this.context.producerID = "";
                        alert("Please relogin");
                    }
                }
            }

            this.setState({ videoPlayURL: data.url });

        } catch (error: any) {
            this.setState({ videoPlayURLError: "Error fetching data: " + error.toString() });
            this.setState({ singleVideoAPIAction: false });
        }
    }

    closeClickedMediaData() {
        this.setState({clickedMediaData: null});
    }

    clickedMediaDataModalClose() {
        this.setState({clickedMediaData: null});
    }

    getGuid() {
        let myguid:string = uuidv4();

        return myguid;
    }

    handleExtraPagesDropdownToggle() {
        this.setState({ extraPagesDropdownOpen: !this.state.extraPagesDropdownOpen });
    }

    handlePageChange(newPage:number):void {
        this.setState({curPage: newPage},this.fetchUserMessages.bind(this,this.state.conversationID));
    }

    render() {
        if (this.context) {
            if (this.context.producerID === "") {
                return <Redirect to="/" push={true} />
            }
        } else {
            return <Redirect to="/" push={true} />
        }

        return (
            <div className='w-100'>
                <div id='MessengerDIV' className='w-100'>
                    <Modal isOpen={this.state.clickedMediaData !== null} size="md" onClosed={this.clickedMediaDataModalClose.bind(this)}>
                        <ModalHeader close={<button className="close" onClick={this.clickedMediaDataModalClose.bind(this)}>×</button>}>
                            { this.state.clickedMediaData ? this.state.clickedMediaData.type : null }
                        </ModalHeader>
                        <ModalBody>
                        { this.state.singleVideoAPIAction ?
                        <div className="d-flex flex-column align-items-center justify-content-center w-100 h-100">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">{this.getText("Loading")}...</span>
                            </div>
                        </div>
                        :
                        this.state.videoPlayURL !== "" ? <video style={{width: "100%"}} controls autoPlay src={this.state.videoPlayURL} playsInline muted /> : 
                        this.state.clickedMediaData ? <img x-mediatype={this.state.clickedMediaData.type} x-mediaid={this.state.clickedMediaData.mediaID} src={this.getSingleImagePreviewUrl(this.state.clickedMediaData)} className="w-100" /> 
                        : null 
                        }
                        { this.state.videoPlayURLError !== "" ? <div className="alert alert-danger">{this.state.videoPlayURLError}</div> : null }
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={this.closeClickedMediaData.bind(this)}>{this.getText("Close")}</Button>
                        </ModalFooter>
                    </Modal>

                    <h5 className="msgheadlineright ml-2 mt-2 pb-2 mb-2 link-dark text-decoration-none border-bottom">
                        <span className="fs-5 fw-semibold">
                            {this.getText("ConversationWith")} {this.state.selectedUserPseudo}
                            <Button className='d-inline-block' style={{marginLeft: "10px"}} size='sm' onClick={this.fetchUserMessages.bind(this,this.state.conversationID)}><FontAwesomeIcon icon={solid('refresh')}></FontAwesomeIcon></Button>
                        </span>
                    </h5>

                    {this.state.errorMessage !== "" ? <Alert color="danger">{this.state.errorMessage}</Alert> : null}

                    {this.state.fetchingMessages ? <p className="pt-2"><FontAwesomeIcon icon={solid('cog')} spin size="4x" /></p> :
                        <div id="MessengerConversationDIV" className='w-100'>
                            {this.state.messages.length === 0 ?
                            <p>{this.getText("NoMessages")}</p> :
                            <ul className="list-group list-group-flush border-bottom scrollarea">
                                {this.state.messages.map(msg =>
                                    <div className="list-group-item list-group-item-action py-3 lh-sm">
                                        <div className="d-flex w-100 align-items-center justify-content-between flex-wrap">
                                            <strong className="mb-1">{msg.clickTime ? <FontAwesomeIcon icon={solid('envelope-open')} fixedWidth /> : <FontAwesomeIcon icon={solid('envelope')} fixedWidth />} {msg.sender}</strong>
                                            { msg.paidSendPrice !== null ? msg.paidSendPrice > 0 ? <small className='badge bg-secondary'>{new Number(msg.paidSendPrice / 100).toFixed(2)} <FontAwesomeIcon icon={solid('coins')} fixedWidth /></small> : null : null }
                                            <small>{DateTime.fromISO(msg.sendTime.toString()).toLocaleString(DateTime.DATETIME_MED)}</small>
                                            { msg.schedule ? <span className='badge bg-secondary'> {!msg.scheduleExpired ? <FontAwesomeIcon icon={solid('clock')} fixedWidth /> : null}
                                            &nbsp;{msg.scheduleExpired ? this.getText("ScheduleExpired") : this.getText("Scheduled")}
                                            </span> : null }
                                        </div>
                                        <div className="w-100 small">{msg.msgtext}</div>
                                        { msg.freeMedia.length > 0 ? 
                                        <div>
                                            <small><b>{msg.sender == this.context?.producerID ? this.getText("Kostenlose Inhalte") : this.getText("Anhänge")}:</b></small><br />
                                            <div className="d-flex flex-wrap" style={{gap: "4px"}}>
                                                {msg.freeMedia.map((item,index) =>
                                                    <SmallMediaPreview key={"SmallMediaPreviewFreeMessage-" + msg.msgID + "-" + index} context="message" contextID={msg.msgID} guid={this.getGuid()} type={item.type} mediaID={item.mediaID} reviewStatus={item.reviewStatus} clicked={this.freeMediaClicked.bind(this,item,false)} />
                                                )}
                                            </div>
                                        </div>
                                        : null }
                                        { msg.payMedia.length > 0 ? 
                                        <div>
                                            <h6>{this.getText("Kostenpflichtige Inhalte")} <Badge color="secondary">{this.getText("Price")}: {msg.price}</Badge>:</h6>
                                            <div className="d-flex flex-wrap" style={{gap: "4px"}}>
                                                {msg.payMedia.map((item,index) =>
                                                    <SmallMediaPreview key={"SmallMediaPreviewPayMessage-" + msg.msgID + "-" + index} context="message" contextID={msg.msgID} guid={this.getGuid()} type={item.type} mediaID={item.mediaID} reviewStatus={item.reviewStatus} clicked={this.payMediaClicked.bind(this,item,false)} />
                                                )}
                                            </div>
                                        </div>
                                        : null }
                                    </div>
                                )}
                            </ul>
                        }
                        </div>
                    }

                    {this.state.pagesArray ? this.state.pagesArray.length > 1 ?
                    <Pagination size='sm' className='mt-2 mb-0'>
                        { this.state.pagesArray.map((page,index) =>
                            <PaginationItem style={{display: "list-item"}} active={page.active} className={page.page < this.state.maxPages ? "" : "d-none"}><PaginationLink onClick={this.handlePageChange.bind(this,page.page)} style={{color: "#000"}}>{page.page + 1}</PaginationLink></PaginationItem>
                        )}
                        { this.state.pagesArray.length > 10 ?
                        <Dropdown className='paginationExtraDropdown' isOpen={this.state.extraPagesDropdownOpen} toggle={this.handleExtraPagesDropdownToggle.bind(this)} direction="down">
                            <DropdownToggle size='sm' caret>{this.state.curPage >= this.state.maxPages ? this.state.curPage : this.getText("More")}</DropdownToggle>
                            <DropdownMenu>
                                { this.state.pagesArray.map((page,index) =>
                                    <DropdownItem className={page.page >= this.state.maxPages ? page.active ? "bg-secondary text-white" : "" : "d-none"} onClick={this.handlePageChange.bind(this,page.page)}>{page.page + 1}</DropdownItem>
                                )}
                            </DropdownMenu>
                        </Dropdown> : null }
                    </Pagination>
                    : null : null }
                </div>
            </div>
        )
    }
}