import React from 'react';
import { AxiosResponse } from 'axios';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheckCircle, faCheck, faPencil } from '@fortawesome/pro-solid-svg-icons';
import { Card, CardBody, CardHeader, FormGroup, Button, Label, Input, CardTitle, Col } from 'reactstrap';
import Moment from 'moment';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';


export interface ShopItemEntryProps {
    index: number;
    id: number;
    //selectCallback: (ID: number) => void;
    selectCallback: (itemID: number) => void;
    entry: {};
}

interface ShopItemEntry {
    id: number;
    state: ShopItemEntryState;
    props: ShopItemEntryProps;
    PublishDate: string;
    DescriptionLong: string;
    DescriptionShort: string;
    PreviewImage: string;
    selectCallback: (itemID: number) => void;
    ProducerID: string;
}

interface ShopItemEntryState {
    id: number;
    entry: any;
}

class ShopItemEntry extends React.Component<ShopItemEntryProps> {
    constructor(props: ShopItemEntryProps) {
        super(props);
        this.selectCallback = props.selectCallback;

        this.state = {
            id:props.id,
            entry: props.entry
        }

        library.add(faTimes);
        library.add(faCheck);
        library.add(faPencil);
    }

    render() {
        return (
        <tr>
                <td>{this.state.entry.isActive ? <FontAwesomeIcon icon={["fas", "times"]} /> : <FontAwesomeIcon icon={["fas", "check"]}  />}</td>
                <td>{this.state.entry.userCanBuyThisArticle ? <FontAwesomeIcon icon={["fas", "times"]} /> : <FontAwesomeIcon icon={["fas", "check"]} />}</td>
                <td><Link to={"/ShopItemEdit/" + this.state.entry.id}>{this.state.entry.artikelnummer}</Link></td>
                <td><Link to={"/ShopItemEdit/" + this.state.entry.id}>{this.state.entry.articleName}</Link></td>
                <td>{this.state.entry.amountAvailable}</td>
                <td>{this.state.entry.amountSold}</td>
                <td>{Moment(this.state.entry.publishDate).format('DD.MM.YYYY')}</td>
                <td>{this.state.entry.digitalGood ? <FontAwesomeIcon icon={["fas", "times"]} /> : <FontAwesomeIcon icon={["fas", "check"]} />}</td>
                <td><Link to={"/ShopItemEdit/" + this.state.entry.id}><Button size="sm" color="success"><FontAwesomeIcon icon={["fas", "pencil"]} />&nbsp;&nbsp;Editieren</Button></Link></td>
        </tr>
                        
        )
    }
}

export default ShopItemEntry;