import React, { Component, FormEvent } from 'react';
import { Modal,ModalHeader,ModalBody,ModalFooter,Alert, Container, Form, FormGroup, Button, Label, Input, ButtonGroup, Dropdown, DropdownMenu, DropdownItem } from 'reactstrap';
import { LocalStorageWorker } from '../StorageHelper';
import { CryptoHelper } from '../CryptoHelper.js';
import { AppCtx, AppContextInterface, ApiHelper } from '../AppContextInterface';
import { Redirect } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DateTime } from 'luxon';
import VideoUpload from './VideoUpload';

interface VideoThumbModalProps {
    streamName: string;
    mode: string;
    setThumb: (mode:string,thumbUrl:string) => void;
    close: () => void;
}

interface VideoThumbModalState {
    errorMessage: string,
    fetching: boolean,
    language: string,
    isOpen: boolean,
    streamName: string,
    mode: string,
    thumbs: Array<VideoThumb>
}

interface VideoThumb {
    imageurl: string,
    s3key: string,
    s3prefix: string
}

export interface VideoThumbModal extends React.Component {
    state: VideoThumbModalState;
    props: VideoThumbModalProps;
    contextType: AppContextInterface;
}

export class VideoThumbModal extends React.Component {
    static contextType = AppCtx;
    context!: React.ContextType<typeof AppCtx>;

    constructor(props: VideoThumbModalProps) {
        super(props);

        let contextLanguage = "de";

        if (this.context) {
            if (this.context.language !== null && this.context.language !== "") {
                contextLanguage = this.context.language;
            }
        }

        this.state = {
            errorMessage: "",
            fetching: false,
            language: contextLanguage,
            isOpen: true,
            streamName: props.streamName,
            mode: props.mode,
            thumbs: []
        }
    }

    componentDidMount() {
        this.fetchThumbs();
    }

    fetchThumbs = async () => {
        this.setState({ fetching: true });
        this.setState({ errorMessage: "" });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/videothumbs/" + encodeURIComponent(this.state.streamName);

        if (this.context) {
            jsonurl += "?producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        }

        let response: any = null;

        try {
            response = await fetch(jsonurl);
            let data = await response.json();
            this.setState({ fetching: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                    if(!ApiHelper.checkResponse(data,this.context) && this.context !== null) {
                        this.context.producerID = "";
                        alert("Please relogin");
                    }
                }
            }

            this.setState({ thumbs: data.thumbs });

        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching data: " + error.toString() });
            this.setState({ fetching: false });
        }
    }

    clickThumb = async(thumb:VideoThumb) => {
        this.setState({isOpen: false});
        this.props.setThumb(this.state.mode,thumb.imageurl);
        this.props.close();
    }

    closeClick() {
        this.setState({isOpen: false});
        this.props.close();
    }

    getText(exp: string) {
        if (this.state.language === "de") {
            if (exp === "SelectVideoImage") {
                return "Video Bild auswählen";
            }
            if(exp === "Cancel") {
                return "Abbrechen";
            }
            if(exp === "Close") {
                return "Schliessen";
            }
            if(exp === "NoVideoImages") {
                return "Es gibt keine automatisch generierten Bilder zu diesem Video";
            }
        } else {
            if (exp === "Username") {
                return "Livecam Profile";
            }
            if (exp === "SelectVideoImage") {
                return "Select Video Image";
            }
            if(exp === "Cancel") {
                return "Cancel";
            }
            if(exp === "Close") {
                return "Close";
            }
            if(exp === "NoVideoImages") {
                return "No automatically generated video images available";
            }
        }

        return exp;
    }

    render() {
        if (this.context) {
            if (this.context.producerID === "") {
                return <Redirect to="/" push={true} />
            }
        } else {
            return <Redirect to="/" push={true} />
        }

        return (
            <Modal isOpen={this.state.isOpen} size="lg" onClosed={this.closeClick.bind(this)}>
                <ModalHeader close={<button className="close" onClick={this.closeClick.bind(this)}>×</button>}>
                    { this.getText("SelectVideoImage") }
                </ModalHeader>
                <ModalBody>
                    {this.state.errorMessage !== "" ? <Alert color="danger">{this.state.errorMessage}</Alert> : null}
                    <div className="d-flex flex-wrap" style={{gap: "4px"}}>
                        {this.state.fetching ? <p className="pt-4"><FontAwesomeIcon icon={solid('cog')} spin size="4x" /></p> :
                            this.state.thumbs.length === 0 ? <div className="w-100">{this.getText("NoVideoImages")}</div> : null }
                            {this.state.thumbs.map(thumb =>
                                <div className="videoThumb w-25">
                                    <img src={thumb.imageurl} onClick={this.clickThumb.bind(this,thumb)} className="videoThumbItem w-100" />
                                </div>
                            )
                        }
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={this.closeClick.bind(this)}>{this.getText("Close")}</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default VideoThumbModal;