import React, { Component, FormEvent } from 'react';
import { Alert, Modal, ModalHeader, ModalBody, ModalFooter, Container, Form, FormGroup, Button, Label, Input, ButtonGroup, Dropdown, DropdownMenu, DropdownItem } from 'reactstrap';
import { AppCtx, AppContextInterface, ApiHelper } from '../AppContextInterface';
import { Redirect } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import {ImageList,ImageData} from './ImageList';
import { MessagingPostMediaData } from './Timeline';

interface ImageModalProps {
    open:boolean;
    mediaAddMode: string;
    singleSelect: boolean;
    closeCallback():void;
    itemClickCallback(clickedImage:MessagingPostMediaData):void;
    itemSelectMulti(images:MessagingPostMediaData[]):void;
    changeMediaAddMode(mode:string):void;
}

export interface ImageModal extends React.Component {
    state: ImageModalState;
    props: ImageModalProps;
    contextType: AppContextInterface;
    updateTimer:NodeJS.Timer;
}

interface ImageModalState {
    isOpen: boolean;
    errorMessage: string;
    infoMessage: string;
    language: string;
    mediaAddMode: string;
    singleSelect: boolean;
}

export class ImageModal extends React.Component {
    static contextType = AppCtx;
    context!: React.ContextType<typeof AppCtx>;

    constructor(props: ImageModalProps) {
        super(props);

        let contextLanguage = "de";

        if (this.context) {
            if (this.context.language !== null && this.context.language !== "") {
                contextLanguage = this.context.language;
            }
        }

        this.state = {
            isOpen: props.open,
            errorMessage: "",
            infoMessage: "",
            language: contextLanguage,
            mediaAddMode: props.mediaAddMode,
            singleSelect: props.singleSelect
        }
    }

    componentDidMount() {
        this.updateTimer = setInterval(this.watchPropsUpdate.bind(this),250);
    }

    watchPropsUpdate() {
        if(this.props.open != this.state.isOpen) {
            this.setState({isOpen: this.props.open});
        }
        if(this.props.mediaAddMode !== this.state.mediaAddMode) {
            this.setState({mediaAddMode: this.props.mediaAddMode});
        }
    }

    componentWillUnmount() {
        if(this.updateTimer) {
            clearInterval(this.updateTimer);
        }
    }

    getText(exp: string) {
        if (this.state.language === "de") {
            if(exp === "Kostenloses Bild hinzufügen") {
                return "Kostenloses Bild hinzufügen";
            }
            if(exp === "Kostenpflichtiges Bild hinzufügen") {
                return "Kostenpflichtiges Bild hinzufügen";
            }
            if(exp === "Bild zur Nachricht hinzufügen") {
                return "Bild zur Nachricht hinzufügen";
            }
            if(exp === "Profilbild auswählen") {
                return "Profilbild auswählen";
            }
            if(exp === "ChangeToPay") {
                return "Zu kostenpflichtig wechseln";
            }
            if(exp === "ChangeToFree") {
                return "Zu kostenfrei wechseln";
            }
            if (exp === "Username") {
                return "Pseudo";
            }
            if (exp === "Reviewed") {
                return "Geprüft";
            }
            if (exp === "In review") {
                return "In Prüfung";
            }
            if (exp === "Public") {
                return "Veröffentlicht";
            }
            if (exp === "Not public") {
                return "Privat";
            }
            if(exp === "Change") {
                return "Ändern";
            }
        } else {
            if(exp === "Kostenloses Bild hinzufügen") {
                return "Add free image";
            }
            if(exp === "Kostenpflichtiges Bild hinzufügen") {
                return "Add paid image";
            }
            if(exp === "Bild zur Nachricht hinzufügen") {
                return "Add image to message";
            }
            if(exp === "Profilbild auswählen") {
                return "Select profile image";
            }
            if(exp === "ChangeToPay") {
                return "Change to pay";
            }
            if(exp === "ChangeToFree") {
                return "Change to free";
            }
            if (exp === "Username") {
                return "Livecam Profile";
            }
            if(exp === "Change") {
                return "Change";
            }
        }

        return exp;
    }

    modalClosed() {
        this.setState({isOpen: false});
        this.props.closeCallback();
    }

    confirmSelection(images:ImageData[]) {
        if(images) {
            let multiArray:MessagingPostMediaData[] = [];

            for(let i:number = 0; i < images.length; i++) {
                if(images[i] !== null) {
                    multiArray.push({
                        mediaID: images[i].imageID,
                        type: "image",
                        reviewStatus: images[i].reviewStatus
                    });
                }
            }

            this.props.itemSelectMulti(multiArray);
        }
    }

    imageClicked(clickedImage:ImageData) {
        console.log("ImageModal " + clickedImage.imageID + " clicked");
        if(clickedImage) {
            this.props.itemClickCallback({
                mediaID: clickedImage.imageID,
                type: "image",
                reviewStatus: clickedImage.reviewStatus
            });
        } else {
            this.setState({errorMessage: "Invalid image clicked"});
        }
    }

    getHeader() {
        if(this.state.mediaAddMode === "free") {
            return this.getText("Kostenloses Bild hinzufügen");
        }
        if(this.state.mediaAddMode === "pay") {
            return this.getText("Kostenpflichtiges Bild hinzufügen");
        }
        if(this.state.mediaAddMode === "message") {
            return this.getText("Bild zur Nachricht hinzufügen");
        }
        if(this.state.mediaAddMode === "big" || this.state.mediaAddMode === "small" || this.state.mediaAddMode === "bigsoft" || this.state.mediaAddMode === "smallsoft") {
            return this.getText("Profilbild auswählen");
        }
    }

    changeMediaAddMode() {
        if(this.state.mediaAddMode === "free") {
            this.props.changeMediaAddMode("pay");
            this.setState({mediaAddMode: "pay"});
        }
        if(this.state.mediaAddMode === "pay") {
            this.props.changeMediaAddMode("free");
            this.setState({mediaAddMode: "free"});
        }
    }
    
    render() {
        if (this.context) {
            if (this.context.producerID === "") {
                return <Redirect to="/" push={true} />
            }
        } else {
            return <Redirect to="/" push={true} />
        }

        return (
            <Modal isOpen={this.state.isOpen} size="lg" onClosed={this.modalClosed.bind(this)}>
                <ModalHeader close={<button className="close" onClick={this.modalClosed.bind(this)}>×</button>}>
                    { this.getHeader() }
                    { this.state.mediaAddMode === "free" ?
                    <Button size="sm" style={{marginLeft: "10px"}} onClick={this.changeMediaAddMode.bind(this)}>{this.getText("ChangeToPay")}</Button>
                    : null }
                    { this.state.mediaAddMode === "pay" ?
                    <Button size="sm" style={{marginLeft: "10px"}} onClick={this.changeMediaAddMode.bind(this)}>{this.getText("ChangeToFree")}</Button>
                    : null }
                </ModalHeader>
                <ModalBody>
                    <div id='ImagesListDIV'>
                        <Alert isOpen={this.state.infoMessage !== ""} color="info">{this.state.infoMessage}</Alert>
                        <Alert isOpen={this.state.errorMessage !== ""} color="danger">{this.state.errorMessage}</Alert>
                        <ImageList singleSelect={this.state.singleSelect} onImageClick={this.imageClicked.bind(this)} confirmSelection={this.confirmSelection.bind(this)} modalMode={true} mediaAddMode={this.state.mediaAddMode} />
                    </div>
                </ModalBody>
            </Modal>
        )
    }
}

export default ImageModal;