import React, { Component, FormEvent } from 'react';
import { Alert, Container, Form, FormGroup, Button, Label, Input } from 'reactstrap';
import { LocalStorageWorker } from '../StorageHelper';
import { CryptoHelper } from '../CryptoHelper.js';
import { AppCtx, AppContextInterface, ApiHelper } from '../AppContextInterface';
import { Redirect } from 'react-router'

interface LivecamEditProps {

}

export interface LivecamEdit {
    state: LivecamEditState;
    props: LivecamEditProps;
    contextType: AppContextInterface;
}

interface LivecamEditState {
    website: string;
    websiteTitle: string;
    customLink: string;
    showLivecamLinkType: string;
    showLivecam: boolean;
    submitting: false;
    infoMessage: string;
    errorMessage: string;
    language: string;
}

export class LivecamEdit extends React.Component {
    static contextType = AppCtx;
    context!: React.ContextType<typeof AppCtx>;

    constructor(props: LivecamEditProps) {
        super(props);

        let myLocalStorage = new LocalStorageWorker();

        let contextLanguage = "de";

        if (this.context) {
            if (this.context.language !== null && this.context.language !== "") {
                contextLanguage = this.context.language;
            }
        }

        this.state = { 
            website: '', 
            websiteTitle: '',
            customLink: '',
            showLivecam: false,
            showLivecamLinkType: '',
            submitting: false, 
            infoMessage: '',
            errorMessage: '', 
            language: contextLanguage
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    handleWebsiteChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ website: event.target.value });
    }

    handleCustomLinkChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ customLink: event.target.value });
    }

    handleWebsiteTitleChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ websiteTitle: event.target.value });
    }

    handleShowLivecamChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ showLivecam: event.target.value });
    }

    handleShowLivecamLinkTypeChange(event: React.ChangeEvent<HTMLSelectElement>) {
        this.setState({ showLivecamLinkType: event.target.value });
    }

    handleSubmit(event: FormEvent) {
        event.preventDefault();
        this.formSubmitted();
    }

    fetchData = async () => {
        this.setState({ errorMessage: "" });
        this.setState({ infoMessage: "" });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/livecamprops";

        if (this.context) {
            if(!this.context.loggedin) {
                await ApiHelper.checkLoginStatus(this.context);
            }
            jsonurl += "?producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        }

        this.setState({ submitting: true });

        let response: any = null;

        try {
            response = await fetch(jsonurl);
            let data = await response.json();
            this.setState({ submitting: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                } else {
                    this.setState({ website: data.website });
                    this.setState({ websiteTitle: data.websiteTitle });
                    this.setState({ customLink: data.customLink });
                    this.setState({ showLivecam: data.showLivecam });
                    this.setState({ showLivecamLinkType: data.showLivecamLinkType });
                }
            }
        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching data: " + error.toString() });
            this.setState({ submitting: false });
        }
    }

    formSubmitted = async () => {
        this.setState({ emailChanged: false });
        this.setState({ emailMessage: "" });
        this.setState({ passwordChanged: false });
        this.setState({ passwordMessage: "" });
        this.setState({ errorMessage: "" });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/livecamprops";

        jsonurl += "?showLivecamLinkType=" + encodeURIComponent(this.state.showLivecamLinkType);
        jsonurl += "&website=" + encodeURIComponent(this.state.website);
        jsonurl += "&websiteTitle=" + encodeURIComponent(this.state.websiteTitle);
        jsonurl += "&customLink=" + encodeURIComponent(this.state.customLink);

        if(this.state.showLivecam) {
            jsonurl += "&showLivecam=true";
        } else {
            jsonurl += "&showLivecam=false";
        }

        if (this.context) {
            jsonurl += "&producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        }

        this.setState({ submitting: true });

        let response:any = null;

        try {
            const settings = {
                method: 'POST',
                headers: {
                    Accept: 'application/json'
                }
            };
            response = await fetch(jsonurl, settings);
            let data = await response.json();
            this.setState({ submitting: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                    if(!ApiHelper.checkResponse(data,this.context) && this.context !== null) {
                        this.context.producerID = "";
                        alert("Please relogin");
                    }
                }

                if(data.success) {
                    this.setState({ infoMessage: "Changes saved" });
                } else {
                    this.setState({ errorMessage: "Error: " + data.error });
                }
            }
        } catch (error: any) {
            this.setState({ errorMessage: "Error making changes: " + error.toString() });
            this.setState({ submitting: false });
        }
    }

    getText(exp: string) {
        if (this.state.language === "de") {
            if(exp === "ShowLivecam") {
                return "Zeige meinen Fan-Usern, dass ich mit der Livecam online bin";
            }
            if(exp === "ShowMyProfileOnSusiLive") {
                return "Zeige mein Profil auf susi.live";
            }
            if(exp === "ShowMyWebsite") {
                return "Zeige eigenen Link zu meiner Website";
            }
            if(exp === "CustomLink") {
                return "Eigener Link";
            }
            if(exp === "CustomLinkPlaceholder") {
                return "Trage hier den Link ein, der angezeigt werden soll, wenn Du mit der Livecam online bist";
            }
            if(exp === "MyWebsite") {
                return "Link zu meiner Website";
            }
            if(exp === "MyWebsitePlaceholder") {
                return "Trage hier den Link zu Deiner Website ein";
            }
            if(exp === "WebsiteTitle") {
                return "Name der Website (optional)";
            }
            if(exp === "WebsiteTitlePlaceholder") {
                return "Trage hier den Namen Deiner Website ein (wird angezeigt, wenn Du mit der Livecam online bist)";
            }
            if(exp === "ShowCustomLink") {
                return "Zeige einen Link den ich hier eintragen möchte";
            }
            if(exp === "LivecamEditTitle") {
                return "Livecam-Einstellungen";
            }
            if(exp === "LivecamEditSubtitle") {
                return "Was passiert, wenn Du mit Deiner Livecam online gehst";
            }
            if (exp === "Username") {
                return "Pseudo";
            }
            if (exp === "Password") {
                return "Passwort";
            }
            if (exp === "UsernameHelp") {
                return "Das Pseudo bzw. der Name mit dem Dein LivecamEdit-Profil angezeigt wird";
            }
            if (exp === "LivecamEditSubtitle") {
                return "Hier kannst Du Änderungen an Deinem Account vornehmen";
            }
            if (exp === "Save Data") {
                return "Logindaten merken";
            }
            if (exp === "Submit") {
                return "Abschicken";
            }
            if (exp === "ChangesSuccess") {
                return "Die Änderungen wurden übernommen";
            }
            if (exp === "Error") {
                return "Fehler";
            }
            if (exp === "EMailChanged") {
                return "Deine EMail-Adresse wurde geändert";
            }
            if (exp === "PasswordChanged") {
                return "Dein Passwort wurde geändert";
            }
            if(exp === "Save") {
                return "Speichern";
            }
            if(exp === "Current") {
                return "Aktuell";
            }
            if(exp === "susi") {
                return "Mein Profil bei susi.live";
            }
            if(exp === "cms") {
                return "Meine Website";
            }
            if(exp === "custom") {
                return "Eigener Link";
            }
        } else {
            if(exp === "ShowLivecam") {
                return "Show my fans that I am online with the livecam";
            }
            if(exp === "ShowMyProfileOnSusiLive") {
                return "Show my profile on susi.live";
            }
            if(exp === "ShowMyWebsite") {
                return "Show a link to my website";
            }
            if(exp === "ShowCustomLink") {
                return "Show a link that I enter here";
            }
            if(exp === "CustomLink") {
                return "Custom link";
            }
            if(exp === "CustomLinkPlaceholder") {
                return "Enter the link here that should be displayed when you go online with the livecam";
            }
            if(exp === "MyWebsite") {
                return "Link to my website";
            }
            if(exp === "MyWebsitePlaceholder") {
                return "Enter the link to your website here";
            }
            if(exp === "WebsiteTitle") {
                return "Name of the website (optional)";
            }
            if(exp === "WebsiteTitlePlaceholder") {
                return "Enter the name of your website here (will be displayed when you go online with the livecam)";
            }
            if(exp === "LivecamEditTitle") {
                return "Livecam Settings";
            }
            if(exp === "LivecamEditSubtitle") {
                return "What happens when you go online with your livecam";
            }
            if (exp === "Username") {
                return "LivecamEdit Profile";
            }
            if (exp === "UsernameHelp") {
                return "This is the name that has been assigned to your LivecamEdit profile";
            }
            if (exp === "LivecamEditSubtitle") {
                return "You can change settings for your account here";
            }
            if (exp === "ChangesSuccess") {
                return "Changes successfully applied";
            }
            if (exp === "EMailChanged") {
                return "Your email address has been changed";
            }
            if (exp === "PasswordChanged") {
                return "Your password has been changed";
            }
            if(exp === "Save") {
                return "Save";
            }
            if(exp === "Current") {
                return "Current";
            }
            if(exp === "susi") {
                return "My susi.live Profile";
            }
            if(exp === "cms") {
                return "My Website";
            }
            if(exp === "custom") {
                return "Custom Link";
            }
        }

        return exp;
    }

    render() {
        if (this.context) {
            if (this.context.producerID === "") {
                return <Redirect to="/" push={true} />
            }
        } else {
            return <Redirect to="/" push={true} />
        }

        return (
            <div id='LivecamEditDIV' className='container pt-4'>
                <h4 className="pageHeadline">{this.getText("LivecamEditTitle")}</h4>
                <div className="mb-4"><small>{this.getText("LivecamEditSubtitle")}</small></div>

                {this.state.infoMessage !== "" ? <Alert color="info">{this.state.infoMessage}</Alert> : null}
                {this.state.errorMessage !== "" ? <Alert color="danger">{this.state.errorMessage}</Alert> : null}

                <FormGroup className="mb-2" controlId="showLink">
                    <Input className='showLivecamCheckbox' type='checkbox' id='showLink' checked={this.state.showLivecam} onChange={this.handleShowLivecamChange.bind(this)} />
                    <Label for="showLink">{this.getText("ShowLivecam")}</Label>
                </FormGroup>
                { this.state.showLivecam ?
                <Form onSubmit={this.handleSubmit.bind(this)}>
                    <FormGroup className="mb-2" controlId="myLinkType">
                        <Label>{this.getText("LinkType")} <span className='badge badge-secondary'>{this.getText("Current")} : {this.getText(this.state.showLivecamLinkType)}</span></Label>
                        <select className='form-control' size={1} id="myLinkType" onChange={this.handleShowLivecamLinkTypeChange.bind(this)}>
                            <option selected={this.state.showLivecamLinkType === "susi" ? true : false} value="susi">{this.getText("ShowMyProfileOnSusiLive")}</option>
                            <option selected={this.state.showLivecamLinkType === "cms" ? true : false} value="cms">{this.getText("ShowMyWebsite")}</option>
                            <option selected={this.state.showLivecamLinkType === "custom" ? true : false} value="custom">{this.getText("ShowCustomLink")}</option>
                        </select>
                    </FormGroup>
                    { this.state.showLivecamLinkType === "cms" ?
                    <FormGroup className="mb-2" controlId="ctrlWebsiteTitle">
                        <Label>{this.getText("WebsiteTitle")}</Label>
                        <Input id="ctrlWebsiteTitle" type="text" placeholder={this.getText("WebsiteTitlePlaceholder")} value={this.state.websiteTitle} onChange={this.handleWebsiteTitleChange.bind(this)} />
                    </FormGroup> : null }
                    { this.state.showLivecamLinkType === "cms" ?
                    <FormGroup className="mb-2" controlId="myWebsite">
                        <Label>{this.getText("MyWebsite")}</Label>
                        <Input id="myWebsite" type="text" placeholder={this.getText("MyWebsitePlaceholder")} value={this.state.website} onChange={this.handleWebsiteChange.bind(this)} />
                    </FormGroup> : null }
                    { this.state.showLivecamLinkType === "custom" ?
                    <FormGroup className="mb-2" controlId="ctrlCustomLink">
                        <Label>{this.getText("CustomLink")}</Label>
                        <Input id="ctrlCustomLink" type="text" placeholder={this.getText("CustomLinkPlaceholder")} value={this.state.customLink} onChange={this.handleCustomLinkChange.bind(this)} />
                        </FormGroup> : null }
                    <Button variant="primary" disabled={this.state.submitting} type="submit">{this.getText("Save")}</Button>
                </Form> : 
                <Form onSubmit={this.handleSubmit.bind(this)}>
                    <Button variant="primary" disabled={this.state.submitting} type="submit">{this.getText("Save")}</Button>
                </Form> }
            </div>
        );
    }
}

export default LivecamEdit;