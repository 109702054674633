import React, { Component } from 'react';
import { Collapse, Container, Row, Col, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { AppCtx, AppContextInterface, ApiHelper } from '../AppContextInterface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro';

interface NavMenuState {
    collapsed: boolean;
    loggedin: boolean;
    pseudo: string;
    language: string;
    isDebug: boolean;
    unreadMessages: number;
    unseenEvents: number;
    logoURL: string;
    siteTitle: string;
    platform: string;
}

export interface NavMenu {
    state: NavMenuState;
    checkStatusTimer: NodeJS.Timer;
    onlineMarkerTimer: NodeJS.Timer;
    checkUnreadTimer: NodeJS.Timer;
    checkUnseenTimer: NodeJS.Timer;
    checkUnreadCounter: number;
}

export class NavMenu extends Component {
    static displayName = NavMenu.name;
    static contextType = AppCtx;
    context!: React.ContextType<typeof AppCtx>;

    constructor (props:any) {
        super(props);

        let startCollapsed:boolean = true;

        if (window.innerWidth >= 992) {
            startCollapsed = false;
        }

        let logoURL:string = "";
        let siteTitle:string = "";

        if(window.location.hostname === "control.deine.fans") {
            logoURL = "https://d39j9qa9t0x6xj.cloudfront.net/x320/fans_logoBETA.png";
            siteTitle = "Deine Fans";
        } else if(window.location.hostname === "control.cherry.fans") {
            logoURL = "https://d39j9qa9t0x6xj.cloudfront.net/logos/cherry_fans_logo.webp";
            siteTitle = "Cherry Fans";
        } else if(window.location.hostname === "control.livestrip.fans") {
            logoURL = "https://d3jg4n5aipvur8.cloudfront.net/live-strip-ohne-domainendung.png";
            siteTitle = "Live-Strip Fans";
        } else {
            logoURL = "https://d3jg4n5aipvur8.cloudfront.net/live-strip-ohne-domainendung.png";
            siteTitle = "Live-Strip Control";
        }

        let myplatform:string = ApiHelper.getPlatform();

        if(this.context) {
            myplatform = this.context.platform;
        }

        this.state = {
            collapsed: startCollapsed,
            loggedin: false,
            pseudo: "",
            language: "de",
            isDebug: false,
            unreadMessages: 0,
            unseenEvents: 0,
            logoURL: logoURL,
            siteTitle: siteTitle,
            platform: myplatform
        };

        window.onresize = this.checkCollapsing.bind(this);

        this.checkUnreadCounter = 0;
    }

    componentDidMount() {
        this.onlineMarkerTimer = setInterval(this.markOnline.bind(this),28000);
        this.checkUnreadTimer = setInterval(this.checkUnread.bind(this), 1000);
        this.checkStatusTimer = setInterval(this.checkStatus.bind(this), 250);
        this.checkUnseenTimer = setInterval(this.checkUnseenEvents.bind(this), 11000);

        if(this.context) {
            if(this.context.producerID === "1962125312") {
                this.setState({ isDebug: true });
            }
        }
    }

    componentWillUnmount() {
        clearInterval(this.checkStatusTimer);
        clearInterval(this.checkUnreadTimer);
        clearInterval(this.onlineMarkerTimer);
    }

    checkUnread() {
        this.checkUnreadCounter++;

        let doUpdateUnread = false;

        if(this.checkUnreadCounter >= 30) {
            doUpdateUnread = true;
        }

        if(this.context) {
            if(this.context.doUpdateUnread) {
                doUpdateUnread = true;
            }
        }

        if(doUpdateUnread) {
            if (this.context) {
                if(!this.context.loggedin) {
                    return;
                }

                if(this.context.producerID !== "" && this.context.authToken !== "") {
                    var jsonurl = ApiHelper.getAPIUrl(this.context) + "/unreadmessages?producerid=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken) + "&role=producer&userID=none&platform=" + encodeURIComponent(this.state.platform);

                    fetch(jsonurl, {method: "GET"}).then(async(response: any) => {
                        let data = await response.json();
            
                        if(typeof(data.title) !== "undefined") {
                            console.error("Problem getting unread messages: " + data.title);
                            return;
                        } else {
                            if (data.error !== "" && data.error !== null) {
                                this.setState({ errorMessage: "Error: " + data.error });
                                if(!ApiHelper.checkResponse(data,this.context) && this.context !== null) {
                                    this.context.producerID = "";
                                    alert("Please relogin");
                                }
                            }
                        }
            
                        this.setState({unreadMessages: data.numUnread});
                        this.checkUnreadCounter = 0;
                    }).catch((error: any) => {
                        console.error("Error getting unread messages: " + error.toString());
                        this.checkUnreadCounter = 0;
                    });
                } else {
                    console.error("getting unread messages not logged in");
                }
            } else {
                console.error("getting unread messages no context");
            }
        }
    }

    checkUnseenEvents() {
        if (this.context) {
            if(!this.context.loggedin) {
                return;
            }

            if(this.context.producerID !== "" && this.context.authToken !== "") {
                var jsonurl = ApiHelper.getAPIUrl(this.context) + "/unseenevents?producerid=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken) + "&role=producer&userID=none&platform=" + encodeURIComponent(this.state.platform);

                fetch(jsonurl, {method: "GET"}).then(async(response: any) => {
                    let data = await response.json();
        
                    if(typeof(data.title) !== "undefined") {
                        console.error("Problem getting unseen events: " + data.title);
                        return;
                    } else {
                        if (data.error !== "" && data.error !== null) {
                            this.setState({ errorMessage: "Error: " + data.error });
                            if(!ApiHelper.checkResponse(data,this.context) && this.context !== null) {
                                this.context.producerID = "";
                                alert("Please relogin");
                            }
                        }
                    }
        
                    this.setState({unseenEvents: data.numEvents});
                }).catch((error: any) => {
                    console.error("Error getting unseen events: " + error.toString());
                });
            } else {
                console.error("getting unseen events not logged in");
            }
        } else {
            console.error("getting unseen events no context");
        }
    }

    markOnline() {
        if (this.context) {
            if(!this.context.loggedin) {
                return;
            }

            if(this.context.producerID !== "" && this.context.authToken !== "") {
                var jsonurl = ApiHelper.getAPIUrl(this.context) + "/onlinemarker?producerid=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);

                fetch(jsonurl, {method: "PUT"}).then(async(response: any) => {
                    let data = await response.json();
        
                    if(typeof(data.title) !== "undefined") {
                        console.error("Problem marking girl online: " + data.title);
                        return;
                    } else {
                        if (data.error !== "" && data.error !== null) {
                            this.setState({ errorMessage: "Error: " + data.error });
                            if(!ApiHelper.checkResponse(data,this.context) && this.context !== null) {
                                this.context.producerID = "";
                                alert("Please relogin");
                            }
                        }
                    }
        
                    if(data.success) {
                        console.log("Marked girl as online");
                    }
                }).catch((error: any) => {
                    console.error("Error marking girl online: " + error.toString());
                });
            } else {
                console.error("markOnline not logged in");
            }
        } else {
            console.error("markOnline no context");
        }
    }

    checkCollapsing() {
        let setCollapsed:boolean = true;

        if (window.innerWidth >= 992) {
            setCollapsed = false;
        }

        if (this.state.collapsed !== setCollapsed) {
            this.setState({ collapsed: setCollapsed });
        }
    }

    checkStatus() {
        if (this.context) {
            this.setState({ loggedin: this.context.loggedin });
            this.setState({ pseudo: this.context.pseudo });
            this.setState({ language: this.context.language });
        }
    }

    toggleNavbar () {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    getText(exp:string) {
        if(this.context?.language == "de") {
            if(exp == "Inbox") {
                return "Posteingang";
            }
            if(exp == "Images") {
                return "Bilder";
            }
            if(exp == "Profile") {
                return "Profil";
            }
            if(exp == "Signup") {
                return "Account erstellen";
            }
            if(exp == "PayoutData") {
                return "Abrechnungsdaten";
            }
            if(exp == "Conditions") {
                return "Auszahlungsmodell";
            }
            if(exp == "Subscriptions") {
                return "Abo-Level";
            }
            if(exp == "Logout") {
                return "Abmelden";
            }
        } else {
            if(exp == "PayoutData") {
                return "Account Data";
            }
            if(exp == "Conditions") {
                return "Payout Model";
            }
        }

        return exp;
    }

  render () {
    return (
      <div>
            <Navbar color="faded" light toggleable={true}>
                <NavbarBrand tag={Link} to="/" className='d-none d-lg-block me-auto'>
                    <img src={this.state.logoURL} alt={this.state.siteTitle} className="img-fluid" style={{width: "128px"}} /><br></br>
                    <small>{this.state.pseudo !== "" ? this.state.pseudo : this.state.siteTitle}</small>
                </NavbarBrand>
                <NavbarBrand className='d-block d-lg-none me-auto fansMobiInfobar'>
                    <small>{this.state.pseudo !== "" ? this.state.pseudo : this.state.siteTitle}</small>
                </NavbarBrand>
                <NavbarBrand tag={Link} to="/" className='d-block d-lg-none me-auto'>
                    <img id="fansLogoMobi" src={this.state.logoURL} alt={this.state.siteTitle} className="img-fluid" />
                </NavbarBrand>
                <NavbarBrand className='d-block d-lg-none me-auto fansMobiButtonbar'>
                    {this.state.loggedin ? <Link onClick={this.checkCollapsing.bind(this)} className="text-dark" to="/events"><FontAwesomeIcon icon={solid('bolt-lightning')} fixedWidth />{this.state.unseenEvents > 0 ? <span> <span className='badge bg-secondary'>{this.state.unseenEvents}</span></span> : null}</Link> : null}&nbsp;&nbsp;&nbsp;
                    {this.state.loggedin ? <Link onClick={this.checkCollapsing.bind(this)} className="text-dark" to="/inbox"><FontAwesomeIcon icon={solid('inbox')} fixedWidth />{this.state.unreadMessages > 0 ? <span> <span className='badge bg-secondary'>{this.state.unreadMessages}</span></span> : null}</Link> : null}&nbsp;&nbsp;&nbsp;
                    {this.state.loggedin ? <Link onClick={this.checkCollapsing.bind(this)} className="text-dark" to="/messenger"><FontAwesomeIcon icon={solid('envelope')} fixedWidth />{this.state.unreadMessages > 0 ? <span> <span className='badge bg-secondary'>{this.state.unreadMessages}</span></span> : null}</Link> : null}&nbsp;&nbsp;&nbsp;
                    {this.state.loggedin ? <Link onClick={this.checkCollapsing.bind(this)} className="text-dark" to="/timeline"><FontAwesomeIcon icon={solid('list-timeline')} fixedWidth /></Link> : null}&nbsp;&nbsp;&nbsp;
                    {this.state.loggedin ? <Link onClick={this.checkCollapsing.bind(this)} className="text-dark" to="/images"><FontAwesomeIcon icon={solid('image')} fixedWidth /></Link> : null}&nbsp;&nbsp;&nbsp;
                    {this.state.loggedin ? <Link onClick={this.checkCollapsing.bind(this)} className="text-dark" to="/videos"><FontAwesomeIcon icon={solid('video')} fixedWidth /></Link> : null}
                </NavbarBrand>
                <NavbarToggler onClick={this.toggleNavbar.bind(this)} className="d-block d-lg-none me-2" />
                <Collapse isOpen={!this.state.collapsed} navbar>
                    <Nav navbar>
                        <hr className='d-block d-lg-none' />
                        <Row>
                            <Col xs="6" lg="12">
                                {!this.state.loggedin ? 
                                <NavItem><NavLink onClick={this.checkCollapsing.bind(this)} tag={Link} className="text-dark" to="/login"><FontAwesomeIcon icon={solid('right-to-bracket')} fixedWidth /> {this.getText("Login")}</NavLink></NavItem> :
                                <NavItem><NavLink onClick={this.checkCollapsing.bind(this)} tag={Link} className="text-dark" to="/timeline"><FontAwesomeIcon icon={solid('list-timeline')} fixedWidth /> {this.getText("Timeline")}</NavLink></NavItem>
                                }
                            </Col>
                            <Col xs="6" lg="12">
                                {!this.state.loggedin ? 
                                this.state.platform === "fans" ?
                                <NavItem><NavLink onClick={this.checkCollapsing.bind(this)} className="text-dark" href="https://signup.livestrip.fans" target="_blank"><FontAwesomeIcon icon={solid('user-plus')} fixedWidth /> {this.getText("Signup")}</NavLink></NavItem> : 
                                <NavItem><NavLink onClick={this.checkCollapsing.bind(this)} className="text-dark" href="https://sender.livestrip.com/Signup.aspx" target="_blank"><FontAwesomeIcon icon={solid('user-plus')} fixedWidth /> {this.getText("Signup")}</NavLink></NavItem> : 
                                <Col xs="6" lg="12"><NavItem><NavLink onClick={this.checkCollapsing.bind(this)} tag={Link} className="text-dark" to="/messenger"><FontAwesomeIcon icon={solid('envelope')} fixedWidth /> {this.getText("Messenger")}{this.state.unreadMessages > 0 ? <span> <span className='badge bg-secondary'>{this.state.unreadMessages}</span></span> : null}</NavLink></NavItem></Col>
                                }
                            </Col>
                        </Row>
                        {this.state.loggedin ? 
                        this.state.platform === "fans" ?
                        <section>
                            <Row>
                                <Col xs="6" lg="12">
                                    <NavItem><NavLink onClick={this.checkCollapsing.bind(this)} tag={Link} className="text-dark" to="/profile"><FontAwesomeIcon icon={solid('address-card')} fixedWidth /> {this.getText("Profile")}</NavLink></NavItem>
                                </Col>
                                <Col xs="6" lg="12">
                                    <NavItem><NavLink onClick={this.checkCollapsing.bind(this)} tag={Link} className="text-dark" to="/inbox"><FontAwesomeIcon icon={solid('inbox')} fixedWidth /> {this.getText("Inbox")}{this.state.unreadMessages > 0 ? <span> <span className='badge bg-secondary'>{this.state.unreadMessages}</span></span> : null}</NavLink></NavItem>
                                </Col>
                            </Row>
                            <Row>
                            <NavItem><NavLink onClick={this.checkCollapsing.bind(this)} tag={Link} className="text-dark" to="/events"><FontAwesomeIcon icon={solid('bolt-lightning')} fixedWidth /> {this.getText("Events")}{this.state.unseenEvents > 0 ? <span> <span className='badge bg-secondary'>{this.state.unseenEvents}</span></span> : null}</NavLink></NavItem>
                                <Col xs="6" lg="12"><NavItem><NavLink onClick={this.checkCollapsing.bind(this)} tag={Link} className="text-dark" to="/subscriptions"><FontAwesomeIcon icon={solid('money-check-pen')} fixedWidth /> {this.getText("Subscriptions")}</NavLink></NavItem></Col>
                            </Row>
                            <Row>
                                <Col xs="6" lg="12"><NavItem><NavLink onClick={this.checkCollapsing.bind(this)} tag={Link} className="text-dark" to="/images"><FontAwesomeIcon icon={solid('image')} fixedWidth /> {this.getText("Images")}</NavLink></NavItem></Col>
                                <Col xs="6" lg="12"><NavItem><NavLink onClick={this.checkCollapsing.bind(this)} tag={Link} className="text-dark" to="/videos"><FontAwesomeIcon icon={solid('video')} fixedWidth /> {this.getText("Videos")}</NavLink></NavItem></Col>
                            </Row>
                            <Row>
                                <Col xs="6" lg="12"><NavItem><NavLink onClick={this.checkCollapsing.bind(this)} tag={Link} className="text-dark" to="/idshots"><FontAwesomeIcon icon={solid('id-card-clip')} fixedWidth /> {this.getText("ID-Shots")}</NavLink></NavItem></Col>
                                <Col xs="6" lg="12"><NavItem><NavLink onClick={this.checkCollapsing.bind(this)} tag={Link} className="text-dark" to="/userlists"><FontAwesomeIcon icon={solid('users')} fixedWidth /> {this.getText("Users")}</NavLink></NavItem></Col>
                            </Row>
                            <Row>
                                <Col xs="6" lg="12"><NavItem><NavLink onClick={this.checkCollapsing.bind(this)} tag={Link} className="text-dark" to="/myaccount"><FontAwesomeIcon icon={solid('gear')} fixedWidth />Account</NavLink></NavItem></Col>
                                <Col xs="6" lg="12"><NavItem><NavLink onClick={this.checkCollapsing.bind(this)} tag={Link} className="text-dark" to="/my-data"><FontAwesomeIcon icon={solid('euro')} fixedWidth />{this.getText("PayoutData")}</NavLink></NavItem></Col>
                            </Row>
                            <Row>
                                <Col xs="6" lg="12"><NavItem><NavLink onClick={this.checkCollapsing.bind(this)} tag={Link} className="text-dark" to="/livecam"><FontAwesomeIcon icon={solid('webcam')} fixedWidth />{this.getText("Livecam")}</NavLink></NavItem></Col>
                                <Col xs="6" lg="12"><NavItem><NavLink onClick={this.checkCollapsing.bind(this)} tag={Link} className="text-dark" to="/sales"><FontAwesomeIcon icon={solid('coins')} fixedWidth />{this.getText("Sales")}</NavLink></NavItem></Col>
                            </Row>
                            <Row>
                                <Col xs="6" lg="12"><NavItem><NavLink onClick={this.checkCollapsing.bind(this)} tag={Link} className="text-dark" to="/support"><FontAwesomeIcon icon={solid('question')} fixedWidth />{this.getText("Support")}</NavLink></NavItem></Col>
                                <Col xs="6" lg="12"><NavItem><NavLink onClick={this.checkCollapsing.bind(this)} tag={Link} className="text-dark" to="/logout"><FontAwesomeIcon icon={solid('right-from-bracket')} fixedWidth /> {this.getText("Logout")}</NavLink></NavItem></Col>
                            </Row>
                        </section> :
                        <section>
                            <Row>
                                <Col xs="6" lg="12"><NavItem><NavLink onClick={this.checkCollapsing.bind(this)} tag={Link} className="text-dark" to="/images"><FontAwesomeIcon icon={solid('image')} fixedWidth /> {this.getText("Images")}</NavLink></NavItem></Col>
                                <Col xs="6" lg="12"><NavItem><NavLink onClick={this.checkCollapsing.bind(this)} tag={Link} className="text-dark" to="/videos"><FontAwesomeIcon icon={solid('video')} fixedWidth /> {this.getText("Videos")}</NavLink></NavItem></Col>
                            </Row>
                            <Row>
                                <Col xs="6" lg="12"><NavItem><NavLink onClick={this.checkCollapsing.bind(this)} tag={Link} className="text-dark" to="/idshots"><FontAwesomeIcon icon={solid('id-card-clip')} fixedWidth /> {this.getText("ID-Shots")}</NavLink></NavItem></Col>
                                <Col xs="6" lg="12"><NavItem><NavLink onClick={this.checkCollapsing.bind(this)} tag={Link} className="text-dark" to="/userlists"><FontAwesomeIcon icon={solid('users')} fixedWidth /> {this.getText("Users")}</NavLink></NavItem></Col>
                            </Row>
                            <Row>
                                <Col xs="6" lg="12"><NavItem><NavLink onClick={this.checkCollapsing.bind(this)} tag={Link} className="text-dark" to="/support"><FontAwesomeIcon icon={solid('question')} fixedWidth />{this.getText("Support")}</NavLink></NavItem></Col>
                                <Col xs="6" lg="12"><NavItem><NavLink onClick={this.checkCollapsing.bind(this)} tag={Link} className="text-dark" to={{pathname:"https://sender.livestrip.com/"}} target='_blank'><FontAwesomeIcon icon={solid('right-from-bracket')} fixedWidth /> {this.getText("SenderPortal")}</NavLink></NavItem></Col>
                            </Row>
                            <Row>
                                <Col xs="6" lg="12"><NavItem><NavLink onClick={this.checkCollapsing.bind(this)} tag={Link} className="text-dark" to="/logout"><FontAwesomeIcon icon={solid('user-lock')} fixedWidth /> {this.getText("Logout")}</NavLink></NavItem></Col>
                            </Row>
                        </section>
                        : null }
                    </Nav>
                </Collapse>
            </Navbar>
      </div>
    );
  }
}
