import React, { Component, FormEvent } from 'react';
import { ListGroup,ListGroupItem,InputGroup,Alert, Modal, ModalHeader, ModalBody, ModalFooter, Container, Form, FormGroup, Button, Label, Input, ButtonGroup, Dropdown, DropdownMenu, DropdownItem } from 'reactstrap';
import { LocalStorageWorker } from '../StorageHelper';
import { CryptoHelper } from '../CryptoHelper.js';
import { AppCtx, AppContextInterface, ApiHelper } from '../AppContextInterface';
import { Redirect } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DateTime } from 'luxon';
import { TimelinePostFundraiseData, TimelinePostFundraiseOption } from './Timeline';

interface FundraiseModalProps {
    open:boolean;
    setParamsCallback(myParams:TimelinePostFundraiseData):void;
    closeCallback():void;
}

interface FundraiseModalState {
    language:string;
    isOpen:boolean;
    infoMessage:string;
    errorMessage:string;
    fundraiseOptions:TimelinePostFundraiseOption[];
    title:string;
    target:number;
    curOption:string;
    curOptionAmount:number;
}

export interface FundraiseModal extends React.Component {
    props:FundraiseModalProps;
    state:FundraiseModalState;
    contextType: AppContextInterface;
    updateTimer:NodeJS.Timer;
}

export class FundraiseModal extends React.Component {
    static contextType = AppCtx;
    context!: React.ContextType<typeof AppCtx>;

    constructor(props: FundraiseModalProps) {
        super(props);

        let contextLanguage = "de";

        if (this.context) {
            if (this.context.language !== null && this.context.language !== "") {
                contextLanguage = this.context.language;
            }
        }

        this.state = {
            language: contextLanguage,
            isOpen:props.open,
            infoMessage:"",
            errorMessage:"",
            fundraiseOptions: [],
            title:"",
            target:30,
            curOption:"",
            curOptionAmount:0
        }
    }

    componentDidMount() {
        this.updateTimer = setInterval(this.watchPropsUpdate.bind(this),250);
    }

    watchPropsUpdate() {
        if(this.props.open != this.state.isOpen) {
            this.setState({isOpen: this.props.open});
        }
    }

    componentWillUnmount() {
        if(this.updateTimer) {
            clearInterval(this.updateTimer);
        }
    }

    handleSubmit(e:FormEvent) {
        e.preventDefault();
        this.props.setParamsCallback({
            title:this.state.title,
            target:this.state.target,
            options:this.state.fundraiseOptions
        });
    }

    getText(exp: string) {
        if (this.state.language === "de") {
            if (exp === "Fundraise") {
                return "Fundraise";
            }
        } else {
            
        }

        return exp;
    }

    modalClosed() {
        this.setState({isOpen: false});
        this.props.closeCallback();
    }

    addOption() {
        let newOption:TimelinePostFundraiseOption = {
            title: this.state.curOption,
            amount: this.state.curOptionAmount
        }
        this.setState({fundraiseOptions: [...this.state.fundraiseOptions,newOption]});
        this.setState({curOption: ""});
        this.setState({curAmount: 0});
    }

    removeOption(clickedItem:TimelinePostFundraiseOption) {
        let myOptions:TimelinePostFundraiseOption[];
        myOptions = this.state.fundraiseOptions.filter((item:TimelinePostFundraiseOption) => { return item.title !== clickedItem.title });
        this.setState({fundraiseOptions: myOptions});
    }

    handleOptionKeyPress(e:React.KeyboardEvent) {
        if(e.key === "Enter") {
            this.addOption();
        }
    }

    render() {
        if (this.context) {
            if (this.context.producerID === "") {
                return <Redirect to="/" push={true} />
            }
        } else {
            return <Redirect to="/" push={true} />
        }

        return (
            <Modal isOpen={this.state.isOpen} size="lg" onClosed={this.modalClosed.bind(this)}>
                <ModalHeader>
                    { this.getText("Fundraise") }
                </ModalHeader>
                <ModalBody>
                    <Alert isOpen={this.state.infoMessage !== ""} color="info">{this.state.infoMessage}</Alert>
                    <Alert isOpen={this.state.errorMessage !== ""} color="danger">{this.state.errorMessage}</Alert>
                </ModalBody>
                <Form onSubmit={this.handleSubmit.bind(this)}>
                    <FormGroup className="mb-2">
                        <Label>{this.getText("Title")}</Label>
                        <Input type="text" placeholder={this.getText("Title")} value={this.state.title} onChange={(e:React.ChangeEvent<HTMLInputElement>) => this.setState({title: e.target.value})} />
                    </FormGroup>
                    <FormGroup className="mb-2">
                        <Label>{this.getText("Target")}</Label>
                        <Input type="tel" placeholder={this.getText("Target")} value={this.state.target} onChange={(e:React.ChangeEvent<HTMLInputElement>) => this.setState({days: e.target.value})} />
                    </FormGroup>
                    <Button variant="primary" type="submit">{this.getText("Submit")}</Button>
                </Form>
                <div>
                    <h6>{this.getText("Mögliche Optionen")}</h6>
                    <ListGroup>
                        {this.state.fundraiseOptions.map(item =>
                            <ListGroupItem>
                                <FontAwesomeIcon icon={solid('question')} size="sm" /> {item.title} {item.amount} <FontAwesomeIcon icon={solid('coin')}></FontAwesomeIcon> <FontAwesomeIcon icon={solid('remove')} style={{cursor: "pointer"}} onClick={this.removeOption.bind(this,item)} size="sm" /> 
                            </ListGroupItem>
                        )}
                    </ListGroup>
                    <InputGroup>
                        <Input type="text" placeholder={this.getText("Add option")} onKeyDown={this.handleOptionKeyPress.bind(this)} value={this.state.curOption} onChange={(e:React.ChangeEvent<HTMLInputElement>) => this.setState({curOption: e.target.value})} />
                        <Input type="tel" placeholder={this.getText("Amount")} onKeyDown={this.handleOptionKeyPress.bind(this)} value={this.state.curOptionAmount} onChange={(e:React.ChangeEvent<HTMLInputElement>) => this.setState({curOptionAmount: e.target.value})} />
                        <Button>
                            <FontAwesomeIcon icon={solid('plus')} size="sm" />
                        </Button>
                    </InputGroup>
                </div>
            </Modal>
        )
    }
}

export default FundraiseModal;