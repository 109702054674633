import React, { useState, useCallback, useRef, useEffect,useContext } from "react";
import styled, { css } from "styled-components";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Line,Circle } from "rc-progress";
import Uploady, {
  withRequestPreSendUpdate,
  useItemFinalizeListener,
  withBatchStartUpdate,
  useBatchAddListener,
  useBatchFinalizeListener,
  useBatchProgressListener,
  useItemStartListener,
  useItemProgressListener,
  useItemFinishListener,
  useItemErrorListener
} from "@rpldy/uploady";
import { UploadyContext } from '@rpldy/uploady';
import UploadButton from "@rpldy/upload-button";
import UploadPreview, { PREVIEW_TYPES } from "@rpldy/upload-preview";
import cropImage from "./cropImage";
import "./imageUploadStyles.css";
import { AppCtx, AppContextInterface, ApiHelper } from '../AppContextInterface';
import { Alert, Button, ButtonGroup } from 'reactstrap';

const STATES = {
   PROGRESS: "PROGRESS",
   DONE: "DONE",
   ABORTED: "ABORTED",
   ERROR: "ERROR",
};

const STATE_COLORS = {
   [STATES.PROGRESS]: "#f4e4a4",
   [STATES.DONE]: "#a5f7b3",
   [STATES.ABORTED]: "#f7cdcd",
   [STATES.ERROR]: "#ee4c4c",
};

const StyledReactCrop = styled(ReactCrop)`
  width: 100%;
  max-width: 600px;
`;

const ButtonsWrapper = styled.div`
  display: block;
  margin-top: 0px;
  margin-bottom: 10px;
`;

const MultiCropContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const dotCss = css`
    &:before {
        content: "";
        width: 12px;
        height: 12px;
        position: absolute;
        top: 1px;
        right: 1px;
        background-color: #00ff4e;
        border: 1px solid #eeeeee;
        border-radius: 100%;
    }
`;

const finishedCss = css`
    &:after {
        content: "";
        position: absolute;
        z-index: 10;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(34, 34, 34, 0.6);
    }

    cursor: default;
`;

const ItemPreviewImgWrapper = styled.div`
    margin-right: 10px;
    position: relative;
    cursor: pointer;

    ${({ $isCropped }) => $isCropped ? dotCss : ""}

    ${({ $isFinished }) => $isFinished ? finishedCss : ""}
`;

const ItemPreviewImg = styled.img`
    max-height: 160px;
    max-width: 160px;

    transition: box-shadow 0.5s;

    &:hover {
        box-shadow: 0 0 1px 2px #222222;
    }
`;

const StyledCircle = styled(Circle)`
 width: 32px;
 height: 32px;
`;

const PreviewsContainer = styled.div`
 width: 100%;
 display: flex;
 flex-wrap: wrap;
 border-top: 1px solid #0c86c1;
 margin-top: 10px;
`;

const PreviewImageWrapper = styled.div`
 height: 150px;
 text-align: center;
 width: 100%;
`;

const PreviewImage = styled.img`
 max-width: 200px;
 height: auto;
 max-height: 140px;
`;

const PreviewItemContainer = styled.div`
 width: 220px;
 padding: 10px;
 display: flex;
 flex-direction: column;
 box-shadow: ${({ state }) => (state ? STATE_COLORS[state] : "#c3d2dd")} 0px
   8px 5px -2px;
 position: relative;
 align-items: center;
 margin: 0 10px 10px 0;
`;

const ImageName = styled.span`
 position: absolute;
 top: 10px;
 font-size: 12px;
 padding: 3px;
 background-color: #25455bab;
 width: 180px;
 white-space: nowrap;
 overflow: hidden;
 text-overflow: ellipsis;
 color: #fff;
`;

const PreviewItemBar = styled.div`
 display: flex;
 align-items: center;
 justify-content: space-between;
 padding: 10px 0;
 width: 100%;
 box-shadow: #5dbdec 0px -3px 2px -2px;
`;

const ItemButtons = styled.div`
 button {
   width: 52px;
   height: 34px;
   font-size: 26px;
   line-height: 26px;
   cursor: pointer;
   margin-right: 4px;

   :disabled {
     cursor: not-allowed;
     background-color: grey;
     color: grey;
   }
 }
`;

const CropperContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const PreviewButtons = ({finished,crop,updateRequest,onUploadCancel,onUploadCrop}) => {
  const myContext = useContext(AppCtx);

  const getText = (msg) => {
    if(myContext.language === "de") {
      if(msg === "Upload Cropped") {
        return "Zugeschnitten hochladen";
      }
      if(msg === "Upload without Crop") {
        return "Ohne Zuschneiden hochladen";
      }
      if(msg === "Cancel") {
        return "Nicht hochladen";
      }
    }

    return msg;
  }

  return (
    <ButtonsWrapper>
      <Button size="sm" style={{display: !finished && updateRequest && crop ? "inline-block" : "none",marginRight: "10px"}} onClick={onUploadCrop}>
        {getText("Upload Cropped")}
      </Button>
      <Button size="sm" style={{ display: !finished && updateRequest ? "inline-block" : "none",marginRight: "10px"}} onClick={updateRequest}>
        {getText("Upload without Crop")}
      </Button>
      <Button size="sm" style={{display: !finished && updateRequest && crop ? "inline-block" : "none"}} type="button" onClick={onUploadCancel}>
        {getText("Cancel")}
      </Button>
    </ButtonsWrapper>
  );
};

const UPLOAD_STATES = {
  NONE: 0,
  UPLOADING: 1,
  FINISHED: 2
};

const ItemPreviewWithCrop = withRequestPreSendUpdate((props) => {
  const {
    id,
    url,
    isFallback,
    type,
    updateRequest,
    requestData,
    previewMethods
  } = props;
  const cropRef = useRef(null);
  const [uploadState, setUploadState] = useState(UPLOAD_STATES.NONE);
  const [crop, setCrop] = useState(true);
  const [croppedUrl, setCroppedUrl] = useState(null);
  const isFinished = uploadState === UPLOAD_STATES.FINISHED;
  const myContext = useContext(AppCtx);

  useItemStartListener(() => setUploadState(UPLOAD_STATES.UPLOADING), id);
  useItemFinalizeListener(() => setUploadState(UPLOAD_STATES.FINISHED), id);

  const onImageLoaded = useCallback((image) => {
    cropRef.current = image;
  }, []);

  const onUploadCrop = useCallback(async () => {
    if (updateRequest && (crop?.height || crop?.width)) {
      const { blob: croppedBlob, blobUrl, revokeUrl } = await cropImage(
        cropRef.current,
        requestData.items[0].file,
        crop,
        true
      );

      requestData.items[0].file = croppedBlob;

      updateRequest({ items: requestData.items });
      setCroppedUrl({ blobUrl, revokeUrl });
    }
  }, [requestData, updateRequest, crop]);

  const onUploadCancel = useCallback(() => {
    updateRequest(false);
  }, [updateRequest, previewMethods]);

  useEffect(() => () => croppedUrl?.revokeUrl(), [croppedUrl]);

  const getText = (msg) => {
    if(myContext.language === "de") {
      if(msg === "FINISHED") {
        return "Bild abgeschlossen";
      }
    } else {
      if(msg === "FINISHED") {
        return "Image finished";
      }
    }

    return msg;
  }

  return isFallback || type !== PREVIEW_TYPES.IMAGE ? (
    <PreviewImage src={url} alt="fallback img" />
  ) : (
    <>
      <PreviewButtons finished={isFinished} crop={crop} updateRequest={updateRequest} onUploadCancel={onUploadCancel} onUploadCrop={onUploadCrop} />
      {requestData && uploadState === UPLOAD_STATES.UPLOADING ? (
        <StyledReactCrop
          ruleOfThirds
          src={url}
          crop={crop}
          onImageLoaded={onImageLoaded}
          onChange={setCrop}
          onComplete={setCrop}
          style={{ height: "100%" }}
        />
      ) : (
        <PreviewImage src={croppedUrl?.blobUrl || url} alt="img to upload" />
      )}
      {isFinished ? <p className="w-100 imageUploadComplete">{getText("FINISHED")}</p> : null }
    </>
  );
});

const UploadProgress = (props) => {
    const [progress, setProgess] = useState(0);
    const progressData = useItemProgressListener();
  
    if (progressData && progressData.completed > progress) {
      setProgess(() => progressData.completed);
    }

    const uploadyContext = useContext(UploadyContext);

    useItemFinishListener((item) => {
        console.log("item finish: " + JSON.stringify(item.uploadResponse));
        if(typeof(item.uploadResponse) !== "undefined") {
            try {
                const myResponseData = JSON.parse(item.uploadResponse);
                if(typeof(myResponseData.message) !== "undefined") {
                    props.finishCallback(item.uploadResponse.message);
                } else {
                    props.finishCallback("Upload done");
                }
            } catch(e) {
                props.finishCallback("Upload completed");
            }
        }
    });
  
    useItemErrorListener((item) => {
      console.log(`item ${item.id} upload error !!!! `, item);
    });
  
    useItemStartListener((item) => {
      console.log(`item ${item.id} starting to upload,name = ${item.file.name}`);
    });

    return (
        progressData && (
            <div>
                <div style={{position: "absolute",zIndex: 1,color: "#fff",textAlign: "center",width: "420px",paddingTop: "2px"}}>{progress}%</div>
                <Line
                    style={{ height: "20px", marginTop: "0px",position: "relative" }}
                    strokeWidth={5}
                    strokeColor={progress === 100 ? "#00a626" : "#2db7f5"}
                    percent={progress}
                />
            </div>
        )
    );
};

export default function ImageUpload(props) {
  const previewMethodsRef = useRef();
  const [producerID, setProducerID] = useState(props.producerID);
  const [uploadInfo, setUploadInfo] = useState(props.uploadInfo);
  const myContext = useContext(AppCtx);

  const handleFileInput = (e) => {
    console.log("File selected for imageupload: " + e.target.files[0].name);
  }

  const getText = (msg) => {
    if(myContext.language === "de") {
      if(msg === "Select Image to upload") {
        return "Bild zum Hochladen auswählen (jpg, png)";
      }
      if(msg === "Cancel") {
        return "Abbrechen";
      }
    }

    return msg;
  }

  return (
    <Uploady
      multiple={false}
      destination={{ url: ApiHelper.getAPIUrl(myContext) + "/upload?producerID=" + encodeURIComponent(producerID) + "&uploadInfo=" + encodeURIComponent(uploadInfo) }}
    >
      <div className="ImageUpload">
        <UploadButton className="btn btn-secondary btn-sm">{getText("Select Image to upload")}</UploadButton><br />
        <UploadProgress finishCallback={props.finishCallback} />
        <br />
        <UploadPreview
          PreviewComponent={ItemPreviewWithCrop}
          previewComponentProps={{ previewMethods: previewMethodsRef }}
          previewMethodsRef={previewMethodsRef}
          fallbackUrl="https://icon-library.net/images/image-placeholder-icon/image-placeholder-icon-6.jpg"
        />
      </div>
    </Uploady>
  );
}

export function ImageUploadMultiple(props) {
  const previewMethodsRef = useRef();
  const [producerID, setProducerID] = useState(props.producerID);
  const [uploadInfo, setUploadInfo] = useState(props.uploadInfo);
  const myContext = useContext(AppCtx);

  const handleFileInput = (e) => {
    console.log("File selected for imageupload: " + e.target.files[0].name);
  }

  const getText = (msg) => {
    if(myContext.language === "de") {
      if(msg === "Select Image to upload") {
        return "Bild(er) zum Hochladen auswählen (jpg, png)";
      }
      if(msg === "Cancel") {
        return "Abbrechen";
      }
    }

    return msg;
  }

  return (
    <Uploady
      multiple={true}
      destination={{ url: ApiHelper.getAPIUrl(myContext) + "/upload?producerID=" + encodeURIComponent(producerID) + "&uploadInfo=" + encodeURIComponent(uploadInfo) }}
    >
      <div className="ImageUpload">
        <MultiCropQueue finishCallback={props.finishCallback} />
      </div>
    </Uploady>
  );
}

export function FileUpload(props) {
  const previewMethodsRef = useRef();
  const [producerID, setProducerID] = useState(props.producerID);
  const [uploadInfo, setUploadInfo] = useState(props.uploadInfo);
  const myContext = useContext(AppCtx);

  const handleFileInput = (e) => {
    console.log("File selected for imageupload: " + e.target.files[0].name);
  }

  const getText = (msg) => {
    if(myContext.language === "de") {
      if(msg === "Select File to upload") {
        return "Datei zum Hochladen auswählen (pdf)";
      }
      if(msg === "Cancel") {
        return "Abbrechen";
      }
    }

    return msg;
  }

  return (
    <div className="ImageUpload">
      <Uploady
        multiple={false}
        destination={{ url: ApiHelper.getAPIUrl(myContext) + "/upload?producerID=" + encodeURIComponent(producerID) + "&uploadInfo=" + encodeURIComponent(uploadInfo) }}
      >
        <div className="FileUpload">
          <UploadButton onChange={handleFileInput} className="btn btn-secondary btn-sm">{getText("Select File to upload")}</UploadButton><br />
          <UploadProgress finishCallback={props.finishCallback} />
        </div>
      </Uploady>
    </div>
  );
}

const ItemPreviewThumb = ({ id, url, onPreviewSelected, isCroppedSet, isFinished }) => {
  const onPreviewClick = () => {
      if (!isFinished) {
          onPreviewSelected({ id, url });
      }
  };

  return <ItemPreviewImgWrapper
      $isCropped={isCroppedSet}
      $isFinished={isFinished}
  >
      <ItemPreviewImg
          className={`preview-thumb ${isFinished ? "finished" : (isCroppedSet ? "cropped" : "")}`}
          onClick={onPreviewClick}
          src={url}
      />
  </ItemPreviewImgWrapper>
};

const CropperForMultiCrop = ({ item, url, setCropForItem }) => {
  const [crop, setCrop] = useState({ height: 100, width: 100, x: 50, y: 50 });

  const onSaveCrop = async () => {
      const cropped = await cropImage(url, item.file, crop);
      setCropForItem(item.id, cropped);
  };

  const onUnsetCrop = () => {
      setCropForItem(item.id, null);
  };

  return (<CropperContainer>
      <StyledReactCrop
          src={url}
          crop={crop}
          onChange={setCrop}
          onComplete={setCrop}
      />
      <Button onClick={onSaveCrop} id="save-crop-btn">Save Crop</Button>
      <Button onClick={onUnsetCrop} id="unset-crop-btn">Dont use Crop</Button>
  </CropperContainer>);
};

const BatchCrop = withBatchStartUpdate((props) => {
  const previewMethodsRef = useRef();
  const { id, updateRequest, requestData, uploadInProgress } = props;
  const [selected, setSelected] = useState({ url: null, id: null });
  const [finishedItems, setFinishedItems] = useState([]);
  const [cropped, setCropped] = useState({});
  const hasData = !!(id && requestData);
  const selectedItem = !!selected && requestData?.items.find(({ id }) => id === selected.id);

  const setCropForItem = (id, data) => {
      setCropped((cropped) => ({ ...cropped, [id]: data }));
  };

  const myContext = useContext(AppCtx);

  const getText = (msg) => {
    if(myContext.language === "de") {
      if(msg === "StartUpload") {
        return "Upload starten";
      }
      if(msg === "CancelUpload") {
        return "Upload abbrechen";
      }
      if(msg === "Cancel") {
        return "Abbrechen";
      }
    }

    return msg;
  }

  const onUploadAll = () => {
      if (updateRequest) {
          const readyItems = requestData.items
              .map((item) => {
                  item.file = cropped[item.id] || item.file;
                  return item;
              });

          updateRequest({ items: readyItems });
      }
  };

  const onUploadCancel = () => {
      if (updateRequest) {
          updateRequest({ items: [] });
      }
      if(previewMethodsRef.current) {
        previewMethodsRef.current.clear();
      }
  };

  useItemFinalizeListener(({ id }) => {
      setFinishedItems((finished) => finished.concat(id))
  });

  const getPreviewCompProps = useCallback((item) => {
      return ({
          onPreviewSelected: setSelected,
          isCroppedSet: cropped[item.id],
          isFinished: !!~finishedItems.indexOf(item.id),
          previewMethods: previewMethodsRef
      });
  }, [cropped, setSelected, finishedItems]);

  return (<MultiCropContainer>
      {hasData && !uploadInProgress &&
      <ButtonGroup>
        <Button onClick={onUploadAll} size="sm" className="w-50" style={{marginRight: "4px"}} id="upload-all-btn">{getText("StartUpload")}</Button>
        <Button onClick={onUploadCancel} size="sm" className="w-50" style={{marginLeft: "4px"}} id="upload-cancel-btn">{getText("CancelUpload")}</Button>
      </ButtonGroup>
      }

      <PreviewsContainer>
          <UploadPreview
              PreviewComponent={ItemPreviewWithCrop}
              previewComponentProps={getPreviewCompProps}
              previewMethodsRef={previewMethodsRef}
              fallbackUrl="https://icon-library.net/images/image-placeholder-icon/image-placeholder-icon-6.jpg"
          />
      </PreviewsContainer>
      {selectedItem && hasData && !uploadInProgress &&
      <CropperForMultiCrop
          {...selected}
          item={selectedItem}
          setCropForItem={setCropForItem}
      />}
  </MultiCropContainer>);
});

const MultiCropQueue = (props) => {
  const [producerID, setProducerID] = useState(props.producerID);
  const [uploadInfo, setUploadInfo] = useState(props.uploadInfo);
  const myContext = useContext(AppCtx);
  const [currentBatch, setCurrentBatch] = useState(null);
  const [inProgress, setInProgress] = useState(false);

  useBatchAddListener((batch) => setCurrentBatch(batch.id));

  useBatchFinalizeListener(() => {
      setCurrentBatch(null);
      setInProgress(false);
  });

  useBatchProgressListener(() => {
      if (!inProgress) {
          setInProgress(true);
      }
  });

  const handleFileInput = (e) => {
    console.log("File selected for imageupload: " + e.target.files[0].name);
    setCurrentBatch(null);
    setInProgress(false);
  }

  const getText = (msg) => {
    if(myContext.language === "de") {
      if(msg === "Select Image to upload") {
        return "Klicke hier um Bilder zum Hochladen auszuwählen (inkl. Zuschneidefunktion)";
      }
      if(msg === "Cancel") {
        return "Abbrechen";
      }
    } else {
      if(msg === "Select Image to upload") {
        return "Click here in order to select to upload (supports cropping)";
      }
    }

    return msg;
  }

  return (
      <div className="MultiCropQueue">
        {currentBatch ? <UploadProgress finishCallback={props.finishCallback} /> : null }

        {inProgress ? <h4>Uploading...</h4> : null }

        {!currentBatch &&
        <UploadButton id="upload-btn" handleFileInput={handleFileInput} className="btn btn-secondary">{getText("Select Image to upload")}</UploadButton>
        }

        <BatchCrop
            id={currentBatch}
            uploadInProgress={inProgress}
        />
      </div>
  );
};