import React, { Component,FormEvent } from 'react';
import { UncontrolledDropdown,UncontrolledCollapse, Card, CardBody, Alert, Badge, InputGroup, InputGroupText, Modal, ModalHeader, Row, Col, ListGroup, ListGroupItem, Form, FormGroup, Button, Label, Input, ModalBody, ModalFooter, ButtonToolbar, ButtonGroup, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, CardHeader } from 'reactstrap';
import { LocalStorageWorker } from '../StorageHelper';
import { CryptoHelper } from '../CryptoHelper.js';
import { AppCtx, AppContextInterface, ApiHelper } from '../AppContextInterface';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DateTime } from 'luxon';
import ImageModal from './ImageModal';
import VideoModal from './VideoModal';
import PollModal from './PollModal';
import QuizModal from './QuizModal';
import FundraiseModal from './FundraiseModal';
import { ThemeProvider } from 'styled-components';
import { faL } from '@fortawesome/pro-solid-svg-icons';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-US';
import ItemPreviewWithCrop from './ItemPreviewWithCrop';
import SmallMediaPreview from './SmallMediaPreview';
import { Tooltip,UncontrolledTooltip } from 'reactstrap';
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { SubscriptionTierData } from './SubscriptionTiers';
import { VideoSingleEdit } from './VideoSingleEdit';
import { VideoData } from './VideoList';
import { v4 as uuidv4 } from 'uuid';
import $ from 'jquery';

interface TimelineProps {

}

export interface TimelinePost {
    guid: string;
    msgText: string;
    payText: string;
    msgTitle: string;
    price: number;
    tags: string;
    freeMedia: MessagingPostMediaData[];
    payMedia: MessagingPostMediaData[];
    poll: TimelinePostPollData;
    quiz: TimelinePostQuizData;
    fundraise: TimelinePostFundraiseData;
    creationTime: Date|null;
    publishTime: Date|null;
    pinned: boolean;
    nonsub: boolean;
    promo: boolean;
    reviewStatus: number;
    reviewComment: string;
    priceOverrides: PriceOverrideMessagingPostRecord[]|null;
    deleted: boolean;
    openEdit: boolean;
    editText: string;
    editPayText: string;
    editTitle: string;
    editPrice: number;
    editPublishTime: Date|null;
    editFreeMedia: MessagingPostMediaData[]|null;
    editPayMedia: MessagingPostMediaData[]|null;
    editPriceOverrides: PriceOverrideMessagingPostRecord[]|null;
    editPoll: TimelinePostPollData|null;
    editQuiz: TimelinePostQuizData|null;
    editFundraise: TimelinePostFundraiseData|null;
    likes: number;
    views: number;
    ranking: number;
    calculatedPrices: CalculatedPriceRecord[]|null;
    platform: string|null;
    clonedToGUID: string|null;
    clonedFromGUID: string|null;
    mergeStatus: string|null;
}

export interface MessagingPost {
    publishTime: string|null;
    msgText: string;
    payText: string;
    msgTitle: string;
    price: number;
    tags: string;
    freeMedia: MessagingPostMediaData[];
    payMedia: MessagingPostMediaData[];
    poll: TimelinePostPollData|null;
    quiz: TimelinePostQuizData|null;
    fundraise: TimelinePostFundraiseData|null;
    pinned: boolean;
    nonsub: boolean;
    priceOverrides: PriceOverrideMessagingPostRecord[]|null;
}

export interface PriceOverrideMessagingPostRecord {
    price: number;
    tierId: number;
}

export interface MessagingPostMediaData {
    type: string;
    mediaID: string;
    reviewStatus: number;
}

export interface TimelinePostQuizData {
    question: string;
    days: number;
    answers: TimelinePostQuizAnswer[];
}

export interface TimelinePostQuizAnswer {
    answer: string;
    right: boolean;
}

export interface TimelinePostPollData {
    question: string;
    days: number;
    answers: TimelinePostPollAnswer[];
}

export interface TimelinePostPollAnswer {
    answer: string;
}

export interface TimelinePostFundraiseData {
    title: string;
    options: TimelinePostFundraiseOption[];
    target:number;
}

export interface TimelinePostFundraiseOption {
    title: string;
    amount: number;
}

export interface Timeline {
    state: TimelineState;
    props: TimelineProps;
    contextType: AppContextInterface;
    hasBeforeUnloadHandler: boolean;
}

interface TimelineState {
    tags: string;
    msgText: string;
    payText: string;
    msgTitle: string;
    payTextMode: boolean;
    freeMedia: MessagingPostMediaData[];
    payMedia: MessagingPostMediaData[];
    pollQuestion: string;
    pollDays: number; 
    pollAnswers: TimelinePostPollAnswer[];
    quizQuestion: string;
    quizDays: number;
    quizAnswers: TimelinePostQuizAnswer[];
    fundraiseTitle: string;
    fundraiseTarget: number;
    fundraiseOptions: TimelinePostFundraiseOption[];
    publishTime: Date|null;
    price: number;
    pinned: boolean;
    nonsub: boolean;
    promo: boolean;
    errorMessage: string;
    infoMessage: string;
    canChangePlatform: boolean;
    dateSelectorOpen: boolean;
    imageSelectorOpen: boolean;
    videoSelectorOpen: boolean;
    audioSelectorOpen: boolean;
    fileSelectorOpen: boolean;
    language: string;
    submitting: boolean;
    fetching: boolean;
    posts: TimelinePost[];
    mediaAddMode: string;
    imageModal: boolean;
    videoModal: boolean;
    audioModal: boolean;
    calendarModal: boolean;
    quizModal: boolean;
    pollModal: boolean;
    fundraiseModal: boolean;
    priceModal: boolean;
    clickedMediaData: MessagingPostMediaData|null;
    priceInputEuro: number;
    priceInputCents: number;
    smallPreviewDimension: string;
    mediumPreviewDimension: string;
    previewTimelineImageDimension: string;
    singleImagePreviewDimension: string;
    clickedMediaDeletable: boolean;
    priceOverrides: PriceOverrideRecord[];
    calculatedPrices: CalculatedPriceRecord[];
    fetchingSubscriptionTiers: boolean;
    fetchingSubscriptionTiersError: string;
    subscriptionTiers: SubscriptionTierData[];
    editGUID: string;
    showDeleted: boolean;
    showNonSub: boolean;
    showPromo: boolean;
    isWindows: boolean;
    curPage: number;
    pageSize: number;
    totalItemsCount: number;
    undeletedItemsCount: number;
    pagesArray: PageData[];
    sort: string;
    sortDropdownOpen: boolean;
    platformDropdownOpen: boolean;
    extraPagesDropdownOpen: boolean;
    previewMode: boolean;
    maxPages: number;
    singleVideoEditStreamname: string;
    timelineOnlyForSubs: boolean;
    timelineNeedsPaymentAuth: boolean;
    viewDropdownOpen: boolean;
    timelineSettingsDropdownOpen: boolean;
    changingTimelineSettings: boolean;
    hasGuppyTimeline: boolean;
    hasFanTimeline: boolean;
    mergeFanPostsOnGuppyTimeline: boolean;
    platform: string;

}

export interface PageData {
    page: number;
    active: boolean;
}

interface PriceOverrideRecord {
    priceEuro: number;
    priceCents: number;
    tierId: number;
}

interface CalculatedPriceRecord {
    price: number;
    tierName: string;
    tierId: number;
}

export class Timeline extends React.Component {
    static contextType = AppCtx;
    context!: React.ContextType<typeof AppCtx>;

    constructor(props: TimelineProps) {
        super(props);

        let mylang: string = "de";

        if (this.context) {
            mylang = this.context.language;
        }

        let myplatform:string = ApiHelper.getPlatform();

        if(this.context) {
            myplatform = this.context.platform;
        }

        this.state = {
            tags: "",
            msgText: "",
            payText: "",
            payTextMode: false,
            msgTitle: "",
            publishTime: null,
            freeMedia: [],
            payMedia: [],
            quizQuestion: "",
            quizDays: 0,
            quizAnswers: [],
            pollQuestion: "",
            pollDays: 0,
            pollAnswers: [],
            fundraiseTitle: "",
            fundraiseTarget: 0,
            fundraiseOptions: [],
            price: 0,
            pinned: false,
            nonsub: false,
            promo: false,
            errorMessage: '',
            infoMessage: '',
            canChangePlatform: false,
            language: mylang,
            dateSelectorOpen: false,
            imageSelectorOpen: false,
            videoSelectorOpen: false,
            audioSelectorOpen: false,
            fileSelectorOpen: false,
            submitting: false,
            fetching: false,
            posts: [],
            mediaAddMode: "free",
            imageModal: false,
            videoModal: false,
            audioModal: false,
            calendarModal: false,
            quizModal: false,
            pollModal: false,
            fundraiseModal: false,
            clickedMediaData: null,
            priceModal: false,
            priceInputEuro: 0,
            priceInputCents: 0,
            smallPreviewDimension: "80x80xinside",
            mediumPreviewDimension: "300x300xinside",
            previewTimelineImageDimension: "x600",
            singleImagePreviewDimension: "480x480xinside",
            clickedMediaDeletable: false,
            priceOverrides: [],
            calculatedPrices: [],
            fetchingSubscriptionTiers: false,
            fetchingSubscriptionTiersError: "",
            subscriptionTiers: [],
            editGUID: "",
            showDeleted: false,
            showNonSub: false,
            showPromo: false,
            isWindows: navigator.platform.indexOf("Win") > -1,
            curPage: 0,
            pageSize: 10,
            totalItemsCount: 0,
            undeletedItemsCount: 0,
            pagesArray: [],
            sort: "publishtime",
            sortDropdownOpen: false,
            platformDropdownOpen: false,
            extraPagesDropdownOpen: false,
            previewMode: false,
            maxPages: window.innerWidth < 768 ? 12 : 12,
            singleVideoEditStreamname: "",
            timelineOnlyForSubs: false,
            timelineNeedsPaymentAuth: false,
            viewDropdownOpen: false,
            timelineSettingsDropdownOpen: false,
            changingTimelineSettings: false,
            hasGuppyTimeline: false,
            hasFanTimeline: false,
            mergeFanPostsOnGuppyTimeline: false,
            platform: myplatform
        };

        registerLocale('de', de);
        registerLocale('en', en);

        this.hasBeforeUnloadHandler = false;
    }

    beforeUnload(e:any) {
        return "Are you sure you want to leave?";
    }

    componentDidMount() {
        let myLocalStorage:LocalStorageWorker = new LocalStorageWorker();

        if(myLocalStorage.get("lastpage") !== "timeline") {
            myLocalStorage.add("lastpage","timeline");
        }
        
        this.fetchData();
    }

    togglePreviewMode() {
        this.setState({ previewMode: !this.state.previewMode },this.fetchData.bind(this));
    }

    fetchData = async () => {
        this.setState({ fetching: true });

        let jsonurl = ApiHelper.getAPIUrl(this.context) + "/timeline/producer/";

        if (this.context) {
            if(!this.context.loggedin) {
                await ApiHelper.checkLoginStatus(this.context);
            }
            jsonurl += encodeURIComponent(this.context.producerID) + "?role=producer";
            jsonurl += "&roleID=" + encodeURIComponent(this.context.producerID);
            jsonurl += "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
            if(this.state.curPage > 0) {
                jsonurl += "&startindex=" + encodeURIComponent((this.state.curPage * this.state.pageSize));
            }
            if(this.state.sort === "publishtime" && this.state.previewMode) {
                jsonurl += "&sort=" + encodeURIComponent("previewmode");
            } else {
                jsonurl += "&sort=" + encodeURIComponent(this.state.sort);
            }
            if(this.state.platform === "fans") {
                if(this.state.showPromo) {
                    jsonurl += "&filter=promo";
                } else {
                    if(this.state.showNonSub) {
                        jsonurl += "&filter=previewnonsub";
                    } else {
                        jsonurl += "&filter=previewsub";
                    }
                }
            }
            if(this.state.showDeleted && !this.state.previewMode) {
                jsonurl += "&special=showdeleted";
            }
            jsonurl += "&platform=" + encodeURIComponent(this.state.platform);
        } else {
            jsonurl += "?producerID=missingcontext";
        }

        let response: any = null;

        try {
            response = await fetch(jsonurl);
            let data = await response.json();
            this.setState({ fetching: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                    if(!ApiHelper.checkResponse(data,this.context) && this.context !== null) {
                        this.context.producerID = "";
                        alert("Please relogin");
                    }
                }
            }

            let totalItemsCount:number = 0;
            let undeletedItemsCount:number = 0;

            if(data.total_posts) {
                totalItemsCount = data.total_posts;
            } else {
                totalItemsCount = 0;
            }

            if(data.shown_posts) {
                undeletedItemsCount = data.shown_posts;
            } else {
                undeletedItemsCount = 0;
            }

            if (data.posts) {
                this.setState({ posts: data.posts,pagesArray: [],timelineOnlyForSubs: data.needsAbo,timelineNeedsPaymentAuth: data.needsPaymentAuth,totalItemsCount: totalItemsCount,undeletedItemsCount: undeletedItemsCount,canChangePlatform: data.canChangePlatform },this.handleAfterPostDataLoaded.bind(this));
            }
        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching data: " + error.toString() });
            this.setState({ fetching: false });
        }
    }

    handleAfterPostDataLoaded() {
        if(this.state.showDeleted) {
            this.handlePagination(this.state.totalItemsCount);
        } else {
            this.handlePagination(this.state.undeletedItemsCount);
        }

        this.fetchRealImageURLs();
        this.updateSubscriptionTiers();
    }

    handlePagination(numPosts:number) {
        if(numPosts > 0) {
            let newArray:PageData[] = [];
            for(let si = 0;si < numPosts;si += this.state.pageSize) {
                let newPage:PageData = {
                    page: Math.floor(si / this.state.pageSize),
                    active: Math.floor(si / this.state.pageSize) == this.state.curPage ? true : false
                };
                newArray.push(newPage);
            }
            this.setState({pagesArray: newArray});
        } else {
            this.setState({pagesArray: []});
        }
    }

    handleSubmit(event: FormEvent) {
        event.preventDefault();
        this.formSubmitted();
    }

    handleEditSubmit(event: FormEvent) {
        event.preventDefault();
        this.formSubmitted();
    }

    handleEditCancel() {
        this.setState({editGUID: ""});
        this.resetWriteArea();
    }

    deletePost(guid:string) {
        if(window.confirm(this.getText("DoYouReallyWantToDeleteThisPost"))) {
            if (this.context) {
                let jsonurl = ApiHelper.getAPIUrl(this.context) + "/timeline/" + encodeURIComponent(guid);
                jsonurl += "?producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
    
                fetch(jsonurl, {
                    method: 'DELETE'
                }).then(async response => {
                    let data = await response.json();
                    if (data.success) {
                        this.fetchData();
                    } else {
                        this.setState({ errorMessage: "Error deleting post: " + data.message });
                    }
                }).catch(error => {
                    this.setState({ errorMessage: "Error deleting post: " + error.toString() });
                });
            }
        }
    }

    compareMediaCollections(a: MessagingPostMediaData[]|null, b: MessagingPostMediaData[]|null):boolean {
        if (a === null) {
            if (b === null) {
                return false;
            } else {
                return true;
            }
        }
        if (b === null) {
            if (a === null) {
                return false;
            } else {
                return true;
            }
        }
        if (a.length !== b.length) {
            return true;
        }
        for(let i = 0;i < a.length;i++) {
            if(a[i].mediaID !== b[i].mediaID) {
                return true;
            }
        }

        return false;
    }

    formSubmitted() {
        if (this.context === null) {
            return;
        }

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/timeline?producerid=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);

        jsonurl += "&platform=" + encodeURIComponent(this.state.platform);

        this.setState({ submitting: true });
        this.setState({errorMessage: ""});

        let currentPost:MessagingPost = {
            msgText: this.state.msgText,
            msgTitle: this.state.msgTitle,
            payText: this.state.payText,
            price: this.state.price,
            tags: this.state.tags,
            pinned: this.state.pinned,
            nonsub: this.state.nonsub,
            freeMedia: this.state.freeMedia,
            payMedia: this.state.payMedia,
            quiz: {
                question: this.state.quizQuestion,
                answers: this.state.quizAnswers,
                days: this.state.quizDays
            },
            poll: {
                question: this.state.pollQuestion,
                answers: this.state.pollAnswers,
                days: this.state.pollDays
            },
            fundraise: {
                title: this.state.fundraiseTitle,
                target: this.state.fundraiseTarget,
                options: this.state.fundraiseOptions
            },
            priceOverrides: this.convertPriceOverrides(),
            publishTime: this.state.publishTime ? DateTime.fromISO(new Date(this.state.publishTime).toISOString()).toFormat("MM-dd-yyyy HH:mm:ss") : null
        }


        let currentEditGUID = this.state.editGUID;
        let fetchMethod:string = "POST";

        if(currentEditGUID !== "") {
            jsonurl += "&guid=" + encodeURIComponent(currentEditGUID);
            fetchMethod = "PUT";
        }

        fetch(jsonurl, { method: fetchMethod, body: JSON.stringify(currentPost), headers: {'Accept': 'application/json', 'Content-Type': 'text/plain'} }).then(async (response: any) => {
            let data = await response.json();

            if (data.sendMessage === "ok") {
                if(currentEditGUID !== "") {
                    this.setState({infoMessage: this.getText("Post updated")});
                    this.setState({ submitting: false });
                    this.resetWriteArea();
                    this.fetchData();
                } else {
                    this.setState({ submitting: false });
                    this.resetWriteArea();
                    this.fetchData();
                }
            } else {
                this.setState({ errorMessage: this.getText("Error") + data.error });
                this.setState({ submitting: false });
            }
        }).catch((error: any) => {
            this.setState({ errorMessage: "Error submitting timeline form: " + error.toString() });
            this.setState({ submitting: false });
        });
    }

    mergePost(sourceGUID:string) {
        if (this.context === null) {
            return;
        }

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/timelineclone?producerid=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);

        jsonurl += "&sourceGUID=" + encodeURIComponent(sourceGUID);

        if(this.state.platform === "merge") {
            jsonurl += "&platform=fans";
        }

        this.setState({ submitting: true });
        this.setState({errorMessage: ""});

        let fetchMethod:string;

        fetchMethod = "PUT";

        fetch(jsonurl, { method: fetchMethod, body: "", headers: {'Accept': 'application/json', 'Content-Type': 'text/plain'} }).then(async (response: any) => {
            let data = await response.json();

            if (data.sendMessage === "ok") {
                if(sourceGUID !== "") {
                    this.setState({infoMessage: this.getText("Post updated")});
                    this.setState({ submitting: false });
                    this.resetWriteArea();
                    this.fetchData();
                } else {
                    this.setState({ submitting: false });
                    this.resetWriteArea();
                    this.fetchData();
                }
            } else {
                this.setState({ errorMessage: this.getText("Error") + data.error });
                this.setState({ submitting: false });
            }
        }).catch((error: any) => {
            this.setState({ errorMessage: "Error submitting timeline mergePost: " + error.toString() });
            this.setState({ submitting: false });
        });
    }

    convertPriceOverrides() {
        let result:PriceOverrideMessagingPostRecord[] = [];

        for(let i = 0; i < this.state.priceOverrides.length; i++) {
            let currentOverride:PriceOverrideRecord = this.state.priceOverrides[i];
            let myPrice:number = currentOverride.priceEuro * 100;
            myPrice += currentOverride.priceCents;

            result.push({
                price: myPrice,
                tierId: currentOverride.tierId
            });
        }

        return result
    }

    clickImageButton() {
        this.setState({ mediaAddMode: "free" });
        this.setState({ imageModal: !this.state.imageModal });
    }

    clickVideoButton() {
        this.setState({ mediaAddMode: "free" });
        this.setState({ videoModal: !this.state.videoModal });
    }

    clickAudioButton() {
        this.setState({ mediaAddMode: "free" });
        this.setState({ audioModal: !this.state.audioModal });
    }

    clickPayImageButton() {
        this.setState({ mediaAddMode: "pay" });
        this.setState({ imageModal: !this.state.imageModal });
    }

    clickPayVideoButton() {
        this.setState({ mediaAddMode: "pay" });
        this.setState({ videoModal: !this.state.videoModal });
    }

    clickPayAudioButton() {
        this.setState({ mediaAddMode: "pay" });
        this.setState({ audioModal: !this.state.audioModal });
    }

    clickCalendarButton() {
        this.setState({ calendarModal: !this.state.calendarModal });
    }

    clickPriceButton() {
        this.setState({ priceModal: !this.state.priceModal });
    }

    updateCalculatedPrices() {
        if (this.context === null) {
            return;
        }
        if (!this.context.loggedin) {
            return;
        }

        let curPrice:number = this.state.price;

        if(this.state.subscriptionTiers !== null) {
            var calculatedPrices: CalculatedPriceRecord[] = [];

            calculatedPrices.push({ tierName: this.getText("DefaultPriceUsers"),tierId: 0, price: curPrice });

            for (var i = 0; i < this.state.subscriptionTiers.length; i++) {
                var subscriptionTier:SubscriptionTierData = this.state.subscriptionTiers[i];
                var calculatedPrice = curPrice;

                console.log("Calculating price for tier " + subscriptionTier.tierName + " with formula " + subscriptionTier.subscriptionFunction + " and discount " + subscriptionTier.subscriptionDiscount);
                console.log("Calculating price for tier " + subscriptionTier.tierName + " curPrice: " + calculatedPrice);

                if(subscriptionTier.subscriptionFunction === "WATCH_FOR_FREE") {
                    calculatedPrice = 0;
                } else if(subscriptionTier.subscriptionFunction === "PERCENT") {
                    calculatedPrice = (calculatedPrice * (100 - subscriptionTier.subscriptionDiscount)) / 100;
                } else if(subscriptionTier.subscriptionFunction === "ABS") {
                    calculatedPrice = calculatedPrice - subscriptionTier.subscriptionDiscount;
                }

                console.log("New calculated price for tier " + subscriptionTier.tierName + " curPrice: " + calculatedPrice);

                let myPriceRecord:PriceOverrideRecord | undefined = this.state.priceOverrides.find((record:PriceOverrideRecord) => record.tierId === subscriptionTier.id);

                if(myPriceRecord) {
                    calculatedPrice = myPriceRecord.priceEuro * 100;
                    calculatedPrice += myPriceRecord.priceCents;
                }

                calculatedPrices.push({ tierName: this.getText("User mit") + subscriptionTier.tierName,tierId: subscriptionTier.id, price: calculatedPrice });
            }

            this.setState({ calculatedPrices: calculatedPrices });
        }
    }

    updateCalculatedPricesForPosts() {
        if(this.state.subscriptionTiers !== null && this.state.posts !== null) {
            let myPostArray:TimelinePost[] = this.state.posts;

            for (let p = 0; p < myPostArray.length; p++) {
                let myPost:TimelinePost = myPostArray[p];
                let calculatedPrices: CalculatedPriceRecord[] = [];

                let curPrice:number = myPost.price;

                calculatedPrices.push({ tierName: this.getText("DefaultPrice"),tierId: 0, price: curPrice });

                for (let i = 0; i < this.state.subscriptionTiers.length; i++) {
                    let subscriptionTier:SubscriptionTierData = this.state.subscriptionTiers[i];
                    let calculatedPrice = curPrice;

                    if(subscriptionTier.subscriptionFunction === "WATCH_FOR_FREE") {
                        calculatedPrice = 0;
                    } else if(subscriptionTier.subscriptionFunction === "PERCENT") {
                        calculatedPrice = (calculatedPrice * (100 - subscriptionTier.subscriptionDiscount)) / 100;
                    } else if(subscriptionTier.subscriptionFunction === "ABS") {
                        calculatedPrice = calculatedPrice - subscriptionTier.subscriptionDiscount;
                    }

                    let isOverride:boolean = false;

                    if(myPost.priceOverrides !== null && myPost.priceOverrides !== undefined) {
                        for (let o = 0; o < myPost.priceOverrides.length; o++) {
                            let myPriceOverride:PriceOverrideMessagingPostRecord = myPost.priceOverrides[o];
    
                            if(myPriceOverride.tierId === subscriptionTier.id) {
                                calculatedPrice = myPriceOverride.price;
                                isOverride = true;
                            }
                        }
                    }

                    console.log("Calculating price for post " + myPost.guid + "[price: " + myPost.price + "] for tier " + subscriptionTier.tierName + " with formula " + subscriptionTier.subscriptionFunction + " and discount " + subscriptionTier.subscriptionDiscount + " -> " + calculatedPrice + isOverride ? " (override)" : "");

                    calculatedPrices.push({ tierName: subscriptionTier.tierName,tierId: subscriptionTier.id, price: calculatedPrice });
                }

                myPost.calculatedPrices = calculatedPrices;
            }

            this.setState({ posts: myPostArray });
        }
    }

    updateSubscriptionTiers() {
        if(this.state.platform === "guppy") {
            this.setState({ subscriptionTiers: [] },this.handleAfterSubscriptionTiersUpdate.bind(this));
        }

        this.setState({ fetchingSubscriptionTiers: true });
        this.setState({ fetchingSubscriptionTiersError: "" });

        if (this.context === null) {
            return;
        }

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/subscriptiontiers?producerid=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);

        try {
            fetch(jsonurl, { method: "GET", headers: {'Accept': 'application/json', 'Content-Type': 'text/plain'} }).then(async (response: any) => {
                let data = await response.json();

                if (data.tiers) {
                    this.setState({ fetchingSubscriptionTiers: false });
                    this.setState({ fetchingSubscriptionTiersError: "" });

                    let subscriptionTiers:SubscriptionTierData[] = [];

                    for (var i = 0; i < data.tiers.length; i++) {
                        var subscriptionTier:SubscriptionTierData = data.tiers[i];
                        subscriptionTiers.push(subscriptionTier);
                    }

                    this.setState({ subscriptionTiers: subscriptionTiers },this.handleAfterSubscriptionTiersUpdate.bind(this));
                } else {
                    this.setState({ fetchingSubscriptionTiers: false });
                    this.setState({ fetchingSubscriptionTiersError: "Error fetching subscription tiers: " + data.error });
                }
            });
        } catch(error: any) {
            this.setState({ fetchingSubscriptionTiers: false });
            this.setState({ fetchingSubscriptionTiersError: "Error fetching subscription tiers: " + error });
        }
    }

    handleAfterSubscriptionTiersUpdate() {
        this.updateCalculatedPricesForPosts();
    }

    getSubscriptionTierName(tierId:number) {
        for (var i = 0; i < this.state.subscriptionTiers.length; i++) {
            var subscriptionTier:SubscriptionTierData = this.state.subscriptionTiers[i];
            if(subscriptionTier != null) {
                if(subscriptionTier.id === tierId) {
                    if(subscriptionTier.tierName !== "") {
                        return subscriptionTier.tierName;
                    } else {
                        return "Abo-Level " + tierId;
                    }
                }
            }
        }

        return "Abo-Level " + tierId;
    }

    clickPollButton() {
        this.setState({ pollModal: !this.state.pollModal });
    }

    clickQuizButton() {
        this.setState({ quizModal: !this.state.quizModal });
    }

    clickFundraiseButton() {
        this.setState({ fundraiseModal: !this.state.fundraiseModal });
    }

    handleMessageChange(e: any) {
        this.setState({ msgText: e.target.value });
        this.handleHasBeforeUnloadChange();
    }
    handlePayMessageChange(e: any) {
        this.setState({ payText: e.target.value });
        this.handleHasBeforeUnloadChange();
    }
    handleMessageTitleChange(e: any) {
        this.setState({ msgTitle: e.target.value });
        this.handleHasBeforeUnloadChange();
    }

    handleHasBeforeUnloadChange() {
        if(!this.hasBeforeUnloadHandler) {
            window.onbeforeunload = this.beforeUnload.bind(this);
            this.hasBeforeUnloadHandler = true;
        }
    }

    switchFreeTextMode() {
        this.setState({ payTextMode: false });
        setTimeout(this.focusFreeText.bind(this),600);
    }

    focusFreeText() {
        let myElement = document.getElementById("txtMessage");

        if(myElement) {
            myElement.focus();
        }
    }

    switchPayTextMode() {
        this.setState({ payTextMode: true });
        setTimeout(this.focusPayText.bind(this),600);
    }

    focusPayText() {
        let myElement = document.getElementById("txtPayMessage");

        if(myElement) {
            myElement.focus();
        }
    }

    handleSortDropdownToggle() {
        this.setState({ sortDropdownOpen: !this.state.sortDropdownOpen });
    }

    handlePlatformDropdownToggle() {
        this.setState({ platformDropdownOpen: !this.state.platformDropdownOpen });
    }

    handleExtraPagesDropdownToggle() {
        this.setState({ extraPagesDropdownOpen: !this.state.extraPagesDropdownOpen });
    }

    handleShowViewDropdownToggle() {
        this.setState({ viewDropdownOpen: !this.state.viewDropdownOpen });
    }

    handleShowTimelineSettingsDropdownToggle() {
        this.setState({ timelineSettingsDropdownOpen: !this.state.timelineSettingsDropdownOpen });
    }

    getText(exp: string) {
        if (this.state.language === "de") {
            if(exp === "SelectPlatformGuppy") {
                return "LiveStrip/Susi-Timeline";
            }
            if(exp === "SelectPlatformFans") {
                return "Fan-Timeline";
            }
            if(exp === "SelectPlatformMerge") {
                return "Fan- und LiveStrip/Susi-Timeline zusammenführen";
            }
            if(exp === "MergePossible") {
                return "Der untenstehende Post kann auch auf der Fan-Timeline veröffentlicht werden:";
            }
            if(exp === "PinThisPost") {
                return "Diesen Post anpinnen";
            }
            if(exp === "UnpinThisPost") {
                return "Diesen Post entpinnen";
            }
            if(exp === "MakeTimelineVisibleForAll") {
                return "Timeline für alle sichtbar machen";
            }
            if(exp === "MakeTimelineVisibleForPaymentAuthOnly") {
                return "Timeline nur für User mit Zahlungsauthentifizierung sichtbar machen";
            }
            if(exp === "MakeTimelineVisibleForSubsOnly") {
                return "Timeline nur für Abonennten sichtbar machen";
            }
            if(exp === "TimelineNeedsPaymentAuth") {
                return "Deine Timeline benötigt eine Zahlungsauthentifizierung";
            }
            if(exp === "TimelineOnlyForSubs") {
                return "Deine Timeline ist nur für Abonnenten sichtbar";
            }
            if(exp === "TimelineForAllUsers") {
                return "Deine Timeline ist für alle User sichtbar";
            }
            if(exp === "Hol Dir diesen Post für nur") {
                return "Hol Dir diesen Post für nur";
            }
            if(exp === "Jetzt direkt freischalten") {
                return "Jetzt direkt freischalten";
            }
            if(exp === "oder schließe jetzt ein Abonnement ab.") {
                return "oder schließe jetzt ein Abonnement ab.";
            }
            if(exp === "Calculate") {
                return "Berechnen";
            }
            if(exp === "PromoView") {
                return "Posts für nicht-eingeloggt User";
            }
            if(exp === "NormalView") {
                return "Posts für eingeloggt User";
            }
            if(exp === "Promo") {
                return "Für nicht-eingeloggte User";
            }
            if(exp === "EditPost") {
                return "Post bearbeiten";
            }
            if(exp === "DeletePost") {
                return "Post löschen";
            }
            if(exp === "EnablePromo") {
                return "Für nicht-eingeloggte User sichtbar machen";
            }
            if(exp === "DisablePromo") {
                return "Nur für eingeloggte User sichtbar machen";
            }
            if(exp === "EnableNonSub") {
                return "Für nicht-Abonnenten sichtbar machen";
            }
            if(exp === "DisableNonSub") {
                return "Nur für Abonnenten sichtbar machen";
            }
            if(exp === "OnlySub") {
                return "Nur Abonnenten";
            }
            if(exp === "NonSub") {
                return "Nicht-Abonnenten";
            }
            if(exp === "EnableNonSubExplanation") {
                return "Dieser Post ist nur für Abonennten sichtbar, klicke um ihn für nicht-Abonnenten sichtbar zu machen.";
            }
            if(exp === "DisableNonSubExplanation") {
                return "Dieser Post ist auch für nicht-Abonennten sichtbar, klicke um ihn nur für Abonnenten sichtbar zu machen.";
            }
            if(exp === "This is") {
                return "Das ist die ";
            }
            if(exp === "Change") {
                return "Ändern";
            }
            if(exp === "ChangeView") {
                return "Wechseln";
            }
            if(exp === "AbonenntenView") {
                return "Ansicht für Abonnenten";
            }
            if(exp === "NonAbonenntenView") {
                return "Ansicht für nicht-Abonnenten";
            }
            if(exp === "Starte jetzt") {
                return "Starte jetzt ";
            }
            if(exp === "lang durch") {
                return " lang durch ";
            }
            if(exp === "for only") {
                return " für nur ";
            }
            if(exp === "CancellableAfterMinRuntime") {
                return "Nach der Mindestlaufzeit ist das Abo jederzeit zum Ende einer Abrechnungsperiode kündbar."
            }
            if(exp === "DailyCancellableAfterMinRuntime") {
                return "Nach der Mindestlaufzeit ist das Abo täglich kündbar."
            }
            if(exp === "WeeklyCancellableAfterMinRuntime") {
                return "Nach der Mindestlaufzeit ist das Abo jeweils zum Ende einer Woche kündbar."
            }
            if(exp === "MonthlyCancellableAfterMinRuntime") {
                return "Nach der Mindestlaufzeit ist das Abo jeweils zum Ende des Monats kündbar."
            }
            if(exp === "VideoDetails") {
                return "Video-Details";
            }
            if(exp === "Sort") {
                return "Sortierung";
            }
            if(exp === "PublishTime") {
                return "Veröffentlichungszeitpunkt";
            }
            if(exp === "CreationTime") {
                return "Erstellungszeitpunkt";
            }
            if(exp === "Views") {
                return "Ansichten";
            }
            if(exp === "DeletePostExplanation") {
                return "Post löschen.";
            }
            if(exp === "EditPostExplanation") {
                return "Post bearbeiten (bitte beachte, dass Änderungen von uns geprüft werden, falls der Post bereits veröffentlicht ist)";
            }
            if(exp === "FreeTextExplanation") {
                return "Dieser Text wird für alle Benutzer sichtbar sein.";
            }
            if(exp === "PayTextExplanation") {
                return "Dieser Text wird nur für Abonnenten und User, die den Post gekauft haben, sichtbar sein.";
            }
            if(exp === "MsgTitle") {
                return "Überschrift";
            }
            if(exp === "PriceExplanation") {
                return "Nicht-Abonnenten müssen diesen Preis für die kostenpflichtigen Inhalte dieses Posts zahlen. Kostenpflichtige Inhalte sind der PayText sowie die kostenpflichtigen Bilder, Video und Audios, die an diesem Post angehangen sind.";
            }
            if(exp === "PayImageExplanation") {
                return "Füge dem Post kostenpflichtige Bilder hinzu.";
            }
            if(exp === "PayVideoExplanation") {
                return "Füge dem Post kostenpflichtige Videos hinzu.";
            }
            if(exp === "PayAudioExplanation") {
                return "Füge dem Post kostenpflichtige Audioaufnahmen hinzu.";
            }
            if(exp === "FreeImageExplanation") {
                return "Füge dem Post kostenlose Bilder hinzu.";
            }
            if(exp === "FreeVideoExplanation") {
                return "Füge dem Post kostenlose Videos hinzu.";
            }
            if(exp === "FreeAudioExplanation") {
                return "Füge dem Post kostenlose Audioaufnahmen hinzu.";
            }
            if(exp === "CalendarExplanation") {
                return "Lege fest, wann der Post veröffentlicht werden soll (dadurch kannst Du auch die Sortierung bestimmen)";
            }
            if(exp === "payimage") {
                return "Kostenpflichtiges Bild";
            }
            if(exp === "payvideo") {
                return "Kostenpflichtiges Video";
            }
            if(exp === "freeimage") {
                return "Kostenloses Bild";
            }
            if(exp === "freevideo") {
                return "Kostenloses Video";
            }
            if(exp === "ChangeToFree") {
                return "Kostenlos machen";
            }
            if(exp === "ChangeToPay") {
                return "kostenpflichtig machen";
            }
            if(exp === "ChangeToFreevideo") {
                return "Zum kostenlosen Video machen";
            }
            if(exp === "ChangeToPayvideo") {
                return "Zum kostenpflichtigen Video machen";
            }
            if(exp === "ChangeToFreeimage") {
                return "Zum kostenlosen Bild machen";
            }
            if(exp === "ChangeToPayimage") {
                return "Zum kostenpflichtigen Bild machen";
            }
            if(exp === "Title") {
                return "Überschrift";
            }
            if(exp === "MakeNewPost") {
                return "Post abschicken";
            }
            if(exp === "SaveEditedPost") {
                return "Änderung speichern";
            }
            if(exp === "Close") {
                return "Schließen";
            }
            if(exp === "Delete") {
                return "Löschen";
            }
            if(exp === "Edit") {
                return "Bearbeiten";
            }
            if(exp === "Cancel") {
                return "Abbrechen";
            }
            if(exp === "DeleteContentFromPost") {
                return "Lösche Inhalt aus Post";
            }
            if(exp === "ToBePublished") {
                return "Wird veröffentlicht";
            }
            if(exp === "ShowDeleted") {
                return "Zeige gelöschte Posts";
            }
            if(exp === "HideDeleted") {
                return "Verstecke gelöschte Posts";
            }
            if(exp === "Post updated") {
                return "Vielen Dank, die Änderungen für Deinen Post wurden gespeichert und werden nun von uns geprüft.";
            }
            if(exp === "DoYouReallyWantToDeleteThisPost") {
                return "Möchtest Du diesen Post wirklich löschen?";
            }
            if(exp === "EditContent") {
                return "Bearbeitet";
            }
            if(exp === "Save") {
                return "Speichern";
            }
            if(exp === "New Post") {
                return "Neuer Post";
            }
            if(exp === "Edit Post") {
                return "Post bearbeiten";
            }
            if(exp === "User mit") {
                return "User mit ";
            }
            if (exp === "TimelineSubtitle") {
                return "Hier kannst Du Ankündigungen für Deine Kunden und Besucher von Deinem Profil hinterlassen, zudem kannst Du kostenpflichtige Inhalte einstellen, die Deine Nutzer kaufen können.";
            }
            if (exp === "Submit") {
                return "Abschicken";
            }
            if (exp === "LoginError") {
                return "Fehler beim Einloggen";
            }
            if (exp === "New Message") {
                return "Neue Nachricht";
            }
            if(exp === "OrangeBorderedImages") {
                return "Orange umrandete Bilder/Videos müssen noch freigeschaltet werden, bevor sie den Usern angezeigt werden.";
            }
            if(exp === "Approved") {
                return "Freigeschaltet";
            }
            if(exp === "Rejected") {
                return "Abgelehnt";
            }
            if(exp === "InReview") {
                return "Noch nicht geprüft";
            }
            if(exp === "EditRequested") {
                return "Bearbeitung angefordert";
            }
            if(exp === "Erstellt") {
                return "Erstellt";
            }
            if(exp === "Published") {
                return "Veröffentlichungsdatum";
            }
            if(exp === "Veröffentlichungsdatum festlegen") {
                return "Veröffentlichungsdatum festlegen";
            }
            if(exp === "DefaultPrice") {
                return "Standardpreis";
            }
            if(exp === "CalculatedPrices") {
                return "Errechnete Preise";
            }
            if(exp === "DefaultPriceUsers") {
                return "Standardpreis für Nutzer";
            }
            if(exp === "EditSubscriptionTiers") {
                return "Abo-Level bearbeiten";
            }
            if(exp === "Price") {
                return "Preis";
            }
            if(exp === "Kostenlose Inhalte für Deine neue Nachricht") {
                return "Kostenlose Inhalte für Deine neue Nachricht";
            }
            if(exp === "Kostenpflichtige Inhalte für Deine neue Nachricht") {
                return "Kostenpflichtige Inhalte für Deine neue Nachricht";
            }
            if(exp === "OnInitialRuntimeOf") {
                return "bei Mindestlaufzeit von ";
            }
            if(exp === "CurrentSelection") {
                return "Aktuelle Auswahl";
            }
            if(exp === "CurrentPrice") {
                return "Aktueller Preis";
            }
            if(exp === "CreateGroup") {
                return "Abo-Paket erstellen";
            }
            if(exp === "CreateTierGroup") {
                return "Abo-Paket erstellen";
            }
            if(exp === "Erklärung") {
                return "Erklärung";
            }
            if(exp === "MinRuntimeDescription") {
                return "Die Mindestlaufzeit dieses Abos beträgt ";
            }
            if(exp === "MinRuntime") {
                return "Laufzeit";
            }
            if(exp === "NoMinRuntime") {
                return "Eine Kündigung des Abonnements ist jederzeit möglich.";
            }
            if(exp === "MinRuntimePriceDescription") {
                return " der Preis während der Mindestlaufzeit beträgt ";
            }
            if(exp === "AfterMinRuntimeDescription") {
                return "Nach der Mindestlaufzeit wechselt das Abo in den regulären Modus.";
            }
            if(exp === "RegularPriceDescription") {
                return "Das Abo kostet im regulären Modus";
            }
            if(exp === "one") {
                return "einen";
            }
            if(exp === "each") {
                return "jeden";
            }
            if(exp === "every") {
                return "alle";
            }
            if(exp === "currently") {
                return "Aktuell";
            }
            if(exp === "RegularCancelInfo") {
                return " und kann dann jeweils zum nächsten Abbuchungszeitraum gekündigt werden.";
            }
            if(exp === "Edit Done") {
                return "Abo-Level wurde gespeichert";
            }
            if(exp === "NumSubscribers") {
                return "Anzahl der Abonnenten";
            }
            if(exp === "WatchForFreeExplanation") {
                return "Abonennten dürfen Posts kostenlos ansehen (es sei denn, in dem Post ist explizit eingestellt, dass er für Abonennten Geld kosten soll)";
            }
            if(exp === "WatchPercentExplanation") {
                return "Abonennten erhalten beim Kauf von Posts einen Rabatt von PERCENT Prozent (es kann aber ein eigener Preis für jeden Post festgelegt werden)";
            }
            if(exp === "WatchAbsExplanation") {
                return "Abonennten erhalten beim Kauf von Posts einen Rabatt von ABS € (es kann aber ein eigener Preis für jeden Post festgelegt werden)";
            }
            if(exp === "TierGroupTemplateFree") {
                return "Ein kostenloses Abo-Level";
            }
            if(exp === "TierGroupTemplateDefault") {
                return "Ein Abo-Level für 29,99 € pro Monat";
            }
            if(exp === "TierGroupTemplateBasisPremium") {
                return "Abo-Level Basis und Premium für 29,99 bzw. 49,99 € pro Monat";
            }
            if(exp === "TierGroupTemplateStandardGoldPlatin") {
                return "Abo-Level Standard, Gold und Platin für 19,99, 34,99 und 49,99 € pro Monat";
            }
            if(exp === "TierGroupTemplateFree") {
                return "Ein kostenloses Abo-Level";
            }
            if(exp === "TierGroupTemplateFree") {
                return "Ein kostenloses Abo-Level";
            }
            if(exp === "Select tier group template") {
                return "Wähle zu erstellende Vorlage aus";
            }
            if(exp === "Create new tier") {
                return "Erstelle ein einzelnes Abo-Level";
            }
            if(exp === "Edit tier") {
                return "Bearbeite Abo-Level";
            }
            if(exp === "Create new tier group") {
                return "Erstelle ein Abo-Paket für Deine Kunden";
            }
            if (exp === "Tier Name") {
                return "Abo-Level Name";
            }
            if (exp === "Tier Description") {
                return "Abo-Level Beschreibung";
            }
            if (exp === "Tier Price") {
                return "Abo-Preis";
            }
            if (exp === "Tier Billing Period") {
                return "Abo-Abbuchungszeitraum";
            }
            if (exp === "Billing Period") {
                return "Abbuchungszeitraum";
            }
            if(exp === "NoSubscriptionTierSelected") {
                return "Hier kannst Du 'Abo-Level' erstellen und bearbeiten. Deine Kunden/User können zwischen diesen Abo-Levels auswählen, so kannst Du z.B. unterschiedliche Laufzeiten anbieten oder eine Abstufung in der Form Basis/Premium Abo, bei dem nur die Premium-Abonennten bestimmte Inhalte kostenlos bekommen. Zusätzlich kannst für jeden Deiner Posts auch immer individuelle Preise einstellen, falls Du mal sehr kurze oder besondere Premium-Inhalte verkaufen möchtest.";
            }
            if(exp === "SubscriptionTierSelected") {
                return "Die Abo-Level lassen sich auch nachträglich bearbeiten, aber wenn es bereits Abonennten gibt, dann wird das von unserem Team geprüft, wenn sich Abo-Preise und -Laufzeiten ändern sollen."
            }
            if (exp === "Edit") {
                return "Bearbeiten";
            }
            if (exp === "Close") {
                return "Schliessen";
            }
            if (exp === "Days") {
                return "Tage";
            }
            if (exp === "Weeks") {
                return "Wochen";
            }
            if(exp === "Month") {
                return "Monat";
            }
            if(exp === "Months") {
                return "Monate";
            }
            if(exp === "MonthlyCancellable") {
                return "Eine Kündigung des Abonnements ist jederzeit möglich.";
            }
            if(exp === "SubscriptionTiers") {
                return "Abonnement-Level einrichten";
            }
            if (exp === "Name") {
                return "Name des Levels (z.B. 'Gold','Silber','Platin')";
            }
            if(exp === "Description") {
                return "Beschreibungs-Text für das Abo-Level";
            }
            if(exp === "Price") {
                return "Preis";
            }
            if(exp === "Posts are free to watch") {
                return "Abonnenten in diesem Level können meine Posts kostenlos ansehen";
            }
            if(exp === "Posts are discounted by percent") {
                return "Abonnenten in diesem Level können meine Posts für PERCENT Prozent weniger kaufen";
            }
            if(exp === "Posts are discounted by cents") {
                return "Abonnenten in diesem Level können meine Posts für ABS Cents weniger kaufen";
            }
            if(exp === "Tier Subscription Function") {
                return "Was erhalten die Abonennten in diesem Level?";
            }
            if(exp === "Tier Subscription Function Number PERCENT") {
                return "Rabatt in Prozent";
            }
            if(exp === "Tier Subscription Function Number ABS") {
                return "Rabatt in €-Cents (100 = 1 €)";
            }
            if(exp === "Submit") {
                return "Speichern";
            }
            if(exp === "Cancel") {
                return "Abbrechen";
            }
            if(exp === "SelectedTierName") {
                return "Name des ausgewählten Abo-Level";
            }
            if(exp === "SelectedTierDescription") {
                return "Beschreibung ausgewähltes Abo-Level";
            }
            if(exp === "SelectedTierPrice") {
                return "Preis ausgewähltes Abo-Level";
            }
            if(exp === "SelectedTierBillingPeriod") {
                return "Abbuchungszeitraum";
            }
            if(exp === "SelectedTierSubscriptionFunction") {
                return "Benefit für Abonennten in diesem Abo-Level";
            }
            if(exp === "FetchSubscribers") {
                return "Abonennten anzeigen";
            }
            if(exp === "Subscribers") {
                return "Abonennten";
            }
            if(exp === "NoSubscribers") {
                return "Keine Abonennten gefunden";
            }
            if(exp === "HideSubscribers") {
                return "Abonennten ausblenden";
            }
            if(exp === "InitialRuntime") {
                return "Mindestlaufzeit";
            }
            if(exp === "InitialRuntimePrice") {
                return "Preis während der Mindestlaufzeit";
            }
            if(exp === "InitialPrice") {
                return "Preis während der Mindestlaufzeit";
            }
            if(exp === "NumberOf") {
                return "Anzahl";
            }
            if(exp === "ThereIsNoMinRuntime") {
                return "Es gibt keine Mindestlaufzeit.";
            }
            if(exp === "afterMinruntime") {
                return "danach";
            }
        } else {
            if(exp === "SelectPlatformGuppy") {
                return "LiveStrip/Susi-Timeline";
            }
            if(exp === "SelectPlatformFans") {
                return "Fan-Timeline";
            }
            if(exp === "SelectPlatformMerge") {
                return "Merge Fan- und LiveStrip/Susi-Timeline";
            }
            if(exp === "MergePossible") {
                return "The post below can also be published on the fan timeline:";
            }
            if(exp === "PinThisPost") {
                return "Pin this post";
            }
            if(exp === "UnpinThisPost") {
                return "Unpin this post";
            }
            if(exp === "MakeTimelineVisibleForAll") {
                return "Make Timeline visible for all users";
            }
            if(exp === "MakeTimelineVisibleForPaymentAuthOnly") {
                return "Make Timeline visible for users with payment authorization only";
            }
            if(exp === "MakeTimelineVisibleForSubsOnly") {
                return "Make Timeline visible for subs only";
            }
            if(exp === "TimelineNeedsPaymentAuth") {
                return "Your timeline is only visible for users with payment authorization.";
            }
            if(exp === "TimelineOnlyForSubs") {
                return "Timeline only for subs.";
            }
            if(exp === "TimelineForAllUsers") {
                return "Timeline is for all users.";
            }
            if(exp === "Hol Dir diesen Post für nur") {
                return "Get this post for only";
            }
            if(exp === "Jetzt direkt freischalten") {
                return "Unlock now";
            }
            if(exp === "oder schließe jetzt ein Abonnement ab.") {
                return "or subscribe to this creator.";
            }
            if(exp === "PromoView") {
                return "Posts for not-logged in users";
            }
            if(exp === "NormalView") {
                return "Posts for logged in users";
            }
            if(exp === "Promo") {
                return "For non-logged in users";
            }
            if(exp === "EditPost") {
                return "Edit Post";
            }
            if(exp === "DeletePost") {
                return "Delete Post";
            }
            if(exp === "EnablePromo") {
                return "Make visible for non-logged in users";
            }
            if(exp === "DisablePromo") {
                return "Make visible for logged in users only";
            }
            if(exp === "EnableNonSub") {
                return "Make visible for non-subscribers";
            }
            if(exp === "DisableNonSub") {
                return "Make visible for subscribers only";
            }
            if(exp === "OnlySub") {
                return "Only subscribers";
            }
            if(exp === "NonSub") {
                return "Non-subscribers";
            }
            if(exp === "EnableNonSubExplanation") {
                return "This post is only visible to subscribers, click to make it visible to non-subscribers as well.";
            }
            if(exp === "DisableNonSubExplanation") {
                return "This post is visible to subscribers and non-subscribers, click to make it visible to subscribers only.";
            }
            if(exp === "This is") {
                return "This is the ";
            }
            if(exp === "Change") {
                return "Change";
            }
            if(exp === "ChangeView") {
                return "Change";
            }
            if(exp === "AbonenntenView") {
                return "Subscriber-View";
            }
            if(exp === "NonAbonenntenView") {
                return "Non-Subscriber-View";
            }
            if(exp === "Starte jetzt") {
                return "Get started now - ";
            }
            if(exp === "lang durch") {
                return " long ";
            }
            if(exp === "for only") {
                return " for only ";
            }
            if(exp === "CancellableAfterMinRuntime") {
                return "After the minimum term, the subscription can be canceled at any time at the end of a billing period."
            }
            if(exp === "DailyCancellableAfterMinRuntime") {
                return "After the minimum term, the subscription can be canceled on a daily basis."
            }
            if(exp === "WeeklyCancellableAfterMinRuntime") {
                return "After the minimum term, the subscription can be canceled at the end of a week."
            }
            if(exp === "MonthlyCancellableAfterMinRuntime") {
                return "After the minimum term, the subscription can be canceled at the end of the month."
            }
            if(exp === "VideoDetails") {
                return "Video-Details";
            }
            if(exp === "DeletePostExplanation") {
                return "Delete post.";
            }
            if(exp === "EditPostExplanation") {
                return "Edit post.";
            }
            if(exp === "FreeTextExplanation") {
                return "This text will be visible for all users.";
            }
            if(exp === "PayTextExplanation") {
                return "This text will only be visible for subscribers and users who have paid for this post.";
            }
            if(exp === "MsgTitle") {
                return "Post Title";
            }
            if(exp === "PriceExplanation") {
                return "Set the price for the paid-content in this post. Non-subscribers will have to pay this price to see the paytext and paid images/videos/audio.";
            }
            if(exp === "PayImageExplanation") {
                return "Add paid images to the post.";
            }
            if(exp === "PayVideoExplanation") {
                return "Add paid videos to the post.";
            }
            if(exp === "PayAudioExplanation") {
                return "Add paid audio recordings to the post.";
            }
            if(exp === "FreeImageExplanation") {
                return "Add free images to the post.";
            }
            if(exp === "FreeVideoExplanation") {
                return "Add free videos to the post.";
            }
            if(exp === "FreeAudioExplanation") {
                return "Add free audio recordings to the post.";
            }
            if(exp === "CalendarExplanation") {
                return "Set the date when the post will be published (also useful for sorting posts)";
            }
            if(exp === "payimage") {
                return "Paid Image";
            }
            if(exp === "payvideo") {
                return "Paid Video";
            }
            if(exp === "freeimage") {
                return "Free Image";
            }
            if(exp === "freevideo") {
                return "Free Video";
            }
            if(exp === "ChangeToFree") {
                return "Mark as free Content";
            }
            if(exp === "ChangeToPay") {
                return "Mark as paid Content";
            }
            if(exp === "ChangeToFreevideo") {
                return "Mark as free Video";
            }
            if(exp === "ChangeToPayvideo") {
                return "Mark as paid Video";
            }
            if(exp === "ChangeToFreeimage") {
                return "Mark as free Image";
            }
            if(exp === "ChangeToPayimage") {
                return "Mark as paid Image";
            }
            if(exp === "MakeNewPost") {
                return "Submit Posts";
            }
            if(exp === "SaveEditedPost") {
                return "Save Changes";
            }
            if(exp === "DeleteContentFromPost") {
                return "Delete Content from Post";
            }
            if(exp === "ToBePublished") {
                return "To be published";
            }
            if(exp === "ShowDeleted") {
                return "Show deleted posts";
            }
            if(exp === "HideDeleted") {
                return "Hide deleted posts";
            }
            if(exp === "Post updated") {
                return "Thank you, the changes for your post have been saved and will now be reviewed by us.";
            }
            if(exp === "DoYouReallyWantToDeleteThisPost") {
                return "Do you really want to delete this post?";
            }
            if(exp === "EditContent") {
                return "Edit";
            }
            if(exp === "Save") {
                return "Save";
            }
            if(exp === "Price") {
                return "Price";
            }
            if(exp === "Users mit") {
                return "Users with ";
            }
            if (exp === "TimelineSubtitle") {
                return "Here you can post announcements for your customers and visitors of your profile, you can also post paid content that your users can buy. In addition there are polls, quizzes and other features.";
            }
            if (exp === "LoginError") {
                return "Login Problem";
            }
            if(exp === "OrangeBorderedImages") {
                return "Orange bordered images/videos have to be reviewed, before users will be able to see them.";
            }
            if(exp === "EditRequested") {
                return "Edit Requested";
            }
            if(exp === "Erstellt") {
                return "Created";
            }
            if(exp === "Published") {
                return "PublishTime";
            }
            if(exp === "Veröffentlichungsdatum festlegen") {
                return "Set Publish Time";
            }
            if(exp === "DefaultPrice") {
                return "Default Price";
            }
            if(exp === "CalculatedPrices") {
                return "Calculated Prices";
            }
            if(exp === "DefaultPriceUsers") {
                return "Default Price for Users";
            }
            if(exp === "EditSubscriptionTiers") {
                return "Edit Subscription Levels";
            }
            if(exp === "Kostenlose Inhalte für Deine neue Nachricht") {
                return "Items that will be available for free in your new post";
            }
            if(exp === "Kostenpflichtige Inhalte für Deine neue Nachricht") {
                return "Items that have to be paid for in your new post";
            }
            if(exp === "OnInitialRuntimeOf") {
                return "During the initial runtime of ";
            }
            if(exp === "CurrentSelection") {
                return "Current selection";
            }
            if(exp === "CurrentPrice") {
                return "Current price";
            }
            if(exp === "Erklärung") {
                return "Explanation";
            }
            if(exp === "MinRuntimeDescription") {
                return "Minimum runtime for a subscription is ";
            }
            if(exp === "MinRuntime") {
                return "Minimum runtime";
            }
            if(exp === "NoMinRuntime") {
                return "no minimum runtime";
            }
            if(exp === "MinRuntimePriceDescription") {
                return " the price during this time is ";
            }
            if(exp === "AfterMinRuntimeDescription") {
                return "After the minimum runtime the subscription will switch to regular mode.";
            }
            if(exp === "RegularPriceDescription") {
                return "The regular price is ";
            }
            if(exp === "one") {
                return "one";
            }
            if(exp === "each") {
                return "jeden";
            }
            if(exp === "every") {
                return "every";
            }
            if(exp === "currently") {
                return "currently";
            }
            if(exp === "RegularCancelInfo") {
                return " and can then be cancelled at any time.";
            }
            if(exp === "Edit Done") {
                return "Done";
            }
            if(exp === "NumSubscribers") {
                return "Number of subscribers";
            }
            if(exp === "WatchForFreeExplanation") {
                return "Subscribers will be able to watch your posts for free (unless there is a price set for the post)";
            }
            if(exp === "WatchPercentExplanation") {
                return "Subscribers will get a discount of {x} % when buying posts (but a price can be set for each post)";
            }
            if(exp === "WatchAbsExplanation") {
                return "Subscribers will get a discount of {x} € when buying posts (but a price can be set for each post)";
            }
            if(exp === "TierGroupTemplateFree") {
                return "One free subscription level";
            }
            if(exp === "TierGroupTemplateDefault") {
                return "One subscription level for 29,99 € per month";
            }
            if(exp === "TierGroupTemplateBasisPremium") {
                return "Subscription levels Basis and Premium for 29,99 and 49,99 € per month";
            }
            if(exp === "TierGroupTemplateStandardGoldPlatin") {
                return "Subscription levels Standard, Gold and Platin for 19,99, 34,99 and 49,99 € per month";
            }
            if(exp === "Select tier group template") {
                return "Select tier group template";
            }
            if(exp === "Create new tier") {
                return "Please select a subscription level";
            }
            if(exp === "Edit tier") {
                return "Edit subscription level";
            }
            if(exp === "Create new tier group") {
                return "Create new subscription level group";
            }
            if (exp === "Tier Name") {
                return "New Subscription Level Name";
            }
            if (exp === "Tier Description") {
                return "New Subscription Level Description";
            }
            if (exp === "Tier Price") {
                return "New Subscription Level Price";
            }
            if (exp === "Tier Billing Period") {
                return "Billing Period";
            }
            if (exp === "Billing Period") {
                return "Billing Period";
            }
            if(exp === "NoSubscriptionTierSelected") {
                return "Here you can create and edit 'Subscription Levels'. Your customers/users can choose between these subscription levels, so you can, for example, offer different terms or a gradation in the form of a basic/premium subscription, where only premium subscribers get certain content free of charge. In addition, you can always set individual prices for each of your posts if you want to sell very short or special premium content.";
            }
            if(exp === "SubscriptionTierSelected") {
                return "The subscription levels can also be edited afterwards, but if there are already subscribers, this will be checked by our team if subscription prices and durations are to change."
            }
            if (exp === "Days") {
                return "Days";
            }
            if (exp === "Weeks") {
                return "Weeks";
            }
            if(exp === "Months") {
                return "Months";
            }
            if(exp === "MonthlyCancellable") {
                return "Monthly cancellable";
            }
            if(exp === "SubscriptionTiers") {
                return "Subscription Level Management";
            }
            if (exp === "Name") {
                return "Name";
            }
            if(exp === "Description") {
                return "Beschreibung";
            }
            if(exp === "Price") {
                return "Preis";
            }
            if(exp === "Posts are free to watch") {
                return "Users in this level can watch my posts for free";
            }
            if(exp === "Posts are discounted by percent") {
                return "Users in this level can buy my posts for x percent off";
            }
            if(exp === "Posts are discounted by cents") {
                return "Users in this level can buy my posts for x cents off";
            }
            if(exp === "Tier Subscription Function") {
                return "Subscription Level Benefit";
            }
            if(exp === "Tier Subscription Function Number PERCENT") {
                return "Discount in percent";
            }
            if(exp === "Tier Subscription Function Number ABS") {
                return "Discount in €-Cents (100 = 1 €)";
            }
            if(exp === "Submit") {
                return "Submit";
            }
            if(exp === "Cancel") {
                return "Cancel";
            }
            if(exp === "SelectedTierName") {
                return "Name of selected subscription level";
            }
            if(exp === "SelectedTierDescription") {
                return "Description of selected subscription level";
            }
            if(exp === "SelectedTierPrice") {
                return "Price of selected subscription level";
            }
            if(exp === "SelectedTierBillingPeriod") {
                return "Billing Period";
            }
            if(exp === "SelectedTierSubscriptionFunction") {
                return "Benefit for users in this subscription level";
            }
            if(exp === "FetchSubscribers") {
                return "Show subscribers";
            }
            if(exp === "Subscribers") {
                return "Subscribers";
            }
            if(exp === "NoSubscribers") {
                return "No subscribers found";
            }
            if(exp === "HideSubscribers") {
                return "Hide subscribers";
            }
            if(exp === "InitialRuntime") {
                return "Minimum Runtime";
            }
            if(exp === "InitialPrice") {
                return "Price during minimum runtime";
            }
            if(exp === "InitialRuntimePrice") {
                return "Price during minimum runtime";
            }
            if(exp === "NumberOf") {
                return "Number of";
            }
            if(exp === "ThereIsNoMinRuntime") {
                return "There is no minimum runtime.";
            }
            if(exp === "afterMinruntime") {
                return "after that";
            }
        }

        return exp;
    }

    imageModalClose() {
        this.setState({imageModal: false});
    }

    videoModalClose() {
        this.setState({videoModal: false});
    }

    imageModalChangeMediaAddMode(mode:string) {
        this.setState({mediaAddMode: mode});
    }

    videoModalChangeMediaAddMode(mode:string) {
        this.setState({mediaAddMode: mode});
    }

    imageModalSelect(imageData:MessagingPostMediaData) {
        this.setState({imageModal: false});
        if(this.state.mediaAddMode == "free") {
            console.log("Adding free media: " + JSON.stringify(imageData));
            this.setState({freeMedia: [...this.state.freeMedia,imageData]});
        } else {
            console.log("Adding pay media: " + JSON.stringify(imageData));
            this.setState({payMedia: [...this.state.payMedia,imageData]},this.updateCalculatedPrices.bind(this));
        }
        this.handleHasBeforeUnloadChange();
    }

    imageModalSelectMulti(imageData:MessagingPostMediaData[]) {
        this.setState({imageModal: false});
        if(this.state.mediaAddMode == "free") {
            console.log("Adding free media array: " + JSON.stringify(imageData));
            this.setState({freeMedia: [...this.state.freeMedia,...imageData]});
        } else {
            console.log("Adding pay media array: " + JSON.stringify(imageData));
            this.setState({payMedia: [...this.state.payMedia,...imageData]},this.updateCalculatedPrices.bind(this));
        }
        this.handleHasBeforeUnloadChange();
    }

    videoModalSelect(videoData:MessagingPostMediaData) {
        this.setState({videoModal: false});
        if(this.state.mediaAddMode == "free") {
            console.log("Adding free media: " + JSON.stringify(videoData));
            this.setState({freeMedia: [...this.state.freeMedia,videoData]});
        } else {
            console.log("Adding pay media: " + JSON.stringify(videoData));
            this.setState({payMedia: [...this.state.payMedia,videoData]},this.updateCalculatedPrices.bind(this));
        }
        this.handleHasBeforeUnloadChange();
    }

    videoModalSelectMulti(videoData:MessagingPostMediaData[]) {
        this.setState({videoModal: false});
        if(this.state.mediaAddMode == "free") {
            console.log("Adding free media array: " + JSON.stringify(videoData));
            this.setState({freeMedia: [...this.state.freeMedia,...videoData]});
        } else {
            console.log("Adding pay media array: " + JSON.stringify(videoData));
            this.setState({payMedia: [...this.state.payMedia,...videoData]},this.updateCalculatedPrices.bind(this));
        }
        this.handleHasBeforeUnloadChange();
    }

    fetchVideoInfo = async(response: any) => {
        let data = await response.json();

        if(typeof(data.video) !== "undefined") {
            if(data.video) {
                if(this.state.msgTitle == "") {
                    this.setState({msgTitle: data.video.title});
                }
                if(this.state.msgText == "") {
                    this.setState({msgText: data.video.description});
                }
            }
        }
    }

    freeMediaClicked(clickedMediaData:MessagingPostMediaData,deletable:boolean) {
        if(clickedMediaData) {
            this.setState({mediaAddMode: "free"});
            this.setState({clickedMediaData: clickedMediaData},this.fetchRealImageURLs.bind(this));
            this.setState({clickedMediaDeletable: deletable});
            setTimeout(this.fetchRealImageURLs.bind(this),1000);
        } else {
            this.setState({errorMessage: "Invalid image clicked"});
        }
    }

    payMediaClicked(clickedMediaData:MessagingPostMediaData,deletable:boolean) {
        if(clickedMediaData) {
            this.setState({mediaAddMode: "pay"});
            this.setState({clickedMediaData: clickedMediaData},this.fetchRealImageURLs.bind(this));
            this.setState({clickedMediaDeletable: deletable});
            setTimeout(this.fetchRealImageURLs.bind(this),1000);
        } else {
            this.setState({errorMessage: "Invalid image clicked"});
        }
    }

    closeClickedMediaData() {
        this.setState({clickedMediaData: null});
    }

    deleteClickedMediaData() {
        if(this.state.clickedMediaData !== null) {
            let myImages:MessagingPostMediaData[];
            if(this.state.mediaAddMode == "free") {
                myImages = this.state.freeMedia.filter((img:MessagingPostMediaData) => { return img.mediaID !== this.state.clickedMediaData?.mediaID });
                this.setState({freeMedia: myImages});
            } else {
                myImages = this.state.payMedia.filter((img:MessagingPostMediaData) => { return img.mediaID !== this.state.clickedMediaData?.mediaID });
                this.setState({payMedia: myImages});
            }
            this.setState({clickedMediaData: null});
        }
    }

    clickSingleVideoEdit() {
        if(this.state.clickedMediaData) {
            this.setState({singleVideoEditStreamname: this.state.clickedMediaData.mediaID});
        }
    }

    changeClickedMediaDataToPay() {
        let myImages:MessagingPostMediaData[];
        myImages = this.state.freeMedia.filter((img:MessagingPostMediaData) => { return img.mediaID !== this.state.clickedMediaData?.mediaID });
        this.setState({freeMedia: myImages});
        this.setState({payMedia: [...this.state.payMedia,this.state.clickedMediaData]});
        this.setState({mediaAddMode: "pay"});
    }

    changeClickedMediaDataToFree() {
        let myImages:MessagingPostMediaData[];
        myImages = this.state.payMedia.filter((img:MessagingPostMediaData) => { return img.mediaID !== this.state.clickedMediaData?.mediaID });
        this.setState({payMedia: myImages});
        this.setState({freeMedia: [...this.state.freeMedia,this.state.clickedMediaData]});
        this.setState({mediaAddMode: "free"});
    }

    clickedMediaDataModalClose() {
        this.setState({clickedMediaData: null});
    }

    handlePriceSubmit(e:FormEvent) {
        e.preventDefault();
        let newprice:number = this.state.priceInputEuro * 100;
        newprice += this.state.priceInputCents;
        this.setState({price: newprice},this.updateCalculatedPrices.bind(this));
        this.setState({priceModal: false});
    }

    priceModalClose() {
        this.setState({priceModal: false});
    }

    pollModalClose() {
        this.setState({pollModal: false});
    }

    quizModalClose() {
        this.setState({quizModal: false});
    }

    fundraiseModalClose() {
        this.setState({fundraiseModal: false});
    }

    calendarModalClose() {
        this.setState({calendarModal: false});
    }

    countFreeMediaImages() {
        return this.state.freeMedia.filter((item:MessagingPostMediaData) => { return item.type === "image" }).length;
    }

    countFreeMediaVideos() {
        return this.state.freeMedia.filter((item:MessagingPostMediaData) => { return item.type === "video" }).length;
    }

    countFreeMediaAudios() {
        return this.state.freeMedia.filter((item:MessagingPostMediaData) => { return item.type === "audio" }).length;
    }

    countPayMediaImages() {
        return this.state.payMedia.filter((item:MessagingPostMediaData) => { return item.type === "image" }).length;
    }

    countPayMediaVideos() {
        return this.state.payMedia.filter((item:MessagingPostMediaData) => { return item.type === "video" }).length;
    }

    countPayMediaAudios() {
        return this.state.payMedia.filter((item:MessagingPostMediaData) => { return item.type === "audio" }).length;
    }

    getMediumMediaPreviewUrl(item:MessagingPostMediaData) {
        return "https://placehold.co/450x600";
    }

    getSingleImagePreviewUrl(item:MessagingPostMediaData) {
        return "https://placehold.co/300";
    }

    fetchRealImageURLs() {
        let producerID:string = "";
        let authToken:string = "";

        if(this.context) {
            producerID = this.context.producerID;
            authToken = this.context.authToken;
        }

        document.querySelectorAll('img[src="https://placehold.co/450x600"]').forEach((item: any) => {
            let myURL:string = ApiHelper.getAPIUrl(this.context) + "/" + item.getAttribute("x-mediatype") + "/get/" + item.getAttribute("x-mediaid") + "?role=producer&producerOrUserID=" + encodeURIComponent(producerID) + "&authToken=" + encodeURIComponent(authToken) + "&dimension=" + this.state.previewTimelineImageDimension;

            fetch(myURL).then(async(response: any) => {
                let data = await response.json();

                if(typeof(data.image) !== "undefined") {
                    if(data.image) {
                        item.src = data.image.imageURL;
                        if(data.image.reviewStatus === 1) {
                            item.classList.add("reviewed");
                        } else if(data.image.reviewStatus === -1) {
                            item.classList.add("rejected");
                        } else {
                            item.classList.add("inreview");
                        }
                    }
                }

                if(typeof(data.video) !== "undefined") {
                    if(data.video) {
                        if(item.getAttribute("x-mediapreview") === "hard") {
                            item.src = data.video.previewImageURLHard;
                        } else {
                            item.src = data.video.previewImageURLSoft;
                        }
                        if(data.video.reviewStatus === 1) {
                            item.classList.add("reviewed");
                        } else if(data.video.reviewStatus === -1) {
                            item.classList.add("rejected");
                        } else {
                            item.classList.add("inreview");
                        }
                    }
                }
            }).catch(function() {
                item.src = "https://placehold.co/450x600?Text=Error";
            });
        });

        document.querySelectorAll('img[src="https://placehold.co/200"]').forEach((item: any) => {
            let myURL:string = ApiHelper.getAPIUrl(this.context) + "/" + item.getAttribute("x-mediatype") + "/get/" + item.getAttribute("x-mediaid") + "?role=producer&producerOrUserID=" + encodeURIComponent(producerID) + "&authToken=" + encodeURIComponent(authToken) + "&dimension=" + this.state.mediumPreviewDimension;

            fetch(myURL).then(async(response: any) => {
                let data = await response.json();

                if(typeof(data.image) !== "undefined") {
                    if(data.image) {
                        item.src = data.image.imageURL;
                        if(data.image.reviewStatus === 1) {
                            item.classList.add("reviewed");
                        } else if(data.image.reviewStatus === -1) {
                            item.classList.add("rejected");
                        } else {
                            item.classList.add("inreview");
                        }
                    }
                }

                if(typeof(data.video) !== "undefined") {
                    if(data.video) {
                        if(item.getAttribute("x-mediapreview") === "hard") {
                            item.src = data.video.previewImageURLHard;
                        } else {
                            item.src = data.video.previewImageURLSoft;
                        }
                        if(data.video.reviewStatus === 1) {
                            item.classList.add("reviewed");
                        } else if(data.video.reviewStatus === -1) {
                            item.classList.add("rejected");
                        } else {
                            item.classList.add("inreview");
                        }
                    }
                }
            }).catch(function() {
                item.src = "https://placehold.co/200?Text=Error";
            });
        });

        document.querySelectorAll('img[src="https://placehold.co/300"]').forEach((item: any) => {
            let myURL:string = ApiHelper.getAPIUrl(this.context) + "/" + item.getAttribute("x-mediatype") + "/get/" + item.getAttribute("x-mediaid") + "?role=producer&producerOrUserID=" + encodeURIComponent(producerID) + "&authToken=" + encodeURIComponent(authToken) + "&dimension=" + this.state.singleImagePreviewDimension;

            fetch(myURL).then(async(response: any) => {
                let data = await response.json();

                if(typeof(data.image) !== "undefined") {
                    if(data.image) {
                        item.src = data.image.imageURL;
                        if(data.image.reviewStatus === 1) {
                            item.classList.add("reviewed");
                        } else if(data.image.reviewStatus === -1) {
                            item.classList.add("rejected");
                        } else {
                            item.classList.add("inreview");
                        }
                    }
                }

                if(typeof(data.video) !== "undefined") {
                    if(data.video) {
                        if(item.getAttribute("x-mediapreview") === "hard") {
                            item.src = data.video.previewImageURLHard;
                        } else {
                            item.src = data.video.previewImageURLSoft;
                        }
                        if(data.video.reviewStatus === 1) {
                            item.classList.add("reviewed");
                        } else if(data.video.reviewStatus === -1) {
                            item.classList.add("rejected");
                        } else {
                            item.classList.add("inreview");
                        }
                    }
                }
            }).catch(function() {
                item.src = "https://placehold.co/300?Text=Error";
            });
        });
    }

    getPayMediaDescriptionText() {
        if(this.context?.language == "de") {
            return "Zu diesem Preis können User die kostenpflichtigen Inhalte sehen, die an diesen Post angehängt sind, dies können Bilder, Videos und ein kostenpflichtiger Text (PayText) sein. Der FreeText ist kostenlos lesbar, ebenso können kostenlose Bilder und Videos angehangen werden. Die Buttons mit dem Euro-Zeichen diesen dazu kostenpflichtige Bilder und Videos hinzuzufügen, die mit dem Geschenk sind für kostenlose Medieninhalte.";
        } else {
            return "At this price users can see the paid media contents that are attached to this post, which can be images, videos and a paid text (PayText). The FreeText is free to read, as well as free images and videos can be attached. The buttons with the Euro sign add paid images and videos, the ones with the gift are for free media contents.";
        }
    }

    pollParamsCallback(params:TimelinePostPollData) {
        this.setState({pollQuestion: params.question});
        this.setState({pollDays: params.days});
        this.setState({pollAnswers: params.answers});
        this.setState({pollModal: false});
    }

    quizParamsCallback(params:TimelinePostQuizData) {
        this.setState({quizQuestion: params.question});
        this.setState({quizDays: params.days});
        this.setState({quizAnswers: params.answers});
        this.setState({quizModal: false});
    }

    fundraiseParamsCallback(params:TimelinePostFundraiseData) {
        this.setState({fundraiseTitle: params.title});
        this.setState({fundraiseTarget: params.target});
        this.setState({fundraiseOptions: params.options});
        this.setState({fundraiseModal: false});
    }

    removePoll() {
        this.setState({pollQuestion: ""});
        this.setState({pollDays: 0});
        this.setState({pollAnswers: []});
        this.setState({pollModal: false});
    }

    removeQuiz() {
        this.setState({quizQuestion: ""});
        this.setState({quizDays: 0});
        this.setState({quizAnswers: []});
        this.setState({quizModal: false});
    }

    removeFundraise() {
        this.setState({fundraiseTitle: ""});
        this.setState({fundraiseTarget: 0});
        this.setState({fundraiseOptions: []});
        this.setState({fundraiseModal: false});
    }

    changePriceInputEuro(e:React.ChangeEvent<HTMLInputElement>) {
        if(e.target.value === "") {
            this.setState({priceInputEuro: 0});
            let newprice:number = 0;
            newprice += this.state.priceInputCents;
            this.setState({price: newprice},this.updateCalculatedPrices.bind(this));
        } else {
            if(!isNaN(parseInt(e.target.value))) {
                this.setState({priceInputEuro: parseInt(e.target.value)});
                let newprice:number = parseInt(e.target.value) * 100;
                newprice += this.state.priceInputCents;
                this.setState({price: newprice},this.updateCalculatedPrices.bind(this));
            }
        }
    }

    changePriceInputCents(e:React.ChangeEvent<HTMLInputElement>) {
        if(e.target.value === "") {
            this.setState({priceInputCents: 0});
            let newprice:number = this.state.priceInputEuro * 100;
            this.setState({price: newprice},this.updateCalculatedPrices.bind(this));
        } else {
            if(!isNaN(parseInt(e.target.value))) {
                this.setState({priceInputCents: parseInt(e.target.value)});
                let newprice:number = this.state.priceInputEuro * 100;
                newprice += parseInt(e.target.value);
                this.setState({price: newprice},this.updateCalculatedPrices.bind(this));
            }
        }        
    }

    changePriceOverrideEuro(tierId:number,e:React.ChangeEvent<HTMLInputElement>) {
        if(e.target.value === "") {
            let myValue:number = 0;
            let myArray:PriceOverrideRecord[] = this.state.priceOverrides;
            let myPriceRecord:PriceOverrideRecord | undefined = myArray.find((record:PriceOverrideRecord) => record.tierId === tierId);

            if(myPriceRecord) {
                myPriceRecord.priceEuro = myValue;
                let newArray:PriceOverrideRecord[] = this.state.priceOverrides.filter((record:PriceOverrideRecord) => record.tierId !== tierId);
                newArray.push(myPriceRecord);
                this.setState({priceOverrides: newArray},this.updateCalculatedPrices.bind(this));
            } else {
                let newArray:PriceOverrideRecord[] = myArray;
                newArray.push({priceEuro: myValue, priceCents: 0, tierId: tierId});
                this.setState({priceOverrides: newArray},this.updateCalculatedPrices.bind(this));
            }
        } else {
            if(!isNaN(parseInt(e.target.value))) {
                let myValue:number = parseInt(e.target.value);
                let myArray:PriceOverrideRecord[] = this.state.priceOverrides;
                let myPriceRecord:PriceOverrideRecord | undefined = myArray.find((record:PriceOverrideRecord) => record.tierId === tierId);

                if(myPriceRecord) {
                    myPriceRecord.priceEuro = myValue;
                    let newArray:PriceOverrideRecord[] = this.state.priceOverrides.filter((record:PriceOverrideRecord) => record.tierId !== tierId);
                    newArray.push(myPriceRecord);
                    this.setState({priceOverrides: newArray},this.updateCalculatedPrices.bind(this));
                } else {
                    let newArray:PriceOverrideRecord[] = myArray;
                    newArray.push({priceEuro: myValue, priceCents: 0, tierId: tierId});
                    this.setState({priceOverrides: newArray},this.updateCalculatedPrices.bind(this));
                }
            }
        }
    }

    changePriceOverrideCents(tierId:number,e:React.ChangeEvent<HTMLInputElement>) {
        if(e.target.value === "") {
            let myValue:number = 0;
            let myArray:PriceOverrideRecord[] = this.state.priceOverrides;
            let myPriceRecord:PriceOverrideRecord | undefined = myArray.find((record:PriceOverrideRecord) => record.tierId === tierId);

            if(myPriceRecord) {
                myPriceRecord.priceCents = myValue;
                let newArray:PriceOverrideRecord[] = this.state.priceOverrides.filter((record:PriceOverrideRecord) => record.tierId !== tierId);
                newArray.push(myPriceRecord);
                this.setState({priceOverrides: newArray},this.updateCalculatedPrices.bind(this));
            } else {
                let newArray:PriceOverrideRecord[] = myArray;
                newArray.push({priceCents: myValue, priceEuro: 0, tierId: tierId});
                this.setState({priceOverrides: newArray},this.updateCalculatedPrices.bind(this));
            }
        } else {
            if(!isNaN(parseInt(e.target.value))) {
                let myValue:number = parseInt(e.target.value);
                let myArray:PriceOverrideRecord[] = this.state.priceOverrides;
                let myPriceRecord:PriceOverrideRecord | undefined = myArray.find((record:PriceOverrideRecord) => record.tierId === tierId);

                if(myPriceRecord) {
                    myPriceRecord.priceCents = myValue;
                    let newArray:PriceOverrideRecord[] = this.state.priceOverrides.filter((record:PriceOverrideRecord) => record.tierId !== tierId);
                    newArray.push(myPriceRecord);
                    this.setState({priceOverrides: newArray},this.updateCalculatedPrices.bind(this));
                } else {
                    let newArray:PriceOverrideRecord[] = myArray;
                    newArray.push({priceCents: myValue, priceEuro: 0, tierId: tierId});
                    this.setState({priceOverrides: newArray},this.updateCalculatedPrices.bind(this));
                }
            }
        }
    }

    changeTimelineSettings = async(newSetting:string) => {
        this.setState({ changingTimelineSettings: true });

        let jsonurl = ApiHelper.getAPIUrl(this.context) + "/timeline/settings";

        if (this.context) {
            if(!this.context.loggedin) {
                await ApiHelper.checkLoginStatus(this.context);
            }
            jsonurl += "?producerID=" + encodeURIComponent(this.context.producerID);
            jsonurl += "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
            jsonurl += "&newSetting=" + encodeURIComponent(newSetting);
            jsonurl += "&platform=" + encodeURIComponent(this.state.platform);

            let response: any = null;

            try {
                response = await fetch(jsonurl, { method: "put" });
                let data = await response.json();
                this.setState({ changingTimelineSettings: false });
    
                if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                    this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
                } else {
                    if (data.error !== "" && data.error !== null) {
                        this.setState({ errorMessage: "Error: " + data.error });
                        if(!ApiHelper.checkResponse(data,this.context) && this.context !== null) {
                            this.context.producerID = "";
                            alert("Please relogin");
                        }
                    }
                }
    
                if(data) {
                    this.setState({ hasGuppyTimeline: data.hasGuppyTimeline,hasFanTimeline: data.hasFanTimeline,mergeFanPostsOnGuppyTimeline: data.mergeFanPostsOnGuppyTimeline,timelineOnlyForSubs: data.needsAbo,timelineNeedsPaymentAuth: data.needsPaymentAuth }, this.fetchData.bind(this));
                }
            } catch (error: any) {
                this.setState({ errorMessage: "Error changing timeline settings: " + error.toString() });
                this.setState({ changingTimelineSettings: false });
            }
        }
    }

    enableGuppyTimeline = async() => {
        this.changeTimelineSettings("enableGuppy");
    }

    disableGuppyTimeline = async() => {
        this.changeTimelineSettings("disableGuppy");
    }

    enableGuppyTimelineMerge = async() => {
        this.changeTimelineSettings("enableGuppyTimelineMerge");
    }

    disableGuppyTimelineMerge = async() => {
        this.changeTimelineSettings("disableGuppyTimelineMerge");
    }

    makeTimelineVisibleForAll = async() => {
        this.changeTimelineSettings("all");
    }

    makeTimelineVisibleForPaymentAuthOnly = async() => {
        this.changeTimelineSettings("paymentAuthOnly");
    }

    makeTimelineVisibleForSubsOnly = async() => {
        this.changeTimelineSettings("subsOnly");
    }

    goToPromoView() {
        this.setState({showPromo: true},this.fetchData.bind(this));
    }

    goToSubscriberView() {
        this.setState({showPromo: false,showNonSub: false},this.fetchData.bind(this));
    }

    goToNonSubscriberView() {
        this.setState({showPromo: false,showNonSub: true},this.fetchData.bind(this));
    }

    toggleShowDeleted() {
        this.setState({showDeleted: !this.state.showDeleted},this.fetchData.bind(this));
    }

    handleSortChange(sort:string) {
        this.setState({sort: sort},this.fetchData.bind(this));
    }

    handlePlatformChange(platform:string) {
        this.setState({platform: platform,subscriptionTiers: []},this.fetchData.bind(this));
    }

    findPriceOverrideEuro(tier:CalculatedPriceRecord):string {
        let myPriceRecord:PriceOverrideRecord | undefined = this.state.priceOverrides.find((record:PriceOverrideRecord) => record.tierId === tier.tierId);

        if(myPriceRecord) {
            return myPriceRecord.priceEuro.toString();
        } else {
            return Math.floor(tier.price / 100).toString();
        }
    }

    findPriceOverrideCents(tier:CalculatedPriceRecord):string {
        let myPriceRecord:PriceOverrideRecord | undefined = this.state.priceOverrides.find((record:PriceOverrideRecord) => record.tierId === tier.tierId);

        if(myPriceRecord) {
            return myPriceRecord.priceCents.toString();
        } else {
            return Math.round(tier.price % 100).toString();
        }
    }

    formatPrice(price:number) {
        return Math.floor(price / 100) + "," + (price % 100).toString().padStart(2, "0");
    }

    resetWriteArea() {
        this.setState({ msgText: "" });
        this.setState({ msgTitle: "" });
        this.setState({ payText: "" });
        this.setState({ price: 0 },this.updateCalculatedPrices.bind(this));
        this.setState({ priceInputEuro: 0});
        this.setState({ priceInputCents: 0});
        this.setState({ tags: "" });
        this.setState({ publishTime: null });
        this.setState({ pinned: false });
        this.setState({ nonsub: false });
        this.setState({ promo: false });
        this.setState({ freeMedia: [] });
        this.setState({ payMedia: [] });
        this.setState({ priceOverrides: [] });
        this.setState({ quizQuestion: "" });
        this.setState({ quizDays: 0 });
        this.setState({ quizAnswers: [] });
        this.setState({ pollQuestion: "" });
        this.setState({ pollDays: 0 });
        this.setState({ pollAnswers: [] });
        this.setState({ fundraiseTitle: "" });
        this.setState({ fundraiseTarget: 0 });
        this.setState({ fundraiseOptions: [] });
    }

    handlePageChange(newPage:number):void {
        this.setState({curPage: newPage},this.fetchData.bind(this));
    }

    changePinStatus(post:TimelinePost,newval:boolean) {
        if (this.context === null) {
            return;
        }

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/timeline/pinned/" + encodeURIComponent(post.guid) + "?producerid=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);

        this.setState({ submitting: true });
        this.setState({errorMessage: ""});

        let fetchMethod:string = "PUT";

        fetch(jsonurl, { method: fetchMethod, headers: {'Accept': 'application/json'} }).then(async (response: any) => {
            let data = await response.json();

            if (data.success) {
                this.setState({ submitting: false });
                this.setState({errorMessage: ""});

                let myArray:TimelinePost[] = this.state.posts;

                let myPost:TimelinePost | undefined = myArray.find((post:TimelinePost) => post.guid === data.guid);

                if(myPost) {
                    myPost.pinned = newval;
                }

                this.setState({posts: myArray});
            } else {
                this.setState({ errorMessage: this.getText("Error") + data.error });
                this.setState({ submitting: false });
            }
        }).catch((error: any) => {
            this.setState({ errorMessage: "Error submitting timeline pin form: " + error.toString() });
            this.setState({ submitting: false });
        });
    }

    makePostPromo(post:TimelinePost,newval:boolean) {
        if (this.context === null) {
            return;
        }

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/timeline/promo/" + encodeURIComponent(post.guid) + "?producerid=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);

        this.setState({ submitting: true });
        this.setState({errorMessage: ""});

        let fetchMethod:string = "PUT";

        fetch(jsonurl, { method: fetchMethod, headers: {'Accept': 'application/json'} }).then(async (response: any) => {
            let data = await response.json();

            if (data.success) {
                this.setState({ submitting: false });
                this.setState({errorMessage: ""});

                let myArray:TimelinePost[] = this.state.posts;

                let myPost:TimelinePost | undefined = myArray.find((post:TimelinePost) => post.guid === data.guid);

                if(myPost) {
                    myPost.promo = newval;
                }

                this.setState({posts: myArray});
            } else {
                this.setState({ errorMessage: this.getText("Error") + data.error });
                this.setState({ submitting: false });
            }
        }).catch((error: any) => {
            this.setState({ errorMessage: "Error submitting timeline promo form: " + error.toString() });
            this.setState({ submitting: false });
        });
    }

    makePostNonSub(post:TimelinePost,newval:boolean) {
        if (this.context === null) {
            return;
        }

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/timeline/nonsub/" + encodeURIComponent(post.guid) + "?producerid=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);

        jsonurl += "&guid=" + encodeURIComponent(post.guid);

        this.setState({ submitting: true });
        this.setState({errorMessage: ""});

        let fetchMethod:string = "PUT";

        fetch(jsonurl, { method: fetchMethod, headers: {'Accept': 'application/json'} }).then(async (response: any) => {
            let data = await response.json();

            if (data.success) {
                this.setState({ submitting: false });
                this.setState({errorMessage: ""});

                let myArray:TimelinePost[] = this.state.posts;

                let myPost:TimelinePost | undefined = myArray.find((post:TimelinePost) => post.guid === data.guid);

                if(myPost) {
                    myPost.nonsub = newval;
                }

                this.setState({posts: myArray});
            } else {
                this.setState({ errorMessage: this.getText("Error") + data.error });
                this.setState({ submitting: false });
            }
        }).catch((error: any) => {
            this.setState({ errorMessage: "Error submitting timeline nonsub form: " + error.toString() });
            this.setState({ submitting: false });
        });
    }

    editPost(post:TimelinePost) {
        this.setState({ submitting: false });
        this.setState({ editGUID: post.guid});
        
        if(post.editTitle !== null) {
            if(post.editTitle !== "") {
                this.setState({ msgTitle: post.editTitle});
            } else {
                this.setState({ msgTitle: post.msgTitle});
            }
        } else {
            this.setState({ msgTitle: post.msgTitle});
        }

        if(post.editText !== null) {
            if(post.editText !== "") {
                this.setState({ msgText: post.editText});
            } else {
                this.setState({ msgText: post.msgText});
            }
        } else {
            this.setState({ msgText: post.msgText});
        }

        if(post.editPayText !== null) {
            if(post.editPayText !== "") {
                this.setState({ payText: post.editPayText});
            } else {
                if(post.payText !== null) {
                    if(post.payText !== "") {
                        this.setState({ payText: post.payText});
                    } else {
                        this.setState({ payText: ""});
                    }
                } else {
                    this.setState({ payText: ""});
                }
            }
        } else {
            if(post.payText !== null) {
                if(post.payText !== "") {
                    this.setState({ payText: post.payText});
                } else {
                    this.setState({ payText: ""});
                }
            } else {
                this.setState({ payText: ""});
            }
        }

        let myTakenPrice:number = 0;

        if(post.editPrice !== null) {
            if(post.editPrice > 0) {
                this.setState({ price: post.editPrice},this.updateCalculatedPrices.bind(this));
                myTakenPrice = post.editPrice;
            } else {
                this.setState({ price: post.price},this.updateCalculatedPrices.bind(this));
                myTakenPrice = post.price;
            }
        } else {
            this.setState({ price: post.price},this.updateCalculatedPrices.bind(this));
            myTakenPrice = post.price;
        }

        this.setState({ priceInputEuro: Math.floor(myTakenPrice / 100) });
        this.setState({ priceInputCents: Math.floor(myTakenPrice % 100)});

        if(post.editPublishTime !== null) {
            this.setState({ publishTime: post.editPublishTime});
        } else {
            this.setState({ publishTime: post.publishTime});
        }
        
        this.setState({ tags: post.tags });
        this.setState({ pinned: post.pinned });
        this.setState({ nonsub: post.nonsub });
        this.setState({ promo: post.promo });
        if(post.editFreeMedia !== null) {
            this.setState({ freeMedia: post.editFreeMedia });
        } else {
            if(post.freeMedia === null) {
                this.setState({ freeMedia: [] });
            } else {
                this.setState({ freeMedia: post.freeMedia });
            }
        }
        if(post.editPayMedia !== null) {
            this.setState({ payMedia: post.editPayMedia });
        } else {
            if(post.payMedia === null) {
                this.setState({ payMedia: [] });
            } else {
                this.setState({ payMedia: post.payMedia });
            }
        }
    
        let newPriceOverrides:PriceOverrideRecord[] = [];

        if(post.editPriceOverrides !== null) {
            let currec:PriceOverrideMessagingPostRecord;

            for(let i = 0;i < post.editPriceOverrides.length;i++) {
                currec = post.editPriceOverrides[i];

                let newrec:PriceOverrideRecord = {
                    tierId: currec.tierId,
                    priceEuro: Math.floor(currec.price / 100),
                    priceCents: currec.price % 100
                };
                
                newPriceOverrides.push(newrec);
            }
        } else if(post.priceOverrides !== null) {
            let currec:PriceOverrideMessagingPostRecord;

            for(let i = 0;i < post.priceOverrides.length;i++) {
                currec = post.priceOverrides[i];

                let newrec:PriceOverrideRecord = {
                    tierId: currec.tierId,
                    priceEuro: Math.floor(currec.price / 100),
                    priceCents: currec.price % 100
                };
    
                newPriceOverrides.push(newrec);
            }
        }

        this.setState({ priceOverrides: newPriceOverrides });

        if(post.editQuiz !== null) {
            this.setState({ quizQuestion: post.editQuiz.question });
            this.setState({ quizDays: post.editQuiz.days });
            this.setState({ quizAnswers: post.editQuiz.answers });
        } else {
            if(post.quiz !== null) {
                this.setState({ quizQuestion: post.quiz.question });
                this.setState({ quizDays: post.quiz.days });
                this.setState({ quizAnswers: post.quiz.answers });
            }
        }
        if(post.editPoll !== null) {
            this.setState({ pollQuestion: post.editPoll.question });
            this.setState({ pollDays: post.editPoll.days });
            this.setState({ pollAnswers: post.editPoll.answers });
        } else {
            if(post.poll !== null) {
                this.setState({ pollQuestion: post.poll.question });
                this.setState({ pollDays: post.poll.days });
                this.setState({ pollAnswers: post.poll.answers });
            }
        }
        if(post.editFundraise !== null) {
            this.setState({ surveyQuestion: post.editFundraise.title });
            this.setState({ surveyDays: post.editFundraise.target });
            this.setState({ surveyAnswers: post.editFundraise.options });
        } else {
            if(post.fundraise !== null) {
                this.setState({ fundraiseTitle: post.fundraise.title });
                this.setState({ fundraiseTarget: post.fundraise.target });
                this.setState({ fundraiseOptions: post.fundraise.options });
            }
        }

        var body = $("html, body");
        body.stop().animate({scrollTop:0}, 500, 'swing', function() { 
            // do nothing
        });
    }

    changePublishDate(newDate:Date) {
        this.setState({publishTime: newDate});
    }

    clickTier(id: number) {
        // do nothing
    }

    describePierKuendigung(tier:SubscriptionTierData) {
        let result:string = this.getText("MinRuntime") + ":";

        if(tier.initialRuntime * tier.billingPeriodNum === 1) {
            result += " 1 " + this.getTimeIntervalText(tier.initialRuntime * tier.billingPeriodNum,tier.billingPeriodUnits)
        }

        if(tier.initialRuntime > 1) {
            if(tier.billingPeriodUnits === "months" && tier.billingPeriodNum === 1) {
                result += ". " + this.getText("MonthlyCancellableAfterMinRuntime");
            } else if(tier.billingPeriodUnits === "weeks" && tier.billingPeriodNum === 1) {
                result += ". " + this.getText("MonthlyCancellableAfterMinRuntime");
            } else if(tier.billingPeriodUnits === "days" && tier.billingPeriodNum === 1) {
                result += ". " + this.getText("DailyCancellableAfterMinRuntime");
            } else {
                result += ". " + this.getText("CancellableAfterMinRuntime");
            }
        } else {
            result += ". " + this.getText("NoMinRuntime");
        }

        return result;
    }

    describePierPrice(tier:SubscriptionTierData) {
        let result:string = "";
        
        let myprice:number = tier.price;

        if(tier.initialRuntime > 0) {
            myprice = tier.initialPrice;
        }

        if(myprice > 0) {
            result += this.getText("Price") + ":";
            result += " " + (myprice / 100).toLocaleString(undefined,{minimumFractionDigits: 2}) + " € ";
            if(tier.billingPeriodNum === 1) {
                result += this.getText("each");
            } else {
                result += this.getText("every");
            }
            result += " " + this.getTimeIntervalText(tier.billingPeriodNum,tier.billingPeriodUnits);
        }

        return result;
    }

    describePierPriceInitialOffer1(tier:SubscriptionTierData) {
        let result:string = "";

        if(tier.price !== tier.initialPrice) {
            result += this.getText("Starte jetzt");
        }

        return result;
    }

    describePierPriceInitialOffer2(tier:SubscriptionTierData) {
        let result:string = "";

        if(tier.price !== tier.initialPrice) {
            if(tier.initialRuntime * tier.billingPeriodNum === 1) {
                result += "1 ";
            }
            result += this.getTimeIntervalText(tier.initialRuntime * tier.billingPeriodNum,tier.billingPeriodUnits);
        }

        return result;
    }

    describePierPriceInitialOffer3(tier:SubscriptionTierData) {
        let result:string = "";

        if(tier.price !== tier.initialPrice) {
            result += this.getText("lang durch");
            result += this.getText("for only");
        }

        return result;
    }

    describePierPriceInitialOffer4(tier:SubscriptionTierData) {
        let result:string = "";

        if(tier.price !== tier.initialPrice) {
            result += this.describePierInitialPrice(tier);
        }

        return result;
    }

    describePierPriceInitialOfferPast(tier:SubscriptionTierData) {
        let result:string = "";

        if(tier.price !== tier.initialPrice) {
            result += this.getText("Anschließend:") + this.describePierDefaultPrice(tier);
        }

        return result;
    }

    describePierInitialPrice(tier:SubscriptionTierData) {
        let result:string = "";
        
        let myprice:number = tier.initialPrice;

        result += " " + (myprice / 100).toLocaleString(undefined,{minimumFractionDigits: 2}) + " € ";
        if(tier.billingPeriodNum === 1) {
            result += this.getText("each");
        } else {
            result += this.getText("every");
        }
        result += " " + this.getTimeIntervalText(tier.billingPeriodNum,tier.billingPeriodUnits);

        return result;
    }

    describePierDefaultPrice(tier:SubscriptionTierData) {
        let result:string = "";
        
        let myprice:number = tier.price;

        result += " " + (myprice / 100).toLocaleString(undefined,{minimumFractionDigits: 2}) + " € ";
        if(tier.billingPeriodNum === 1) {
            result += this.getText("each");
        } else {
            result += this.getText("every");
        }
        result += " " + this.getTimeIntervalText(tier.billingPeriodNum,tier.billingPeriodUnits);

        return result;
    }

    getTimeIntervalText(num:number,units:string) {
        let result:string = "";

        if (this.state.language === "de") {
            if(num === 1) {
                if(units === "months") {
                    return "Monat";
                }
                if(units === "weeks") {
                    return "Woche";
                }
                if(units === "days") {
                    return "Tag";
                }
            } else {
                if(units === "months") {
                    return num + " Monate";
                }
                if(units === "weeks") {
                    return num + " Wochen";
                }
                if(units === "days") {
                    return num + " Tage";
                }
            }
        } else {
            if(num === 1) {
                if(units === "months") {
                    return "month";
                }
                if(units === "weeks") {
                    return "week";
                }
                if(units === "days") {
                    return "day";
                }
            } else {
                if(units === "months") {
                    return num + " Months";
                }
                if(units === "weeks") {
                    return num + " Weeks";
                }
                if(units === "days") {
                    return num + " Days";
                }
            }
        }
    }

    singleVideoEditModalClose() {
        this.setState({ singleVideoEditStreamname: "" });
    }

    singleVideoEditCloseCallback(lastVideo:VideoData|null) {
        this.setState({ singleVideoEditStreamname: "" });
    }

    getGuid() {
        let myguid:string = uuidv4();

        return myguid;
    }

    render() {
        return (
            <div id='TimelineDIV'>
                <ImageModal singleSelect={false} open={this.state.imageModal} mediaAddMode={this.state.mediaAddMode} changeMediaAddMode={this.imageModalChangeMediaAddMode.bind(this)} closeCallback={this.imageModalClose.bind(this)} itemClickCallback={this.imageModalSelect.bind(this)} itemSelectMulti={this.imageModalSelectMulti.bind(this)} />
                <VideoModal open={this.state.videoModal} mediaAddMode={this.state.mediaAddMode} changeMediaAddMode={this.videoModalChangeMediaAddMode.bind(this)} closeCallback={this.videoModalClose.bind(this)} itemClickCallback={this.videoModalSelect.bind(this)} itemSelectMulti={this.videoModalSelectMulti.bind(this)} />
                <PollModal open={this.state.pollModal} closeCallback={this.pollModalClose.bind(this)} setParamsCallback={this.pollParamsCallback.bind(this)} />
                <QuizModal open={this.state.quizModal} closeCallback={this.quizModalClose.bind(this)} setParamsCallback={this.quizParamsCallback.bind(this)} />
                <FundraiseModal open={this.state.fundraiseModal} closeCallback={this.fundraiseModalClose.bind(this)} setParamsCallback={this.fundraiseParamsCallback.bind(this)} />
                <Modal isOpen={this.state.singleVideoEditStreamname !== ""} onClosed={this.singleVideoEditModalClose.bind(this)} size='xl'>
                    <ModalHeader close={<button className="close" onClick={this.singleVideoEditModalClose.bind(this)}>×</button>}>
                        { this.getText("VideoDetails") }
                    </ModalHeader>
                    <ModalBody>
                        <VideoSingleEdit streamName={this.state.singleVideoEditStreamname} closeCallback={this.singleVideoEditCloseCallback.bind(this)} />
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={this.singleVideoEditModalClose.bind(this)} variant="secondary" type="button">{this.getText("Close")}</Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.calendarModal} onClosed={this.calendarModalClose.bind(this)}>
                    <ModalHeader close={<button className="close" onClick={this.calendarModalClose.bind(this)}>×</button>}>
                        { this.getText("Veröffentlichungsdatum festlegen") }
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label for="publishTime">{this.getText("Veröffentlichungsdatum festlegen")}</Label>
                            <DatePicker dateFormat="dd.MM.yyyy HH:mm" timeFormat="HH:mm" timeIntervals={15} timeCaption="time" selected={this.state.publishTime ? new Date(this.state.publishTime) : null } showTimeSelect showMonthDropdown showYearDropdown dropdownMode="select" locale={this.state.language === 'de' ? de : en} onChange={this.changePublishDate.bind(this)} />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={this.calendarModalClose.bind(this)} variant="primary" type="submit">{this.getText("Submit")}</Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.priceModal} onClosed={this.priceModalClose.bind(this)}>
                    <ModalHeader close={<button className="close" onClick={this.priceModalClose.bind(this)}>×</button>}>
                        { this.getText("Preis festlegen") }
                    </ModalHeader>
                    <ModalBody>
                        <Form onSubmit={this.handlePriceSubmit.bind(this)}>
                            <FormGroup className="mb-2" controlId="formBasicPassword">
                                <Label>{this.getText("DefaultPrice")}</Label>
                                <InputGroup>
                                    <Input value={this.state.priceInputEuro} onChange={this.changePriceInputEuro.bind(this)} type="tel" style={{textAlign: "right"}} />
                                    <InputGroupText>
                                        <FontAwesomeIcon icon={solid('euro-sign')} size="sm" />
                                    </InputGroupText>
                                    <InputGroupText>
                                        ,
                                    </InputGroupText>
                                    <Input value={this.state.priceInputCents} maxLength={2} onChange={this.changePriceInputCents.bind(this)} type="tel" style={{textAlign: "right"}} />
                                    <InputGroupText>
                                        <FontAwesomeIcon icon={solid('cent-sign')} size="sm" />
                                    </InputGroupText>
                                </InputGroup>
                            </FormGroup>
                            <Button variant="primary" type="submit">{this.getText("Submit")}</Button>
                        </Form>
                        { this.state.fetchingSubscriptionTiers ? <p className="pt-4"><FontAwesomeIcon icon={solid('cog')} spin size="2x" /></p> : null }
                        { this.state.fetchingSubscriptionTiersError !== "" ? <Alert color='warning'>{this.state.fetchingSubscriptionTiersError}</Alert> : null }
                        { this.state.platform === "fans" && this.state.calculatedPrices ? 
                        <div className="mt-3">
                            <h6>
                                {this.getText("CalculatedPrices")}
                                <Button onClick={this.updateCalculatedPrices.bind(this)} size="sm" className="ml-2" variant="outline-primary" type="button">{this.getText("Calculate")}</Button>
                            </h6>
                            <ListGroup>
                                { this.state.calculatedPrices.map((price:CalculatedPriceRecord, index:number) => {
                                    return <ListGroupItem key={index}>
                                        <small>{this.getText("User mit")} {price.tierName} {(price.price / 100).toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2})}€ [{price.tierId}]</small>
                                        {price.tierId !== 0 ?
                                        <InputGroup size="sm">
                                            <Input value={this.findPriceOverrideEuro(price)} onChange={this.changePriceOverrideEuro.bind(this,price.tierId)} type="tel" style={{textAlign: "right"}} />
                                            <InputGroupText>
                                                <FontAwesomeIcon icon={solid('euro-sign')} size="sm" />
                                            </InputGroupText>
                                            <InputGroupText>,</InputGroupText>
                                            <Input value={this.findPriceOverrideCents(price)} maxLength={2} onChange={this.changePriceOverrideCents.bind(this,price.tierId)} type="tel" style={{textAlign: "right"}} />
                                            <InputGroupText>
                                                <FontAwesomeIcon icon={solid('cent-sign')} size="sm" />
                                            </InputGroupText>
                                        </InputGroup> : null }
                                    </ListGroupItem>
                                }) }
                            </ListGroup>
                        </div> : null }
                        { this.state.platform === "fans" ?
                        <Link to="/subscriptions"><small>{this.getText("EditSubscriptionTiers")}</small></Link>
                        : null }
                        <p className="mt-2 mb-0" style={{textAlign: "justify",lineHeight: "16px",color: "#888"}}>
                            <small>{this.getPayMediaDescriptionText()}</small>
                        </p>
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.clickedMediaData !== null} size="md" onClosed={this.clickedMediaDataModalClose.bind(this)}>
                    <ModalHeader close={<button className="close" onClick={this.clickedMediaDataModalClose.bind(this)}>×</button>}>
                        { this.state.clickedMediaData ? this.getText(this.state.mediaAddMode + this.state.clickedMediaData.type) : null }
                    </ModalHeader>
                    <ModalBody>
                        { this.state.clickedMediaData ? <img x-mediatype={this.state.clickedMediaData.type} x-mediaid={this.state.clickedMediaData.mediaID} src={this.getSingleImagePreviewUrl(this.state.clickedMediaData)} className="w-100" /> : null }
                    </ModalBody>
                    <ModalFooter>
                        {this.state.clickedMediaData && this.state.clickedMediaData.type === "video" ? <Button onClick={this.clickSingleVideoEdit.bind(this)}>{this.getText("VideoDetails")}</Button> : null}
                        {this.state.clickedMediaDeletable && this.state.mediaAddMode === "free" ?
                        <Button onClick={this.changeClickedMediaDataToPay.bind(this)}>{this.state.clickedMediaData !== null ? this.getText("ChangeToPay" + this.state.clickedMediaData.type) : this.getText("ChangeToPay")}</Button>
                        : null }
                        {this.state.clickedMediaDeletable && this.state.mediaAddMode === "pay" ?
                        <Button onClick={this.changeClickedMediaDataToFree.bind(this)}>{this.state.clickedMediaData !== null ? this.getText("ChangeToFree" + this.state.clickedMediaData.type) : this.getText("ChangeToFree")}</Button>
                        : null }
                        {this.state.clickedMediaDeletable ?
                        <Button onClick={this.deleteClickedMediaData.bind(this)}>{this.getText("DeleteContentFromPost")}</Button>
                        : null }
                        <Button onClick={this.closeClickedMediaData.bind(this)}>{this.getText("Close")}</Button>
                    </ModalFooter>
                </Modal>
                <h4 className="pageHeadline">{this.getText("Timeline")}</h4>
                <div className="mb-4"><small>{this.getText("TimelineSubtitle")}</small></div>

                {this.state.errorMessage !== "" ? <Alert color="danger">{this.state.errorMessage}</Alert> : null}
                {this.state.infoMessage !== "" ? <Alert color="info">{this.state.infoMessage}</Alert> : null}

                { this.state.canChangePlatform ?
                <Dropdown isOpen={this.state.platformDropdownOpen} toggle={this.handlePlatformDropdownToggle.bind(this)} direction="down">
                    <DropdownToggle size='sm' caret>{this.getText("Platform")} <Badge color='secondary'>{this.state.platform}</Badge></DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem onClick={this.handlePlatformChange.bind(this,"guppy")}>{this.getText("SelectPlatformGuppy")}</DropdownItem>
                        <DropdownItem onClick={this.handlePlatformChange.bind(this,"fans")}>{this.getText("SelectPlatformFans")}</DropdownItem>
                        <DropdownItem onClick={this.handlePlatformChange.bind(this,"merge")}>{this.getText("SelectPlatformMerge")}</DropdownItem>
                    </DropdownMenu>
                </Dropdown> : null }

                <hr></hr>

                <Form onSubmit={this.handleSubmit.bind(this)}>
                    <FormGroup className="mt-2">
                        <Label>
                            { this.state.editGUID !== "" ? this.getText("Edit Post") : this.getText("New Post")}
                            { this.state.isWindows && this.state.language === "de" ? <span className="helptextTimeline">Zeilenumbrüche kannst Du mit <kbd>Shift+Return</kbd> einfügen, für Smileys drücke <kbd>gleichzeitig Windows- und Punkt-Taste</kbd></span> : null }
                            { this.state.isWindows && this.state.language === "en" ? <span className="helptextTimeline">Line-Breaks with <kbd>Shift+Return</kbd>, press <kbd>Windows+.</kbd> for Smileys</span> : null }
                        </Label>
                        <Input type="text" onChange={this.handleMessageTitleChange.bind(this)} id="txtMessageTitle" value={this.state.msgTitle} placeholder={this.getText("MsgTitle")} />
                    </FormGroup>

                    <div className='TextTabDIV d-flex'>
                        <div id='btnFreeText' onClick={this.switchFreeTextMode.bind(this)} className={ this.state.payTextMode ? "TextTabButton" : "TextTabButton TextTabButtonActive" }><div className='TextTabIcon'><FontAwesomeIcon icon={solid('gift')} size="sm" /></div> <span>FreeText</span>{ !this.state.payTextMode ? <div className='TextTabIconEnd'><FontAwesomeIcon icon={solid('arrow-down')} size="sm" /></div> : null }</div>
                        <div id='btnPayText' onClick={this.switchPayTextMode.bind(this)} className={ this.state.payTextMode ? "TextTabButton TextTabButtonActive" : "TextTabButton" }><div className='TextTabIcon'><FontAwesomeIcon icon={solid('euro')} size="sm" /></div> <span>PayText</span>{ this.state.payTextMode ?  <div className='TextTabIconEnd'><FontAwesomeIcon icon={solid('arrow-down')} size="sm" /></div> : null }</div>
                    </div>
                    { this.state.payTextMode ?
                    <FormGroup>
                        <Input type="textarea" rows={5} onChange={this.handlePayMessageChange.bind(this)} id="txtPayMessage" value={this.state.payText} />
                    </FormGroup> :
                    <FormGroup>
                        <Input type="textarea" rows={5} onChange={this.handleMessageChange.bind(this)} id="txtMessage" value={this.state.msgText} />
                    </FormGroup> }
                </Form>
                <div className="d-flex w-100 align-items-center justify-content-between flex-wrap mt-1">
                    <ButtonToolbar>
                        <ButtonGroup className="me-2">
                            <Button id="btnPostPrice" className="BelowPostButton" onClick={this.clickPriceButton.bind(this)}><span><FontAwesomeIcon icon={solid('euro')} size="sm" /></span><Badge color="secondary"><span className="d-none d-md-inline">{this.getText("Price")}: </span>{this.formatPrice(this.state.price)}</Badge></Button>
                            <Button id="btnPostPayImage" className="BelowPostButton" onClick={this.clickPayImageButton.bind(this)}><div className='BelowPostButtonPayIcon'><FontAwesomeIcon icon={solid('euro')} size="sm" /></div><FontAwesomeIcon icon={solid('image')} size="sm" /></Button>
                            <Button id="btnPostPayVideo" className="BelowPostButton" onClick={this.clickPayVideoButton.bind(this)}><div className='BelowPostButtonPayIcon'><FontAwesomeIcon icon={solid('euro')} size="sm" /></div><FontAwesomeIcon icon={solid('video')} size="sm" /></Button>
                            <Button style={{display: "none"}} id="btnPostPayAudio" className="BelowPostButton" onClick={this.clickPayAudioButton.bind(this)}><div className='BelowPostButtonPayIcon'><FontAwesomeIcon icon={solid('euro')} size="sm" /></div><FontAwesomeIcon icon={solid('microphone')} size="sm" /></Button>
                        </ButtonGroup>
                        <ButtonGroup className="me-2">
                            <Button id="btnPostFreeImage" className="BelowPostButton" onClick={this.clickImageButton.bind(this)}><div className='BelowPostButtonPayIcon'><FontAwesomeIcon icon={solid('gift')} size="sm" /></div><FontAwesomeIcon icon={solid('image')} size="sm" /></Button>
                            <Button id="btnPostFreeVideo" className="BelowPostButton" onClick={this.clickVideoButton.bind(this)}><div className='BelowPostButtonPayIcon'><FontAwesomeIcon icon={solid('gift')} size="sm" /></div><FontAwesomeIcon icon={solid('video')} size="sm" /></Button>
                            <Button style={{display: "none"}} id="btnPostFreeAudio" className="BelowPostButton" onClick={this.clickAudioButton.bind(this)}><div className='BelowPostButtonPayIcon'><FontAwesomeIcon icon={solid('gift')} size="sm" /></div><FontAwesomeIcon icon={solid('microphone')} size="sm" /></Button>
                        </ButtonGroup>
                        <ButtonGroup className="me-2">
                            <Button id="btnPostCalendar" className="BelowPostButton" onClick={this.clickCalendarButton.bind(this)}><FontAwesomeIcon icon={solid('calendar')} size="sm" /></Button>
                        </ButtonGroup>
                        <ButtonGroup className="me-2 d-none">
                            <Button id="btnPostPoll" className="BelowPostButton" onClick={this.clickPollButton.bind(this)}><FontAwesomeIcon icon={solid('poll-people')} size="sm" /></Button>
                            <Button id="btnPostQuiz" className="BelowPostButton" onClick={this.clickQuizButton.bind(this)}><FontAwesomeIcon icon={solid('block-question')} size="sm" /></Button>
                            <Button id="btnPostFundraise" className="BelowPostButton" onClick={this.clickFundraiseButton.bind(this)}><FontAwesomeIcon icon={solid('hand-holding-dollar')} size="sm" /></Button>
                        </ButtonGroup>
                    </ButtonToolbar>

                    <UncontrolledTooltip placement="left" target="btnPostPrice">{this.getText("PriceExplanation")}</UncontrolledTooltip>
                    <UncontrolledTooltip placement="bottom" target="btnPostPayImage">{this.getText("PayImageExplanation")}</UncontrolledTooltip>
                    <UncontrolledTooltip placement="bottom" target="btnPostPayVideo">{this.getText("PayVideoExplanation")}</UncontrolledTooltip>
                    <UncontrolledTooltip placement="bottom" target="btnPostPayAudio">{this.getText("PayAudioExplanation")}</UncontrolledTooltip>
                    <UncontrolledTooltip placement="bottom" target="btnPostFreeImage">{this.getText("FreeImageExplanation")}</UncontrolledTooltip>
                    <UncontrolledTooltip placement="bottom" target="btnPostFreeVideo">{this.getText("FreeVideoExplanation")}</UncontrolledTooltip>
                    <UncontrolledTooltip placement="bottom" target="btnPostFreeAudio">{this.getText("FreeAudioExplanation")}</UncontrolledTooltip>
                    <UncontrolledTooltip placement="right" target="btnPostCalendar">{this.getText("CalendarExplanation")}</UncontrolledTooltip>
                    <UncontrolledTooltip placement="bottom" target="btnPostPoll">{this.getText("PollExplanation")}</UncontrolledTooltip>
                    <UncontrolledTooltip placement="bottom" target="btnPostQuiz">{this.getText("QuizExplanation")}</UncontrolledTooltip>
                    <UncontrolledTooltip placement="bottom" target="btnPostFundraise">{this.getText("FundraiseExplanation")}</UncontrolledTooltip>
                    <UncontrolledTooltip placement="top" target="btnFreeText">{this.getText("FreeTextExplanation")}</UncontrolledTooltip>
                    <UncontrolledTooltip placement="top" target="btnPayText">{this.getText("PayTextExplanation")}</UncontrolledTooltip>

                    { this.state.editGUID === "" ? 
                    <Button color="primary" onClick={this.handleSubmit.bind(this)} disabled={this.state.submitting} type="submit">    
                        <span className="d-inline"><FontAwesomeIcon icon={solid('envelope')} size="sm" /> </span><span className="d-none d-md-inline">{this.getText("MakeNewPost")}</span>
                    </Button>
                    : <div>
                        <Button color="secondary" onClick={this.handleEditCancel.bind(this)} disabled={this.state.submitting} style={{marginRight: "5px"}}>
                            <span className="d-inline"><FontAwesomeIcon icon={solid('remove')} size="sm" /> </span><span className="d-none d-md-inline">{this.getText("Cancel")}</span>
                        </Button>
                        <Button color="primary" onClick={this.handleEditSubmit.bind(this)} disabled={this.state.submitting} type="submit">
                          <span className="d-inline"><FontAwesomeIcon icon={solid('floppy-disk')} size="sm" /> </span><span className="d-none d-md-inline">{this.getText("SaveEditedPost")}</span>
                        </Button>
                      </div>
                    }
                </div>

                { this.state.publishTime ? <p><small><FontAwesomeIcon icon={solid('calendar')} size="sm" /> {this.getText("ToBePublished") + " : " + DateTime.fromISO(new Date(this.state.publishTime).toISOString()).toLocaleString(DateTime.DATETIME_MED)}</small></p> : null }
                { this.state.pollAnswers.length > 0 ? <div className="mt-1"><small>{this.getText("Poll")} {this.getText("with")} {this.state.pollAnswers.length} {this.getText("answers")} <span style={{cursor: "pointer"}}><FontAwesomeIcon icon={solid('image')} size="sm" onClick={this.removePoll.bind(this)} /></span></small></div> : null }
                { this.state.quizAnswers.length > 0 ? <div className="mt-1"><small>{this.getText("Quiz")} {this.getText("with")} {this.state.pollAnswers.length} {this.getText("answers")} <span style={{cursor: "pointer"}}><FontAwesomeIcon icon={solid('image')} size="sm" onClick={this.removeQuiz.bind(this)} /></span></small></div> : null }
                { this.state.fundraiseOptions.length > 0 ? <div className="mt-1"><small>{this.getText("Fundraise")} {this.getText("with")} {this.state.pollAnswers.length} {this.getText("options")} <span style={{cursor: "pointer"}}><FontAwesomeIcon icon={solid('image')} onClick={this.removeFundraise.bind(this)} size="sm" /></span></small></div> : null }

                { this.state.freeMedia.length > 0 ? 
                <Card className='mt-2 mb-2'>
                    <CardHeader>{this.getText("Kostenlose Inhalte für Deine neue Nachricht")}:</CardHeader>
                    <CardBody>
                        <p><small>{this.getText("OrangeBorderedImages")}</small></p>
                        <div className="d-flex flex-wrap" style={{gap: "4px"}}>
                            {this.state.freeMedia.map((item,index) =>
                                <SmallMediaPreview key={"SmallMediaPreviewFreePost-WriteArea-" + index} guid={this.getGuid()} context="timeline" contextID="write" type={item.type} mediaID={item.mediaID} reviewStatus={item.reviewStatus} clicked={this.freeMediaClicked.bind(this,item,true)} />
                            )}
                        </div>
                    </CardBody>
                </Card>
                : null }
                { this.state.payMedia.length > 0 ? 
                <Card className='mt-2 mb-2'>
                    <CardHeader>
                        {this.getText("Kostenpflichtige Inhalte für Deine neue Nachricht")}
                    </CardHeader>
                    <CardBody>
                        <div className='mb-2'>
                            { this.state.calculatedPrices === null || this.state.platform !== "fans" ? 
                            <span className="timelinePostPriceLabel">{this.getText("Price")}: {(this.state.price / 100).toLocaleString(undefined,{minimumFractionDigits: 2})} &euro;</span>
                            : null }
                            { this.state.calculatedPrices !== null && this.state.platform === "fans" ? 
                            this.state.calculatedPrices.map(price =>
                                <span className="timelinePostPriceLabel">{price.tierName} {(price.price / 100).toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2})} &euro;</span>
                            )
                            : this.state.priceOverrides !== null && this.state.platform === "fans" ? this.state.priceOverrides.length > 0 ? 
                            this.state.priceOverrides.map(item =>
                                <span className="timelinePostPriceLabel">{this.getSubscriptionTierName(item.tierId)}: {(item.priceEuro)},{item.priceCents.toLocaleString(undefined,{minimumIntegerDigits: 2})} &euro;</span>
                            )
                            : null : null }
                        </div>
                        <p><small>{this.getText("OrangeBorderedImages")}</small></p>
                        <div className="d-flex flex-wrap" style={{gap: "4px"}}>
                            {this.state.payMedia.map((item,index) =>
                                <SmallMediaPreview key={"SmallMediaPreviewPayPost-WriteArea-" + index} guid={this.getGuid()} context="timeline" contextID="write" type={item.type} mediaID={item.mediaID} reviewStatus={item.reviewStatus} clicked={this.payMediaClicked.bind(this,item,true)} />
                            )}
                        </div>
                    </CardBody>
                </Card>
                : null }

                <hr />

                <Row className='mb-0' style={{height: "auto"}}>
                    <Col xs="12" md="6" className='paginationColumnLeft'>
                        {this.state.pagesArray ? this.state.pagesArray.length > 0 ?
                        <Pagination size='sm' className='mb-0'>
                            { this.state.pagesArray.map((page,index) =>
                                <PaginationItem style={{display: "list-item"}} active={page.active} className={page.page < this.state.maxPages ? "" : "d-none"}><PaginationLink onClick={this.handlePageChange.bind(this,page.page)} style={{color: "#000"}}>{page.page + 1}</PaginationLink></PaginationItem>
                            )}
                            { this.state.pagesArray.length > 10 ?
                            <Dropdown className='paginationExtraDropdown' isOpen={this.state.extraPagesDropdownOpen} toggle={this.handleExtraPagesDropdownToggle.bind(this)} direction="down">
                                <DropdownToggle size='sm' caret>{this.state.curPage >= this.state.maxPages ? this.state.curPage : this.getText("More")}</DropdownToggle>
                                <DropdownMenu>
                                    { this.state.pagesArray.map((page,index) =>
                                        <DropdownItem className={page.page >= this.state.maxPages ? page.active ? "bg-secondary text-white" : "" : "d-none"} onClick={this.handlePageChange.bind(this,page.page)}>{page.page + 1}</DropdownItem>
                                    )}
                                </DropdownMenu>
                            </Dropdown> : null }
                        </Pagination>
                        : null : null }
                    </Col>
                    <Col xs="12" md="6" className='paginationColumnRight'>
                        <Dropdown style={{display: "inline-block",marginRight: "10px"}} isOpen={this.state.sortDropdownOpen} toggle={this.handleSortDropdownToggle.bind(this)} direction="down">
                            <DropdownToggle size='sm' caret>{this.getText("Sort")}</DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={this.handleSortChange.bind(this,"publishtime")}>{this.getText("PublishTime")}</DropdownItem>
                                <DropdownItem onClick={this.handleSortChange.bind(this,"creationtime")}>{this.getText("CreationTime")}</DropdownItem>
                                <DropdownItem onClick={this.handleSortChange.bind(this,"likes")}>{this.getText("Likes")}</DropdownItem>
                                <DropdownItem onClick={this.handleSortChange.bind(this,"views")}>{this.getText("Views")}</DropdownItem>
                                <DropdownItem onClick={this.handleSortChange.bind(this,"ranking")}>{this.getText("Ranking")}</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                        { this.state.previewMode ? null :
                         !this.state.showDeleted ? 
                        <Button size="sm" style={{display: "inline-block",marginRight: "10px"}} onClick={this.toggleShowDeleted.bind(this)}><FontAwesomeIcon icon={solid('trash-check')} fixedWidth /> {this.getText("ShowDeleted")}</Button> : 
                        <Button size="sm" style={{display: "inline-block",marginRight: "10px"}} onClick={this.toggleShowDeleted.bind(this)}><FontAwesomeIcon icon={solid('trash-can-xmark')} fixedWidth /> {this.getText("HideDeleted")}</Button>
                        }
                        <Button size="sm" style={{display: "inline-block",marginRight: "10px"}} onClick={this.fetchData.bind(this)}><FontAwesomeIcon icon={solid('sync')} fixedWidth /></Button>
                        <Button size="sm" style={{display: "inline-block",marginRight: "10px"}} onClick={this.togglePreviewMode.bind(this)}><FontAwesomeIcon icon={solid('glasses')} fixedWidth /> {this.getText("Preview")}</Button>
                    </Col>
                </Row>

                <hr />

                { this.state.platform === "fans" ? 
                <Row className='mb-0' style={{height: "34px"}}>
                    <Col xs="12" md="6" className='paginationColumnLeft'>
                        { this.state.showPromo ?
                        <span>{this.getText("This is")} <FontAwesomeIcon icon={solid('user-secret')} fixedWidth /> {this.getText("PromoView")}</span> :
                        this.state.showNonSub ? 
                        <span>{this.getText("This is")} <FontAwesomeIcon icon={solid('user-lock')} fixedWidth /> {this.getText("NonAbonenntenView")}</span>
                            : 
                        <span>{this.getText("This is")} <FontAwesomeIcon icon={solid('user-crown')} fixedWidth /> {this.getText("AbonenntenView")}</span> 
                        }
                        <Dropdown style={{display: "inline-block",marginLeft: "5px",marginTop: "-5px"}} isOpen={this.state.viewDropdownOpen} toggle={this.handleShowViewDropdownToggle.bind(this)} direction="down">
                            <DropdownToggle size='sm' caret>{this.getText("ChangeView")}</DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={this.goToPromoView.bind(this)}><FontAwesomeIcon icon={solid('user-secret')} fixedWidth /> {this.getText("PromoView")}</DropdownItem>
                                <DropdownItem onClick={this.goToNonSubscriberView.bind(this)}><FontAwesomeIcon icon={solid('user-lock')} fixedWidth /> {this.getText("NonAbonenntenView")}</DropdownItem>
                                <DropdownItem onClick={this.goToSubscriberView.bind(this)}><FontAwesomeIcon icon={solid('user-crown')} fixedWidth /> {this.getText("AbonenntenView")}</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </Col>
                    <Col xs="12" md="6" className='paginationColumnRight'>
                        { this.state.timelineOnlyForSubs ? 
                        <span><FontAwesomeIcon icon={solid('user-lock')} fixedWidth /> {this.getText("TimelineOnlyForSubs")}</span>
                        : 
                        this.state.timelineNeedsPaymentAuth ?
                        <span><FontAwesomeIcon icon={solid('user-unlock')} fixedWidth /> {this.getText("TimelineNeedsPaymentAuth")}</span> 
                        :
                        <span><FontAwesomeIcon icon={solid('user-unlock')} fixedWidth /> {this.getText("TimelineForAllUsers")}</span> 
                        }
                        { this.state.changingTimelineSettings ?
                        <FontAwesomeIcon icon={solid('cog')} spin />
                        :
                        <Dropdown style={{display: "inline-block",marginLeft: "5px",marginRight: "10px",marginTop: "-5px"}} isOpen={this.state.timelineSettingsDropdownOpen} toggle={this.handleShowTimelineSettingsDropdownToggle.bind(this)} direction="left">
                            <DropdownToggle size='sm' caret>{this.getText("Change")}</DropdownToggle>
                            <DropdownMenu>
                                { this.state.platform === "fans" ?
                                <DropdownItem onClick={this.makeTimelineVisibleForAll.bind(this)}><FontAwesomeIcon icon={solid('user-secret')} fixedWidth /> {this.getText("MakeTimelineVisibleForAll")}</DropdownItem>
                                : null }
                                { this.state.platform === "fans" ?
                                <DropdownItem onClick={this.makeTimelineVisibleForPaymentAuthOnly.bind(this)}><FontAwesomeIcon icon={solid('user-lock')} fixedWidth /> {this.getText("MakeTimelineVisibleForPaymentAuthOnly")}</DropdownItem>
                                : null }
                                { this.state.platform === "fans" ?
                                <DropdownItem onClick={this.makeTimelineVisibleForSubsOnly.bind(this)}><FontAwesomeIcon icon={solid('user-crown')} fixedWidth /> {this.getText("MakeTimelineVisibleForSubsOnly")}</DropdownItem>
                                : null }
                                { this.state.platform === "fans" ? null : !this.state.hasGuppyTimeline ? 
                                    <DropdownItem onClick={this.enableGuppyTimeline.bind(this)}><FontAwesomeIcon icon={solid('user-unlock')} fixedWidth /> {this.getText("EnableGuppyTimeline")}</DropdownItem>
                                    :
                                    <DropdownItem onClick={this.disableGuppyTimeline.bind(this)}><FontAwesomeIcon icon={solid('user-lock')} fixedWidth /> {this.getText("DisableGuppyTimeline")}</DropdownItem>
                                }
                                { this.state.platform === "fans" ? null : !this.state.hasFanTimeline ?  null : !this.state.mergeFanPostsOnGuppyTimeline ? 
                                    <DropdownItem onClick={this.enableGuppyTimelineMerge.bind(this)}><FontAwesomeIcon icon={solid('merge')} fixedWidth /> {this.getText("EnableGuppyTimelineMerge")}</DropdownItem>
                                    :
                                    <DropdownItem onClick={this.disableGuppyTimelineMerge.bind(this)}><FontAwesomeIcon icon={solid('arrows')} fixedWidth /> {this.getText("DisableGuppyTimelineMerge")}</DropdownItem>
                                }
                            </DropdownMenu>
                        </Dropdown> }
                    </Col>
                </Row> : null }

                {this.state.fetching ? <p className="pt-4"><FontAwesomeIcon icon={solid('cog')} spin size="4x" /></p> :
                    this.state.previewMode ? 
                    <Row className="timelinePostPreview">
                        <Col xs="12" md={this.state.platform === "fans" ? 9 : 12}>
                            {this.state.posts.map((post,index) => 
                                !post.deleted && post.mergeStatus !== "merge" ?
                                <div className="testpostcard_maincontent">
                                    <Row className="testpostcard_maxwidth">
                                        <Col xs="12" md="8">Post von: <a className="testpostcard_pseudoname" href="#">{this.context?.pseudo}</a></Col>
                                        <Col xs="12" md="4" className="testpostcard_texttoright">{post.publishTime !== null && typeof(post.publishTime) !== "undefined" ? DateTime.fromISO(post.publishTime?.toString()).toLocaleString(DateTime.DATE_SHORT) : null}{post.editPublishTime !== null && typeof(post.editPublishTime) !== "undefined" ? <span><FontAwesomeIcon icon={solid("arrow-right")} size="sm"></FontAwesomeIcon> {DateTime.fromISO(post.editPublishTime?.toString()).toLocaleString(DateTime.DATE_SHORT)}</span> : null}</Col>
                                    </Row>
                                    <Row className="testpostcard_maxwidth">
                                        <Col xs="12" md="6"></Col>
                                        <Col xs="12" md="6" className="testpostcard_flexitsm">
                                            <div className="testpostcard_flexitsm">
                                                <img src="https://livestrip.fans/images/icons/post_icon.png" className="testpostcard_iconsm" alt="mediaitem" />
                                                { post.payMedia && post.freeMedia ? 
                                                    <p className="testpostcard_marker"> : {post.payMedia.filter((item) => { if(item.type === "image") {return true} else {return false} }).length + post.freeMedia.filter((item) => { if(item.type === "image") {return true} else {return false} }).length}</p>
                                                : null }
                                            </div>
                                            <div className="testpostcard_flexitsm">
                                                <img src="https://livestrip.fans/images/icons/video.png" className="testpostcard_iconsm" alt="mediaitem" />
                                                { post.payMedia && post.freeMedia ? 
                                                    <p className="testpostcard_marker"> : {post.payMedia.filter((item) => { if(item.type === "video") {return true} else {return false} }).length + post.freeMedia.filter((item) => { if(item.type === "video") {return true} else {return false} }).length}</p>
                                                : null }
                                            </div>
                                        </Col>
                                    </Row>
                                    <div>
                                        {post.msgTitle !== null && post.msgTitle !== "" ? <p className="testpostcard_heading">{post.msgTitle}</p> : null }
                                        {post.msgText !== null && post.msgText !== "" ? <p className="testpostcard_subheading"><span><br />{post.msgText}</span></p> : null }
                                    </div>
                                    { post.freeMedia ? post.freeMedia.map((item,index) =>
                                    <div id={"freeMediaPreviewItem_" + item.mediaID + "_" + index}>
                                        <div className="testpostcard_element">
                                            <div className="testpostcard_elementinner">
                                                <div>
                                                    <div className="scrollimage_wrapper">
                                                        <img alt={item.mediaID} x-mediatype={item.type} x-mediaid={item.mediaID} src={this.getMediumMediaPreviewUrl(item)} className="smallimageElement_myimageelement" style={{transition: "transform 1000ms ease-out 0s",transform: "scale(1) translateY(0%)",maxWidth: "100%"}} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> ) : null }
                                    <div className="testpostcard_flexit testpostcard_iconliste">
                                        <div><img src="https://livestrip.fans/images/icons/post_icon_mail.png" className="testpostcard_icon" /></div>
                                        <div><img src="https://livestrip.fans/images/icons/post_icon_trinkgeld.png" className="testpostcard_icon" /></div>
                                        <div className="testpostcard_likecontainer">
                                            <img src="https://livestrip.fans/images/icons/post_icon_herz.png" className="testpostcard_icongray" />
                                            <p className="testpostcard_likenumber">{post.likes}</p>
                                        </div>
                                        <div><img src="https://livestrip.fans/images/icons/post_icon_pin.png" className="testpostcard_icongray" /></div>
                                    </div>
                                    { post.payMedia ? post.price > 0 && (post.payMedia.length > 0 || (post.payText !== null && post.payText !== "")) ?
                                    <div className="testpostcard_topaywrapper">
                                        <div className="testpostcard_topaybg">
                                            <img className='testpostcard_fallbackimage' src="https://livestrip.fans/images/fallback_schloss_quer.jpg" alt="fallback" />
                                        </div>
                                        <div className="testpostcard_topaytext">
                                            <h5>{this.getText("Hol Dir diesen Post für nur")}  {(post.price / 100).toLocaleString(undefined,{minimumFractionDigits: 2})} EUR!</h5>
                                            <button type="button" className="redbutton_MyButton">{this.getText("Jetzt direkt freischalten")}</button>
                                            <h5 className="testpostcard_distancerdown">{this.getText("oder schließe jetzt ein Abonnement ab.")}</h5>
                                        </div>
                                    </div>
                                    : null : null }
                                </div>
                                : null
                            )}
                        </Col>
                        { this.state.platform === "fans" ? 
                        <Col xs="12" md="3" className='displaySub_column'>
                            <div className='displaySub_containertrans'>
                                <h5 className='displaySub_extrahead'>{this.getText("Abos und Vergünstigungen")}</h5>
                                {this.state.subscriptionTiers.map(tier =>
                                    <ul className='displaySub_wrapper'>
                                        <li className='displaySub_thelist'>
                                            <div onClick={this.clickTier.bind(this, tier.id)} className='displaySub_innerWrapper'>
                                                <h5 className='displaySub_header'>
                                                    <span className="fs-5 fw-semibold">{tier.tierName}</span>
                                                </h5>
                                                <p><small>{tier.tierDescription}</small></p>
                                                <p className='displaySub_initialPrice'>{this.describePierPriceInitialOffer1(tier)}<b>{this.describePierPriceInitialOffer2(tier)}</b>{this.describePierPriceInitialOffer3(tier)}<b>{this.describePierPriceInitialOffer4(tier)}</b></p>
                                                <p className='displaySub_initialPricePast'>{this.describePierPriceInitialOfferPast(tier)}</p>
                                                <p className='displaySub_priceline'>
                                                    {this.describePierPrice(tier)}
                                                </p>
                                                <p className='displaySub_kuendigung'>{this.describePierKuendigung(tier)}</p>
                                            </div>
                                            <div className='displaySub_catoAc'>
                                                Abonnieren
                                            </div>
                                        </li>
                                    </ul>
                                )}
                            </div>
                        </Col> : null }
                    </Row>
                    :
                    <ul className="list-group list-group-flush border-bottom scrollarea mt-0">
                        {this.state.posts.map((post,index) =>
                            !post.deleted || this.state.showDeleted ?
                            <div className='list-group-item'>
                                { post.mergeStatus === "merge" ? 
                                <Alert color='info' className='alert-mergepossible'>
                                    <span className='post-mergepossible'>{this.getText("MergePossible")}</span>
                                    <Button size="sm" className="btn btn-primary" onClick={this.mergePost.bind(this,post.guid)}><FontAwesomeIcon icon={solid('merge')} fixedWidth /> {this.getText("Merge")}</Button>
                                </Alert>
                                : null }
                                <Card className={post.deleted ? "timelinePost timelinePostDeleted lh-sm" : post.mergeStatus === "merge" ? "timelinePost timelinePostToMerge lh-sm" : "timelinePost lh-sm" }>
                                    <CardHeader className={"timelinePostHeader d-flex w-100 align-items-center justify-content-between flex-wrap mb-2 " + (post.reviewStatus === 1 ? "timelinePostHeaderApproved" : "timelinePostHeaderNotApproved")}>
                                        <div className='PostHeadlineLabels'>
                                            <small>
                                                {this.getText("Post")}
                                                {post.deleted ? <span className='post-deleted'>{this.getText("Deleted")}</span> : post.mergeStatus === "merge" ? <small><span className='post-mergepossible'>{this.getText("MergePossible")}</span></small> : post.reviewStatus === 1 ? <span className='post-approved'>{this.getText("Approved")}</span> : post.reviewStatus === -1 ? <span className='post-rejected'>{this.getText("Rejected")}</span> : <span className='post-inreview'>{this.getText("InReview")}</span>}
                                                {post.promo ? <span className='post-promo'>{this.getText("Promo")}</span> : this.state.timelineOnlyForSubs ? post.nonsub ? <span className='post-nonsub'>{this.getText("NonSub")}</span> : <span className='post-onlysub'>{this.getText("OnlySub")}</span> : null }
                                                {post.pinned ? <span className='post-pinned'>{this.getText("Pinned")}</span> : null }
                                                {post.reviewStatus === 1 && post.reviewComment !== null && post.reviewComment !== "" ? <small><span className='post-reviewcomment'>{post.reviewComment}</span></small> : null }
                                                {post.openEdit ? <small><span className='post-inreview'>{this.getText("EditRequested")}</span></small> : null}
                                            </small>
                                        </div>
                                        <small>{this.getText("Erstellt")}: {post.creationTime !== null && typeof(post.creationTime) !== "undefined" ? DateTime.fromISO(post.creationTime?.toString()).toLocaleString(DateTime.DATETIME_MED) : null}</small>
                                        <small>{this.getText("Veröffentlicht")}: {post.publishTime !== null && typeof(post.publishTime) !== "undefined" ? DateTime.fromISO(post.publishTime?.toString()).toLocaleString(DateTime.DATETIME_MED) : null}{post.editPublishTime !== null && typeof(post.editPublishTime) !== "undefined" ? <span><FontAwesomeIcon icon={solid("arrow-right")} size="sm"></FontAwesomeIcon> {DateTime.fromISO(post.editPublishTime?.toString()).toLocaleString(DateTime.DATETIME_MED)}</span> : null}</small>
                                        <UncontrolledDropdown direction="left">
                                            <DropdownToggle variant="secondary" id={"dropdownMenuButton-" + index} size="sm">
                                                <FontAwesomeIcon icon={solid('ellipsis-v')} size="sm" />
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                { post.pinned ?
                                                        <DropdownItem id={"btnUnpinPost-" + index} onClick={this.changePinStatus.bind(this,post,false)}><FontAwesomeIcon icon={solid('location-pin-slash')} fixedWidth /> {this.getText("UnpinThisPost")}</DropdownItem>
                                                    :
                                                        <DropdownItem id={"btnPinPost-" + index} onClick={this.changePinStatus.bind(this,post,true)}><FontAwesomeIcon icon={solid('thumbtack')} fixedWidth /> {this.getText("PinThisPost")}</DropdownItem> 
                                                }
                                                { post.promo ?
                                                        <DropdownItem id={"btnPromo-" + index} onClick={this.makePostPromo.bind(this,post,false)}><FontAwesomeIcon icon={solid('lock')} fixedWidth /> {this.getText("DisablePromo")}</DropdownItem>
                                                    :
                                                        <DropdownItem id={"btnPromo-" + index} onClick={this.makePostPromo.bind(this,post,true)}><FontAwesomeIcon icon={solid('unlock')} fixedWidth /> {this.getText("EnablePromo")}</DropdownItem> 
                                                }
                                                { this.state.timelineOnlyForSubs ? 
                                                    post.nonsub ? 
                                                        <DropdownItem id={"btnDisableNonSub-" + index} onClick={this.makePostNonSub.bind(this,post,false)}><FontAwesomeIcon icon={solid('lock')} fixedWidth /> {this.getText("DisableNonSub")}</DropdownItem>
                                                    :
                                                        <DropdownItem id={"btnEnableNonSub-" + index} onClick={this.makePostNonSub.bind(this,post,true)}><FontAwesomeIcon icon={solid('unlock')} fixedWidth /> {this.getText("EnableNonSub")}</DropdownItem> 
                                                    : null
                                                }
                                                { !post.deleted ?
                                                    <DropdownItem id={"btnEditPost-" + index} onClick={this.editPost.bind(this,post)}><FontAwesomeIcon icon={solid('pen-to-square')} fixedWidth /> {this.getText("EditPost")}</DropdownItem> 
                                                    : null 
                                                }
                                                { !post.deleted ?
                                                    <DropdownItem id={"btnDeletePost-" + index} onClick={this.deletePost.bind(this,post.guid)}><FontAwesomeIcon icon={solid('trash')} fixedWidth /> {this.getText("DeletePost")}</DropdownItem> 
                                                    : null 
                                                }
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </CardHeader>
                                    {!post.deleted ? <UncontrolledTooltip placement="top" target={"btnEditPost-" + index}>{this.getText("EditPostExplanation")}</UncontrolledTooltip> : null }
                                    {!post.deleted ? <UncontrolledTooltip placement="top" target={"btnDeletePost-" + index}>{this.getText("DeletePostExplanation")}</UncontrolledTooltip> : null }
                                    { this.state.timelineOnlyForSubs ? !post.deleted && post.nonsub ? <UncontrolledTooltip placement="top" target={"btnDisableNonSub-" + index}>{this.getText("DisableNonSubExplanation")}</UncontrolledTooltip> : null : null }
                                    { this.state.timelineOnlyForSubs ? !post.deleted && !post.nonsub ? <UncontrolledTooltip placement="top" target={"btnEnableNonSub-" + index}>{this.getText("EnableNonSubExplanation")}</UncontrolledTooltip> : null : null }
                                    <CardBody>
                                        {post.msgTitle !== null && post.msgTitle !== "" ? <div className="mb-2"><small className='badge bg-secondary'>{this.getText("Title")}</small> <b>{post.msgTitle}</b></div> : null }
                                        {post.editTitle !== null && post.editTitle !== "" ? <div className="col-10 mb-2 editText"><small className='badge bg-secondary'>{this.getText("Title")} - {this.getText("EditContent")}</small> <b>{post.editTitle}</b></div> : null}
                                        {post.msgText !== null && post.msgText !== "" ? <div className="mb-2"><small className='badge bg-secondary'>{this.getText("FreeText")}</small><br /><NewlineText text={post.msgText} /></div> : null}
                                        {post.editText !== null && post.editText !== "" ? <div className="col-10 mb-2 editText"><small className='badge bg-secondary'>{this.getText("FreeText")} - {this.getText("EditContent")}</small>: <NewlineText text={post.editText} /></div> : null}
                                        {post.payText !== null && post.payText !== "" ? <div className="mb-2"><small className='badge bg-secondary'>{this.getText("PayText")}</small><br /><NewlineText text={post.payText} /></div> : null}
                                        {post.editPayText !== null && post.editPayText !== "" ? <div className="col-10 mb-2 editText"><small className='badge bg-secondary'>{this.getText("PayText")} - {this.getText("EditContent")}</small>: <NewlineText text={post.editPayText} /></div> : null}
                                        { post.freeMedia !== null ? post.freeMedia.length > 0 ? 
                                        <Card className='mb-2'>
                                            <CardHeader>{this.getText("Kostenlose Inhalte")}:</CardHeader>
                                            <CardBody>
                                                <div className="d-flex flex-wrap" style={{gap: "4px"}}>
                                                    {post.freeMedia.map((item, index) =>
                                                        <SmallMediaPreview key={"SmallMediaPreviewFreePost-" + post.guid + "-" + index} context="timeline" contextID={post.guid} guid={this.getGuid()} type={item.type} mediaID={item.mediaID} reviewStatus={item.reviewStatus} clicked={this.freeMediaClicked.bind(this,item,false)} />
                                                    )}
                                                </div>
                                            </CardBody>
                                        </Card>
                                        : null : null }
                                        { this.compareMediaCollections(post.freeMedia,post.editFreeMedia) ? post.editFreeMedia !== null ? 
                                        <Card className='mb-2'>
                                            <CardHeader>{this.getText("Kostenlose Inhalte Änderung")}:</CardHeader>
                                            <CardBody>
                                                <div className="d-flex flex-wrap" style={{gap: "4px"}}>
                                                    {post.editFreeMedia.map((item, index) =>
                                                        <SmallMediaPreview key={"SmallMediaPreviewEditFreePost-" + post.guid + "-" + index} context="timeline" contextID={post.guid} guid={this.getGuid()} type={item.type} mediaID={item.mediaID} reviewStatus={item.reviewStatus} clicked={this.freeMediaClicked.bind(this,item,false)} />
                                                    )}
                                                </div>
                                            </CardBody>
                                        </Card>
                                        : null : null }
                                        { post.payMedia !== null ? post.payMedia.length > 0 ? 
                                        <Card className='mb-2'>
                                            <CardHeader>
                                                {this.getText("Kostenpflichtige Inhalte")}
                                            </CardHeader>
                                            <CardBody>
                                                <div className='mb-2'>
                                                    { typeof(post.calculatedPrices) === "undefined" || post.calculatedPrices === null ? 
                                                    <span className="timelinePostPriceLabel">{this.getText("Price")}: {(this.state.price / 100).toLocaleString(undefined,{minimumFractionDigits: 2})}&euro;</span>
                                                    : null }
                                                    { this.state.platform === "fans" ? 
                                                        typeof(post.calculatedPrices) !== "undefined" && post.calculatedPrices !== null ? post.calculatedPrices.map(price =>
                                                            <span className="timelinePostPriceLabel">{price.tierName} {(price.price / 100).toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2})}&euro;</span>
                                                        )
                                                        : post.priceOverrides !== null ? post.priceOverrides.map(item =>
                                                            <span className="timelinePostPriceLabel">{this.getSubscriptionTierName(item.tierId)}: {(item.price / 100).toLocaleString(undefined,{minimumFractionDigits: 2})}&euro;</span>
                                                        ) : null 
                                                    : null }
                                                </div>
                                                <div className="d-flex flex-wrap" style={{gap: "4px"}}>
                                                    {post.payMedia.map((item, index) =>
                                                        <div>
                                                            <SmallMediaPreview key={"SmallMediaPreviewPayPost-" + post.guid + "-" + index} context="timeline" contextID={post.guid} guid={this.getGuid()} type={item.type} mediaID={item.mediaID} reviewStatus={item.reviewStatus} clicked={this.payMediaClicked.bind(this,item,false)} />
                                                        </div>
                                                    )}
                                                </div>
                                            </CardBody>
                                        </Card>
                                        : null : null }
                                        { this.compareMediaCollections(post.payMedia,post.editPayMedia) ? post.editPayMedia !== null ? 
                                        <Card className='mb-2'>
                                            <CardHeader>{this.getText("Kostenpflichtige Inhalte Änderung")}</CardHeader>
                                            <CardBody>
                                                <div className='mb-2'>
                                                    <span className="timelinePostPriceLabel">{this.getText("Price")}: {((post.editPrice !== null ? post.editPrice : post.price) / 100).toLocaleString(undefined,{minimumFractionDigits: 2})} &euro;</span>
                                                    { this.state.platform === "fans" ? 
                                                        post.editPriceOverrides !== null ? post.editPriceOverrides.map(item =>
                                                            <span className="timelinePostPriceLabel">{this.getSubscriptionTierName(item.tierId)}: {(item.price / 100).toLocaleString(undefined,{minimumFractionDigits: 2})} &euro;</span>
                                                        ) : null 
                                                    : null }
                                                </div>
                                                <div className="d-flex flex-wrap" style={{gap: "4px"}}>
                                                    {post.editPayMedia.map((item, index) =>
                                                        <SmallMediaPreview key={"SmallMediaPreviewEditPayPost-" + post.guid + "-" + index} context="timeline" contextID={post.guid} guid={this.getGuid()} type={item.type} mediaID={item.mediaID} reviewStatus={item.reviewStatus} clicked={this.payMediaClicked.bind(this,item,false)} />
                                                    )}
                                                </div>
                                            </CardBody>
                                        </Card>
                                        : null : null }
                                        <div className='mt-2'>
                                            <small className='badge bg-secondary me-2'>{this.getText("Likes")} {post.likes}</small>
                                            <small className='badge bg-secondary me-2'>{this.getText("Views")} {post.views}</small>
                                            <small className='badge bg-secondary'>{this.getText("Ranking")} {post.ranking}</small>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div> : null
                        )}
                    </ul>
                }
            </div>
        );
    }
}

function NewlineText(props:any) {
    let text = props.text;
    if(typeof(props.text) === "undefined") {
        text = "";
    } else {
        if(props.text === null) {
            text = "";
        }
    }
    const newText = text.split('\n').map((str:any) => <p>{str}</p>);
    
    return newText;
}

export default Timeline;