import React, { Component, FormEvent } from 'react';
import { Row, Col, Alert, Badge, Container, ListGroup, ListGroupItem, Modal, ModalFooter, ModalHeader, ModalBody, Form, FormGroup, Button, Label, Input, ButtonGroup, Dropdown, DropdownMenu, DropdownItem, InputGroup, InputGroupText, Table } from 'reactstrap';
import { LocalStorageWorker } from '../StorageHelper';
import { CryptoHelper } from '../CryptoHelper.js';
import { AppCtx, AppContextInterface, ApiHelper } from '../AppContextInterface';
import { Redirect } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DateTime } from 'luxon';

interface SubscriptionTiersProps {

}

export interface SubscriptionTiers extends React.Component {
    state: SubscriptionTiersState;
    props: SubscriptionTiersProps;
    contextType: AppContextInterface;
}

interface SubscriptionTiersState {
    errorMessage: string;
    language: string;
    tiers: SubscriptionTierData[];
    fetchingTiers: boolean;
    selectedTier: SubscriptionTierData|null;
    successMessage: string;
    createTierMode: boolean;
    editTierMode: boolean;
    createTierSubmitting: boolean;
    editTierSubmitting: boolean;
    newTierName: string;
    newTierDescription: string;
    newTierPriceEuro: number;
    newTierPriceCents: number;
    newTierBillingPeriodNum: number;
    newTierBillingPeriodUnits: string;
    newTierSubscriptionFunction: string;
    newTierSubscriptionDiscount: number;
    newTierInitialRuntime: number;
    newTierInitialPriceEuro: number;
    newTierInitialPriceCents: number;
    editTierName: string;
    editTierDescription: string;
    editTierPriceEuro: number;
    editTierPriceCents: number;
    editTierBillingPeriodNum: number;
    editTierBillingPeriodUnits: string;
    editTierSubscriptionFunction: string;
    editTierSubscriptionDiscount: number;
    editTierInitialRuntime: number;
    editTierInitialPriceEuro: number;
    editTierInitialPriceCents: number;
    createTierGroupModal: boolean;
    fetchingSubscriberInfo: boolean;
    tierInfos: SubscriptionTierInfo[];
    showSubscriberList: boolean;
    singleTierSubscriberList: SubscriptionInfoJSON[];
    groupDefaultName: string;
    groupDefaultDescription: string;
    groupDefaultPriceEuro: number;
    groupDefaultPriceCents: number;
    groupDefaultPriceEuro3: number;
    groupDefaultPriceCents3: number;
    groupDefaultPriceEuro6: number;
    groupDefaultPriceCents6: number;
    groupDefaultPriceEuro12: number;
    groupDefaultPriceCents12: number;
    groupErrorMessage: string;
}

export interface SubscriptionInfoJSON {
    producerID: string;
    userID: string;
    userPseudo: string;
    timestamp: Date;
    id: number;
    isBilled: boolean;
    billingPeriod: string;
    price: number;
    nextBillingCycle: Date|null;
    billingStatus: string;
    subscriptionStatus: string;
    authorizationID: number;
}

export interface SubscriptionTierData {
    id: number;
    price: number;
    creationTime: Date;
    tierName: string;
    tierDescription: string;
    tierLogo: string;
    billingPeriodNum: number;
    billingPeriodUnits: string;
    subscriptionFunction: string;
    subscriptionDiscount: number;
    discountFormula: string;
    initialRuntime: number;
    initialPrice: number;
}

interface SubscriptionTierInfo {
    tierID: number;
    numSubscribers: number;
}

export class SubscriptionTiers extends React.Component {
    static contextType = AppCtx;
    context!: React.ContextType<typeof AppCtx>;

    constructor(props: SubscriptionTiersProps) {
        super(props);

        let contextLanguage = "de";

        if (this.context) {
            if (this.context.language !== null && this.context.language !== "") {
                contextLanguage = this.context.language;
            }
        }

        this.state = {
            errorMessage: "",
            successMessage: "",
            language: contextLanguage,
            tiers: [],
            selectedTier: null,
            fetchingTiers: false,
            createTierMode: false,
            editTierMode: false,
            createTierSubmitting: false,
            editTierSubmitting: false,
            newTierName: "",
            newTierDescription: "",
            newTierPriceEuro: 0,
            newTierPriceCents: 0,
            newTierBillingPeriodNum: 1,
            newTierBillingPeriodUnits: "months",
            newTierSubscriptionFunction: "WATCH_FOR_FREE",
            newTierSubscriptionDiscount: 0,
            newTierInitialRuntime: 0,
            newTierInitialPriceEuro: 0,
            newTierInitialPriceCents: 0,
            editTierName: "",
            editTierDescription: "",
            editTierPriceEuro: 0,
            editTierPriceCents: 0,
            editTierBillingPeriodNum: 1,
            editTierBillingPeriodUnits: "months",
            editTierSubscriptionFunction: "WATCH_FOR_FREE",
            editTierSubscriptionDiscount: 0,
            editTierInitialRuntime: 0,
            editTierInitialPriceEuro: 0,
            editTierInitialPriceCents: 0,
            createTierGroupModal: false,
            fetchingSubscriberInfo: false,
            tierInfos: [],
            showSubscriberList: false,
            singleTierSubscriberList: [],
            groupDefaultName: "",
            groupDefaultDescription: "",
            groupDefaultPriceEuro: 0,
            groupDefaultPriceCents: 0,
            groupDefaultPriceEuro3: 0,
            groupDefaultPriceCents3: 0,
            groupDefaultPriceEuro6: 0,
            groupDefaultPriceCents6: 0,
            groupDefaultPriceEuro12: 0,
            groupDefaultPriceCents12: 0,
            groupErrorMessage: ""
        }
    }

    componentDidMount() {
        this.fetchTiers();
    }

    fetchTiers = async () => {
        this.setState({ fetchingTiers: true });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/subscriptiontiers";

        if (this.context) {
            if(!this.context.loggedin) {
                await ApiHelper.checkLoginStatus(this.context);
            }
            jsonurl += "?producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        }

        let response: any = null;

        try {
            response = await fetch(jsonurl);
            let data = await response.json();
            this.setState({ fetchingTiers: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                }
            }

            if (data.tiers) {
                this.setState({ tiers: data.tiers });
                this.fetchSubscriberNumbers();
            }
        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching data: " + error.toString() });
            this.setState({ fetching: false });
        }
    }

    clickTier(id: number) {
        let mytier: SubscriptionTierData | undefined = undefined;

        this.setState({ listID: "" });
        this.setState({ successMessage: "" });
        this.setState({ errorMessage: "" });
        this.setState({ editTierMode: false });
        this.setState({ showSubscriberList: false });

        mytier = this.state.tiers.find(element => element.id === id);

        if (mytier !== undefined && mytier !== null) {
            this.setState({ selectedTier: mytier });
        } else {
            this.setState({ errorMessage: "Invalid Tier clicked: " + id });
        }
    }

    initEditMode() {
        if(this.state.selectedTier === null) {
            return;
        }

        this.setState({ showSubscriberList: false });
        this.setState({ editTierMode: true });
        this.setState({ editTierName: this.state.selectedTier.tierName });
        this.setState({ editTierDescription: this.state.selectedTier.tierDescription });
        this.setState({ editTierPriceEuro: Math.floor(this.state.selectedTier.price / 100) });
        this.setState({ editTierPriceCents: this.state.selectedTier.price % 100 });
        this.setState({ editTierBillingPeriodNum: this.state.selectedTier.billingPeriodNum });
        this.setState({ editTierBillingPeriodUnits: this.state.selectedTier.billingPeriodUnits });
        this.setState({ editTierSubscriptionFunction: this.state.selectedTier.subscriptionFunction });
        this.setState({ editTierSubscriptionDiscount: this.state.selectedTier.subscriptionDiscount });
        this.setState({ editTierInitialRuntime: this.state.selectedTier.initialRuntime });
        this.setState({ editTierInitialPriceEuro: Math.floor(this.state.selectedTier.initialPrice / 100) });
        this.setState({ editTierInitialPriceCents: this.state.selectedTier.initialPrice % 100 });
        this.setState({ editTierSubmitting: false });
    }

    cancelEditMode() {
        this.setState({ editTierMode: false });
    }

    createNewTierGroup() {
        this.setState({selectedTier: null});
        this.setState({createTierMode: false});
        this.setState({createTierGroupModal: true});
        this.setState({createTierSubmitting: false});

        if(this.state.groupDefaultName === "") {
            this.setState({groupDefaultName: "VIP-Abo"});
        }
        if(this.state.groupDefaultDescription === "") {
            this.setState({groupDefaultDescription: "Unterstütze mich mit einem VIP-Abo und erhalte Zugriff auf alle Inhalte"});
        }
        if(this.state.groupDefaultPriceEuro === 0) {
            this.setState({groupDefaultPriceEuro: 29});
        }
        if(this.state.groupDefaultPriceCents === 0) {
            this.setState({groupDefaultPriceCents: 99});
        }
        if(this.state.groupDefaultPriceEuro3 === 0) {
            this.setState({groupDefaultPriceEuro3: 24});
        }
        if(this.state.groupDefaultPriceCents3 === 0) {
            this.setState({groupDefaultPriceCents3: 99});
        }
        if(this.state.groupDefaultPriceEuro6 === 0) {
            this.setState({groupDefaultPriceEuro6: 19});
        }
        if(this.state.groupDefaultPriceCents6 === 0) {
            this.setState({groupDefaultPriceCents6: 99});
        }
        if(this.state.groupDefaultPriceEuro12 === 0) {
            this.setState({groupDefaultPriceEuro12: 14});
        }
        if(this.state.groupDefaultPriceCents12 === 0) {
            this.setState({groupDefaultPriceCents12: 99});
        }
    }

    createNewTier() {
        this.setState({selectedTier: null});
        this.setState({createTierMode: true});
        this.setState({createTierGroupModal: false});
        this.setState({createTierSubmitting: false});
    }

    createTierGroupModalSubmit() {
        if(this.state.groupDefaultName === "") {
            this.setState({groupErrorMessage: "Bitte einen Namen für die Gruppe eingeben"});
            return;
        }
        
        let groupDefaultPrice:number = this.state.groupDefaultPriceEuro * 100;
        groupDefaultPrice += this.state.groupDefaultPriceCents;
        let groupPrice3:number = this.state.groupDefaultPriceEuro3 * 100;
        groupPrice3 += this.state.groupDefaultPriceCents3;
        let groupPrice6:number = this.state.groupDefaultPriceEuro6 * 100;
        groupPrice6 += this.state.groupDefaultPriceCents6;
        let groupPrice12:number = this.state.groupDefaultPriceEuro12 * 100;
        groupPrice12 += this.state.groupDefaultPriceCents12;

        this.setState({createTierGroupModal: false});
        this.autoCreateTier(this.state.groupDefaultName + " 1 " + this.getText("Month"),this.state.groupDefaultDescription,groupDefaultPrice,1,"months","WATCH_FOR_FREE",0,0,groupDefaultPrice);
        this.autoCreateTier(this.state.groupDefaultName + " 3 " + this.getText("Months"),this.state.groupDefaultDescription,groupDefaultPrice,1,"months","WATCH_FOR_FREE",0,3,groupPrice3);
        this.autoCreateTier(this.state.groupDefaultName + " 6 " + this.getText("Months"),this.state.groupDefaultDescription,groupDefaultPrice,1,"months","WATCH_FOR_FREE",0,6,groupPrice6);
        this.autoCreateTier(this.state.groupDefaultName + " 12 " + this.getText("Months"),this.state.groupDefaultDescription,groupDefaultPrice,1,"months","WATCH_FOR_FREE",0,12,groupPrice12);
        setTimeout(this.fetchTiers.bind(this),1500);
    }

    createTierGroupModalClose() {
        this.setState({createTierGroupModal: false});
    }

    handleGroupDefaultNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ groupDefaultName: event.target.value });
    }

    handleGroupDefaultDescriptionChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ groupDefaultDescription: event.target.value });
    }

    handleGroupDefaultPriceEuroChange(event: React.ChangeEvent<HTMLInputElement>) {
        if(!isNaN(parseInt(event.target.value))) {
            this.setState({groupDefaultPriceEuro: parseInt(event.target.value)})
        }
    }

    handleGroupDefaultPriceCentsChange(event: React.ChangeEvent<HTMLInputElement>) {
        if(!isNaN(parseInt(event.target.value))) {
            this.setState({groupDefaultPriceCents: parseInt(event.target.value)})
        }
    }

    handleGroupDefaultPriceEuro3Change(event: React.ChangeEvent<HTMLInputElement>) {
        if(!isNaN(parseInt(event.target.value))) {
            this.setState({groupDefaultPriceEuro3: parseInt(event.target.value)})
        }
    }

    handleGroupDefaultPriceCents3Change(event: React.ChangeEvent<HTMLInputElement>) {
        if(!isNaN(parseInt(event.target.value))) {
            this.setState({groupDefaultPriceCents3: parseInt(event.target.value)})
        }
    }

    handleGroupDefaultPriceEuro6Change(event: React.ChangeEvent<HTMLInputElement>) {
        if(!isNaN(parseInt(event.target.value))) {
            this.setState({groupDefaultPriceEuro6: parseInt(event.target.value)})
        }
    }

    handleGroupDefaultPriceCents6Change(event: React.ChangeEvent<HTMLInputElement>) {
        if(!isNaN(parseInt(event.target.value))) {
            this.setState({groupDefaultPriceCents6: parseInt(event.target.value)})
        }
    }

    handleGroupDefaultPriceEuro12Change(event: React.ChangeEvent<HTMLInputElement>) {
        if(!isNaN(parseInt(event.target.value))) {
            this.setState({groupDefaultPriceEuro12: parseInt(event.target.value)})
        }
    }

    handleGroupDefaultPriceCents12Change(event: React.ChangeEvent<HTMLInputElement>) {
        if(!isNaN(parseInt(event.target.value))) {
            this.setState({groupDefaultPriceCents12: parseInt(event.target.value)})
        }
    }

    handleNewTierNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ newTierName: event.target.value });
    }

    handleNewTierDescriptionChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ newTierDescription: event.target.value });
    }

    handleNewTierPriceEuroChange(event: React.ChangeEvent<HTMLInputElement>) {
        if(event.target.value === "") {
            this.setState({newTierPriceEuro: 0})
            return;
        }

        if(!isNaN(parseInt(event.target.value))) {
            this.setState({newTierPriceEuro: parseInt(event.target.value)})
        }
    }

    handleNewTierPriceCentsChange(event: React.ChangeEvent<HTMLInputElement>) {
        if(event.target.value === "") {
            this.setState({newTierPriceCents: 0})
            return;
        }

        if(!isNaN(parseInt(event.target.value))) {
            this.setState({newTierPriceCents: parseInt(event.target.value)})
        }
    }

    handleNewTierBillingPeriodChange(event: React.ChangeEvent<HTMLInputElement>) {
        if(event.target.value === "") {
            this.setState({newTierBillingPeriodNum: 0})
            return;
        }

        if(!isNaN(parseInt(event.target.value))) {
            this.setState({newTierBillingPeriodNum: parseInt(event.target.value)})
        }
    }

    handleNewTierBillingPeriodUnitsChange(event: React.ChangeEvent<HTMLSelectElement>) {
        this.setState({newTierBillingPeriodUnits: event.target.value})
    }

    handleNewTierSubscriptionFunctionChange(event: React.ChangeEvent<HTMLSelectElement>) {
        this.setState({ newTierSubscriptionFunction: event.target.value });
    }

    handleNewTierSubscriptionDiscountChange(event: React.ChangeEvent<HTMLInputElement>) {
        if(event.target.value === "") {
            this.setState({newTierSubscriptionDiscount: 0})
            return;
        }

        if(!isNaN(parseInt(event.target.value))) {
            this.setState({newTierSubscriptionDiscount: parseInt(event.target.value)})
        }
    }

    handleNewTierInitialPriceEuroChange(event: React.ChangeEvent<HTMLInputElement>) {
        if(event.target.value === "") {
            this.setState({newTierInitialPriceEuro: 0})
            return;
        }

        if(!isNaN(parseInt(event.target.value))) {
            this.setState({newTierInitialPriceEuro: parseInt(event.target.value)})
        }
    }

    handleNewTierInitialPriceCentsChange(event: React.ChangeEvent<HTMLInputElement>) {
        if(event.target.value === "") {
            this.setState({newTierInitialPriceCents: 0})
            return;
        }

        if(!isNaN(parseInt(event.target.value))) {
            this.setState({newTierInitialPriceCents: parseInt(event.target.value)})
        }
    }

    handleNewTierInitialRuntimeChange(event: React.ChangeEvent<HTMLInputElement>) {
        if(event.target.value === "") {
            this.setState({newTierInitialRuntime: 0})
            return;
        }

        if(!isNaN(parseInt(event.target.value))) {
            this.setState({newTierInitialRuntime: parseInt(event.target.value)})
        }
    }

    handleEditTierNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ editTierName: event.target.value });
    }

    handleEditTierDescriptionChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ editTierDescription: event.target.value });
    }

    handleEditTierBillingPeriodChange(event: React.ChangeEvent<HTMLInputElement>) {
        if(event.target.value === "") {
            this.setState({editTierBillingPeriodNum: 0})
            return;
        }

        if(!isNaN(parseInt(event.target.value))) {
            this.setState({ editTierBillingPeriodNum: parseInt(event.target.value) });
        }
    }

    handleEditTierBillingPeriodUnitsChange(event: React.ChangeEvent<HTMLSelectElement>) {
        this.setState({ editTierBillingPeriodUnits: event.target.value });
    }

    handleEditTierSubscriptionFunctionChange(event: React.ChangeEvent<HTMLSelectElement>) {
        this.setState({ editTierSubscriptionFunction: event.target.value });
    }

    handleEditTierSubscriptionDiscountChange(event: React.ChangeEvent<HTMLInputElement>) {
        if(event.target.value === "") {
            this.setState({editTierSubscriptionDiscount: 0})
            return;
        }

        if(!isNaN(parseInt(event.target.value))) {
            this.setState({ editTierSubscriptionDiscount: parseInt(event.target.value) });
        }
    }

    handleEditTierPriceEuroChange(event: React.ChangeEvent<HTMLInputElement>) {
        if(event.target.value === "") {
            this.setState({editTierPriceEuro: 0})
            return;
        }

        if(!isNaN(parseInt(event.target.value))) {
            this.setState({ editTierPriceEuro: parseInt(event.target.value) });
        }
    }

    handleEditTierPriceCentsChange(event: React.ChangeEvent<HTMLInputElement>) {
        if(event.target.value === "") {
            this.setState({editTierPriceCents: 0})
            return;
        }

        if(!isNaN(parseInt(event.target.value))) {
            this.setState({ editTierPriceCents: parseInt(event.target.value) });
        }
    }


    handleEditTierInitialPriceEuroChange(event: React.ChangeEvent<HTMLInputElement>) {
        if(event.target.value === "") {
            this.setState({editTierInitialPriceEuro: 0})
            return;
        }

        if(!isNaN(parseInt(event.target.value))) {
            this.setState({ editTierInitialPriceEuro: parseInt(event.target.value) });
        }
    }

    handleEditTierInitialPriceCentsChange(event: React.ChangeEvent<HTMLInputElement>) {
        if(event.target.value === "") {
            this.setState({editTierInitialPriceCents: 0})
            return;
        }

        if(!isNaN(parseInt(event.target.value))) {
            this.setState({ editTierInitialPriceCents: parseInt(event.target.value) });
        }
    }

    handleEditTierInitialRuntimeChange(event: React.ChangeEvent<HTMLInputElement>) {
        if(event.target.value === "") {
            this.setState({editTierInitialRuntime: 0})
            return;
        }

        if(!isNaN(parseInt(event.target.value))) {
            this.setState({ editTierInitialRuntime: parseInt(event.target.value) });
        }
    }

    handleCreateTierSubmit(event: FormEvent) {
        event.preventDefault();
        this.createNewTierSubmitted();
    }

    handleEditTierSubmit(event: FormEvent) {
        event.preventDefault();
        this.editTierSubmit();
    }

    editTierSubmit = async() => {
        this.setState({ editTierSubmitting: true });
        this.setState({ successMessage: "" });
        this.setState({ errorMessage: "" });

        if(this.state.selectedTier === null) {
            return;
        }

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/subscriptiontiers";

        if (this.context) {
            jsonurl += "?producerID=" + encodeURIComponent(this.context.producerID);
        }

        jsonurl += "&language=" + encodeURIComponent(this.state.language);
        jsonurl += "&id=" + encodeURIComponent(this.state.selectedTier.id);
        jsonurl += "&tierName=" + encodeURIComponent(this.state.editTierName);
        jsonurl += "&tierDescription=" + encodeURIComponent(this.state.editTierDescription);
        let newprice:number = this.state.editTierPriceEuro * 100;
        newprice += this.state.editTierPriceCents;
        jsonurl += "&tierPrice=" + encodeURIComponent(newprice);
        jsonurl += "&billingPeriodNum=" + encodeURIComponent(this.state.editTierBillingPeriodNum);
        jsonurl += "&billingPeriodUnits=" + encodeURIComponent(this.state.editTierBillingPeriodUnits);
        jsonurl += "&subscriptionFunction=" + encodeURIComponent(this.state.editTierSubscriptionFunction);
        if(this.state.editTierSubscriptionDiscount === null) {
            jsonurl += "&subscriptionDiscount=0";
        } else {
            jsonurl += "&subscriptionDiscount=" + encodeURIComponent(this.state.editTierSubscriptionDiscount);
        }
        jsonurl += "&initialRuntime=" + encodeURIComponent(this.state.editTierInitialRuntime);
        let initialprice:number = this.state.editTierInitialPriceEuro * 100;
        initialprice += this.state.editTierInitialPriceCents;
        jsonurl += "&initialPrice=" + encodeURIComponent(initialprice);

        if (this.context) {
            jsonurl += "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        }

        let response: any = null;

        try {
            response = await fetch(jsonurl,{method: "PUT"});
            let data = await response.json();
            this.setState({ editTierSubmitting: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null && (data.message === "" || data.message === null)) {
                    this.setState({ errorMessage: "Error: " + data.error });
                }
            }

            if(data.message) {
                this.setState({ successMessage: data.message });
            }

            if(data.editStatus) {
                if(data.editStatus === "done") {
                    this.setState({ editTierMode: false });
                    this.setState({ selectedTier: null });
                    this.fetchTiers();
                    this.setState({ successMessage: this.getText("Edit Done") });
                }
            }

        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching data: " + error.toString() });
            this.setState({ editTierSubmitting: false });
        }
    }

    createNewTierSubmitted = async() => {
        this.setState({ createTierSubmitting: true });
        this.setState({ successMessage: "" });
        this.setState({ errorMessage: "" });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/subscriptiontiers";

        if (this.context) {
            jsonurl += "?producerID=" + encodeURIComponent(this.context.producerID);
        }

        jsonurl += "&tierName=" + encodeURIComponent(this.state.newTierName);
        jsonurl += "&tierDescription=" + encodeURIComponent(this.state.newTierDescription);
        let newprice:number = this.state.newTierPriceEuro * 100;
        newprice += this.state.newTierPriceCents;
        jsonurl += "&tierPrice=" + encodeURIComponent(newprice);
        jsonurl += "&billingPeriodNum=" + encodeURIComponent(this.state.newTierBillingPeriodNum);
        jsonurl += "&billingPeriodUnits=" + encodeURIComponent(this.state.newTierBillingPeriodUnits);
        jsonurl += "&subscriptionFunction=" + encodeURIComponent(this.state.newTierSubscriptionFunction);
        jsonurl += "&subscriptionDiscount=" + encodeURIComponent(this.state.newTierSubscriptionDiscount);
        jsonurl += "&initialRuntime=" + encodeURIComponent(this.state.newTierInitialRuntime);
        let initialprice:number = this.state.newTierInitialPriceEuro * 100;
        initialprice += this.state.newTierInitialPriceCents;
        jsonurl += "&initialPrice=" + encodeURIComponent(initialprice);

        if (this.context) {
            jsonurl += "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        }

        let response: any = null;

        try {
            response = await fetch(jsonurl,{method: "POST"});
            let data = await response.json();
            this.setState({ createTierSubmitting: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                }
            }

            this.setState({ successMessage: data.message });
            this.setState({ createTierMode: false });

            this.fetchTiers();

        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching data: " + error.toString() });
            this.setState({ createTierSubmitting: false });
        }
    }

    deleteTierClicked = async(tierID:number) => {
        this.setState({ createTierMode: false });
        this.setState({ selectedTier: null });
        
        this.setState({ fetching: true });
        this.setState({ successMessage: "" });
        this.setState({ errorMessage: "" });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/subscriptiontiers";

        if (this.context) {
            jsonurl += "?producerID=" + encodeURIComponent(this.context.producerID);
        }

        jsonurl += "&id=" + encodeURIComponent(tierID);
        jsonurl += "&language=" + encodeURIComponent(this.state.language);

        if (this.context) {
            jsonurl += "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        }

        let response: any = null;

        try {
            response = await fetch(jsonurl,{method: "DELETE"});
            let data = await response.json();
            this.setState({ createTierSubmitting: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                }
            }

            this.setState({ successMessage: data.message });

            this.fetchTiers();

        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching data: " + error.toString() });
            this.setState({ fetching: false });
        }
    }

    unselectTier() {
        this.setState({ createTierMode: false });
        this.setState({ selectedTier: null });
    }

    cancelCreateTier(e:React.FormEvent<HTMLButtonElement>) {
        e.preventDefault();
        this.setState({ createTierMode: false });
        this.setState({ selectedTier: null });
    }

    getSubEndDesc(subscriptionStatus:string,nextBillingCycle:Date|null) {
        if(subscriptionStatus == "ACTIVE") {
            if(nextBillingCycle) {
                if(typeof(nextBillingCycle.toISOString) === "function") {
                    return this.getText("NextBilling") + " " + DateTime.fromISO(nextBillingCycle.toISOString()).toLocaleString(DateTime.DATETIME_MED);
                } else {
                    return this.getText("NextBilling") + " " + DateTime.fromISO(nextBillingCycle.toString()).toLocaleString(DateTime.DATETIME_MED);
                }
            } else {
                return this.getText("Active");
            }
        } else if(subscriptionStatus == "CANCELLED") {
            if(nextBillingCycle) {
                if(typeof(nextBillingCycle.toISOString) === "function") {
                    return this.getText("ValidTil") + " " + DateTime.fromISO(nextBillingCycle.toISOString()).toLocaleString(DateTime.DATETIME_MED);
                } else {
                    return this.getText("ValidTil") + " " + DateTime.fromISO(nextBillingCycle.toString()).toLocaleString(DateTime.DATETIME_MED);
                }
            } else {
                return this.getText("Cancelled");
            }
        }
    }

    getText(exp: string) {
        if (this.state.language === "de") {
            if(exp === "WATCH_FOR_FREE") {
                return "Darf Timeline-Inhalte kostenlos abrufen";
            }
            if(exp === "PERCENT") {
                return "Prozentualer Rabatt beim Kauf von Timeline-Inhalten";
            }
            if(exp === "ABS") {
                return "Rabatt in Cents beim Kauf von Timeline-Inhalten";
            }
            if(exp === "NextBilling") {
                return "Nächste Abbuchung";
            }
            if(exp == "ValidTil") {
                return "Noch gültig bis";
            }
            if(exp == "ACTIVE") {
                return "Aktiv";
            }
            if(exp == "CANCELLED") {
                return "Gekündigt";
            }
            if(exp == "DELETED") {
                return "Gelöscht";
            }
            if(exp === "HowToCreateFreeAbo") {
                return "Hinweis: Um ein kostenloses Abo zu erstellen, trange einfach nur einen Namen und einen Beschreibungstext ein und lasse Preis und Mindestlaufzeit auf 0.";
            }
            if(exp === "OnInitialRuntimeOf") {
                return "bei Mindestlaufzeit von ";
            }
            if(exp === "CurrentSelection") {
                return "Aktuelle Auswahl";
            }
            if(exp === "CurrentPrice") {
                return "Aktueller Preis";
            }
            if(exp === "CreateGroup") {
                return "Abo-Paket erstellen";
            }
            if(exp === "CreateTierGroup") {
                return "Abo-Paket erstellen";
            }
            if(exp === "Erklärung") {
                return "Erklärung";
            }
            if(exp === "MinRuntimeDescription") {
                return "Die Mindestlaufzeit dieses Abos beträgt ";
            }
            if(exp === "MinRuntime") {
                return "Mindestlaufzeit";
            }
            if(exp === "NoMinRuntime") {
                return "ohne Mindestlaufzeit";
            }
            if(exp === "MinRuntimePriceDescription") {
                return " der Preis während der Mindestlaufzeit beträgt ";
            }
            if(exp === "AfterMinRuntimeDescription") {
                return "Nach der Mindestlaufzeit wechselt das Abo in den regulären Modus.";
            }
            if(exp === "RegularPriceDescription") {
                return "Das Abo kostet im regulären Modus";
            }
            if(exp === "one") {
                return "einen";
            }
            if(exp === "each") {
                return "jeden";
            }
            if(exp === "every") {
                return "alle";
            }
            if(exp === "currently") {
                return "Aktuell";
            }
            if(exp === "RegularCancelInfo") {
                return " und kann dann jeweils zum nächsten Abbuchungszeitraum gekündigt werden.";
            }
            if(exp === "Edit Done") {
                return "Abo-Level wurde gespeichert";
            }
            if(exp === "NumSubscribers") {
                return "Anzahl der Abonnenten";
            }
            if(exp === "WatchForFreeExplanation") {
                return "Abonennten dürfen Posts kostenlos ansehen (es sei denn, in dem Post ist explizit eingestellt, dass er für Abonennten Geld kosten soll)";
            }
            if(exp === "WatchPercentExplanation") {
                return "Abonennten erhalten beim Kauf von Posts einen Rabatt von PERCENT Prozent (es kann aber ein eigener Preis für jeden Post festgelegt werden)";
            }
            if(exp === "WatchAbsExplanation") {
                return "Abonennten erhalten beim Kauf von Posts einen Rabatt von ABS € (es kann aber ein eigener Preis für jeden Post festgelegt werden)";
            }
            if(exp === "TierGroupTemplateFree") {
                return "Ein kostenloses Abo-Level";
            }
            if(exp === "TierGroupTemplateDefault") {
                return "Ein Abo-Level für 29,99 € pro Monat";
            }
            if(exp === "TierGroupTemplateBasisPremium") {
                return "Abo-Level Basis und Premium für 29,99 bzw. 49,99 € pro Monat";
            }
            if(exp === "TierGroupTemplateStandardGoldPlatin") {
                return "Abo-Level Standard, Gold und Platin für 19,99, 34,99 und 49,99 € pro Monat";
            }
            if(exp === "TierGroupTemplateFree") {
                return "Ein kostenloses Abo-Level";
            }
            if(exp === "TierGroupTemplateFree") {
                return "Ein kostenloses Abo-Level";
            }
            if(exp === "Select tier group template") {
                return "Wähle zu erstellende Vorlage aus";
            }
            if(exp === "Create new tier") {
                return "Erstelle ein einzelnes Abo-Level";
            }
            if(exp === "Edit tier") {
                return "Bearbeite Abo-Level";
            }
            if(exp === "Create new tier group") {
                return "Erstelle ein Abo-Paket für Deine Kunden";
            }
            if (exp === "Tier Name") {
                return "Abo-Level Name";
            }
            if (exp === "Tier Description") {
                return "Abo-Level Beschreibung";
            }
            if (exp === "Tier Price") {
                return "Abo-Preis";
            }
            if (exp === "Tier Billing Period") {
                return "Abo-Abbuchungszeitraum";
            }
            if (exp === "Billing Period") {
                return "Abbuchungszeitraum";
            }
            if(exp === "NoSubscriptionTierSelected") {
                return "Hier kannst Du 'Abo-Level' erstellen und bearbeiten. Deine Kunden/User können zwischen diesen Abo-Levels auswählen, so kannst Du z.B. unterschiedliche Laufzeiten anbieten oder eine Abstufung in der Form Basis/Premium Abo, bei dem nur die Premium-Abonennten bestimmte Inhalte kostenlos bekommen. Zusätzlich kannst für jeden Deiner Posts auch immer individuelle Preise einstellen, falls Du mal sehr kurze oder besondere Premium-Inhalte verkaufen möchtest.";
            }
            if(exp === "SubscriptionTierSelected") {
                return "Die Abo-Level lassen sich auch nachträglich bearbeiten, aber wenn es bereits Abonennten gibt, dann wird das von unserem Team geprüft, wenn sich Abo-Preise und -Laufzeiten ändern sollen."
            }
            if (exp === "Edit") {
                return "Bearbeiten";
            }
            if (exp === "Close") {
                return "Schliessen";
            }
            if (exp === "Days") {
                return "Tage";
            }
            if (exp === "Weeks") {
                return "Wochen";
            }
            if(exp === "Month") {
                return "Monat";
            }
            if(exp === "Months") {
                return "Monate";
            }
            if(exp === "MonthlyCancellable") {
                return "jederzeit zum Monatsende kündbar";
            }
            if(exp === "SubscriptionTiers") {
                return "Abonnement-Level einrichten";
            }
            if (exp === "Name") {
                return "Name des Levels (z.B. 'Gold','Silber','Platin')";
            }
            if(exp === "Description") {
                return "Beschreibungs-Text für das Abo-Level";
            }
            if(exp === "Price") {
                return "Preis";
            }
            if(exp === "Posts are free to watch") {
                return "Abonnenten in diesem Level können meine Posts kostenlos ansehen";
            }
            if(exp === "Posts are discounted by percent") {
                return "Abonnenten in diesem Level können meine Posts für PERCENT Prozent weniger kaufen";
            }
            if(exp === "Posts are discounted by cents") {
                return "Abonnenten in diesem Level können meine Posts für ABS Cents weniger kaufen";
            }
            if(exp === "Tier Subscription Function") {
                return "Was erhalten die Abonennten in diesem Level?";
            }
            if(exp === "Tier Subscription Function Number PERCENT") {
                return "Rabatt in Prozent";
            }
            if(exp === "Tier Subscription Function Number ABS") {
                return "Rabatt in €-Cents (100 = 1 €)";
            }
            if(exp === "Submit") {
                return "Speichern";
            }
            if(exp === "Cancel") {
                return "Abbrechen";
            }
            if(exp === "SelectedTierName") {
                return "Name des ausgewählten Abo-Level";
            }
            if(exp === "SelectedTierDescription") {
                return "Beschreibung ausgewähltes Abo-Level";
            }
            if(exp === "SelectedTierPrice") {
                return "Preis ausgewähltes Abo-Level";
            }
            if(exp === "SelectedTierBillingPeriod") {
                return "Abbuchungszeitraum";
            }
            if(exp === "SelectedTierSubscriptionFunction") {
                return "Benefit für Abonennten in diesem Abo-Level";
            }
            if(exp === "FetchSubscribers") {
                return "Abonennten anzeigen";
            }
            if(exp === "Subscribers") {
                return "Abonennten";
            }
            if(exp === "NoSubscribers") {
                return "Keine Abonennten gefunden";
            }
            if(exp === "HideSubscribers") {
                return "Abonennten ausblenden";
            }
            if(exp === "InitialRuntime") {
                return "Mindestlaufzeit";
            }
            if(exp === "InitialRuntimePrice") {
                return "Preis während der Mindestlaufzeit";
            }
            if(exp === "InitialPrice") {
                return "Preis während der Mindestlaufzeit";
            }
            if(exp === "NumberOf") {
                return "Anzahl";
            }
            if(exp === "ThereIsNoMinRuntime") {
                return "Es gibt keine Mindestlaufzeit.";
            }
            if(exp === "afterMinruntime") {
                return "danach";
            }
        } else {
            if(exp === "WATCH_FOR_FREE") {
                return "Can watch and view timeline contents for free";
            }
            if(exp === "PERCENT") {
                return "Discount in percent when buying timeline contents";
            }
            if(exp === "ABS") {
                return "Discount in cents when buying timeline contents";
            }
            if(exp === "NextBilling") {
                return "Next billing";
            }
            if(exp == "ValidTil") {
                return "Valid till";
            }
            if(exp == "ACTIVE") {
                return "Active";
            }
            if(exp == "CANCELLED") {
                return "Cancelled";
            }
            if(exp == "DELETED") {
                return "Deleted";
            }
            if(exp === "HowToCreateFreeAbo") {
                return "Hint: In order to create a free subscription model just enter name and description and leave the price and minimum runtime at 0.";
            }
            if(exp === "OnInitialRuntimeOf") {
                return "During the initial runtime of ";
            }
            if(exp === "CurrentSelection") {
                return "Current selection";
            }
            if(exp === "CurrentPrice") {
                return "Current price";
            }
            if(exp === "Erklärung") {
                return "Explanation";
            }
            if(exp === "MinRuntimeDescription") {
                return "Minimum runtime for a subscription is ";
            }
            if(exp === "MinRuntime") {
                return "Minimum runtime";
            }
            if(exp === "NoMinRuntime") {
                return "no minimum runtime";
            }
            if(exp === "MinRuntimePriceDescription") {
                return " the price during this time is ";
            }
            if(exp === "AfterMinRuntimeDescription") {
                return "After the minimum runtime the subscription will switch to regular mode.";
            }
            if(exp === "RegularPriceDescription") {
                return "The regular price is ";
            }
            if(exp === "one") {
                return "one";
            }
            if(exp === "each") {
                return "jeden";
            }
            if(exp === "every") {
                return "every";
            }
            if(exp === "currently") {
                return "currently";
            }
            if(exp === "RegularCancelInfo") {
                return " and can then be cancelled at any time.";
            }
            if(exp === "Edit Done") {
                return "Done";
            }
            if(exp === "NumSubscribers") {
                return "Number of subscribers";
            }
            if(exp === "WatchForFreeExplanation") {
                return "Subscribers will be able to watch your posts for free (unless there is a price set for the post)";
            }
            if(exp === "WatchPercentExplanation") {
                return "Subscribers will get a discount of {x} % when buying posts (but a price can be set for each post)";
            }
            if(exp === "WatchAbsExplanation") {
                return "Subscribers will get a discount of {x} € when buying posts (but a price can be set for each post)";
            }
            if(exp === "TierGroupTemplateFree") {
                return "One free subscription level";
            }
            if(exp === "TierGroupTemplateDefault") {
                return "One subscription level for 29,99 € per month";
            }
            if(exp === "TierGroupTemplateBasisPremium") {
                return "Subscription levels Basis and Premium for 29,99 and 49,99 € per month";
            }
            if(exp === "TierGroupTemplateStandardGoldPlatin") {
                return "Subscription levels Standard, Gold and Platin for 19,99, 34,99 and 49,99 € per month";
            }
            if(exp === "Select tier group template") {
                return "Select tier group template";
            }
            if(exp === "Create new tier") {
                return "Please select a subscription level";
            }
            if(exp === "Edit tier") {
                return "Edit subscription level";
            }
            if(exp === "Create new tier group") {
                return "Create new subscription level group";
            }
            if (exp === "Tier Name") {
                return "New Subscription Level Name";
            }
            if (exp === "Tier Description") {
                return "New Subscription Level Description";
            }
            if (exp === "Tier Price") {
                return "New Subscription Level Price";
            }
            if (exp === "Tier Billing Period") {
                return "Billing Period";
            }
            if (exp === "Billing Period") {
                return "Billing Period";
            }
            if(exp === "NoSubscriptionTierSelected") {
                return "Here you can create and edit 'Subscription Levels'. Your customers/users can choose between these subscription levels, so you can, for example, offer different terms or a gradation in the form of a basic/premium subscription, where only premium subscribers get certain content free of charge. In addition, you can always set individual prices for each of your posts if you want to sell very short or special premium content.";
            }
            if(exp === "SubscriptionTierSelected") {
                return "The subscription levels can also be edited afterwards, but if there are already subscribers, this will be checked by our team if subscription prices and durations are to change."
            }
            if (exp === "Days") {
                return "Days";
            }
            if (exp === "Weeks") {
                return "Weeks";
            }
            if(exp === "Months") {
                return "Months";
            }
            if(exp === "MonthlyCancellable") {
                return "Monthly cancellable";
            }
            if(exp === "SubscriptionTiers") {
                return "Subscription Level Management";
            }
            if (exp === "Name") {
                return "Name";
            }
            if(exp === "Description") {
                return "Beschreibung";
            }
            if(exp === "Price") {
                return "Preis";
            }
            if(exp === "Posts are free to watch") {
                return "Users in this level can watch my posts for free";
            }
            if(exp === "Posts are discounted by percent") {
                return "Users in this level can buy my posts for x percent off";
            }
            if(exp === "Posts are discounted by cents") {
                return "Users in this level can buy my posts for x cents off";
            }
            if(exp === "Tier Subscription Function") {
                return "Subscription Level Benefit";
            }
            if(exp === "Tier Subscription Function Number PERCENT") {
                return "Discount in percent";
            }
            if(exp === "Tier Subscription Function Number ABS") {
                return "Discount in €-Cents (100 = 1 €)";
            }
            if(exp === "Submit") {
                return "Submit";
            }
            if(exp === "Cancel") {
                return "Cancel";
            }
            if(exp === "SelectedTierName") {
                return "Name of selected subscription level";
            }
            if(exp === "SelectedTierDescription") {
                return "Description of selected subscription level";
            }
            if(exp === "SelectedTierPrice") {
                return "Price of selected subscription level";
            }
            if(exp === "SelectedTierBillingPeriod") {
                return "Billing Period";
            }
            if(exp === "SelectedTierSubscriptionFunction") {
                return "Benefit for users in this subscription level";
            }
            if(exp === "FetchSubscribers") {
                return "Show subscribers";
            }
            if(exp === "Subscribers") {
                return "Subscribers";
            }
            if(exp === "NoSubscribers") {
                return "No subscribers found";
            }
            if(exp === "HideSubscribers") {
                return "Hide subscribers";
            }
            if(exp === "InitialRuntime") {
                return "Minimum Runtime";
            }
            if(exp === "InitialPrice") {
                return "Price during minimum runtime";
            }
            if(exp === "InitialRuntimePrice") {
                return "Price during minimum runtime";
            }
            if(exp === "NumberOf") {
                return "Number of";
            }
            if(exp === "ThereIsNoMinRuntime") {
                return "There is no minimum runtime.";
            }
            if(exp === "afterMinruntime") {
                return "after that";
            }
        }

        return exp;
    }

    fetchSubscriberNumbers = async() => {
        this.setState({ fetchingSubscriberInfo: true });
        this.setState({ errorMessage: "" });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/subscriptioninfo";

        if (this.context) {
            jsonurl += "/producer/" + encodeURIComponent(this.context.producerID);
            jsonurl += "?authToken=" + encodeURIComponent(this.context.authToken);
        } else {
            return;
        }

        let response: any = null;

        try {
            response = await fetch(jsonurl,{method: "GET"});
            let data = await response.json();

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error fetching subscribers info: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error fetching subscribers info: " + data.error });
                }
            }

            this.setState({ fetchingSubscriberInfo: false });

            if (data.subscriptions !== null && data.subscriptions !== undefined) {
                this.setState({ tierInfos: data.subscriptions });
            }

        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching subscribers info: " + error.toString() });
            this.setState({ createTierSubmitting: false });
        }
    }

    hideSubscriberList() {
        this.setState({ showSubscriberList: false });
    }

    fetchSubscriberList = async(tierID:number) => {
        this.setState({ fetchingSubscriberInfo: true });
        this.setState({ errorMessage: "" });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/subscriptions";

        if (this.context) {
            jsonurl += "/producer/" + encodeURIComponent(this.context.producerID);
            jsonurl += "?authToken=" + encodeURIComponent(this.context.authToken);
            jsonurl += "&tierID=" + encodeURIComponent(tierID);
        } else {
            return;
        }

        let response: any = null;

        try {
            response = await fetch(jsonurl,{method: "GET"});
            let data = await response.json();

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error fetching subscribers list: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error fetching subscribers list: " + data.error });
                }
            }

            this.setState({ fetchingSubscriberInfo: false });

            if (data.subscriptions !== null && data.subscriptions !== undefined) {
                this.setState({ singleTierSubscriberList: data.subscriptions });
                this.setState({ showSubscriberList: true });
            }

        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching subscribers list: " + error.toString() });
            this.setState({ createTierSubmitting: false });
        }
    }

    findNumSubscribers(tierID:number) {
        if(this.state.tierInfos !== null && this.state.tierInfos !== undefined) {
            for(var i = 0; i < this.state.tierInfos.length; i++) {
                if(this.state.tierInfos[i].tierID === tierID) {
                    return this.state.tierInfos[i].numSubscribers;
                }
            }
        }

        return 0;
    }

    autoCreateTier = async(newTierName:string,newTierDescription:string,newTierPrice:number,newTierBillingPeriodNum:number,newTierBillingPeriodUnits:string,newTierSubscriptionFunction:string,newTierSubscriptionDiscount:number,newTierInitialRuntime:number,newTierInitialPrice:number) => {
        this.setState({ createTierSubmitting: true });
        this.setState({ successMessage: "" });
        this.setState({ errorMessage: "" });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/subscriptiontiers";

        if (this.context) {
            jsonurl += "?producerID=" + encodeURIComponent(this.context.producerID);
        }

        jsonurl += "&tierName=" + encodeURIComponent(newTierName);
        jsonurl += "&tierDescription=" + encodeURIComponent(newTierDescription);
        jsonurl += "&tierPrice=" + encodeURIComponent(newTierPrice);
        jsonurl += "&billingPeriodNum=" + encodeURIComponent(newTierBillingPeriodNum);
        jsonurl += "&billingPeriodUnits=" + encodeURIComponent(newTierBillingPeriodUnits);
        jsonurl += "&subscriptionFunction=" + encodeURIComponent(newTierSubscriptionFunction);
        jsonurl += "&subscriptionDiscount=" + encodeURIComponent(newTierSubscriptionDiscount);
        jsonurl += "&initialRuntime=" + encodeURIComponent(newTierInitialRuntime);
        jsonurl += "&initialPrice=" + encodeURIComponent(newTierInitialPrice);

        if (this.context) {
            jsonurl += "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        }

        let response: any = null;

        try {
            response = await fetch(jsonurl,{method: "POST"});
            let data = await response.json();
            this.setState({ createTierSubmitting: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null && (data.message === "" || data.message === null)) {
                    this.setState({ errorMessage: "Error: " + data.error });
                }
            }

            this.setState({ successMessage: data.message });

        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching data: " + error.toString() });
            this.setState({ createTierSubmitting: false });
        }
    }

    changeEditTierPriceEuro(e:React.ChangeEvent<HTMLInputElement>) {
        if(e.target.value === "") {
            this.setState({editTierInitialPriceEuro: 0});
            return;
        }

        if(!isNaN(parseInt(e.target.value))) {
            this.setState({editTierInitialPriceEuro: parseInt(e.target.value)});
        }
    }

    changeEditTierPriceCents(e:React.ChangeEvent<HTMLInputElement>) {
        if(e.target.value === "") {
            this.setState({editTierInitialPriceCents: 0});
            return;
        }

        if(!isNaN(parseInt(e.target.value))) {
            this.setState({editTierInitialPriceCents: parseInt(e.target.value)});
        }
    }

    changeNewTierPriceEuro(e:React.ChangeEvent<HTMLInputElement>) {
        if(e.target.value === "") {
            this.setState({newTierInitialPriceEuro: 0});
            return;
        }

        if(!isNaN(parseInt(e.target.value))) {
            this.setState({newTierInitialPriceEuro: parseInt(e.target.value)});
        }
    }

    changeMewTierPriceCents(e:React.ChangeEvent<HTMLInputElement>) {
        if(e.target.value === "") {
            this.setState({newTierInitialPriceCents: 0});
            return;
        }

        if(!isNaN(parseInt(e.target.value))) {
            this.setState({newTierInitialPriceCents: parseInt(e.target.value)});
        }
    }

    getTimeIntervalText(num:number,units:string) {
        let result:string = "";

        if (this.state.language === "de") {
            if(num === 1) {
                if(units === "months") {
                    return "Monat";
                }
                if(units === "weeks") {
                    return "Woche";
                }
                if(units === "days") {
                    return "Tag";
                }
            } else {
                if(units === "months") {
                    return num + " Monate";
                }
                if(units === "weeks") {
                    return num + " Wochen";
                }
                if(units === "days") {
                    return num + " Tage";
                }
            }
        } else {
            if(num === 1) {
                if(units === "months") {
                    return "month";
                }
                if(units === "weeks") {
                    return "week";
                }
                if(units === "days") {
                    return "day";
                }
            } else {
                if(units === "months") {
                    return num + " Months";
                }
                if(units === "weeks") {
                    return num + " Weeks";
                }
                if(units === "days") {
                    return num + " Days";
                }
            }
        }
    }

    getBillingPeriodExplanationNew() {
        let result:string = "";

        if(this.state.newTierBillingPeriodNum === 1) {
            result += this.getText("each");
        } else {
            result += this.getText("every");
        }
        result += " " + this.getTimeIntervalText(this.state.newTierBillingPeriodNum,this.state.newTierBillingPeriodUnits);

        return result;
    }

    getBillingPeriodExplanationEdit() {
        let result:string = "";

        if(this.state.editTierBillingPeriodNum === 1) {
            result += this.getText("each");
        } else {
            result += this.getText("every");
        }
        result += " " + this.getTimeIntervalText(this.state.editTierBillingPeriodNum,this.state.editTierBillingPeriodUnits);

        return result;
    }

    getPriceExplanationNew() {
        let result:string = this.getText("CurrentPrice") + ": ";

        let myprice:number = this.state.newTierPriceEuro * 100;
        myprice += this.state.newTierPriceCents;
        result += " " + (myprice / 100).toLocaleString(undefined,{minimumFractionDigits: 2}) + " € ";
        if(this.state.newTierBillingPeriodNum === 1) {
            if (this.state.language === "de" && this.state.newTierBillingPeriodUnits === "weeks") {
                result += "jede";
            } else {
                result += this.getText("each");
            }
        } else {
            result += this.getText("every");
        }
        result += " " + this.getTimeIntervalText(this.state.newTierBillingPeriodNum,this.state.newTierBillingPeriodUnits);

        return result;
    }

    getPriceExplanationEdit() {
        let result:string = this.getText("CurrentPrice") + ": ";

        let myprice:number = this.state.editTierPriceEuro * 100;
        myprice += this.state.editTierPriceCents;
        result += " " + (myprice / 100).toLocaleString(undefined,{minimumFractionDigits: 2}) + " € ";
        if(this.state.editTierBillingPeriodNum === 1) {
            if (this.state.language === "de" && this.state.editTierBillingPeriodUnits === "weeks") {
                result += "jede";
            } else {
                result += this.getText("each");
            }
        } else {
            result += this.getText("every");
        }
        result += " " + this.getTimeIntervalText(this.state.editTierBillingPeriodNum,this.state.editTierBillingPeriodUnits);

        return result;
    }

    getExplanationTextNew() {
        let result:string = "";
        
        if(this.state.newTierInitialRuntime > 0) {
            result = this.getText("MinRuntimeDescription");
            if(this.state.newTierInitialRuntime * this.state.newTierBillingPeriodNum === 1) {
                if (this.state.language === "de" && this.state.newTierBillingPeriodUnits === "weeks") {
                    result += " eine";
                } else {
                    result += " " + this.getText("one");
                }
            }
            result += " " + this.getTimeIntervalText(this.state.newTierInitialRuntime * this.state.newTierBillingPeriodNum,this.state.newTierBillingPeriodUnits);
            result += " " + this.getText("MinRuntimePriceDescription");
            let initialprice:number = this.state.newTierInitialPriceEuro * 100;
            initialprice += this.state.newTierInitialPriceCents;
            result += " " + (initialprice / 100).toLocaleString(undefined,{minimumFractionDigits: 2}) + " € ";
            if(this.state.newTierBillingPeriodNum === 1) {
                result += this.getText("each");
            } else {
                result += this.getText("every");
            }
            result += " " + this.getTimeIntervalText(this.state.newTierBillingPeriodNum,this.state.newTierBillingPeriodUnits);
            result += ".";

            if(initialprice === 0) {
                if (this.state.language === "de") {
                    result += "Das Abo ist also während der ersten";
                    result += " " + this.getTimeIntervalText(this.state.newTierInitialRuntime * this.state.newTierBillingPeriodNum,this.state.newTierBillingPeriodUnits);
                    result += " kostenlos.";
                } else {
                    result += "So the subscription is free during the first";
                    result += " " + this.getTimeIntervalText(this.state.newTierInitialRuntime * this.state.newTierBillingPeriodNum,this.state.newTierBillingPeriodUnits);
                    result += ".";
                }
            } else {
                if (this.state.language === "de") {
                    result += "Das Abo kostet also insgesamt ";
                    let initialTotalPrice:number = this.state.newTierInitialPriceEuro * 100;
                    initialTotalPrice += this.state.newTierInitialPriceCents;
                    initialTotalPrice *= this.state.newTierInitialRuntime;
                    result += " " + (initialTotalPrice / 100).toLocaleString(undefined,{minimumFractionDigits: 2}) + " € ";
                    result += " für die ersten";
                    result += " " + this.getTimeIntervalText(this.state.newTierInitialRuntime * this.state.newTierBillingPeriodNum,this.state.newTierBillingPeriodUnits);
                    result += ".";
                } else {
                    result += "So in total the subscription will be ";
                    let initialTotalPrice:number = this.state.newTierInitialPriceEuro * 100;
                    initialTotalPrice += this.state.newTierInitialPriceCents;
                    initialTotalPrice *= this.state.newTierInitialRuntime;
                    result += " " + (initialTotalPrice / 100).toLocaleString(undefined,{minimumFractionDigits: 2}) + " € ";
                    result += " for the first";
                    result += " " + this.getTimeIntervalText(this.state.newTierInitialRuntime * this.state.newTierBillingPeriodNum,this.state.newTierBillingPeriodUnits);
                    result += ".";
                }
            }

            result += this.getText("AfterMinRuntimeDescription");
        }

        result += this.getText("RegularPriceDescription");
        let myprice:number = this.state.newTierPriceEuro * 100;
        myprice += this.state.newTierPriceCents;
        result += " " + (myprice / 100).toLocaleString(undefined,{minimumFractionDigits: 2}) + " € ";
        if(this.state.newTierBillingPeriodNum === 1) {
            result += this.getText("each");
        } else {
            result += this.getText("every");
        }
        result += " " + this.getTimeIntervalText(this.state.newTierBillingPeriodNum,this.state.newTierBillingPeriodUnits);

        result += " " + this.getText("RegularCancelInfo");

        if(this.state.newTierInitialRuntime == 0) {
            result += " " + this.getText("ThereIsNoMinRuntime");
        }

        return result;
    }

    getExplanationTextEdit() {
        let result:string = "";
        
        if(this.state.editTierInitialRuntime > 0) {
            result = this.getText("MinRuntimeDescription");
            if(this.state.editTierInitialRuntime * this.state.editTierBillingPeriodNum === 1) {
                if (this.state.language === "de" && this.state.editTierBillingPeriodUnits === "weeks") {
                    result += " eine";
                } else {
                    result += " " + this.getText("one");
                }
            }
            result += " " + this.getTimeIntervalText(this.state.editTierInitialRuntime * this.state.editTierBillingPeriodNum,this.state.editTierBillingPeriodUnits);
            result += " " + this.getText("MinRuntimePriceDescription");
            let initialprice:number = this.state.editTierInitialPriceEuro * 100;
            initialprice += this.state.editTierInitialPriceCents;
            result += " " + (initialprice / 100).toLocaleString(undefined,{minimumFractionDigits: 2}) + " € ";
            if(this.state.editTierBillingPeriodNum === 1) {
                result += this.getText("each");
            } else {
                result += this.getText("every");
            }
            result += " " + this.getTimeIntervalText(this.state.editTierBillingPeriodNum,this.state.editTierBillingPeriodUnits);
            result += ".";

            if(initialprice === 0) {
                if (this.state.language === "de") {
                    result += "Das Abo ist also während der ersten";
                    result += " " + this.getTimeIntervalText(this.state.editTierInitialRuntime * this.state.editTierBillingPeriodNum,this.state.editTierBillingPeriodUnits);
                    result += " kostenlos.";
                } else {
                    result += "So the subscription is free during the first";
                    result += " " + this.getTimeIntervalText(this.state.editTierInitialRuntime * this.state.editTierBillingPeriodNum,this.state.editTierBillingPeriodUnits);
                    result += ".";
                }
            } else {
                if (this.state.language === "de") {
                    result += "Das Abo kostet also insgesamt ";
                    let initialTotalPrice:number = this.state.editTierInitialPriceEuro * 100;
                    initialTotalPrice += this.state.editTierInitialPriceCents;
                    initialTotalPrice *= this.state.editTierInitialRuntime;
                    result += " " + (initialTotalPrice / 100).toLocaleString(undefined,{minimumFractionDigits: 2}) + " € ";
                    result += " für die ersten";
                    result += " " + this.getTimeIntervalText(this.state.editTierInitialRuntime * this.state.editTierBillingPeriodNum,this.state.editTierBillingPeriodUnits);
                    result += ".";
                } else {
                    result += "So in total the subscription will be ";
                    let initialTotalPrice:number = this.state.editTierInitialPriceEuro * 100;
                    initialTotalPrice += this.state.editTierInitialPriceCents;
                    initialTotalPrice *= this.state.editTierInitialRuntime;
                    result += " " + (initialTotalPrice / 100).toLocaleString(undefined,{minimumFractionDigits: 2}) + " € ";
                    result += " for the first";
                    result += " " + this.getTimeIntervalText(this.state.editTierInitialRuntime * this.state.editTierBillingPeriodNum,this.state.editTierBillingPeriodUnits);
                    result += ".";
                }
            }

            result += this.getText("AfterMinRuntimeDescription");
        }

        result += this.getText("RegularPriceDescription");
        let myprice:number = this.state.editTierPriceEuro * 100;
        myprice += this.state.editTierPriceCents;
        result += " " + (myprice / 100).toLocaleString(undefined,{minimumFractionDigits: 2}) + " € ";
        if(this.state.editTierBillingPeriodNum === 1) {
            result += this.getText("each");
        } else {
            result += this.getText("every");
        }
        result += " " + this.getTimeIntervalText(this.state.editTierBillingPeriodNum,this.state.editTierBillingPeriodUnits);

        result += " " + this.getText("RegularCancelInfo");

        if(this.state.editTierInitialRuntime == 0) {
            result += " " + this.getText("ThereIsNoMinRuntime");
        }

        return result;
    }

    describeInitialRuntime(tier:SubscriptionTierData) {
        if(tier.initialRuntime > 1) {
            let result:string = this.getText("MinRuntime") + ":" + this.getTimeIntervalText(tier.initialRuntime * tier.billingPeriodNum,tier.billingPeriodUnits);
            result += ", " + this.getText("afterMinruntime") + " " + this.describePierDefaultPrice(tier);
            return result;
        } else {
            if(tier.billingPeriodUnits === "months" && tier.billingPeriodNum === 1) {
                return this.getText("MonthlyCancellable");
            }

            return this.getText("NoMinRuntime");
        }
    }

    describePierPrice(tier:SubscriptionTierData) {
        let result:string = "";
        
        let myprice:number = tier.price;

        if(tier.initialRuntime > 0) {
            myprice = tier.initialPrice;
        }

        result += " " + (myprice / 100).toLocaleString(undefined,{minimumFractionDigits: 2}) + " € ";
        if(tier.billingPeriodNum === 1) {
            result += this.getText("each");
        } else {
            result += this.getText("every");
        }
        result += " " + this.getTimeIntervalText(tier.billingPeriodNum,tier.billingPeriodUnits);

        return result;
    }

    describePierDefaultPrice(tier:SubscriptionTierData) {
        let result:string = "";
        
        let myprice:number = tier.price;

        result += " " + (myprice / 100).toLocaleString(undefined,{minimumFractionDigits: 2}) + " € ";
        if(tier.billingPeriodNum === 1) {
            result += this.getText("each");
        } else {
            result += this.getText("every");
        }
        result += " " + this.getTimeIntervalText(tier.billingPeriodNum,tier.billingPeriodUnits);

        return result;
    }

    getAboFunctionExplanationEdit() {
        if(this.state.editTierSubscriptionFunction === null) {
            return "";
        }
        if(this.state.editTierSubscriptionFunction === "WATCH_FOR_FREE") {
            return this.getText("WatchForFreeExplanation");
        }
        if(this.state.editTierSubscriptionFunction === "PERCENT") {
            if(this.state.editTierSubscriptionDiscount !== null) {
                return this.getText("WatchPercentExplanation").replace("PERCENT",this.state.editTierSubscriptionDiscount.toString());
            } else {
                return this.getText("WatchPercentExplanation").replace("PERCENT","0");
            }
        }
        if(this.state.editTierSubscriptionFunction === "ABS") {
            if(this.state.editTierSubscriptionDiscount !== null) {
                let mydiscount:number = this.state.editTierSubscriptionDiscount;
                let mydiscountstr:string = (mydiscount / 100).toLocaleString(undefined,{minimumFractionDigits: 2});
                return this.getText("WatchAbsExplanation").replace("ABS",mydiscountstr);
            } else {
                return this.getText("WatchAbsExplanation").replace("PERCENT","0");
            }
        }
    }

    getAboFunctionExplanationNew() {
        if(this.state.newTierSubscriptionFunction === "WATCH_FOR_FREE") {
            return this.getText("WatchForFreeExplanation");
        }
        if(this.state.newTierSubscriptionFunction === "PERCENT") {
            return this.getText("WatchPercentExplanation").replace("PERCENT",this.state.editTierSubscriptionDiscount.toString());
        }
        if(this.state.newTierSubscriptionFunction === "ABS") {
            let mydiscount:number = this.state.editTierSubscriptionDiscount;
            let mydiscountstr:string = (mydiscount / 100).toLocaleString(undefined,{minimumFractionDigits: 2});
            return this.getText("WatchAbsExplanation").replace("ABS",mydiscountstr);
        }
    }

    render() {
        if (this.context) {
            if (this.context.producerID === "") {
                return <Redirect to="/" push={true} />
            }
        } else {
            return <Redirect to="/" push={true} />
        }

        return (
            <div className='w-100'>
                <Modal isOpen={this.state.createTierGroupModal} size="lg" onClosed={this.createTierGroupModalClose.bind(this)}>
                    <ModalHeader close={<button className="close" onClick={this.createTierGroupModalClose.bind(this)}>×</button>}>
                        { this.getText("CreateTierGroup") }
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup className="mb-2">
                            <Label>{this.getText("Tier Name")}</Label>
                            <Input type="text" placeholder={this.getText("Name")} required value={this.state.groupDefaultName} onChange={this.handleGroupDefaultNameChange.bind(this)} />
                        </FormGroup>
                        <FormGroup className="mb-2">
                            <Label>{this.getText("Tier Description")}</Label>
                            <Input type="text" placeholder={this.getText("Description")} required value={this.state.groupDefaultDescription} onChange={this.handleGroupDefaultDescriptionChange.bind(this)} />
                        </FormGroup>
                        <FormGroup className="mb-2">
                            <Label>
                                {this.getText("Tier Price")} {this.getText("OnInitialRuntimeOf")} 1 {this.getText("Month")}
                            </Label>
                            <InputGroup>
                                <Input value={this.state.groupDefaultPriceEuro} onChange={this.handleGroupDefaultPriceEuroChange.bind(this)} type="tel" style={{textAlign: "right"}} />
                                <InputGroupText>
                                    <FontAwesomeIcon icon={solid('euro-sign')} size="sm" />
                                </InputGroupText>
                                <InputGroupText>
                                    ,
                                </InputGroupText>
                                <Input value={this.state.groupDefaultPriceCents} onChange={this.handleGroupDefaultPriceCentsChange.bind(this)} type="tel" style={{textAlign: "right"}} />
                                <InputGroupText>
                                    <FontAwesomeIcon icon={solid('cent-sign')} size="sm" />
                                </InputGroupText>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup className="mb-2">
                            <Label>
                                {this.getText("Tier Price")} {this.getText("OnInitialRuntimeOf")} 3 {this.getText("Months")}
                            </Label>
                            <InputGroup>
                                <Input value={this.state.groupDefaultPriceEuro3} onChange={this.handleGroupDefaultPriceEuro3Change.bind(this)} type="tel" style={{textAlign: "right"}} />
                                <InputGroupText>
                                    <FontAwesomeIcon icon={solid('euro-sign')} size="sm" />
                                </InputGroupText>
                                <InputGroupText>
                                    ,
                                </InputGroupText>
                                <Input value={this.state.groupDefaultPriceCents3} onChange={this.handleGroupDefaultPriceCents3Change.bind(this)} type="tel" style={{textAlign: "right"}} />
                                <InputGroupText>
                                    <FontAwesomeIcon icon={solid('cent-sign')} size="sm" />
                                </InputGroupText>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup className="mb-2">
                            <Label>
                                {this.getText("Tier Price")} {this.getText("OnInitialRuntimeOf")} 6 {this.getText("Months")}
                            </Label>
                            <InputGroup>
                                <Input value={this.state.groupDefaultPriceEuro6} onChange={this.handleGroupDefaultPriceEuro6Change.bind(this)} type="tel" style={{textAlign: "right"}} />
                                <InputGroupText>
                                    <FontAwesomeIcon icon={solid('euro-sign')} size="sm" />
                                </InputGroupText>
                                <InputGroupText>
                                    ,
                                </InputGroupText>
                                <Input value={this.state.groupDefaultPriceCents6} onChange={this.handleGroupDefaultPriceCents6Change.bind(this)} type="tel" style={{textAlign: "right"}} />
                                <InputGroupText>
                                    <FontAwesomeIcon icon={solid('cent-sign')} size="sm" />
                                </InputGroupText>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup className="mb-2">
                            <Label>
                                {this.getText("Tier Price")} {this.getText("OnInitialRuntimeOf")} 12 {this.getText("Months")}
                            </Label>
                            <InputGroup>
                                <Input value={this.state.groupDefaultPriceEuro12} onChange={this.handleGroupDefaultPriceEuro12Change.bind(this)} type="tel" style={{textAlign: "right"}} />
                                <InputGroupText>
                                    <FontAwesomeIcon icon={solid('euro-sign')} size="sm" />
                                </InputGroupText>
                                <InputGroupText>
                                    ,
                                </InputGroupText>
                                <Input value={this.state.groupDefaultPriceCents12} onChange={this.handleGroupDefaultPriceCents12Change.bind(this)} type="tel" style={{textAlign: "right"}} />
                                <InputGroupText>
                                    <FontAwesomeIcon icon={solid('cent-sign')} size="sm" />
                                </InputGroupText>
                            </InputGroup>
                        </FormGroup>
                        <div className='w-100 d-flex justify-content-end'>
                            <Button onClick={this.createTierGroupModalClose.bind(this)} color="secondary" type="button">{this.getText("Close")}</Button>&nbsp;
                            <Button onClick={this.createTierGroupModalSubmit.bind(this)} color="primary" type="submit">{this.getText("CreateGroup")}</Button>
                        </div>
                        <hr></hr>
                        <div className='container'>
                            <Row>
                                <Col xs={6} md={6}>
                                    <div className='aboGroupItem'>
                                        <h5>
                                            <span className="fs-5 fw-semibold">{this.state.groupDefaultName} 1 {this.getText("Month")}</span>
                                        </h5>
                                        <p className='aboGroupItemDescription'><small>{this.state.groupDefaultDescription}</small></p>
                                        <p>1 {this.getText("Month")}*</p>
                                        <p>
                                            {((this.state.groupDefaultPriceEuro * 100 + this.state.groupDefaultPriceCents) / 100).toLocaleString(undefined,{minimumFractionDigits: 2})} € {this.getText("per")} {this.getText("Month")}
                                        </p>
                                        <p className='aboGroupItemAsterisk'>*{this.getText("MonthlyCancellable")}</p>
                                    </div>
                                </Col>
                                <Col xs={6} md={6} >
                                    <div className='aboGroupItem'>
                                        <h5>
                                            <span className="fs-5 fw-semibold">{this.state.groupDefaultName} 3 {this.getText("Months")}</span>
                                        </h5>
                                        <p className='aboGroupItemDescription'><small>{this.state.groupDefaultDescription}</small></p>
                                        <p>3 {this.getText("Months")}*</p>
                                        <p>
                                            {((this.state.groupDefaultPriceEuro3 * 100 + this.state.groupDefaultPriceCents3) / 100).toLocaleString(undefined,{minimumFractionDigits: 2})} € {this.getText("per")} {this.getText("Month")}
                                        </p>
                                        <p className='aboGroupItemAsterisk'>*{this.getText("InitialRuntime")} 3 {this.getText("Months")}, {this.getText("afterMinruntime")} {((this.state.groupDefaultPriceEuro * 100 + this.state.groupDefaultPriceCents) / 100).toLocaleString(undefined,{minimumFractionDigits: 2})} € {this.getText("per")} {this.getText("Month")}</p>
                                    </div>
                                </Col>
                                <Col xs={6} md={6}>
                                    <div className='aboGroupItem'>
                                        <h5>
                                            <span className="fs-5 fw-semibold">{this.state.groupDefaultName} 6 {this.getText("Months")}</span>
                                        </h5>
                                        <p className='aboGroupItemDescription'><small>{this.state.groupDefaultDescription}</small></p>
                                        <p>6 {this.getText("Months")}*</p>
                                        <p>
                                            {((this.state.groupDefaultPriceEuro6 * 100 + this.state.groupDefaultPriceCents6) / 100).toLocaleString(undefined,{minimumFractionDigits: 2})} € {this.getText("per")} {this.getText("Month")}
                                        </p>
                                        <p className='aboGroupItemAsterisk'>*{this.getText("InitialRuntime")} 6 {this.getText("Months")}, {this.getText("afterMinruntime")} {((this.state.groupDefaultPriceEuro * 100 + this.state.groupDefaultPriceCents) / 100).toLocaleString(undefined,{minimumFractionDigits: 2})} € {this.getText("per")} {this.getText("Month")}</p>
                                    </div>
                                </Col>
                                <Col xs={6} md={6}>
                                    <div className='aboGroupItem'>
                                        <h5>
                                            <span className="fs-5 fw-semibold">{this.state.groupDefaultName} 12 {this.getText("Months")}</span>
                                        </h5>
                                        <p className='aboGroupItemDescription'><small>{this.state.groupDefaultDescription}</small></p>
                                        <p>12 {this.getText("Months")}*</p>
                                        <p>
                                            {((this.state.groupDefaultPriceEuro12 * 100 + this.state.groupDefaultPriceCents12) / 100).toLocaleString(undefined,{minimumFractionDigits: 2})} € {this.getText("per")} {this.getText("Month")}
                                        </p>
                                        <p className='aboGroupItemAsterisk'>*{this.getText("InitialRuntime")} 12 {this.getText("Months")}, {this.getText("afterMinruntime")} {((this.state.groupDefaultPriceEuro * 100 + this.state.groupDefaultPriceCents) / 100).toLocaleString(undefined,{minimumFractionDigits: 2})} € {this.getText("per")} {this.getText("Month")}</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </ModalBody>
                </Modal>
                <div id='SubscriptionTiersDIV' className='w-100 row'>
                    <div id="SubscriptionTiersListPane" className={this.state.selectedTier === null ? "nosubtierselected flex-shrink-0 p-3 col-12 col-lg-6" : "subtierselected flex-shrink-0 p-3 col-12 col-lg-6"}>
                        <h4 className='pageHeadline'>
                            <span className="fs-5 fw-semibold">{this.getText("SubscriptionTiers")}</span>
                        </h4>
                        { this.state.fetchingTiers ? <p className="pt-4"><FontAwesomeIcon icon={solid('cog')} spin size="4x" /></p> :
                        this.state.createTierMode ? null :
                        <ul className="list-group">
                            {this.state.tiers.map(tier =>
                                <li className={tier.id == this.state.selectedTier?.id ? "list-group-item subscriptionTier active" : "list-group-item userList"} onClick={this.clickTier.bind(this, tier.id)}>{tier.tierName} <span className="badge bg-secondary"><FontAwesomeIcon size="sm" icon={solid('user')}></FontAwesomeIcon> {this.findNumSubscribers(tier.id)}</span> <span className='badge bg-secondary'>{this.describePierPrice(tier)}</span> <FontAwesomeIcon onClick={this.deleteTierClicked.bind(this,tier.id)} icon={solid('remove')}></FontAwesomeIcon></li>
                            )}
                            { this.state.createTierMode ? null : 
                            <li className='list-group-item subscriptionTier' onClick={this.createNewTier.bind(this)}>{this.getText("Create new tier")} <span className="badge bg-secondary"><FontAwesomeIcon icon={solid('plus')}></FontAwesomeIcon></span></li> }
                            { this.state.createTierMode ? null : 
                            <li className='list-group-item subscriptionTier' onClick={this.createNewTierGroup.bind(this)}>{this.getText("Create new tier group")} <span className="badge bg-secondary"><FontAwesomeIcon icon={solid('plus')}></FontAwesomeIcon></span></li> }
                        </ul>
                        }
                        { this.state.fetchingSubscriberInfo ? <p className="pt-4"><FontAwesomeIcon icon={solid('cog')} spin size="2x" /></p> : null }
                        { this.state.createTierMode ? 
                        <Form onSubmit={this.handleCreateTierSubmit.bind(this)}>
                            <p><small>{this.getText("HowToCreateFreeAbo")}</small></p>
                            <FormGroup className="mb-2">
                                <Label>{this.getText("Tier Name")}</Label>
                                <Input type="text" placeholder={this.getText("Name")} required value={this.state.newTierName} onChange={this.handleNewTierNameChange.bind(this)} />
                            </FormGroup>
                            <FormGroup className="mb-2">
                                <Label>{this.getText("Tier Description")}</Label>
                                <Input type="textarea" placeholder={this.getText("Description")} required value={this.state.newTierDescription} onChange={this.handleNewTierDescriptionChange.bind(this)} />
                            </FormGroup>
                            <FormGroup className="mb-2">
                                <Label>
                                    {this.getText("Tier Price")}
                                    <span className='badge badge-secondary'>{this.getPriceExplanationNew()}</span>
                                </Label>
                                <InputGroup>
                                    <Input value={this.state.newTierPriceEuro} onChange={this.handleNewTierPriceEuroChange.bind(this)} type="tel" style={{textAlign: "right"}} />
                                    <InputGroupText>
                                        <FontAwesomeIcon icon={solid('euro-sign')} size="sm" />
                                    </InputGroupText>
                                    <InputGroupText>
                                        ,
                                    </InputGroupText>
                                    <Input value={this.state.newTierPriceCents} onChange={this.handleNewTierPriceCentsChange.bind(this)} type="tel" style={{textAlign: "right"}} />
                                    <InputGroupText>
                                        <FontAwesomeIcon icon={solid('cent-sign')} size="sm" />
                                    </InputGroupText>
                                </InputGroup>
                            </FormGroup>
                            <FormGroup className="mb-2">
                                <Label>
                                    {this.getText("Tier Billing Period")}&nbsp;
                                    <span className='badge badge-secondary'>{this.getPriceExplanationNew()}</span>
                                </Label>
                                <InputGroup>
                                    <Input type="tel" placeholder={this.getText("Billing Period")} value={this.state.newTierBillingPeriodNum} onChange={this.handleNewTierBillingPeriodChange.bind(this)} />
                                    <select size={1} className='form-select' onChange={this.handleNewTierBillingPeriodUnitsChange.bind(this)}>
                                        <option selected={this.state.newTierBillingPeriodUnits === "days" ? true : false} value="days">{this.getText("Days")}</option>
                                        <option selected={this.state.newTierBillingPeriodUnits === "weeks" ? true : false} value="weeks">{this.getText("Weeks")}</option>
                                        <option selected={this.state.newTierBillingPeriodUnits === "months" ? true : false} value="months">{this.getText("Months")}</option>
                                    </select>
                                </InputGroup>
                            </FormGroup>
                            <hr></hr>
                            <FormGroup className="mb-2">
                                { this.state.newTierSubscriptionFunction === 'PERCENT' || this.state.newTierSubscriptionFunction === 'ABS' ?
                                <Row>
                                    <Col>
                                        <Label>{this.getText("Tier Subscription Function")}</Label>
                                    </Col>
                                    <Col>
                                        <Label>{this.getText("Tier Subscription Function Number " + this.state.newTierSubscriptionFunction)}</Label>
                                    </Col>
                                </Row>
                                :
                                <Label>{this.getText("Tier Subscription Function")}</Label>
                                }
                                <InputGroup>
                                    <select size={1} className='form-select' onChange={this.handleNewTierSubscriptionFunctionChange.bind(this)}>
                                        <option selected={this.state.newTierSubscriptionFunction === "WATCH_FOR_FREE" ? true : false} value="WATCH_FOR_FREE">{this.getText("Posts are free to watch")}</option>
                                        <option selected={this.state.newTierSubscriptionFunction === "PERCENT" ? true : false} value="PERCENT">{this.getText("Posts are discounted by percent")}</option>
                                        <option selected={this.state.newTierSubscriptionFunction === "ABS" ? true : false} value="ABS">{this.getText("Posts are discounted by cents")}</option>
                                    </select>
                                    { this.state.newTierSubscriptionFunction === 'PERCENT' || this.state.newTierSubscriptionFunction === 'ABS' ?
                                    <Input type="tel" placeholder={this.getText("Discount")} value={this.state.newTierSubscriptionDiscount} onChange={this.handleNewTierSubscriptionDiscountChange.bind(this)} />
                                    : null }
                                </InputGroup>
                            </FormGroup>
                            <p><small>{this.getText("currently")}: {this.getAboFunctionExplanationNew()}</small></p>
                            <hr></hr>
                            <FormGroup className="mb-2">
                                <Label>{this.getText("InitialRuntime")}&nbsp;
                                    { this.state.newTierBillingPeriodNum === 1 ? 
                                    <small>({this.getText("NumberOf")} {this.state.newTierBillingPeriodUnits === "months" ? this.getText("Months") : this.state.newTierBillingPeriodUnits === "weeks" ? this.getText("Weeks") : this.getText("Days")} - {this.getText("currently")}: { this.state.newTierInitialRuntime === 0 ? this.getText("ThereIsNoMinRuntime") : <span>{this.state.newTierInitialRuntime} {this.state.newTierBillingPeriodUnits === "months" ? this.getText("Months") : this.state.newTierBillingPeriodUnits === "weeks" ? this.getText("Weeks") : this.getText("Days")}</span>})</small>
                                    : 
                                    <small>({this.getText("NumberOf")} {this.state.newTierBillingPeriodUnits === "months" ? this.getText("Months") : this.state.newTierBillingPeriodUnits === "weeks" ? this.getText("Weeks") : this.getText("Days")} x {this.state.newTierBillingPeriodNum} - {this.getText("currently")}: { this.state.newTierInitialRuntime === 0 ? this.getText("ThereIsNoMinRuntime") : <span>{this.state.newTierInitialRuntime} x {this.state.newTierBillingPeriodNum} = {this.state.newTierInitialRuntime * this.state.newTierBillingPeriodNum} {this.state.newTierBillingPeriodUnits === "months" ? this.getText("Months") : this.state.newTierBillingPeriodUnits === "weeks" ? this.getText("Weeks") : this.getText("Days")}</span>})</small>
                                    }
                                </Label>
                                <Input type="text" placeholder={this.getText("InitialRuntime")} required value={this.state.newTierInitialRuntime} onChange={this.handleNewTierInitialRuntimeChange.bind(this)} />
                            </FormGroup>
                            <FormGroup className="mb-2">
                                <Label>
                                    {this.getText("InitialPrice")}&nbsp;
                                    <small>({this.getBillingPeriodExplanationNew()})</small>
                                </Label>
                                <InputGroup>
                                    <Input value={this.state.newTierInitialPriceEuro} onChange={this.handleNewTierInitialPriceEuroChange.bind(this)} type="tel" style={{textAlign: "right"}} />
                                    <InputGroupText>
                                        <FontAwesomeIcon icon={solid('euro-sign')} size="sm" />
                                    </InputGroupText>
                                    <InputGroupText>
                                        ,
                                    </InputGroupText>
                                    <Input value={this.state.newTierInitialPriceCents} onChange={this.handleNewTierInitialPriceCentsChange.bind(this)} type="tel" style={{textAlign: "right"}} />
                                    <InputGroupText>
                                        <FontAwesomeIcon icon={solid('cent-sign')} size="sm" />
                                    </InputGroupText>
                                </InputGroup>
                            </FormGroup>
                            <p style={{whiteSpace: "break-spaces"}}>
                                <small>{this.getText("Erklärung")}: {this.getExplanationTextNew()}</small>
                            </p>
                            <div className='w-100 d-flex justify-content-end'>
                                <Button color="secondary" onClick={this.cancelCreateTier.bind(this)} disabled={this.state.createTierSubmitting} type="button">{this.getText("Cancel")}</Button>&nbsp;
                                <Button color="primary" disabled={this.state.createTierSubmitting} type="submit">{this.getText("Submit")}</Button>&nbsp;
                            </div>
                        </Form>
                        : null }
                    </div>
                    <div className="col-12 col-lg-6">
                        { this.state.selectedTier == null ? <Alert className='mt-4' color="secondary">{this.getText("NoSubscriptionTierSelected")}</Alert> : null }
                        { this.state.selectedTier == null ? 
                        <div className='container displaySub_containertrans'>
                            <h5 className='displaySub_extrahead'>{this.getText("CurrentSelection")}</h5>
                            <Row>
                                {this.state.tiers.map(tier =>
                                <Col xs={6} md={6}>
                                    <ul className='displaySub_wrapper'>
                                        <li className='displaySub_thelist'>
                                            <div onClick={this.clickTier.bind(this, tier.id)} className='displaySub_innerWrapper'>
                                                <h5 className='displaySub_header'>
                                                    <span className="fs-5 fw-semibold">{tier.tierName}</span>
                                                </h5>
                                                <p style={{whiteSpace: "pre-line"}}><small>{tier.tierDescription}</small></p>
                                                <p className='displaySub_priceline'>
                                                    {this.describePierPrice(tier)}
                                                </p>
                                                <p className='displaySub_kuendigung'>{this.describeInitialRuntime(tier)}</p>
                                            </div>
                                            <div className='displaySub_catoAc'>
                                                Abonnieren
                                            </div>
                                        </li>
                                    </ul>
                                </Col>)}
                            </Row>
                        </div>
                         :
                        <div id="SubscriptionTierPane" className="w-100 d-flex flex-column align-items-stretch flex-shrink-0">
                            <p className="d-flex align-items-center flex-shrink-0 p-3 link-dark text-decoration-none border-bottom">
                                <Button variant="secondary" size="sm" className='d-inline-block' onClick={this.unselectTier.bind(this)}><FontAwesomeIcon icon={solid('arrow-left')} fixedWidth /></Button>&nbsp;
                                <span className="fs-5 fw-semibold">{this.getText(this.state.selectedTier.tierName)}</span>
                            </p>
                            {this.state.selectedTier ? 
                            <div className='w-100'>
                                <table className='table table-sm w-100'>
                                    <tr>
                                        <td>{this.getText("SelectedTierName")}</td>
                                        <td>{this.state.selectedTier.tierName}</td>
                                    </tr>
                                    <tr>
                                        <td>{this.getText("SelectedTierDescription")}</td>
                                        <td style={{whiteSpace: "pre-line"}}>{this.state.selectedTier.tierDescription}</td>
                                    </tr>
                                    <tr>
                                        <td>{this.getText("SelectedTierPrice")}</td>
                                        <td>
                                            {(this.state.selectedTier.price / 100).toLocaleString(undefined,{minimumFractionDigits: 2})} €&nbsp;
                                            {this.state.selectedTier.billingPeriodNum === 1 ? this.getText("each") : this.getText("every")}&nbsp;
                                            {this.getTimeIntervalText(this.state.selectedTier.billingPeriodNum,this.state.selectedTier.billingPeriodUnits)}
                                        </td>
                                    </tr>
                                    {this.state.selectedTier.initialRuntime > 0 ?
                                    <tr>
                                        <td>{this.getText("InitialRuntime")}</td>
                                        <td>
                                            {this.getTimeIntervalText(this.state.selectedTier.initialRuntime * this.state.selectedTier.billingPeriodNum,this.state.selectedTier.billingPeriodUnits)}
                                        </td>
                                    </tr> : null }
                                    {this.state.selectedTier.initialRuntime > 0 ?
                                    <tr>
                                        <td>{this.getText("InitialRuntimePrice")}</td>
                                        <td>
                                            {(this.state.selectedTier.initialPrice / 100).toLocaleString(undefined,{minimumFractionDigits: 2})} €&nbsp;
                                            {this.state.selectedTier.billingPeriodNum === 1 ? this.getText("each") : this.getText("every")}&nbsp;
                                            {this.getTimeIntervalText(this.state.selectedTier.billingPeriodNum,this.state.selectedTier.billingPeriodUnits)}
                                        </td>
                                    </tr> : null }
                                    <tr>
                                        <td colSpan={2}>
                                            {this.getText("SelectedTierSubscriptionFunction")}:<br></br>
                                            {this.getText(this.state.selectedTier.subscriptionFunction)}{this.state.selectedTier.subscriptionDiscount > 0 ? " (" + this.state.selectedTier.subscriptionDiscount + ")" : null}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{this.getText("NumSubscribers")}</td>
                                        <td>{this.findNumSubscribers(this.state.selectedTier.id)}</td>
                                    </tr>
                                </table>
                                { this.state.editTierMode ? null : <p><Button onClick={this.initEditMode.bind(this)} size="sm" variant="secondary">{this.getText("Edit")}</Button></p> }
                                { this.state.showSubscriberList ? <p><Button onClick={this.hideSubscriberList.bind(this)} size="sm" variant="secondary">{this.getText("HideSubscribers")}</Button></p> : <p><Button onClick={this.fetchSubscriberList.bind(this,this.state.selectedTier.id)} size="sm" variant="secondary">{this.getText("FetchSubscribers")}</Button></p> }
                                { this.state.showSubscriberList && this.state.singleTierSubscriberList ?
                                    this.state.singleTierSubscriberList.length === 0 ? <p>{this.getText("NoSubscribers")}</p> :
                                    <div>
                                        <p>{this.getText("Subscribers")}</p>
                                        <table className='table table-sm table-striped'>
                                            {this.state.singleTierSubscriberList.map((subscriber:SubscriptionInfoJSON) => {
                                                return <tr key={subscriber.id}>
                                                    <td>{subscriber.userPseudo}</td>
                                                    <td>{this.getText(subscriber.subscriptionStatus)}</td>
                                                    <td>{this.getSubEndDesc(subscriber.subscriptionStatus,subscriber.nextBillingCycle)}</td>
                                                </tr>
                                            })}
                                        </table>
                                    </div>
                                    : null }
                                </div>
                                : null
                            }
                            { this.state.selectedTier != null ? <Alert className='mt-4' color="secondary">{this.getText("SubscriptionTierSelected")}</Alert> : null }
                            { this.state.errorMessage !== "" ? <Alert className='mt-4' color="danger">{this.state.errorMessage}</Alert> : null}
                            { this.state.successMessage !== "" ? <Alert className='mt-4' color="success">{this.state.successMessage}</Alert> : null}
                            { this.state.editTierMode ? 
                            <Form onSubmit={this.handleEditTierSubmit.bind(this)}>
                                <FormGroup className="mb-2">
                                    <Label>{this.getText("Tier Name")}</Label>
                                    <Input type="text" placeholder={this.getText("Name")} required value={this.state.editTierName} onChange={this.handleEditTierNameChange.bind(this)} />
                                </FormGroup>
                                <FormGroup className="mb-2">
                                    <Label>{this.getText("Tier Description")}</Label>
                                    <Input type="textarea" placeholder={this.getText("Description")} required value={this.state.editTierDescription} onChange={this.handleEditTierDescriptionChange.bind(this)} />
                                </FormGroup>
                                <FormGroup className="mb-2">
                                    <Label>
                                        {this.getText("Tier Price")}&nbsp;
                                        <span className="badge bg-secondary">{this.getPriceExplanationEdit()}</span>
                                    </Label>
                                    <InputGroup>
                                        <Input value={this.state.editTierPriceEuro} onChange={this.handleEditTierPriceEuroChange.bind(this)} type="tel" style={{textAlign: "right"}} />
                                        <InputGroupText>
                                            <FontAwesomeIcon icon={solid('euro-sign')} size="sm" />
                                        </InputGroupText>
                                        <InputGroupText>
                                            ,
                                        </InputGroupText>
                                        <Input value={this.state.editTierPriceCents} maxlength="2" onChange={this.handleEditTierPriceCentsChange.bind(this)} type="tel" style={{textAlign: "right"}} />
                                        <InputGroupText>
                                            <FontAwesomeIcon icon={solid('cent-sign')} size="sm" />
                                        </InputGroupText>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup className="mb-2">
                                    <Label>
                                        {this.getText("Tier Billing Period")}&nbsp;
                                        <span className="badge bg-secondary">{this.getPriceExplanationEdit()}</span>
                                    </Label>
                                    <InputGroup>
                                        <Input type="tel" placeholder={this.getText("Billing Period")} value={this.state.editTierBillingPeriodNum} onChange={this.handleEditTierBillingPeriodChange.bind(this)} />
                                        <select size={1} className='form-select' onChange={this.handleEditTierBillingPeriodUnitsChange.bind(this)}>
                                            <option selected={this.state.editTierBillingPeriodUnits === "days" ? true : false} value="days">{this.getText("Days")}</option>
                                            <option selected={this.state.editTierBillingPeriodUnits === "weeks" ? true : false} value="weeks">{this.getText("Weeks")}</option>
                                            <option selected={this.state.editTierBillingPeriodUnits === "months" ? true : false} value="months">{this.getText("Months")}</option>
                                        </select>
                                    </InputGroup>
                                </FormGroup>
                                <hr />
                                <FormGroup className="mb-2">
                                    { this.state.editTierSubscriptionFunction === 'PERCENT' || this.state.editTierSubscriptionFunction === 'ABS' ?
                                    <Row>
                                        <Col>
                                            <Label>{this.getText("Tier Subscription Function")}</Label>
                                        </Col>
                                        <Col>
                                            <Label>{this.getText("Tier Subscription Function Number " + this.state.editTierSubscriptionFunction)}</Label>
                                        </Col>
                                    </Row>
                                    :
                                    <Label>{this.getText("Tier Subscription Function")}</Label>
                                    }
                                    <InputGroup>
                                        <select size={1} className='form-select' onChange={this.handleEditTierSubscriptionFunctionChange.bind(this)}>
                                            <option selected={this.state.editTierSubscriptionFunction === "WATCH_FOR_FREE" ? true : false} value="WATCH_FOR_FREE">{this.getText("Posts are free to watch")}</option>
                                            <option selected={this.state.editTierSubscriptionFunction === "PERCENT" ? true : false} value="PERCENT">{this.getText("Posts are discounted by percent")}</option>
                                            <option selected={this.state.editTierSubscriptionFunction === "ABS" ? true : false} value="ABS">{this.getText("Posts are discounted by cents")}</option>
                                        </select>
                                        { this.state.editTierSubscriptionFunction === 'PERCENT' || this.state.editTierSubscriptionFunction === 'ABS' ?
                                        <Input type="tel" placeholder={this.getText("Discount")} value={this.state.editTierSubscriptionDiscount} onChange={this.handleEditTierSubscriptionDiscountChange.bind(this)} />
                                        : null }
                                    </InputGroup>
                                </FormGroup>
                                <p><small>{this.getText("currently")}: {this.getAboFunctionExplanationEdit()}</small></p>
                                <hr />
                                <FormGroup className="mb-2">
                                    <Label>
                                        {this.getText("InitialRuntime")}
                                        { this.state.editTierBillingPeriodNum === 1 ? 
                                        <small>({this.getText("NumberOf")} {this.state.editTierBillingPeriodUnits === "months" ? this.getText("Months") : this.state.editTierBillingPeriodUnits === "weeks" ? this.getText("Weeks") : this.getText("Days")} - {this.getText("currently")}: { this.state.editTierInitialRuntime === 0 ? this.getText("ThereIsNoMinRuntime") : <span>{this.state.editTierInitialRuntime} {this.state.editTierBillingPeriodUnits === "months" ? this.getText("Months") : this.state.editTierBillingPeriodUnits === "weeks" ? this.getText("Weeks") : this.getText("Days")}</span>})</small>
                                        : 
                                        <small>({this.getText("NumberOf")} {this.state.editTierBillingPeriodUnits === "months" ? this.getText("Months") : this.state.editTierBillingPeriodUnits === "weeks" ? this.getText("Weeks") : this.getText("Days")} x {this.state.editTierBillingPeriodNum} - {this.getText("currently")}: { this.state.editTierInitialRuntime === 0 ? this.getText("ThereIsNoMinRuntime") : <span>{this.state.editTierInitialRuntime} x {this.state.editTierBillingPeriodNum} = {this.state.editTierInitialRuntime * this.state.editTierBillingPeriodNum} {this.state.editTierBillingPeriodUnits === "months" ? this.getText("Months") : this.state.editTierBillingPeriodUnits === "weeks" ? this.getText("Weeks") : this.getText("Days")}</span>})</small>
                                        }
                                    </Label>
                                    <Input type="text" placeholder={this.getText("InitialRuntime")} required value={this.state.editTierInitialRuntime} onChange={this.handleEditTierInitialRuntimeChange.bind(this)} />
                                </FormGroup>
                                <FormGroup className="mb-2">
                                    <Label>{this.getText("InitialPrice")}&nbsp;
                                    <small>({this.getBillingPeriodExplanationEdit()})</small>
                                    </Label>
                                    <InputGroup>
                                        <Input value={this.state.editTierInitialPriceEuro} onChange={this.handleEditTierInitialPriceEuroChange.bind(this)} type="tel" style={{textAlign: "right"}} />
                                        <InputGroupText>
                                            <FontAwesomeIcon icon={solid('euro-sign')} size="sm" />
                                        </InputGroupText>
                                        <InputGroupText>
                                            ,
                                        </InputGroupText>
                                        <Input value={this.state.editTierInitialPriceCents} maxlength="2" onChange={this.handleEditTierInitialPriceCentsChange.bind(this)} type="tel" style={{textAlign: "right"}} />
                                        <InputGroupText>
                                            <FontAwesomeIcon icon={solid('cent-sign')} size="sm" />
                                        </InputGroupText>
                                    </InputGroup>
                                </FormGroup>
                                <p style={{whiteSpace: "break-spaces"}}>
                                    <small>{this.getText("Erklärung")}: {this.getExplanationTextEdit()}</small>
                                </p>
                                <div className='w-100 d-flex justify-content-end'>
                                    <Button color="secondary" onClick={this.cancelEditMode.bind(this)} disabled={this.state.editTierSubmitting} type="button">{this.getText("Cancel")}</Button>&nbsp;
                                    <Button color="primary" disabled={this.state.editTierSubmitting} type="submit">{this.getText("Submit")}</Button>&nbsp;
                                </div>
                            </Form>
                            : null }
                        </div> }
                    </div>
                </div>
            </div>
        )
    }
}