import React, { Component, FormEvent } from 'react';
import { Alert, Container, Form, FormGroup, Button, Label, Input, ButtonGroup, Dropdown, DropdownMenu, DropdownItem } from 'reactstrap';
import { LocalStorageWorker } from '../StorageHelper';
import { CryptoHelper } from '../CryptoHelper.js';
import { AppCtx, AppContextInterface, ApiHelper } from '../AppContextInterface';
import { Redirect } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DateTime } from 'luxon';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-US';

interface EventsProps {

}

export interface Events extends React.Component {
    state: EventsState;
    props: EventsProps;
    contextType: AppContextInterface;
}

interface EventsState {
    errorMessage: string;
    language: string;
    events: EventRecord[];
    fetchingEvents: boolean;
    currentFilter: string;
    redirectTarget: string;
    startDate: Date;
}

interface EventRecord {
    id: number;
    timestamp: Date;
    category: string;
    msgtext: string;
    streamname: string;
    imageID: string;
    userID: string;
    userPseudo: string;
    credits: number;
    extraParam1: string;
    extraParam2: string;
    extraParam3: string;
}

interface MessageAttachment {
    attachmentID: string;
    attachmentType: string;
    attachmentFileName: string;
    s3Key: string;
    s3Bucket: string;
    isBought: boolean;
    price: number;
}

export class Events extends React.Component {
    static contextType = AppCtx;
    context!: React.ContextType<typeof AppCtx>;

    constructor(props: EventsProps) {
        super(props);

        let contextLanguage = "de";

        if (this.context) {
            if (this.context.language !== null && this.context.language !== "") {
                contextLanguage = this.context.language;
            }
        }

        let nowDate:Date = new Date();
        let startDate:Date = new Date(nowDate.getFullYear(), nowDate.getMonth(), 1, 0, 0, 0, 0);

        this.state = {
            errorMessage: "",
            fetchingEvents: false,
            language: contextLanguage,
            events: [],
            currentFilter: "Events",
            redirectTarget: "",
            startDate: startDate
        }
    }

    componentDidMount() {
        this.fetchEvents(this.state.currentFilter);
        this.updateUnseenEvents();
    }

    updateUnseenEvents() {
        if (this.context) {
            if(this.context.producerID !== "" && this.context.authToken !== "") {
                var jsonurl = ApiHelper.getAPIUrl(this.context) + "/unseenevents?producerid=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken) + "&role=producer&userID=none";

                fetch(jsonurl, {method: "PUT"}).then(async(response: any) => {
                    let data = await response.json();
        
                    if(typeof(data.title) !== "undefined") {
                        console.error("Problem putting unseen events: " + data.title);
                        return;
                    }
        
                    this.setState({unseenEvents: data.numEvents});
                }).catch((error: any) => {
                    console.error("Error putting unseen events: " + error.toString());
                });
            } else {
                console.error("putting unseen events not logged in");
            }
        } else {
            console.error("putting unseen events no context");
        }
    }

    getEventText(event: EventRecord) {
        if (this.state.language === "de") {
            if (event.category === "TimelineBuy") {
                return "User " + event.userPseudo + " hat Deinen Post für " + event.credits + " gekauft";
            }
            if (event.category === "MessageBuy") {
                return "User " + event.userPseudo + " hat Deine Nachricht für " + event.credits + " gekauft";
            }
            if (event.category === "ImageReview") {
                return "Bild überprüft";
            }
            if (event.category === "favadd") {
                return "User " + event.userPseudo + " hat Dich als Favorit hinzugefügt";
            }
            if (event.category === "favremove") {
                return "User " + event.userPseudo + " hat Dich als Favorit entfernt";
            }
        } else {
            if (event.category === "TimelineBuy") {
                return "User " + event.userPseudo + " has bought your post for " + event.credits + " credits";
            }
            if (event.category === "MessageBuy") {
                return "User " + event.userPseudo + " has bought your message for " + event.credits + " credits";
            }
            if (event.category === "message") {
                return "Message";
            }
            if (event.category === "ImageReview") {
                return "Image reviewed";
            }
            if (event.category === "favadd") {
                return "User " + event.userPseudo + " has added you as a favorite";
            }
            if (event.category === "favremove") {
                return "User " + event.userPseudo + " has removed you as a favorite";
            }
        }

        return event.msgtext;
    }

    fetchEvents = async (filter:string) => {
        this.setState({ fetchingEvents: true });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/events";

        if (this.context) {
            if(!this.context.loggedin) {
                await ApiHelper.checkLoginStatus(this.context);
            }
            jsonurl += "?producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        } else {
            jsonurl += "?producerID=missingcontext";
        }
        jsonurl += "&filter=" + encodeURIComponent(filter);
        jsonurl += "&startTime=" + encodeURIComponent(DateTime.fromISO(this.state.startDate.toISOString()).toFormat("MM-dd-yyyy"));
        let newDate:Date;
        if(this.state.startDate.getMonth() == 11) {
            newDate = new Date(this.state.startDate.getFullYear() + 1, 0, 1, 0, 0, 0, 0);
        } else {
            newDate = new Date(this.state.startDate.getFullYear(), this.state.startDate.getMonth() + 1, 1, 0, 0, 0, 0);
        }
        jsonurl += "&endTime=" + encodeURIComponent(DateTime.fromISO(newDate.toISOString()).toFormat("MM-dd-yyyy"));

        let response: any = null;

        try {
            response = await fetch(jsonurl);
            let data = await response.json();
            this.setState({ fetchingEvents: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                    if(!ApiHelper.checkResponse(data,this.context) && this.context !== null) {
                        this.context.producerID = "";
                        alert("Please relogin");
                    }
                }
            }

            if (data.events) {
                this.setState({ events: data.events });
            }
        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching data: " + error.toString() });
            this.setState({ fetchingEvents: false });
        }
    }

    eventClick(event:EventRecord):void {
        if (event.category === "message") {
            this.setState({ redirectTarget: "/messages" });
        }
        if (event.category === "ImageReview") {
            this.setState({ redirectTarget: "/images" });
        }
        if (event.category === "favadd") {
            this.setState({ redirectTarget: "/userlists" });
        }
        if (event.category === "favremove") {
            this.setState({ redirectTarget: "/userlists" });
        }
    }

    getText(exp: string) {
        if (this.state.language === "de") {
            if (exp === "Month") {
                return "Monat";
            }
            if(exp === "TimelineLike" || exp === "timelinelike") {
                return "Post geliked";
            }
            if(exp === "TimelineUnlike" || exp === "timelineunlike") {
                return "Post like entzogen";
            }
            if (exp === "TimelineBuy" || exp === "timelinebuy") {
                return "Post gekauft";
            }
            if (exp === "tip") {
                return "Trinkgeld";
            }
            if (exp === "timeline") {
                return "Timline";
            }
            if (exp === "MessageBuy") {
                return "Nachricht gekauft";
            }
            if (exp === "message") {
                return "Nachricht";
            }
            if (exp === "ImageReview") {
                return "Bild überprüft";
            }
            if (exp === "favadd") {
                return "Favorit hinzugefügt";
            }
            if (exp === "favremove") {
                return "Favorit entfernt";
            }
            if (exp === "Events") {
                return "Ereignisse";
            }
            if (exp === "EventsDescription") {
                return "Hier erhältst Du eine Übersicht über alle Ereignisse, die für Dich relevant sind. Zum Beispiel wenn Kunden Deine Nachrichten oder Posts gekauft haben oder wenn Sie Dich als Favorit hinzufügen.";
            }
        } else {
            if(exp === "TimelineLike" || exp === "timelinelike") {
                return "Post liked";
            }
            if(exp === "TimelineUnlike" || exp === "timelineunlike") {
                return "Post like removed";
            }
            if (exp === "TimelineBuy" || exp === "timelinebuy") {
                return "Post bought";
            }
            if (exp === "tip") {
                return "Tip";
            }
            if (exp === "TimelineBuy") {
                return "Post bought";
            }
            if (exp === "MessageBuy") {
                return "Message bought";
            }
            if (exp === "timeline") {
                return "Timline";
            }
            if (exp === "message") {
                return "Message";
            }
            if (exp === "ImageReview") {
                return "Image reviewed";
            }
            if (exp === "favadd") {
                return "Favorite added";
            }
            if (exp === "favremove") {
                return "Favorite removed";
            }
            if (exp === "EventsDescription") {
                return "Here you get an overview of all events that are relevant to you. For example, if customers have bought your messages or posts or if they add you as a favorite."
            }
        }

        return exp;
    }

    changeDate(newDate:Date) {
        this.setState({startDate: newDate},this.fetchEvents.bind(this,this.state.currentFilter));
    }

    render() {
        if (this.context) {
            if (this.context.producerID === "") {
                return <Redirect to="/" push={true} />
            }
        } else {
            return <Redirect to="/" push={true} />
        }

        if(this.state.redirectTarget !== "") {
            return <Redirect to={this.state.redirectTarget} push={true} />
        }

        return (
            <div id='EventsDIV' className='d-flex flex-nowrap'>
                <div id="EventsMessagesPane" className="d-flex flex-column align-items-stretch flex-shrink-0 bg-white w-100">
                    <h4 className="pageHeadline">{this.getText("Events")}</h4>
                    <div className="mb-4"><small>{this.getText("EventsDescription")}</small></div>
                    <p>
                        { this.state.startDate !== null ? this.getText("Month") + " : " + DateTime.fromISO(this.state.startDate.toISOString())?.toLocaleString(DateTime.DATE_MED)?.replace("1. ","") : null }
                        <DatePicker selected={ this.state.startDate } dateFormat="MM/yyyy" showMonthDropdown showYearDropdown showMonthYearPicker dropdownMode="select" locale={this.state.language === 'de' ? de : en} onChange={this.changeDate.bind(this)} />
                    </p>
                    {this.state.errorMessage !== "" ? <Alert color="danger">{this.state.errorMessage}</Alert> : null}
                    {this.state.fetchingEvents ? <p className="pt-4"><FontAwesomeIcon icon={solid('cog')} spin size="4x" /></p> :
                        <ul className="list-group list-group-flush border-bottom scrollarea">
                            {this.state.events.map(event =>
                                <div className="list-group-item list-group-item-action py-3 lh-sm">
                                    <div className="d-flex w-100 align-items-center justify-content-between flex-wrap">
                                        <strong className="mb-1">{this.getText(event.category)}</strong>
                                        <small>{DateTime.fromISO(event.timestamp.toString()).toLocaleString(DateTime.DATETIME_MED)}</small>
                                    </div>
                                    <div className="col-10 mb-1 small" onClick={this.eventClick.bind(this,event)} style={{cursor: "pointer"}}>{this.getEventText(event)}</div>
                                </div>
                            )}
                        </ul>
                    }
                </div>
            </div>
        )
    }
}