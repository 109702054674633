import React, { Component } from 'react';
import { AppCtx, AppContextInterface, ApiHelper } from '../AppContextInterface';
import { Card, Row, Col, Alert, Modal, ModalHeader, ModalBody, ModalFooter, Container, Form, FormGroup, Button, Label, Input, ButtonGroup, Dropdown, DropdownMenu, DropdownItem,NavLink, CardHeader, CardBody, CardFooter } from 'reactstrap';
import { LocalStorageWorker } from '../StorageHelper';
import { Link } from 'react-router-dom';
import { ImageList,ImageData } from './ImageList';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

interface ImagesPageState {
    infoMessage: string;
    errorMessage: string;
    language: string;
    selectedImage: ImageData|null;
    selectedImageURL: string;
    fetching: boolean;
    additionalPeople: string;
    additionalPeopleConfirm: boolean;
    additionalPeopleReviewStatus: number;
    additionalPeopleReviewComment: string;
    tempGuid: string;
    singleImageOperation: boolean;
}

interface ImagesPageProps {

}

export interface ImagesPage extends React.Component {
    state: ImagesPageState;
    props: ImagesPageProps;
    contextType: AppContextInterface;
}

export class ImagesPage extends Component {
    static contextType = AppCtx;
    context!: React.ContextType<typeof AppCtx>;

    constructor(props: ImagesPageProps) { 
        super(props);
        let contextLanguage = "de";
        if (this.context) {
            if (this.context.language !== null && this.context.language !== "") {
                contextLanguage = this.context.language;
            }
        }

        this.state = {
            infoMessage: "",
            errorMessage: "",
            language: contextLanguage,
            selectedImage: null,
            selectedImageURL: "",
            fetching: false,
            additionalPeople: "",
            additionalPeopleConfirm: false,
            additionalPeopleReviewStatus: 0,
            additionalPeopleReviewComment: "",
            tempGuid: "",
            singleImageOperation: false
        }
    }

    componentDidMount(): void {
        let myLocalStorage:LocalStorageWorker = new LocalStorageWorker();

        if(myLocalStorage.get("lastpage") !== "images") {
            myLocalStorage.add("lastpage","images");
        }
    }

    getText(exp: string) {
        if (this.state.language === "de") {
            if (exp === "Submit") {return "Speichern";}
            if (exp === "Approved") {return "Freigeschaltet";}
            if (exp === "Rejected") {return "Abgelehnt";}
            if (exp === "Unknown") {return "Unbekannt";}
            if (exp === "Not reviewed") {return "Noch nicht geprüft";}
            if (exp === "Images") {return "Bilder";}
            if (exp === "Image of") {return "Bild von";}
            if (exp === "AdditionalPeopleConfirmLabel") {return "Ich bestätige, dass ich die Personen gefragt habe";}
            if (exp === "AdditionalPeopleSubmit") {return "Zusätzliche Personen einreichen";}
            if (exp === "AdditionalPeopleLabel") {return "Trage hier die Namen von anderen Personen ein (mit Komma getrennt), wenn welche auf dem Bild zu sehen sind";}
            if (exp === "AdditionalPeopleSubmitted") {return "Zusätzliche Personen eingetragen";}
            if (exp === "ImagesPageInfo") {
                return "Hier siehst Du eine Liste der von Dir hochgeladenen Bilder, die Du in Posts oder als Profilbild verwenden kannst. Bitte beachte, dass, wenn zusätzliche Personen auf dem Bild zu sehen sind, Du den Namen der Personen eintragen musst und eine Bestätigung, dass deren Einverständnis für eine Verwendung und Veröffentlichung der Bilder vorliegt. Beim Upload kannst Du die Bidler auch 'croppen', also einen Teil der Bilder ausschneiden.";
            }
        } else {
            if (exp === "Submit") {return "Save";}
            if (exp === "AdditionalPeopleConfirmLabel") {
                return "I confirm that I have asked the people on the picture";
            }
            if (exp === "AdditionalPeopleSubmit") {
                return "Submit additional people";
            }
            if (exp === "AdditionalPeopleLabel")
            {
                return "Enter the names of additional people if they are visible on the picture";
            }
            if (exp === "AdditionalPeopleSubmitted")
            {
                return "Additional people submitted";
            }
            if (exp === "ImagesPageInfo") {
                return "Here you can see a list of images you have uploaded, which you can use in posts or as profile picture. Please note that if additional people are visible on the picture, you have to enter their names and confirm that their consent for the use and publication of the images is given.";
            }
        }

        return exp;
    }

    imageClicked(img:ImageData) {
        this.fetchSingleImageInfo(img);
    }

    confirmSelection(images:ImageData[]) {
        // do nothing
    }

    fetchSingleImageInfo = async(img:ImageData) => {
        if (img !== null) {
            this.setState({ fetching: true });
            this.setState({ infoMessage: "" });
            this.setState({ errorMessage: "" });
            this.setState({selectedImage:img});

            var jsonurl = ApiHelper.getAPIUrl(this.context) + "/image/get/" + encodeURIComponent(img.imageID);

            jsonurl += "?role=producer";

            if (this.context) {
                jsonurl += "&producerOrUserID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
            }

            jsonurl += "&dimension=" + encodeURIComponent("600x600xinside");

            let response: any = null;

            try {
                response = await fetch(jsonurl);
                let data = await response.json();
                this.setState({ fetching: false });

                if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                    this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
                } else {
                    if (data.error !== "" && data.error !== null) {
                        this.setState({ errorMessage: "Error: " + data.error });
                        if(!ApiHelper.checkResponse(data,this.context) && this.context !== null) {
                            this.context.producerID = "";
                            alert("Please relogin");
                        }
                    }
                }

                if (data.image) {
                    this.setState({ selectedImageURL: data.image.imageURL });
                    this.setState({ additionalPeopleReviewStatus: data.image.additionalPeopleReviewStatus });
                    this.setState({ additionalPeopleReviewComment: data.image.additionalPeopleReviewComment });
                }
            } catch (error: any) {
                this.setState({ errorMessage: "Error fetching data: " + error.toString() });
                this.setState({ fetching: false });
            }
        }
    }

    submitAdditionalPeople = async() => {
        if (this.state.selectedImage !== null) {
            this.setState({ fetching: true });
            this.setState({ infoMessage: "" });
            this.setState({ errorMessage: "" });

            var jsonurl = ApiHelper.getAPIUrl(this.context) + "/image/additionalPeople/";

            jsonurl += "?imageID=" + encodeURIComponent(this.state.selectedImage.imageID);

            if (this.context) {
                jsonurl += "&producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
            }

            jsonurl += "&people=" + encodeURIComponent(this.state.additionalPeople);

            let response: any = null;

            try {
                response = await fetch(jsonurl,{method: "POST"});
                let data = await response.json();
                this.setState({ fetching: false });

                if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                    this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
                } else {
                    if (data.error !== "" && data.error !== null) {
                        this.setState({ errorMessage: "Error: " + data.error });
                        if(!ApiHelper.checkResponse(data,this.context) && this.context !== null) {
                            this.context.producerID = "";
                            alert("Please relogin");
                        }
                    }
                }

                if (data.success) {
                    this.setState({ infoMessage: this.getText("AdditionalPeopleSubmitted") });
                }
            } catch (error: any) {
                this.setState({ errorMessage: "Error fetching data: " + error.toString() });
                this.setState({ fetching: false });
            }
        }
    }

    additionalPeopleChange(event: any) {
        this.setState({additionalPeople: event.target.value});
    }

    additionalPeopleConfirmChange(event: any) {
        this.setState({additionalPeopleConfirm: event.target.checked});
    }

    singleModalClosed() {
        this.setState({selectedImage:null});
    }

    doRotate = async(rotation:string) => {
        if(this.state.selectedImage === null) {
            return;
        }

        this.setState({ singleImageOperation: true });
        this.setState({ infoMessage: "" });
        this.setState({ errorMessage: "" });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/image/Rotate/";

        jsonurl += encodeURIComponent(this.state.selectedImage.imageID);

        if (this.context) {
            jsonurl += "?producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        }

        jsonurl += "&rotate=" + encodeURIComponent(rotation);

        let response: any = null;

        try {
            response = await fetch(jsonurl,{method: "GET"});
            let data = await response.json();
            this.setState({ singleImageOperation: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
                return;
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                    if(!ApiHelper.checkResponse(data,this.context) && this.context !== null) {
                        this.context.producerID = "";
                        alert("Please relogin");
                        return;
                    }
                }
            }

            if ((data.error === null || data.error === "") && data.tempGuid !== "") {
                this.setState({ tempGuid: data.tempGuid });

                let newImageURL:string = ApiHelper.getAPIUrl(this.context) + "/image/Fetch/";

                newImageURL += encodeURIComponent(this.state.selectedImage.imageID);

                if (this.context) {
                    newImageURL += "?producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
                }
        
                newImageURL += "&tempGuid=" + encodeURIComponent(data.tempGuid);

                this.setState({ selectedImageURL: newImageURL });
            } else {
                this.setState({ errorMessage: data.error });
            }
        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching data: " + error.toString() });
            this.setState({ singleImageOperation: false });
        }
    }

    doImageReset = async() => {
        if(this.state.selectedImage === null) {
            return;
        }

        this.setState({ singleImageOperation: true });
        this.setState({ infoMessage: "" });
        this.setState({ errorMessage: "" });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/image/Reset/";

        jsonurl += encodeURIComponent(this.state.selectedImage.imageID);

        if (this.context) {
            jsonurl += "?producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        }

        let response: any = null;

        try {
            response = await fetch(jsonurl,{method: "GET"});
            let data = await response.json();
            this.setState({ singleImageOperation: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
                return;
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                    if(!ApiHelper.checkResponse(data,this.context) && this.context !== null) {
                        this.context.producerID = "";
                        alert("Please relogin");
                        return;
                    }
                }
            }

            if ((data.error === null || data.error === "") && data.tempGuid !== "") {
                this.setState({ tempGuid: data.tempGuid });

                let newImageURL:string = ApiHelper.getAPIUrl(this.context) + "/image/Fetch/";

                newImageURL += encodeURIComponent(this.state.selectedImage.imageID);

                if (this.context) {
                    newImageURL += "?producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
                }
        
                newImageURL += "&tempGuid=" + encodeURIComponent(data.tempGuid);

                this.setState({ selectedImageURL: newImageURL });
            } else {
                this.setState({ errorMessage: data.error });
            }
        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching data: " + error.toString() });
            this.setState({ singleImageOperation: false });
        }
    }

    doRemoveExif = async() => {
        if(this.state.selectedImage === null) {
            return;
        }

        this.setState({ singleImageOperation: true });
        this.setState({ infoMessage: "" });
        this.setState({ errorMessage: "" });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/image/RemoveEXIF/";

        jsonurl += encodeURIComponent(this.state.selectedImage.imageID);

        if (this.context) {
            jsonurl += "?producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        }

        let response: any = null;

        try {
            response = await fetch(jsonurl,{method: "GET"});
            let data = await response.json();
            this.setState({ singleImageOperation: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
                return;
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                    if(!ApiHelper.checkResponse(data,this.context) && this.context !== null) {
                        this.context.producerID = "";
                        alert("Please relogin");
                        return;
                    }
                }
            }

            if ((data.error === null || data.error === "") && data.tempGuid !== "") {
                this.setState({ tempGuid: data.tempGuid });

                let newImageURL:string = ApiHelper.getAPIUrl(this.context) + "/image/Fetch/";

                newImageURL += encodeURIComponent(this.state.selectedImage.imageID);

                if (this.context) {
                    newImageURL += "?producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
                }
        
                newImageURL += "&tempGuid=" + encodeURIComponent(data.tempGuid);

                this.setState({ selectedImageURL: newImageURL });
            } else {
                this.setState({ errorMessage: data.error });
            }
        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching data: " + error.toString() });
            this.setState({ singleImageOperation: false });
        }
    }

    resetImage() {
        this.setState({tempGuid:""});
        this.doImageReset();
    }

    removeExif() {
        this.setState({tempGuid:""});
        this.doRemoveExif();
    }

    imageRotate0() {
        this.setState({tempGuid:""});
        this.doRotate("0");
    }

    imageRotateLeft() {
        this.setState({tempGuid:""});
        this.doRotate("Left");
    }

    imageRotateRight() {
        this.setState({tempGuid:""});
        this.doRotate("Right");
    }

    imageRotate180() {
        this.setState({tempGuid:""});
        this.doRotate("180");
    }

    imageModalSave = async() => {
        if (this.state.tempGuid !== "") {
            if(this.state.selectedImage === null) {
                return;
            }
    
            this.setState({ singleImageOperation: true });
            this.setState({ infoMessage: "" });
            this.setState({ errorMessage: "" });
    
            var jsonurl = ApiHelper.getAPIUrl(this.context) + "/image/Save/";
    
            jsonurl += encodeURIComponent(this.state.selectedImage.imageID);
    
            if (this.context) {
                jsonurl += "?producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
            }
    
            jsonurl += "&tempGuid=" + encodeURIComponent(this.state.tempGuid);
    
            let response: any = null;
    
            try {
                response = await fetch(jsonurl,{method: "GET"});
                let data = await response.json();
                this.setState({ singleImageOperation: false });
    
                if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                    this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
                    return;
                } else {
                    if (data.error !== "" && data.error !== null) {
                        this.setState({ errorMessage: "Error: " + data.error });
                        if(!ApiHelper.checkResponse(data,this.context) && this.context !== null) {
                            this.context.producerID = "";
                            alert("Please relogin");
                            return;
                        }
                    }
                }
    
                if (data.error === null || data.error === "") {
                    if (data.new240x240url !== null) {
                        console.log("New image url: " + data.new240x240url);
                        console.log("Setting new image url: " + data.new240x240url + " for imageID: " + this.state.selectedImage.imageID);
                        let img = document.getElementById("img-" + this.state.selectedImage.imageID);
                        if(img !== null) {
                            console.log("Setting new image url: " + data.new240x240url + " for imageID: " + this.state.selectedImage.imageID);
                            img.setAttribute("src", data.new240x240url);
                        } else {
                            console.log("Image not found: " + this.state.selectedImage.imageID);
                        }
                    }
                    this.setState({ tempGuid: "" });
                    this.singleModalClosed();
                } else {
                    this.setState({ errorMessage: data.error });
                }
            } catch (error: any) {
                this.setState({ errorMessage: "Error fetching data: " + error.toString() });
                this.setState({ singleImageOperation: false });
            }
        }
    }

    getReviewStatusText(reviewStatus: number) {
        switch (reviewStatus) {
            case 0:
                return this.getText("Not reviewed");
            case 1:
                return this.getText("Approved");
            case 2:
                return this.getText("Rejected");
            default:
                return this.getText("Unknown");
        }
    }

    uploaded() {

    }

    render () {
        return (
            <div className='mt-4'>
                { this.state.selectedImage !== null ?
                <Modal isOpen={true} size="lg" onClosed={this.singleModalClosed.bind(this)}>
                    <ModalHeader close={<button className="close" onClick={this.singleModalClosed.bind(this)}>×</button>}>
                        {this.getText("Image of")} { DateTime.fromISO(this.state.selectedImage.uploadTime.toString()).toLocaleString(DateTime.DATETIME_MED) } - { this.getReviewStatusText(this.state.selectedImage.reviewStatus) }
                    </ModalHeader>
                    <ModalBody>
                        <Alert isOpen={this.state.infoMessage !== ""} color="info">{this.state.infoMessage}</Alert>
                        <Alert isOpen={this.state.errorMessage !== ""} color="danger">{this.state.errorMessage}</Alert>
                        {this.state.fetching || this.state.singleImageOperation ? <p className='pt-4' style={{color: '#000'}}><FontAwesomeIcon icon={solid('cog')} size="4x" spin></FontAwesomeIcon></p> : <img src={this.state.selectedImageURL} className="w-100" />}
                        <FormGroup className='mb-2'>
                            <Label for="additionalPeople">{this.getText("AdditionalPeopleLabel")}</Label>
                            <Input type="text" className='form-control' name="additionalPeople" id="additionalPeople" value={this.state.additionalPeople} onChange={this.additionalPeopleChange.bind(this)} />
                        </FormGroup>
                        <FormGroup className='mb-2'>
                            <Row>
                                <Col xs="1"><Input type="checkbox" className='form-control' style={{height: "24px"}} name="additionalPeopleConfirm" id="additionalPeopleConfirm" checked={this.state.additionalPeopleConfirm} onChange={this.additionalPeopleConfirmChange.bind(this)} /></Col>
                                <Col xs="6"><Label for="additionalPeopleConfirm">{this.getText("AdditionalPeopleConfirmLabel")}</Label></Col>
                                <Col xs="5" style={{textAlign: "right"}}><Button className='d-inline-block' color='secondary' onClick={this.submitAdditionalPeople.bind(this)}>{this.getText("AdditionalPeopleSubmit")}</Button></Col>
                            </Row>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.singleImageOperation} color="secondary" onClick={this.resetImage.bind(this)}>{this.getText("Reset")}</Button>
                        <Button disabled={this.state.singleImageOperation} color="secondary" onClick={this.imageRotateLeft.bind(this)}><FontAwesomeIcon icon={solid('rotate-left')} /></Button>
                        <Button disabled={this.state.singleImageOperation} color="secondary" onClick={this.imageRotateRight.bind(this)}><FontAwesomeIcon icon={solid('rotate-right')} /></Button>
                        <Button disabled={this.state.singleImageOperation} color="secondary" onClick={this.imageRotate180.bind(this)}><FontAwesomeIcon icon={solid('rotate')} /></Button>
                        { this.state.tempGuid !== "" ? <Button disabled={this.state.singleImageOperation} color="primary" onClick={this.imageModalSave.bind(this)}>{this.getText("Save")}</Button> : null }
                        <Button color="secondary" onClick={this.singleModalClosed.bind(this)}>{this.getText("Close")}</Button>
                    </ModalFooter>
                </Modal> : null }
                <div>
                    <h4 className='pageHeadline'>
                        {this.getText("Images")}
                    </h4>
                    <Alert color="secondary"><small>{this.getText("ImagesPageInfo")}</small></Alert>
                    <Alert isOpen={this.state.infoMessage !== ""} color="info">{this.state.infoMessage}</Alert>
                    <Alert isOpen={this.state.errorMessage !== ""} color="danger">{this.state.errorMessage}</Alert>
                    <ImageList singleSelect={false} onImageClick={this.imageClicked.bind(this)} confirmSelection={this.confirmSelection.bind(this)} modalMode={false} mediaAddMode="" />
                </div>
            </div>
        );
    }
}
