import React, { Component, FormEvent } from 'react';
import { Alert, Container, Form, FormGroup, Button, Label, Input, InputGroup, InputGroupText, Badge, ButtonToolbar, ButtonGroup, Modal, ModalHeader, ModalBody, ModalFooter, Dropdown, DropdownMenu, DropdownItem } from 'reactstrap';
import { LocalStorageWorker } from '../StorageHelper';
import { CryptoHelper } from '../CryptoHelper.js';
import { AppCtx, AppContextInterface, ApiHelper } from '../AppContextInterface';
import { Redirect } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DateTime } from 'luxon';
import { VideoModal } from './VideoModal';
import { ImageModal } from './ImageModal';
import { MessagingPost,MessagingPostMediaData } from './Timeline';
import { MediaData,BuyMediaData } from './Inbox';

interface MessengerWriteProps {
    onMessageSent(): void;
    onCancel(): void;
    conversationID: string;
    replyToMsgID: string;
    userPseudo: string;
    targetListID: string;
}

export interface MessengerWrite extends React.Component {
    state: MessengerWriteState;
    props: MessengerWriteProps;
    contextType: AppContextInterface;
    propsChangeTimer: NodeJS.Timer;
}

interface MsgPost {
    msg: string,
    receiverPseudo: string,
    conversationID: string,
    freeMedia: MessagingPostMediaData[],
    payMedia: MessagingPostMediaData[],
    price: number
}

interface MessengerWriteState {
    errorMessage: string;
    fetching: boolean;
    language: string;
    selectedUserPseudo: string;
    conversationID: string;
    replyToMsgID: string;
    msgText: string;
    freeMedia: MessagingPostMediaData[];
    payMedia: MessagingPostMediaData[];
    sendingMessage: boolean;
    mediaAddMode: string;
    imageModal: boolean;
    videoModal: boolean;
    audioModal: boolean;
    price: number;
    priceModal: boolean;
    clickedMediaData: MessagingPostMediaData|null;
    priceInputEuro: number;
    priceInputCents: number;
    smallPreviewDimension: string;
    mediumPreviewDimension: string;
    singleImagePreviewDimension: string;
    clickedMediaDeletable: boolean;
    targetListID: string;
    groupScheduleModal: boolean;
    groupSchedule: false;
    groupScheduleDays: number;
    platform: string;
}

interface MessengerConversation {
    conversationID: string;
    userPseudo: string;
    unreadFromUser: number;
    lastSendFromUser: Date;
    unreadFromGirl: number;
}

interface MessengerMail {
    msgid: string;
    sendTime: Date;
    clickTime: Date;
    content: string;
    sender: string;
    receiver: string;
    msgtext: string;
    freeMedia: MediaData[];
    payMedia: BuyMediaData[];
    price: number;
}

interface MessageAttachment {
    attachmentID: string;
    attachmentType: string;
    attachmentFileName: string;
    s3Key: string;
    s3Bucket: string;
    isBought: boolean;
    price: number;
}

export class MessengerWrite extends React.Component {
    static contextType = AppCtx;
    context!: React.ContextType<typeof AppCtx>;

    constructor(props: MessengerWriteProps) {
        super(props);

        let contextLanguage = "de";

        if (this.context) {
            if (this.context.language !== null && this.context.language !== "") {
                contextLanguage = this.context.language;
            }
        }

        let myplatform:string = ApiHelper.getPlatform();

        if(this.context) {
            myplatform = this.context.platform;
        }

        this.state = {
            errorMessage: "",
            fetching: false,
            language: contextLanguage,
            selectedUserPseudo: props.userPseudo,
            conversationID: props.conversationID,
            replyToMsgID: props.replyToMsgID,
            msgText: "",
            freeMedia: [],
            payMedia: [],
            sendingMessage: false,
            mediaAddMode: "",
            imageModal: false,
            videoModal: false,
            audioModal: false,
            clickedMediaData: null,
            price: 0,
            priceModal: false,
            priceInputEuro: 0,
            priceInputCents: 0,
            smallPreviewDimension: "80x80xinside",
            mediumPreviewDimension: "300x300xinside",
            singleImagePreviewDimension: "480x480xinside",
            clickedMediaDeletable: false,
            targetListID: props.targetListID,
            groupScheduleModal: false,
            groupSchedule: false,
            groupScheduleDays: 90,
            platform: myplatform
        }

        this.propsChangeTimer = setInterval(this.checkPropsChange.bind(this),1000);
    }

    componentWillUnmount(): void {
        clearInterval(this.propsChangeTimer);
    }

    checkPropsChange() {
        if(this.props.conversationID !== this.state.conversationID || this.props.targetListID !== this.state.targetListID) {
            this.setState({
                conversationID: this.props.conversationID,
                selectedUserPseudo: this.props.userPseudo,
                replyToMsgID: this.props.replyToMsgID,
                targetListID: this.props.targetListID
            });
        }
    }

    getText(exp: string) {
        if (this.state.language === "de") {
            if(exp === "Sending") {
                return "Wird gesendet...";
            }
            if(exp === "GroupSchedule") {
                return "Nachricht an Gruppe planen";
            }
            if(exp === "SendThisToNewUsers") {
                return "Diese Nachricht innerhalb der nächsten x Tage auch an Nutzer senden, die neu in diese Gruppe kommen.";
            }
            if(exp === "Days") {
                return "Tage";
            }
            if(exp === "PriceSubmit") {
                return "Preis festlegen";
            }
            if(exp === "Eigene Gruppe") {
                return "Eigene Gruppe";
            }
            if(exp === "Username") {
                return "Pseudo";
            }
            if(exp === "ConfirmDelete") {
                return "Ja, Nachricht löschen";
            }
            if(exp === "to") {
                return "an";
            }
            if(exp === "Submit") {
                return "Nachricht senden";
            }
            if(exp === "Cancel") {
                return "Abbrechen";
            }
            if(exp === "Price") {
                return "Preis";
            }
            if(exp === "RedBorderedImages") {
                return "Rot umrandete Bilder/Videos müssen noch freigeschaltet werden, bevor sie den Usern angezeigt werden.";
            }
            if(exp === "MessengerDescription") {
                return "Der Messenger bietet Dir eine Übersicht über Deine Unterhaltungen mit einzelnen Usern, Du kannst auch kostenpflichtige Inhalte in Nachrichten versenden. Wenn Du lieber eine Übersicht über alle Nachrichten haben möchtest, dann nutze bitte die Funktion 'Posteingang' im Menü.";
            }
            if(exp === "OrangeOutlinedItemsHaveToBeReviewed") {
                return "(orange umrandete Inhalte müssen noch freigeschaltet werden, bevor sie angezeigt werden)";
            }
            if(exp === "MessengerPriceModalTitle") {
                return "Preis für Nachrichtenversand von Usern";
            }
            if(exp === "MessengerPriceDescription") {
                return "Hier kannst du den Preis festlegen, den User für den Versand von Nachrichten an Dich zahlen müssen.";
            }
            if(exp === "CalculatedPrices") {
                return "Preise für einzelne Abo-Level";
            }
            if(exp === "EditSubscriptionTiers") {
                return "Abo-Level bearbeiten";
            }
            if(exp === "DefaultPrice") {
                return "Standardpreis für Nicht-Abonnenten (und falls nichts anderes festgelegt wurde)";
            }
            if(exp === "DefaultPriceUsers") {
                return "Standardpreis für Nicht-Abonnenten";
            }
        } else {
            if(exp === "Sending") {
                return "Sending...";
            }
            if(exp === "GroupSchedule") {
                return "Schedule message to group";
            }
            if(exp === "SendThisToNewUsers") {
                return "Send this message to users that join this group in the next x days.";
            }
            if(exp === "Days") {
                return "Days";
            }
            if(exp === "MessengerPriceModalTitle") {
                return "Price for users for sending messages";
            }
            if(exp === "MessengerPriceDescription") {
                return "Here you can set the price that users have to pay to send you messages.";
            }
            if(exp === "DefaultPriceUsers") {
                return "Default price for non-subscribers";
            }
            if(exp === "DefaultPrice") {
                return "Default price for non-subscribers (and if nothing else is set)";
            }
            if(exp === "CalculatedPrices") {
                return "Calculated prices for individual subscription tiers";
            }
            if(exp === "EditSubscriptionTiers") {
                return "Edit subscription tiers";
            }
            if(exp === "PriceSubmit") {
                return "Set price";
            }
            if(exp === "Eigene Gruppe") {
                return "Own group";
            }
            if (exp === "Username") {
                return "Livecam Profile";
            }
            if(exp === "ConfirmDelete") {
                return "Yes, delete message";
            }
            if(exp === "Wirklich Nachricht löschen?") {
                return "Really delete message?";
            }
            if(exp === "Preis festlegen") {
                return "Set price";
            }
            if(exp === "Preis") {
                return "Price";
            }
            if(exp === "Anhänge") {
                return "Attachments";
            }
            if(exp === "OrangeOutlinedItemsHaveToBeReviewed") {
                return "(orange outlined items have to be reviewed, before they will be shown)";
            }
            if(exp === "Kostenlose Inhalte") {
                return "Free content";
            }
            if(exp === "Kostenpflichtige Inhalte") {
                return "Pay content";
            }
            if(exp === "RedBorderedImages") {
                return "Red bordered images/videos have to be reviewed, before users will be able to see them.";
            }
            if(exp === "MessengerDescription") {
                return "The messenger offers you an overview of your conversations with individual users, you can also send paid content in messages. If you would rather have an overview of all messages, please use the function 'Inbox' in the menu."
            }
        }

        return exp;
    }

    handleSubmit(event: FormEvent) {
        event.preventDefault();
        this.formSubmitted();
    }

    formatPrice(price:number) {
        return Math.floor(price / 100) + "," + (price % 100).toString().padStart(2, "0");
    }

    changeGroupSchedule(e:React.ChangeEvent<HTMLInputElement>) {
        this.setState({groupSchedule: e.target.checked});
    }

    changeGroupScheduleDays(e:React.ChangeEvent<HTMLInputElement>) {
        if(!isNaN(parseInt(e.target.value))) {
            this.setState({groupScheduleDays: parseInt(e.target.value)});
        }
    }

    changePriceInputEuro(e:React.ChangeEvent<HTMLInputElement>) {
        if(!isNaN(parseInt(e.target.value))) {
            this.setState({priceInputEuro: parseInt(e.target.value)});
        }
    }

    changePriceInputCents(e:React.ChangeEvent<HTMLInputElement>) {
        if(!isNaN(parseInt(e.target.value))) {
            this.setState({priceInputCents: parseInt(e.target.value)});
        }
    }

    formSubmitted() {
        let myLocalStorage = new LocalStorageWorker();

        if (this.context === null) {
            return;
        }

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/messengergirl?producerid=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);

        jsonurl += "&receiver=" + encodeURIComponent(this.state.selectedUserPseudo);
        jsonurl += "&platform=" + encodeURIComponent(this.state.platform);

        if(this.state.replyToMsgID !== "") {
            jsonurl += "&replyToMsgID=" + encodeURIComponent(this.state.replyToMsgID);
        } else {
            jsonurl += "&conversationID=" + encodeURIComponent(this.state.conversationID);
            if(this.state.targetListID) {
                jsonurl += "&listID=" + encodeURIComponent(this.state.targetListID);
            }
            if(this.state.groupSchedule) {
                jsonurl += "&groupSchedule=1";
                jsonurl += "&groupScheduleDays=" + encodeURIComponent(this.state.groupScheduleDays);
            } else {
                jsonurl += "&groupSchedule=0";
            }
        }

        this.setState({ sendingMessage: true });

        let postObject:MessagingPost = {
            msgText: this.state.msgText,
            msgTitle: "",
            payText: "",
            price: this.state.price,
            tags: "",
            pinned: false,
            nonsub: false,
            freeMedia: this.state.freeMedia,
            payMedia: this.state.payMedia,
            quiz: null,
            poll: null,
            fundraise: null,
            publishTime: null,
            priceOverrides: null,
        }

        fetch(jsonurl, {method: "POST",body: JSON.stringify(postObject), headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}}).then(async(response: any) => {
            let data = await response.json();

            if(typeof(data.title) !== "undefined") {
                this.setState({ errorMessage: "Problem sending message: " + data.title });
                this.setState({ msgActionInProgress: false });
                return;
            }

            if (data.result === "ok") {
                this.setState({ sendingMessage: false });
                this.resetWriteArea();
                this.props.onMessageSent();
            } else {
                this.setState({ errorMessage: "Problem sending message: " + data.error });
                this.setState({ sendingMessage: false });
            }
        }).catch((error: any) => {
            this.setState({ errorMessage: "Error sending message: " + error.toString() });
            this.setState({ sendingMessage: false });
        });
    }

    resetWriteArea() {
        this.setState({ msgText: "" });
        this.setState({ freeMedia: [] });
        this.setState({ payMedia: [] });
        this.setState({ imageModal: false});
        this.setState({ videoModal: false});
        this.setState({ audioModal: false});
        this.setState({ clickedMediaData: null});
        this.setState({ price: 0});
        this.setState({ priceModal: false});
        this.setState({ priceInputEuro: 0});
        this.setState({ priceInputCents: 0});
        this.setState({ groupSchedule: false});
        this.setState({ groupScheduleDays: 90});
        this.setState({ groupScheduleModal: false});
    }

    handleMessageChange(e: any) {
        this.setState({ msgText: e.target.value });
    }

    countFreeMediaImages() {
        return this.state.freeMedia.filter((item:MessagingPostMediaData) => { return item.type === "image" }).length;
    }

    countFreeMediaVideos() {
        return this.state.freeMedia.filter((item:MessagingPostMediaData) => { return item.type === "video" }).length;
    }

    countFreeMediaAudios() {
        return this.state.freeMedia.filter((item:MessagingPostMediaData) => { return item.type === "audio" }).length;
    }

    countPayMediaImages() {
        return this.state.payMedia.filter((item:MessagingPostMediaData) => { return item.type === "audio" }).length;
    }

    countPayMediaVideos() {
        return this.state.payMedia.filter((item:MessagingPostMediaData) => { return item.type === "audio" }).length;
    }

    countPayMediaAudios() {
        return this.state.payMedia.filter((item:MessagingPostMediaData) => { return item.type === "audio" }).length;
    }

    getSmallMediaPreviewUrl(item:MessagingPostMediaData) {
        return "https://placehold.co/80";
    }

    getMediumMediaPreviewUrl(item:MessagingPostMediaData) {
        return "https://placehold.co/200";
    }

    getSingleImagePreviewUrl(item:MessagingPostMediaData) {
        return "https://placehold.co/300";
    }

    fetchRealImageURLs() {
        let producerID:string = "";
        let authToken:string = "";

        if(this.context) {
            producerID = this.context.producerID;
            authToken = this.context.authToken;
        }

        document.querySelectorAll('img[src="https://placehold.co/80"]').forEach((item: any) => {
            let myURL:string = ApiHelper.getAPIUrl(this.context) + "/" + item.getAttribute("x-mediatype") + "/get/" + item.getAttribute("x-mediaid") + "?role=producer&producerOrUserID=" + encodeURIComponent(producerID) + "&authToken=" + encodeURIComponent(authToken) + "&dimension=" + this.state.smallPreviewDimension;

            fetch(myURL).then(async(response: any) => {
                let data = await response.json();

                if(data.image) {
                    item.src = data.image.imageURL;
                    if(data.image.reviewStatus === 1) {
                        item.classList.add("reviewed");
                    } else if(data.image.reviewStatus === -1) {
                        item.classList.add("rejected");
                    } else {
                        item.classList.add("inreview");
                    }
                }
            }).catch(function() {
                item.src = "https://placehold.co/80?Text=Error";
            });
        });

        document.querySelectorAll('img[src="https://placehold.co/200"]').forEach((item: any) => {
            let myURL:string = ApiHelper.getAPIUrl(this.context) + "/" + item.getAttribute("x-mediatype") + "/get/" + item.getAttribute("x-mediaid") + "?role=producer&producerOrUserID=" + encodeURIComponent(producerID) + "&authToken=" + encodeURIComponent(authToken) + "&dimension=" + this.state.mediumPreviewDimension;

            fetch(myURL).then(async(response: any) => {
                let data = await response.json();

                if(data.image) {
                    item.src = data.image.imageURL;
                    if(data.image.reviewStatus === 1) {
                        item.classList.add("reviewed");
                    } else if(data.image.reviewStatus === -1) {
                        item.classList.add("rejected");
                    } else {
                        item.classList.add("inreview");
                    }
                }
            }).catch(function() {
                item.src = "https://placehold.co/200?Text=Error";
            });
        });

        document.querySelectorAll('img[src="https://placehold.co/300"]').forEach((item: any) => {
            let myURL:string = ApiHelper.getAPIUrl(this.context) + "/" + item.getAttribute("x-mediatype") + "/get/" + item.getAttribute("x-mediaid") + "?role=producer&producerOrUserID=" + encodeURIComponent(producerID) + "&authToken=" + encodeURIComponent(authToken) + "&dimension=" + this.state.singleImagePreviewDimension;

            fetch(myURL).then(async(response: any) => {
                let data = await response.json();

                if(data.image) {
                    item.src = data.image.imageURL;
                    if(data.image.reviewStatus === 1) {
                        item.classList.add("reviewed");
                    } else if(data.image.reviewStatus === -1) {
                        item.classList.add("rejected");
                    } else {
                        item.classList.add("inreview");
                    }
                }
            }).catch(function() {
                item.src = "https://placehold.co/300?Text=Error";
            });
        });
    }

    getPayMediaDescriptionText() {
        if(this.context?.language == "de") {
            return "Zu diesem Preis können User die kostenpflichtigen Bilder und Videos sehen, die an diese Nachricht angehängt sind. Der Text ist kostenlos lesbar, ebenso können kostenlose Bilder und Videos angehangen werden. Die Buttons mit dem Euro-Zeichen diesen dazu kostenpflichtige Bilder und Videos hinzuzufügen, die mit dem Geschenk sind für kostenlose Medieninhalte.";
        } else {
            return "For this price, users can see the paid images and videos attached to this message. The text is free to read, as well as free images and videos can be attached. The buttons with the Euro sign add paid images and videos, the buttons with the gift sign add free media.";
        }
    }

    imageModalClose() {
        this.setState({imageModal: false});
    }

    videoModalClose() {
        this.setState({videoModal: false});
    }

    imageModalSelect(imageData:MessagingPostMediaData) {
        this.setState({imageModal: false});
        if(this.state.mediaAddMode == "free") {
            console.log("Adding free media: " + JSON.stringify(imageData));
            this.setState({freeMedia: [...this.state.freeMedia,imageData]},this.fetchRealImageURLs.bind(this));
            setTimeout(this.fetchRealImageURLs.bind(this),1000);
        } else {
            console.log("Adding pay media: " + JSON.stringify(imageData));
            this.setState({payMedia: [...this.state.payMedia,imageData]},this.fetchRealImageURLs.bind(this));
            setTimeout(this.fetchRealImageURLs.bind(this),1000);
        }
    }

    imageModalSelectMulti(images:MessagingPostMediaData[]) {
        if(images) {
            if(this.state.mediaAddMode == "free") {
                console.log("Adding free media array: " + JSON.stringify(images));
                this.setState({freeMedia: [...this.state.freeMedia,...images]},this.fetchRealImageURLs.bind(this));
                setTimeout(this.fetchRealImageURLs.bind(this),1000);
            } else {
                console.log("Adding pay media array: " + JSON.stringify(images));
                this.setState({payMedia: [...this.state.payMedia,...images]},this.fetchRealImageURLs.bind(this));
                setTimeout(this.fetchRealImageURLs.bind(this),1000);
            }
        }
    }

    videoModalSelect(videoData:MessagingPostMediaData) {
        this.setState({videoModal: false});
        if(this.state.mediaAddMode == "free") {
            console.log("Adding free media: " + JSON.stringify(videoData));
            this.setState({freeMedia: [...this.state.freeMedia,videoData]},this.fetchRealImageURLs.bind(this));
            setTimeout(this.fetchRealImageURLs.bind(this),1000);
        } else {
            console.log("Adding pay media: " + JSON.stringify(videoData));
            this.setState({payMedia: [...this.state.payMedia,videoData]},this.fetchRealImageURLs.bind(this));
            setTimeout(this.fetchRealImageURLs.bind(this),1000);
        }
    }

    videoModalSelectMulti(videoData:MessagingPostMediaData[]) {
        if(this.state.mediaAddMode == "free") {
            console.log("Adding free media array: " + JSON.stringify(videoData));
            this.setState({freeMedia: [...this.state.freeMedia,...videoData]},this.closeVideoModal.bind(this));
        } else {
            console.log("Adding pay media array: " + JSON.stringify(videoData));
            this.setState({payMedia: [...this.state.payMedia,...videoData]},this.closeVideoModal.bind(this));
        }
    }

    closeVideoModal() {
        this.setState({videoModal: false});
        this.fetchRealImageURLs();
        setTimeout(this.fetchRealImageURLs.bind(this),1000);
    }

    toggleGroupScheduleModal() {
        this.setState({groupScheduleModal: !this.state.groupScheduleModal});
    }

    freeMediaClicked(clickedMediaData:MessagingPostMediaData,deletable:boolean) {
        if(clickedMediaData) {
            this.setState({mediaAddMode: "free"});
            this.setState({clickedMediaData: clickedMediaData},this.fetchRealImageURLs.bind(this));
            this.setState({clickedMediaDeletable: deletable});
            setTimeout(this.fetchRealImageURLs.bind(this),1000);
        } else {
            this.setState({errorMessage: "Invalid image clicked"});
        }
    }

    payMediaClicked(clickedMediaData:MessagingPostMediaData,deletable:boolean) {
        if(clickedMediaData) {
            this.setState({mediaAddMode: "pay"});
            this.setState({clickedMediaData: clickedMediaData},this.fetchRealImageURLs.bind(this));
            this.setState({clickedMediaDeletable: deletable});
            setTimeout(this.fetchRealImageURLs.bind(this),1000);
        } else {
            this.setState({errorMessage: "Invalid image clicked"});
        }
    }

    changeClickedMediaDataToPay() {
        let myImages:MessagingPostMediaData[];
        myImages = this.state.freeMedia.filter((img:MessagingPostMediaData) => { return img.mediaID !== this.state.clickedMediaData?.mediaID });
        this.setState({freeMedia: myImages});
        this.setState({payMedia: [...this.state.payMedia,this.state.clickedMediaData]});
        this.setState({mediaAddMode: "pay"});
    }

    changeClickedMediaDataToFree() {
        let myImages:MessagingPostMediaData[];
        myImages = this.state.payMedia.filter((img:MessagingPostMediaData) => { return img.mediaID !== this.state.clickedMediaData?.mediaID });
        this.setState({payMedia: myImages});
        this.setState({payMedia: [...this.state.freeMedia,this.state.clickedMediaData]});
        this.setState({mediaAddMode: "free"});
    }

    closeClickedMediaData() {
        this.setState({clickedMediaData: null});
    }

    deleteClickedMediaData() {
        if(this.state.clickedMediaData !== null) {
            let myImages:MessagingPostMediaData[];
            if(this.state.mediaAddMode == "free") {
                myImages = this.state.freeMedia.filter((img:MessagingPostMediaData) => { return img.mediaID !== this.state.clickedMediaData?.mediaID });
                this.setState({freeMedia: myImages});
            } else {
                myImages = this.state.payMedia.filter((img:MessagingPostMediaData) => { return img.mediaID !== this.state.clickedMediaData?.mediaID });
                this.setState({payMedia: myImages});
            }
            this.setState({clickedMediaData: null});
        }
    }

    clickedMediaDataModalClose() {
        this.setState({clickedMediaData: null});
    }

    clickedGroupScheduleModalClose() {
        this.setState({groupScheduleModal: false});
    }

    handlePriceSubmit(e:FormEvent) {
        e.preventDefault();
        let newprice:number = (this.state.priceInputEuro * 100) + this.state.priceInputCents;
        this.setState({ price: newprice});
        this.setState({priceModal: false});
    }

    priceModalClose() {
        this.setState({priceModal: false});
    }

    clickPriceButton() {
        this.setState({ priceInputEuro: Math.floor(this.state.price / 100) });
        this.setState({ priceInputCents: Math.floor(this.state.price % 100) });
        this.setState({ priceModal: !this.state.priceModal });
    }

    clickImageButton() {
        this.setState({ mediaAddMode: "free" });
        this.setState({ imageModal: !this.state.imageModal });
    }

    clickVideoButton() {
        this.setState({ mediaAddMode: "free" });
        this.setState({ videoModal: !this.state.videoModal });
    }

    clickAudioButton() {
        this.setState({ mediaAddMode: "free" });
        this.setState({ audioModal: !this.state.audioModal });
    }

    clickPayImageButton() {
        this.setState({ mediaAddMode: "pay" });
        this.setState({ imageModal: !this.state.imageModal });
    }

    clickPayVideoButton() {
        this.setState({ mediaAddMode: "pay" });
        this.setState({ videoModal: !this.state.videoModal });
    }

    clickPayAudioButton() {
        this.setState({ mediaAddMode: "pay" });
        this.setState({ audioModal: !this.state.audioModal });
    }

    imageModalChangeMediaAddMode(mode:string) {
        this.setState({mediaAddMode: mode});
    }

    videoModalChangeMediaAddMode(mode:string) {
        this.setState({mediaAddMode: mode});
    }

    onCancel() {
        this.props.onCancel();
    }

    render() {
        if (this.context) {
            if (this.context.producerID === "") {
                return <Redirect to="/" push={true} />
            }
        } else {
            return <Redirect to="/" push={true} />
        }

        return (
            <div id='MessengerWriteParentDIV' className='pt-0'>
                <ImageModal singleSelect={false} open={this.state.imageModal} mediaAddMode={this.state.mediaAddMode} changeMediaAddMode={this.imageModalChangeMediaAddMode.bind(this)} closeCallback={this.imageModalClose.bind(this)} itemClickCallback={this.imageModalSelect.bind(this)} itemSelectMulti={this.imageModalSelectMulti.bind(this)} />
                <VideoModal open={this.state.videoModal} mediaAddMode={this.state.mediaAddMode} changeMediaAddMode={this.videoModalChangeMediaAddMode.bind(this)} closeCallback={this.videoModalClose.bind(this)} itemClickCallback={this.videoModalSelect.bind(this)} itemSelectMulti={this.videoModalSelectMulti.bind(this)} />
                <Modal isOpen={this.state.priceModal} size="sm" onClosed={this.priceModalClose.bind(this)}>
                    <ModalHeader close={<button className="close" onClick={this.priceModalClose.bind(this)}>×</button>}>
                        { this.getText("Preis festlegen") }
                    </ModalHeader>
                    <ModalBody>
                        <Form onSubmit={this.handlePriceSubmit.bind(this)}>
                            <FormGroup className="mb-2" controlId="formBasicPassword">
                                <Label>{this.getText("Price")}</Label>
                                <InputGroup>
                                    <Input value={this.state.priceInputEuro} onChange={this.changePriceInputEuro.bind(this)} type="tel" style={{textAlign: "right"}} />
                                    <InputGroupText>
                                        <FontAwesomeIcon icon={solid('euro-sign')} size="sm" />
                                    </InputGroupText>
                                    <InputGroupText>
                                        ,
                                    </InputGroupText>
                                    <Input value={this.state.priceInputCents} maxLength={2} onChange={this.changePriceInputCents.bind(this)} type="tel" style={{textAlign: "right"}} />
                                    <InputGroupText>
                                        <FontAwesomeIcon icon={solid('cent-sign')} size="sm" />
                                    </InputGroupText>
                                </InputGroup>
                            </FormGroup>
                            <Button variant="primary" disabled={this.state.sendingMessage} type="submit">{this.state.sendingMessage ? this.getText("Sending") : this.getText("PriceSubmit")}</Button>
                        </Form>
                        <p className="mt-2 mb-0" style={{textAlign: "justify",lineHeight: "16px",color: "#888"}}>
                            <small>{this.getPayMediaDescriptionText()}</small>
                        </p>
                    </ModalBody>
                </Modal>

                <Modal isOpen={this.state.groupScheduleModal} size="md" onClosed={this.clickedGroupScheduleModalClose.bind(this)}>
                    <ModalHeader close={<button className="close" onClick={this.clickedGroupScheduleModalClose.bind(this)}>×</button>}>
                        {this.getText("GroupSchedule")}
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup className="mb-2" check>
                            <input id="cbGroupSchedule" type="checkbox" checked={this.state.groupSchedule} onChange={this.changeGroupSchedule.bind(this)} />
                            <Label for="cbGroupSchedule" check>{this.getText("SendThisToNewUsers")}</Label>
                        </FormGroup>
                        <FormGroup className="mb-2">
                            <Label>{this.getText("Days")}</Label>
                            <InputGroup>
                                <Input value={this.state.groupScheduleDays} onChange={this.changeGroupScheduleDays.bind(this)} type="number" />
                                <InputGroupText>
                                    <FontAwesomeIcon icon={solid('calendar')} size="sm" />
                                </InputGroupText>
                            </InputGroup>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={this.clickedGroupScheduleModalClose.bind(this)}>{this.getText("Close")}</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.clickedMediaData !== null} size="md" onClosed={this.clickedMediaDataModalClose.bind(this)}>
                    <ModalHeader close={<button className="close" onClick={this.clickedMediaDataModalClose.bind(this)}>×</button>}>
                    { this.state.clickedMediaData ? this.getText(this.state.mediaAddMode + this.state.clickedMediaData.type) : null }
                    </ModalHeader>
                    <ModalBody>
                    { this.state.clickedMediaData ? <img x-mediatype={this.state.clickedMediaData.type} x-mediaid={this.state.clickedMediaData.mediaID} src={this.getSingleImagePreviewUrl(this.state.clickedMediaData)} className="w-100" /> : null }
                    </ModalBody>
                    <ModalFooter>
                        {this.state.clickedMediaDeletable && this.state.mediaAddMode === "free" ?
                        <Button onClick={this.changeClickedMediaDataToPay.bind(this)}>{this.getText("ChangeToPay")}</Button>
                        : null }
                        {this.state.clickedMediaDeletable && this.state.mediaAddMode === "pay" ?
                        <Button onClick={this.changeClickedMediaDataToFree.bind(this)}>{this.getText("ChangeToFree")}</Button>
                        : null }
                        { this.state.clickedMediaDeletable ?
                        <Button onClick={this.deleteClickedMediaData.bind(this)}>{this.getText("Bild aus der Nachricht löschen")}</Button> : null }
                        <Button onClick={this.closeClickedMediaData.bind(this)}>{this.getText("Close")}</Button>
                    </ModalFooter>
                </Modal>

                <div id='MessengerWriteDIV' className='pt-0'>
                    {this.state.errorMessage !== "" ? <Alert color="danger">{this.state.errorMessage}</Alert> : null}

                    <Form onSubmit={this.handleSubmit.bind(this)}>
                        <FormGroup>
                            <Label>
                                {this.getText("Nachricht schreiben")} {this.getText("to")} {this.state.selectedUserPseudo}
                                { this.state.targetListID !== "" ? <Button style={{marginLeft: "10px"}} className='MessengerExtraButton' size="sm" onClick={this.toggleGroupScheduleModal.bind(this)}><FontAwesomeIcon icon={solid('user-group')} size="sm" />{this.state.groupSchedule ? <span> <FontAwesomeIcon icon={solid('circle-check')} size="sm" /></span> : null}</Button> : null }
                            </Label>
                            <Input className='mt-1' type="textarea" autoFocus={true} onChange={this.handleMessageChange.bind(this)} id="txtMessage" value={this.state.msgText } />
                        </FormGroup>
                        <div className="d-flex w-100 align-items-center justify-content-between flex-wrap mt-1">
                            <ButtonToolbar>
                                <ButtonGroup className="me-2">
                                    <Button className="BelowPostButton" size='sm' onClick={this.clickPriceButton.bind(this)}><FontAwesomeIcon icon={solid('euro')} size="sm" /><Badge color="secondary">{this.getText("Price")}: {this.formatPrice(this.state.price)}</Badge></Button>
                                    <Button className="BelowPostButton" size='sm' onClick={this.clickPayImageButton.bind(this)}><div className='BelowPostButtonPayIcon'><FontAwesomeIcon icon={solid('euro')} size="sm" /></div><FontAwesomeIcon icon={solid('image')} size="sm" /></Button>
                                    <Button className="BelowPostButton" size='sm' onClick={this.clickPayVideoButton.bind(this)}><div className='BelowPostButtonPayIcon'><FontAwesomeIcon icon={solid('euro')} size="sm" /></div><FontAwesomeIcon icon={solid('video')} size="sm" /></Button>
                                    <Button className="BelowPostButton" size='sm' onClick={this.clickPayAudioButton.bind(this)}><div className='BelowPostButtonPayIcon'><FontAwesomeIcon icon={solid('euro')} size="sm" /></div><FontAwesomeIcon icon={solid('microphone')} size="sm" /></Button>
                                </ButtonGroup>
                                <ButtonGroup className="me-2">
                                    <Button className="BelowPostButton" size='sm' onClick={this.clickImageButton.bind(this)}><div className='BelowPostButtonPayIcon'><FontAwesomeIcon icon={solid('gift')} size="sm" /></div><FontAwesomeIcon icon={solid('image')} size="sm" /></Button>
                                    <Button className="BelowPostButton" size='sm' onClick={this.clickVideoButton.bind(this)}><div className='BelowPostButtonPayIcon'><FontAwesomeIcon icon={solid('gift')} size="sm" /></div><FontAwesomeIcon icon={solid('video')} size="sm" /></Button>
                                    <Button className="BelowPostButton" size='sm' onClick={this.clickAudioButton.bind(this)}><div className='BelowPostButtonPayIcon'><FontAwesomeIcon icon={solid('gift')} size="sm" /></div><FontAwesomeIcon icon={solid('microphone')} size="sm" /></Button>
                                </ButtonGroup>
                            </ButtonToolbar>

                            <ButtonGroup>
                                <Button onClick={this.onCancel.bind(this)} color="secondary" size='sm'><span className="d-inline"><FontAwesomeIcon icon={solid('remove')} size="sm" /> </span><span className="d-none d-md-inline">{this.getText("Cancel")}</span></Button>
                                <Button color="primary" disabled={this.state.sendingMessage} size='sm' type="submit"><span className="d-inline"><FontAwesomeIcon icon={solid('envelope')} size="sm" /> </span><span className="d-none d-md-inline">{this.getText("Submit")}</span></Button>
                            </ButtonGroup>
                        </div>
                    </Form>
                </div>
                { this.state.freeMedia.length > 0 ? 
                <div>
                    <h6>{this.getText("Kostenlose Inhalte")}:</h6>
                    <p><small>{this.getText("RedBorderedImages")}</small></p>
                    <div className="d-flex flex-wrap" style={{gap: "4px"}}>
                        {this.state.freeMedia.map(item =>
                            <div className="smallMediaPreview">
                                { item.reviewStatus !== 1 ? <img className="smallMediaPreviewReview" src="https://d3jg4n5aipvur8.cloudfront.net/fanicons/30x30/icon_analysis.png" alt="review required" /> : null }
                                <div className="smallMediaPreviewIcon">
                                    { item.type === "image" ? <FontAwesomeIcon icon={solid('image')} size="sm" /> : item.type === "video" ? <FontAwesomeIcon icon={solid('video')} size="sm" /> : item.type === "audio" ? <FontAwesomeIcon icon={solid('microphone')} size="sm" /> : null }
                                </div>
                                <div className="w-100">
                                    <img x-mediaType={item.type} x-mediaID={item.mediaID} src={this.getSmallMediaPreviewUrl(item)} className="w-100" style={{cursor: "pointer",border: item.reviewStatus === 1 ? "none" : "solid 2px red"}} onClick={this.freeMediaClicked.bind(this,item,true)} />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                : null }
                { this.state.payMedia.length > 0 ? 
                <div>
                    <h6>{this.getText("Kostenpflichtige Inhalte")} <Badge color="secondary">{this.getText("Price")}: {this.formatPrice(this.state.price)}</Badge>:</h6>
                    <p><small>{this.getText("RedBorderedImages")}</small></p>
                    <div className="d-flex flex-wrap" style={{gap: "4px"}}>
                        {this.state.payMedia.map(item =>
                            <div className="smallMediaPreview">
                                { item.reviewStatus !== 1 ? <img className="smallMediaPreviewReview" src="https://d3jg4n5aipvur8.cloudfront.net/fanicons/30x30/icon_analysis.png" alt="review required" /> : null }
                                <div className="smallMediaPreviewIcon">
                                    { item.type === "image" ? <FontAwesomeIcon icon={solid('image')} size="sm" /> : item.type === "video" ? <FontAwesomeIcon icon={solid('video')} size="sm" /> : item.type === "audio" ? <FontAwesomeIcon icon={solid('microphone')} size="sm" /> : null }
                                </div>
                                <div className="w-100">
                                    <img x-mediaType={item.type} x-mediaID={item.mediaID} src={this.getSmallMediaPreviewUrl(item)} className="w-100" style={{cursor: "pointer",border: item.reviewStatus === 1 ? "none" : "solid 2px red"}} onClick={this.payMediaClicked.bind(this,item,true)} />
                                </div>
                            </div>
                        )}
                    </div>
                </div> : null }
            </div>
        )
    }
}