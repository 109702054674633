import React from 'react';
import { LocalStorageWorker } from './StorageHelper';
import { Amplify,Auth } from 'aws-amplify';

export interface AppContextInterface {
    producerID: string;
    platform: string;
    language: string;
    pseudo: string;
    email: string;
    phonenumber: string;
    loggedin: boolean;
    infomsg: string;
    authToken: string;
    jwtAuthToken: string;
    refreshToken: string;
    api: string;
    cognitoUser: any;
    doUpdateUnread: boolean;
}

export class ApiHelper {
    static getPlatform():string {
        if(window.location.hostname === "control.deine.fans" || window.location.hostname === "control.cherry.fans" || window.location.hostname === "control.livestrip.fans") {
            return "fans";
        } else {
            return "guppy";
        }
    }

    static getAPIUrl(context:AppContextInterface|null) {
        if(context) {
            if(context.api == "local") {
                return "https://localhost:7055/api";
            } else {
                return "https://fanapi-test.guppy.live/api";
            }
        } else {
            return "https://fanapi-test.guppy.live/api";
        }
    }

    static async checkResponse(data:any|null,context:AppContextInterface|null):Promise<boolean> {
        if(!context) {
            return true;
        }
        if(!data){return true}

        if(data.need_refresh) {
            ApiHelper.refreshToken(context);
        }

        if(new String(data.error).indexOf("AuthToken expired") >= 0) {
            return false;
        }

        return true;
    }

    static async refreshToken(context:AppContextInterface|null) {
        if(!context) {
            return;
        }

        fetch("https://api.deine.fans/api/token/refresh", {
            method: "POST",
            body: JSON.stringify({
              role: "producer",
              producerID: context.producerID,
              authToken: context.refreshToken ? context.refreshToken : context.authToken,
            }),
            headers: {
              "Content-Type": "application/json",
            },
          }).then((res) => {
            res.json().then((data) => {
                if(!data){return}
      
                if(!data.success){
                    context.producerID = "";
                    context.pseudo = "";
                    context.loggedin = false;
                    context.authToken = "";
                    if(context.language === "de") {
                        context.infomsg = "Du wurdest ausgeloggt";
                    } else {
                        context.infomsg = "You have been logged out";
                    }

                    this.awsSignOut();

                    let myLocalStorage:LocalStorageWorker = new LocalStorageWorker();

                    myLocalStorage.remove("pseudo");
                    myLocalStorage.remove("producerid");
                    myLocalStorage.remove("password");
                }
            
                if (data.success && data.refreshMessage !== "Refresh not needed! Returning old Token.") {
                    context.refreshToken = data.refreshToken;
                    context.authToken = data.authToken;
                    context.jwtAuthToken = data.jwtAuthToken;
                }
            });
        });
    }
    
    static async awsSignOut() {
        try {
            Amplify.configure({
                Auth: {
                    identityPoolId: "eu-west-1:facaef99-eb33-48af-b5ae-739edb22fb8a",
                    region: "eu-west-1",
                    userPoolId: "eu-west-1_U6LI5uyvd",
                    userPoolWebClientId: "15e8m1kg2g0mm59g0s4n94q2um",
                    mandatorySignIn: false,
                    signUpVerificationMethod: "code",
                    authenticationFlowType: "USER_PASSWORD_AUTH"
                }
            });

            await Auth.signOut();
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }

    static async checkLoginStatus(context:AppContextInterface|null) {
        if (context) {
            if(!context.loggedin) {
                let myLocalStorage:LocalStorageWorker = new LocalStorageWorker();
                let lastToken:string = myLocalStorage.get("lastToken");
                let lastPseudo:string = myLocalStorage.get("lastPseudo");
                let lastProducerID:string = myLocalStorage.get("lastProducerID");
                let lastLanguage:string = myLocalStorage.get("lastLanguage");

                try {
                    if(lastToken != null && lastProducerID != null) {
                        if(lastToken !== "" && lastProducerID !== "") {
                            var jsonurl = ApiHelper.getAPIUrl(context) + "/authtoken?token=" + encodeURIComponent(lastToken) + "&producerID=" + encodeURIComponent(lastProducerID);
                            const response = await fetch(jsonurl, {method: "GET", headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}});
                            let data = null;
                            data = await response.json();
                            if(data.isValid) {
                                console.log("Last token is valid, logging in automatically...");
                                context.loggedin = true;
                                context.authToken = lastToken;
                                context.pseudo = lastPseudo;
                                context.producerID = lastProducerID;
                                context.language = lastLanguage;
                            } else {
                                console.log("Last token is not valid...");
                            }
                        }
                    }
                } catch(e:any) {
                    console.error("Error checking locally stored lastToken: " + e.toString());
                }
            }
        }
    }
}

export const AppCtx = React.createContext<AppContextInterface | null>(null);