import React, { Component, FormEvent } from 'react';
import { Alert, Container, Form, FormGroup, Button, Label, Input } from 'reactstrap';
import { LocalStorageWorker } from '../StorageHelper';
import { CryptoHelper } from '../CryptoHelper.js';
import { AppCtx, AppContextInterface, ApiHelper } from '../AppContextInterface';
import { Redirect } from 'react-router'
//import { Amplify,Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';

interface LogoutFormProps {
    
}

export interface LogoutForm {
    state: LogoutFormState;
    props: LogoutFormProps;
    contextType: AppContextInterface;
}

interface LogoutFormState {
    submitting: boolean;
    errorMessage: string;
    language: string;
    loggedout: boolean;
}

export class LogoutForm extends React.Component {
    static contextType = AppCtx;
    context!: React.ContextType<typeof AppCtx>;

    constructor(props: LogoutFormProps) {
        super(props);

        let myLocalStorage:LocalStorageWorker = new LocalStorageWorker();

        let defaultLanguage = "auto";

        if (myLocalStorage.get("language") != null) {
            defaultLanguage = myLocalStorage.get("language");
        }

        this.state = { 
            submitting: false, 
            errorMessage: '', 
            language: defaultLanguage,
            loggedout: false
        };
    }

    componentDidMount() {
    }

    handleUserNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ userName: event.target.value });
    }

    handlePasswordChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ password: event.target.value });
    }

    handleSubmit(event: FormEvent) {
        event.preventDefault();
        this.formSubmitted();
    }

    formSubmitted = async() => {
        if(!this.context) {
            return;
        }

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/logout?producerID=" + encodeURIComponent(this.context.producerID) + "&token=" + encodeURIComponent(this.context.authToken);

        this.setState({ errorMessage: "" });
        this.setState({ submitting: true });

        let data = null;

        try {
            const response = await fetch(jsonurl);
            data = await response.json();
            this.setState({ submitting: false });

            if (data.logout === "ok") {
                if (this.context) {
                    this.context.producerID = "";
                    this.context.pseudo = "";
                    this.context.loggedin = false;
                    this.context.authToken = "";
                    this.context.jwtAuthToken = "";
                    this.context.refreshToken = "";
                    if(this.context.language === "de") {
                        this.context.infomsg = "Du wurdest ausgeloggt";
                    } else {
                        this.context.infomsg = "You have been logged out";
                    }

                    this.awsSignOut();

                    let myLocalStorage:LocalStorageWorker = new LocalStorageWorker();

                    myLocalStorage.remove("pseudo");
                    myLocalStorage.remove("producerid");
                    myLocalStorage.remove("password");

                    this.setState({ loggedout: true });
                }
            } else {
                if(data.error) {
                    this.setState({ errorMessage: this.getText("LogoutFailed") + " : " + data.error });
                } else {
                    this.setState({ errorMessage: this.getText("LogoutFailed") + " : " + data.logout });
                }
            }
        } catch (error: any) {
            this.setState({ errorMessage: "Error on login request: " + error.toString() });
            this.setState({ submitting: false });
            return;
        }
    }

    awsSignOut = async() => {
        /*try {
            Amplify.configure({
                Auth: {
                    identityPoolId: "eu-west-1:facaef99-eb33-48af-b5ae-739edb22fb8a",
                    region: "eu-west-1",
                    userPoolId: "eu-west-1_U6LI5uyvd",
                    userPoolWebClientId: "15e8m1kg2g0mm59g0s4n94q2um",
                    mandatorySignIn: false,
                    signUpVerificationMethod: "code",
                    authenticationFlowType: "USER_PASSWORD_AUTH"
                }
            });

            await Auth.signOut();
        } catch (error) {
            console.log('error signing out: ', error);
        }*/
    }

    getText(exp: string) {
        if (this.state.language === "de") {
            if (exp === "Username") {
                return "Pseudo";
            }
            if (exp === "Password") {
                return "Passwort";
            }
            if (exp === "UsernameHelp") {
                return "Das Pseudo bzw. der Name mit dem Dein Fan-Profil angezeigt wird";
            }
            if (exp === "LogoutFormTitle") {
                return "Logout";
            }
            if (exp === "LogoutFormSubtitle") {
                return "Logge Dich aus und Du bist, naja, ausgeloggt";
            }
            if (exp === "Save Data") {
                return "Logindaten merken";
            }
            if (exp === "Submit") {
                return "Abschicken";
            }
            if (exp === "LoggedIn") {
                return "Du bist eingeloggt";
            }
            if (exp === "LoginFailed") {
                return "Login fehlgeschlagen";
            }
        } else {
            if (exp === "Username") {
                return "Livecam Profile";
            }
            if (exp === "UsernameHelp") {
                return "This is the name that has been assigned to your fan profile";
            }
            if (exp === "LogoutFormTitle") {
                return "Logout";
            }
            if (exp === "LogoutFormSubtitle") {
                return "Logout here and you are, well, logged out";
            }
            if (exp === "LoggedIn") {
                return "You are logged in";
            }
            if (exp === "LoginFailed") {
                return "Login failed";
            }
        }

        return exp;
    }

    render() {
        if (this.context) {
            if (this.context.producerID === "") {
                return <Redirect to="/" push={true} />
            }
        } else {
            return <Redirect to="/" push={true} />
        }

        return (
            <div id='LogoutFormDIV' className='container pt-4'>
                <h4 className="mb-4">{this.getText("LogoutFormTitle")}</h4>
                { !this.state.loggedout ?
                <div className="mb-4"><small>{this.getText("LogoutFormSubtitle")}</small></div>
                : null }

                {this.state.errorMessage !== "" ? <Alert color="danger">{this.state.errorMessage}</Alert> : null}

                { !this.state.loggedout ?
                <Form onSubmit={this.handleSubmit.bind(this)}>
                    <Button variant="primary" disabled={this.state.submitting} type="submit">{this.getText("Logout")}</Button>
                </Form> : 
                <Link to="/">
                    <Button variant="primary" type="button">{this.getText("Home")}</Button>
                </Link> }
            </div>
        );
    }
}

export default LogoutForm;