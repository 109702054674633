import React, { Component, FormEvent } from 'react';
import { Alert, Container, Form, FormGroup, Button, Label, Input } from 'reactstrap';
import { LocalStorageWorker } from '../StorageHelper';
import { CryptoHelper } from '../CryptoHelper.js';
import { AppCtx, AppContextInterface, ApiHelper } from '../AppContextInterface';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';

interface SignupFormProps {

}

export interface SignupForm {
    state: SignupFormState;
    props: SignupFormProps;
    contextType: AppContextInterface;
    changeLanguage: (newLang: string) => void;
}

interface SignupFormState {
    successMessage: string;
    username: string;
    password: string;
    passwordRepeat: string;
    email: string;
    submitting: false;
    errorMessage: string;
    signupSuccess: boolean;
    language: string;
}

let globalSignupForm: SignupForm;

export class SignupForm extends React.Component {
    static contextType = AppCtx;
    context!: React.ContextType<typeof AppCtx>;

    constructor(props: SignupFormProps) {
        super(props);

        let myLocalStorage = new LocalStorageWorker();

        let contextLanguage = "de";

        if (this.context) {
            if (this.context.language !== null && this.context.language !== "") {
                contextLanguage = this.context.language;
            }
        }

        this.state = { 
            username: '',
            password: '',
            passwordRepeat: '',
            email: '',
            submitting: false,
            errorMessage: '',
            language: contextLanguage,
            signupSuccess: false,
            successMessage: ""
        };

        globalSignupForm = this;
    }

    componentDidMount() {
        // do nothing
    }

    handleLanguageChange(event: React.ChangeEvent<HTMLInputElement>) {
        let newLang = event.target.value;
        this.setState({ language: newLang });
        this.changeLanguage(newLang);
        let myLocalStorage = new LocalStorageWorker();
        myLocalStorage.add("language", newLang);
    }

    handleUserNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ username: event.target.value });
    }

    handlePasswordChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ password: event.target.value });
    }

    handlePasswordRepeatChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ passwordRepeat: event.target.value });
    }

    handleEMailChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ email: event.target.value });
    }

    handleSubmit(event: FormEvent) {
        event.preventDefault();
        this.formSubmitted();
    }

    formSubmitted = async () => {
        this.setState({ errorMessage: "" });

        if (this.state.password !== this.state.passwordRepeat) {
            this.setState({ errorMessage: this.getText("Password mismatch") });
        }

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/signup?newPassword=" + encodeURIComponent(this.state.password) + "&newEMail=" + encodeURIComponent(this.state.email) + "&newUsername=" + encodeURIComponent(this.state.username);

        this.setState({ submitting: true });

        let response:any = null;

        try {
            const settings = {
                method: 'POST',
                headers: {
                    Accept: 'application/json'
                }
            };
            response = await fetch(jsonurl, settings);
            let data = await response.json();
            this.setState({ submitting: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                }

                if (data.success === true) {
                    this.setState({ signupSuccess: true });
                    this.setState({ successMessage: data.successMessage });
                }
            }
        } catch (error: any) {
            this.setState({ errorMessage: "Error making changes: " + error.toString() });
            this.setState({ submitting: false });
        }
    }

    getText(exp: string) {
        if (this.state.language === "de") {
            if (exp === "Username") {
                return "Pseudo";
            }
            if (exp === "Password") {
                return "Passwort";
            }
            if (exp === "UsernameHelp") {
                return "Das Pseudo bzw. der Name mit dem Dein Livecam-Profil angezeigt wird";
            }
            if (exp === "SignupFormSubtitle") {
                return "Hier kannst Du Änderungen an Deinem Account vornehmen";
            }
            if (exp === "Save Data") {
                return "Logindaten merken";
            }
            if (exp === "Submit") {
                return "Abschicken";
            }
            if (exp === "ChangesSuccess") {
                return "Die Änderungen wurden übernommen";
            }
            if (exp === "Error") {
                return "Fehler";
            }
            if (exp === "EMailChanged") {
                return "Deine EMail-Adresse wurde geändert";
            }
            if (exp === "PasswordChanged") {
                return "Dein Passwort wurde geändert";
            }
        } else {
            if (exp === "Username") {
                return "Livecam Profile";
            }
            if (exp === "UsernameHelp") {
                return "This is the name that has been assigned to your livecam profile";
            }
            if (exp === "SignupFormSubtitle") {
                return "You can change settings for your account here";
            }
            if (exp === "ChangesSuccess") {
                return "Changes successfully applied";
            }
            if (exp === "EMailChanged") {
                return "Your email address has been changed";
            }
            if (exp === "PasswordChanged") {
                return "Your password has been changed";
            }
        }

        return exp;
    }

    render() {
        if (this.context) {
            if (this.context.producerID === "") {
                return <Redirect to="/" push={true} />
            }
        } else {
            return <Redirect to="/" push={true} />
        }

        return (
            <div id='SignupFormDIV' className='container pt-4'>
                <h4 className="mb-4">{this.getText("Account")}</h4>
                <div className="mb-4"><small>{this.getText("SignupFormSubtitle")}</small></div>

                { this.state.signupSuccess === true ? <Link to="/login"><button className="btn btn-primary mb-4">{this.getText("Login")}</button></Link> : null }

                {this.state.successMessage !== "" ? <Alert color="success">{this.state.successMessage}</Alert> : null}
                {this.state.errorMessage !== "" ? <Alert color="danger">{this.state.errorMessage}</Alert> : null}

                <Form onSubmit={this.handleSubmit.bind(this)}>
                    <FormGroup className="mb-2" controlId="formBasicEMail">
                        <Label>{this.getText("EMail")}</Label>
                        <Input type="email" placeholder={this.getText("EMail")} value={this.state.email} onChange={this.handleEMailChange.bind(this)} />
                    </FormGroup>
                    <FormGroup className="mb-2" controlId="formBasicEMail">
                        <Label>{this.getText("Username")}</Label>
                        <Input type="text" placeholder={this.getText("Username")} value={this.state.email} onChange={this.handleUserNameChange.bind(this)} />
                    </FormGroup>
                    <FormGroup className="mb-2" controlId="formBasicPassword">
                        <Label>{this.getText("New Password")}</Label>
                        <Input type="password" placeholder={this.getText("Password")} value={this.state.password} onChange={this.handlePasswordChange.bind(this)} />
                    </FormGroup>
                    <FormGroup className="mb-2" controlId="formBasicPasswordRepeat">
                        <Label>{this.getText("PasswordRepeat")}</Label>
                        <Input type="password" placeholder={this.getText("Password")} value={this.state.passwordRepeat} onChange={this.handlePasswordRepeatChange.bind(this)} />
                    </FormGroup>
                    <Button variant="primary" disabled={this.state.submitting} type="submit">{this.getText("Submit")}</Button>
                    <hr></hr>
                    <FormGroup>
                        <Label>{this.getText("Language")}</Label>
                        <Input defaultValue={this.state.language} id="drpLanguage" as="select" onChange={this.handleLanguageChange.bind(this)}>
                            <option value="en">English</option>
                            <option value="de">Deutsch/German</option>
                        </Input>
                    </FormGroup>
                </Form>
            </div>
        );
    }
}

export default SignupForm;