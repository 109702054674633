import React, { Component, FormEvent } from 'react';
import { Alert, Container, Form, FormGroup, Button, Label, Input } from 'reactstrap';
import { LocalStorageWorker } from '../StorageHelper';
import { CryptoHelper } from '../CryptoHelper.js';
import { AppCtx, AppContextInterface, ApiHelper } from '../AppContextInterface';
//import { Amplify,Auth } from 'aws-amplify';
import { Redirect } from 'react-router'

interface MyAccountProps {

}

export interface MyAccount {
    state: MyAccountState;
    props: MyAccountProps;
    contextType: AppContextInterface;
}

interface MyAccountState {
    password: string;
    passwordRepeat: string;
    email: string;
    submitting: false;
    errorMessage: string;
    emailChanged: boolean;
    emailMessage: string;
    passwordChanged: boolean;
    passwordMessage: string;
    language: string;
}

export class MyAccount extends React.Component {
    static contextType = AppCtx;
    context!: React.ContextType<typeof AppCtx>;

    constructor(props: MyAccountProps) {
        super(props);

        let myLocalStorage = new LocalStorageWorker();

        let contextLanguage = "de";

        if (this.context) {
            if (this.context.language !== null && this.context.language !== "") {
                contextLanguage = this.context.language;
            }
        }

        this.state = { password: '', passwordRepeat: '', email: '', submitting: false, emailChanged: false, emailMessage: '', passwordChanged: false, passwordMessage: '', errorMessage: '', language: contextLanguage };
    }

    componentDidMount() {
        this.fetchData();
    }

    handlePasswordChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ password: event.target.value });
    }

    handlePasswordRepeatChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ passwordRepeat: event.target.value });
    }

    handleEMailChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ email: event.target.value });
    }

    handleSubmit(event: FormEvent) {
        event.preventDefault();
        this.formSubmitted();
    }

    fetchData = async () => {
        this.setState({ emailChanged: false });
        this.setState({ emailMessage: "" });
        this.setState({ passwordChanged: false });
        this.setState({ passwordMessage: "" });
        this.setState({ errorMessage: "" });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/account";

        if (this.context) {
            if(!this.context.loggedin) {
                await ApiHelper.checkLoginStatus(this.context);
            }
            jsonurl += "?producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        }

        this.setState({ submitting: true });

        let response: any = null;

        try {
            response = await fetch(jsonurl);
            let data = await response.json();
            this.setState({ submitting: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                } else {
                    this.setState({ email: data.email });
                    this.setState({ password: data.password });
                    this.setState({ passwordRepeat: data.password });
                }
            }
        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching data: " + error.toString() });
            this.setState({ submitting: false });
        }
    }

    awsChangeEMail = async() => {
        /*try {
            Amplify.configure({
                Auth: {
                    identityPoolId: "eu-west-1:facaef99-eb33-48af-b5ae-739edb22fb8a",
                    region: "eu-west-1",
                    userPoolId: "eu-west-1_U6LI5uyvd",
                    userPoolWebClientId: "15e8m1kg2g0mm59g0s4n94q2um",
                    mandatorySignIn: false,
                    signUpVerificationMethod: "code",
                    authenticationFlowType: "USER_PASSWORD_AUTH"
                }
            });

            if(this.context) {
                await Auth.updateUserAttributes(this.context.cognitoUser, { email: this.state.email });
            }
        } catch (error) {
            console.log('error updateUserAttributes: ', error);
        }*/
    }

    awsChangePassword = async() => {
        /*try {
            Amplify.configure({
                Auth: {
                    identityPoolId: "eu-west-1:facaef99-eb33-48af-b5ae-739edb22fb8a",
                    region: "eu-west-1",
                    userPoolId: "eu-west-1_U6LI5uyvd",
                    userPoolWebClientId: "15e8m1kg2g0mm59g0s4n94q2um",
                    mandatorySignIn: false,
                    signUpVerificationMethod: "code",
                    authenticationFlowType: "USER_PASSWORD_AUTH"
                }
            });

            if(this.context) {
                await Auth.changePassword(this.context.cognitoUser, this.state.password, this.state.passwordRepeat);
            }
        } catch (error) {
            console.log('error changePassword: ', error);
        }*/
    }

    formSubmitted = async () => {
        this.setState({ emailChanged: false });
        this.setState({ emailMessage: "" });
        this.setState({ passwordChanged: false });
        this.setState({ passwordMessage: "" });
        this.setState({ errorMessage: "" });

        if (this.state.password !== this.state.passwordRepeat) {
            this.setState({ errorMessage: this.getText("Password mismatch") });
        }

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/account?newPassword=" + encodeURIComponent(this.state.password) + "&newEMail=" + encodeURIComponent(this.state.email);

        if (this.context) {
            jsonurl += "&producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        }

        this.setState({ submitting: true });

        let response:any = null;

        try {
            const settings = {
                method: 'POST',
                headers: {
                    Accept: 'application/json'
                }
            };
            response = await fetch(jsonurl, settings);
            let data = await response.json();
            this.setState({ submitting: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                    if(!ApiHelper.checkResponse(data,this.context) && this.context !== null) {
                        this.context.producerID = "";
                        alert("Please relogin");
                    }
                } else {
                    this.setState({ emailChanged: data.emailChanged });
                    this.setState({ passwordChanged: data.passwordChanged });
                }

                this.setState({ emailChanged: data.emailChanged });
                if(data.emailChanged) {
                    this.awsChangeEMail();
                }
                this.setState({ emailMessage: data.emailMessage === null ? "" : data.emailMessage });
                this.setState({ passwordChanged: data.passwordChanged });
                this.setState({ passwordMessage: data.passwordMessage === null ? "" : data.passwordMessage });
                if(data.passwordChanged) {
                    this.awsChangePassword();
                }
            }
        } catch (error: any) {
            this.setState({ errorMessage: "Error making changes: " + error.toString() });
            this.setState({ submitting: false });
        }
    }

    getText(exp: string) {
        if (this.state.language === "de") {
            if (exp === "Username") {
                return "Pseudo";
            }
            if (exp === "Password") {
                return "Passwort";
            }
            if (exp === "UsernameHelp") {
                return "Das Pseudo bzw. der Name mit dem Dein Livecam-Profil angezeigt wird";
            }
            if (exp === "MyAccountSubtitle") {
                return "Hier kannst Du Änderungen an Deinem Account vornehmen";
            }
            if (exp === "Save Data") {
                return "Logindaten merken";
            }
            if (exp === "Submit") {
                return "Abschicken";
            }
            if (exp === "ChangesSuccess") {
                return "Die Änderungen wurden übernommen";
            }
            if (exp === "Error") {
                return "Fehler";
            }
            if (exp === "EMailChanged") {
                return "Deine EMail-Adresse wurde geändert";
            }
            if (exp === "PasswordChanged") {
                return "Dein Passwort wurde geändert";
            }
            if(exp === "No change in email") {
                return "Es gab keine Änderung in der EMail-Adresse";
            }
        } else {
            if (exp === "Username") {
                return "Livecam Profile";
            }
            if (exp === "UsernameHelp") {
                return "This is the name that has been assigned to your livecam profile";
            }
            if (exp === "MyAccountSubtitle") {
                return "You can change settings for your account here";
            }
            if (exp === "ChangesSuccess") {
                return "Changes successfully applied";
            }
            if (exp === "EMailChanged") {
                return "Your email address has been changed";
            }
            if (exp === "PasswordChanged") {
                return "Your password has been changed";
            }
        }

        return exp;
    }

    render() {
        if (this.context) {
            if (this.context.producerID === "") {
                return <Redirect to="/" push={true} />
            }
        } else {
            return <Redirect to="/" push={true} />
        }

        return (
            <div id='MyAccountDIV' className='container pt-4'>
                <h4 className="pageHeadline">{this.getText("Account")}</h4>
                <div className="mb-4"><small>{this.getText("MyAccountSubtitle")}</small></div>

                {this.state.emailChanged ? <Alert color="info">{this.getText("EMailChanged")}</Alert> : null}
                {this.state.emailMessage !== "" ? <Alert color="info">{this.getText(this.state.emailMessage)}</Alert> : null}
                {this.state.passwordChanged ? <Alert color="info">{this.getText("PasswordChanged")}</Alert> : null}
                {this.state.passwordMessage !== "" ? <Alert color="info">{this.getText(this.state.passwordMessage)}</Alert> : null}
                {this.state.errorMessage !== "" ? <Alert color="danger">{this.state.errorMessage}</Alert> : null}

                <Form onSubmit={this.handleSubmit.bind(this)}>
                    <FormGroup className="mb-2" controlId="formBasicPassword">
                        <Label>{this.getText("New Password")}</Label>
                        <Input autoComplete="new-password" type="password" placeholder={this.getText("Password")} value={this.state.password} onChange={this.handlePasswordChange.bind(this)} />
                    </FormGroup>
                    <FormGroup className="mb-2" controlId="formBasicPasswordRepeat">
                        <Label>{this.getText("PasswordRepeat")}</Label>
                        <Input autoComplete="new-password" type="password" placeholder={this.getText("Password")} value={this.state.passwordRepeat} onChange={this.handlePasswordRepeatChange.bind(this)} />
                    </FormGroup>
                    <FormGroup className="mb-2" controlId="formBasicEMail">
                        <Label>{this.getText("EMail")}</Label>
                        <Input autoComplete="email" type="email" placeholder={this.getText("EMail")} value={this.state.email} onChange={this.handleEMailChange.bind(this)} />
                    </FormGroup>
                    <Button variant="primary" disabled={this.state.submitting} type="submit">{this.getText("Submit")}</Button>
                </Form>
            </div>
        );
    }
}

export default MyAccount;