import React, { Component, FormEvent } from 'react';
import { Alert, Row, Col, Pagination, PaginationItem, PaginationLink, Button, DropdownToggle, Input, ButtonGroup, Dropdown, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label } from 'reactstrap';
import { LocalStorageWorker } from '../StorageHelper';
import { CryptoHelper } from '../CryptoHelper.js';
import { AppCtx, AppContextInterface, ApiHelper } from '../AppContextInterface';
import { Redirect } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DateTime } from 'luxon';
import { PageData } from './Timeline';
import { FolderData } from './ImageList';
import { faFileExport, faFileXmark, faFolderArrowUp, faFolderCog, faFolderGear, faFolderPlus, faFolderXmark, faMagnifyingGlass, faRemove } from '@fortawesome/pro-solid-svg-icons';

interface VideoListProps {
    onVideoClick(video:VideoData): void;
    confirmSelection(videos:VideoData[]): void;
    modalMode:boolean;
    mediaAddMode:string;
    singleSelect:boolean;
    folderName: string;
    folderGuid: string;
}

export interface VideoList extends React.Component {
    state: VideoListState;
    props: VideoListProps;
    contextType: AppContextInterface;
}

interface VideoListState {
    errorMessage: string,
    successMessage: string,
    fetchingVideos: boolean,
    language: string,
    videos: VideoData[],
    conversationID: string,
    maxPages: number;
    curPage: number;
    pageSize: number;
    videoType: string;
    pagesArray: PageData[];
    pagesToShow: number;
    sort: string;
    sortDropdownOpen: boolean;
    pageSizeDropdownOpen: boolean;
    extraPagesDropdownOpen: boolean;
    foldersDropdownOpen: boolean;
    curFolderName: string;
    curFolderGuid: string;
    folders: FolderData[];
    createFolderModalOpen: boolean;
    modifyFolderModalOpen: boolean;
    deleteFolderModalOpen: boolean;
    moveFilesModalOpen: boolean;
    deleteFilesModalOpen: boolean;
    selectedFiles: string[];
    moveToFolderDropdownOpen: boolean;
    moveToFolderGuid: string;
    moveToFolderName: string;
    fileSelectionDropdownOpen: boolean;
    modalMode: boolean;
    mediaAddMode: string;
    singleSelect: boolean;
    platform: string;
}

export interface VideoData {
    streamname: string;
    title: string;
    description: string;
    uploadTime: Date;
    publishTime: Date;
    reviewStatus: number;
    price: number;
    previewImageURLHard: string;
    previewImageURLSoft: string;
    timelinePostGuid: string|null;
    folderName: string;
    folderGuid: string;
}

export class VideoList extends React.Component {
    static contextType = AppCtx;
    context!: React.ContextType<typeof AppCtx>;

    constructor(props: VideoListProps) {
        super(props);

        let contextLanguage = "de";

        if (this.context) {
            if (this.context.language !== null && this.context.language !== "") {
                contextLanguage = this.context.language;
            }
        }

        let myplatform:string = ApiHelper.getPlatform();

        if(this.context) {
            myplatform = this.context.platform;
        }

        this.state = {
            errorMessage: "",
            successMessage: "",
            fetchingVideos: false,
            language: contextLanguage,
            conversationID: "",
            videos: [],
            maxPages: 12,
            pageSize: 24,
            videoType: "Premium",
            curPage: 0,
            pagesArray: [],
            pagesToShow: props.modalMode ? 3 : 10,
            sort: "uploadtime",
            sortDropdownOpen: false,
            pageSizeDropdownOpen: false,
            extraPagesDropdownOpen: false,
            foldersDropdownOpen: false,
            curFolderName: props.folderName,
            curFolderGuid: props.folderGuid,
            folders: [],
            createFolderModalOpen: false,
            modifyFolderModalOpen: false,
            deleteFolderModalOpen: false,
            moveFilesModalOpen: false,
            deleteFilesModalOpen: false,
            selectedFiles: [],
            moveToFolderDropdownOpen: false,
            moveToFolderGuid: "",
            moveToFolderName: "",
            fileSelectionDropdownOpen: false,
            modalMode: props.modalMode,
            mediaAddMode: props.mediaAddMode,
            singleSelect: props.singleSelect,
            platform: myplatform
        }
    }

    componentDidMount() {
        this.fetchData();
        this.fetchFolders();
    }

    componentWillReceiveProps(nextProps: Readonly<{}>, nextContext: any): void {
        console.log("props changed");
        this.fetchData();
        this.fetchFolders();
    }
    handleVideoSelect(streamName:string) {
        let selectedFiles = this.state.selectedFiles;
        if(selectedFiles.indexOf(streamName) === -1) {
            selectedFiles.push(streamName);
        } else {
            selectedFiles.splice(selectedFiles.indexOf(streamName),1);
        }
        this.setState({selectedFiles: selectedFiles});
    }

    handlePagination(numItems:number) {
        if(numItems > 0) {
            let newArray:PageData[] = [];
            for(let si = 0;si < numItems;si += this.state.pageSize) {
                let newPage:PageData = {
                    page: Math.floor(si / this.state.pageSize),
                    active: Math.floor(si / this.state.pageSize) == this.state.curPage ? true : false
                };
                newArray.push(newPage);
            }
            this.setState({pagesArray: newArray});
        } else {
            this.setState({pagesArray: []});
        }
    }

    handlePageChange(newPage:number):void {
        this.setState({curPage: newPage},this.fetchData.bind(this));
    }

    handleSortChange(sort:string) {
        this.setState({sort: sort},this.fetchData.bind(this));
    }

    handlePageSizeChange(pageSize:number) {
        this.setState({pageSize: pageSize},this.fetchData.bind(this));
    }

    handleSortDropdownToggle() {
        this.setState({ sortDropdownOpen: !this.state.sortDropdownOpen });
    }

    handlePageSizeDropdownToggle() {
        this.setState({ pageSizeDropdownOpen: !this.state.pageSizeDropdownOpen });
    }

    handleExtraPagesDropdownToggle() {
        this.setState({ extraPagesDropdownOpen: !this.state.extraPagesDropdownOpen });
    }

    handleFoldersDropdownToggle() {
        this.setState({ foldersDropdownOpen: !this.state.foldersDropdownOpen });
    }

    handleFolderChange(folder:FolderData|null) {
        if(folder === null) {
            this.setState({curFolderName: "", curFolderGuid: ""},this.fetchData.bind(this));
            return;
        } else {
            this.setState({curFolderName: folder.folder, curFolderGuid: folder.guid},this.fetchData.bind(this));
        }
    }

    handleCreateFolderModalToggle() {
        this.setState({ createFolderModalOpen: !this.state.createFolderModalOpen },this.focusCreateFolder.bind(this));
    }

    handleFileSelectionDropdownToggle() {
        this.setState({ fileSelectionDropdownOpen: !this.state.fileSelectionDropdownOpen });
    }

    handleSelectAll() {
        let selectedFiles = this.state.selectedFiles;
        let videos = this.state.videos;
        for(let i = 0; i < videos.length; i++) {
            if(selectedFiles.indexOf(videos[i].streamname) === -1) {
                selectedFiles.push(videos[i].streamname);
            }
        }
        this.setState({selectedFiles: selectedFiles});
    }

    handleSelectInvert() {
        let selectedFiles = this.state.selectedFiles;
        let videos = this.state.videos;
        for(let i = 0; i < videos.length; i++) {
            if(selectedFiles.indexOf(videos[i].streamname) === -1) {
                selectedFiles.push(videos[i].streamname);
            } else {
                selectedFiles.splice(selectedFiles.indexOf(videos[i].streamname),1);
            }
        }
        this.setState({selectedFiles: selectedFiles});
    }

    handleDeselectAll() {
        this.setState({selectedFiles: []});
    }

    focusCreateFolderReal() {
        if((document.getElementById("createFolderName") as HTMLInputElement)) {
            (document.getElementById("createFolderName") as HTMLInputElement).focus();
        }
    }

    focusCreateFolder() {
        setTimeout(this.focusCreateFolderReal.bind(this),800);
    }

    handleModifyFolderModalToggle() {
        this.setState({ modifyFolderModalOpen: !this.state.modifyFolderModalOpen },this.focusModifyFolder.bind(this));
    }

    focusModifyFolderReal() {
        if((document.getElementById("modifyFolderName") as HTMLInputElement)) {
            (document.getElementById("modifyFolderName") as HTMLInputElement).value = this.state.curFolderName;
            (document.getElementById("modifyFolderName") as HTMLInputElement).focus();
            (document.getElementById("modifyFolderName") as HTMLInputElement).select();
        }
    }

    focusModifyFolder() {
        setTimeout(this.focusModifyFolderReal.bind(this),800);
    }

    handleMoveFilesModalToggle() {
        this.setState({ moveFilesModalOpen: !this.state.moveFilesModalOpen });
    }

    handleDeleteFilesModalToggle() {
        this.setState({ deleteFilesModalOpen: !this.state.deleteFilesModalOpen });
    }

    handleFolderDeleteModalToggle() {
        this.setState({ deleteFolderModalOpen: !this.state.deleteFolderModalOpen });
    }

    handleCreateFolder() {
        let folderName = (document.getElementById("createFolderName") as HTMLInputElement).value;
        this.handleCreateFolderModalToggle();
        this.createFolder(folderName);
    }

    handleCreateFolderKeyPress(e:React.KeyboardEvent) {
        if(e.key === "Enter") {
            e.preventDefault();
            this.handleCreateFolder();
        } else if(e.key === "Escape") {
            this.handleCreateFolderModalToggle();
        }
    }

    handleModifyFolderKeyPress(e:React.KeyboardEvent) {
        if(e.key === "Enter") {
            e.preventDefault();
            this.handleModifyFolder();
        } else if(e.key === "Escape") {
            this.handleModifyFolderModalToggle();
        }
    }

    async createFolder(folderName:string) {
        let jsonurl = ApiHelper.getAPIUrl(this.context) + "/folders";

        if (this.context) {
            if(!this.context.loggedin) {
                await ApiHelper.checkLoginStatus(this.context);
            }
            jsonurl += "?producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        }

        jsonurl += "&category=videos";

        let response: any = null;

        try {
            response = await fetch(jsonurl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    folder: folderName
                })
            });
        } catch (e) {
            this.setState({ errorMessage: "Error: " + e });
            return;
        }

        if (response.status === 200) {
            this.setState({ infoMessage: "Folder created successfully." });
            this.fetchFolders();
        } else {
            this.setState({ errorMessage: "Error: " + response.statusText });
        }
    }

    handleModifyFolder() {
        let folderName = (document.getElementById("modifyFolderName") as HTMLInputElement).value;
        this.handleModifyFolderModalToggle();
        this.modifyFolder(folderName);
    }

    handleDeleteFolder() {
        this.handleFolderDeleteModalToggle();
        this.deleteFolder();
    }

    async modifyFolder(folderName:string) {
        let jsonurl = ApiHelper.getAPIUrl(this.context) + "/folders/" + encodeURIComponent(this.state.curFolderGuid);

        if (this.context) {
            if(!this.context.loggedin) {
                await ApiHelper.checkLoginStatus(this.context);
            }
            jsonurl += "?producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        }

        let response: any = null;

        try {
            response = await fetch(jsonurl, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    folder: folderName
                })
            });
        } catch (e) {
            this.setState({ errorMessage: "Error: " + e });
            return;
        }

        if (response.status === 200) {
            this.setState({ infoMessage: "Folder modified successfully." });
            this.setState({ curFolderName: folderName })
            this.fetchFolders();
        } else {
            this.setState({ errorMessage: "Error: " + response.statusText });
        }
    }

    async deleteFolder() {
        let jsonurl = ApiHelper.getAPIUrl(this.context) + "/folders/" + encodeURIComponent(this.state.curFolderGuid);

        if (this.context) {
            if(!this.context.loggedin) {
                await ApiHelper.checkLoginStatus(this.context);
            }

            jsonurl += "?producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        }

        let response: any = null;

        try {
            response = await fetch(jsonurl, {
                method: "DELETE"
            });
        } catch (e) {
            this.setState({ errorMessage: "Error: " + e });
            return;
        }

        if (response.status === 200) {
            this.setState({ infoMessage: "Folder deleted successfully." });
            this.fetchFolders();
        } else {
            this.setState({ errorMessage: "Error: " + response.statusText });
        }
    }

    async fetchFolders() {
        let jsonurl = ApiHelper.getAPIUrl(this.context) + "/folders";

        if (this.context) {
            if(!this.context.loggedin) {
                await ApiHelper.checkLoginStatus(this.context);
            }

            jsonurl += "?producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        }

        jsonurl += "&category=videos";

        let response: any = null;

        try {
            response = await fetch(jsonurl);
        } catch (e) {
            this.setState({ errorMessage: "Error: " + e });
            return;
        }

        if (response.status === 200) {
            let data = await response.json();
            this.setState({ folders: data.folders });
        } else {
            this.setState({ errorMessage: "Error: " + response.statusText });
        }
    }

    handleMoveToFolderDropdownToggle() {
        this.setState({ moveToFolderDropdownOpen: !this.state.moveToFolderDropdownOpen });
    }

    handleMoveToFolderChange(folder:FolderData|null) {
        if(folder === null) {
            this.setState({ moveToFolderGuid: "", moveToFolderName: "" });
        } else {
            this.setState({ moveToFolderGuid: folder.guid, moveToFolderName: folder.folder });
        }
    }

    handleMoveFiles() {
        this.handleMoveFilesModalToggle();
        this.moveFiles();
    }

    handleDeleteFiles() {
        this.handleDeleteFilesModalToggle();
        this.deleteFiles();
    }

    async moveFiles() {
        let jsonurl = ApiHelper.getAPIUrl(this.context) + "/videos/move";

        if (this.context) {
            if(!this.context.loggedin) {
                await ApiHelper.checkLoginStatus(this.context);
            }

            jsonurl += "?producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        }

        let response: any = null;

        try {
            response = await fetch(jsonurl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    folder: this.state.moveToFolderGuid,
                    videos: this.state.selectedFiles
                })
            });
        } catch (e) {
            this.setState({ errorMessage: "Error: " + e });
            return;
        }
        
        if (response.status === 200) {
            this.setState({ infoMessage: "Files moved successfully." });
            this.fetchData();
        } else {
            this.setState({ errorMessage: "Error: " + response.statusText });
        }
    }

    async deleteFiles() {
        let jsonurl = ApiHelper.getAPIUrl(this.context) + "/videos";

        if (this.context) {
            if(!this.context.loggedin) {
                await ApiHelper.checkLoginStatus(this.context);
            }

            jsonurl += "?producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        }

        let response: any = null;

        try {
            response = await fetch(jsonurl, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    videos: this.state.selectedFiles
                })
            });
        } catch (e) {
            this.setState({ errorMessage: "Error: " + e });
            return;
        }

        if (response.status === 200) {
            this.setState({ infoMessage: "Files deleted successfully." });
            this.fetchData();
        } else {
            this.setState({ errorMessage: "Error: " + response.statusText });
        }
    }

    fetchData = async() => {
        this.setState({ fetchingVideos: true });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/videos?role=producer";

        if (this.context) {
            if(!this.context.loggedin) {
                await ApiHelper.checkLoginStatus(this.context);
            }
            jsonurl += "&producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        }

        jsonurl += "&videoType=" + encodeURIComponent(this.state.videoType);
        if(this.state.curPage > 0) {
            jsonurl += "&startindex=" + encodeURIComponent((this.state.curPage * this.state.pageSize));
        }
        jsonurl += "&sort=" + encodeURIComponent(this.state.sort);
        jsonurl += "&pagesize=" + encodeURIComponent(this.state.pageSize);
        jsonurl += "&folder=" + encodeURIComponent(this.state.curFolderGuid);
        if(this.state.platform != "") {
            jsonurl += "&platform=" + encodeURIComponent(this.state.platform);
        }

        let response: any = null;

        try {
            response = await fetch(jsonurl);
            let data = await response.json();
            this.setState({ fetchingVideos: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                    if(!ApiHelper.checkResponse(data,this.context) && this.context !== null) {
                        this.context.producerID = "";
                        alert("Please relogin");
                    }
                }
            }

            if (data.videos) {
                this.setState({ videos: data.videos });
                this.handlePagination(data.total_items);
            }
        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching data: " + error.toString() });
            this.setState({ fetchingVideos: false });
        }
    }

    getText(exp: string) {
        if (this.state.language === "de") {
            if( exp === "kostenfreieVideosHinzufuegen") {
                return "kostenfreie Videos hinzufügen";
            }
            if( exp === "kostenpflichtigeVideosHinzufuegen") {
                return "kostenpflichtige Videos hinzufügen";
            }
            if (exp === "ConfirmSelection") {
                return "Auswahl bestätigen";
            }
            if(exp === "SelectAll") {
                return "Alle auswählen";
            }
            if(exp === "SelectInvert") {
                return "Auswahl umkehren";
            }
            if(exp === "SelectNone") {
                return "Auswahl aufheben";
            }
            if (exp === "selected") {
                return "ausgewählt";
            }
            if (exp === "Move") {
                return "Verschieben";
            }
            if(exp === "MoveFiles") {
                return "Videos verschieben";
            }
            if(exp === "DeleteFiles") {
                return "Videos löschen";
            }
            if(exp === "MoveFilesToFolder") {
                return "Wähle unten den Zielordner aus, in den die Videos verschoben werden sollen.";
            }
            if(exp === "SelectFolder") {
                return "Ordner auswählen";
            }
            if(exp === "DeleteFilesWarning") {
                return "Wenn die Videos gelöscht werden, kann dies nicht rückgängig gemacht werden.";
            }
            if (exp === "Videos") {
                return "Videos";
            }
            if(exp === "Folders") {
                return "Ordner";
            }
            if(exp === "CreateFolder") {
                return "Ordner erstellen";
            }
            if(exp === "DeleteFolder") {
                return "Ordner löschen";
            }
            if(exp === "FolderName") {
                return "Ordnername";
            }
            if(exp === "Create") {
                return "Erstellen";
            }
            if(exp === "Modify") {
                return "Ändern";
            }
            if(exp === "Delete") {
                return "Löschen";
            }
            if(exp === "Cancel") {
                return "Abbrechen";
            }
            if(exp === "DeleteFolderWarning") {
                return "Wenn der Ordner gelöscht wird, kann dies nicht rückgängig gemacht werden, alle Videos in dem Ordner werder ebenfalls gelöscht. Bist Du sicher?";
            }
            if (exp === "RootDir") {
                return "Hauptordner";
            }
            if (exp === "Posted") {
                return "In Timeline";
            }
            if (exp === "Not Posted") {
                return "Nicht in Timeline";
            }
            if (exp === "AddedPerson") {
                return "Hinzugefügte Person";
            }
            if (exp === "IDShotsExplanation") {
                return "Falls weitere Personen im Video auftauchen, benötigen wir eine Einverständniserklärung von diesen Personen. Diese kannst Du hier hochladen bzw. dem Video hinzufügen.";
            }
            if(exp === "AddIDShot") {
                return "Einverständniserklärung für Person hinzufügen";
            }
            if(exp === "VideoUploaded") {
                return "Video hochgeladen";
            }
            if(exp === "ReallyDeleteVideo") {
                return "Bist Du sicher, dass Du das Video löschen möchtest?";
            }
            if (exp === "Username") {
                return "Pseudo";
            }
            if (exp === "Deine Videos") {
                return "Deine Videoliste";
            }
            if (exp === "Reviewed") {
                return "Geprüft";
            }
            if (exp === "In review") {
                return "In Prüfung";
            }
            if (exp === "No Title") {
                return "Ohne Titel";
            }
            if(exp === "Change Pic") {
                return "Bild ändern";
            }
            if (exp === "Published") {
                return "Zur Veröffentlichung freigegeben";
            }
            if (exp === "Uploaded") {
                return "Hochgeladen";
            }
            if (exp === "ReviewStatus") {
                return "Prüfstatus";
            }
            if (exp === "Title") {
                return "Videotitel";
            }
            if (exp === "Description") {
                return "Beschreibungstext";
            }
            if (exp === "Public") {
                return "Veröffentlicht";
            }
            if (exp === "Not public") {
                return "Privat";
            }
            if(exp === "Altersverifikation für Drehpartner Description") {
                return "Der Gesetzgeber verlangt von uns Alter und Identität der Darsteller in Deinen Videos sicher zu stellen. Du kannst hier ab sofort eine Liste von Darstellern anlegen, die in Deinen Filmen mitwirken und einen ID-Shot für jeden Darsteller hochladen. Zusätzlich benötigen wir die von Deinem Drehpartner unterschriebene Einverständniserklärung.";
            }
            if(exp === "Altersverifikation für Drehpartner Description2") {
                return "Bei jedem Video, dass Du neu hochlädst musst Du angeben, wer neben Dir noch in den Videos mitwirkt. Erst dann können wir ein Video freigeben.";
            }
            if(exp === "SelectAutoThumb") {
                return "Thumbnail";
            }
            if(exp === "UploadPic") {
                return "Bild hochladen";
            }
            if(exp === "DeleteCurrentPic") {
                return "Bild löschen";
            }
            if(exp === "CopyToSoft") {
                return "Kopieren";
            }
            if(exp === "CopyToHard") {
                return "Kopieren";
            }
            if(exp === "NoIDShots") {
                return "Diesem Video sind im Moment keine Einverständniserklärungen von weiteren Personen zugeordnet";
            }
            if(exp === "NoFiles") {
                return "Keine Dateien zu diesem Datensatz";
            }
            if (exp === "Vorname") {
                return "Vorname";
            }
            if (exp === "Nachname") {
                return "Nachname";
            }
            if (exp === "Geburtsdatum") {
                return "Geburtsdatum";
            }
            if(exp === "uploadtime") {
                return "Zeitpunkt";
            }
            if(exp === "sortindex") {
                return "Eigene Sotierung";
            }
            if(exp === "dimensions") {
                return "Pixelauflösung";
            }
            if(exp === "uploadsize") {
                return "Dateigrösse";
            }
            if(exp === "reviewstatus") {
                return "Freischaltung";
            }
            if(exp === "adultstatus") {
                return "Alterseinstufung";
            }
        } else {
            if( exp === "kostenfreieVideosHinzufuegen") {
                return "Add free videos"
            }
            if( exp === "kostenpflichtigeVideosHinzufuegen") {
                return "Add paid videos";
            }
            if (exp === "ConfirmSelection") {
                return "Confirm selection";
            }
            if(exp === "SelectAll") {
                return "Select all";
            }
            if(exp === "SelectInvert") {
                return "Invert selection";
            }
            if(exp === "SelectNone") {
                return "Select none";
            }
            if (exp === "selected") {
                return "selected";
            }
            if (exp === "Move") {
                return "Move";
            }
            if(exp === "MoveFiles") {
                return "Move videos";
            }
            if(exp === "DeleteFiles") {
                return "Delete videos";
            }
            if(exp === "MoveFilesToFolder") {
                return "Select a folder below to move the videos to."
            }
            if(exp === "SelectFolder") {
                return "Select folder";
            }
            if(exp === "DeleteFilesWarning") {
                return "If the videos are deleted, this cannot be undone.";
            }
            if(exp === "CreateFolder") {
                return "Create folder";
            }
            if(exp === "DeleteFolder") {
                return "Delete folder";
            }
            if(exp === "FolderName") {
                return "Folder Name";
            }
            if(exp === "Create") {
                return "Create";
            }
            if(exp === "Modify") {
                return "Modify";
            }
            if(exp === "Delete") {
                return "Delete";
            }
            if(exp === "Folders") {
                return "Folders";
            }
            if (exp === "RootDir") {
                return "Root Folder";
            }
            if(exp === "Cancel") {
                return "Cancel";
            }
            if(exp === "DeleteFolderWarning") {
                return "If the folder is deleted, this cannot be undone, all videos in the folder will also be deleted. Are you sure?";
            }
            if (exp === "AddedPerson") {
                return "Assgined Person";
            }
            if (exp === "IDShotsExplanation") {
                return "If other people appear in the video, we need a letter of consent from these people. You can upload it here or add it to the video.";
            }
            if(exp === "AddIDShot") {
                return "Add letter of consent for person in video";
            }
            if(exp === "NoFiles") {
                return "No files for this record";
            }
            if (exp === "Vorname") {
                return "First name";
            }
            if (exp === "Nachname") {
                return "Last name";
            }
            if (exp === "Geburtsdatum") {
                return "Birthday";
            }
            if(exp === "NoIDShots") {
                return "Currently there are no letters of consent for people in this video attached";
            }
            if(exp === "VideoUploaded") {
                return "Video uploaded";
            }
            if(exp === "ReallyDeleteVideo") {
                return "Are you sure you want to delete this video?";
            }
            if (exp === "Username") {
                return "Livecam Profile";
            }
            if (exp === "Deine Videos") {
                return "Your Videolist";
            }
            if(exp === "Hier geht es zur ID-Shot Verwaltung") {
                return "To ID-Shot management";
            }
            if(exp === "Altersverifikation für Drehpartner") {
                return "Age verification for people in videos";
            }
            if(exp === "Altersverifikation für Drehpartner Description") {
                return "Legislation requires us to ensure the age and identity of the actors in your videos. You can create a list of actors who act in your videos and upload an ID shot for each actor. In addition, we need the declaration of consent signed by your shooting partner.";
            }
            if(exp === "Altersverifikation für Drehpartner Description2") {
                return "For each new video that you upload, you have to indicate who else is involved in the videos alongside you. Only then can we release a video.";
            }
            if(exp === "SelectAutoThumb") {
                return "Thumbnail";
            }
            if(exp === "UploadPic") {
                return "Upload Picture";
            }
            if(exp === "DeleteCurrentPic") {
                return "Delete Picture";
            }
            if(exp === "CopyToSoft") {
                return "Copy";
            }
            if(exp === "CopyToHard") {
                return "Copy";
            }
            if(exp === "uploadtime") {
                return "Upload time";
            }
            if(exp === "sortindex") {
                return "Custom sort";
            }
            if(exp === "dimensions") {
                return "Pixel resolution";
            }
            if(exp === "uploadsize") {
                return "File size";
            }
            if(exp === "reviewstatus") {
                return "Review status";
            }
            if(exp === "adultstatus") {
                return "Adult status";
            }
        }

        return exp;
    }

    clickVideo(selectedVideoData:VideoData) {
        if(typeof(this.props.onVideoClick) === "function") {
            this.props.onVideoClick(selectedVideoData);
        } else {
            this.setState({ showUpload: false });
            this.setState({ videoPlayURL: "" });
            this.setState({ selectedVideoData: selectedVideoData });
        }
    }

    confirmSelection() {
        if(this.state.selectedFiles) {
            if(this.state.selectedFiles.length > 0) {
                let videoDataArray:VideoData[] = [];

                for(let i = 0; i < this.state.selectedFiles.length; i++) {
                    let newVideo:VideoData|undefined = this.state.videos.find((video:VideoData) => video.streamname === this.state.selectedFiles[i]);
                    if(newVideo !== null && newVideo !== undefined) {
                        videoDataArray.push(newVideo);
                    }
                }
                this.props.confirmSelection(videoDataArray);
            }
        }
    }

    deleteVideo(selectedVideoData:VideoData) {
        if(window.confirm(this.getText("ReallyDeleteVideo"))) {
            this.setState({ showUpload: false });
            this.setState({ videoPlayURL: "" });
            this.setState({ selectedVideoData: null });
            this.setState({ idshots: [] });
            this.deleteSingleVideo(selectedVideoData.streamname);
        }
    }

    deleteSingleVideo = async(streamName:string) => {
        this.setState({ singleVideoAPIAction: true });
        this.setState({ errorMessage: "" });
        this.setState({ successMessage: "" });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/video/delete/" + encodeURIComponent(streamName);

        if (this.context) {
            jsonurl += "?producerOrUserID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken) + "&role=producer";
        } else {
            alert("Cannot delete video because of missing context");
        }

        let response: any = null;

        try {
            response = await fetch(jsonurl);
            let data = await response.json();
            this.setState({ singleVideoAPIAction: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                    if(!ApiHelper.checkResponse(data,this.context) && this.context !== null) {
                        this.context.producerID = "";
                        alert("Please relogin");
                    }
                }
            }

            this.setState({ videoPlayURL: data.url });

            if(data.success) {
                this.setState({ successMessage: data.message });
                setTimeout(this.fetchData.bind(this),3000);
            } else {
                this.setState({ errorMessage: data.error });
            }

        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching data: " + error.toString() });
            this.setState({ singleVideoAPIAction: false });
        }
    }

    render() {
        if (this.context) {
            if (this.context.producerID === "") {
                return <Redirect to="/" push={true} />
            }
        } else {
            return <Redirect to="/" push={true} />
        }

        return (
            <div id='VideoListDIV'>
                <Modal isOpen={this.state.createFolderModalOpen}>
                    <ModalHeader>
                        <span>{this.getText("CreateFolder")}</span>
                    </ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup>
                                <Label for="folderName">{this.getText("FolderName")}</Label>
                                <Input type="text" name="createFolderName" id="createFolderName" placeholder={this.getText("FolderName")} onKeyPress={this.handleCreateFolderKeyPress.bind(this)} />
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleCreateFolder.bind(this)}>{this.getText("Create")}</Button>{' '}
                        <Button color="secondary" onClick={this.handleCreateFolderModalToggle.bind(this)}>{this.getText("Cancel")}</Button>
                    </ModalFooter>
                </Modal>
                 <Modal isOpen={this.state.modifyFolderModalOpen}>
                    <ModalHeader>
                        <span>{this.getText("ModifyFolder")} {this.state.curFolderName}</span>
                    </ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup>
                                <Label for="folderName">{this.getText("FolderName")}</Label>
                                <Input type="text" name="modifyFolderName" id="modifyFolderName" placeholder={this.getText("FolderName")} onKeyPress={this.handleModifyFolderKeyPress.bind(this)} />
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleModifyFolder.bind(this)}>{this.getText("Modify")}</Button>{' '}
                        <Button color="secondary" onClick={this.handleModifyFolderModalToggle.bind(this)}>{this.getText("Cancel")}</Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.deleteFolderModalOpen}>
                    <ModalHeader>
                        <span>{this.getText("DeleteFolder")} {this.state.curFolderName}</span>
                    </ModalHeader>
                    <ModalBody>
                        <Alert color='warning'>
                            {this.getText("DeleteFolderWarning")}
                        </Alert>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={this.handleDeleteFolder.bind(this)}>{this.getText("Delete")}</Button>{' '}
                        <Button color="secondary" onClick={this.handleFolderDeleteModalToggle.bind(this)}>{this.getText("Cancel")}</Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.deleteFilesModalOpen}>
                    <ModalHeader>
                        <span>{this.getText("DeleteFiles")} <span className='badge bg-secondary'>{this.state.selectedFiles.length} {this.getText("selected")}</span></span>
                    </ModalHeader>
                    <ModalBody>
                        <Alert color='warning'>
                            {this.getText("DeleteFilesWarning")}
                        </Alert>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={this.handleDeleteFiles.bind(this)}>{this.getText("DeleteFiles")}</Button>{' '}
                        <Button color="secondary" onClick={this.handleDeleteFilesModalToggle.bind(this)}>{this.getText("Cancel")}</Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.moveFilesModalOpen}>
                    <ModalHeader>
                        <span>{this.getText("MoveFiles")} <span className='badge bg-secondary'>{this.state.selectedFiles.length} {this.getText("selected")}</span></span>
                    </ModalHeader>
                    <ModalBody>
                        <p>
                            {this.getText("MoveFilesToFolder")}
                        </p>
                        <FormGroup>
                            <Label>{this.getText("SelectFolder")}</Label>
                            <Dropdown isOpen={this.state.moveToFolderDropdownOpen} toggle={this.handleMoveToFolderDropdownToggle.bind(this)} direction="down">
                                <DropdownToggle size='sm' caret>{this.state.moveToFolderName !== "" ? this.state.moveToFolderName : this.getText("RootDir")}</DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem className={this.state.moveToFolderGuid === "" && this.state.folders.length > 0 ? "bg-secondary text-white" : ""} onClick={this.handleMoveToFolderChange.bind(this,null)}>{this.getText("RootDir")}</DropdownItem>
                                    { this.state.folders ? this.state.folders.map((folder,index) =>
                                        <DropdownItem className={folder.guid === this.state.moveToFolderGuid ? "bg-secondary text-white" : ""} onClick={this.handleMoveToFolderChange.bind(this,folder)}>{folder.folder}</DropdownItem>
                                    ) : null }
                                </DropdownMenu>
                            </Dropdown>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="warning" onClick={this.handleMoveFiles.bind(this)}>{this.getText("MoveFiles")}</Button>{' '}
                        <Button color="secondary" onClick={this.handleMoveFilesModalToggle.bind(this)}>{this.getText("Cancel")}</Button>
                    </ModalFooter>
                </Modal>
                {this.state.successMessage !== "" ? <Alert color="success">{this.state.successMessage}</Alert> : null}
                {this.state.errorMessage !== "" ? <Alert color="danger">{this.state.errorMessage}</Alert> : null}
                <div id="VideoListPane" className="d-flex flex-column align-items-stretch flex-shrink-0 bg-white">
                    <Row className='mb-2'>
                        <Col xs="12" lg={this.state.modalMode ? 4 : 6} className='paginationColumnLeft'>
                            {this.state.pagesArray ? this.state.pagesArray.length > 0 ?
                            <Pagination size='sm'>
                                { this.state.pagesArray.map((page,index) =>
                                    <PaginationItem style={{display: "list-item"}} active={page.active} className={page.page < this.state.maxPages ? "" : "d-none"}><PaginationLink onClick={this.handlePageChange.bind(this,page.page)} style={{color: "#000"}}>{page.page + 1}</PaginationLink></PaginationItem>
                                )}
                                { this.state.pagesArray.length > 10 ?
                                <Dropdown className='paginationExtraDropdown' isOpen={this.state.extraPagesDropdownOpen} toggle={this.handleExtraPagesDropdownToggle.bind(this)} direction="down">
                                    <DropdownToggle size='sm' caret>{this.state.curPage >= this.state.maxPages ? this.state.curPage : this.getText("More")}</DropdownToggle>
                                    <DropdownMenu>
                                        { this.state.pagesArray.map((page,index) =>
                                            <DropdownItem className={page.page >= this.state.maxPages ? page.active ? "bg-secondary text-white" : "" : "d-none"} onClick={this.handlePageChange.bind(this,page.page)}>{page.page + 1}</DropdownItem>
                                        )}
                                    </DropdownMenu>
                                </Dropdown> : null }
                            </Pagination>
                            : null : null }
                        </Col>
                        <Col xs="12" lg={this.state.modalMode ? 8 : 6} className='paginationColumnRight'>
                            <Dropdown style={{display: "inline-block",marginRight: "10px"}} isOpen={this.state.pageSizeDropdownOpen} toggle={this.handlePageSizeDropdownToggle.bind(this)} direction="down">
                                <DropdownToggle size='sm' caret>{this.getText("PageSize")} {this.state.pageSize}</DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={this.handlePageSizeChange.bind(this,12)}>12</DropdownItem>
                                    <DropdownItem onClick={this.handlePageSizeChange.bind(this,24)}>24</DropdownItem>
                                    <DropdownItem onClick={this.handlePageSizeChange.bind(this,36)}>36</DropdownItem>
                                    <DropdownItem onClick={this.handlePageSizeChange.bind(this,48)}>48</DropdownItem>
                                    <DropdownItem onClick={this.handlePageSizeChange.bind(this,60)}>60</DropdownItem>
                                    <DropdownItem onClick={this.handlePageSizeChange.bind(this,72)}>72</DropdownItem>
                                    <DropdownItem onClick={this.handlePageSizeChange.bind(this,100)}>100</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            <Dropdown style={{display: "inline-block",marginRight: "10px"}} isOpen={this.state.sortDropdownOpen} toggle={this.handleSortDropdownToggle.bind(this)} direction="down">
                                <DropdownToggle size='sm' caret>{this.getText("Sort")} {this.getText(this.state.sort)}</DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={this.handleSortChange.bind(this,"uploadtime")}>{this.getText("uploadtime")}</DropdownItem>
                                    <DropdownItem onClick={this.handleSortChange.bind(this,"dimensions")}>{this.getText("dimensions")}</DropdownItem>
                                    <DropdownItem onClick={this.handleSortChange.bind(this,"uploadsize")}>{this.getText("uploadsize")}</DropdownItem>
                                    <DropdownItem onClick={this.handleSortChange.bind(this,"reviewstatus")}>{this.getText("reviewstatus")}</DropdownItem>
                                    <DropdownItem onClick={this.handleSortChange.bind(this,"adultstatus")}>{this.getText("adultstatus")}</DropdownItem>
                                    <DropdownItem onClick={this.handleSortChange.bind(this,"ranking")}>{this.getText("ranking")}</DropdownItem>
                                    <DropdownItem className='d-none' onClick={this.handleSortChange.bind(this,"sortindex")}>{this.getText("sortindex")}</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            <Button size="sm" className='ml-2 d-none' onClick={this.fetchData.bind(this)}><FontAwesomeIcon icon={solid('sync')} fixedWidth /> {this.getText("Refresh")}</Button>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs="12" lg={this.state.modalMode ? 4 : 6} className='paginationColumnLeft'>
                            <h5>
                                {this.getText("Videos")}&nbsp;
                                <Dropdown style={{display: "inline-block",marginRight: "0px"}} isOpen={this.state.foldersDropdownOpen} toggle={this.handleFoldersDropdownToggle.bind(this)} direction="down">
                                    <DropdownToggle size='sm' caret>{this.state.curFolderName !== "" ? this.state.curFolderName : this.getText("RootDir")}</DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem className={this.state.curFolderGuid === "" && this.state.folders.length > 0 ? "bg-secondary text-white" : ""} onClick={this.handleFolderChange.bind(this,null)}>{this.getText("RootDir")}</DropdownItem>
                                        { this.state.folders ? this.state.folders.map((folder,index) =>
                                            <DropdownItem className={folder.guid === this.state.curFolderGuid ? "bg-secondary text-white" : ""} onClick={this.handleFolderChange.bind(this,folder)}>{folder.folder}</DropdownItem>
                                        ) : null }
                                    </DropdownMenu>
                                </Dropdown>
                                {this.state.curFolderGuid !== "" ? <Button size='sm' color='secondary' style={{display: "inline-block"}} onClick={this.handleFolderChange.bind(this,null)}><FontAwesomeIcon icon={faFolderArrowUp}></FontAwesomeIcon></Button> : null}
                                <Button style={{display: "inline-block",marginLeft: "10px"}} size='sm' color='secondary' onClick={this.handleCreateFolderModalToggle.bind(this)}><FontAwesomeIcon icon={faFolderPlus} /></Button>
                                {this.state.curFolderGuid !== "" ? <Button size='sm' color='secondary' style={{display: "inline-block",marginLeft: "10px"}} onClick={this.handleModifyFolderModalToggle.bind(this)}><FontAwesomeIcon icon={faFolderCog}></FontAwesomeIcon></Button> : null}
                                {this.state.curFolderGuid !== "" ? <Button size='sm' color='secondary' style={{display: "inline-block",marginLeft: "10px"}} onClick={this.handleFolderDeleteModalToggle.bind(this)}><FontAwesomeIcon icon={faFolderXmark} /></Button> : null}
                            </h5>
                        </Col>
                        <Col xs="12" lg={this.state.modalMode ? 8 : 6} className='paginationColumnRight'>
                            <Dropdown style={{display: "inline-block",marginRight: "0px"}} isOpen={this.state.fileSelectionDropdownOpen} toggle={this.handleFileSelectionDropdownToggle.bind(this)} direction="down">
                                <DropdownToggle size='sm' caret>{this.state.selectedFiles.length} {this.getText("selected")}</DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={this.handleSelectAll.bind(this)}>{this.getText("SelectAll")}</DropdownItem>
                                    <DropdownItem onClick={this.handleSelectInvert.bind(this)}>{this.getText("SelectInvert")}</DropdownItem>
                                    <DropdownItem onClick={this.handleDeselectAll.bind(this)}>{this.getText("SelectNone")}</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            <Button disabled={this.state.selectedFiles.length === 0 ? true : false} size='sm' color='secondary' style={{display: "inline-block",marginLeft: "10px"}} onClick={this.handleMoveFilesModalToggle.bind(this)}>
                                <FontAwesomeIcon icon={faFileExport}></FontAwesomeIcon> {this.getText("Move")}
                            </Button>
                            <Button disabled={this.state.selectedFiles.length === 0 ? true : false} size='sm' color='secondary' style={{display: "inline-block",marginLeft: "10px"}} onClick={this.handleDeleteFilesModalToggle.bind(this)}>
                                <FontAwesomeIcon icon={faFileXmark}></FontAwesomeIcon> {this.getText("Delete")}
                            </Button>
                        </Col>
                    </Row>

                    { !this.state.singleSelect && this.state.mediaAddMode !== "" && this.state.selectedFiles.length > 0 ? <p className='text-center mt-2'><Button size="lg" color='primary' onClick={this.confirmSelection.bind(this)}>{this.state.selectedFiles.length} {this.state.mediaAddMode === "free" ? this.getText("kostenfreieVideosHinzufuegen") : this.getText("kostenpflichtigeVideosHinzufuegen")}</Button></p> : null }

                    {this.state.fetchingVideos ? <p className="pt-4"><FontAwesomeIcon icon={solid('cog')} spin size="4x" /></p> :
                        <div className="mt-2 d-flex flex-wrap" style={{gap: "4px"}}>
                            {this.state.videos.map(video =>
                                <div className="videoItem">
                                    <div className="d-flex justify-content-between w-100">
                                        <strong className="mb-1">
                                            <input type='checkbox' className='videoSelectCheckBox' checked={this.state.selectedFiles.includes(video.streamname)} onChange={this.handleVideoSelect.bind(this,video.streamname)} />
                                        </strong>
                                        <small onClick={this.clickVideo.bind(this,video)}>
                                            {video.title && video.title !== "" ? video.title : this.getText("No Title")}
                                        </small>
                                        <div>
                                            { this.state.mediaAddMode === "" ? <FontAwesomeIcon style={{cursor: "pointer"}} onClick={this.clickVideo.bind(this,video)} icon={faMagnifyingGlass} fixedWidth /> : null }
                                            <FontAwesomeIcon style={{cursor: "pointer"}} onClick={this.deleteVideo.bind(this,video)} icon={faRemove} fixedWidth />
                                        </div>
                                    </div>
                                    <div className="d-flex w-100 align-items-center justify-content-between flex-wrap videoBoxUpper">
                                        <small onClick={this.clickVideo.bind(this,video)}>{DateTime.fromISO(video.uploadTime.toString()).toLocaleString(DateTime.DATETIME_MED)}&nbsp;</small>
                                    </div>
                                    <div className="w-100 d-flex mb-1">
                                        <img src={video.previewImageURLHard ? video.previewImageURLHard : "https://placehold.co/200?text=No+hard+Image"} onClick={this.clickVideo.bind(this,video)} className="videoListItem w-50" />
                                        <img src={video.previewImageURLSoft ? video.previewImageURLSoft : "https://placehold.co/200?text=No+soft+Image"} onClick={this.clickVideo.bind(this,video)} className="videoListItem w-50" />
                                    </div>
                                    <div className="d-flex justify-content-between videoBoxLower">
                                        <span>
                                            {video.reviewStatus === 1 ? <span className='badge bg-secondary'><FontAwesomeIcon icon={solid("check-circle")}></FontAwesomeIcon> {this.getText("Reviewed")}</span> : <span className='badge bg-secondary'>{this.getText("In review")}</span> }
                                        </span>
                                        <span>
                                            {video.timelinePostGuid != null ? <span className='badge bg-success'><FontAwesomeIcon icon={solid("megaphone")}></FontAwesomeIcon> {this.getText("Posted")}</span> : <span className='badge bg-secondary'>{this.getText("Not posted")}</span> }
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    }
                </div>
            </div>
        )
    }
}