import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { LoginForm } from './components/LoginForm';
import { LogoutForm } from './components/LogoutForm';
import { SignupForm } from './components/SignupForm';
import { Support } from './components/Support';
import { UserList } from './components/UserList';
import { Events } from './components/Events';
import { VideosPage } from './components/VideosPage';
import { AudioList } from './components/AudioList';
import { Inbox } from './components/Inbox';
import { Timeline } from './components/Timeline';
import { MyAccount } from './components/MyAccount';
import { Messenger } from './components/Messenger';
import { ShopItemsList } from './components/ShopItemsList';
import { ShopItemEdit } from './components/ShopItemEdit';
import { SubscriptionTiers } from './components/SubscriptionTiers';
import { Agb } from './components/agb';
import { Impressum } from './components/impressum';
import { PrivacyPolicy } from './components/PrivacyPolicy';
import { MasterDataEdit } from './components/MasterDataEdit';
import { LivecamEdit } from './components/LivecamEdit';
import { SalesList } from './components/SalesList';
import { ChooseMyConditions } from './components/ChooseMyConditions';
import './custom.css'
import { ImagesPage } from './components/ImagesPage';
import { ProfileEdit } from './components/ProfileEdit';
import { IDShotManager } from './components/IDShotManager';
import { AppCtx, AppContextInterface } from './AppContextInterface';
//import { Amplify, Auth, Analytics } from 'aws-amplify';
import { ServiceWorker } from 'aws-amplify';
import { initializeApp } from "firebase/app";
import { getMessaging,getToken } from "firebase/messaging";
//import awsconfig from './aws-exports.js';
//Amplify.configure(awsconfig);

// Provider in your app
const sampleAppContext: AppContextInterface = {
    platform: "fans",
    producerID: "",
    language: "de",
    pseudo: localStorage.getItem("pseudo") || "",
    loggedin: false,
    infomsg: "",
    authToken: "",
    jwtAuthToken: "",
    refreshToken: "",
    api: "main",
    email: "",
    phonenumber: "",
    cognitoUser: null,
    doUpdateUnread: false
};

export interface App {
    contextType: AppContextInterface;
}

export class App extends Component {
    static contextType = AppCtx;
    context!: React.ContextType<typeof AppCtx>;

    static displayName = App.name;

    constructor(props:any) {
        super(props);
    }

    componentDidMount(): void {
        if(this.context) {
            if(window.location.hostname === "control.deine.fans" || window.location.hostname === "control.cherry.fans" || window.location.hostname === "control.livestrip.fans") {
                this.context.platform = "fans";
            } else {
                this.context.platform = "guppy";
            }
        }

        /*Analytics.autoTrack('session', {
            // REQUIRED, turn on/off the auto tracking
            enable: true,
            // OPTIONAL, the attributes of the event, you can either pass an object or a function 
            // which allows you to define dynamic attributes
            attributes: {
                pseudo: this.context?.pseudo,
                producerID: this.context?.producerID,
                language: this.context?.language
            },
            // when using function
            // attributes: () => {
            //    const attr = somewhere();
            //    return {
            //        myAttr: attr
            //    }
            // },
            // OPTIONAL, the service provider, by default is the Amazon Pinpoint
            provider: 'AWSPinpoint'
        });

        Analytics.autoTrack('pageView', {
            // REQUIRED, turn on/off the auto tracking
            enable: true,
            // OPTIONAL, the event name, by default is 'pageView'
            eventName: 'pageView',
            // OPTIONAL, the attributes of the event, you can either pass an object or a function
            // which allows you to define dynamic attributes
            attributes: {
                pseudo: this.context?.pseudo,
                producerID: this.context?.producerID,
                language: this.context?.language
            },
            // when using function
            // attributes: () => {
            //    const attr = somewhere();
            //    return {
            //        myAttr: attr
            //    }
            // },
            // OPTIONAL, by default is 'multiPageApp'
            // you need to change it to 'SPA' if your app is a single-page app like React
            type: 'SPA',
            // OPTIONAL, the service provider, by default is the Amazon Pinpoint
            provider: 'AWSPinpoint',
            // OPTIONAL, to get the current page url
            getUrl: () => {
              // the default function
              return window.location.origin + window.location.pathname;
            }
        });*/
    }

    setupPush = async() => {
        const serviceWorker = new ServiceWorker();
    
        try {
          const registeredServiceWorker = await serviceWorker.register('/firebase-messaging-sw.js', '/');
          serviceWorker.enablePush('BPHWQ9EVz9gSk9knXj3LLvTAyqB0s1N2ONMCVzNr9WAhwrA9_Y2Fp_-Phr7VzAGeOzioKJVdKS3IXyhHBmNUiZU');
          /*try {
            console.log('Updating pinpoint endpoint');
            Analytics.updateEndpoint({
              channelType: "GCM",
              optOut: "NONE"
            });
            console.log('Done updating pinpoint endpoint');
          } catch(err) {
            console.log("Error updating pinpoint endpoint: " + err.toString());
          }*/
        } catch(err:any) {
          console.log("Error on serviceWorker.enablePush: " + err.toString());
        }
    
        const firebaseConfig = {
          apiKey: "AIzaSyAJDcYtt_0XVIKmc25dgZV0LOVj0rK83TA",
          authDomain: "deinefans-f79b9.firebaseapp.com",
          projectId: "deinefans-f79b9",
          storageBucket: "deinefans-f79b9.appspot.com",
          messagingSenderId: "220559797435",
          appId: "1:220559797435:web:a262367664c493e541cd9e",
          measurementId: "G-R9K5GE61ZQ"
        };
    
        const app = initializeApp(firebaseConfig);
        const messaging = getMessaging(app);
        getToken(messaging, { vapidKey: "BPHWQ9EVz9gSk9knXj3LLvTAyqB0s1N2ONMCVzNr9WAhwrA9_Y2Fp_-Phr7VzAGeOzioKJVdKS3IXyhHBmNUiZU"}).then((currentToken) => {
            if (currentToken) {
                if (currentToken !== "") {
                    /*console.log('got Firebase token: ' + currentToken);
                    try {
                        console.log('Updating userEndpoint: ' + currentToken);
                        Analytics.updateEndpoint({
                        address: currentToken,
                        channelType: "GCM",
                        optOut: "NONE"
                        });
                        console.log('Done updating userEndpoint: ' + currentToken);
                    } catch(err:any) {
                        console.log("Error updating endpoint: " + err.toString());
                    }*/
                }
            } else {
                console.log('No registration token available. Request permission to generate one.');
                if(typeof(window.Notification) === 'undefined') {
                    console.log('Notifications are not supported in this browser.');
                } else {
                    if(typeof(window.Notification.requestPermission) === 'function') {
                        window.Notification.requestPermission().then((permission) => {
                        if (permission === 'granted') {
                            console.log('Notification permission granted.');
                        } else {
                            console.log('Unable to get permission to notify.');
                        }
                        });
                    } else {
                        console.log('Notifications are not supported in this browser(requestPermission is not a function).');
                    }
                }
            }
        }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
        });
    }

    render () {
      return (
          <AppCtx.Provider value={sampleAppContext}>
              <Layout>
                  <Route exact path='/' component={Home} />
                  <Route path='/login' component={LoginForm} />
                  <Route path='/logout' component={LogoutForm} />
                  <Route path='/signup' component={SignupForm} />
                  <Route path='/myaccount' component={MyAccount} />
                  <Route path='/support' component={Support} />
                  <Route path='/profile' component={ProfileEdit} />
                  <Route path='/messenger' component={Messenger} />
                  <Route path='/images' component={ImagesPage} />
                  <Route path='/videos' component={VideosPage} />
                  <Route path='/videos/:streamname' component={VideosPage} />
                  <Route path='/audios' component={AudioList} />
                  <Route path='/idshots' component={IDShotManager} />
                  <Route path='/inbox' component={Inbox} />
                  <Route path='/events' component={Events} />
                  <Route path='/timeline' component={Timeline} />
                  <Route path='/userlists' component={UserList} />
                  <Route path='/subscriptions' component={SubscriptionTiers} />
                  <Route path='/shop' component={ShopItemsList} />
                  <Route path='/ShopItemEdit' component={ShopItemEdit} />
                  <Route path='/AGB' component={Agb} />
                  <Route path='/Impressum' component={Impressum} />
                  <Route path='/PrivacyPolicy' component={PrivacyPolicy} />
                  <Route path='/my-data' component={MasterDataEdit} />
                  <Route path='/livecam' component={LivecamEdit} />
                  <Route path='/sales' component={SalesList} />
                  <Route path='/choosemyconditions' component={ChooseMyConditions} />
            </Layout>
          </AppCtx.Provider>
    );
  }
}

export default App;