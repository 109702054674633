import React, { Component, FormEvent } from 'react';
import { Alert, Col, Row, Container, Form, FormGroup, Button, Label, Input, ButtonGroup, Dropdown, DropdownMenu, DropdownItem } from 'reactstrap';
import { LocalStorageWorker } from '../StorageHelper';
import { CryptoHelper } from '../CryptoHelper.js';
import { AppCtx, AppContextInterface, ApiHelper } from '../AppContextInterface';
import { Redirect } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DateTime } from 'luxon';
import { MessengerWrite } from './MessengerWrite';
import { MessengerConversationControl } from './MessengerConversationControl';

interface UserListProps {

}

export interface UserList extends React.Component {
    state: UserListState;
    props: UserListProps;
    contextType: AppContextInterface;
}

interface UserListState {
    createdAuthToken: string;
    successMessage: string;
    errorMessage: string;
    fetchingSystemLists: boolean;
    fetchingCustomLists: boolean;
    language: string;
    systemLists: UserListData[];
    customLists: UserListData[];
    users: UserListDataJSON[];
    listID: string;
    fetchingUsers: boolean;
    selectedUser: UserDataJSON|null;
    fetchingUser: boolean;
    addBalanceAmount: string;
    substractBalanceAmount: string;
    doingSingleAPIStuff: boolean;
    createCustomListMode: boolean;
    createListSubmitting: boolean;
    newListName: string;
    newListDescription: string;
    userIDToAdd: string;
    addUserToListMode: boolean;
    addUserToListSubmitting: boolean;
    isSelectedListCustom: boolean;
    boughtContent: BoughtContentJSON[];
    subscriptions: UserSubscriptionInfoJSON[];
    allowBalance: boolean;
    allowToken: boolean;
    sendMessageMode: boolean;
    conversationID: string;
    messengerTargetListID: string;
    messengerTargetPseudo: string;
    reloadMessages: boolean;
    sortUsersBy: string;
    platform: string;
}

export interface UserListData {
    listID: string;
    name: string;
    numUsers: number;
    conversationID: string;
    messengerUserID: string;
}

interface UserSubscriptionInfoJSON {
    userID: string;
    subscriptionID: string;
    subscriptionName: string;
    subscriptionStatus: string;
    startTime: Date;
}

interface UserDataJSON {
    userID: string;
    creationTime: Date;
    credits: number;
    pseudo: string;
    email: string;
    ipaddress: string;
    domain: string;
    clientType: string;
    clientTypeDetail: string;
}

interface BoughtContentJSON {
    contentSource: string;
    contentSourceID: string;
    timestamp: DateTime;
    pricePaid: number;
    producerID: string;
    girlPseudo: string;
    contentID: string;
    contentType: string;
    buyType: string;
    buyDescription: string;
}

interface UserListDataJSON {
    userID: string;
    creationTime: Date;
    addedToList: Date;
    pseudo: string;
    isOnline: boolean;
    isSub: boolean;
    isFan: boolean;
}

export class UserList extends React.Component {
    static contextType = AppCtx;
    context!: React.ContextType<typeof AppCtx>;

    constructor(props: UserListProps) {
        super(props);

        let contextLanguage = "de";

        if (this.context) {
            if (this.context.language !== null && this.context.language !== "") {
                contextLanguage = this.context.language;
            }
        }

        let myplatform:string = ApiHelper.getPlatform();

        if(this.context) {
            myplatform = this.context.platform;
        }

        this.state = {
            successMessage: "",
            createdAuthToken: "",
            errorMessage: "",
            fetchingSystemLists: false,
            fetchingCustomLists: false,
            fetchingUsers: false,
            language: contextLanguage,
            listID: "",
            customLists: [],
            systemLists: [],
            users: [],
            selectedUser: null,
            fetchingUser: false,
            addBalanceAmount: "",
            substractBalanceAmount: "",
            doingSingleAPIStuff: false,
            newListName: "",
            newListDescription: "",
            createListSubmitting: false,
            createCustomListMode: false,
            userIDToAdd: "",
            addUserToListMode: false,
            addUserToListSubmitting: false,
            isSelectedListCustom: false,
            boughtContent: [],
            subscriptions: [],
            allowBalance: false,
            allowToken: false,
            sendMessageMode: false,
            conversationID: "",
            messengerTargetListID: "",
            messengerTargetPseudo: "",
            reloadMessages: false,
            sortUsersBy: "time",
            platform: myplatform
        }
    }

    componentDidMount() {
        let myLocalStorage:LocalStorageWorker = new LocalStorageWorker();

        if(myLocalStorage.get("lastpage") !== "userlists") {
            myLocalStorage.add("lastpage","userlists");
        }

        this.fetchSystemLists();
        this.fetchCustomLists();
    }

    fetchSystemLists = async () => {
        this.setState({ fetchingSystemLists: true });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/usersystemlists";

        if (this.context) {
            if(!this.context.loggedin) {
                await ApiHelper.checkLoginStatus(this.context);
            }
            jsonurl += "?producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
            jsonurl += "&platform=" + encodeURIComponent(this.state.platform);
        }

        let response: any = null;

        try {
            response = await fetch(jsonurl);
            let data = await response.json();
            this.setState({ fetchingSystemLists: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                }
            }

            if (data.lists) {
                this.setState({ systemLists: data.lists });
            }
        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching data: " + error.toString() });
            this.setState({ fetchingSystemLists: false });
        }
    }

    fetchCustomLists = async () => {
        this.setState({ fetchingCustomLists: true });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/userlists";

        if (this.context) {
            if(!this.context.loggedin) {
                await ApiHelper.checkLoginStatus(this.context);
            }
            jsonurl += "?producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        }

        jsonurl += "&platform=" + encodeURIComponent(this.state.platform);

        let response: any = null;

        try {
            response = await fetch(jsonurl);
            let data = await response.json();
            this.setState({ fetchingCustomLists: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                }
            }

            if (data.lists) {
                this.setState({ customLists: data.lists });
            }
        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching data: " + error.toString() });
            this.setState({ fetchingCustomLists: false });
        }
    }

    getText(exp: string) {
        if (this.state.language === "de") {
            if(exp === "SchreibeUserInListeAn") {
                return "Nachricht an alle in dieser Liste";
            }
            if(exp === "NoRecordsFound") {
                return "Keine Einträge gefunden";
            }
            if(exp === "List") {
                return "Liste";
            }
            if(exp === "Userlisten") {
                return "Userlisten";
            }
            if(exp === "New List Name") {
                return "Name der neuen Liste";
            }
            if(exp === "New List Description") {
                return "Beschreibung (optional)";
            }
            if(exp === "Create new list") {
                return "Neue Liste anlegen";
            }
            if(exp === "Name") {
                return "Name der Liste";
            }
            if(exp === "Description") {
                return "Beschreibung";
            }
            if(exp === "abo") {
                return "Deine Abonnenten";
            }
            if(exp === "customers") {
                return "Deine Kunden";
            }
            if(exp === "exabo") {
                return "Ehemalige Abonnenten";
            }
            if(exp === "fans") {
                return "Deine Fans";
            }
            if(exp === "Username") {
                return "Nickname";
            }
            if(exp === "buyTime") {
                return "Kaufzeitpunkt";
            }
            if(exp === "pricePaid") {
                return "Preis";
            }
            if(exp === "buyDescription") {
                return "Kaufbeschreibung";
            }
            if(exp === "creationTime") {
                return "Anmeldezeitpunkt";
            }
            if(exp === "domain") {
                return "Anmeldedomain";
            }
            if(exp === "credits") {
                return "Credits";
            }
            if(exp === "subscriptions") {
                return "Abonnements";
            }
            if(exp === "NoBoughtContentFound") {
                return "Keine Einzelkäufe gefunden";
            }
            if(exp === "BoughtContentsFound") {
                return "Einzelkäufe gefunden";
            }
            if(exp === "SubscriptionsFound") {
                return "Abonnements gefunden"
            }
            if(exp === "NoSubscriptionsFound") {
                return "Keine Abonnements gefunden";
            }
            if(exp === "startTime") {
                return "Startzeitpunkt";
            }
            if(exp === "endTime") {
                return "Endzeitpunkt";
            }
            if(exp === "subscriptionDescription") {
                return "Abonnementbeschreibung";
            }
            if(exp === "subscriptionPrice") {
                return "Abonnementpreis";
            }
            if(exp === "subscriptionStatus") {
                return "Abonnementstatus";
            }
        } else {
            if(exp === "SchreibeUserInListeAn") {
                return "Write to everyone in list";
            }
            if(exp === "NoRecordsFound") {
                return "No records found";
            }
            if  (exp === "Userlisten") {
                return "User Lists";
            }
            if (exp === "New List Name") {
                return "Name of the new list";
            }
            if (exp === "New List Description") {
                return "Description (optional)";
            }
            if (exp === "Create new list") {
                return "Create new list";
            }
            if (exp === "Name") {
                return "Name of the list";
            }
            if (exp === "Description") {
                return "Description";
            }
            if (exp === "abo") {
                return "Your subscribers";
            }
            if (exp === "customers") {
                return "Your customers";
            }
            if (exp === "exabo") {
                return "Former subscribers";
            }
            if (exp === "fans") {
                return "Your fans";
            }
            if (exp === "Username") {
                return "Username";
            }
            if(exp === "buyTime") {
                return "Buy Time";
            }
            if(exp === "pricePaid") {
                return "Price";
            }
            if(exp === "buyDescription") {
                return "Buy Description";
            }
            if(exp === "creationTime") {
                return "Creation Time";
            }
            if(exp === "domain") {
                return "Domain";
            }
            if(exp === "credits") {
                return "Credits";
            }
            if(exp === "subscriptions") {
                return "Subscriptions";
            }
            if(exp === "NoBoughtContentFound") {
                return "No bought content found";
            }
            if(exp === "BoughtContentsFound") {
                return "Bought contents found";
            }
            if(exp === "SubscriptionsFound") {
                return "subscriptions found"
            }
            if(exp === "NoSubscriptionsFound") {
                return "No subscriptions found";
            }
            if(exp === "startTime") {
                return "Start Time";
            }
            if(exp === "endTime") {
                return "End Time";
            }
            if(exp === "subscriptionDescription") {
                return "Subscription Description";
            }
            if(exp === "subscriptionPrice") {
                return "Subscription Price";
            }
            if(exp === "subscriptionStatus") {
                return "Subscription Status";
            }
        }

        return exp;
    }

    clickUser(userID: string) {
        this.setState({ errorMessage: "" });
        this.setState({ infoMessage: "" });
        this.setState({ sendMessageMode: false });
        this.fetchSingleUser(userID);
    }

    fetchSingleUser = async (userID: string) => {
        this.setState({ fetchingUser: true });
        this.setState({ selectedUser: null });
        this.setState({ boughtContent: [] });
        this.setState({ subscriptions: [] });
        this.setState({ allowToken: false });
        this.setState({ allowBalance: false });
        this.setState({ sendMessageMode: false });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/users/" + userID;

        if (this.context) {
            jsonurl += "?producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        } else {
            jsonurl += "?producerID=nocontext";
        }

        jsonurl += "&listID=" + encodeURIComponent(this.state.listID);

        let response: any = null;

        try {
            response = await fetch(jsonurl);
            let data = await response.json();
            this.setState({ fetchingUser: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                    if(!ApiHelper.checkResponse(data,this.context) && this.context !== null) {
                        this.context.producerID = "";
                        alert("Please relogin");
                    }
                }
            }

            if (data.user) {
                this.setState({ selectedUser: data.user });
            }

            if(data.boughtContent) {
                this.setState({boughtContent: data.boughtContent });
            }

            if(data.subscriptions) {
                this.setState({subscriptions: data.subscriptions });
            }

            this.setState({ allowToken: data.allowToken });
            this.setState({ allowBalance: data.allowBalance });
        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching data: " + error.toString() });
            this.setState({ fetchingUser: false });
        }
    }

    clickList(listID: string) {
        let mylist: UserListData | undefined = undefined;

        this.setState({ listID: "" });
        this.setState({ selectedUser: null });
        this.setState({ boughtContent: [] });
        this.setState({ subscriptions: [] });
        this.setState({ allowToken: false });
        this.setState({ allowBalance: false });
        this.setState({ errorMessage: "" });
        this.setState({ infoMessage: "" });
        this.setState({ sendMessageMode: false });

        mylist = this.state.systemLists.find(element => element.listID === listID);
        if (mylist == undefined || mylist == null) {
            mylist = this.state.customLists.find(element => element.listID === listID);
            if (mylist != undefined && mylist != null) {
                this.setState({ isSelectedListCustom: true });
            }
        } else {
            this.setState({ isSelectedListCustom: false });
        }

        if (mylist !== undefined && mylist !== null) {
            this.setState({ listID: mylist.listID });
            this.fetchUsers(mylist.listID);
        } else {
            this.setState({ errorMessage: "Invalid List clicked: " + listID });
        }
    }

    fetchUsers = async (listID: string) => {
        this.setState({ fetchingUsers: true });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/users";

        if (this.context) {
            jsonurl += "?producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        } else {
            jsonurl += "?producerID=nocontext";
        }

        jsonurl += "&listID=" + encodeURIComponent(listID);
        jsonurl += "&sort=" + encodeURIComponent(this.state.sortUsersBy);
        jsonurl += "&platform=" + encodeURIComponent(this.state.platform);

        let response: any = null;

        try {
            response = await fetch(jsonurl);
            let data = await response.json();
            this.setState({ fetchingUsers: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                }
            }

            if (data.users) {
                this.setState({ users: data.users });
            }
        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching data: " + error.toString() });
            this.setState({ fetchingUsers: false });
        }
    }

    handleAddBalanceChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ addBalanceAmount: event.target.value });
    }

    handleSubstractBalanceChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ substractBalanceAmount: event.target.value });
    }

    addBalance = async() => {
        this.setState({ doingSingleAPIStuff: true });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/balance";

        if (this.context) {
            jsonurl += "?producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        } else {
            jsonurl += "?producerID=nocontext";
        }

        if(this.state.selectedUser) {
            jsonurl += "&userID=" + encodeURIComponent(this.state.selectedUser.userID);
        }

        jsonurl += "&action=add";
        jsonurl += "&amount=" + encodeURIComponent(this.state.addBalanceAmount);

        let response: any = null;

        try {
            response = await fetch(jsonurl,{method:"POST"});
            let data = await response.json();
            this.setState({ doingSingleAPIStuff: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                }
            }

            if (data.success) {
                let myUser:UserDataJSON | null = this.state.selectedUser;
                if(myUser) {
                    myUser.credits = data.newBalance;
                    this.setState({ selectedUser: myUser });
                }
                this.setState({ successMessage: "Balance added" });
            }
        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching data: " + error.toString() });
            this.setState({ doingSingleAPIStuff: false });
        }
    }

    substractBalance = async() => {
        this.setState({ doingSingleAPIStuff: true });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/balance";

        if (this.context) {
            jsonurl += "?producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        } else {
            jsonurl += "?producerID=nocontext";
        }

        if(this.state.selectedUser) {
            jsonurl += "&userID=" + encodeURIComponent(this.state.selectedUser.userID);
        }

        jsonurl += "&action=substract";
        jsonurl += "&amount=" + encodeURIComponent(this.state.addBalanceAmount);

        let response: any = null;

        try {
            response = await fetch(jsonurl,{method:"POST"});
            let data = await response.json();
            this.setState({ doingSingleAPIStuff: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                }
            }

            if (data.success) {
                let myUser:UserDataJSON | null = this.state.selectedUser;
                if(myUser) {
                    myUser.credits = data.newBalance;
                    this.setState({ selectedUser: myUser });
                }
                this.setState({ successMessage: "Balance substracted" });
            }
        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching data: " + error.toString() });
            this.setState({ doingSingleAPIStuff: false });
        }
    }

    createAuthToken = async() => {
        this.setState({ doingSingleAPIStuff: true });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/authtoken";

        if (this.context) {
            jsonurl += "?producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        } else {
            jsonurl += "?producerID=nocontext";
        }

        if(this.state.selectedUser) {
            jsonurl += "&userID=" + encodeURIComponent(this.state.selectedUser.userID);
        }

        let response: any = null;

        try {
            response = await fetch(jsonurl,{method:"POST"});
            let data = await response.json();
            this.setState({ doingSingleAPIStuff: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                }
            }

            if (data.success && typeof(data.success) !== "undefined") {
                this.setState({ successMessage: "AuthToken created" });
                this.setState({ createdAuthToken: data.token });
            }
        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching data: " + error.toString() });
            this.setState({ doingSingleAPIStuff: false });
        }
    }

    handleUserIDToAddChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ userIDToAdd: event.target.value });
    }

    handleNewListNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ newListName: event.target.value });
    }

    handleNewListDescriptionChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ newListDescription: event.target.value });
    }

    handleCreateListSubmit(event: FormEvent) {
        event.preventDefault();
        this.createNewListSubmitted();
    }

    createNewListSubmitted = async() => {
        this.setState({ createListSubmitting: true });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/userlists";

        if (this.context) {
            jsonurl += "?producerID=" + encodeURIComponent(this.context.producerID);
        }

        jsonurl += "&listName=" + encodeURIComponent(this.state.newListName);
        jsonurl += "&listDescription=" + encodeURIComponent(this.state.newListDescription);

        if (this.context) {
            jsonurl += "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        }

        jsonurl += "&platform=" + encodeURIComponent(this.state.platform);

        let response: any = null;

        try {
            response = await fetch(jsonurl,{method: "POST"});
            let data = await response.json();
            this.setState({ createListSubmitting: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                }
            }

            this.setState({ successMessage: data.message });
            this.setState({ createCustomListMode: false });

            this.fetchCustomLists();
        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching data: " + error.toString() });
            this.setState({ createListSubmitting: false });
        }
    }

    deleteListClicked = async(listID:string) => {
        this.setState({ fetching: true });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/userlists";

        if (this.context) {
            jsonurl += "?producerID=" + encodeURIComponent(this.context.producerID);
        }

        jsonurl += "&listID=" + encodeURIComponent(listID);

        if (this.context) {
            jsonurl += "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        }

        jsonurl += "&platform=" + encodeURIComponent(this.state.platform);

        let response: any = null;

        try {
            response = await fetch(jsonurl,{method: "DELETE"});
            let data = await response.json();
            this.setState({ createTierSubmitting: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                }
            }

            this.setState({ successMessage: data.message });

            this.fetchCustomLists();

        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching data: " + error.toString() });
            this.setState({ fetching: false });
        }
    }

    addUserToList() {
        this.setState({addUserToListMode: true});
        this.setState({addUserToListSubmitting: false});
    }

    handleAddUsertoListSubmit = async() => {
        this.setState({ doingSingleAPIStuff: true });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/userstouserlists";

        if (this.context) {
            jsonurl += "?producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        } else {
            jsonurl += "?producerID=nocontext";
        }

        jsonurl += "&listID=" + encodeURIComponent(this.state.listID);
        jsonurl += "&userID=" + encodeURIComponent(this.state.userIDToAdd);
        jsonurl += "&platform=" + encodeURIComponent(this.state.platform);

        let response: any = null;

        try {
            response = await fetch(jsonurl,{method:"POST"});
            let data = await response.json();
            this.setState({ doingSingleAPIStuff: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                }
            }

            this.setState({ addUserToListMode: false });

            if (data.success) {
                this.setState({ successMessage: data.message });
            } else {
                this.setState({ errorMessage: data.message });
            }

            this.clickList(this.state.listID);
        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching data: " + error.toString() });
            this.setState({ doingSingleAPIStuff: false });
        }
    }

    fetchConversationID = async(userID:string) => {
        this.setState({ doingSingleAPIStuff: true });
        this.setState({ sendMessageMode: false,conversationID: "",messengerTargetPseudo: "",messengerTargetListID: "" });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/messengerconversation";

        if (this.context) {
            jsonurl += "?producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        } else {
            jsonurl += "?producerID=nocontext";
        }

        jsonurl += "&role=producer";
        jsonurl += "&userID=" + encodeURIComponent(userID);
        jsonurl += "&platform=" + encodeURIComponent(this.state.platform);

        let response: any = null;

        try {
            response = await fetch(jsonurl,{method:"GET"});
            let data = await response.json();
            this.setState({ doingSingleAPIStuff: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                }
            }

            if (data.conversationID) {
                if (data.conversationID !== "") {
                    if(this.state.selectedUser) {
                        this.setState({conversationID: data.conversationID,sendMessageMode: true,messengerTargetPseudo: this.state.selectedUser.pseudo });
                    }
                } else {
                    this.setState({errorMessage: "Could not get conversationID for Messenger"});
                }
            } else {
                this.setState({errorMessage: "Unable to get conversationID for Messenger"});
            }
        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching data: " + error.toString() });
            this.setState({ doingSingleAPIStuff: false });
        }
    }

    deleteUserFromList = async(userID:string) => {
        this.setState({ doingSingleAPIStuff: true });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/userstouserlists";

        if (this.context) {
            jsonurl += "?producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        } else {
            jsonurl += "?producerID=nocontext";
        }

        jsonurl += "&listID=" + encodeURIComponent(this.state.listID);
        jsonurl += "&userID=" + encodeURIComponent(userID);
        jsonurl += "&platform=" + encodeURIComponent(this.state.platform);

        let response: any = null;

        try {
            response = await fetch(jsonurl,{method:"DELETE"});
            let data = await response.json();
            this.setState({ doingSingleAPIStuff: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                }
            }

            if (data.success) {
                this.setState({ successMessage: data.message });
            } else {
                this.setState({ errorMessage: data.message });
            }

            this.clickList(this.state.listID);
        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching data: " + error.toString() });
            this.setState({ doingSingleAPIStuff: false });
        }
    }

    createNewList() {
        this.setState({createCustomListMode: true});
        this.setState({createListSubmitting: false});
    }

    unselectList() {
        this.setState({listID: ""});
    }

    writeToListInMessenger() {
        let mylist: UserListData | undefined = undefined;

        mylist = this.state.systemLists.find(element => element.listID === this.state.listID);
        if(mylist != null && typeof(mylist) !== "undefined") {
            this.setState({messengerTargetListID: this.state.listID,messengerTargetPseudo: "Liste " + mylist.name,conversationID: mylist.conversationID,sendMessageMode: true});
        }
    }

    unselectUser() {
        this.setState({selectedUser: null});
    }

    sendMessage() {
        if(this.state.selectedUser != null) {
            this.fetchConversationID(this.state.selectedUser.userID);
        }
    }

    onMessageSent() {
        this.setState({reloadMessages: true});
    }

    onMessageCancel() {
        this.setState({ sendMessageMode: false,conversationID: "",messengerTargetPseudo: "",messengerTargetListID: "" });
    }

    onMessagesReloaded() {
        this.setState({reloadMessages: false});
    }

    render() {
        if (this.context) {
            if (this.context.producerID === "") {
                return <Redirect to="/" push={true} />
            }
        } else {
            return <Redirect to="/" push={true} />
        }

        return (
            <div>
                {this.state.successMessage !== "" ? <Alert color="success">{this.state.successMessage}</Alert> : null}
                {this.state.errorMessage !== "" ? <Alert color="danger">{this.state.errorMessage}</Alert> : null}
                <div id='UserListDIV' className='row'>
                    <div id="UserListsPane" className={this.state.listID !== "" ? "col-12 col-lg-3 userlistselected flex-shrink-0 p-3 bg-white" : "col-12 col-lg-3 nouserlistselected flex-shrink-0 p-3 bg-white"}>
                        <h4 className='pageHeadline'>
                            <span className="fs-5 fw-semibold">{this.getText("Userlisten")}</span>
                        </h4>
                        {this.state.fetchingSystemLists ? <p className="pt-4"><FontAwesomeIcon icon={solid('cog')} spin size="4x" /></p> :
                        <ul className="list-group">
                            {this.state.systemLists.map(list =>
                                <li className={list.listID == this.state.listID ? "list-group-item userList active" : "list-group-item userList"} onClick={this.clickList.bind(this, list.listID)}>{this.getText(list.name)} <span className="badge bg-secondary">{list.numUsers}</span></li>
                            )}
                        </ul>
                        }
                        {this.state.fetchingCustomLists ? <p className="pt-4"><FontAwesomeIcon icon={solid('cog')} spin size="4x" /></p> :
                        <ul className="list-group">
                            {this.state.customLists.map(list =>
                                <li className={list.listID == this.state.listID ? "list-group-item userList active" : "list-group-item userList"} onClick={this.clickList.bind(this, list.listID)}>{list.name} <span className="badge bg-secondary">{list.numUsers}</span></li>
                            )}
                            <li className='list-group-item subscriptionTier' onClick={this.createNewList.bind(this)}>{this.getText("Create new list")} <span className="badge bg-secondary"><FontAwesomeIcon icon={solid('plus')}></FontAwesomeIcon></span></li>
                        </ul>
                        }
                        { this.state.createCustomListMode ? 
                        <Form onSubmit={this.handleCreateListSubmit.bind(this)}>
                            <FormGroup className="mb-2">
                                <Label>{this.getText("New List Name")}</Label>
                                <Input type="text" placeholder={this.getText("Name")} value={this.state.newListName} onChange={this.handleNewListNameChange.bind(this)} />
                            </FormGroup>
                            <FormGroup className="mb-2">
                                <Label>{this.getText("New List Description")}</Label>
                                <Input type="text" placeholder={this.getText("Description")} value={this.state.newListDescription} onChange={this.handleNewListDescriptionChange.bind(this)} />
                            </FormGroup>
                            <Button variant="primary" disabled={this.state.createListSubmitting} type="submit">{this.getText("Submit")}</Button>
                        </Form>
                        : null }
                    </div>
                    { this.state.sendMessageMode ? 
                        <div id="UserListMessengerPane" className="d-flex flex-column align-items-stretch flex-shrink-0 bg-white col-12 col-lg-9">
                            <div className='w-100 mt-4'>
                                <MessengerWrite onCancel={this.onMessageCancel.bind(this)} onMessageSent={this.onMessageSent.bind(this)} userPseudo={this.state.messengerTargetPseudo} conversationID={this.state.conversationID} replyToMsgID="" targetListID={this.state.messengerTargetListID} />
                                <hr />
                                <MessengerConversationControl doReload={this.state.reloadMessages} onMessagesReloaded={this.onMessagesReloaded.bind(this)} userPseudo={this.state.messengerTargetPseudo} conversationID={this.state.conversationID} />
                            </div>
                        </div> :
                    this.state.selectedUser === null ? 
                      this.state.listID !== "" ? 
                    <div id="UserRecordsPane" className="d-flex flex-column align-items-stretch flex-shrink-0 bg-white col-12 col-lg-9">
                        <p>&nbsp;</p>
                        <p className="d-flex align-items-center flex-shrink-0 p-3 link-dark text-decoration-none border-bottom">
                            <Button variant="secondary" size="sm" className='d-inline-block' onClick={this.unselectList.bind(this)}><FontAwesomeIcon icon={solid('arrow-left')} fixedWidth /></Button>&nbsp;
                            <span className="fs-5 fw-semibold">{this.getText(this.state.listID)}</span>&nbsp;
                            <Button variant="secondary" size="sm" className='d-inline-block' onClick={this.writeToListInMessenger.bind(this)}><FontAwesomeIcon icon={solid('envelope')} fixedWidth /> {this.getText("SchreibeUserInListeAn")}</Button>
                        </p>
                        {this.state.successMessage !== "" ? <Alert color="success">{this.state.successMessage}</Alert> : null}
                        {this.state.errorMessage !== "" ? <Alert color="danger">{this.state.errorMessage}</Alert> : null}
                        {this.state.fetchingUsers ? <p className="pt-4"><FontAwesomeIcon icon={solid('cog')} spin size="4x" /></p> :
                            this.state.users.length > 0 ?
                            <ul className="list-group list-group-flush scrollarea">
                                {this.state.users.map(user =>
                                    <li onClick={this.clickUser.bind(this,user.userID)} className={user.userID === this.state.selectedUser?.userID ? "list-group-item userRecordItem active py-3 lh-sm" : "list-group-item userRecordItem py-3 lh-sm"}>
                                        <div className="d-flex w-100 align-items-center justify-content-between flex-wrap">
                                            <strong className="mb-1"><FontAwesomeIcon icon={solid('user')} fixedWidth /> {user.pseudo}</strong>
                                            <small>{DateTime.fromISO(user.creationTime.toString()).toLocaleString(DateTime.DATETIME_MED)}</small>
                                            {user.isOnline ? <span className='badge bg-success'>online</span> : null }
                                            { this.state.isSelectedListCustom ? <FontAwesomeIcon onClick={this.deleteUserFromList.bind(this.addBalance,user.userID)} icon={solid('remove')} style={{cursor: "pointer"}} fixedWidth /> : null }
                                        </div>
                                    </li>
                                )}
                                { this.state.isSelectedListCustom ? <li className='list-group-item userRecordItem' onClick={this.addUserToList.bind(this)}>{this.getText("Add user")} <span className="badge bg-secondary"><FontAwesomeIcon icon={solid('plus')}></FontAwesomeIcon></span></li> : null }
                            </ul> : <p>{this.getText("NoRecordsFound")}</p>
                        }
                        { this.state.addUserToListMode ? 
                        <Form onSubmit={this.handleAddUsertoListSubmit.bind(this)}>
                            <FormGroup className="mb-2">
                                <Label>{this.getText("UserID")}</Label>
                                <Input type="text" placeholder={this.getText("UserID")} value={this.state.userIDToAdd} onChange={this.handleUserIDToAddChange.bind(this)} />
                            </FormGroup>
                            <Button variant="primary" disabled={this.state.addUserToListSubmitting} type="submit">{this.getText("Add User")}</Button>
                        </Form>
                        : null }
                    </div> :
                    <div className='col-12 col-lg-9' style={{marginTop: "42px"}}><p className="fs-5 fw-semibold">{this.getText("Wähle eine Liste aus")}</p></div> :
                        <div id="singleUserParentPane" className="d-flex flex-column align-items-stretch flex-shrink-0 bg-white w-75">
                            <div id="singleUserPane" className='w-100'>
                                { this.state.successMessage !== "" ? <Alert className='w-100' color="success">{this.state.successMessage}</Alert> : null}
                                { this.state.errorMessage !== "" ? <Alert className='w-100' color="danger">{this.state.errorMessage}</Alert> : null}
                                { this.state.fetchingUser ? <p className="pt-4 w-100"><FontAwesomeIcon icon={solid('cog')} spin size="4x" /></p> : null }
                                <p className="d-flex align-items-center flex-shrink-0 p-3 link-dark text-decoration-none border-bottom">
                                    <Button variant="secondary" size="sm" className='d-inline-block' onClick={this.unselectUser.bind(this)}><FontAwesomeIcon icon={solid('arrow-left')} fixedWidth /></Button>&nbsp;
                                    <span className="fs-5 fw-semibold">User {this.state.selectedUser.pseudo}</span>
                                    &nbsp;
                                    <Button className='d-inline-block' disabled={this.state.fetchingUser} size="sm" onClick={this.fetchSingleUser.bind(this,this.state.selectedUser.userID)}><FontAwesomeIcon icon={solid("arrow-rotate-right")}></FontAwesomeIcon></Button>
                                </p>
                                {this.state.doingSingleAPIStuff ? <p className="pt-4"><FontAwesomeIcon icon={solid('cog')} spin size="4x" /></p> : null }
                                <div id="userInfoPane" className='w-100'>
                                    <table className='table table-condensed'>
                                        <tr>
                                            <td>{this.getText("creationTime")}</td>
                                            <td>{DateTime.fromISO(this.state.selectedUser.creationTime.toString()).toLocaleString(DateTime.DATETIME_MED)}</td>
                                        </tr>
                                        <tr>
                                            <td>{this.getText("domain")}</td>
                                            <td>{this.state.selectedUser.domain}</td>
                                        </tr>
                                        <tr>
                                            <td>{this.getText("credits")}</td>
                                            <td>{this.state.selectedUser.credits}</td>
                                        </tr>
                                    </table>
                                    <hr />
                                    <FormGroup row className='mb-2'>
                                        <Col sm={8}>
                                            &nbsp;
                                        </Col>
                                        <Col sm={4}>
                                            <Button disabled={this.state.doingSingleAPIStuff} className='w-100' size="sm" onClick={this.sendMessage.bind(this)}>{this.getText("Message")} <FontAwesomeIcon icon={solid("envelope")}></FontAwesomeIcon></Button>
                                        </Col>
                                    </FormGroup>
                                    { this.state.allowBalance ? 
                                    <FormGroup row className='mb-2'>
                                        <Col sm={8}>
                                            <Input className='w-100' bsSize="sm" type="tel" placeholder={this.getText("AddBalance")} value={this.state.addBalanceAmount} onChange={this.handleAddBalanceChange.bind(this)} />
                                        </Col>
                                        <Col sm={4}>
                                            <Button disabled={this.state.doingSingleAPIStuff} className='w-100' size="sm" onClick={this.addBalance.bind(this)}>Balance <FontAwesomeIcon icon={solid("plus")}></FontAwesomeIcon></Button>
                                        </Col>
                                    </FormGroup> : null }
                                    { this.state.allowBalance ? 
                                    <FormGroup row className='mb-2'>
                                        <Col sm={8}>
                                            <Input className='w-100' bsSize="sm" type="tel" placeholder={this.getText("SubstractBalance")} value={this.state.substractBalanceAmount} onChange={this.handleSubstractBalanceChange.bind(this)} />
                                        </Col>
                                        <Col sm={4}>
                                            <Button disabled={this.state.doingSingleAPIStuff} className='w-100' size="sm" onClick={this.substractBalance.bind(this)}>Balance <FontAwesomeIcon icon={solid("minus")}></FontAwesomeIcon></Button>
                                        </Col>
                                    </FormGroup> : null }
                                    { this.state.allowToken ? 
                                    <FormGroup row className='mb-2'>
                                        <Col sm={8}>
                                            {this.state.createdAuthToken !== "" ? <small>{this.state.createdAuthToken}</small> : null }
                                        </Col>
                                        <Col sm={4}>
                                            <Button disabled={this.state.doingSingleAPIStuff} className='w-100' size="sm" onClick={this.createAuthToken.bind(this)}>Token <FontAwesomeIcon icon={solid("wand-magic-sparkles")}></FontAwesomeIcon></Button>
                                        </Col>
                                    </FormGroup> : null }
                                    <hr />
                                    { this.state.subscriptions !== null && typeof(this.state.subscriptions) !== "undefined" ? 
                                    this.state.subscriptions.length > 0 ?
                                    <table className="table table-condensed">
                                        <thead>
                                            <tr>
                                                <th>{this.getText("startTime")}</th>
                                                <th>{this.getText("subscriptionName")}</th>
                                                <th>{this.getText("subscriptionStatus")}</th>
                                            </tr>
                                        </thead>
                                        {this.state.subscriptions.map(item =>
                                            <tr>
                                                <td>{ item.startTime != null && typeof(item.startTime) != "undefined" ? DateTime.fromISO(item.startTime.toString()).toLocaleString(DateTime.DATETIME_MED) : null }</td>
                                                <td>{item.subscriptionName}</td>
                                                <td>{item.subscriptionStatus}</td>
                                            </tr>
                                        )}
                                        <tfoot>
                                            <tr>
                                                <td colSpan={3}>
                                                    <p>{this.state.subscriptions.length} {this.getText("SubscriptionsFound")}</p>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                    : <p>{this.getText("NoSubscriptionsFound")}</p> 
                                    : <p>{this.getText("NoSubscriptionsFound")}</p> }
                                    { this.state.boughtContent !== null && typeof(this.state.boughtContent) !== "undefined" ? 
                                    this.state.boughtContent.length > 0 ?
                                    <table className="table table-condensed">
                                        <thead>
                                            <tr>
                                                <th>{this.getText("buyTime")}</th>
                                                <th>{this.getText("buyType")}</th>
                                                <th>{this.getText("buyDescription")}</th>
                                            </tr>
                                        </thead>
                                        {this.state.boughtContent.map(item =>
                                            <tr>
                                                <td>{ item.timestamp != null && typeof(item.timestamp) != "undefined" ? DateTime.fromISO(item.timestamp.toString()).toLocaleString(DateTime.DATETIME_MED) : null }</td>
                                                <td>{item.buyType}</td>
                                                <td>{item.buyDescription}</td>
                                            </tr>
                                        )}
                                        <tfoot>
                                            <tr>
                                                <td colSpan={3}>
                                                    <p>{this.state.boughtContent.length} {this.getText("BoughtContentsFound")}</p>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                    : <p>{this.getText("NoBoughtContentFound")}</p> 
                                    : <p>{this.getText("NoBoughtContentFound")}</p> }
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}