import React, { Component } from 'react';
import { NavMenu } from './NavMenu';
import { Container, Row, Col,NavLink  } from 'reactstrap';
import { ImpressumFooter } from './ImpressumFooter';
import { Link } from 'react-router-dom';
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export class Layout extends Component {
  static displayName = Layout.name;

  render () {
    return (
    <Container fluid={false} className="maincontainer">
        <Row className='content'>
        <Col xs="12" md="2">
            <NavMenu />
          </Col>
          <Col xs="12" md="10">
            {this.props.children}
          </Col>
          <ImpressumFooter />
        </Row>
        <footer className='footer' >
        <hr/>
        <p><a className='text-dark' href="https://signup.deine.fans/assets/ModernSlaveryAndHumanTraffickingStatement.pdf" target="_blank">Modern Slavery And Human Trafficking Statement</a> &middot; <Link  className="text-dark" to="/AGB"> AGB</Link> &middot; <Link  className="text-dark" to="/Impressum"> Impressum</Link> &middot; <Link  className="text-dark" to="/PrivacyPolicy"> Datenschutzerklärung</Link></p> 
        </footer>
      </Container>
    );
  }
}
