import React, { Component, FormEvent, MouseEvent } from 'react';
import { Redirect } from 'react-router';
import { AppCtx, AppContextInterface, ApiHelper } from '../AppContextInterface';
import { Card, Alert, Container, Form, FormGroup, Button, Label, Input, ButtonGroup, Dropdown, DropdownMenu, DropdownItem,Row, CardHeader, CardBody, CardFooter, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro';

interface MasterDataEditState {
    userName: string;
    saveData: boolean;
    errorMessage: string;
    infoMessage: string;
    language: string;
    loggedin: boolean;
    bankAccountType: string;
    masterData: MasterDataJSON|null;
    land: string;
}

interface MasterDataJSON {
    id : number;
    insertTimestamp : Date;
    lastUpdate : Date;
    name : string;
    vorname : string;
    strasse : string;
    plz : string;
    ort : string;
    land : string;
    telefon : string;
    eMail : string;
    ustID : string;
    instagram : string;
    twitter : string;
    skype : string;
    kontoinhaber : string;
    bank : string;
    kontonummer : string;
    blz : string;
    iban : string;
    bic : string;
    kleinunternehmer : number;
    firma : string;
    agenturPasswort : string;
    bankAccountType : string;
    routingNumber : string;
    gender : string;
    bankAccountConfirmed : boolean;
    bankAccountConfirmedTime : Date;
    bankAccountConfirmInfo : string;
    bankAccountVerified : boolean;
    bankAccountVerifiedTime : Date;
    bankAccountVerifiedInfo : string;
    customPayoutInfo : string;
}

interface MasterDataEditProps {

}

export interface MasterDataEdit extends React.Component {
    state: MasterDataEditState;
    props: MasterDataEditProps;
    contextType: AppContextInterface;
    myFormRef: React.RefObject<HTMLFormElement>;
}

export class MasterDataEdit extends Component {
    static contextType = AppCtx;
    context!: React.ContextType<typeof AppCtx>;

    static displayName = MasterDataEdit.name;

    constructor(props: MasterDataEditProps) { 
        super(props);

        let contextLanguage = "de";

        if (this.context) {
            if (this.context.language !== null && this.context.language !== "") {
                contextLanguage = this.context.language;
            }
        }

        this.state = {
            loggedin: false,
            language: contextLanguage,
            saveData: false,
            errorMessage: "",
            infoMessage: "",
            userName: "",
            bankAccountType: "IBAN",
            masterData: null,
            land: "DE"
        };

        this.myFormRef = React.createRef();
    }

    componentDidMount() {
        this.fetchMasterData();
    }

    handleSubmitButton(event:React.MouseEvent<HTMLButtonElement>) {
        if (this.myFormRef.current) {
            const formData = new FormData(this.myFormRef.current as HTMLFormElement)
            this.formSubmitted(formData);
        }
    }

    handleSubmit(event: FormEvent) {
        event.preventDefault();
        const formData = new FormData(event.currentTarget as HTMLFormElement)
        this.formSubmitted(formData);
    }

    formSubmitted = async (reqdata: FormData) => {
        this.setState({ errorMessage: "" });
        this.setState({ infoMessage: "" });
        this.setState({ saveData: true });

        let jsonurl = "";

        if (this.context) {
            jsonurl = ApiHelper.getAPIUrl(this.context) + "/masterdata/" +  encodeURIComponent(this.context.producerID);
            jsonurl += "?authToken=" + encodeURIComponent(this.context?.authToken);
        }        

        const response = await fetch(jsonurl, {
            method: 'post',
            body: reqdata,
        });

        try {
            let data = await response.json();

            this.setState({ saveData: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                    if(!ApiHelper.checkResponse(data,this.context) && this.context !== null) {
                        this.context.producerID = "";
                        alert("Please relogin");
                    }
                }
            }

            if (data.senderDatenDetail) {
                this.setState({ masterData: data.senderDatenDetail });
                this.setState({ land: data.senderDatenDetail.land });
            }

            if (data.message) {
                this.setState({ infoMessage: data.message });
            }
        } catch(e:any) {
            this.setState({ errorMessage: "Error: " + e.message });
            this.setState({ saveData: false });
        }
    }
    
    fetchMasterData = async () => {
        this.setState({ saveData: true });

        var jsonurl = '';

        if (this.context) {
            if(!this.context.loggedin) {
                await ApiHelper.checkLoginStatus(this.context);
            }
            jsonurl = ApiHelper.getAPIUrl(this.context) + "/masterdata/" +  encodeURIComponent(this.context.producerID);
            jsonurl += "?authToken=" + encodeURIComponent(this.context?.authToken);
        } else {
            jsonurl = ApiHelper.getAPIUrl(this.context) + "/masterdata/" +  encodeURIComponent("noContext");
        }

        let response: any = null;

        try {
            response = await fetch(jsonurl);
            let data = await response.json();
            this.setState({ saveData: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                    if(!ApiHelper.checkResponse(data,this.context) && this.context !== null) {
                        this.context.producerID = "";
                        alert("Please relogin");
                    }
                }
            }

            if (data.senderDatenDetail) {
                this.setState({ masterData: data.senderDatenDetail });
            }

        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching data: " + error.toString() });
            this.setState({ saveData: false });
        }
    }

    getText(exp: string) {
        if (this.state.language === "de") {
            if (exp === "Submit") {return "Speichern";}
            if (exp === "EditMasterData") {return "Stammdaten bearbeiten";}
            if (exp === "bankAccountType") {return "Art der Bankverbindung";}
            if (exp === "Gender") {return "Geschlecht";}
            if (exp === "Male") {return "Männlich";}
            if (exp === "Female") {return "Weiblich";}
            if (exp === "Other") {return "Divers/Anderes";}
        } else {
            if (exp === "Firma") {return "Company";}
            if (exp === "Gender") {return "Gender";}
            if (exp === "Vorname") {return "First name";}
            if (exp === "Nachname") {return "Last name";}
            if (exp === "PLZ") {return "Postal code";}
            if (exp === "UStId") {return "Tax ID";}
            if (exp === "Ort") {return "City";}
            if (exp === "Land") {return "Country";}
            if (exp === "Telefon") {return "Phone";}
            if (exp === "Kleinunternehmer") {return "German small business tax regulation";}
            if (exp === "bankAccountType") {return "Type of Bank Account";}
            if (exp === "Strasse") {return "Street";}
            if (exp === "Kontoinhaber") {return "Account Owner";}
            if (exp === "EditMasterData") {return "Edit your Masterdata";}
        }

        return exp;
    }
    
    handleBankAccountTypeChange(event: React.ChangeEvent<HTMLInputElement>) {
        let bankAccountType = event.target.value;
        this.setState({ bankAccountType: bankAccountType });
    }

    handleCountryChange(event: React.ChangeEvent<HTMLInputElement>) {
        let land = event.target.value;
        this.setState({ land: land });
    }

    render () {
        if (this.context) {
            if (this.context.producerID === "") {
                return <Redirect to="/" push={true} />
            }
        } else {
            return <Redirect to="/" push={true} />
        }

        return (
            <div>
                <form id="myForm" ref={this.myFormRef} className="mt-4 form form-horizontal" onSubmit={this.handleSubmit.bind(this)}>
                    <input type="hidden" name="id" value={this.state.masterData?.id}></input>
                    <Card>
                        <CardHeader>
                            {this.getText("EditMasterData")}
                        </CardHeader>
                        <CardBody>
                            {this.state.saveData ? <p className="pt-4"><FontAwesomeIcon icon={solid('cog')} spin size="4x" /></p> : null }

                            {this.state.errorMessage !== "" ? <Alert color="danger">{this.state.errorMessage}</Alert> : null}
                            {this.state.infoMessage !== "" ? <Alert color="info">{this.state.infoMessage}</Alert> : null}

                            <Row >
                                <Col sm="12">
                                    <FormGroup>
                                        <Label for="firma">{this.getText("Firma")}</Label>
                                        <Input type='text' name="firma" autoComplete='organization' defaultValue={this.state.masterData?.firma}></Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col sm="12" md="2">
                                    <FormGroup>
                                        <Label for="gender">{this.getText("Gender")}</Label>
                                        <Input type='select' name="gender" defaultValue={this.state.masterData?.gender}>
                                        <option value="f">{this.getText("Female")}</option>
                                        <option value="m">{this.getText("Male")}</option>
                                        <option value="d">{this.getText("Other")}</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm="12" md="5">
                                    <FormGroup>
                                        <Label for="name">{this.getText("Name")}</Label>
                                        <Input type='text' name="name" autoComplete='family-name' defaultValue={this.state.masterData?.name}></Input>
                                    </FormGroup>
                                </Col>
                                <Col sm="12" md="5">
                                    <FormGroup>
                                        <Label for="vorname">{this.getText("Vorname")}</Label>
                                        <Input type='text' name="vorname" autoComplete='given-name' defaultValue={this.state.masterData?.vorname}></Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col sm="12">
                                    <FormGroup>
                                        <Label for="strasse">{this.getText("Strasse")}</Label>
                                        <Input type='text' name="strasse" autoComplete='address-line1' defaultValue={this.state.masterData?.strasse}></Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col sm="12" md="4">
                                    <FormGroup>
                                        <Label for="plz">{this.getText("PLZ")}</Label>
                                        <Input type='text' name="plz" autoComplete='postal-code' defaultValue={this.state.masterData?.plz}></Input>
                                    </FormGroup>
                                </Col>
                                <Col sm="12" md="8">
                                    <FormGroup>
                                        <Label for="ort">{this.getText("Ort")}</Label>
                                        <Input type='text' name="ort" autoComplete='address-level2' defaultValue={this.state.masterData?.ort}></Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col sm="12">
                                    <FormGroup>
                                        <Label for="land">{this.getText("Land")}</Label>
                                        <Input type='select' name="land" autoComplete='country' onChange={this.handleCountryChange.bind(this)}>
                                        <option selected={this.state.masterData?.land === "DE" ? true : false} value="DE">Germany</option>
                                        <option selected={this.state.masterData?.land === "AF" ? true : false} value="AF">Afghanistan</option>
                                        <option selected={this.state.masterData?.land === "AL" ? true : false} value="AL">Albania</option>
                                        <option selected={this.state.masterData?.land === "DZ" ? true : false} value="DZ">Algeria</option>
                                        <option selected={this.state.masterData?.land === "AS" ? true : false} value="AS">American Samoa</option>
                                        <option selected={this.state.masterData?.land === "AD" ? true : false} value="AD">Andorra</option>
                                        <option selected={this.state.masterData?.land === "AO" ? true : false} value="AO">Angola</option>
                                        <option selected={this.state.masterData?.land === "AI" ? true : false} value="AI">Anguilla</option>
                                        <option selected={this.state.masterData?.land === "AQ" ? true : false} value="AQ">Antarctica</option>
                                        <option selected={this.state.masterData?.land === "AG" ? true : false} value="AG">Antigua And Barbuda</option>
                                        <option selected={this.state.masterData?.land === "AR" ? true : false} value="AR">Argentina</option>
                                        <option selected={this.state.masterData?.land === "AM" ? true : false} value="AM">Armenia</option>
                                        <option selected={this.state.masterData?.land === "AW" ? true : false} value="AW">Aruba</option>
                                        <option selected={this.state.masterData?.land === "AU" ? true : false} value="AU">Australia</option>
                                        <option selected={this.state.masterData?.land === "AT" ? true : false} value="AT">Austria</option>
                                        <option selected={this.state.masterData?.land === "AZ" ? true : false} value="AZ">Azerbaijan</option>
                                        <option selected={this.state.masterData?.land === "BS" ? true : false} value="BS">Bahamas</option>
                                        <option selected={this.state.masterData?.land === "BH" ? true : false} value="BH">Bahrain</option>
                                        <option selected={this.state.masterData?.land === "BD" ? true : false} value="BD">Bangladesh</option>
                                        <option selected={this.state.masterData?.land === "BB" ? true : false} value="BB">Barbados</option>
                                        <option selected={this.state.masterData?.land === "BY" ? true : false} value="BY">Belarus</option>
                                        <option selected={this.state.masterData?.land === "BE" ? true : false} value="BE">Belgium</option>
                                        <option selected={this.state.masterData?.land === "BZ" ? true : false} value="BZ">Belize</option>
                                        <option selected={this.state.masterData?.land === "BJ" ? true : false} value="BJ">Benin</option>
                                        <option selected={this.state.masterData?.land === "BM" ? true : false} value="BM">Bermuda</option>
                                        <option selected={this.state.masterData?.land === "BT" ? true : false} value="BT">Bhutan</option>
                                        <option selected={this.state.masterData?.land === "BO" ? true : false} value="BO">Bolivia</option>
                                        <option selected={this.state.masterData?.land === "BA" ? true : false} value="BA">Bosnia And Herzegowina</option>
                                        <option selected={this.state.masterData?.land === "BW" ? true : false} value="BW">Botswana</option>
                                        <option selected={this.state.masterData?.land === "BV" ? true : false} value="BV">Bouvet Island</option>
                                        <option selected={this.state.masterData?.land === "BR" ? true : false} value="BR">Brazil</option>
                                        <option selected={this.state.masterData?.land === "IO" ? true : false} value="IO">British Indian Ocean Territory</option>
                                        <option selected={this.state.masterData?.land === "BN" ? true : false} value="BN">Brunei Darussalam</option>
                                        <option selected={this.state.masterData?.land === "BG" ? true : false} value="BG">Bulgaria</option>
                                        <option selected={this.state.masterData?.land === "BF" ? true : false} value="BF">Burkina Faso</option>
                                        <option selected={this.state.masterData?.land === "BI" ? true : false} value="BI">Burundi</option>
                                        <option selected={this.state.masterData?.land === "KH" ? true : false} value="KH">Cambodia</option>
                                        <option selected={this.state.masterData?.land === "CM" ? true : false} value="CM">Cameroon</option>
                                        <option selected={this.state.masterData?.land === "CA" ? true : false} value="CA">Canada</option>
                                        <option selected={this.state.masterData?.land === "CV" ? true : false} value="CV">Cape Verde</option>
                                        <option selected={this.state.masterData?.land === "KY" ? true : false} value="KY">Cayman Islands</option>
                                        <option selected={this.state.masterData?.land === "CF" ? true : false} value="CF">Central African Republic</option>
                                        <option selected={this.state.masterData?.land === "TD" ? true : false} value="TD">Chad</option>
                                        <option selected={this.state.masterData?.land === "CL" ? true : false} value="CL">Chile</option>
                                        <option selected={this.state.masterData?.land === "CN" ? true : false} value="CN">China</option>
                                        <option selected={this.state.masterData?.land === "CX" ? true : false} value="CX">Christmas Island</option>
                                        <option selected={this.state.masterData?.land === "CC" ? true : false} value="CC">Cocos (Keeling) Islands</option>
                                        <option selected={this.state.masterData?.land === "CO" ? true : false} value="CO">Colombia</option>
                                        <option selected={this.state.masterData?.land === "KM" ? true : false} value="KM">Comoros</option>
                                        <option selected={this.state.masterData?.land === "CG" ? true : false} value="CG">Congo</option>
                                        <option selected={this.state.masterData?.land === "CK" ? true : false} value="CK">Cook Islands</option>
                                        <option selected={this.state.masterData?.land === "CR" ? true : false} value="CR">Costa Rica</option>
                                        <option selected={this.state.masterData?.land === "CI" ? true : false} value="CI">Cote D'Ivoire</option>
                                        <option selected={this.state.masterData?.land === "HR" ? true : false} value="HR">Croatia (Local Name: Hrvatska)</option>
                                        <option selected={this.state.masterData?.land === "CU" ? true : false} value="CU">Cuba</option>
                                        <option selected={this.state.masterData?.land === "CY" ? true : false} value="CY">Cyprus</option>
                                        <option selected={this.state.masterData?.land === "CZ" ? true : false} value="CZ">Czech Republic</option>
                                        <option selected={this.state.masterData?.land === "DK" ? true : false} value="DK">Denmark</option>
                                        <option selected={this.state.masterData?.land === "DJ" ? true : false} value="DJ">Djibouti</option>
                                        <option selected={this.state.masterData?.land === "DM" ? true : false} value="DM">Dominica</option>
                                        <option selected={this.state.masterData?.land === "DO" ? true : false} value="DO">Dominican Republic</option>
                                        <option selected={this.state.masterData?.land === "TP" ? true : false} value="TP">East Timor</option>
                                        <option selected={this.state.masterData?.land === "EC" ? true : false} value="EC">Ecuador</option>
                                        <option selected={this.state.masterData?.land === "EG" ? true : false} value="EG">Egypt</option>
                                        <option selected={this.state.masterData?.land === "SV" ? true : false} value="SV">El Salvador</option>
                                        <option selected={this.state.masterData?.land === "GQ" ? true : false} value="GQ">Equatorial Guinea</option>
                                        <option selected={this.state.masterData?.land === "ER" ? true : false} value="ER">Eritrea</option>
                                        <option selected={this.state.masterData?.land === "EE" ? true : false} value="EE">Estonia</option>
                                        <option selected={this.state.masterData?.land === "ET" ? true : false} value="ET">Ethiopia</option>
                                        <option selected={this.state.masterData?.land === "FK" ? true : false} value="FK">Falkland Islands (Malvinas)</option>
                                        <option selected={this.state.masterData?.land === "FO" ? true : false} value="FO">Faroe Islands</option>
                                        <option selected={this.state.masterData?.land === "FJ" ? true : false} value="FJ">Fiji</option>
                                        <option selected={this.state.masterData?.land === "FI" ? true : false} value="FI">Finland</option>
                                        <option selected={this.state.masterData?.land === "FR" ? true : false} value="FR">France</option>
                                        <option selected={this.state.masterData?.land === "GF" ? true : false} value="GF">French Guiana</option>
                                        <option selected={this.state.masterData?.land === "PF" ? true : false} value="PF">French Polynesia</option>
                                        <option selected={this.state.masterData?.land === "TF" ? true : false} value="TF">French Southern Territories</option>
                                        <option selected={this.state.masterData?.land === "GA" ? true : false} value="GA">Gabon</option>
                                        <option selected={this.state.masterData?.land === "GM" ? true : false} value="GM">Gambia</option>
                                        <option selected={this.state.masterData?.land === "GE" ? true : false} value="GE">Georgia</option>
                                        <option selected={this.state.masterData?.land === "GH" ? true : false} value="GH">Ghana</option>
                                        <option selected={this.state.masterData?.land === "GI" ? true : false} value="GI">Gibraltar</option>
                                        <option selected={this.state.masterData?.land === "GR" ? true : false} value="GR">Greece</option>
                                        <option selected={this.state.masterData?.land === "GL" ? true : false} value="GL">Greenland</option>
                                        <option selected={this.state.masterData?.land === "GD" ? true : false} value="GD">Grenada</option>
                                        <option selected={this.state.masterData?.land === "GP" ? true : false} value="GP">Guadeloupe</option>
                                        <option selected={this.state.masterData?.land === "GU" ? true : false} value="GU">Guam</option>
                                        <option selected={this.state.masterData?.land === "GT" ? true : false} value="GT">Guatemala</option>
                                        <option selected={this.state.masterData?.land === "GN" ? true : false} value="GN">Guinea</option>
                                        <option selected={this.state.masterData?.land === "GW" ? true : false} value="GW">Guinea-Bissau</option>
                                        <option selected={this.state.masterData?.land === "GY" ? true : false} value="GY">Guyana</option>
                                        <option selected={this.state.masterData?.land === "HT" ? true : false} value="HT">Haiti</option>
                                        <option selected={this.state.masterData?.land === "HM" ? true : false} value="HM">Heard And Mc Donald Islands</option>
                                        <option selected={this.state.masterData?.land === "VA" ? true : false} value="VA">Holy See (Vatican City State)</option>
                                        <option selected={this.state.masterData?.land === "HN" ? true : false} value="HN">Honduras</option>
                                        <option selected={this.state.masterData?.land === "HK" ? true : false} value="HK">Hong Kong</option>
                                        <option selected={this.state.masterData?.land === "HU" ? true : false} value="HU">Hungary</option>
                                        <option selected={this.state.masterData?.land === "IS" ? true : false} value="IS">Icel And</option>
                                        <option selected={this.state.masterData?.land === "IN" ? true : false} value="IN">India</option>
                                        <option selected={this.state.masterData?.land === "ID" ? true : false} value="ID">Indonesia</option>
                                        <option selected={this.state.masterData?.land === "IR" ? true : false} value="IR">Iran (Islamic Republic Of)</option>
                                        <option selected={this.state.masterData?.land === "IQ" ? true : false} value="IQ">Iraq</option>
                                        <option selected={this.state.masterData?.land === "IE" ? true : false} value="IE">Ireland</option>
                                        <option selected={this.state.masterData?.land === "IL" ? true : false} value="IL">Israel</option>
                                        <option selected={this.state.masterData?.land === "IT" ? true : false} value="IT">Italy</option>
                                        <option selected={this.state.masterData?.land === "JM" ? true : false} value="JM">Jamaica</option>
                                        <option selected={this.state.masterData?.land === "JP" ? true : false} value="JP">Japan</option>
                                        <option selected={this.state.masterData?.land === "JO" ? true : false} value="JO">Jordan</option>
                                        <option selected={this.state.masterData?.land === "KZ" ? true : false} value="KZ">Kazakhstan</option>
                                        <option selected={this.state.masterData?.land === "KE" ? true : false} value="KE">Kenya</option>
                                        <option selected={this.state.masterData?.land === "KI" ? true : false} value="KI">Kiribati</option>
                                        <option selected={this.state.masterData?.land === "KP" ? true : false} value="KP">Korea, Dem People'S Republic</option>
                                        <option selected={this.state.masterData?.land === "KR" ? true : false} value="KR">Korea, Republic Of</option>
                                        <option selected={this.state.masterData?.land === "KW" ? true : false} value="KW">Kuwait</option>
                                        <option selected={this.state.masterData?.land === "KG" ? true : false} value="KG">Kyrgyzstan</option>
                                        <option selected={this.state.masterData?.land === "LA" ? true : false} value="LA">Lao People'S Dem Republic</option>
                                        <option selected={this.state.masterData?.land === "LV" ? true : false} value="LV">Latvia</option>
                                        <option selected={this.state.masterData?.land === "LB" ? true : false} value="LB">Lebanon</option>
                                        <option selected={this.state.masterData?.land === "LS" ? true : false} value="LS">Lesotho</option>
                                        <option selected={this.state.masterData?.land === "LR" ? true : false} value="LR">Liberia</option>
                                        <option selected={this.state.masterData?.land === "LY" ? true : false} value="LY">Libyan Arab Jamahiriya</option>
                                        <option selected={this.state.masterData?.land === "LI" ? true : false} value="LI">Liechtenstein</option>
                                        <option selected={this.state.masterData?.land === "LT" ? true : false} value="LT">Lithuania</option>
                                        <option selected={this.state.masterData?.land === "LU" ? true : false} value="LU">Luxembourg</option>
                                        <option selected={this.state.masterData?.land === "MO" ? true : false} value="MO">Macau</option>
                                        <option selected={this.state.masterData?.land === "MK" ? true : false} value="MK">Macedonia</option>
                                        <option selected={this.state.masterData?.land === "MG" ? true : false} value="MG">Madagascar</option>
                                        <option selected={this.state.masterData?.land === "MW" ? true : false} value="MW">Malawi</option>
                                        <option selected={this.state.masterData?.land === "MY" ? true : false} value="MY">Malaysia</option>
                                        <option selected={this.state.masterData?.land === "MV" ? true : false} value="MV">Maldives</option>
                                        <option selected={this.state.masterData?.land === "ML" ? true : false} value="ML">Mali</option>
                                        <option selected={this.state.masterData?.land === "MT" ? true : false} value="MT">Malta</option>
                                        <option selected={this.state.masterData?.land === "MH" ? true : false} value="MH">Marshall Islands</option>
                                        <option selected={this.state.masterData?.land === "MQ" ? true : false} value="MQ">Martinique</option>
                                        <option selected={this.state.masterData?.land === "MR" ? true : false} value="MR">Mauritania</option>
                                        <option selected={this.state.masterData?.land === "MU" ? true : false} value="MU">Mauritius</option>
                                        <option selected={this.state.masterData?.land === "YT" ? true : false} value="YT">Mayotte</option>
                                        <option selected={this.state.masterData?.land === "MX" ? true : false} value="MX">Mexico</option>
                                        <option selected={this.state.masterData?.land === "FM" ? true : false} value="FM">Micronesia, Federated States</option>
                                        <option selected={this.state.masterData?.land === "MD" ? true : false} value="MD">Moldova, Republic Of</option>
                                        <option selected={this.state.masterData?.land === "MC" ? true : false} value="MC">Monaco</option>
                                        <option selected={this.state.masterData?.land === "MN" ? true : false} value="MN">Mongolia</option>
                                        <option selected={this.state.masterData?.land === "MS" ? true : false} value="MS">Montserrat</option>
                                        <option selected={this.state.masterData?.land === "MA" ? true : false} value="MA">Morocco</option>
                                        <option selected={this.state.masterData?.land === "MZ" ? true : false} value="MZ">Mozambique</option>
                                        <option selected={this.state.masterData?.land === "MM" ? true : false} value="MM">Myanmar</option>
                                        <option selected={this.state.masterData?.land === "NA" ? true : false} value="NA">Namibia</option>
                                        <option selected={this.state.masterData?.land === "NR" ? true : false} value="NR">Nauru</option>
                                        <option selected={this.state.masterData?.land === "NP" ? true : false} value="NP">Nepal</option>
                                        <option selected={this.state.masterData?.land === "NL" ? true : false} value="NL">Netherlands</option>
                                        <option selected={this.state.masterData?.land === "AN" ? true : false} value="AN">Netherlands Ant Illes</option>
                                        <option selected={this.state.masterData?.land === "NC" ? true : false} value="NC">New Caledonia</option>
                                        <option selected={this.state.masterData?.land === "NZ" ? true : false} value="NZ">New Zealand</option>
                                        <option selected={this.state.masterData?.land === "NI" ? true : false} value="NI">Nicaragua</option>
                                        <option selected={this.state.masterData?.land === "NE" ? true : false} value="NE">Niger</option>
                                        <option selected={this.state.masterData?.land === "NG" ? true : false} value="NG">Nigeria</option>
                                        <option selected={this.state.masterData?.land === "NU" ? true : false} value="NU">Niue</option>
                                        <option selected={this.state.masterData?.land === "NF" ? true : false} value="NF">Norfolk Island</option>
                                        <option selected={this.state.masterData?.land === "MP" ? true : false} value="MP">Northern Mariana Islands</option>
                                        <option selected={this.state.masterData?.land === "NO" ? true : false} value="NO">Norway</option>
                                        <option selected={this.state.masterData?.land === "OM" ? true : false} value="OM">Oman</option>
                                        <option selected={this.state.masterData?.land === "PK" ? true : false} value="PK">Pakistan</option>
                                        <option selected={this.state.masterData?.land === "PW" ? true : false} value="PW">Palau</option>
                                        <option selected={this.state.masterData?.land === "PA" ? true : false} value="PA">Panama</option>
                                        <option selected={this.state.masterData?.land === "PG" ? true : false} value="PG">Papua New Guinea</option>
                                        <option selected={this.state.masterData?.land === "PY" ? true : false} value="PY">Paraguay</option>
                                        <option selected={this.state.masterData?.land === "PE" ? true : false} value="PE">Peru</option>
                                        <option selected={this.state.masterData?.land === "PH" ? true : false} value="PH">Philippines</option>
                                        <option selected={this.state.masterData?.land === "PN" ? true : false} value="PN">Pitcairn</option>
                                        <option selected={this.state.masterData?.land === "PL" ? true : false} value="PL">Poland</option>
                                        <option selected={this.state.masterData?.land === "PT" ? true : false} value="PT">Portugal</option>
                                        <option selected={this.state.masterData?.land === "PR" ? true : false} value="PR">Puerto Rico</option>
                                        <option selected={this.state.masterData?.land === "QA" ? true : false} value="QA">Qatar</option>
                                        <option selected={this.state.masterData?.land === "RE" ? true : false} value="RE">Reunion</option>
                                        <option selected={this.state.masterData?.land === "RO" ? true : false} value="RO">Romania</option>
                                        <option selected={this.state.masterData?.land === "RU" ? true : false} value="RU">Russian Federation</option>
                                        <option selected={this.state.masterData?.land === "RW" ? true : false} value="RW">Rwanda</option>
                                        <option selected={this.state.masterData?.land === "KN" ? true : false} value="KN">Saint K Itts And Nevis</option>
                                        <option selected={this.state.masterData?.land === "LC" ? true : false} value="LC">Saint Lucia</option>
                                        <option selected={this.state.masterData?.land === "VC" ? true : false} value="VC">Saint Vincent, The Grenadines</option>
                                        <option selected={this.state.masterData?.land === "WS" ? true : false} value="WS">Samoa</option>
                                        <option selected={this.state.masterData?.land === "SM" ? true : false} value="SM">San Marino</option>
                                        <option selected={this.state.masterData?.land === "ST" ? true : false} value="ST">Sao Tome And Principe</option>
                                        <option selected={this.state.masterData?.land === "SA" ? true : false} value="SA">Saudi Arabia</option>
                                        <option selected={this.state.masterData?.land === "SN" ? true : false} value="SN">Senegal</option>
                                        <option selected={this.state.masterData?.land === "SC" ? true : false} value="SC">Seychelles</option>
                                        <option selected={this.state.masterData?.land === "SL" ? true : false} value="SL">Sierra Leone</option>
                                        <option selected={this.state.masterData?.land === "SG" ? true : false} value="SG">Singapore</option>
                                        <option selected={this.state.masterData?.land === "SK" ? true : false} value="SK">Slovakia (Slovak Republic)</option>
                                        <option selected={this.state.masterData?.land === "SI" ? true : false} value="SI">Slovenia</option>
                                        <option selected={this.state.masterData?.land === "SB" ? true : false} value="SB">Solomon Islands</option>
                                        <option selected={this.state.masterData?.land === "SO" ? true : false} value="SO">Somalia</option>
                                        <option selected={this.state.masterData?.land === "ZA" ? true : false} value="ZA">South Africa</option>
                                        <option selected={this.state.masterData?.land === "GS" ? true : false} value="GS">South Georgia , S Sandwich Is.</option>
                                        <option selected={this.state.masterData?.land === "ES" ? true : false} value="ES">Spain</option>
                                        <option selected={this.state.masterData?.land === "LK" ? true : false} value="LK">Sri Lanka</option>
                                        <option selected={this.state.masterData?.land === "SH" ? true : false} value="SH">St. Helena</option>
                                        <option selected={this.state.masterData?.land === "PM" ? true : false} value="PM">St. Pierre And Miquelon</option>
                                        <option selected={this.state.masterData?.land === "SD" ? true : false} value="SD">Sudan</option>
                                        <option selected={this.state.masterData?.land === "SR" ? true : false} value="SR">Suriname</option>
                                        <option selected={this.state.masterData?.land === "SJ" ? true : false} value="SJ">Svalbard, Jan Mayen Islands</option>
                                        <option selected={this.state.masterData?.land === "SZ" ? true : false} value="SZ">Sw Aziland</option>
                                        <option selected={this.state.masterData?.land === "SE" ? true : false} value="SE">Sweden</option>
                                        <option selected={this.state.masterData?.land === "CH" ? true : false} value="CH">Switzerland</option>
                                        <option selected={this.state.masterData?.land === "SY" ? true : false} value="SY">Syrian Arab Republic</option>
                                        <option selected={this.state.masterData?.land === "TW" ? true : false} value="TW">Taiwan</option>
                                        <option selected={this.state.masterData?.land === "TJ" ? true : false} value="TJ">Tajikistan</option>
                                        <option selected={this.state.masterData?.land === "TZ" ? true : false} value="TZ">Tanzania, United Republic Of</option>
                                        <option selected={this.state.masterData?.land === "TH" ? true : false} value="TH">Thailand</option>
                                        <option selected={this.state.masterData?.land === "TG" ? true : false} value="TG">Togo</option>
                                        <option selected={this.state.masterData?.land === "TK" ? true : false} value="TK">Tokelau</option>
                                        <option selected={this.state.masterData?.land === "TO" ? true : false} value="TO">Tonga</option>
                                        <option selected={this.state.masterData?.land === "TT" ? true : false} value="TT">Trinidad And Tobago</option>
                                        <option selected={this.state.masterData?.land === "TN" ? true : false} value="TN">Tunisia</option>
                                        <option selected={this.state.masterData?.land === "TR" ? true : false} value="TR">Turkey</option>
                                        <option selected={this.state.masterData?.land === "TM" ? true : false} value="TM">Turkmenistan</option>
                                        <option selected={this.state.masterData?.land === "TC" ? true : false} value="TC">Turks And Caicos Islands</option>
                                        <option selected={this.state.masterData?.land === "TV" ? true : false} value="TV">Tuvalu</option>
                                        <option selected={this.state.masterData?.land === "UG" ? true : false} value="UG">Uganda</option>
                                        <option selected={this.state.masterData?.land === "UA" ? true : false} value="UA">Ukraine</option>
                                        <option selected={this.state.masterData?.land === "AE" ? true : false} value="AE">United Arab Emirates</option>
                                        <option selected={this.state.masterData?.land === "GB" ? true : false} value="GB">United Kingdom</option>
                                        <option selected={this.state.masterData?.land === "US" ? true : false} value="US">United States</option>
                                        <option selected={this.state.masterData?.land === "UM" ? true : false} value="UM">United States Minor Is.</option>
                                        <option selected={this.state.masterData?.land === "UY" ? true : false} value="UY">Uruguay</option>
                                        <option selected={this.state.masterData?.land === "UZ" ? true : false} value="UZ">Uzbekistan</option>
                                        <option selected={this.state.masterData?.land === "VU" ? true : false} value="VU">Vanuatu</option>
                                        <option selected={this.state.masterData?.land === "VE" ? true : false} value="VE">Venezuela</option>
                                        <option selected={this.state.masterData?.land === "VN" ? true : false} value="VN">Viet Nam</option>
                                        <option selected={this.state.masterData?.land === "VG" ? true : false} value="VG">Virgin Islands (British)</option>
                                        <option selected={this.state.masterData?.land === "VI" ? true : false} value="VI">Virgin Islands (U.S.)</option>
                                        <option selected={this.state.masterData?.land === "WF" ? true : false} value="WF">Wallis And Futuna Islands</option>
                                        <option selected={this.state.masterData?.land === "EH" ? true : false} value="EH">Western Sahara</option>
                                        <option selected={this.state.masterData?.land === "YE" ? true : false} value="YE">Yemen</option>
                                        <option selected={this.state.masterData?.land === "RS" ? true : false} value="RS">Serbia</option>
                                        <option selected={this.state.masterData?.land === "ME" ? true : false} value="ME">Montenegro</option>
                                        <option selected={this.state.masterData?.land === "ZR" ? true : false} value="ZR">Zaire</option>
                                        <option selected={this.state.masterData?.land === "ZM" ? true : false} value="ZM">Zambia</option>
                                        <option selected={this.state.masterData?.land === "ZW" ? true : false} value="ZW">Zimbabwe</option>                                            </Input>
                                    </FormGroup>
                                </Col>
                            </Row>    
                            <Row className='mt-2'>
                                <Col sm="12">
                                    <FormGroup>
                                        <Label for="telefon">{this.getText("Telefon")}</Label>
                                        <Input type='text' name="telefon" autoComplete='tel' defaultValue={this.state.masterData?.telefon}></Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col sm="12">
                                    <FormGroup>
                                        <Label for="instagram">{this.getText("Instagram")}</Label>
                                        <Input type='text' name="instagram" defaultValue={this.state.masterData?.instagram}></Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col sm="12">
                                    <FormGroup>
                                        <Label for="twitter">{this.getText("Twitter")}</Label>
                                        <Input type='text' name="twitter" defaultValue={this.state.masterData?.twitter}></Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col sm="12">
                                    <FormGroup>
                                        <Label for="skype">{this.getText("Skype")}</Label>
                                        <Input type='text' name="skype" defaultValue={this.state.masterData?.skype}></Input>
                                    </FormGroup>
                                </Col>
                            </Row>  
                            <hr></hr>                                                      
                            <Row className='mt-2'>
                                <Col sm="12">
                                    <FormGroup>
                                        <Label for="ustID">{this.getText("UstID")}</Label>
                                        <Input type='text' name="ustID" defaultValue={this.state.masterData?.ustID}></Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col sm="12">
                                    <FormGroup check>
                                        <Label check>
                                        <Input type="checkbox" name="kleinunternehmer"  defaultValue={this.state.masterData?.kleinunternehmer}/>{' '}
                                        {this.getText("Kleinunternehmer")}
                                        </Label>
                                    </FormGroup>          
                                </Col>    
                            </Row> 
                            <hr></hr>
                            <Row className='mt-2'>
                                <Col sm="12">
                                    <FormGroup>
                                        <Label for="bank">{this.getText("Kontoinhaber")}</Label>
                                        <Input type='text' name="kontoinhaber" defaultValue={this.state.masterData?.kontoinhaber} autoComplete="cc-name"></Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col sm="12">
                                    <FormGroup>
                                        <Label for="bankAccountType">{this.getText("bankAccountType")}</Label>
                                        <Input type='select' name="bankAccountType" onChange={this.handleBankAccountTypeChange.bind(this)}  defaultValue={this.state.masterData?.bankAccountType}>
                                        <option value="IBAN">{this.getText("IBAN/BIC")}</option>
                                        <option value="International">{this.getText("International (RoutingNumber)")}</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>                                             
                            <Row className='mt-2'>
                                <Col sm="12">
                                    <FormGroup>
                                        <Label for="bank">{this.getText("Bank")}</Label>
                                        <Input type='text' name="bank" defaultValue={this.state.masterData?.bank}></Input>
                                    </FormGroup>
                                </Col>
                            </Row>  
                            <Row className='mt-2'>
                                <Col sm="12">
                                    <FormGroup>
                                        <Label for="iban">{this.getText("IBAN")}</Label>
                                        <Input type='text' name="iban" defaultValue={this.state.masterData?.iban}></Input>
                                    </FormGroup>
                                </Col>
                            </Row>  
                            <Row className='mt-2'>
                                <Col sm="12">
                                    <FormGroup>
                                        <Label for="bic">{this.getText("BIC")}</Label>
                                        <Input type='text' name="bic" defaultValue={this.state.masterData?.bic}></Input>
                                    </FormGroup>
                                </Col>
                            </Row>  
                            <Row className='mt-2' hidden={this.state.bankAccountType != "International"}>
                                <Col sm="12">
                                    <FormGroup>
                                        <Label for="routingNumber">{this.getText("RoutingNumber")}</Label>
                                        <Input type='text' name="routingNumber" defaultValue={this.state.masterData?.routingNumber}></Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            {this.state.saveData ? <p className="pt-4"><FontAwesomeIcon icon={solid('cog')} spin size="4x" /></p> : null }
                        </CardBody>
                        <CardFooter>
                            <Button disabled={this.state.saveData} type='submit' onClick={this.handleSubmitButton.bind(this)} variant="primary" bsSize="sm">{this.getText("Submit")}</Button>
                        </CardFooter>
                    </Card>
                </form>
            </div>
        );
    }
}
