import React, { Component } from 'react';
import { AppCtx, AppContextInterface, ApiHelper } from '../AppContextInterface';
import { Card, Row, Col, Alert, Modal, ModalHeader, ModalBody, ModalFooter, Container, Form, FormGroup, Button, Label, Input, ButtonGroup, Dropdown, DropdownMenu, DropdownItem,NavLink, CardHeader, CardBody, CardFooter } from 'reactstrap';
import { Link } from 'react-router-dom';
import { VideoList,VideoData } from './VideoList';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { IDShotRecord,IDShotRecordFile } from './IDShotManager';
import IDShotManagerModal from './IDShotManagerModal';
import ImageUpload from './ImageUpload';
import VideoThumbModal from './VideoThumbModal';

interface VideoSingleEditState {
    infoMessage: string;
    errorMessage: string;
    language: string;
    selectedVideoDataURL: string;
    fetching: boolean;
    additionalPeople: string;
    additionalPeopleConfirm: boolean;
    additionalPeopleReviewStatus: number;
    additionalPeopleReviewComment: string;
    tempGuid: string;
    singleImageOperation: boolean;
    selectedVideoData: VideoData|null,
    singleVideoAPIAction: boolean,
    showVideoThumbModal: string,
    videoPlayURL: string,
    fetchIDShotAPIAction: boolean,
    imageUploadMode: string,
    idshots: IDShotRecord[],
    idshotAdd: boolean,
    videoType: string;
    
}

interface VideoSingleEditProps {
    streamName: string;
    closeCallback(editedVideo:VideoData|null):void;
}

export interface VideoSingleEdit extends React.Component {
    state: VideoSingleEditState;
    props: VideoSingleEditProps;
    contextType: AppContextInterface;
}

export class VideoSingleEdit extends Component {
    static contextType = AppCtx;
    context!: React.ContextType<typeof AppCtx>;

    constructor(props: VideoSingleEditProps) { 
        super(props);
        let contextLanguage = "de";
        if (this.context) {
            if (this.context.language !== null && this.context.language !== "") {
                contextLanguage = this.context.language;
            }
        }

        this.state = {
            infoMessage: "",
            errorMessage: "",
            language: contextLanguage,
            selectedVideoData: null,
            selectedVideoDataURL: "",
            fetching: false,
            additionalPeople: "",
            additionalPeopleConfirm: false,
            additionalPeopleReviewStatus: 0,
            additionalPeopleReviewComment: "",
            tempGuid: "",
            singleImageOperation: false,
            showVideoThumbModal: "",
            videoPlayURL: "",
            fetchIDShotAPIAction: false,
            imageUploadMode: "",
            idshots: [],
            idshotAdd: false,
            videoType: "Premium",
            singleVideoAPIAction: false
        }
    }

    componentDidMount(): void {
        this.fetchSingleVideo(this.props.streamName);
    }

    fetchSingleVideo = async (streamName:string) => {
        this.setState({ singleVideoAPIAction: true });
        this.setState({ errorMessage: "" });
        this.setState({ successMessage: "" });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/video/" + encodeURIComponent(streamName) + "?role=producer&videoType=Premium";

        if (this.context) {
            jsonurl += "&producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        }

        let response: any = null;

        try {
            response = await fetch(jsonurl);
            let data = await response.json();
            this.setState({ singleVideoAPIAction: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                    if(!ApiHelper.checkResponse(data,this.context) && this.context !== null) {
                        this.context.producerID = "";
                        alert("Please relogin");
                    }
                }
            }

            this.setState({ selectedVideoData: data.video });
            
            this.fetchIDShotsOfSingleVideo();

        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching data: " + error.toString() });
            this.setState({ singleVideoAPIAction: false });
        }
    }

    fetchIDShotsOfSingleVideo = async () => {
        let streamName:string = this.state.selectedVideoData?.streamname || "";

        this.setState({ singleVideoAPIAction: true });
        this.setState({ errorMessage: "" });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/videoidshot/" + encodeURIComponent(streamName);

        if (this.context) {
            jsonurl += "?producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        } else {
            alert("Cannot get idshots because of missing context");
        }

        let response: any = null;

        try {
            response = await fetch(jsonurl,{method: "GET"});
            let data = await response.json();
            this.setState({ singleVideoAPIAction: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                    if(!ApiHelper.checkResponse(data,this.context) && this.context !== null) {
                        this.context.producerID = "";
                        alert("Please relogin");
                    }
                }

                if(typeof(data.idshots) !== "undefined") {
                    if(data.idshots) {
                        this.setState({ idshots: data.idshots });
                    }
                }
            }
        } catch (error: any) {
            this.setState({ errorMessage: "Error gettings idshots: " + error.toString() });
            this.setState({ singleVideoAPIAction: false });
        }
    }

    getReviewTextForSelectedVideo() {
        return this.state.selectedVideoData?.reviewStatus === 0 ? this.getText("In review") : this.getText("Reviewed");
    }

    changeHardPic() {
        this.setState({ videoPlayURL: "" });
        this.setState({ showVideoThumbModal: "hard" });
        this.setState({ imageUploadMode: "" });
    }

    changeSoftPic() {
        this.setState({ videoPlayURL: "" });
        this.setState({ showVideoThumbModal: "soft" });
        this.setState({ imageUploadMode: "" });
    }

    deleteHardPic() {
        this.setState({ videoPlayURL: "" });
        this.setState({ showVideoThumbModal: "" });
        this.setState({ imageUploadMode: "" });
        let myVideoData:VideoData|null = this.state.selectedVideoData;
        if(myVideoData) {
            myVideoData.previewImageURLHard = "";
            this.setState({ selectedVideoData: myVideoData });
        }
    }

    deleteSoftPic() {
        this.setState({ videoPlayURL: "" });
        this.setState({ showVideoThumbModal: "" });
        this.setState({ imageUploadMode: "" });
        let myVideoData:VideoData|null = this.state.selectedVideoData;
        if(myVideoData) {
            myVideoData.previewImageURLSoft = "";
            this.setState({ selectedVideoData: myVideoData });
        }
    }

    copyHardPicToSoft() {
        let myVideoData:VideoData|null = this.state.selectedVideoData;
        if(myVideoData) {
            myVideoData.previewImageURLSoft = myVideoData.previewImageURLHard;
            this.setState({ selectedVideoData: myVideoData });
            this.copyHardPicToSoftAPI();
        }
    }

    copyHardPicToSoftAPI = async () => {
        let streamName:string = this.state.selectedVideoData?.streamname || "";

        this.setState({ singleVideoAPIAction: true });
        this.setState({ errorMessage: "" });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/video/" + encodeURIComponent(streamName) + "/copyhardtosoft";

        if (this.context) {
            jsonurl += "?producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        } else {
            alert("Cannot copy pic because of missing context");
        }

        let response: any = null;

        try {
            response = await fetch(jsonurl,{method: "PUT"});
            let data = await response.json();
            this.setState({ singleVideoAPIAction: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                    if(!ApiHelper.checkResponse(data,this.context) && this.context !== null) {
                        this.context.producerID = "";
                        alert("Please relogin");
                    }
                }
            }
        } catch (error: any) {
            this.setState({ errorMessage: "Error copying preview pic: " + error.toString() });
            this.setState({ singleVideoAPIAction: false });
        }
    }

    copySoftPicToHard() {
        let myVideoData:VideoData|null = this.state.selectedVideoData;
        if(myVideoData) {
            myVideoData.previewImageURLHard = myVideoData.previewImageURLSoft;
            this.setState({ selectedVideoData: myVideoData });
            this.copySoftPicToHardAPI();
        }
    }

    copySoftPicToHardAPI = async () => {
        let streamName:string = this.state.selectedVideoData?.streamname || "";

        this.setState({ singleVideoAPIAction: true });
        this.setState({ errorMessage: "" });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/video/" + encodeURIComponent(streamName) + "/copysofttohard";

        if (this.context) {
            jsonurl += "?producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        } else {
            alert("Cannot copy pic because of missing context");
        }

        let response: any = null;

        try {
            response = await fetch(jsonurl,{method: "PUT"});
            let data = await response.json();
            this.setState({ singleVideoAPIAction: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                    if(!ApiHelper.checkResponse(data,this.context) && this.context !== null) {
                        this.context.producerID = "";
                        alert("Please relogin");
                    }
                }
            }
        } catch (error: any) {
            this.setState({ errorMessage: "Error copying preview pic: " + error.toString() });
            this.setState({ singleVideoAPIAction: false });
        }
    }

    uploadHardPic() {
        this.setState({ videoPlayURL: "" });
        this.setState({ showVideoThumbModal: "" });
        this.setState({ imageUploadMode: "hard" });
    }

    uploadSoftPic() {
        this.setState({ videoPlayURL: "" });
        this.setState({ showVideoThumbModal: "" });
        this.setState({ imageUploadMode: "soft" });
    }

    uploadFinishHard() {
        this.setState({ imageUploadMode: "" });
        if(this.state.selectedVideoData) {
            setTimeout(this.fetchSingleVideo.bind(this,this.state.selectedVideoData.streamname), 1000);
            setTimeout(this.fetchSingleVideo.bind(this,this.state.selectedVideoData.streamname), 2000);
            setTimeout(this.fetchSingleVideo.bind(this,this.state.selectedVideoData.streamname), 3000);
        }
    }

    uploadFinishSoft() {
        this.setState({ imageUploadMode: "" });
        if(this.state.selectedVideoData) {
            setTimeout(this.fetchSingleVideo.bind(this,this.state.selectedVideoData.streamname), 1000);
            setTimeout(this.fetchSingleVideo.bind(this,this.state.selectedVideoData.streamname), 2000);
            setTimeout(this.fetchSingleVideo.bind(this,this.state.selectedVideoData.streamname), 3000);
        }
    }

    handleTitleChange(event: React.ChangeEvent<HTMLInputElement>) {
        let myVideoData:VideoData|null = this.state.selectedVideoData;
        if(myVideoData) {
            myVideoData.title = event.target.value;
            this.setState({ selectedVideoData: myVideoData });
        }
    }

    handleDescriptionChange(event: React.ChangeEvent<HTMLInputElement>) {
        let myVideoData:VideoData|null = this.state.selectedVideoData;
        if(myVideoData) {
            myVideoData.description = event.target.value;
            this.setState({ selectedVideoData: myVideoData });
        }
    }

    clickSave() {
        if(this.state.selectedVideoData) {
            this.saveVideo(this.state.selectedVideoData);
        }
    }

    clickClose() {
        this.props.closeCallback(this.state.selectedVideoData);
    }

    setThumb(mode:string,thumbUrl:string) {
        if(this.state.selectedVideoData) {
            let myVideoData:VideoData|null = this.state.selectedVideoData;

            if(myVideoData) {
                if(mode === "hard") {
                    myVideoData.previewImageURLHard = thumbUrl;
                } else if(mode === "soft") {
                    myVideoData.previewImageURLSoft = thumbUrl;
                }
                this.setState({ selectedVideoData: myVideoData });
            }
        }
    }

    detectAndroid():boolean {
        var ua = navigator.userAgent.toLowerCase();
        var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
        return isAndroid;
    }

    detectiOS():boolean {
        return [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }

    fetchVideoPlayURL = async () => {

        if(this.state.videoPlayURL !== "") {
            this.setState({ videoPlayURL: "" });
            return;
        }

        let streamName:string = this.state.selectedVideoData?.streamname || "";

        this.setState({ singleVideoAPIAction: true });
        this.setState({ errorMessage: "" });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/videoplay/" + encodeURIComponent(streamName);

        if (this.context) {
            jsonurl += "?userOrProducerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken) + "&role=producer";
            if(this.detectiOS()) {
                jsonurl += "&playerType=hls";
            } else {
                jsonurl += "&playerType=mp4";
            }
            jsonurl += "&viewContextType=control";
            jsonurl += "&viewContextID=control";
        } else {
            alert("Cannot get video play url because of missing context");
        }

        let response: any = null;

        try {
            response = await fetch(jsonurl);
            let data = await response.json();
            this.setState({ singleVideoAPIAction: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                    if(!ApiHelper.checkResponse(data,this.context) && this.context !== null) {
                        this.context.producerID = "";
                        alert("Please relogin");
                    }
                }
            }

            this.setState({ videoPlayURL: data.url });

        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching data: " + error.toString() });
            this.setState({ singleVideoAPIAction: false });
        }
    }

    saveVideo = async (videoData:VideoData) => {
        this.setState({ singleVideoAPIAction: true });
        this.setState({ errorMessage: "" });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/video/" + encodeURIComponent(videoData.streamname);

        if (this.context) {
            jsonurl += "?producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        }

        let response: any = null;

        try {
            response = await fetch(jsonurl,{method: "PUT", body: JSON.stringify(videoData), headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}});
            let data = await response.json();
            this.setState({ singleVideoAPIAction: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                    if(!ApiHelper.checkResponse(data,this.context) && this.context !== null) {
                        this.context.producerID = "";
                        alert("Please relogin");
                    }
                }
            }

        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching data: " + error.toString() });
            this.setState({ singleVideoAPIAction: false });
        }
    }

    idshotAddClick() {
        this.setState({ idshotAdd: true });
    }

    idshotAddSelect = async(idshot:IDShotRecord) => {
        this.setState({ idshotAdd: false });
        this.setState({ idshots: [...this.state.idshots, idshot] });

        let streamName:string = this.state.selectedVideoData?.streamname || "";

        this.setState({ singleVideoAPIAction: true });
        this.setState({ errorMessage: "" });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/videoidshot/" + encodeURIComponent(streamName);

        if (this.context) {
            jsonurl += "?producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        } else {
            alert("Cannot add idshot because of missing context");
        }

        jsonurl += "&idshotguid=" + encodeURIComponent(idshot.guid);

        let response: any = null;

        try {
            response = await fetch(jsonurl,{method: "PUT"});
            let data = await response.json();
            this.setState({ singleVideoAPIAction: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                    if(!ApiHelper.checkResponse(data,this.context) && this.context !== null) {
                        this.context.producerID = "";
                        alert("Please relogin");
                    }
                }
            }
        } catch (error: any) {
            this.setState({ errorMessage: "Error adding idshot: " + error.toString() });
            this.setState({ singleVideoAPIAction: false });
        }
    }

    idshotAddClose() {
        this.setState({ idshotAdd: false });
    }

    idshotDeleteClick = async(guid:string) => {
        let newArray:IDShotRecord[] = this.state.idshots.filter((item:IDShotRecord) => item.guid !== guid);
        this.setState({ idshots: newArray });

        let streamName:string = this.state.selectedVideoData?.streamname || "";

        this.setState({ singleVideoAPIAction: true });
        this.setState({ errorMessage: "" });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/videoidshot/" + encodeURIComponent(streamName);

        if (this.context) {
            jsonurl += "?producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        } else {
            alert("Cannot delete idshot because of missing context");
        }

        jsonurl += "&idshotguid=" + encodeURIComponent(guid);

        let response: any = null;

        try {
            response = await fetch(jsonurl,{method: "DELETE"});
            let data = await response.json();
            this.setState({ singleVideoAPIAction: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                    if(!ApiHelper.checkResponse(data,this.context) && this.context !== null) {
                        this.context.producerID = "";
                        alert("Please relogin");
                    }
                }
            }
        } catch (error: any) {
            this.setState({ errorMessage: "Error deleting idshot: " + error.toString() });
            this.setState({ singleVideoAPIAction: false });
        }
    }

    getText(exp: string) {
        if (this.state.language === "de") {
            if(exp === "BackToList") {
                return "Zurück zur Liste";
            }
            if (exp === "Posted") {
                return "In Timeline";
            }
            if (exp === "Not Posted") {
                return "Nicht in Timeline";
            }
            if (exp === "AddedPerson") {
                return "Hinzugefügte Person";
            }
            if (exp === "IDShotsExplanation") {
                return "Falls weitere Personen im Video auftauchen, benötigen wir eine Einverständniserklärung von diesen Personen. Diese kannst Du hier hochladen bzw. dem Video hinzufügen.";
            }
            if(exp === "AddIDShot") {
                return "Einverständniserklärung für Person hinzufügen";
            }
            if(exp === "VideoUploaded") {
                return "Video hochgeladen";
            }
            if(exp === "ReallyDeleteVideo") {
                return "Bist Du sicher, dass Du das Video löschen möchtest?";
            }
            if (exp === "Username") {
                return "Pseudo";
            }
            if (exp === "Deine Videos") {
                return "Deine Videoliste";
            }
            if (exp === "Reviewed") {
                return "Geprüft";
            }
            if (exp === "In review") {
                return "In Prüfung";
            }
            if (exp === "No Title") {
                return "Ohne Titel";
            }
            if(exp === "Change Pic") {
                return "Bild ändern";
            }
            if (exp === "Published") {
                return "Zur Veröffentlichung freigegeben";
            }
            if (exp === "Uploaded") {
                return "Hochgeladen";
            }
            if (exp === "ReviewStatus") {
                return "Prüfstatus";
            }
            if (exp === "Title") {
                return "Videotitel";
            }
            if (exp === "Description") {
                return "Beschreibungstext";
            }
            if (exp === "Public") {
                return "Veröffentlicht";
            }
            if (exp === "Not public") {
                return "Privat";
            }
            if(exp === "Altersverifikation für Drehpartner Description") {
                return "Der Gesetzgeber verlangt von uns Alter und Identität der Darsteller in Deinen Videos sicher zu stellen. Du kannst hier ab sofort eine Liste von Darstellern anlegen, die in Deinen Filmen mitwirken und einen ID-Shot für jeden Darsteller hochladen. Zusätzlich benötigen wir die von Deinem Drehpartner unterschriebene Einverständniserklärung.";
            }
            if(exp === "Altersverifikation für Drehpartner Description2") {
                return "Bei jedem Video, dass Du neu hochlädst musst Du angeben, wer neben Dir noch in den Videos mitwirkt. Erst dann können wir ein Video freigeben.";
            }
            if(exp === "SelectAutoThumb") {
                return "Thumbnail";
            }
            if(exp === "UploadPic") {
                return "Bild hochladen";
            }
            if(exp === "DeleteCurrentPic") {
                return "Bild löschen";
            }
            if(exp === "CopyToSoft") {
                return "Kopieren";
            }
            if(exp === "CopyToHard") {
                return "Kopieren";
            }
            if(exp === "NoIDShots") {
                return "Diesem Video sind im Moment keine Einverständniserklärungen von weiteren Personen zugeordnet";
            }
            if(exp === "NoFiles") {
                return "Keine Dateien zu diesem Datensatz";
            }
            if (exp === "Vorname") {
                return "Vorname";
            }
            if (exp === "Nachname") {
                return "Nachname";
            }
            if (exp === "Geburtsdatum") {
                return "Geburtsdatum";
            }
            if(exp === "uploadtime") {
                return "Zeitpunkt";
            }
            if(exp === "sortindex") {
                return "Eigene Sotierung";
            }
            if(exp === "dimensions") {
                return "Pixelauflösung";
            }
            if(exp === "uploadsize") {
                return "Dateigrösse";
            }
            if(exp === "reviewstatus") {
                return "Freischaltung";
            }
            if(exp === "adultstatus") {
                return "Alterseinstufung";
            }
        } else {
            if(exp === "BackToList") {
                return "Back to List";
            }
            if (exp === "AddedPerson") {
                return "Assgined Person";
            }
            if (exp === "IDShotsExplanation") {
                return "If other people appear in the video, we need a letter of consent from these people. You can upload it here or add it to the video.";
            }
            if(exp === "AddIDShot") {
                return "Add letter of consent for person in video";
            }
            if(exp === "NoFiles") {
                return "No files for this record";
            }
            if (exp === "Vorname") {
                return "First name";
            }
            if (exp === "Nachname") {
                return "Last name";
            }
            if (exp === "Geburtsdatum") {
                return "Birthday";
            }
            if(exp === "NoIDShots") {
                return "Currently there are no letters of consent for people in this video attached";
            }
            if(exp === "VideoUploaded") {
                return "Video uploaded";
            }
            if(exp === "ReallyDeleteVideo") {
                return "Are you sure you want to delete this video?";
            }
            if (exp === "Username") {
                return "Livecam Profile";
            }
            if (exp === "Deine Videos") {
                return "Your Videolist";
            }
            if(exp === "Hier geht es zur ID-Shot Verwaltung") {
                return "To ID-Shot management";
            }
            if(exp === "Altersverifikation für Drehpartner") {
                return "Age verification for people in videos";
            }
            if(exp === "Altersverifikation für Drehpartner Description") {
                return "Legislation requires us to ensure the age and identity of the actors in your videos. You can create a list of actors who act in your videos and upload an ID shot for each actor. In addition, we need the declaration of consent signed by your shooting partner.";
            }
            if(exp === "Altersverifikation für Drehpartner Description2") {
                return "For each new video that you upload, you have to indicate who else is involved in the videos alongside you. Only then can we release a video.";
            }
            if(exp === "SelectAutoThumb") {
                return "Thumbnail";
            }
            if(exp === "UploadPic") {
                return "Upload Picture";
            }
            if(exp === "DeleteCurrentPic") {
                return "Delete Picture";
            }
            if(exp === "CopyToSoft") {
                return "Copy";
            }
            if(exp === "CopyToHard") {
                return "Copy";
            }
            if(exp === "uploadtime") {
                return "Upload time";
            }
            if(exp === "sortindex") {
                return "Custom sort";
            }
            if(exp === "dimensions") {
                return "Pixel resolution";
            }
            if(exp === "uploadsize") {
                return "File size";
            }
            if(exp === "reviewstatus") {
                return "Review status";
            }
            if(exp === "adultstatus") {
                return "Adult status";
            }
        }

        return exp;
    }

    getReviewStatusText(reviewStatus: number) {
        switch (reviewStatus) {
            case 0:
                return this.getText("Not reviewed");
            case 1:
                return this.getText("Approved");
            case 2:
                return this.getText("Rejected");
            default:
                return this.getText("Unknown");
        }
    }

    render () {
        return (
            <div className='mt-4'><Alert isOpen={this.state.infoMessage !== ""} color="info">{this.state.infoMessage}</Alert>
            <Alert isOpen={this.state.errorMessage !== ""} color="danger">{this.state.errorMessage}</Alert>
                {this.state.singleVideoAPIAction ? <p className="pt-4"><FontAwesomeIcon icon={solid('cog')} spin size="4x" /></p> : null }
                { this.state.fetchIDShotAPIAction ? <FontAwesomeIcon icon={solid('cog')} spin></FontAwesomeIcon> : null }
                { this.state.showVideoThumbModal !== "" && this.state.selectedVideoData !== null ?
                    <VideoThumbModal mode={this.state.showVideoThumbModal} streamName={this.state.selectedVideoData.streamname} setThumb={this.setThumb.bind(this)} close={() => this.setState({ showVideoThumbModal: "" })} />
                : null }
                { this.state.selectedVideoData !== null ?
                <div id="SingleVideoPane">
                    <h5>
                        <span style={{marginRight: "10px"}}>Video {this.state.selectedVideoData.title !== "" ? this.state.selectedVideoData.title : this.state.selectedVideoData.streamname}</span>
                        { this.state.selectedVideoData !== null ?
                        <Button className='d-inline-block' style={{marginRight: "10px"}} color="secondary" onClick={this.fetchVideoPlayURL.bind(this)}><FontAwesomeIcon icon={solid('play')}></FontAwesomeIcon> {this.getText("Play")}</Button>
                        : null }
                        { this.state.selectedVideoData !== null ?
                        <Button className='d-inline-block' style={{marginRight: "10px"}} color="primary" onClick={this.clickSave.bind(this)}><FontAwesomeIcon icon={solid('floppy-disk')}></FontAwesomeIcon> {this.getText("Save")}</Button>
                        : null }
                        <Button className='d-inline-block' color="secondary" onClick={this.clickClose.bind(this)}><FontAwesomeIcon icon={solid('arrow-right-from-bracket')}></FontAwesomeIcon> {this.getText("BackToList")}</Button>
                    </h5>
                    { this.state.videoPlayURL !== "" ? <video controls autoPlay src={this.state.videoPlayURL} playsInline muted /> : null }
                    <table className="table table-striped">
                        <tr>
                            <td>{this.getText("Published")}</td>
                            <td>{this.state.selectedVideoData.publishTime ? DateTime.fromISO(this.state.selectedVideoData.uploadTime.toString()).toLocaleString(DateTime.DATETIME_MED) : null }</td>
                        </tr>
                        <tr>
                            <td>{this.getText("Uploaded")}</td>
                            <td>{DateTime.fromISO(this.state.selectedVideoData.uploadTime.toString()).toLocaleString(DateTime.DATETIME_MED)}</td>
                        </tr>
                        <tr>
                            <td>{this.getText("ReviewStatus")}</td>
                            <td>{this.getReviewTextForSelectedVideo()}</td>
                        </tr>
                        <tr>
                            <td>{this.getText("Title")}</td>
                            <td><Input type="text" placeholder={this.getText("Title")} value={this.state.selectedVideoData.title} onChange={this.handleTitleChange.bind(this)} /></td>
                        </tr>
                        <tr>
                            <td>{this.getText("Description")}</td>
                            <td><Input type="textarea" placeholder={this.getText("Description")} value={this.state.selectedVideoData.description} onChange={this.handleDescriptionChange.bind(this)} /></td>
                        </tr>
                    </table>
                    {this.state.singleVideoAPIAction ? <p className="pt-4"><FontAwesomeIcon icon={solid('cog')} spin size="4x" /></p> : null }
                    <div className="w-100 d-flex mb-1">
                        <img src={this.state.selectedVideoData.previewImageURLHard && this.state.selectedVideoData.previewImageURLHard !== "" ? this.state.selectedVideoData.previewImageURLHard : "https://placehold.co/540x400?text=No+hard+Image"} className="w-50" />
                        <img src={this.state.selectedVideoData.previewImageURLSoft && this.state.selectedVideoData.previewImageURLSoft !== "" ? this.state.selectedVideoData.previewImageURLSoft : "https://placehold.co/540x400?text=No+soft+Image"} className="w-50" />
                    </div>
                    <div className="w-100 d-flex mb-1">
                        <div className="w-50">
                            <h5>{this.getText("Hardcore")}</h5>
                            { this.state.imageUploadMode === "hard" ?
                            <ImageUpload producerID={this.context?.producerID} uploadInfo={"videohard-" + this.state.selectedVideoData.streamname} finishCallback={this.uploadFinishHard.bind(this)} />
                            : null }
                            <ButtonGroup>
                                <Button className="btn btn-secondary" size="sm" onClick={this.changeHardPic.bind(this)}><FontAwesomeIcon icon={solid('photo-film')} /> {this.getText("SelectAutoThumb")}</Button>
                                <Button className="btn btn-secondary" size="sm" onClick={this.uploadHardPic.bind(this)}><FontAwesomeIcon icon={solid('upload')} /> {this.getText("UploadPic")}</Button>
                                {this.state.selectedVideoData.previewImageURLHard && this.state.selectedVideoData.previewImageURLHard !== "" ?
                                <Button className="btn btn-secondary" size="sm" onClick={this.deleteHardPic.bind(this)}><FontAwesomeIcon icon={solid('trash')} /> {this.getText("DeleteCurrentPic")}</Button>
                                : null }
                                <Button className="btn btn-secondary" size="sm" onClick={this.copyHardPicToSoft.bind(this)}><FontAwesomeIcon icon={solid('arrow-right')} /> {this.getText("CopyToSoft")}</Button>
                            </ButtonGroup>
                        </div>
                        <div className="w-50">
                            <h5>{this.getText("Softcore")}</h5>
                            { this.state.imageUploadMode === "soft" ?
                            <ImageUpload producerID={this.context?.producerID} uploadInfo={"videosoft-" + this.state.selectedVideoData.streamname} finishCallback={this.uploadFinishSoft.bind(this)} />
                            : null }
                            <ButtonGroup>
                                <Button className="btn btn-secondary" size="sm" onClick={this.changeSoftPic.bind(this)}><FontAwesomeIcon icon={solid('photo-film')} /> {this.getText("SelectAutoThumb")}</Button>
                                <Button className="btn btn-secondary" size="sm" onClick={this.uploadSoftPic.bind(this)}><FontAwesomeIcon icon={solid('upload')} /> {this.getText("UploadPic")}</Button>
                                {this.state.selectedVideoData.previewImageURLSoft && this.state.selectedVideoData.previewImageURLSoft !== "" ? 
                                <Button className="btn btn-secondary" size="sm" onClick={this.deleteSoftPic.bind(this)}><FontAwesomeIcon icon={solid('trash')} /> {this.getText("DeleteCurrentPic")}</Button>
                                : null }
                                <Button className="btn btn-secondary" size="sm" onClick={this.copySoftPicToHard.bind(this)}><FontAwesomeIcon icon={solid('arrow-left')} />{this.getText("CopyToHard")}</Button>
                            </ButtonGroup>
                        </div>
                    </div>
                    <hr></hr>
                    <div>
                        {this.state.idshotAdd ? <IDShotManagerModal open={this.state.idshotAdd} closeCallback={this.idshotAddClose.bind(this)} streamname={this.state.selectedVideoData.streamname} selectCallback={this.idshotAddSelect.bind(this)} /> : null }
                        <p><small>{this.getText("IDShotsExplanation")}</small></p>
                        <Button className="btn btn-secondary" size="sm" onClick={this.idshotAddClick.bind(this)}><FontAwesomeIcon icon={solid('plus')} /> {this.getText("AddIDShot")}</Button>
                    </div>
                    <div className="d-flex flex-wrap mt-2" style={{gap: "4px"}}>
                        {this.state.idshots.length === 0 ? <p>{this.getText("NoIDShots")}</p> :
                        this.state.idshots.map(idshot =>
                            <div className="idshotItem">
                                <div className="d-flex justify-content-between w-100">
                                    <small>{this.getText("AddedPerson")}</small>
                                    <div>
                                        <FontAwesomeIcon style={{cursor: "pointer"}} onClick={this.idshotDeleteClick.bind(this,idshot.guid)} icon={solid('remove')} fixedWidth />
                                    </div>
                                </div>
                                <div className="w-100">
                                    <table className="idshotItemTable w-100">
                                        <tr>
                                            <td className="w-50">{this.getText("Vorname")}</td>
                                            <td className="w-50"><span>{idshot.vorname}</span></td>
                                        </tr>
                                        <tr>
                                            <td className="w-50">{this.getText("Nachname")}</td>
                                            <td className="w-50"><span>{idshot.nachname}</span></td>
                                        </tr>
                                        <tr>
                                            <td className="w-50">{this.getText("Geburtsdatum")}</td>
                                            { idshot.geburtsdatum ? 
                                            <td className="w-50">{ typeof(idshot.geburtsdatum.toISOString) === "function" ? DateTime.fromISO(idshot.geburtsdatum.toISOString()).toLocaleString(DateTime.DATE_MED) : DateTime.fromISO(idshot.geburtsdatum.toString()).toLocaleString(DateTime.DATE_MED)}</td>
                                            : <td className="w-50">{this.getText("Unknown")}</td> }
                                        </tr>
                                        <tr>
                                            <td className="w-50">{this.getText("Status")}</td>
                                            <td className="w-50">
                                                {idshot.files.length === 0 ? 
                                                <span><FontAwesomeIcon icon={solid('exclamation-triangle')} fixedWidth /> {this.getText("NoFiles")}</span> : 
                                                idshot.checkStatus ? <span><FontAwesomeIcon icon={solid('square-check')} fixedWidth /> {this.getText("Reviewed")}</span> : 
                                                <span><FontAwesomeIcon icon={solid('magnifying-glass')} fixedWidth /> {this.getText("In review")}</span> }
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <hr></hr>
                                <div className='w-100 d-flex'>
                                    {idshot.files.length === 0 ? 
                                    <p>
                                        <p>{this.getText("PleaseAddFilesToThisIDShot")}</p>
                                    </p> 
                                    :
                                    idshot.files.map(file =>
                                        <div className="idshotFileItem">
                                            { file.extension !== ".pdf" ? 
                                            <img className="idshotImageFile" src={file.previewURL} alt={"File " + file.id.toString()} /> : null }
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div> : null }
            </div>
        );
    }
}

export default VideoSingleEdit;