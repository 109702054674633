import React, { Component, FormEvent } from 'react';
import { Alert, Container, Form, FormGroup, Button, Label, Input, ButtonGroup, Dropdown, DropdownMenu, DropdownItem } from 'reactstrap';
import { LocalStorageWorker } from '../StorageHelper';
import { CryptoHelper } from '../CryptoHelper.js';
import { AppCtx, AppContextInterface, ApiHelper } from '../AppContextInterface';
import { Redirect } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DateTime } from 'luxon';
import VideoUpload from './VideoUpload';
import VideoThumbModal from './VideoThumbModal';
import { Link } from 'react-router-dom';
import { StringMappingType } from 'typescript';
import ImageUpload from './ImageUpload';
import { IDShotRecord,IDShotRecordFile } from './IDShotManager';
import IDShotManagerModal from './IDShotManagerModal';

interface AudioListProps {
    onAudioClick(video:VideoData): void;
}

export interface AudioList extends React.Component {
    state: AudioListState;
    props: AudioListProps;
    contextType: AppContextInterface;
}

interface AudioListState {
    errorMessage: string,
    successMessage: string,
    fetchingAudios: boolean,
    language: string,
    videos: VideoData[],
    selectedAudioData: VideoData|null,
    conversationID: string,
    showUpload: boolean,
    singleAudioAPIAction: boolean,
    showVideoThumbModal: string,
    videoPlayURL: string,
    fetchIDShotAPIAction: boolean,
    imageUploadMode: string,
    idshots: IDShotRecord[],
    idshotAdd: boolean
}

export interface VideoData {
    streamname: string;
    title: string;
    description: string;
    uploadTime: Date;
    publishTime: Date;
    reviewStatus: number;
    price: number;
    previewImageURLHard: string;
    previewImageURLSoft: string;
    timelinePostGuid: string|null;
}

export class AudioList extends React.Component {
    static contextType = AppCtx;
    context!: React.ContextType<typeof AppCtx>;

    constructor(props: AudioListProps) {
        super(props);

        let contextLanguage = "de";

        if (this.context) {
            if (this.context.language !== null && this.context.language !== "") {
                contextLanguage = this.context.language;
            }
        }

        this.state = {
            errorMessage: "",
            successMessage: "",
            fetchingAudios: false,
            language: contextLanguage,
            selectedAudioData: null,
            conversationID: "",
            videos: [],
            showUpload: false,
            singleAudioAPIAction: false,
            showVideoThumbModal: "",
            videoPlayURL: "",
            fetchIDShotAPIAction: false,
            imageUploadMode: "",
            idshots: [],
            idshotAdd: false
        }
    }

    componentDidMount() {
        this.fetchAudioList();
    }

    fetchAudioList = async () => {
        this.setState({ fetchingAudios: true });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/videos?role=producer";

        if (this.context) {
            if(!this.context.loggedin) {
                await ApiHelper.checkLoginStatus(this.context);
            }
            jsonurl += "&producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        }

        jsonurl += "&videoType=" + encodeURIComponent("audio");

        let response: any = null;

        try {
            response = await fetch(jsonurl);
            let data = await response.json();
            this.setState({ fetchingAudios: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                    if(!ApiHelper.checkResponse(data,this.context) && this.context !== null) {
                        this.context.producerID = "";
                        alert("Please relogin");
                    }
                }
            }

            if (data.videos) {
                this.setState({ videos: data.videos });
            }
        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching data: " + error.toString() });
            this.setState({ fetchingAudios: false });
        }
    }

    fetchSingleAudio = async (streamName:string) => {
        this.setState({ singleAudioAPIAction: true });
        this.setState({ errorMessage: "" });
        this.setState({ successMessage: "" });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/video/" + encodeURIComponent(streamName) + "?role=producer&videoType=audio";

        if (this.context) {
            jsonurl += "&producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        }

        let response: any = null;

        try {
            response = await fetch(jsonurl);
            let data = await response.json();
            this.setState({ singleAudioAPIAction: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                    if(!ApiHelper.checkResponse(data,this.context) && this.context !== null) {
                        this.context.producerID = "";
                        alert("Please relogin");
                    }
                }
            }

            this.setState({ selectedAudioData: data.video });

        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching data: " + error.toString() });
            this.setState({ singleAudioAPIAction: false });
        }
    }

    getText(exp: string) {
        if (this.state.language === "de") {
            if (exp === "Posted") {
                return "In Timeline";
            }
            if (exp === "Not Posted") {
                return "Nicht in Timeline";
            }
            if (exp === "AddedPerson") {
                return "Hinzugefügte Person";
            }
            if (exp === "IDShotsExplanation") {
                return "Falls weitere Personen im Video auftauchen, benötigen wir eine Einverständniserklärung von diesen Personen. Diese kannst Du hier hochladen bzw. dem Video hinzufügen.";
            }
            if(exp === "AddIDShot") {
                return "Einverständniserklärung für Person hinzufügen";
            }
            if(exp === "VideoUploaded") {
                return "Video hochgeladen";
            }
            if(exp === "ReallyDeleteVideo") {
                return "Bist Du sicher, dass Du das Video löschen möchtest?";
            }
            if (exp === "Username") {
                return "Pseudo";
            }
            if (exp === "Deine Videos") {
                return "Deine AudioListe";
            }
            if (exp === "Reviewed") {
                return "Geprüft";
            }
            if (exp === "In review") {
                return "In Prüfung";
            }
            if (exp === "No Title") {
                return "Ohne Titel";
            }
            if(exp === "Change Pic") {
                return "Bild ändern";
            }
            if (exp === "Published") {
                return "Zur Veröffentlichung freigegeben";
            }
            if (exp === "Uploaded") {
                return "Hochgeladen";
            }
            if (exp === "ReviewStatus") {
                return "Prüfstatus";
            }
            if (exp === "Title") {
                return "Videotitel";
            }
            if (exp === "Description") {
                return "Beschreibungstext";
            }
            if (exp === "Public") {
                return "Veröffentlicht";
            }
            if (exp === "Not public") {
                return "Privat";
            }
            if(exp === "Altersverifikation für Drehpartner Description") {
                return "Der Gesetzgeber verlangt von uns Alter und Identität der Darsteller in Deinen Videos sicher zu stellen. Du kannst hier ab sofort eine Liste von Darstellern anlegen, die in Deinen Filmen mitwirken und einen ID-Shot für jeden Darsteller hochladen. Zusätzlich benötigen wir die von Deinem Drehpartner unterschriebene Einverständniserklärung.";
            }
            if(exp === "Altersverifikation für Drehpartner Description2") {
                return "Bei jedem Video, dass Du neu hochlädst musst Du angeben, wer neben Dir noch in den Videos mitwirkt. Erst dann können wir ein Video freigeben.";
            }
            if(exp === "SelectAutoThumb") {
                return "Thumbnail";
            }
            if(exp === "UploadPic") {
                return "Bild hochladen";
            }
            if(exp === "DeleteCurrentPic") {
                return "Bild löschen";
            }
            if(exp === "CopyToSoft") {
                return "Kopieren";
            }
            if(exp === "CopyToHard") {
                return "Kopieren";
            }
            if(exp === "NoIDShots") {
                return "Diesem Video sind im Moment keine Einverständniserklärungen von weiteren Personen zugeordnet";
            }
            if(exp === "NoFiles") {
                return "Keine Dateien zu diesem Datensatz";
            }
            if (exp === "Vorname") {
                return "Vorname";
            }
            if (exp === "Nachname") {
                return "Nachname";
            }
            if (exp === "Geburtsdatum") {
                return "Geburtsdatum";
            }
        } else {
            if (exp === "AddedPerson") {
                return "Assgined Person";
            }
            if (exp === "IDShotsExplanation") {
                return "If other people appear in the video, we need a letter of consent from these people. You can upload it here or add it to the audio.";
            }
            if(exp === "AddIDShot") {
                return "Add letter of consent for person in video";
            }
            if(exp === "NoFiles") {
                return "No files for this record";
            }
            if (exp === "Vorname") {
                return "First name";
            }
            if (exp === "Nachname") {
                return "Last name";
            }
            if (exp === "Geburtsdatum") {
                return "Birthday";
            }
            if(exp === "NoIDShots") {
                return "Currently there are no letters of consent for people in this video attached";
            }
            if(exp === "VideoUploaded") {
                return "Video uploaded";
            }
            if(exp === "ReallyDeleteVideo") {
                return "Are you sure you want to delete this video?";
            }
            if (exp === "Username") {
                return "Livecam Profile";
            }
            if (exp === "Deine Videos") {
                return "Your AudioList";
            }
            if(exp === "Hier geht es zur ID-Shot Verwaltung") {
                return "To ID-Shot management";
            }
            if(exp === "Altersverifikation für Drehpartner") {
                return "Age verification for people in videos";
            }
            if(exp === "Altersverifikation für Drehpartner Description") {
                return "Legislation requires us to ensure the age and identity of the actors in your videos. You can create a list of actors who act in your videos and upload an ID shot for each actor. In addition, we need the declaration of consent signed by your shooting partner.";
            }
            if(exp === "Altersverifikation für Drehpartner Description2") {
                return "For each new video that you upload, you have to indicate who else is involved in the videos alongside you. Only then can we release a audio.";
            }
            if(exp === "SelectAutoThumb") {
                return "Thumbnail";
            }
            if(exp === "UploadPic") {
                return "Upload Picture";
            }
            if(exp === "DeleteCurrentPic") {
                return "Delete Picture";
            }
            if(exp === "CopyToSoft") {
                return "Copy";
            }
            if(exp === "CopyToHard") {
                return "Copy";
            }
        }

        return exp;
    }

    clickAudio(selectedAudioData:VideoData) {
        if(typeof(this.props.onAudioClick) === "function") {
            this.props.onAudioClick(selectedAudioData);
        } else {
            this.setState({ showUpload: false });
            this.setState({ videoPlayURL: "" });
            this.setState({ selectedAudioData: selectedAudioData });
            this.fetchSingleAudio(selectedAudioData.streamname);
        }
    }

    deleteAudio(selectedAudioData:VideoData) {
        if(window.confirm(this.getText("ReallyDeleteVideo"))) {
            this.setState({ showUpload: false });
            this.setState({ videoPlayURL: "" });
            this.setState({ selectedAudioData: null });
            this.setState({ idshots: [] });
            this.deleteSingleAudio(selectedAudioData.streamname);
        }
    }

    deleteSingleAudio = async(streamName:string) => {
        this.setState({ singleAudioAPIAction: true });
        this.setState({ errorMessage: "" });
        this.setState({ successMessage: "" });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/video/delete/" + encodeURIComponent(streamName);

        if (this.context) {
            jsonurl += "?producerOrUserID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken) + "&role=producer";
        } else {
            alert("Cannot delete video because of missing context");
        }

        let response: any = null;

        try {
            response = await fetch(jsonurl);
            let data = await response.json();
            this.setState({ singleAudioAPIAction: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                    if(!ApiHelper.checkResponse(data,this.context) && this.context !== null) {
                        this.context.producerID = "";
                        alert("Please relogin");
                    }
                }
            }

            this.setState({ videoPlayURL: data.url });

            if(data.success) {
                this.setState({ successMessage: data.message });
                setTimeout(this.fetchAudioList.bind(this),3000);
            } else {
                this.setState({ errorMessage: data.error });
            }

        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching data: " + error.toString() });
            this.setState({ singleAudioAPIAction: false });
        }
    }

    clickAudios() {
        this.setState({ successMessage: "",errorMessage: "",videoPlayURL: "",selectedAudioData: null,showUpload: false,showVideoThumbModal: "",imageUploadMode: "" });
        this.setState({ showUpload: false });
        this.setState({ videoPlayURL: "" });
        this.setState({ selectedAudioData: null });
        this.setState({ idshots: [] });
        this.fetchAudioList();
    }

    uploadComplete() {
        this.setState({ videoPlayURL: "" });
        this.setState({ selectedAudioData: null });
        this.setState({ idshots: [] });
        this.setState({ successMessage: this.getText("VideoUploaded") });
        this.fetchAudioList();
    }

    getReviewTextForSelectedVideo() {
        return this.state.selectedAudioData?.reviewStatus === 0 ? this.getText("In review") : this.getText("Reviewed");
    }


    copyHardPicToSoft() {
        let myVideoData:VideoData|null = this.state.selectedAudioData;
        if(myVideoData) {
            myVideoData.previewImageURLSoft = myVideoData.previewImageURLHard;
            this.setState({ selectedAudioData: myVideoData });
        }
    }

    copySoftPicToHard() {
        let myVideoData:VideoData|null = this.state.selectedAudioData;
        if(myVideoData) {
            myVideoData.previewImageURLHard = myVideoData.previewImageURLSoft;
            this.setState({ selectedAudioData: myVideoData });
        }
    }

    handleTitleChange(event: React.ChangeEvent<HTMLInputElement>) {
        let myVideoData:VideoData|null = this.state.selectedAudioData;
        if(myVideoData) {
            myVideoData.title = event.target.value;
            this.setState({ selectedAudioData: myVideoData });
        }
    }

    handleDescriptionChange(event: React.ChangeEvent<HTMLInputElement>) {
        let myVideoData:VideoData|null = this.state.selectedAudioData;
        if(myVideoData) {
            myVideoData.description = event.target.value;
            this.setState({ selectedAudioData: myVideoData });
        }
    }

    clickSave() {
        if(this.state.selectedAudioData) {
            this.saveAudio(this.state.selectedAudioData);
        }
    }

    detectAndroid():boolean {
        var ua = navigator.userAgent.toLowerCase();
        var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
        return isAndroid;
    }

    detectiOS():boolean {
        return [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }

    fetchAudioPlayURL = async () => {

        if(this.state.videoPlayURL !== "") {
            this.setState({ videoPlayURL: "" });
            return;
        }

        let streamName:string = this.state.selectedAudioData?.streamname || "";

        this.setState({ singleAudioAPIAction: true });
        this.setState({ errorMessage: "" });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/videoplay/" + encodeURIComponent(streamName);

        if (this.context) {
            jsonurl += "?userOrProducerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken) + "&role=producer";
            if(this.detectiOS()) {
                jsonurl += "&playerType=hls";
            } else {
                jsonurl += "&playerType=mp4";
            }
            jsonurl += "&viewContextType=control";
            jsonurl += "&viewContextID=control";
        } else {
            alert("Cannot get video play url because of missing context");
        }

        let response: any = null;

        try {
            response = await fetch(jsonurl);
            let data = await response.json();
            this.setState({ singleAudioAPIAction: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                    if(!ApiHelper.checkResponse(data,this.context) && this.context !== null) {
                        this.context.producerID = "";
                        alert("Please relogin");
                    }
                }
            }

            this.setState({ videoPlayURL: data.url });

        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching data: " + error.toString() });
            this.setState({ singleAudioAPIAction: false });
        }
    }

    saveAudio = async (videoData:VideoData) => {
        this.setState({ singleAudioAPIAction: true });
        this.setState({ errorMessage: "" });

        var jsonurl = ApiHelper.getAPIUrl(this.context) + "/video/" + encodeURIComponent(videoData.streamname);

        if (this.context) {
            jsonurl += "?producerID=" + encodeURIComponent(this.context.producerID) + "&authToken=" + encodeURIComponent(this.context.jwtAuthToken !== null && this.context.jwtAuthToken !== "" ? this.context.jwtAuthToken : this.context.authToken);
        }

        let response: any = null;

        try {
            response = await fetch(jsonurl,{method: "PUT", body: JSON.stringify(videoData), headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}});
            let data = await response.json();
            this.setState({ singleAudioAPIAction: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                    if(!ApiHelper.checkResponse(data,this.context) && this.context !== null) {
                        this.context.producerID = "";
                        alert("Please relogin");
                    }
                }
            }

            this.setState({ selectedAudioData: data.video });
            this.fetchAudioList();

        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching data: " + error.toString() });
            this.setState({ singleAudioAPIAction: false });
        }
    }

    render() {
        if (this.context) {
            if (this.context.producerID === "") {
                return <Redirect to="/" push={true} />
            }
        } else {
            return <Redirect to="/" push={true} />
        }

        return (
            <div id='AudioListDIV'>
                <h4 className='pageHeadline'>
                    {this.getText("Audios")}
                </h4>
                <div id="AudioListHeader">
                    <p className="d-flex align-items-center pb-3 mb-3 link-dark text-decoration-none border-bottom">
                        <span className="fs-5 fw-semibold">Videos</span>
                        <Button className='d-inline-block' style={{marginLeft: "10px",marginRight: "10px"}} color="secondary" onClick={this.clickAudios.bind(this)}><FontAwesomeIcon icon={solid('film')} /> {this.getText("Deine Videos")}</Button>
                        <Button className='d-inline-block' color="secondary" onClick={() => this.setState({successMessage: "",errorMessage: "",showUpload: !this.state.showUpload})}><FontAwesomeIcon icon={solid('upload')} /> {this.getText("Upload")}</Button>
                    </p>
                </div>
                { this.state.showUpload ?
                <div id="AudioListUpload">
                    <VideoUpload producerID={this.context.producerID} authToken={this.context.authToken} uploadType="audio" completeCallback={this.uploadComplete.bind(this)} />
                </div> : null }
                {this.state.successMessage !== "" ? <Alert color="success">{this.state.successMessage}</Alert> : null}
                {this.state.errorMessage !== "" ? <Alert color="danger">{this.state.errorMessage}</Alert> : null}
                {this.state.selectedAudioData == null ?
                <div id="AudioListPane" className="d-flex flex-column align-items-stretch flex-shrink-0 bg-white">
                    {this.state.singleAudioAPIAction ? <p className="pt-4"><FontAwesomeIcon icon={solid('cog')} spin size="4x" /></p> : null }
                    {this.state.fetchingAudios ? <p className="pt-4"><FontAwesomeIcon icon={solid('cog')} spin size="4x" /></p> :
                        <div className="d-flex flex-wrap" style={{gap: "4px"}}>
                            {this.state.videos.map(audio =>
                                <div className="videoItem">
                                    <div className="d-flex w-100 align-items-center justify-content-between flex-wrap videoItemTitle">
                                        <strong className="mb-1">
                                            {audio.title && audio.title !== "" ? audio.title : this.getText("No Title")}
                                        </strong>
                                        <small style={{cursor: "pointer"}} onClick={this.deleteAudio.bind(this,audio)}><FontAwesomeIcon icon={solid("remove")}></FontAwesomeIcon> &nbsp;</small>
                                    </div>
                                    <div className="d-flex w-100 align-items-center justify-content-between flex-wrap videoBoxUpper">
                                        <small onClick={this.clickAudio.bind(this,audio)}>{DateTime.fromISO(audio.uploadTime.toString()).toLocaleString(DateTime.DATETIME_MED)}&nbsp;</small>
                                    </div>
                                    <div className="d-flex justify-content-between videoBoxLower">
                                        <span>
                                            {audio.reviewStatus === 1 ? <span className='badge bg-secondary'><FontAwesomeIcon icon={solid("check-circle")}></FontAwesomeIcon> {this.getText("Reviewed")}</span> : <span className='badge bg-secondary'>{this.getText("In review")}</span> }
                                        </span>
                                        <span>
                                            {audio.timelinePostGuid != null ? <span className='badge bg-success'><FontAwesomeIcon icon={solid("megaphone")}></FontAwesomeIcon> {this.getText("Posted")}</span> : <span className='badge bg-secondary'>{this.getText("Not posted")}</span> }
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    }
                    </div>
                    :   <div id="SingleVideoPane">
                            <h5>
                                Video {this.state.selectedAudioData.title !== "" ? this.state.selectedAudioData.title : this.state.selectedAudioData.streamname}
                                { this.state.selectedAudioData !== null ?
                                <Button className='d-inline-block' style={{marginRight: "10px"}} color="secondary" onClick={this.fetchAudioPlayURL.bind(this)}><FontAwesomeIcon icon={solid('play')}></FontAwesomeIcon> {this.getText("Play")}</Button>
                                : null }
                                { this.state.selectedAudioData !== null ?
                                <Button className='d-inline-block' color="primary" onClick={this.clickSave.bind(this)}><FontAwesomeIcon icon={solid('floppy-disk')}></FontAwesomeIcon> {this.getText("Save")}</Button>
                                : null }
                            </h5>
                            { this.state.videoPlayURL !== "" ? <video controls autoPlay src={this.state.videoPlayURL} playsInline muted /> : null }
                            <table className="table table-striped">
                                <tr>
                                    <td>{this.getText("Published")}</td>
                                    <td>{this.state.selectedAudioData.publishTime ? DateTime.fromISO(this.state.selectedAudioData.uploadTime.toString()).toLocaleString(DateTime.DATETIME_MED) : null }</td>
                                </tr>
                                <tr>
                                    <td>{this.getText("Uploaded")}</td>
                                    <td>{DateTime.fromISO(this.state.selectedAudioData.uploadTime.toString()).toLocaleString(DateTime.DATETIME_MED)}</td>
                                </tr>
                                <tr>
                                    <td>{this.getText("ReviewStatus")}</td>
                                    <td>{this.getReviewTextForSelectedVideo()}</td>
                                </tr>
                                <tr>
                                    <td>{this.getText("Title")}</td>
                                    <td><Input type="text" placeholder={this.getText("Title")} value={this.state.selectedAudioData.title} onChange={this.handleTitleChange.bind(this)} /></td>
                                </tr>
                                <tr>
                                    <td>{this.getText("Description")}</td>
                                    <td><Input type="textarea" placeholder={this.getText("Description")} value={this.state.selectedAudioData.description} onChange={this.handleDescriptionChange.bind(this)} /></td>
                                </tr>
                            </table>
                            {this.state.singleAudioAPIAction ? <p className="pt-4"><FontAwesomeIcon icon={solid('cog')} spin size="4x" /></p> : null }
                        </div>
                    }
            </div>
        )
    }
}