import React, { Component } from 'react';
import { AppCtx, AppContextInterface, ApiHelper } from '../AppContextInterface';
import { Card, Row, Col, Alert, Modal, ModalHeader, ModalBody, ModalFooter, Container, Form, FormGroup, Button, Label, Input, ButtonGroup, Dropdown, DropdownMenu, DropdownItem,NavLink, CardHeader, CardBody, CardFooter } from 'reactstrap';
import { LocalStorageWorker } from '../StorageHelper';
import { Link } from 'react-router-dom';
import { VideoList,VideoData } from './VideoList';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { IDShotRecord,IDShotRecordFile } from './IDShotManager';
import IDShotManagerModal from './IDShotManagerModal';
import VideoUpload from './VideoUpload';
import VideoSingleEdit from './VideoSingleEdit';

interface VideosPageState {
    infoMessage: string;
    errorMessage: string;
    language: string;
    selectedVideoData: VideoData|null;
    fetching: boolean;
    additionalPeople: string;
    additionalPeopleConfirm: boolean;
    additionalPeopleReviewStatus: number;
    additionalPeopleReviewComment: string;
    tempGuid: string;
    videoType: string;
    showUpload: boolean;
    lastVideoFolderName: string;
    lastVideoFolderGuid: string;
}

interface VideosPageProps {

}

export interface VideosPage extends React.Component {
    state: VideosPageState;
    props: VideosPageProps;
    contextType: AppContextInterface;
}

export class VideosPage extends Component {
    static contextType = AppCtx;
    context!: React.ContextType<typeof AppCtx>;

    constructor(props: VideosPageProps) { 
        super(props);
        let contextLanguage = "de";
        if (this.context) {
            if (this.context.language !== null && this.context.language !== "") {
                contextLanguage = this.context.language;
            }
        }

        this.state = {
            infoMessage: "",
            errorMessage: "",
            language: contextLanguage,
            selectedVideoData: null,
            fetching: false,
            additionalPeople: "",
            additionalPeopleConfirm: false,
            additionalPeopleReviewStatus: 0,
            additionalPeopleReviewComment: "",
            tempGuid: "",
            videoType: "Premium",
            showUpload: false,
            lastVideoFolderName: "",
            lastVideoFolderGuid: ""
        }
    }

    componentDidMount(): void {
        let myLocalStorage:LocalStorageWorker = new LocalStorageWorker();

        if(myLocalStorage.get("lastpage") !== "videos") {
            myLocalStorage.add("lastpage","videos");
        }
    }

    videoClicked(videoData:VideoData) {
        this.setState({selectedVideoData: videoData});
    }

    confirmSelection(videos:VideoData[]) {
        // do nothing
    }

    getText(exp: string) {
        if (this.state.language === "de") {
            if(exp === "WeNeedEinverstaendniserklaerung") {
                return "Bei weiteren Personen im Video benötigen wir deren Einverständniserklärung";
            }
            if (exp === "VideosPageInfo") {
                return "Hier siehst Du eine Liste der von Dir hochgeladenen Videos, die Du in Posts oder Nachrichten verwenden kannst. Bitte beachte, dass, wenn zusätzliche Personen im Video zu sehen sind, Du diese zu der Liste der IDShots hinzufügen und und eine Bestätigung hochladen musst, dass deren Einverständnis für eine Verwendung und Veröffentlichung der Videos vorliegt.";
            }
            if (exp === "Posted") {
                return "In Timeline";
            }
            if (exp === "Not Posted") {
                return "Nicht in Timeline";
            }
            if (exp === "AddedPerson") {
                return "Hinzugefügte Person";
            }
            if (exp === "IDShotsExplanation") {
                return "Falls weitere Personen im Video auftauchen, benötigen wir eine Einverständniserklärung von diesen Personen. Diese kannst Du hier hochladen bzw. dem Video hinzufügen.";
            }
            if(exp === "AddIDShot") {
                return "Einverständniserklärung für Person hinzufügen";
            }
            if(exp === "VideoUploaded") {
                return "Video hochgeladen";
            }
            if(exp === "ReallyDeleteVideo") {
                return "Bist Du sicher, dass Du das Video löschen möchtest?";
            }
            if (exp === "Username") {
                return "Pseudo";
            }
            if (exp === "Deine Videos") {
                return "Deine Videoliste";
            }
            if (exp === "Reviewed") {
                return "Geprüft";
            }
            if (exp === "In review") {
                return "In Prüfung";
            }
            if (exp === "No Title") {
                return "Ohne Titel";
            }
            if(exp === "Change Pic") {
                return "Bild ändern";
            }
            if (exp === "Published") {
                return "Zur Veröffentlichung freigegeben";
            }
            if (exp === "Uploaded") {
                return "Hochgeladen";
            }
            if (exp === "ReviewStatus") {
                return "Prüfstatus";
            }
            if (exp === "Title") {
                return "Videotitel";
            }
            if (exp === "Description") {
                return "Beschreibungstext";
            }
            if (exp === "Public") {
                return "Veröffentlicht";
            }
            if (exp === "Not public") {
                return "Privat";
            }
            if(exp === "Altersverifikation für Drehpartner Description") {
                return "Der Gesetzgeber verlangt von uns Alter und Identität der Darsteller in Deinen Videos sicher zu stellen. Du kannst hier ab sofort eine Liste von Darstellern anlegen, die in Deinen Filmen mitwirken und einen ID-Shot für jeden Darsteller hochladen. Zusätzlich benötigen wir die von Deinem Drehpartner unterschriebene Einverständniserklärung.";
            }
            if(exp === "Altersverifikation für Drehpartner Description2") {
                return "Bei jedem Video, dass Du neu hochlädst musst Du angeben, wer neben Dir noch in den Videos mitwirkt. Erst dann können wir ein Video freigeben.";
            }
            if(exp === "SelectAutoThumb") {
                return "Thumbnail";
            }
            if(exp === "UploadPic") {
                return "Bild hochladen";
            }
            if(exp === "DeleteCurrentPic") {
                return "Bild löschen";
            }
            if(exp === "CopyToSoft") {
                return "Kopieren";
            }
            if(exp === "CopyToHard") {
                return "Kopieren";
            }
            if(exp === "NoIDShots") {
                return "Diesem Video sind im Moment keine Einverständniserklärungen von weiteren Personen zugeordnet";
            }
            if(exp === "NoFiles") {
                return "Keine Dateien zu diesem Datensatz";
            }
            if (exp === "Vorname") {
                return "Vorname";
            }
            if (exp === "Nachname") {
                return "Nachname";
            }
            if (exp === "Geburtsdatum") {
                return "Geburtsdatum";
            }
            if(exp === "uploadtime") {
                return "Zeitpunkt";
            }
            if(exp === "sortindex") {
                return "Eigene Sotierung";
            }
            if(exp === "dimensions") {
                return "Pixelauflösung";
            }
            if(exp === "uploadsize") {
                return "Dateigrösse";
            }
            if(exp === "reviewstatus") {
                return "Freischaltung";
            }
            if(exp === "adultstatus") {
                return "Alterseinstufung";
            }
        } else {
            if(exp === "WeNeedEinverstaendniserklaerung") {
                return "If other people are in the video we need their letter of consent";
            }
            if (exp === "VideosPageInfo") {
                return "Here you can see a list of videos you have uploaded, which you can use in posts or messages. Please note that if additional people are visible in the video, you have to add them to the list of idshots and confirm that their consent for the use and publication of the video(s) is given.";
            }
            if (exp === "AddedPerson") {
                return "Assgined Person";
            }
            if (exp === "IDShotsExplanation") {
                return "If other people appear in the video, we need a letter of consent from these people. You can upload it here or add it to the video.";
            }
            if(exp === "AddIDShot") {
                return "Add letter of consent for person in video";
            }
            if(exp === "NoFiles") {
                return "No files for this record";
            }
            if (exp === "Vorname") {
                return "First name";
            }
            if (exp === "Nachname") {
                return "Last name";
            }
            if (exp === "Geburtsdatum") {
                return "Birthday";
            }
            if(exp === "NoIDShots") {
                return "Currently there are no letters of consent for people in this video attached";
            }
            if(exp === "VideoUploaded") {
                return "Video uploaded";
            }
            if(exp === "ReallyDeleteVideo") {
                return "Are you sure you want to delete this video?";
            }
            if (exp === "Username") {
                return "Livecam Profile";
            }
            if (exp === "Deine Videos") {
                return "Your Videolist";
            }
            if(exp === "Hier geht es zur ID-Shot Verwaltung") {
                return "To ID-Shot management";
            }
            if(exp === "Altersverifikation für Drehpartner") {
                return "Age verification for people in videos";
            }
            if(exp === "Altersverifikation für Drehpartner Description") {
                return "Legislation requires us to ensure the age and identity of the actors in your videos. You can create a list of actors who act in your videos and upload an ID shot for each actor. In addition, we need the declaration of consent signed by your shooting partner.";
            }
            if(exp === "Altersverifikation für Drehpartner Description2") {
                return "For each new video that you upload, you have to indicate who else is involved in the videos alongside you. Only then can we release a video.";
            }
            if(exp === "SelectAutoThumb") {
                return "Thumbnail";
            }
            if(exp === "UploadPic") {
                return "Upload Picture";
            }
            if(exp === "DeleteCurrentPic") {
                return "Delete Picture";
            }
            if(exp === "CopyToSoft") {
                return "Copy";
            }
            if(exp === "CopyToHard") {
                return "Copy";
            }
            if(exp === "uploadtime") {
                return "Upload time";
            }
            if(exp === "sortindex") {
                return "Custom sort";
            }
            if(exp === "dimensions") {
                return "Pixel resolution";
            }
            if(exp === "uploadsize") {
                return "File size";
            }
            if(exp === "reviewstatus") {
                return "Review status";
            }
            if(exp === "adultstatus") {
                return "Adult status";
            }
        }

        return exp;
    }

    getReviewStatusText(reviewStatus: number) {
        switch (reviewStatus) {
            case 0:
                return this.getText("Not reviewed");
            case 1:
                return this.getText("Approved");
            case 2:
                return this.getText("Rejected");
            default:
                return this.getText("Unknown");
        }
    }

    clickVideos() {
        this.setState({ successMessage: "",errorMessage: "",videoPlayURL: "",selectedVideoData: null,showUpload: false });
        this.setState({ showUpload: false });
        this.setState({ selectedVideoData: null });
        //this.fetchData();
    }

    uploadComplete() {
        this.setState({ selectedVideoData: null });
        this.setState({ successMessage: this.getText("VideoUploaded") });
        //this.fetchData();
    }

    singleEditClose(lastVideo: VideoData|null) {
        if(lastVideo != null) {
            if(lastVideo.folderName === null || lastVideo.folderGuid === null) {
                this.setState({ lastVideoFolderName: "", lastVideoFolderGuid: "", selectedVideoData: null });
            } else {
                this.setState({ lastVideoFolderName: lastVideo.folderName, lastVideoFolderGuid: lastVideo.folderGuid, selectedVideoData: null });
            }
        } else {
            this.setState({ lastVideoFolderName: "",lastVideoFolderGuid: "",selectedVideoData: null });
        }
    }

    render () {
        return (
            <div className='mt-4'>
                {this.state.selectedVideoData == null ?
                <Alert className='mt-2' color="secondary">
                    <h5>{this.getText("Altersverifikation für Drehpartner")}</h5>
                    <p><small>{this.getText("Altersverifikation für Drehpartner Description")}</small></p>
                    <p><small>{this.getText("Altersverifikation für Drehpartner Description2")}</small></p>
                    <p><Link to="/idshots"><Button color="secondary" size="sm">{this.getText("Hier geht es zur ID-Shot Verwaltung")}</Button></Link></p>
                    <p>
                    <small>{this.getText("WeNeedEinverstaendniserklaerung")}</small>&nbsp;&nbsp;
                        <a className='btn btn-secondary btn-sm' href="https://guppytabupload.s3.eu-west-1.amazonaws.com/sender/Einverst%C3%A4ndniserkl%C3%A4rung+Darsteller.pdf" target="_blank" rel="noopener noreferrer">Einverständniserklärung</a>&nbsp;&nbsp;
                        <a className='btn btn-secondary btn-sm' href="https://guppytabupload.s3.eu-west-1.amazonaws.com/sender/Einverst%C3%A4ndniserkl%C3%A4rung+Darsteller+English.pdf" target="_blank" rel="noopener noreferrer">Letter of Consent</a>
                    </p>
                </Alert> : null }
                <Alert isOpen={this.state.infoMessage !== ""} color="info">{this.state.infoMessage}</Alert>
                <Alert isOpen={this.state.errorMessage !== ""} color="danger">{this.state.errorMessage}</Alert>
                { this.state.showUpload && this.context ?
                <div id="VideoListUpload">
                    <VideoUpload producerID={this.context.producerID} authToken={this.context.authToken} uploadType="Premium" completeCallback={this.uploadComplete.bind(this)} />
                </div> : null }
                { this.state.selectedVideoData != null ?
                    <VideoSingleEdit streamName={this.state.selectedVideoData.streamname} closeCallback={this.singleEditClose.bind(this)} />
                : 
                <div>
                    <h4 className='pageHeadline'>
                        {this.getText("Videos")}
                        <Button className='d-inline-block' style={{marginLeft: "10px",marginRight: "10px"}} color="secondary" onClick={this.clickVideos.bind(this)}><FontAwesomeIcon icon={solid('film')} /> {this.getText("Deine Videos")}</Button>
                        <Button className='d-inline-block' color="secondary" onClick={() => this.setState({successMessage: "",errorMessage: "",showUpload: !this.state.showUpload})}><FontAwesomeIcon icon={solid('upload')} /> {this.getText("Upload")}</Button>
                    </h4>
                    <Alert color="secondary"><small>{this.getText("VideosPageInfo")}</small></Alert>
                    <VideoList singleSelect={true} folderName={this.state.lastVideoFolderName} folderGuid={this.state.lastVideoFolderGuid} onVideoClick={this.videoClicked.bind(this)} confirmSelection={this.confirmSelection.bind(this)} modalMode={false} mediaAddMode="" />
                </div> }
            </div>
        );
    }
}
